/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

const SLICE_NAME = 'shared';

const initialState = {
  isDeleting: false,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsDeleting(state, action) {
      state.isDeleting = action.payload;
    },
  },
});

export const {
  setIsDeleting,
} = slice.actions;

export const sharedReducer = slice.reducer;

export const getIsDeleting = (state: any) => state[SLICE_NAME].isDeleting;
