import React from 'react';
import { Bar } from 'react-chartjs-2';
import ButtonSpinner from '../../../../components/shared/ButtonSpinner';
import { EntityContainer } from '../../../../redux/models/core.models';
import { Move } from '../../../../redux/models/trip.models';
import { Timeliness } from '../../../../utils/timeliness';
import { calculateTime, getBoardMoves } from '../../utils/move.utils';
import usePalette from '../../../../hooks/usePalette';

const getData = (boardId: string, boardMoves: EntityContainer<Move[]>, timeliness: Timeliness | null) => {
  const brdMvs = boardMoves[boardId] || [];
  const exEarly = calculateTime(brdMvs, timeliness);
  return exEarly || 0;
}

interface Props {
  boardId: string;
  moves: Move[];
  isLoading: boolean;
}

export default function TimelinessChart({ boardId, isLoading, moves }: Props) {
  const palette = usePalette();
  const labels = ['Excessively Early', 'Early', 'On Time', 'Late', 'Excessively Late', 'None'];
  const boardMoves = getBoardMoves(moves);
  const exEarlyData = getData(boardId, boardMoves, Timeliness.ExcessivelyEarly);
  const earlyData = getData(boardId, boardMoves, Timeliness.Early);
  const onTime = getData(boardId, boardMoves, Timeliness.OnTime);
  const late = getData(boardId, boardMoves, Timeliness.Late);
  const exLate = getData(boardId, boardMoves, Timeliness.ExcessivelyLate);
  const none = getData(boardId, boardMoves, null);

  const data = {
    labels,
    datasets: [
      {
        label: "Excessively Early",
        backgroundColor: palette.primary,
        borderColor: palette.primary,
        hoverBackgroundColor: palette.primary,
        hoverBorderColor: palette.primary,
        data: [exEarlyData, 0, 0, 0, 0, 0],
        barPercentage: 0.325,
        categoryPercentage: 0.5,
        borderRadius: 99,
      },
      {
        label: "Early",
        backgroundColor: palette["primary-light"],
        borderColor: palette["primary-light"],
        hoverBackgroundColor: palette["primary-light"],
        hoverBorderColor: palette["primary-light"],
        data: [0, earlyData, 0, 0, 0, 0],
        barPercentage: 0.325,
        categoryPercentage: 0.5,
        borderRadius: 99,
      },
      {
        label: "On Time",
        backgroundColor: palette.success,
        borderColor: palette.success,
        hoverBackgroundColor: palette.success,
        hoverBorderColor: palette.success,
        data: [0, 0, onTime, 0, 0, 0],
        barPercentage: 0.325,
        categoryPercentage: 0.5,
        borderRadius: 99,
      },
      {
        label: "Late",
        backgroundColor: palette.warning,
        borderColor: palette.warning,
        hoverBackgroundColor: palette.warning,
        hoverBorderColor: palette.warning,
        data: [0, 0, 0, late, 0, 0],
        barPercentage: 0.325,
        categoryPercentage: 0.5,
        borderRadius: 99,
      },
      {
        label: "Excessively Late",
        backgroundColor: palette.danger,
        borderColor: palette.danger,
        hoverBackgroundColor: palette.danger,
        hoverBorderColor: palette.danger,
        data: [0, 0, 0, 0, exLate, 0],
        barPercentage: 0.325,
        categoryPercentage: 0.5,
        borderRadius: 99,
      },
      {
        label: "No Time Recorded",
        backgroundColor: palette["gray-300"],
        borderColor: palette["gray-300"],
        hoverBackgroundColor: palette["gray-300"],
        hoverBorderColor: palette["gray-300"],
        data: [0, 0, 0, 0, 0, none],
        barPercentage: 0.325,
        categoryPercentage: 0.5,
        borderRadius: 99,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 20,
        },
        stacked: true,
      },
      x: {
        grid: {
          color: "transparent",
        },
        stacked: true,
      },
    },
  };
  return (
    <div className="chart chart-lg">
      {isLoading && <ButtonSpinner />}
      {!isLoading && <Bar data={data} options={options} />}
    </div>
  )
}