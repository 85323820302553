/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useCallback } from 'react'
import Select from 'react-select'
import { FormLabel } from 'react-bootstrap';

function SelectDetailLabel({ label, detail }: any) {
  return (
    <div>
      <div>{label}</div>
      {detail && (
        <small style={{ color: '#888' }}>{detail}</small>
      )}
    </div>
  );
};

type Option = {
  value: string
  label: string
}

type SelectInputProps = {
  labelText?: string
  placeholder?: string
  disabled?: boolean
  name: string
  page: string
  value: string | string[]
  options: Option[]
  shouldHaveMargin?: boolean
  multiple?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  handleChange: (name: string, value: string | string[]) => void
  dataCy?: string
}

function SelectInput({
  labelText = '',
  placeholder = 'Search...',
  disabled = false,
  name,
  page,
  value,
  options = [],
  shouldHaveMargin = true,
  multiple = false,
  isClearable = true,
  isSearchable = true,
  handleChange,
  dataCy,
}: SelectInputProps) {
  const onChange: any = useCallback(
    (selectValue: Option | Option[] | null) => {
      if (multiple) {
        handleChange(
          name,
          Array.isArray(selectValue) ? selectValue.map((item) => item.value) : []
        )
      } else {
        handleChange(name, selectValue ? (selectValue as Option).value : '')
      }
    },
    [multiple, name, handleChange]
  )

  const selectValue = useMemo(() => {
    if (multiple) {
      return options.filter((option) => (value as string[])?.includes(option.value))
    }
    return options.find((option) => option.value === value) || null
  }, [multiple, options, value])

  return (
    <div className={`${shouldHaveMargin ? 'mb-3' : ''}`} data-cy={dataCy}>
      {labelText && (
        <FormLabel htmlFor={`${page}-${name}__input`}>
          <h5>{labelText}</h5>
        </FormLabel>
      )}
      <Select
        id={`${page}-${name}__select`}
        instanceId={`${page}-${name}`}
        inputId={`${page}-${name}__input`}
        className={`react-select-container ${name}`}
        classNamePrefix="react-select"
        isClearable={isClearable}
        isDisabled={disabled}
        isSearchable={isSearchable}
        isMulti={multiple}
        options={options}
        placeholder={placeholder}
        formatOptionLabel={SelectDetailLabel}
        value={selectValue}
        onChange={onChange}
      />
    </div>
  )
}

export default SelectInput;