/* eslint-disable */
import { Move } from '../../models/trip.models';
import { TrailerState, MoveState } from './trailerChanges.models';
import { EntityContainer } from '../../models/core.models';

export const mapInitialTrailerState = (moves: Move[]) => {
  const container: EntityContainer<MoveState> = {}
  const moveState = moves.reduce((store, move) => {
    const trailerIds = move.data.trailer_ids || [];
    const shipmentIds = move.data.shipment_ids || [];
    return {
      ...store,
      [move.entity_id]: {
        original: {
          shipment_ids: shipmentIds,
          trailer_ids: trailerIds
        },
        current: {
          shipment_ids: shipmentIds,
          trailer_ids: trailerIds
        },
      }
    }
  }, container);
  return moveState;
};

export class TrailerStateHashTable {
  private table: Map<string, TrailerState>;

  constructor(initialData?: TrailerState[]) {
    this.table = new Map();

    // If initialData is provided, add each task group to the hash table
    if (initialData && Array.isArray(initialData)) {
      initialData.forEach((trailers) => this.add(trailers));
    }
  }

  // Optimized function to generate a hash for an array of strings
  private static generateArrayHash(arr: string[]): number {
    return arr.reduce((acc, val) => {
      for (let i = 0; i < val.length; i++) {
        acc = (acc * 31 + val.charCodeAt(i)) % 1_000_000_007;
      }
      return acc;
    }, 0);
  }

  // Generate a unique key based on hashed taskIds and groupIds
  static generateKey(trailerState: TrailerState): string {
    // Sort the arrays once (O(n log n)) and cache the hash for optimized comparisons
    const sortedTrailerIdsHash = this.generateArrayHash(trailerState.trailer_ids.slice().sort());
    const sortedShipmentIdsHash = this.generateArrayHash(trailerState.shipment_ids.slice().sort());
    return `${sortedTrailerIdsHash}-${sortedShipmentIdsHash}`;
  }

  // Add task group to the hash table
  public add(trailerState: TrailerState): void {
    const key = TrailerStateHashTable.generateKey(trailerState);
    if (!this.table.has(key)) {
      this.table.set(key, trailerState);
    }
  }

  // Check if the task group exists
  public exists(trailerState: TrailerState): boolean {
    const key = TrailerStateHashTable.generateKey(trailerState);
    return this.table.has(key);
  }

  // Get the task group by the generated key
  public get(trailerState: TrailerState): TrailerState | undefined {
    const key = TrailerStateHashTable.generateKey(trailerState);
    return this.table.get(key);
  }

  public getByKey(key: string): TrailerState | undefined {
    return this.table.get(key);
  }

  // Remove task group from the hash table
  public remove(trailerState: TrailerState): void {
    const key = TrailerStateHashTable.generateKey(trailerState);
    this.table.delete(key);
  }

  // Get all stored trailer states
  public getAll(): TrailerState[] {
    return Array.from(this.table.values());
  }

  public getFirst(): TrailerState | null {
    const all = this.getAll();
    if (all.length === 0) return null;
    return all[0];
  }
}
