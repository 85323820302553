import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Permission from '../shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../redux/models/feature.flags.models';

const LOGOUT = 'Logout';
const HLP = 'https://dispatchdriven.notion.site/120e8a0caf328096bd16dc61ee8923ba?v=120e8a0caf3281d58160000cc26b4c23';
const WritePermissions = {
  [FeatureResource.Trip]: ResourcePermission.Write,
  [FeatureResource.Shipment]: ResourcePermission.Write,
};

// const IntegrationPermissions = {
//   [FeatureResource.Integration]: ResourcePermission.Write,
// };

function NavbarUserDropdown() {
  const { user, signOut } = useAuth();
  const { orgCode } = useParams();
  const signOutHandler = () => signOut();
  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            data-cy="user-avatar"
            src={user.avatar || ''}
            className="avatar img-fluid rounded-circle me-1"
            alt={user.displayName || 'profile picture'}
          />
          <span id="user-profile__btn" className="text-dark">{user.displayName}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Permission resources={WritePermissions}>
          <Dropdown.Item href={`/${orgCode}/settings`}>
            Settings
          </Dropdown.Item>
        </Permission>
        {/* <Permission resources={IntegrationPermissions}>
          <Dropdown.Item href={`/${orgCode}/integrations`}>
            Integrations
          </Dropdown.Item>
        </Permission> */}
        <Permission resources={WritePermissions}>
          <Dropdown.Item
            data-cy="help-centre"
            href={HLP}
            target="_blank"
            extlink
          >
            Help Centre
          </Dropdown.Item>
        </Permission>
        <Dropdown.Item
          data-cy="report-issue"
          href="https://forms.gle/GJ8Y8cAmqVZHa3Ty6"
          target="_blank"
          extlink
        >
          Report Issue
        </Dropdown.Item>
        <Dropdown.Item href="/projects?load=true">
          Organizations
        </Dropdown.Item>
        <Dropdown.Item data-cy="user-profile_logout__btn" id="user-profile_logout__btn" onClick={signOutHandler}>
          {LOGOUT}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default NavbarUserDropdown;
