import React from 'react';
import { Form } from 'react-bootstrap';

interface RadioProps {
  id: string;
  label: string;
  dataCy?: string;
  onChange: (id: string, value: string) => void;
}

function RadioInput({ id, label, dataCy, onChange }: RadioProps) {
  return (
    <Form.Check
      type="radio"
      id={`duplicate-trip-${id}`}
      name="duplicate-trip"
      label={label}
      value={id}
      data-cy={dataCy}
      onChange={(e) => onChange(e.target.id, e.target.value)}
    />
  );
}

RadioInput.defaultProps = {
  dataCy: '',
};

export default RadioInput;