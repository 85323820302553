import { useEffect, useState } from 'react';
import TripSummary from './TripSummary';
import Loader from '../../../../components/Loader';
import {
  loadShipmentDetails,
} from '../../../../redux/slices/shipments';
import { Shipment } from '../../../../redux/models/shipment.models';
import Analytics from '../../../../utils/analytics';

interface Props {
  shipmentId: string;
  isEditable: boolean;
}

export default function TripSummaryContainer({ shipmentId, isEditable }: Props) {
  const [loading, setLoading] = useState(false);
  const [shipment, setShipment] = useState<Shipment | null>(null);
  const handleSave = () => {};
  useEffect(() => {
    const load = async (id: string) => {
      try {
        setLoading(true);
        const response = await loadShipmentDetails(id);
        if (response.status === 200) setShipment(response.data.data);
      } catch (error) {
        Analytics.capture(error);
      } finally {
        setLoading(false);
      }
    };
    load(shipmentId);
  }, [shipmentId]);

  return (
    <>
      {loading && <Loader />}
      {!loading && shipment && (
        <TripSummary
          shipmentDetails={shipment}
          isEditable={isEditable}
          onSave={handleSave}
        />
      )}
    </>
  )
}
