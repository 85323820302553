import React from 'react';
import { useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';
import { Provider } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';

import { store } from './redux/store';

import './i18n';
import routes from './routes';

import { ThemeProvider } from './contexts/ThemeContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { LayoutProvider } from './contexts/LayoutContext';

import { AuthProvider } from './contexts/Auth0Context';
import MaintenancePage from './pages/maintenance';
import NotificationBar from './components/notifications/NotificationBar';
import ChartJsDefaults from './utils/ChartJsDefaults';

const appName = process.env.REACT_APP_NAME;
const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
  const content = useSentryRoutes(routes);
  const maintenance = process.env.REACT_APP_APP_MAINTENANCE_MODE_STATUS;
  if (maintenance === 'in-progress') {
    return <MaintenancePage />
  }
  return (
    <HelmetProvider>
      <Helmet titleTemplate={`%s | ${appName}`} defaultTitle={appName} />
      <Provider store={store}>
        <NotificationBar />
        <ChartJsDefaults />
        <ThemeProvider>
          <SidebarProvider>
            <LayoutProvider>
              <AuthProvider>
                {content}
              </AuthProvider>
            </LayoutProvider>
          </SidebarProvider>
        </ThemeProvider>
        <ToastContainer />
      </Provider>
    </HelmetProvider>
  );
}

export default App;
