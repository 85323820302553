import { Button, ListGroup, Row, Stack } from "react-bootstrap";
import { BoardSettings } from "../../../redux/models/board.models";
import { EntityContainer } from "../../../redux/models/core.models";
import { DriverAvailability } from "../../../redux/models/driver.availability.models";
import { Driver } from "../../../redux/models/settings.models";
import { TripBoardModel } from "../../../redux/models/trip.models";
import { DriverFilters, TripPlan } from "../../../redux/slices/planner/planner.models";
import TripPlannerDriverToggle from "./TripPlannerDriverToggle";

interface Props {
  availability: DriverAvailability[];
  boardSettings: BoardSettings;
  drivers: Driver[];
  filters: DriverFilters;
  plan: TripPlan;
  tripStore: EntityContainer<TripBoardModel>;
  title: string;
  onSelect: (driverId: string, checked: boolean) => void;
  onSelectAll: () => void;
  onRemoveAll: () => void;
}

export default function TripPlannerFilterDrivers({
  availability, boardSettings, drivers, filters, plan, tripStore, title, onSelect, onSelectAll, onRemoveAll
}: Props) {
  return (
    <Row className="mb-4">
      <Stack direction="horizontal">
        <h4>{title}</h4>
        <Button onClick={onSelectAll} variant="link"><h6>Select All</h6></Button>
        <Button onClick={onRemoveAll} variant="link"><h6>Remove All</h6></Button>
      </Stack>
      <ListGroup>
        {drivers.map((dr) => {
          const driverAv = availability.filter((av) => av.data.driver?.entity_id === dr.entity_id);
          const yard = boardSettings.addresses[dr.data.home_yard_id || '']?.data.samsara_name || '';
          return (
            <ListGroup.Item key={dr.entity_id}>
              <TripPlannerDriverToggle
                availability={driverAv}
                driver={dr}
                filters={filters}
                plan={plan}
                tripStore={tripStore}
                yard={yard}
                handleSelectDriver={onSelect}
              />
            </ListGroup.Item>
          )
        })}
      </ListGroup>
    </Row>
  )
}