import React from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import Permission from '../../../../components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../../../redux/models/feature.flags.models';
import { ShipmentWritePermissions } from '../../../../components/shared/permissions/permissions.utils';

const WriteTripTemplatePermissions = {
  [FeatureResource.TripTemplate]: ResourcePermission.Write,
};

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  onTemplate: () => void;
}

export default function ShipmentMenu({ onDelete, onDuplicate, onTemplate }: Props) {
  return (
    <DropdownButton
      as={ButtonGroup}
      id="dropdown-button-drop"
      size="sm"
      variant="light"
      title="•••"
      data-cy="shipment_options_menu_button"
    >
      <Permission resources={ShipmentWritePermissions}>
        <Dropdown.Item onClick={onDuplicate}>Duplicate</Dropdown.Item>
      </Permission>
      <Permission resources={WriteTripTemplatePermissions}>
        <Dropdown.Item onClick={onTemplate}>Create Template</Dropdown.Item>
      </Permission>
      <Dropdown.Divider />
      <Permission resources={ShipmentWritePermissions}>
        <Dropdown.Item onClick={onDelete} data-cy="shipment_options_menu_delete_button">Delete</Dropdown.Item>
      </Permission>
    </DropdownButton>
  )
};
