import { TripPlanData } from '../../redux/slices/planner/planner.models';
import axiosInstance from '../../utils/axios';
import { EntityServiceProvider } from '../base/EntityServiceProvider';
import { ApiQuery, ElementType, QueryOperator, FilterOp } from '../../redux/models/network.models';
import { DataModel } from '../../redux/models/core.models';
import { TripPlanDispatchProps } from './TripPlanApi.models';

class TripPlanProvider<T> extends EntityServiceProvider<T> {
  fetchDrafts(boardId: string, from: string, to: string) {
    const query: ApiQuery = {
      filters: [
        {
          "field": {
            "name": "data.start_date",
            "type": ElementType.DateTime
          },
          "op": FilterOp.Equals,
          "value": from,
        },
        {
          "field": {
            "name": "data.end_date",
            "type": ElementType.DateTime
          },
          "op": FilterOp.Equals,
          "value": to,
        },
        {
          "field": {
            "name": "data.board_id",
            "type": ElementType.Rel
          },
          "op": FilterOp.Equals,
          "value": boardId,
        }
      ],
      operator: QueryOperator.And,
    }
    return this.find(query);
  }

  applyDraft(plan: DataModel<T>) {
    return this.api.post(`${this.endpoint.entityPath()}/assign/${plan.entity_id}`, plan);
  }

  dispatchDraft(plan: DataModel<T>, dispatch: TripPlanDispatchProps) {
    return this.api.post(`${this.endpoint.entityPath()}/assign/dispatch/${plan.entity_id}`, dispatch);
  }

  optimise(plan: DataModel<T>, driverIds: string[], greedy: boolean) {
    const data = {
      trip_plan_id: plan.entity_id,
      driver_ids: driverIds,
      greedy
    };
    return this.api.post(`${this.endpoint.entityPath()}/create`, data);
  }
}

const TripPlanApi = new TripPlanProvider<TripPlanData>('trip-plan', axiosInstance);

export default TripPlanApi;