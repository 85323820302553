/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button, Card, Stack, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ControlInput } from '../../components/shared/Input';
import Permission from '../../components/shared/permissions/Permission';
import {
  BillingItemReadPermissions, BillingItemWritePermissions
} from '../../components/shared/permissions/permissions.utils';
import { getSettingByName, selectBillingItems, selectContacts } from '../../redux/slices/settings';
import { Api } from '../../services/services';
import useAppDispatch from '../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../constants/core.constants';
import { BillingItemData, BillingType, BillingTypeData } from '../../redux/models/billing.models';
import { DataModel } from '../../redux/models/core.models';
import {
  FuelSurchargeType, FuelSurchargeTypeData,
} from '../../redux/models/settings.models';
import { createDropdownOptions } from '../../utils/core.utils';
import AsyncButton from '../../components/shared/buttons/AsyncButton';
import Loader from '../../components/Loader';
import DeleteModal from '../../components/modals/DeleteModal';
import { initialBillingTypeData } from '../../components/entities/billing-types/data';
import { Contact, ContactData } from '../../redux/models/contact.models';
import ContactInput from '../../components/entities/contacts/ContactInput';
import BillingItemInput from '../../components/entities/billing-items/BillingItemInput';
import AccessorialInput from '../../components/entities/billing-items/AccessorialInput';
import FuelSurchargeTypeInput from '../../components/entities/fuel-surcharge-type/FuelSurchargeTypeInput';
import BillingItemModal from '../../components/entities/billing-items/BillingItemModal';
import BreadCrumbs, { BreadCrumbRoute } from '../../components/shared/breadcrumbs/BreadCrumbs';

const PAGE = 'add_billing_billing_type_details';
const getRoutes = (
  orgCode: string | undefined,
  customer: Contact | null,
  template: BillingType | null,
): BreadCrumbRoute[] => {
  const routes = [
    {
      name: 'Settings',
      route: `/${orgCode || ''}/settings`,
    },
    {
      name: 'Contacts',
      route: `/${orgCode}/settings/contacts`,
    },
  ];
  if (customer && template) {
    routes.push({
      name: `${customer.data.name}`,
      route: `/${orgCode}/settings/contacts/${customer.entity_id}`,
    });
    routes.push({
      name: `${template.data.name}`,
      route: `/${orgCode}/settings/load-types/${customer.entity_id}/${template.entity_id}`,
    });
  }
  return routes;
};

function BillingTypeDetails() {
  const dispatch = useAppDispatch();
  const { orgCode, customerId, entityId } = useParams();
  const navigate = useNavigate();
  const contacts = useSelector(selectContacts);
  const rates = useSelector(selectBillingItems);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [customer, setCustomer] = useState<Contact | null>(null);
  const [type, setType] = useState<BillingType | null>(null);
  const [fscs, setFscs] = useState<FuelSurchargeType[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [editBaseRate, setEditBaseRate] = useState(false);
  const initialAccessorials = type?.data?.accessorials || [];
  const initialAccessorialIds = initialAccessorials.map((acc) => acc.entity_id);
  const [name, setName] = useState(type?.data?.name || '');
  const [billToId, setBillToId] = useState(type?.data?.bill_to?.entity_id || '');
  const [baseRateId, setBaseRateId] = useState(type?.data?.base_rate?.entity_id || '');
  const [fscId, setFscId] = useState(type?.data?.fuel_surcharge_type?.entity_id || '');
  const [accessorialIds, setAccessorialIds] = useState(initialAccessorialIds);
  const isDisabled = name === '' || billToId === '' || baseRateId === '' || fscId === '';
  const accessorials = rates.filter((acc) => acc.data.accessorial);
  const contactOptions = createDropdownOptions(contacts);
  const rateOptions = createDropdownOptions(rates, 'description');
  const accessorialOptions = createDropdownOptions(accessorials, 'description');
  const fscOptions = createDropdownOptions(fscs);
  const baseRate = rates.find((rate) => rate.entity_id === baseRateId) || null;

  const getEntityData = (data: BillingTypeData): BillingTypeData => {
    const parentId = type?.data.customer?.entity_id || customerId;
    const accs: any = accessorialIds
      .map((id) => rates.find((rate) => rate.entity_id === id))
      .filter((item) => item);
    return {
      ...data,
      name,
      customer: contacts.find((contact) => contact.entity_id === parentId) || null,
      bill_to: contacts.find((contact) => contact.entity_id === billToId) || null,
      base_rate: baseRate,
      fuel_surcharge_type: fscs.find((fsc) => fsc.entity_id === fscId),
      accessorials: accs,
    };
  };
  const handleChange = (prop: string, val: string) => {
    if (prop === 'name') setName(val);
    if (prop === 'customer_id') setBillToId(val);
    if (prop === 'base_rate_id') setBaseRateId(val);
    if (prop === 'fuel_surcharge_type_id') setFscId(val);
  };
  const handleAccChange = (_: string, val: any) => {
    setAccessorialIds(val);
  };
  const handleNewContact = (_: string, entity: DataModel<ContactData>) => {
    setBillToId(entity.entity_id);
  };
  const handleNewFSC = (_: string, entity: DataModel<FuelSurchargeTypeData>) => {
    setFscId(entity.entity_id);
  };
  const handleCreate = async () => {
    const entityData = getEntityData(initialBillingTypeData);
    return Api.BillingTypes.create(entityData);
  };
  const handleUpdate = () => {
    if (!type) {
      throw Error('missing billing type');
    }
    const entity: BillingType = {
      ...type,
      data: {
        ...getEntityData(type.data),
      },
    };
    return Api.BillingTypes.update(entity);
  };

  const handleClose = () => navigate(`/${orgCode}/settings/contacts/${customerId}`);

  const handleDelete = async () => {
    if (!type) return;
    try {
      setIsDeleting(true);
      const response = await Api.BillingTypes.delete(type);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.BILLING_TYPES, false));
        toast(`${name} successfully deleted.`, { type: 'success' });
        handleClose();
      }
    } catch (error) {
      toast(`Couldn't delete ${name}`, { type: 'error' });
    } finally {
      setIsDeleting(false);
    }
  };
  const handleResponse = async (response: AxiosResponse<any, any>) => {
    if (response.status === 200) {
      await dispatch(getSettingByName(SETTINGS_NAMES.BILLING_TYPES, false));
      toast("Quote Template Updated", { type: 'success' });
    }
  }
  const handleNewBaseRate = async (_: string, entity: DataModel<BillingItemData>) => {
    setBaseRateId(entity.entity_id);
    if (type) {
      await handleUpdate();
    } else {
      await handleCreate();
    }
    navigate(`/${orgCode}/settings/rates/${entity.entity_id}`);
  };
  const handleSave = async () => {
    if (isDisabled) return;
    try {
      setIsSaving(true);
      if (type) {
        const response = await handleUpdate();
        handleResponse(response);
      } else {
        const response = await handleCreate();
        handleResponse(response);
      }
      handleClose();
    } catch (error: any) {
      const message = error?.description || "Couldn't Update Quote Template.";
      toast(message, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  useEffect(() => {
    const load = async (id: string, contactId: string) => {
      try {
        setIsLoading(true);
        
        const response = await Api.FuelSurchargeTypes.find();
        if (response.status === 200) {
          const items = response.data.data.items || [];
          console.log(items);
          setFscs(items);
        }
        if (id !== 'new') {
          const typeResponse = await Api.BillingTypes.getById(id);
          const contactResponse = await Api.Contacts.getById(contactId);
          if (contactResponse.status === 200) {
            const contactData: Contact = contactResponse.data.data;
            setCustomer(contactData);
          }
          if (typeResponse.status === 200) {
            const typeData: BillingType = typeResponse.data.data;
            setType(typeData);
            const initialAccessorialData = typeData?.data?.accessorials || [];
            const initialAccessorialDataIds = initialAccessorialData.map((acc) => acc.entity_id);
            setName(typeData?.data?.name || '');
            setBillToId(typeData?.data?.bill_to?.entity_id || '');
            setBaseRateId(typeData?.data?.base_rate?.entity_id || '');
            setFscId(typeData?.data?.fuel_surcharge_type?.entity_id || '');
            setAccessorialIds(initialAccessorialDataIds);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (entityId && customerId) load(entityId, customerId);
  }, []);
  return (
    <Permission resources={BillingItemReadPermissions}>
      <>
        <BreadCrumbs routes={getRoutes(orgCode, customer, type)} />
        <Card>
          <Card.Header as="h4">Quote Template</Card.Header>
          <Card.Body>
            {isLoading && <Loader />}
            {!isLoading && (
              <Stack>
                <ControlInput
                  dataCy={`${PAGE}_name_input`}
                  name="name"
                  type="text"
                  page={PAGE}
                  value={name}
                  labelText="Name"
                  handleChange={handleChange}
                />
                <ContactInput
                  value={billToId}
                  page={PAGE}
                  prop="customer_id"
                  label="Bill To"
                  disabled={false}
                  options={contactOptions}
                  onChange={handleChange}
                  onNewEntity={handleNewContact}
                />
                <Stack direction="horizontal" gap={2}>
                  <Col md={baseRate ? 11 : 12}>
                    <BillingItemInput
                      value={baseRateId}
                      page={PAGE}
                      prop="base_rate_id"
                      label="Base Rate"
                      disabled={false}
                      options={rateOptions}
                      allowAdd
                      onChange={handleChange}
                      onNewEntity={handleNewBaseRate}
                    />
                  </Col>
                  {baseRate && (
                    <Button onClick={() => setEditBaseRate(true)}>Edit</Button>
                  )}
                  {editBaseRate && baseRate && (
                    <BillingItemModal
                      rate={baseRate}
                      show={editBaseRate}
                      setShow={setEditBaseRate}
                      onUpdate={() => {}}
                    />
                  )}
                </Stack>
                <AccessorialInput
                  value={accessorialIds}
                  page={PAGE}
                  prop="accessorial_ids"
                  options={accessorialOptions}
                  disabled={false}
                  onChange={handleAccChange}
                />
                <FuelSurchargeTypeInput
                  value={fscId}
                  page={PAGE}
                  prop="fuel_surcharge_type_id"
                  label="Fuel Surcharge"
                  disabled={false}
                  options={fscOptions}
                  onChange={handleChange}
                  onNewEntity={handleNewFSC}
                />
              </Stack>
            )}
            {confirmDelete && (
              <DeleteModal
                title="Delete Rate"
                description={`Would you like to delete ${name}?`}
                deleteBtnText="Delete"
                isDeleting={isDeleting}
                shouldShowDeleteModal={confirmDelete}
                deleteItem={handleDelete}
                cancelDeletion={() => setConfirmDelete(false)}
              />
            )}
          </Card.Body>
          {!isLoading && (
            <Card.Footer>
              <Button variant="outline-danger" onClick={() => setConfirmDelete(true)}>Delete</Button>
              <Stack direction="horizontal" gap={3} className="float-end justify-content-end">
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Permission resources={BillingItemWritePermissions}>
                  <AsyncButton
                    title="Save"
                    variant="primary"
                    spinner="light"
                    dataCy={`${PAGE}_save_button`}
                    disabled={isSaving}
                    handleClick={handleSave}
                  />
                </Permission>
              </Stack>
            </Card.Footer>
          )}
        </Card>
      </>
    </Permission>
  )
};

export default BillingTypeDetails;
