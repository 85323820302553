/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/default-param-last */
import { BoardSettings } from '../../../../redux/models/board.models';
import { EntityContainer } from '../../../../redux/models/core.models';
import { Driver, Group } from '../../../../redux/models/settings.models';
import { ShipmentListViewData, TripBoardShipment } from '../../../../redux/models/shipment.models';
import { MoveData, TripBoardModel, TripListView, ReeferSettingModel } from '../../../../redux/models/trip.models';
import { DateService } from '../../../../utils/dateService';
import { ReeferSetting } from '../../../shipments/constants/shipments.constants';
import { isReeferSettingLegacy, isShipmentTemp } from '../../../shipments/utils/shipments.utils';

export const mapTrailerName = (trailerId: string, fleetObject: any) => {
  const trailer = fleetObject[trailerId]?.data || {};
  return trailer?.samsara_name || trailer?.name || '';
}

const formatTrailer = (moves: MoveData[] = [], fleetObject: any) => {
  if (!moves.length || !fleetObject) return '';
  const trailerObj: EntityContainer<string> = {};
  moves.forEach((move: MoveData) => {
    move.trailer_ids.forEach((trailerId) => {
      const trailerName = trailerId && fleetObject ? mapTrailerName(trailerId, fleetObject) : '';
      if (trailerName) trailerObj[trailerName] = trailerName;
    })
  });
  return Object.values(trailerObj).reduce((res, next) => {
    if (res === '') {
      return next;
    }
    return `${res}, ${next}`;
  }, '');
};

const formatRoute = (moves: MoveData[], addresses: EntityContainer<any>) => {
  if (!moves.length || !addresses) return '';
  return moves.reduce((prev, move) => {
    const address = addresses[move.destination_id];
    const name = address ? address?.data?.samsara_name : '';
    if (!prev) {
      return name;
    }
    return `${prev} >> ${name}`;
  }, '');
};

/**
 * 1. If there is an appt selected show that scheduled_arrival_time
 * 2. Default to scheduled_arrival_time on first move
 * @param moves 
 * @returns 
 */
const formatAppointment = (moves: MoveData[]) => {
  if (!moves) return '';
  const preferred = moves.find((mv) => mv.show_appt);
  if (preferred) {
    const preferredStart = preferred.scheduled_arrival_time || '';
    return preferredStart;
  }
  const firstDate = moves.find((mv) => mv.scheduled_arrival_time);
  return firstDate?.scheduled_arrival_time || '';
};

const formatEta = (moves: MoveData[]) => {
  if (!moves) return '';
  const etas = moves.filter((mv) => {
    const { action } = mv;
    return action === 'drop' || action === 'deliver';
  }).map((move) => DateService.getHHmmFormat(move.scheduled_arrival_time));
  return etas.reduce((store, move) => {
    return store === '' ? move : `${store}, ${move}`
  }, '');
};

const formatGroup = (trip: TripListView, groupsObject: EntityContainer<Group>) => {
  if (!groupsObject) return '';
  const group = groupsObject[trip?.data?.group_id];
  return group ? group?.data?.name : '';
};

export const formatDriverName = (driver: Driver) => {
  if (!driver) return '';
  return driver?.data?.samsara_name || driver?.data?.samsara_username || 'No name set';
}

const formatDriver = (trip: TripListView, driversObject: EntityContainer<Driver>) => {
  if (!driversObject) return '';
  const driver = driversObject[trip.data?.driver_id];
  return driver ? formatDriverName(driver) : '';
};

// const formatShipmentProp = (trip: any, prop: any) => {
//   const shipments = trip.data?.shipments || [];
//   if (shipments.length === 0) {
//     return '';
//   }
//   return shipments.reduce((res: string, next: any) => {
//     const text = next && prop && next[prop] ? next[prop] : '';
//     if (res === '') {
//       return text;
//     }
//     return `${res}, ${text}`;
//   }, '');
// };

const formatBoardShipment = (shipment: ShipmentListViewData, boardSettings: BoardSettings): TripBoardShipment => {
  let billingType = '';
  let customer = '';
  let notes = '';
  
  if (shipment.billing_type_id) {
    const billingTypeId = shipment.billing_type_id || '';
    const billingName = boardSettings.billingTypes[billingTypeId]?.data.name || '';
    if (billingType === '') {
      billingType = billingName;
    } else {
      billingType += `, ${billingName}`;
    }
  }
  if (shipment.customer_id) {
    const customerId = shipment.customer_id || '';
    const customerName = boardSettings.contacts[customerId]?.data.name || '';
    if (customer === '') {
      customer = customerName;
    } else {
      customer += `, ${customerName}`;
    }
  }
  
  if (shipment.notes) {
    const noteLen = shipment.notes.length;
    const maxLen = 25;
    const shipNotes = noteLen <= maxLen ? shipment.notes : shipment.notes.slice(0, maxLen);
    if (notes === '') {
      notes = shipNotes;
    } else {
      notes += `, ${shipNotes}`;
    }
  }

  return {
    id: shipment.shipment_id || '',
    billing_type_id: shipment.billing_type_id,
    billingType,
    bol: shipment.bol,
    customer,
    containerNo: shipment.container_no,
    customer_id: shipment.customer_id,
    dangerous_goods: shipment.dangerous_goods,
    dry: shipment.dry,
    notes,
    purchase_order_no: shipment.purchase_order_no,
    shipment_no: shipment.shipment_no || '',
    shipment_status: shipment.status,
    temp: shipment.temp,
  };
};

const formatBoardShipmentList = (trip: TripListView, boardSettings: BoardSettings): TripBoardShipment[] => {
  const shipments = trip.data?.shipments || [];
  return shipments.map((ship) => formatBoardShipment(ship, boardSettings));
};

export const getShipmentTemp = (shipment: ShipmentListViewData): ReeferSettingModel | null => {
  const isTemp = isShipmentTemp(
    shipment.reefer_settings,
    shipment.temp,
    shipment.dry
  );
  if (isTemp === false) return null;
  const isLegacy = isReeferSettingLegacy(shipment.reefer_settings, shipment.dry);
  if (isLegacy) {
    return {
      temp: '',
      setting: ReeferSetting.Dry,
    };
  }
  const setting = shipment.reefer_settings || '';
  return {
    temp: `${shipment.temp}`,
    setting,
  };
};

const formatShipment = (trip: TripListView, boardSettings: BoardSettings) => {
  const shipments = trip.data?.shipments || [];
  const temp: ReeferSettingModel[] = [];
  let billingType = '';
  let bol = '';
  let customer = '';
  let dangerous = false;
  let dry = false;
  let notes = '';
  let purchaseOrder = '';
  let containerNo = '';
  
  shipments.forEach((shipment) => {
    if (shipment.billing_type_id) {
      const billingTypeId = shipment.billing_type_id || '';
      const billingName = boardSettings.billingTypes[billingTypeId]?.data.name || '';
      if (billingType === '') {
        billingType = billingName;
      } else {
        billingType += `, ${billingName}`;
      }
    }
    if (shipment.container_no) {
      if (containerNo === '') {
        containerNo = shipment.container_no;
      } else {
        containerNo += `, ${shipment.container_no}`;
      }
    }
    if (shipment.customer_id) {
      const customerId = shipment.customer_id || '';
      const customerName = boardSettings.contacts[customerId]?.data.name || '';
      if (customer === '') {
        customer = customerName;
      } else {
        customer += `, ${customerName}`;
      }
    }
    if (shipment.dry) {
      dry = true;
    }
    if (shipment.dangerous_goods) {
      dangerous = true;
    }
    const shipmentTemp = getShipmentTemp(shipment);
    if (shipmentTemp) {
      temp.push(shipmentTemp);
    }
    if (shipment.purchase_order_no) {
      if (purchaseOrder === '') {
        purchaseOrder = shipment.purchase_order_no;
      } else {
        purchaseOrder += `, ${shipment.purchase_order_no}`;
      }
    }
    if (shipment.bol) {
      if (bol === '') {
        bol = shipment.bol;
      } else {
        bol += `, ${shipment.bol}`;
      }
    }
    if (shipment.notes) {
      const noteLen = shipment.notes.length;
      const maxLen = 25;
      const shipNotes = noteLen <= maxLen ? shipment.notes : shipment.notes.slice(0, maxLen);
      if (notes === '') {
        notes = shipNotes;
      } else {
        notes += `, ${shipNotes}`;
      }
    }
  });

  return {
    billingType,
    bol,
    containerNo,
    customer,
    dangerous_goods: dangerous,
    dry,
    notes,
    purchase_order_no: purchaseOrder,
    temp,
  };
};

export const formatTripBoardModel = (trip: TripListView, boardSettings: BoardSettings) => {
  const update: TripBoardModel = {
    ...formatShipment(trip, boardSettings),
    actual_route_end_time: trip.data.actual_route_end_time || '',
    actual_route_start_time: trip.data.actual_route_start_time || '',
    id: trip.entity_id || '',
    apt: formatAppointment(trip.data?.moves || []) || '',
    driver: formatDriver(trip, boardSettings?.drivers || {}) || '',
    driverId: trip.data?.driver_id || '',
    eta: formatEta(trip.data?.moves || []) || '',
    group: formatGroup(trip, boardSettings?.groups || {}) || '',
    groupId: trip.data.group_id || '',
    modified_at: trip.modified_at || '',
    moves: trip.data.moves || [],
    route: formatRoute(trip.data.moves || [], boardSettings?.addresses || {}) || '',
    startDate: trip.data.start_date || '',
    status: trip.data.status || '',
    scheduled_route_end_time: trip.data.scheduled_route_end_time || '',
    scheduled_route_start_time: trip.data.scheduled_route_start_time || '',
    shipments: formatBoardShipmentList(trip, boardSettings),
    trailer: formatTrailer(trip.data.moves || [], boardSettings?.fleet || {}) || '',
    version: trip.version || '',
  };
  return update;
};

interface FormatTripsProps { trips: TripListView[]; boardSettings: BoardSettings}

/**
 * Takes Trip entities and formats it into an object to be rendered on the dispatch board tables
 * @param {object} config Object containing trips and boardSettings
 * @returns Returns array of dispatch board trips
 */
export const formatTrips = ({ trips, boardSettings }: FormatTripsProps) => {
  return trips.map((trip) => formatTripBoardModel(trip, boardSettings));
};
