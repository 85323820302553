/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EntityContainer } from '../../../redux/models/core.models';
import { ResourcePermission } from '../../../redux/models/feature.flags.models';
import { isPermissions } from '../../../redux/slices/settings.utils';
import { getPermissions } from '../../../config';
import { DropdownOptionsModel } from '../SelectInput.models';
import CreatableSearchInput from './CreatableSearchInput';
import HopperSearchInput from './HopperSearchInput';

interface Props {
  allowAdd?: boolean;
  disabled: boolean;
  labelText: string;
  name: string;
  page: string;
  value: string;
  options: DropdownOptionsModel[];
  emptyStateOptions: DropdownOptionsModel[];
  shouldHaveMargin?: boolean,
  isClearable?: boolean,
  entity: string;
  dataField: string;
  secondSearch?: string;
  dataCy: string;
  readResources: EntityContainer<ResourcePermission>;
  writeResources: EntityContainer<ResourcePermission>;
  handleChange: (prop: string, val: string) => void;
  handleCreate?: (prop: string, val: string) => void;
}

function RelInput({
  allowAdd,
  disabled,
  labelText,
  name,
  page,
  value,
  options = [],
  emptyStateOptions = [],
  shouldHaveMargin = true,
  isClearable = true,
  entity,
  dataField,
  secondSearch,
  dataCy,
  readResources,
  writeResources,
  handleChange,
  handleCreate,
}: Props) {
  const { orgCode } = useParams();
  const [readGranted, setReadGranted] = useState(false);
  const [writeGranted, setWriteGranted] = useState(false);

  const onCreate = (prop: string, val: string) => {
    if (handleCreate) handleCreate(prop, val);
  };

  useEffect(() => {
    const permissions = getPermissions();
    const isReadEnabled = isPermissions(orgCode, permissions, readResources);
    const isWriteEnabled = isPermissions(orgCode, permissions, writeResources);
    if (isReadEnabled) setReadGranted(true);
    if (isWriteEnabled) setWriteGranted(true);
  }, [readGranted, orgCode, readResources, writeResources]);

  if (writeGranted && allowAdd) {
    return (
      <CreatableSearchInput
        disabled={disabled}
        labelText={labelText}
        name={name}
        value={value}
        options={options}
        emptyStateOptions={emptyStateOptions}
        shouldHaveMargin={shouldHaveMargin}
        isClearable={isClearable}
        settingName={entity}
        dataField={dataField}
        secondSearch={secondSearch}
        handleChange={handleChange}
        dataCy={dataCy}
        handleCreate={onCreate}
      />
    )
  }

  if (!writeGranted || !allowAdd) {
    return (
      <HopperSearchInput
        disabled={disabled}
        labelText={labelText}
        name={name}
        page={page}
        value={value}
        options={options}
        emptyStateOptions={emptyStateOptions}
        shouldHaveMargin={shouldHaveMargin}
        isClearable={isClearable}
        settingName={entity}
        dataField={dataField}
        handleChange={handleChange}
        dataCy={dataCy}
      />
    )
  }

  if (!writeGranted && !readGranted) {
    return (
      <HopperSearchInput
        disabled // disable if no permissions
        labelText={labelText}
        name={name}
        page={page}
        value={value}
        options={options}
        emptyStateOptions={emptyStateOptions}
        shouldHaveMargin={shouldHaveMargin}
        isClearable={isClearable}
        settingName={entity}
        dataField={dataField}
        handleChange={handleChange}
        dataCy={dataCy}
      />
    )
  }
  
  return (
    <HopperSearchInput
      disabled
      labelText={labelText}
      name={name}
      page={page}
      value={value}
      options={options}
      emptyStateOptions={emptyStateOptions}
      shouldHaveMargin={shouldHaveMargin}
      isClearable={isClearable}
      settingName={entity}
      dataField={dataField}
      handleChange={handleChange}
      dataCy={dataCy}
    />
  );
}

RelInput.defaultProps = {
  allowAdd: false,
  shouldHaveMargin: true,
  isClearable: true,
  secondSearch: undefined,
  handleCreate: () => {},
}

export default RelInput;