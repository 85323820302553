import React from 'react';
import { matchPath } from 'react-router-dom';

import SidebarNavListItem from './SidebarNavListItem';

// eslint-disable-next-line import/no-cycle
import SidebarNavList from './SidebarNavList';

const reduceChildRoutes = ({ items, page, currentRoute }) => {
  if (page.children) {
    const open = page.href
      ? !!matchPath(
        {
          path: page.href,
          end: false,
        },
        currentRoute,
      )
      : false;
    items.push(
      <SidebarNavListItem
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList pages={page.children} />
      </SidebarNavListItem>,
    );
  } else {
    items.push(
      <SidebarNavListItem
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
        target={page.target && page.target}
        extlink={page.extlink && page.extlink}
      />,
    );
  }
  return items;
};

export default reduceChildRoutes;
