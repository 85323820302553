import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AddressReadPermissions, AddressWritePermissions } from '../../shared/permissions/permissions.utils';
import { createDropdownOptions, getSelectedOptions } from '../../../utils/core.utils';
import EntityInput from '../base/inputs/EntityInput';
import { Api } from '../../../services/services';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import { DataModel } from '../../../redux/models/core.models';
import { Address, AddressData } from '../../../redux/models/settings.models';
import { selectAddresses } from '../../../redux/slices/settings';
import AddressModal from './AddressModal';

interface Props {
  value: string;
  page: string;
  prop: string;
  disabled: boolean;
  allowAdd?: boolean;
  label?: string;
  onChange: (prop: string, value: string) => void;
  onNewEntity: (prop: string, vehicle: DataModel<AddressData>) => void;
}

function AddressInput({ value, label, page, prop, disabled, allowAdd, onChange, onNewEntity }: Props) {
  const addresses = useSelector(selectAddresses);
  const options = createDropdownOptions(addresses, 'samsara_name');
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const handleCreateData = (_: string, val: string): AddressData => {
    return {
      port: false,
      samsara_id: '',
      samsara_name: val,
      samsara_latitude: 0,
      samsara_longitude: 0,
    };
  };
  const handleDidCreate = (addr: Address) => {
    onNewEntity(prop, addr);
  };
  const handleSearchNewAddress = (_: string, searchTerm: string) => {
    setSearch(searchTerm);
    setShowSearch(true);
  };
  return (
    <>
      <EntityInput
        disabled={disabled}
        labelText={label || ""}
        name={prop}
        page={page}
        value={value}
        options={getSelectedOptions(value, options)}
        entity="address"
        dataField="samsara_name"
        secondSearch="samsara_formatted_address"
        readResources={AddressReadPermissions}
        writeResources={AddressWritePermissions}
        api={Api.Addresses}
        allowAdd
        customAdd={allowAdd || false}
        settingName={SETTINGS_NAMES.ADDRESSES}
        syncSettings={false}
        onChange={onChange}
        onCreateCustom={handleSearchNewAddress}
        createData={handleCreateData}
        onNewEntity={onNewEntity}
      />
      {showSearch && (
        <AddressModal
          searchTerm={search}
          show={showSearch}
          setShow={setShowSearch}
          onUpdate={handleDidCreate}
        />
      )}
    </>
  )
}

AddressInput.defaultProps = {
  allowAdd: false,
  label: undefined,
}

export default AddressInput;
