import React, { useState, useMemo } from 'react';

import useSettingsState from '../hooks/useSettingsState';

import { SIDEBAR_POSITION, SIDEBAR_BEHAVIOR } from '../constants';

const initialState = {
  isOpen: true,
  position: SIDEBAR_POSITION.LEFT,
  behavior: SIDEBAR_BEHAVIOR.STICKY,
};

const SidebarContext = React.createContext(initialState);

function SidebarProvider({ children }) {
  const [isOpen, setIsOpen] = useState(true);
  const [position, setPosition] = useSettingsState(
    'sidebarPosition',
    SIDEBAR_POSITION.LEFT,
  );
  const [behavior, setBehavior] = useSettingsState(
    'sidebarBehavior',
    SIDEBAR_BEHAVIOR.STICKY,
  );

  const providerValue = useMemo(() => ({
    isOpen,
    setIsOpen,
    position,
    setPosition,
    behavior,
    setBehavior,
  }), [behavior, isOpen, position, setBehavior, setPosition]);

  return (
    <SidebarContext.Provider value={providerValue}>
      {children}
    </SidebarContext.Provider>
  );
}

export { SidebarProvider, SidebarContext };
