/* eslint-disable @typescript-eslint/no-explicit-any */
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  Button, Card, Col, Dropdown, Row,
  Stack,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { DatePickerComponent } from '../../shared/DatePicker';
import { SelectInput, DateInput } from '../../shared/Input';
import { getDefaultDate } from './searchFilter.utils';
import { InvoiceFilterInputs, ShipmentFilterInputs } from './SearchFilterInputs';
import {
  DATE_PARAMS, DATE_RANGE_LABEL, DateRangeType, DEFAULT_DATE_RANGE, FILTER_TEXT,
} from './searchFilters.constants';
import { SearchFilterOption } from './searchFilter.models';
import ContactInput from '../../entities/contacts/ContactInput';
import { filterInvoiceDates } from '../../../redux/slices/invoices';
import { DateService } from '../../../utils/dateService';

const SEARCH = 'Search';
const CLOSE = 'Close';
const RESET = 'Reset';
const APPLY = 'Apply';

interface Props {
  shouldShowFilters: boolean;
  filterParams: any;
  statusOptions: SearchFilterOption[];
  setDateRangeChange: (startDate: Date, endDate: Date, dateFrom: Date, dateTo: Date) => void;
  setFilterParams: (filters: object) => void;
  setShouldShowFilters: (show: boolean) => void;
  formattedItem: object;
  page: string;
  defaultFilters: object;
  customerOptions: SearchFilterOption[];
}

export default function SearchFilters({
  shouldShowFilters,
  filterParams,
  statusOptions,
  setDateRangeChange,
  setFilterParams,
  setShouldShowFilters,
  formattedItem,
  page,
  defaultFilters,
  customerOptions,
}: Props) {
  const dispatch = useDispatch();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const dateOptions = [...Object.values(DATE_PARAMS)];

  const dateLabel = filterParams.dateRange;

  const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);
  const [startDate, endDate] = dateRange;

  const handleSearchFilter = () => {
    setShouldShowFilters(!shouldShowFilters);
    setOpenDatePicker(false);
  };

  const handleDatePicker = () => setOpenDatePicker(!openDatePicker);

  const handleDateRangeChange = () => {
    const [dateFrom, dateTo] = dateRange;
    handleDatePicker();
    handleSearchFilter();
    setDateRangeChange(startDate, endDate, dateFrom, dateTo);
  };

  const handleDateSelect = (selectedValue: string | null) => {
    const selectedOption = dateOptions.find((option) => option.value === selectedValue);
    if (!selectedOption) {
      return;
    }
    if (selectedOption.value === 'custom') {
      setOpenDatePicker(true);
    }
    if (selectedOption.value !== 'custom') {
      setOpenDatePicker(false);
      const selectedDate = getDefaultDate(selectedOption.value);
      const updates = {
        ...filterParams,
        page: 0,
        total: 0,
        dateRange: selectedOption.label,
        dateFrom: selectedDate.dateFrom,
        dateTo: selectedDate.dateTo,
      };
      dispatch(setFilterParams(updates));
    }
  };

  const handleChange = (prop: string, value: string) => {
    const updates = {
      ...filterParams,
      page: 0,
      total: 0,
      [prop]: value,
    };
    dispatch(setFilterParams(updates));
  };

  const handleDateChange = (prop: string, value: string) => {
    const isStart = prop === 'dateFrom';
    const val = isStart ? DateService.getStartOfTheDayISO(value) : DateService.getEndOfTheDayISO(value);
    const updates = {
      ...filterParams,
      page: 0,
      total: 0,
      dateRange: DateRangeType.Custom,
      [prop]: val,
    };
    dispatch(setFilterParams(updates));
  };

  const handleReset = () => {
    dispatch(setFilterParams(defaultFilters));
    handleSearchFilter();
  };

  const handleApply = () => {
    dispatch(filterInvoiceDates(filterParams));
  };
  return (
    <Card className={`search-filter-wrapper ${shouldShowFilters ? '' : 'hidden'}`}>
      <Card.Body>
        <h4>{FILTER_TEXT}</h4>
        <Row>
          <Col md={6}>
            <ContactInput
              page={page}
              options={customerOptions}
              onChange={handleChange}
              value={filterParams.customer}
              disabled={false}
              prop="customer"
              label="Customer"
              allowAdd={false}
              onNewEntity={() => {}}
            />
          </Col>
          <Col md={6}>
            <SelectInput
              dataCy={`${page}_status_select`}
              name="status"
              page={page}
              options={statusOptions}
              value={page === 'Shipments' ? filterParams.status : (filterParams.status)}
              labelText="Status"
              handleChange={handleChange}
            />
          </Col>
        </Row>
        {page === 'Invoices' && (
          <InvoiceFilterInputs
            handleChange={handleChange}
            page={page}
            filterParams={filterParams}
          />
        )}
        {page === 'Shipments' && (
          <ShipmentFilterInputs
            handleChange={handleChange}
            formattedItem={formattedItem}
            page={page}
            filterParams={filterParams}
          />
        )}
        <Row>
          <Col className="filter-datepicker" md={12}>
            <h5>{DATE_RANGE_LABEL}</h5>
            <Dropdown
              onSelect={handleDateSelect}
              data-cy={`${page}_date_select`}
              className="md-2"
            >
              <Dropdown.Toggle
                data-cy={`${page}_date_filter`}
                className="filter-datepicker"
                variant="outline-secondary"
              >
                <FontAwesomeIcon
                  height={10}
                  width={10}
                  icon={faCalendar}
                />
                { ' '}
                {dateLabel || 'Select Date'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {dateOptions.map((option) => (
                  <Dropdown.Item key={option.label} data-cy={`${option.value}_btn`} eventKey={option.value}>
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={6}>
              <DateInput
                labelText="From"
                name="dateFrom"
                page={page}
                max={undefined}
                min={undefined}
                value={DateService.getYYYYMMDDFormat(filterParams.dateFrom)}
                handleChange={handleDateChange}
                dataCy="invoice_list_date_from"
              />
            </Col>
            <Col md={6}>
              <DateInput
                labelText="To"
                name="dateTo"
                page={page}
                max={undefined}
                min={undefined}
                value={DateService.getYYYYMMDDFormat(filterParams.dateTo)}
                handleChange={handleDateChange}
                dataCy="invoice_list_date_to"
              />
            </Col>
        </Row>
        <Row />
        <Row data-cy={`${page}_datepicker`} className={`header-datepicker ${openDatePicker ? '' : 'hidden'}`}>
          <DatePickerComponent
            selectsRange
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            inline
            showMonthDropdown
            showYearDropdown
            onChange={(update: Date[]) => setDateRange(update)}
          />
        </Row>
        <Row>
          <Col className="filters-footer" data-cy={`${page}_filter_footer`}>
            <Button variant="secondary" data-cy={`${page}_close_btn`} onClick={handleSearchFilter}>
              {CLOSE}
            </Button>
            <Button
              variant="primary"
              data-cy={`${page}_search_btn`}
              className={`${openDatePicker ? '' : 'hidden'}`}
              onClick={handleDateRangeChange}
            >
              {SEARCH}
            </Button>
          </Col>
          <Col>
            <div className="mt-2 d-flex justify-content-end">
              <Stack direction="horizontal" gap={2}>
                <Button
                  className="float-end"
                  data-cy={`${page}_reset_btn`}
                  variant="light"
                  onClick={handleReset}
                >
                  {RESET}
                </Button>
                <Button
                  id="search_shipments_table_apply_filter_btn"
                  data-cy="search_shipments_table_apply_filter_btn"
                  variant="primary"
                  onClick={handleApply}
                >
                  {APPLY}
                </Button>
              </Stack>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
