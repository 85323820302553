/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Modal, Button, Container, Table,
} from 'react-bootstrap';
import { INTEGRATIONS_ETA_TABLE_HEADERS } from './integrations.constant';

import './IntegrationsEtaModal.scss';
import { EtaEmail } from '../../redux/models/integration.models';
import { DateService } from '../../utils/dateService';

const getTrailers = (trailers: string[]) => {
  const data = trailers || [];
  if (data.length === 0) return '';
  return data.reduce((store, trailer) => {
    if (store === '') return trailer;
    return `${store}, ${trailer}`;
  }, '');
};

const ETA_EMAIL = 'ETA Email';
const CLOSE = 'Close';

interface Props {
  shouldShowIntegrationsEtaModal: boolean;
  setShouldShowIntegrationsEtaModal: (show: boolean) => void;
  etaEmail: EtaEmail;
}

export default function IntegrationsEtaModal({
  shouldShowIntegrationsEtaModal,
  setShouldShowIntegrationsEtaModal,
  etaEmail,
}: Props) {
  const etaEmailResponseHeaders = etaEmail?.eta_headers || [];
  const etaEmailResponseRows = etaEmail?.eta_rows || [];

  const onClose = () => {
    setShouldShowIntegrationsEtaModal(false);
  };

  return (
    <div>
      <Modal
        show={shouldShowIntegrationsEtaModal}
        onHide={() => setShouldShowIntegrationsEtaModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>{ETA_EMAIL}</Modal.Header>
        <Modal.Body className="text-center m-3">
          <Container id="eta-modal-table-container">
            <Table id="eta-modal-table">
              <thead>
                <tr>
                  {INTEGRATIONS_ETA_TABLE_HEADERS.map((header) => (
                    <th key={header.key}>{header.name}</th>
                  ))}
                  {etaEmailResponseHeaders.map((header) => (
                    <th key={header.entity_id}>{`ETA ${header.name}`}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  etaEmailResponseRows.map((data, idx) => {
                    const etas = data.move_etas || [];
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{getTrailers(data?.trailers)}</td>
                        {
                          etas.map((eta, moveIdx: number) => {
                            const apptStr = eta.appointment;
                            const etaStr = eta.eta;
                            const appt = apptStr ? DateService.getHHmmFormat(apptStr) : '-';
                            const et = etaStr ? DateService.getHHmmFormat(etaStr) : '-';
                            const time = apptStr && etaStr ? `${appt} - ${et}` : '';
                            return (
                              <td key={moveIdx}>{time}</td>
                            )
                          })
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            {CLOSE}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
