import React, { useState } from 'react';
import { Row } from 'react-bootstrap';

import { Document } from '../../../../redux/models/file.models';
import AssignDocModal from './AssignDocModal';
import { DropdownOption } from './AssignDocModal.utils';
import DocumentsGrid from '../../../../components/documents/DocumentsGrid';

interface Props {
  documents: Document[];
  shipmentIdsDropdownOptions: DropdownOption[];
}

export default function MoveDocuments({ documents, shipmentIdsDropdownOptions }: Props) {
  const [selectedDoc, setSelectedDoc] = useState<Document | null>(null);
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);

  const handleAssignDocument = async (doc: Document, idx: number) => {
    setSelectedIdx(idx);
    setSelectedDoc(doc);
  };
  const handleClose = () => {
    setSelectedIdx(null);
    setSelectedDoc(null);
  }
  return (
    <Row>
      <DocumentsGrid documents={documents} showAssign handleAssignDocument={handleAssignDocument} />
      {selectedDoc && selectedIdx !== null && (
        <AssignDocModal
          shipmentOptions={shipmentIdsDropdownOptions}
          document={selectedDoc}
          documentIdx={selectedIdx}
          shouldShowModal={selectedDoc !== null}
          handleSave={handleClose}
          cancelSave={handleClose}
        />
      )}
    </Row>
  )
}
