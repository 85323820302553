import { Button } from 'react-bootstrap';

export default function CustomToast({ message }) {
  const REFRESH_TEXT = 'Refresh';
  return (
    <>
      <p>{message}</p>
      <Button variant="primary" onClick={() => document.location.reload()}>
        {REFRESH_TEXT}
      </Button>
    </>
  );
}
