/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { TripTableColumns } from '../../../../../redux/models/board.models';

interface Props {
  data: any;
  column: TripTableColumns;
}

export default function DataCell({ data, column }: Props) {
  const item = data[column.key] || '';
  if (Array.isArray(item)) return <td style={{ width: column.width }} />
  return (
    <td style={{ width: column.width }}>
      <div>{item}</div>
    </td>
  )
}
