import { Col } from 'react-bootstrap';

export default function ShipmentCardStatus({ status }: { status: string; }) {
  switch (status) {
    case 'booked':
      return (
        <Col className={`shipment-status ${status}`}><span>{status}</span></Col>
      );
    case 'missing-info':
      return (
        <Col className={`shipment-status ${status}`}><span>{status}</span></Col>
      );
    case 'completed':
      return (
        <Col className={`shipment-status ${status}`}><span>{status}</span></Col>
      );
    case 'invoiced':
      return (
        <Col className={`shipment-status ${status}`}><span>{status}</span></Col>
      );
    case 'training':
      return (
        <Col className={`shipment-status ${status}`}><span>{status}</span></Col>
      );
    default:
      return <div />;
  }
};
