import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card } from "react-bootstrap";
import { Shipment } from "../../../../redux/models/shipment.models";
import { Api } from "../../../../services/services";
import ButtonSpinner from "../../../../components/shared/ButtonSpinner";
import useAuth from "../../../../hooks/useAuth";
import { selectCurrentAuth0User } from "../../../../redux/slices/organization/organisation";
import { AppState } from "../../../../redux/models/state.models";
import ShipmentFeedItem from "./ShipmentFeedItem";

function Feed() {
  const { user } = useAuth();
  const authId = user?.id || '';
  const currentUser = useSelector((state: AppState) => selectCurrentAuth0User(state, authId));
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const isNextPage = shipments.length < total;
  const userEntityId = currentUser?.entity_id || '';

  const loadNextPage = async () => {
    setIsLoading(true);
    const response = await Api.Dashboard.shipmentsRequested(page);
    if (response.status === 200) {
      const shipmentData: Shipment[] = response.data.data.items || [];
      const totalData: number = response.data.data.total_count || 0;
      setShipments([...shipments, ...shipmentData]);
      setTotal(totalData);
      setPage(page + 1);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await Api.Dashboard.shipmentsAssigned(userEntityId, 0);
      if (response.status === 200) {
        const shipmentData: Shipment[] = response.data.data.items || [];
        const totalData: number = response.data.data.total_count || 0;
        setShipments(shipmentData);
        setTotal(totalData);
      }
      setIsLoading(false);
    };
    if (userEntityId && userEntityId !== '') load();
  }, [userEntityId]);
  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        {/* <Badge bg="info" className="float-end">
          Today
        </Badge> */}
        <Card.Title className="mb-0">Assigned Shipment</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <ButtonSpinner />}
        {!isLoading && shipments.length === 0 &&  <small className="text-muted">No assigned shipments</small>}
        <div className="overflow-auto" style={{ maxHeight: '400px' }}>
          {!isLoading && shipments.map((shipment) => {
            return (
              <ShipmentFeedItem key={shipment.entity_id} shipment={shipment} />
            )
          })}
        </div>
        {isNextPage && (
          <div className="d-grid">
            <Button variant="primary" onClick={loadNextPage}>Load more</Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default Feed;
