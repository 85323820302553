import { QueryFilter, ElementType, FilterOp } from "../models/network.models";

const { DateTime, Rel, String } = ElementType;

// ==================== Query Filters ====================

export const getSearchQuery = (prop: string, value: string) => {
  const filter: QueryFilter = {
    field: {
      name: `data.${prop}`,
      type: String,
    },
    op: FilterOp.Regex,
    value,
  };
  return filter;
}

export const getStringQuery = (prop: string, value: string) => {
  const filter: QueryFilter = {
    field: {
      name: `data.${prop}`,
      type: String,
    },
    op: FilterOp.Equals,
    value,
  };
  return filter;
}

export const getFromDateQuery = (prop: string, value: string) => {
  const filter: QueryFilter = {
    field: {
      name: `data.${prop}`,
      type: DateTime,
    },
    op: FilterOp.GreaterThanOrEqual,
    value,
  };
  return filter;
}

export const getToDateQuery = (prop: string, value: string) => {
  const filter: QueryFilter = {
    field: {
      name: `data.${prop}`,
      type: DateTime,
    },
    op: FilterOp.LessThanOrEqual,
    value,
  };
  return filter;
}

export const getRelQuery = (prop: string, value: string) => {
  const filter: QueryFilter = {
    field: {
      // id: element,
      name: `data.${prop}`,
      type: Rel,
    },
    op: FilterOp.Equals,
    value,
  };
  return filter;
}
