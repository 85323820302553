import Analytics from "../../../utils/analytics";

const getErrorPlaceholder = (verb: string) =>
  `Couldn't ${verb} invoices. Please contact support if the problem persists`;

export const getError = (
  e: unknown,
  details = "Couldn't get roles",
  verb = 'get'
): React.ReactText => {
  let em;
  if (e instanceof Error) {
    em = e.message;
    Analytics.capture(em);
  }
  const errorMessage = em ?
    `${details}. ${em}. Please contact support if the problem persists.`
    : getErrorPlaceholder(verb);
  return errorMessage;
};