import { useLocation } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import reduceChildRoutes from './reduceChildRoutes';

function SidebarNavList({ pages }) {
  const router = useLocation();
  const currentRoute = router.pathname;

  return pages.reduce((items, page) => reduceChildRoutes({
    items, page, currentRoute,
  }), []);
}

export default SidebarNavList;
