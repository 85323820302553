/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Card, Spinner, Stack } from 'react-bootstrap';
import {
  getSettingByName, getTripTemplate, selectBoardSettings, updateTripTemplate
} from '../../redux/slices/settings';
import { TripTemplate } from '../../redux/models/settings.models';

import useAppDispatch from '../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../constants/core.constants';
import { ControlInput } from '../../components/shared/Input';
import ShipmentsTable from '../boards/Shipments/ShipmentsTable';
import { SHIPMENT_TABLE_HEADERS, TRIP_TABLE_HEADERS } from '../boards/TripTable/constants/tripTable.constants';
import { formatTripBoardModel, formatTripShipments } from '../boards/TripTable/utils/tripTable.utils';
import { getDefaultDataModel } from '../../redux/models/core.models';
import TripsTable from '../boards/TripTable/TripsTable';
import BreadCrumbs, { BreadCrumbRoute } from '../../components/shared/breadcrumbs/BreadCrumbs';


const getRoutes = (
  orgCode: string | undefined,
  template: TripTemplate | null): BreadCrumbRoute[] => {
  return [
    {
      name: 'Settings',
      route: `/${orgCode || ''}/settings`,
    },
    {
      name: `${template?.data.name || ''}`,
      route: `/${orgCode}/settings/trip-templates/${template?.entity_id || ''}`,
    },
  ];
};

const PARAM_PROPS = {
  name: 'name',
};

const PAGE = 'edit_trip_template_input';

export default function TripTemplateEditor() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode, entityId } = useParams();
  const boardSettings = useSelector(selectBoardSettings);

  const [template, setTemplate] = useState<TripTemplate | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const shipments = template?.data.shipments || [];
  const trips = template?.data.trips || [];
  const boardShipments: any = shipments.map((item: any, idx) => {
    return {
      ...formatTripShipments([item], boardSettings),
      id: `${idx}`,
    }
  });
  const boardTrips = trips
    .map((trip, idx) => {
      return {
        ...getDefaultDataModel(trip),
        entity_id: `${idx}`,
      }
    })
    .map((listView: any) => formatTripBoardModel(listView, boardSettings));

  const handleChange = (prop: string, value: string) => {
    if (!template) return;
    setTemplate({
      ...template,
      data: {
        ...template.data,
        [prop]: value,
      },
    });
  };
  const openTripPage = (id: string) => {
    navigate(`/${orgCode}/settings/trip-templates/${entityId}/trips/${id}`);
  };
  const openShipmentPage = (id: string) => {
    navigate(`/${orgCode}/settings/trip-templates/${entityId}/shipments/${id}`);
  };
  
  const handleClose = () => navigate(-1);
  const handleSave = async () => {
    if (!template) return;
    try {
      setIsSaving(true);
      const response = await updateTripTemplate(template);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.TRIP_TEMPLATES));
        handleClose();
      }
    } catch (error) {
      toast(`Couldn't save ${template?.data.name || 'dispatch template'}`, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  useEffect(() => {
    const handleLoad = async (id: string) => {
      const response = await getTripTemplate(id);
      if (response.status === 200) setTemplate(response.data.data);
      setIsLoading(false);
    }
    if (entityId) handleLoad(entityId);
  }, [entityId]);
  
  if (isLoading) return <Spinner animation="border" variant="light" />;
  return (
    <>
      <BreadCrumbs routes={getRoutes(orgCode, template)} />
      <Card>
        <Card.Header as="h4">Trip Template</Card.Header>
        <Card.Body>
          <ControlInput
            dataCy={`${PAGE}_${PARAM_PROPS.name}`}
            name={PARAM_PROPS.name}
            type="text"
            page={PAGE}
            value={template?.data?.name || ''}
            labelText="Name"
            handleChange={handleChange}
          />
          <h5>Shipments</h5>
          <ShipmentsTable
            trips={{}}
            headers={SHIPMENT_TABLE_HEADERS}
            shipments={boardShipments}
            title="Shipments"
            showDate={false}
            onOpen={openShipmentPage}
          />
          <h5>Trips</h5>
          <TripsTable headers={TRIP_TABLE_HEADERS} trips={boardTrips} title="Trips" onOpen={openTripPage} />
        </Card.Body>
        <Card.Footer>
          <Stack direction="horizontal" gap={3} className="float-end justify-content-end">
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            <Button variant="primary" onClick={handleSave}>Save
              {isSaving && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  style={{ marginLeft: '4px' }}
                />
              )}
            </Button>
          </Stack>
        </Card.Footer>
      </Card>
    </>
  );
}
