/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Stack, Table } from 'react-bootstrap';
import {
  selectAddressesObject, selectCostsObject, selectDriversObject, selectUomObject, selectVehiclesObject
} from '../../../../../redux/slices/settings';
import { ExperimentalBadge } from '../../../../../components/badge/BetaBadge';
import { getShipmentMoves, getTripCost, loadDefaultFuelRate } from '../../../../../components/costs/cost.utils';
import { Shipment } from '../../../../../redux/models/shipment.models';
import ShipmentRevenue from './ShipmentRevenue';
import { getTime } from '../../../../boards/constants/boards.constants';
import { TimeDistanceEstimate, createMoveFromData, createTripFromListViewData } from '../../../../../redux/slices/trips/trips.utils';
import { getTripDistance } from '../../../../../redux/slices/trips/trips';
import { EntityContainer } from '../../../../../redux/models/core.models';

const getDefaultFuelPrice = () => {
  return loadDefaultFuelRate() || 0;
};

interface Props {
  shipment: Shipment;
}

export default function ShipmentCostCard({ shipment }: Props) {
  const defaultFuelPrice = getDefaultFuelPrice();
  const drivers = useSelector(selectDriversObject);
  const vehicles = useSelector(selectVehiclesObject)
  const addresses = useSelector(selectAddressesObject);
  const costs = useSelector(selectCostsObject);
  const uoms = useSelector(selectUomObject);
  
  const [estimates, setEstimates] = useState<EntityContainer<TimeDistanceEstimate>>({})
  const [fuelRate, setFuelRate] = useState(defaultFuelPrice);

  const tripData = shipment.data.trips || [];
  const trips = tripData.map((trip) => createTripFromListViewData(trip, estimates));
  const tripCosts = trips.map((trip) => {
    const moves = trip.data.moves || [];
    const shipmentMoves = getShipmentMoves(moves, shipment.entity_id);
    const sortedMoves = [...shipmentMoves].sort((a, b) => {
      const aPos = a.data.position || 0;
      const bPos = b.data.position || 0;
      return aPos - bPos;
    });
    return getTripCost(
      trip,
      sortedMoves,
      drivers,
      vehicles,
      addresses,
      costs,
      uoms,
      fuelRate,
    );
  });
  const totalShipmentCost = tripCosts.reduce((total, cost) => total + cost.total, 0);
  const handleFuelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRate = Number(e.target.value);
    setFuelRate(newRate);
  };
  useEffect(() => {
    const loadRoutes = async (ship: Shipment) => {
      const container: EntityContainer<TimeDistanceEstimate> = {};
      const trps = ship.data.trips || [];
      for (const trip of trps) {
        const tripMoves = trip.moves || [];
        const moveEntities = tripMoves
          .map((move) => createMoveFromData(move));
        const shipmentMoves = getShipmentMoves(moveEntities, ship.entity_id)
        const estimate = await getTripDistance(shipmentMoves, addresses);
        if (estimate) container[trip.trip_id] = estimate;
      }
      setEstimates(container);
    };
    loadRoutes(shipment);
  }, [shipment, addresses]);
  return (
    <>
      <Stack direction="horizontal" gap={2}>
        <h4>Projected Costs</h4>
        <ExperimentalBadge />
      </Stack>
      <Card>
        <Card.Body>
          <Stack>
            <p>Fuel Price</p>
            <input value={fuelRate} type="number" min={0} onChange={handleFuelChange} />
          </Stack>
          <Table bordered responsive className="mt-2">
            <thead>
              <tr>
                <th>Trip</th>
                <th>Route</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {trips.map((trip) => {
                const moves = trip.data.moves || [];
                const shipmentMoves = getShipmentMoves(moves, shipment.entity_id);
                const sortedMoves = [...shipmentMoves].sort((a, b) => {
                  const aTime = a.data.scheduled_arrival_time || '';
                  const bTime = b.data.scheduled_arrival_time || '';
                  return getTime(aTime) - getTime(bTime);
                });
                const tripNo = trip.data.trip_no || '';
                const route = moves.sort((a, b) => {
                  const aTime = a.data.scheduled_arrival_time || '';
                  const bTime = b.data.scheduled_arrival_time || '';
                  return getTime(aTime) - getTime(bTime);
                }).reduce((stuff, move) => {
                  const addressId = move.data.destination_id || '';
                  const addr = addresses[addressId]?.data.samsara_name || '';
                  return stuff === '' ? addr : `${stuff} > ${addr}`;
                }, '');
                const cost = getTripCost(
                  trip,
                  sortedMoves,
                  drivers,
                  vehicles,
                  addresses,
                  costs,
                  uoms,
                  fuelRate,
                );
                return (
                  <tr key={trip.entity_id}>
                    <td>{tripNo}</td>
                    <td>{route}</td>
                    <td>{`$${cost.total.toFixed(2)}`}</td>
                  </tr>
                )
              })}
            </tbody>
            <tfoot>
              <tr>
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{`$${(totalShipmentCost).toFixed(2)}`}</td>
              </tr>
            </tfoot>
          </Table>
        </Card.Body>
        <Card.Footer>
          <ShipmentRevenue shipment={shipment} totalCost={totalShipmentCost} />
        </Card.Footer>
      </Card>
    </>
  )
}
