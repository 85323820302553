import React from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { TripPlan } from '../../../../redux/slices/planner/planner.models';
import { saveDraft, setCurrentPlan } from '../../../../redux/slices/planner/planner';
import ApiResponse from '../../../../services/base/ApiResponse';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import AsyncButton from '../../../../components/shared/buttons/AsyncButton';
import Analytics from '../../../../utils/analytics';

interface Props {
  plan: TripPlan;
}

export default function SavePlanButton({ plan }: Props) {
  const dispatch = useAppDispatch();
  const { orgCode } = useParams();
  const handleSavePlan = async () => {
    try {
      const response = await saveDraft(plan);
      const entity = ApiResponse.parseItem(response);
      dispatch(setCurrentPlan(entity));
    } catch (error) {
      if (error instanceof Error) {
        toast(`Error saving plan. ${error.message}`, { type: 'error' });
      }
    } finally {
      Analytics.saveDraft(orgCode);
    }
  };
  return <AsyncButton title="Save" spinner="light" handleClick={handleSavePlan} />
}