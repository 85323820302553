/* eslint-disable @typescript-eslint/no-explicit-any */
export default function NoShipmentMsg(props: any) {
  const { areShipmentsLoading } = props;
  return (
    <div>
      {areShipmentsLoading ? null : (
        <h4 className="text-center">No shipments match these dates. Try searching a new date</h4>
      )}
    </div>
  );
}
