/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';

import { auth0Client } from '../contexts/Auth0Context';

import { LOCAL_STORAGE_TOKEN_KEY, getOrgCode } from '../config';


const pendingRequests = new Map();

const createKey = (config: AxiosRequestConfig<any>) => {
  return `${config.method}-${config.url}-${JSON.stringify(config.params)}-${JSON.stringify(config.data)}`;
};

export const addPendingRequest = (config: AxiosRequestConfig<any>) => {
  const requestKey = createKey(config);
  const updatedConfig = { ...config }; // Create a shallow copy of the config
  updatedConfig.cancelToken = updatedConfig.cancelToken || new axios.CancelToken((cancel) => {
    if (!pendingRequests.has(requestKey)) {
      pendingRequests.set(requestKey, cancel);
    }
  });
  return updatedConfig;
};

export const removePendingRequest = (config: AxiosRequestConfig<any>) => {
  const requestKey = createKey(config);
  if (pendingRequests.has(requestKey)) {
    const cancel = pendingRequests.get(requestKey);
    cancel();
    pendingRequests.delete(requestKey);
  }
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)}`,
      'X-Organization-Code': `${getOrgCode()}`
    };
    // removePendingRequest(config); // Cancel any duplicate requests before making a new one
    // addPendingRequest(config); // Add the new request to the pending requests
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use((response) => {
  // removePendingRequest(response.config); // Remove request from pending requests once it's completed
  return response;
}, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 0 && !originalRequest.retry) {
    originalRequest.retry = true;
    const accessToken = await auth0Client.getTokenSilently();
    window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, accessToken);
    return axiosInstance(originalRequest);
  }
  if (error.response.status === 401) {
    if (error.response.data.code === 'invalid_authorization_header') {
      window.location.href = '/';
    }
  }
  return Promise.reject((error.response && error.response.data) || 'Something went wrong');
});

export default axiosInstance;
