/* eslint-disable */
import React from 'react'
import { Form } from 'react-bootstrap'
import { DropdownOptionsModel } from '../../shared/SelectInput.models';

interface Props {
  value: string;
  options: DropdownOptionsModel[];
  onChange: (value: string) => void;
}

const EditableSelect: React.FC<Props> = ({ value, options, onChange }) => (
  <Form.Select value={value} onChange={(e) => onChange(e.target.value)}>
    <option value="">Select...</option>
    {options.map((option) => {
      return <option key={option.value} value={option.value}>{option.label}</option>
    })}
  </Form.Select>
)

export default EditableSelect;
