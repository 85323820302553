import { EntityContainer } from "../../../redux/models/core.models";

export const INVOICES_FROM_TEXT = 'Invoices from:';
export const INVOICES_TO_TEXT = 'Invoices to:';

interface InvoiceStatus {
  value: string;
  label: string;
}

interface Currency {
  locale: string;
  currency: string;
}

export const INVOICE_STATUS_TYPES: EntityContainer<InvoiceStatus> = {
  DRAFT: {
    value: 'draft',
    label: 'Draft',
  },
  SENT: {
    value: 'sent',
    label: 'Sent',
  },
  PAID: {
    value: 'paid',
    label: 'Paid',
  },
  VOID: {
    value: 'void',
    label: 'Void',
  },
};

export const CURRENCY: EntityContainer<Currency> = {
  USD: {
    locale: 'en-US',
    currency: 'USD',
  },
  CAD: {
    locale: 'en-CA',
    currency: 'CAD',
  },
  GBP: {
    locale: 'en-GB',
    currency: 'GBP',
  },
};
