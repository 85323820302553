import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Driver } from "../../../redux/models/settings.models";
import { DriverFilters, TripPlan } from "../../../redux/slices/planner/planner.models";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { setDriverFilters } from "../../../redux/slices/planner/planner";
import { formatOrgName } from "../../../utils/core.utils";
import { DriverAvailability } from "../../../redux/models/driver.availability.models";
import { BoardSettings } from "../../../redux/models/board.models";
import { selectTrips } from "../../../redux/slices/trips/trips";
import TripPlannerFilterDrivers from "./TripPlannerFilterDrivers";

interface Props {
  availability: DriverAvailability[];
  boardSettings: BoardSettings;
  drivers: Driver[];
  filters: DriverFilters;
  plan: TripPlan;
}

export default function TripPlannerFilters({ availability, boardSettings, drivers, filters, plan }: Props) {
  const dispatch = useAppDispatch();
  const { orgCode } = useParams();
  const tripStore = useSelector(selectTrips);
  const companyDrivers = drivers.filter((dr) => !dr.data.outside_cartage);
  const ocDrivers = drivers.filter((dr) => dr.data.outside_cartage);
  const handleSelectedDrivers = (drvs: Driver[], isSelected: boolean) => {
    const selected = drvs.reduce((store, driver) => {
      return {
        ...store,
        [driver.entity_id]: isSelected,
      };
    }, {});
    const filterData: DriverFilters = {
      ...filters,
      selected: {
        ...filters.selected,
        ...selected
      }
    };
    dispatch(setDriverFilters(filterData));
  };
  const handleSelectCompanyDrivers = () => {
    handleSelectedDrivers(companyDrivers, true);
  };
  const handleRemoveCompanyDrivers = () => {
    handleSelectedDrivers(companyDrivers, false);
  };
  const handleSelectOutsideCartageDrivers = () => {
    handleSelectedDrivers(ocDrivers, true);
  };
  const handleRemoveOutsideCartageDrivers = () => {
    handleSelectedDrivers(ocDrivers, false);
  };
  const handleSelectDriver = (driverId: string, checked: boolean) => {
    const filterData: DriverFilters = {
      ...filters,
      selected: {
        ...filters.selected,
        [driverId]: checked,
      },
      hidden: {
        ...filters.hidden,
        [driverId]: false,
      },
    };
    dispatch(setDriverFilters(filterData));
  };
  return (
    <Card>
      <Card.Header>
        <h3>Filters</h3>
      </Card.Header>
      <Card.Body>
        <TripPlannerFilterDrivers
          availability={availability}
          boardSettings={boardSettings}
          drivers={companyDrivers}
          filters={filters}
          plan={plan}
          tripStore={tripStore}
          title={formatOrgName(orgCode)}
          onSelect={handleSelectDriver}
          onSelectAll={handleSelectCompanyDrivers}
          onRemoveAll={handleRemoveCompanyDrivers}
        />
        <TripPlannerFilterDrivers
          availability={availability}
          boardSettings={boardSettings}
          drivers={ocDrivers}
          filters={filters}
          plan={plan}
          tripStore={tripStore}
          title="Outside Cartage"
          onSelect={handleSelectDriver}
          onSelectAll={handleSelectOutsideCartageDrivers}
          onRemoveAll={handleRemoveOutsideCartageDrivers}
        />
      </Card.Body>
    </Card>
  )
}
