import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Row, Spinner, Stack } from 'react-bootstrap';

import { Api } from '../../../services/services';
import AsyncButton from '../../../components/shared/buttons/AsyncButton';
import { ApiQuery, DEFAULT_API_QUERY, getDataSorting } from '../../../redux/models/network.models';
import { getRelQuery } from '../../../redux/slices/network.utils';
import BreadCrumbs from '../../../components/shared/breadcrumbs/BreadCrumbs';
import { Driver } from '../../../redux/models/settings.models';
import { DriverAvailability } from '../../../redux/models/driver.availability.models';
import DriverDetailsAvailability from '../details/components/DriverDetailsAvailability';
import { EntityContainer } from '../../../redux/models/core.models';
// import { errorToast } from '../../../components/notifications/app-toast';
import { getApplyDriverShiftRoutes, getDriverName } from '../utils/driver-details.utils';
import { selectTagObject } from '../../../redux/slices/settings';
import ApplyDriverShiftTable from './ApplyDriverShiftTable';
import ApplyDriverShiftTags from './ApplyDriverShiftTags';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import Analytics from '../../../utils/analytics';
import { infoToast } from '../../../components/notifications/app-toast';

const PAGE = 'apply_driver_shifts';

export default function ApplyDriverShifts() {
  const { orgCode, entityId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [driver, setDriver] = useState<Driver | null>(null);
  const [availability, setAvailability] = useState<EntityContainer<DriverAvailability>>({});
  const [selectedTags, setSelectedTags] = useState<EntityContainer<boolean>>({});
  const [selectedDrivers, setSelectedDrivers] = useState<EntityContainer<boolean>>({});
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [isSaving] = useState(false);
  const tags = useSelector(selectTagObject);
  const driverName = getDriverName(driver);
  const title = 'Apply Driver Availability';
  const details = `Select drivers and tags below to set the same availability as ${driverName}.`;
  
  const handleClose = () => navigate(`/${orgCode}/settings/drivers/${driver?.entity_id ?? ''}`);
  const handleConfirmSave = () => setShowConfirmSave(true);
  const handleSave = async () => {
    if (!entityId) return;
    try {
      const driverIds = Object.keys(selectedDrivers);
      const tagIds = Object.keys(selectedTags)
      const response = await Api.Availability.applyAvailability(entityId, driverIds, tagIds);
      if (response.status === 200) {
        const items = response.data.data || {};
        infoToast(`Applying availability from ${driverName} to ${Object.keys(items).length} drivers`);
        handleClose();
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setShowConfirmSave(false);
    }
  };

  useEffect(() => {
    const handleLoad = async (id: string) => {
      setIsLoading(true);
      const query: ApiQuery = {
        ...DEFAULT_API_QUERY,
        filters: [
          getRelQuery('driver_id', id),
        ],
        sorting: getDataSorting('weekday'),
      }
      const contactResponse = await Api.Drivers.getById(id);
      const response = await Api.Availability.find(query);
      if (contactResponse.status === 200) {
        const data: Driver | null | undefined = contactResponse.data.data || {};
        if (data) {
          setDriver(data);
        }
      }
      if (response.status === 200) {
        const data: DriverAvailability[] | null | undefined = response.data.data.items || [];
        if (data) {
          const container: EntityContainer<DriverAvailability> = {};
          const av = data.reduce((store, item) => {
            const weekday = item.data.weekday || '';
            if (weekday === '') return store;
            return {
              ...store,
              [weekday]: item,
            };
          }, container);
          setAvailability(av);
        }
      }
      setIsLoading(false);
    }
    if (entityId) handleLoad(entityId);
  }, [entityId]);

  if (isLoading) return <Spinner animation="border" variant="light" />;

  return (
    <>
      <BreadCrumbs routes={getApplyDriverShiftRoutes(orgCode, driver)} />
      <Card>
        <Card.Header as="h4">{title}</Card.Header>
        <Card.Body>
          {isLoading && <Spinner animation="border" variant="primary" />}
          {!isLoading && driver && (
            <Stack>
              <p>{details}</p>
              <DriverDetailsAvailability
                title={`${driverName}'s Availability`}
                availability={availability}
                driver={driver}
                showApply={false}
                setAvailability={setAvailability}
              />
              <Row className="mt-3">
                <ApplyDriverShiftTags
                  container={tags}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                />
              </Row>
              <Row className="mt-3">
                <ApplyDriverShiftTable
                  driver={driver}
                  selectedDrivers={selectedDrivers}
                  selectedTags={selectedTags}
                  tags={tags}
                  setSelectedDrivers={setSelectedDrivers}
                />
              </Row>
            </Stack>
          )}
        </Card.Body>
        {!isLoading && driver && (
          <Card.Footer>
            <Stack direction="horizontal" gap={3} className="float-end justify-content-end">
              <Button variant="secondary" onClick={handleClose}>Cancel</Button>
              <AsyncButton
                disabled={isSaving}
                title="Save"
                variant="primary"
                spinner="light"
                dataCy={`${PAGE}_save_button`}
                handleClick={handleConfirmSave}
              />
            </Stack>
          </Card.Footer>
        )}
      </Card>
      {showConfirmSave && (
        <ConfirmModal
          title="Confirm Availability Updates"
          details={`Would you like to apply ${driverName}'s availability to the selected drivers?`}
          btnTitle="Apply"
          saveDisabled={isSaving}
          shouldShowModal={showConfirmSave}
          isSaving={isSaving}
          handleSave={handleSave}
          cancelSave={() => setShowConfirmSave(false)}
        />
      )}
    </>
  );
}
