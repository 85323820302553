/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import { DateService } from '../../../../utils/dateService';
import { EntityContainer } from '../../../../redux/models/core.models';
import { Address } from '../../../../redux/models/settings.models';
import { DriverTrip } from '../../../../redux/models/driver.availability.models';
import { TripDetails, Move } from '../../../../redux/models/trip.models';
import { Coordinate, DistanceService } from '../../../../utils/distance';

export const getMove = (moves: Move[], index: number) => {
  if (moves.length < index) return null;
  return moves[index];
}

// const DEFAULT_MESSAGE = ' Trip 1: 8:30 - 11:30 DP Yard >> CR Lumber, finishes 28kms from Discovery Park Yard ';
enum TimeComparisonStatus {
  INVALID_DATES = "One or more trip times are invalid",
  A_ENDS_BEFORE_B_STARTS = "Trip A ends before Trip B starts",
  A_STARTS_AFTER_B_ENDS = "Trip A starts after Trip B ends",
  A_ENDS_WHEN_B_STARTS = "Trip A ends when Trip B starts",
  A_STARTS_WHEN_B_ENDS = "Trip A starts when Trip B ends",
  A_HAPPENS_DURING_B = "Trip A happens during Trip B",
  A_AND_B_HAPPEN_SIMULTANEOUSLY = "Trip A and Trip B are happening at the same time",
  NOT_RELATED = "Trips are not related",
}

const { INVALID_DATES, A_ENDS_BEFORE_B_STARTS } = TimeComparisonStatus;

function isConflict(status: TimeComparisonStatus): boolean {
  switch(status) {
    case TimeComparisonStatus.A_HAPPENS_DURING_B:
      return true;
    case TimeComparisonStatus.A_AND_B_HAPPEN_SIMULTANEOUSLY:
      return true;
    default:
      return false;
  }
}

type TripTime = {
  start: Date,
  end: Date
};

function getTripTime(start: string, end: string): TripTime {
  return {
    start: new Date(start),
    end: new Date(end),
  };
};

/**
- Two trips A and B
- A arrives before B departs
- A departs after B arrives
- A and B happen at same time
- A and B Match 
*/
function getTimeComparison(tripA: TripTime, tripB: TripTime): TimeComparisonStatus {
  // Check for invalid dates
  if (isNaN(tripA.start.getTime()) || isNaN(tripA.end.getTime()) || isNaN(tripB.start.getTime()) || isNaN(tripB.end.getTime())) {
    return TimeComparisonStatus.INVALID_DATES;
  }

  if (tripA.end < tripB.start) {
    return TimeComparisonStatus.A_ENDS_BEFORE_B_STARTS;
  }
  
  if (tripA.start > tripB.end) {
    return TimeComparisonStatus.A_STARTS_AFTER_B_ENDS;
  }

  if (tripA.end.getTime() === tripB.start.getTime()) {
    return TimeComparisonStatus.A_ENDS_WHEN_B_STARTS;
  }
  
  if (tripA.start.getTime() === tripB.end.getTime()) {
    return TimeComparisonStatus.A_STARTS_WHEN_B_ENDS;
  }
  
  if (
    (tripA.start >= tripB.start && tripA.start <= tripB.end) ||
    (tripA.end >= tripB.start && tripA.end <= tripB.end) ||
    (tripA.start <= tripB.start && tripA.end >= tripB.end)
  ) {
    return TimeComparisonStatus.A_HAPPENS_DURING_B;
  }
  
  if (tripA.start.getTime() === tripB.start.getTime() && tripA.end.getTime() === tripB.end.getTime()) {
    return TimeComparisonStatus.A_AND_B_HAPPEN_SIMULTANEOUSLY;
  }
  
  return TimeComparisonStatus.NOT_RELATED;
}

// Need to determin possible speeds
function isDrivePossible(distanceInKm: number, timeInMinutes: number, averageSpeedKmPerHour = 60): boolean {
  const timeNeededInHours = distanceInKm / averageSpeedKmPerHour;
  const timeNeededInMinutes = timeNeededInHours * 60;
  return timeInMinutes >= timeNeededInMinutes;
}

export const getIsConflict = (details: TripDetails, roster: DriverTrip, addresses: EntityContainer<Address>) => {
  if (!roster) return 'No other trips ';
  const dep = roster?.data?.departure_address;
  const arr = roster?.data?.arrival_address;
  const moves = [...details.data.moves].sort((a, b) => a.data.position - b.data.position);
  const tripDepart = getMove(moves, 0);
  const tripArrival = getMove(moves, moves.length - 1);
  const tripDepartId = tripDepart?.data?.destination_id || '';
  const tripArrivalId = tripArrival?.data?.destination_id || '';
  const tripDepartAddr = addresses[tripDepartId];
  const tripArrivalAddr = addresses[tripArrivalId];
  const rosterTime = getTripTime(roster?.data?.scheduled_departure_time || '', roster?.data?.scheduled_arrival_time || '');
  const tripTime = getTripTime(tripDepart?.data.scheduled_arrival_time || '', tripArrival?.data?.scheduled_arrival_time || '');
  const comparison = getTimeComparison(tripTime, rosterTime);
  const conflict = isConflict(comparison);
  console.log('Trip timing: ', comparison);
  console.log('Trip conflict: ', conflict);

  let aCoords: Coordinate;
  let bCoords: Coordinate;
  let aTime: string;
  let bTime: string;

  if (comparison === TimeComparisonStatus.A_ENDS_BEFORE_B_STARTS) {
    console.log(TimeComparisonStatus.A_ENDS_BEFORE_B_STARTS);
  }
  if (comparison === A_ENDS_BEFORE_B_STARTS) {
    aTime = tripArrival?.data.scheduled_arrival_time || '';
    bTime = roster.data.scheduled_departure_time;
    aCoords = {
      latitude: tripArrivalAddr?.data?.samsara_latitude || 0.00,
      longitude: tripArrivalAddr?.data?.samsara_longitude || 0.00,
    };
    bCoords = {
      latitude: dep?.data?.samsara_latitude || 0.00,
      longitude: dep?.data?.samsara_longitude || 0.00,
    };
  } else {  
    aTime = tripDepart?.data.scheduled_arrival_time || '';
    bTime = roster.data.scheduled_departure_time; // roster.data.scheduled_arrival_time
    aCoords = {
      latitude: arr?.data?.samsara_latitude || 0.00,
      longitude: arr?.data?.samsara_longitude || 0.00,
    };
    bCoords = {
      latitude: tripDepartAddr?.data?.samsara_latitude || 0.00,
      longitude: tripDepartAddr?.data?.samsara_longitude || 0.00,
    };
  }
  const distance = DistanceService.getDistance(aCoords, bCoords);
  const difference = DateService.getDifferenceBetweenInHours(aTime, bTime);
  const hrs = difference > 0 ? difference : Math.abs(difference);
  return conflict || isDrivePossible(distance, hrs * 60);
}

export const getDistanceMessage = (details: TripDetails, roster: DriverTrip, addresses: EntityContainer<Address>) => {
  if (!roster) return 'No other trips ';
  const dep = roster?.data?.departure_address;
  const arr = roster?.data?.arrival_address;
  const moves = [...details.data.moves].sort((a, b) => a.data.position - b.data.position);
  const tripDepart = getMove(moves, 0);
  const tripArrival = getMove(moves, moves.length - 1);
  const tripDepartId = tripDepart?.data?.destination_id || '';
  const tripArrivalId = tripArrival?.data?.destination_id || '';
  const tripDepartAddr = addresses[tripDepartId];
  const tripArrivalAddr = addresses[tripArrivalId];

  let action: string;
  let aCoords: Coordinate;
  let bCoords: Coordinate;
  let aTime: string;
  let bTime: string;
  let tripAddr: string;
  let differenceVerb: string;

  const rosterTime = getTripTime(roster?.data?.scheduled_departure_time || '', roster?.data?.scheduled_arrival_time || '');
  const tripTime = getTripTime(tripDepart?.data.scheduled_arrival_time || '', tripArrival?.data?.scheduled_arrival_time || '');
  const comparison = getTimeComparison(tripTime, rosterTime);
  if (comparison === INVALID_DATES) return 'Invalid dates';
  if (isConflict(comparison)) return 'Trip happens at the same time';
  if (comparison === A_ENDS_BEFORE_B_STARTS) {
    console.log('Current trip details starts before booking');
    action = 'Trip departs';
    aTime = tripArrival?.data.scheduled_arrival_time || '';
    bTime = roster.data.scheduled_departure_time;
    tripAddr = tripArrivalAddr?.data.samsara_name || '';
    aCoords = {
      latitude: tripArrivalAddr?.data?.samsara_latitude || 0.00,
      longitude: tripArrivalAddr?.data?.samsara_longitude || 0.00,
    };
    bCoords = {
      latitude: dep?.data?.samsara_latitude || 0.00,
      longitude: dep?.data?.samsara_longitude || 0.00,
    };
    action = `Trip departs from ${dep?.data?.samsara_name || ''}`;
    differenceVerb = 'before arriving at';
  } else {
    console.log('Current trip details leaves after booking');
    
    aTime = tripDepart?.data.scheduled_arrival_time || '';
    bTime = roster.data.scheduled_departure_time; // roster.data.scheduled_arrival_time
    tripAddr = tripDepartAddr?.data.samsara_name || '';
    aCoords = {
      latitude: arr?.data?.samsara_latitude || 0.00,
      longitude: arr?.data?.samsara_longitude || 0.00,
    };
    bCoords = {
      latitude: tripDepartAddr?.data?.samsara_latitude || 0.00,
      longitude: tripDepartAddr?.data?.samsara_longitude || 0.00,
    };
    action = `Trip arrives at ${arr?.data?.samsara_name || ''}`;
    differenceVerb = 'before departing from';
  }
  const distance = DistanceService.getDistance(aCoords, bCoords);
  const difference = DateService.getDifferenceBetweenInHours(aTime, bTime);
  const hrs = difference > 0 ? difference : Math.abs(difference);
  return `${action} ${hrs} hrs ${differenceVerb} ${tripAddr}, ${distance.toFixed(0)}km away`;
}

export const getTime = (roster: DriverTrip) => {
  if (!roster) return ' ';
  const departureTime = DateService.getHHmmFormat(roster?.data.scheduled_departure_time || '');
  const arrivalTime = DateService.getHHmmFormat(roster?.data.scheduled_arrival_time || '');
  return ` ${departureTime} - ${arrivalTime} - `;
}

export const getMessage = (details: TripDetails, roster: DriverTrip, addresses: EntityContainer<Address>) => {
  return ` ${getTime(roster)} ${getDistanceMessage(details, roster, addresses)}`
}
