import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import {
  loadingTrailers, selectTrailerChangeTripState
} from '../../../../../redux/slices/trailer-changes/trailerChanges';
import { EntityContainer } from '../../../../../redux/models/core.models';
import { Fleet } from '../../../../../redux/models/settings.models';
import TrailerChangeAlertItem from './TrailerChangeAlertItem';

interface Props {
  name: string;
  handleChangeModals: () => void;
  fleet: EntityContainer<Fleet>;
}
export default function TrailerChangeAlertList({
  name,
  handleChangeModals,
  fleet,
}: Props) {
  const isLoading = useSelector(loadingTrailers);
  const tripState = useSelector(selectTrailerChangeTripState);
  const trips = Object.values(tripState).filter((tr) => tr.trips.length > 0);
  return (
    <>
      {isLoading && <Spinner animation="border" variant="primary" size="sm" />}
      {!isLoading && trips.map((item, idx) => {
        return (
          <TrailerChangeAlertItem
            name={name}
            key={`${name}-alert-${idx}`}
            tripState={item}
            fleet={fleet}
            handleChangeModals={handleChangeModals}
            data-cy={`${name}-alert-${idx}`}
          />
        )
      })}
    </>
  );
}
