/* eslint-disable @typescript-eslint/default-param-last */
import { Badge } from 'react-bootstrap';
import { DangerousGoodsIcon, TempIcon } from '../../icons/ShipmentIcons';
import { DateService } from '../../../utils/dateService';
import TimelinessBadge from '../../../components/timeliness/TimelinessBadge';

const renderDangerousGoods = (isDangerous) => {
  if (!isDangerous) return <div />;
  return <DangerousGoodsIcon />;
};

const formatTemperature = (trip) => {
  const temps = trip.temp || [];
  const tripId = trip.id || '';
  return temps.map((temp, idx) => (
      <TempIcon
        key={`tripsTable-${tripId}_${temp}_${idx}`}
        temp={temp.temp}
        setting={temp.setting}
        dry={trip.dry}
      />
    ));
};

const renderTemperature = (trip) => (
  <>
    {formatTemperature(trip)}
  </>
);

export const renderStatus = (status) => {
  switch (status) {
    case 'ready-to-dispatch':
      return (
        <Badge bg="warning" className="badge-space capitalized">
          Ready To Dispatch
        </Badge>
      );
    case 'dispatched':
      return (
        <Badge bg="info" className="badge-space capitalized">
          {status}
        </Badge>
      );
    case 'driver-confirmed':
      return (
        <Badge bg="primary" className="badge-space capitalized">
          Driver Confirmed
        </Badge>
      );
    case 'complete':
      return (
        <Badge bg="success" className="badge-space capitalized">
          {status}
        </Badge>
      );
    default:
      return <div />;
  }
};

const formatApt = (move, isDateRange) => {
  if (move.schedule_tbd) {
    return <div>TBD</div>
  }
  const moveDateStr = move.scheduled_arrival_time || '';
  const dt = isDateRange ? DateService.getDoMMMHHmmFormat(moveDateStr) : DateService.getHHmmFormat(moveDateStr);
  if (dt.includes('Invalid')) {
    console.log('moveDateStr: ', moveDateStr);
    console.log('dt: ', dt);
  }
  return <div>{dt}</div>;
}

export default function TripsTableRow({ trip, isDateRange, prop, boardDate }) {
  if (prop === 'dangerous_goods') {
    return renderDangerousGoods(trip.dangerous_goods);
  }
  if (prop === 'temp') {
    return renderTemperature(trip);
  }
  if (prop === 'status') {
    return renderStatus(trip.status);
  }
  if (prop === 'apt') {
    const moveData = trip.moves || [];
    const moves = moveData.filter((mv) => {
      const arrival = mv.scheduled_arrival_time || '';
      return DateService.isSame(arrival, boardDate);
    });
    const appt = moves.find((move) => move.show_appt);
    if (appt) {
      return formatApt(appt, isDateRange);
    }
    if (moves.length > 0) {
      return formatApt(moves[0], isDateRange);
    }
    return <div>Not Set</div>;
  }
  if (prop === 'timeliness') {
    return <TimelinessBadge scheduled={trip.scheduled_route_end_time} actual={trip.actual_route_end_time} />
  }
  return <div>{trip[prop]}</div>;
}
