import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export interface BreadCrumbRoute {
  name: string;
  route: string;
}

interface Props {
  routes: BreadCrumbRoute[];
}

export default function BreadCrumbs({ routes }: Props) {
  const navigate = useNavigate();
  const noRoute = routes.length;
  const handleBreadCrumb = (route: string) => {
    navigate(route);
  };
  return (
    <Breadcrumb>
      {routes.map((crumb, idx) => {
        const active = idx === (noRoute - 1);
        return (
          <Breadcrumb.Item
            key={idx}
            active={active}
            onClick={() => handleBreadCrumb(`${crumb.route}`)}
          >
            {crumb.name}
          </Breadcrumb.Item>
        )
      })}      
    </Breadcrumb>
  )
}