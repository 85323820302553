/* eslint-disable  react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EntityContainer } from '../../../redux/models/core.models';
import { ResourcePermission } from '../../../redux/models/feature.flags.models';
import { isPermissions } from '../../../redux/slices/settings.utils';
import { getPermissions } from '../../../config';

interface Props {
  resources: EntityContainer<ResourcePermission>;
  children: JSX.Element;
}

export default function Permission({ resources, children }: Props) {
  const { orgCode } = useParams();
  const [granted, setGranted] = useState(false);

  useEffect(() => {
    const permissions = getPermissions();
    const isEnabled = isPermissions(orgCode, permissions, resources);
    if (isEnabled) setGranted(true);
  }, [granted, orgCode, resources]);

  if (!granted) {
    return null;
  }
  return (
    children
  );
}
