/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table } from 'react-bootstrap';
import {
  // useDispatch,
  useSelector,
} from 'react-redux';
import { selectAddressesObject, selectFleetObject } from '../../../redux/slices/settings';
// import { formatTCMoves } from './trailerChange.utils';
import TrailerChangeTableRow from './TrailerChangeTableRow';
import { TripDetails } from '../../../redux/models/trip.models';
import { TRAILER_CHANGE_TABLE_HEADERS } from './trailerChange.constants';
import { createDropdownOptions } from '../../../utils/core.utils';

const headers = TRAILER_CHANGE_TABLE_HEADERS;

interface Props {
  name: string;
  trip: TripDetails;
  onUpdateTrip: (trip: TripDetails) => void;
}

export default function TrailerChangeModalTable({
  name,
  trip,
  onUpdateTrip,
}: Props) {
  // const dispatch = useDispatch();
  const addresses = useSelector(selectAddressesObject);
  const fleet = useSelector(selectFleetObject);
  const fleetDropdownOptions = createDropdownOptions(Object.values(fleet));
  // filteredTrips has updated moves data. moves prop from API call
  const moves = trip.data.moves || [];
  // const formattedMoves = formatTCMoves(moves, addresses);
  const onTrailerChangeInput = (position: number) => (prop: string, value: string) => {
    console.log(position);
    console.log(prop);
    console.log(value);
    const updates: TripDetails = {
      ...trip,
      data: {
        ...trip.data,
        moves:
        trip.data.moves.map((move) => {
          if (move.data.position === position) {
            return {
              ...move,
              data: { ...move.data, [prop]: value },
            };
          }
          return move;
        }),
      },
    }
    onUpdateTrip(updates);
  };
  return (
    <Table className={`${name}-move__table`} data-cy={`${name}-move__table`}>
      <thead className={`${name}-head`}>
        <tr>
          {headers.map((header) => (
            <th key={header.key}>{header.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {moves && moves.map((move, index) => (
          <TrailerChangeTableRow
            name={name}
            index={index}
            move={move}
            shipments={trip.data.shipments || []}
            fleetOptions={fleetDropdownOptions}
            fleet={fleet}
            addresses={addresses}
            onTrailerChangeInput={onTrailerChangeInput}
          />
        ))}
      </tbody>
    </Table>
  );
}
