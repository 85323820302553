import { useState } from "react";
import { Button } from "react-bootstrap";
import ButtonSpinner from "../../../../../components/shared/ButtonSpinner";

interface Props {
  numCols: number;
  onAdd: () => void;
}

export default function AddShipmentRow({ numCols, onAdd }: Props) {
  const [isAdding, setAdding] = useState(false);
  const handleAdd = async () => {
    setAdding(true);
    await onAdd();
    setAdding(false);
  };
  return (
    <tr>
      <td colSpan={numCols}>
        <Button
          variant="light" 
          size="sm" 
          onClick={handleAdd}
          className="text-start"
          style={{ width: '100%', color: '#6c757d', background: 'none', border: 'none' }}
        >
          {isAdding ? <ButtonSpinner /> : 'Add Shipment...'}
        </Button>
      </td>
    </tr>
  );
}
