import { createSlice } from '@reduxjs/toolkit';
import {
  // Role,
  getInitialState,
  getError,
  RoleStore,
} from './role.models';
import { AppState, DispatchProps } from '../../models/state.models';
import { ROLES } from '../slices';
import { SettingsApi } from '../../../services/SettingsApi';

const SLICE_NAME = ROLES;
const initialState = getInitialState();
const store = new RoleStore();

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setRoles(state, action) {
      state.roles = action.payload;
    },
    setIsRolesLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsRolesLoaded(state, action) {
      state.isLoaded = action.payload;
    },
    resetRoles: () => getInitialState(),
  },
});

export const {
  setRoles,
  setIsRolesLoading,
  setIsRolesLoaded,
  resetRoles,
} = slice.actions;

export const loadRoles = (organizationCode: string) => async (dispatch: DispatchProps) => {
  const isLoaded = store.exists(organizationCode);
  if (isLoaded) return;
  dispatch(setIsRolesLoading(true));
  try {
    const { data } = await SettingsApi.getRoles(organizationCode);
    const groups = data?.data || [];
    store.add(organizationCode, groups);
    dispatch(setIsRolesLoaded(true));
    dispatch(setRoles(groups));
  } catch (e) {
    getError(e);
  } finally {
    dispatch(setIsRolesLoading(false));
  }
};

export const selectRoles = (state: AppState) => state[SLICE_NAME].roles;

export const selectIsRolesLoaded = (state: AppState) => state[SLICE_NAME].isLoaded;
export const selectIsRolesLoading = (state: AppState) => state[SLICE_NAME].isLoading;

export const roleReducer = slice.reducer;
