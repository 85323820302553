import { DataModel } from "./core.models";

export enum ContactType {
  Customer = "customer",
  Vendor = "vendor",
}

export interface ContactData {
  name: string;
  types: ContactType[];
  sage_customer_id?: string;
}

export type Contact = DataModel<ContactData>;
