import React, { useEffect, useState } from 'react';
import {
  Card,
  Container,
  Dropdown,
  Row,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { MoreHorizontal } from 'react-feather';

import { DateService } from '../../../utils/dateService';

import Header from '../components/base/Header';
import { selectBoardsObject } from '../../../redux/slices/settings';
import { Move } from '../../../redux/models/trip.models';
import { Board } from '../../../redux/models/board.models';
import { Api } from '../../../services/services';
import MoveTimelinessHeaderTooltip from '../components/MoveTimelinessHeaderTooltip';
import TimelinessChart from '../components/base/TimelinessChart';
import TimelinessTable from '../components/base/TimelinessTable';

const getBoardId = (selectedBoard: Board | null, boards: Board[]) => {
  if (selectedBoard) {
    return selectedBoard.entity_id;
  }
  const isBoards = boards.length > 0;
  return isBoards ? boards[0].entity_id : '';
};

export default function TimelinessReport() {
  const boards = useSelector(selectBoardsObject);
  const [moves, setMoves] = useState<Move[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dateFrom, setFromDate] = useState(DateService.getStartOfTheDayISO());
  const [dateTo, setToDate] = useState(DateService.getEndOfTheDayISO());
  const [selectedBoard, setSelectedBoard] = useState<Board | null>(null);
  const boardData = Object.values(boards) || [];
  const sortedBoards = boardData.sort((a, b) => {
    const aName = a.data.name || '';
    const bName = b.data.name || '';
    return aName.localeCompare(bName);
  });
  const boardId = getBoardId(selectedBoard, sortedBoards);
  const title = boards[boardId]?.data.name || 'Move';
  const handleDate = (from: string, to: string) => {
    setFromDate(from);
    setToDate(to);
  };
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await Api.Dashboard.moveTimeliness({
        start_date: dateFrom,
        end_date: dateTo,
      });
      if (response.status === 200) {
        const moveData = response.data.data || [];
        setMoves(moveData);
      }
      setIsLoading(false);
    };
    load();
  }, [dateFrom, dateTo]);
  return (
    <Container fluid className="p-0">
      <Header title="Move Timeliness" handleDate={handleDate} />
      <Row>
        <Card className="flex-fill w-100">
        <Card.Header>
          <div className="card-actions float-end">
            <Dropdown align="end">
              <Dropdown.Toggle as="a" bsPrefix="-">
                <MoreHorizontal />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {sortedBoards.map((board) => {
                  return (
                    <Dropdown.Item
                      key={board.entity_id}
                      onClick={() => setSelectedBoard(board)}
                    >
                      {board.data.name || ''}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Card.Title className="mb-0">
            {`${title}`} <MoveTimelinessHeaderTooltip moves={moves} />
          </Card.Title>
        </Card.Header>
        <Card.Body className="d-flex">
          <div className="align-self-center w-100">
            <TimelinessChart boardId={boardId} moves={moves} isLoading={isLoading} />
            {!isLoading && (
              <TimelinessTable boardId={boardId} moves={moves} />
            )}
          </div>
        </Card.Body>
      </Card>
      </Row>
    </Container>
  );
}
