/* eslint-disable max-len */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import data from './data';

const { isMaintenance, time, duration, date } = data;

const mess = 
  `AquaDash will be undergoing scheduled maintenance on ${date} at ${time} for approximately ${duration}. We apologize for any inconvenience.`;
const storageKey = 'notificationBar';
const storageVal = 'viewed';

export default function NotificationBar() {
  const viewed = sessionStorage.getItem(storageKey);
  const [open, setOpen] = useState(viewed !== storageVal);
  const handleClose = () => {
    sessionStorage.setItem(storageKey, storageVal);
    setOpen(false);
  };
  return open && isMaintenance ? (
    <div className="px-4 py-2 d-flex align-items-center" style={{ background: '#d9edf7' }}>
      <div>
        <strong>Scheduled Maintenance </strong>- {mess}
      </div>
      <Button variant="light" className="mx-2" onClick={handleClose}><span>x</span></Button>
    </div>
  ) : null;
}
