/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Row, Button, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faFilter, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import {
  selectShipmentFilterParams, createShipment, setGroupBy, loadShipmentsPaginated,
  setFilterParams,
  resetShipments,
} from '../../../redux/slices/shipment-list/shipment-list';
import { createInvoice } from '../../../redux/slices/invoices';
import { FILTER_TEXT } from '../../../components/modals/SearchFilter/searchFilters.constants';

import DropdownButton from '../../../components/shared/DropdownButton';
import {
  DEFAULT_PAGE_SIZE,
  PageSizeDropdownOptions,
  ShipmentDropdownOptions,
  ShipmentListFilters,
} from '../../../redux/slices/shipment-list/shipment-list.models';
import { FeatureResource, ResourcePermission } from '../../../redux/models/feature.flags.models';
import Permission from '../../../components/shared/permissions/Permission';
import ShipmentListModalFilter from '../../../components/modals/ShipmentsModal/ShipmentListModalFilter';
import { ShipmentWritePermissions } from '../../../components/shared/permissions/permissions.utils';
import AsyncButton from '../../../components/shared/buttons/AsyncButton';

const BUTTON_TEXT = 'Add';
const INVOICE_BTN_TEXT = 'Invoice';
const groupByOptions = Object.values(ShipmentDropdownOptions);
const pageSizeOptions = Object.values(PageSizeDropdownOptions);
const InvoicePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Write,
};

interface Props {
  title: string;
  selectedShipments: string[];
}

function ListViewHeader({
  title, selectedShipments,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const userGroupByOptions = groupByOptions.filter((option => {
    return option.id !== ShipmentDropdownOptions.type.id;
  }));

  const filterParams: ShipmentListFilters = useSelector(selectShipmentFilterParams);
  const [shouldShowFilters, setShouldShowFilters] = useState(false);
  const [isDateBtnOpen, setIsDateBtnOpen] = useState(false);
  const handleDateBtnToggle = (open: boolean) => {
    setIsDateBtnOpen(open);
  };
  const handleMenuToggle = () => {
    const open = !shouldShowFilters;
    setShouldShowFilters(open);
    if (!open) handleDateBtnToggle(open);
  }
  const handleAddShipment = async () => {
    try {
      const shipment = await createShipment();
      if (shipment.entity_id) {
        dispatch(resetShipments());
        navigate(`/${orgCode}/shipments/${shipment.entity_id}`);
      }
    } catch (e: any) {
      const errorMessage = `Couldn't add shipment. ${e.message}. Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    }
  };

  const handleAddInvoice = async () => {
    const invoice = await createInvoice(selectedShipments, orgCode);
    if (invoice.entity_id) {
      navigate(`/${orgCode}/invoices/${invoice.entity_id}`);
    }
  };

  const handleSelectGroupBy = (optionId: string) => {
    dispatch(setGroupBy(optionId));
  };

  const handleSelectPageSize = (optionId: string) => {
    const filters: ShipmentListFilters = {
      ...filterParams,
      pageSize: Number(optionId) || DEFAULT_PAGE_SIZE,
      page: 0,
    }
    dispatch(loadShipmentsPaginated(0, filters.pageSize, filters));
  };

  const handleLoadShipments = (filters: ShipmentListFilters) => {
    dispatch(loadShipmentsPaginated(0, filters.pageSize, filters));
  };

  const handleSetFilters = (filters: ShipmentListFilters) => {
    dispatch(setFilterParams(filters));
  }

  return (
    <Row className="mb-2">
      <Stack direction="horizontal" gap={1}>
        <h3 style={{ width: '50%' }}>{title}</h3>
        <div className="search-filter">
          <Button
            variant="outline-secondary"
            data-cy={`${title}_filter_btn`}
            className="filter-datepicker d-flex align-items-center"
            onClick={handleMenuToggle}
          >
            <Stack gap={1} direction="horizontal">
              <FontAwesomeIcon
                height={10}
                width={10}
                icon={faFilter}
              />
              {' '}
              {FILTER_TEXT}
              <FontAwesomeIcon
                height={10}
                width={10}
                icon={shouldShowFilters ? faChevronUp : faChevronDown}
              />
            </Stack>
          </Button>
          <ShipmentListModalFilter
            filterData={{ ...filterParams }}
            shouldShowFilters={shouldShowFilters}
            setShouldShowFilters={setShouldShowFilters}
            saveFilters={handleLoadShipments}
            isDateBtnOpen={isDateBtnOpen}
            handleDateBtnToggle={handleDateBtnToggle}
            setIsDateBtnOpen={setIsDateBtnOpen}
            setFilters={handleSetFilters}
          />
        </div>
        <DropdownButton
          selectText="No grouping"
          selectedId={filterParams.grouping}
          options={userGroupByOptions}
          handleSelect={handleSelectGroupBy}
        />
        <DropdownButton
          selectText="Select Page Size"
          selectedId={`${filterParams.pageSize}`}
          showSelect={false}
          options={pageSizeOptions}
          handleSelect={handleSelectPageSize}
        />
        <Permission resources={InvoicePermissions}>
          <AsyncButton
            title={INVOICE_BTN_TEXT}
            variant="outline-primary"
            spinner="primary"
            disabled={!selectedShipments.length}
            handleClick={handleAddInvoice}
          />
        </Permission>
        <Permission resources={ShipmentWritePermissions}>
          <AsyncButton title={BUTTON_TEXT} spinner="light" handleClick={handleAddShipment} />
        </Permission>
      </Stack>
    </Row>
  );
}

export default ListViewHeader;
