/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

const DISPATCH_TRIPS = 'Dispatch Trips';

interface Props {
  setConfirmDispatchOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DriverCardMenu({
  setConfirmDispatchOpen
}: Props) {
  const { async_dispatch: asyncDispatch } = useFeatureFlags();
  const isAsyncDispatchEnabled = asyncDispatch?.is_enabled || false;
  const onConfirmDispatch = () => setConfirmDispatchOpen(true);
  return (
    <DropdownButton
      as={ButtonGroup}
      id="dropdown-button-drop"
      size="sm"
      variant="light"
      title="+"
    >
      {isAsyncDispatchEnabled && <Dropdown.Item onClick={onConfirmDispatch}>{DISPATCH_TRIPS}</Dropdown.Item>}
    </DropdownButton>
  );
}
