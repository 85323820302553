import {
  Button, Col, Dropdown, Row,
} from 'react-bootstrap';
import ButtonSpinner from './ButtonSpinner';

const SAVE_BUTTON_TEXT = 'Save';
const CLOSE_BUTTON_TEXT = 'Close';
const DELETE_BUTTON_TEXT = 'Delete';

interface Props {
  disabled: boolean;
  isSaving?: boolean;
  deleteButton?: boolean;
  page: string;
  isEditable: boolean;
  handleClose: () => void;
  handleDelete?: () => void;
  handleSave: () => void;
  handleSaveSend: () => void;
}

function InvoiceFooterButtons({
  page, handleDelete, handleClose, handleSave, handleSaveSend, disabled, isSaving, deleteButton, isEditable,
}: Props) {
  return (
    <Row className="flex align-items-end">
      <Col md={8}>
        {deleteButton && isEditable && (
          <Button
            id={`${page}-delete__btn`}
            data-cy={`${page}-delete__btn`}
            variant="outline-danger"
            size="lg"
            onClick={handleDelete}
            disabled={disabled}
          >
            {DELETE_BUTTON_TEXT}
          </Button>
        )}
      </Col>
      <Col className="d-flex justify-content-end align-items-end">
        <Button
          id={`${page}-close__btn`}
          data-cy={`${page}-close__btn`}
          variant="light"
          size="lg"
          className="mx-4"
          onClick={handleClose}
        >
          {CLOSE_BUTTON_TEXT}
        </Button>
        {isEditable && (
          <Dropdown
            id={`${page}-save__btn`}
            className="md-2"
          >
            <Dropdown.Toggle
              data-cy="invoice_save_btn_toggle"
              className="filter-datepicker"
              variant="primary"
            >
              {isSaving ? <ButtonSpinner /> : SAVE_BUTTON_TEXT}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item data-cy="invoice_save_btn" onClick={handleSave}>
                Save
              </Dropdown.Item>
              <Dropdown.Item data-cy="invoice_send_btn" onClick={handleSaveSend}>
                Save & Send
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Col>
    </Row>
  );
}

InvoiceFooterButtons.defaultProps = {
  isSaving: false,
  deleteButton: true,
  handleDelete: () => {},
}

export default InvoiceFooterButtons;
