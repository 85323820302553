import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { AlertCircle } from 'react-feather';
import { EntityContainer } from '../../../../../redux/models/core.models';
import { Fleet } from '../../../../../redux/models/settings.models';
import { getValue } from '../../utils/moves.utils';
import { TripState } from '../../../../../redux/slices/trailer-changes/trailerChanges.models';
import { selectMoveState } from '../../../../../redux/slices/trailer-changes/trailerChanges';

const TRAILER_UPDATE_MESSAGE = ' appears to be on other trips. ';
const SAVE_MESSAGE = 'Would you like to ';
const getTrailerNameString = (trailerId: string, fleet: EntityContainer<Fleet>) => {
  return trailerId ? `${getValue(trailerId, fleet)}` : '';
};

interface Props {
  name: string;
  tripState: TripState;
  fleet: EntityContainer<Fleet>;
  handleChangeModals: () => void;
}

export default function TrailerChangeAlertItem({ tripState, name, fleet, handleChangeModals }: Props) {
  const moveIds = Object.values(tripState.moveIds);
  const moveState = useSelector(selectMoveState);
  const allTrailerIds = moveIds.flatMap((moveId) => {
    return moveState[moveId]?.original.trailer_ids || [];
  });
  const trailerIds = new Set(allTrailerIds);
  const trailerNames = Array.from(trailerIds).map(
    (id) => getTrailerNameString(id, fleet),
  ).join(' & ');
  return (
    <Alert
      variant="primary"
      className={`alert-outline ${name}-alert`}
    >
      <div className={`alert-icon ${name}-btn`} onClick={handleChangeModals}>
        <AlertCircle />
      </div>
      <div className="alert-message">
        <strong>{trailerNames}</strong>
        {TRAILER_UPDATE_MESSAGE}
        {SAVE_MESSAGE}
        <Alert.Link data-cy={`${name}-alert__link`} onClick={handleChangeModals}>update them as well?</Alert.Link>
      </div>
    </Alert>
  );
}
