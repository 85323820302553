import { useSelector } from 'react-redux';
import { Plus } from 'react-feather';
import {
  Button, Col, Row, Spinner, Stack,
} from 'react-bootstrap';

import {
  selectIsAddingMove,
} from '../../../redux/slices/trips/trips';

import { Move, MoveData, TripDetails } from '../../../redux/models/trip.models';
import { DateService } from '../../../utils/dateService';
import { NoteTemplate } from '../../../redux/models/settings.models';
import { getDefaultDataModel } from '../../../redux/models/core.models';

const TITLE = 'Moves';
const ADD_MOVE = 'Move';

export const getDefaultMove = (
  date: string,
  tripId: string,
  position: number,
  lastMove: Move,
  boardId: string,
  dispatchTemplates: NoteTemplate[],
): Move => {
  const trailerIds = lastMove?.data?.trailer_ids || [];
  const notes = dispatchTemplates.length > 0 ? dispatchTemplates[0].data.notes : undefined;
  const arrivalTime =
    position === 0 ? date : DateService.addMinsToDateISO(lastMove?.data?.scheduled_arrival_time, 15, true);
  const departureTime = DateService.addMinsToDateISO(arrivalTime, 15, true);
  const data: MoveData = {
    actual_arrival_time: null,
    actual_departure_time: null,
    board_id: boardId,
    action: position === 0 ? 'start' : '',
    trip_id: tripId || '',
    destination_id: '',
    origin_id: '',
    shipment_ids: [],
    trailer_ids: trailerIds,
    temp: '',
    scheduled_arrival_time: DateService.getUtcISOString(arrivalTime),
    scheduled_departure_time: DateService.getUtcISOString(departureTime),
    position,
  };
  if (notes) data.dispatch_notes = notes;
  return getDefaultDataModel(data);
};

interface Props {
  tripDetails: TripDetails;
  shouldDisableSave: boolean;
  dispatchTemplates: NoteTemplate[];
  onAddMove: (move: Move) => void;
}

export default function MoveHeader({ tripDetails, shouldDisableSave, dispatchTemplates, onAddMove }: Props) {
  const isAddingMove = useSelector(selectIsAddingMove);
  const handleAddMove = () => {
    const moves = tripDetails?.data?.moves || [];
    const date = tripDetails?.data?.date;
    const tripId = tripDetails?.entity_id;
    const position = moves.length || 0;
    const lastMove = moves[moves.length - 1] || '';
    const move = getDefaultMove(
      date,
      tripId,
      position,
      lastMove,
      tripDetails.data.board_id,
      dispatchTemplates,
    );
    // dispatch(addMove(move));
    onAddMove(move);
  };

  return (
    <Row className="pt-4 pb-4">
      <Col>
        <h4>{TITLE}</h4>
      </Col>
      <Col md={6}>
        <Stack gap={3} direction="horizontal" className="float-end">
          <Button
            id="tripDetails-addMove__btn"
            data-cy="tripDetails-addMove__btn"
            disabled={shouldDisableSave}
            variant="light"
            className="float-end bg-white shadow-sm"
            onClick={handleAddMove}
          >
            {isAddingMove ? (
              <Spinner
                animation="border"
                variant="primary"
                size="sm"
                style={{ marginRight: '4px' }}
              />
            ) : null}
            <Plus className="feather align-middle mt-n1" /> {ADD_MOVE}
          </Button>
        </Stack>
      </Col>
    </Row>
  );
}
