export default class HopperServiceConfigProvider {
  static getRoles(organizationCode: string) {
    return `/group?organization_code=${organizationCode}`;
  }
  
  static getUserData() {
    return '/user/data';
  }

  static getUsers(orgId: string) {
    return `/user?organization_id=${orgId}`;
  }
}
