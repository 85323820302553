import { Table } from 'react-bootstrap';
import Loader from '../../../../components/Loader';

import { BILLING_SHIPMENT_TABLE_HEADERS } from '../constants/shipmentTable.constants';

import { TableHeader } from '../models/models';
import Permission from '../../../../components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../../../redux/models/feature.flags.models';
import ShipmentListViewTableRow from './ShipmentListViewTableRow';
import { EntityContainer } from '../../../../redux/models/core.models';
import { Address } from '../../../../redux/models/settings.models';
import { Contact } from '../../../../redux/models/contact.models';
import { BillingType } from '../../../../redux/models/billing.models';

const WritePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Write,
};

interface Props {
  headers: TableHeader[];
  areTripsLoading: boolean;
  noShipmentsMessage: string;
  shipmentIds: string[];
  addresses: EntityContainer<Address>;
  contacts: EntityContainer<Contact>;
  billingTypes: EntityContainer<BillingType>;
  openShipmentPage: (id: string) => void;
  selectShipment: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  selectedShipments: string[];
}

export default function ShipmentListViewTableComponent({
  headers = BILLING_SHIPMENT_TABLE_HEADERS,
  areTripsLoading,
  noShipmentsMessage = '',
  shipmentIds,
  addresses,
  contacts,
  billingTypes,
  selectedShipments,
  openShipmentPage,
  selectShipment,
}: Props) {
  const renderNoShipmentsMessage = () => (areTripsLoading ? <Loader text='' />
    : <div>{noShipmentsMessage}</div>);
  return (
    shipmentIds.length ? (
      <Table
        striped
        bordered
        hover
        role="grid"
        className="shipments-table"
        data-cy="shipments-table"
      >
        <thead>
          <tr>
            <Permission resources={WritePermissions}>
              <th className="check-col">{' '}</th>
            </Permission>
            {headers.slice(0).map((header) => (
              <th key={header.key}>
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {shipmentIds.map((id, index) => (
            <ShipmentListViewTableRow
              key={id}
              id={id}
              index={index}
              headers={headers}
              selectedShipments={selectedShipments}
              addresses={addresses}
              billingTypes={billingTypes}
              contacts={contacts}
              openShipmentPage={openShipmentPage}
              selectShipment={selectShipment}
            />
          ))}
        </tbody>
      </Table>
    ) : renderNoShipmentsMessage()
  );
}
