/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row } from 'react-bootstrap';
import { SHIPMENT_TYPES } from '../../../pages/shipments/constants/shipments.constants';
import { SelectInput, ControlInput } from '../../shared/Input';
import { createFilterOptions, createFiltersWithNoneOption, getAllOptions } from './searchFilter.utils';

interface ShipmentFilterInputsProps {
  page: any;
  handleChange: any;
  filterParams: any;
  formattedItem: any;
}

export function ShipmentFilterInputs({
  page, handleChange, filterParams, formattedItem,
}: ShipmentFilterInputsProps) {
  const originOptions = createFilterOptions(formattedItem, 'origin');
  const destinationOptions = createFilterOptions(formattedItem, 'destination');
  const purchaseOrderOptions = createFiltersWithNoneOption(formattedItem, 'purchase_order_no');
  const shipmentTypeOptions = getAllOptions(SHIPMENT_TYPES);
  return (
    (
      <>
        <Row>
          <Col md={6}>
            <SelectInput
              dataCy={`${page}_origin_select`}
              name="origin"
              page={page}
              options={originOptions}
              value={filterParams.origin}
              labelText="Origin"
              handleChange={handleChange}
            />
          </Col>
          <Col md={6}>
            <SelectInput
              dataCy={`${page}_destination_select`}
              name="destination"
              page={page}
              options={destinationOptions}
              value={filterParams.destination}
              labelText="Destination"
              handleChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <SelectInput
              dataCy={`${page}_type_select`}
              name="type"
              page={page}
              options={shipmentTypeOptions}
              value={filterParams.type}
              labelText="Type"
              handleChange={handleChange}
            />
          </Col>
          <Col md={6}>
            <SelectInput
              dataCy={`${page}_po_select`}
              name="purchaseOrder"
              page={page}
              options={purchaseOrderOptions}
              value={filterParams.purchaseOrder}
              labelText="P.O. #"
              handleChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ControlInput
              dataCy={`${page}_container_no_select`}
              name="container_no"
              type="text"
              page={page}
              value={filterParams.container_no}
              labelText="Container #"
              handleChange={handleChange}
            />
          </Col>
        </Row>
      </>
    )
  );
}

interface InvoiceFilterInputsProps {
  page: any
  handleChange: any
  filterParams: any
}

export function InvoiceFilterInputs({
  page, handleChange, filterParams,
}: InvoiceFilterInputsProps) {
  return (
    (
      <Row>
        <Col md={6}>
          <ControlInput
            dataCy={`${page}_invoice_no`}
            name="invoice"
            page={page}
            value={filterParams.invoice}
            labelText="Invoice No:"
            handleChange={handleChange}
          />
        </Col>
      </Row>
    )
  );
}
