/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dropdown, Form } from 'react-bootstrap'
import { MoreVertical } from 'react-feather'
import { DangerousGoodsIcon } from '../../../../icons/ShipmentIcons'
import DataCell from './DataCell'
import { TripTableColumns } from '../../../../../redux/models/board.models'
import { ShipmentListView } from '../../../../../redux/models/shipment.models'
import EditableCell from '../../../../../components/data-grid/base/EditableCell'
import Permission from '../../../../../components/shared/permissions/Permission'
import {
  InvoiceWritePermissions, TripWritePermissions
} from '../../../../../components/shared/permissions/permissions.utils'
import CustomerCell from './CustomerCell'
import BillingTypeCell from './BillingTypeCell'
import ShipmentStatusCell from './ShipmentStatusCell'
import AddressCell from '../../../../../components/data-grid/base/AddressCell'
import ShipmentDateCell from './ShipmentDateCell'
import BaseRateCell from './BaseRateCell'

interface Props {
  column: TripTableColumns;
  shipment: ShipmentListView;
  selectedShipments: string[];
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onUpdate: (shipmentId: string, key: string, value: any) => void;
  onOpen: (shipmentId: string) => void;
  onInvoice: (shipmentId: string) => void;
  onCreateTrip: (shipmentId: string) => void;
}

function ShipmentCell({
  column, shipment, selectedShipments, onSelect, onUpdate, onOpen, onInvoice, onCreateTrip
}: Props) {
  if (column.key === 'expand') {
    return (
      <td
        role="gridcell"
        className="invoice-checkbox-col"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Form.Group data-cy={`multi_invoices_checkbox_${column.key}`}>
          <Form.Check
            checked={selectedShipments?.includes(shipment.id)}
            className="invoices-checkbox"
            type="checkbox"
            onChange={(e) => onSelect(e, shipment.id)}
          />
        </Form.Group>
      </td>
    )
  }
  if (column.key === 'dangerous_goods') {
    return <DangerousGoodsIcon />
  }
  if (column.key === 'requested_drop_off_date') {
    return (
      <ShipmentDateCell
        value={shipment.requested_drop_off_date}
        onChange={(val) => onUpdate(shipment.id, 'requested_drop_off_date', val)}
      />
    )
  }
  if (column.key === 'base_rate') {
    return (
      <BaseRateCell
        shipment={shipment}
        onChange={(val) => onUpdate(shipment.id, 'base_rate', val)}
      />
    )
  }
  if (column.key === 'requested_pick_up_date') {
    return (
      <ShipmentDateCell
        value={shipment.requested_pick_up_date} 
        onChange={(val) => onUpdate(shipment.id, 'requested_pick_up_date', val)}
      />
    )
  }
  if (column.key === 'customer') {
    return (
      <CustomerCell
        customerId={shipment.customer_id || ''}
        onChange={(val) => onUpdate(shipment.id, 'customer_id', val)}
      />
    )
  }
  if (column.key === 'billing_type') {
    return (
      <BillingTypeCell
        customerId={shipment.customer_id || ''}
        billingTypeId={shipment.billing_type_id || ''}
        onChange={(val) => onUpdate(shipment.id, 'billing_type_id', val)}
      />
    )
  }
  if (column.key === 'container_no') {
    return (
      <EditableCell
        value={shipment.container_no}
        onChange={(val) => onUpdate(shipment.id, column.key, val)}
      />
    )
  }
  if (column.key === 'purchase_order_no') {
    return (
      <EditableCell
        value={shipment.purchase_order_no}
        onChange={(val) => onUpdate(shipment.id, column.key, val)}
      />
    )
  }
  if (column.key === 'status') {
    return (
      <ShipmentStatusCell
        status={shipment.status}
        onChange={(val) => onUpdate(shipment.id, 'status', val)}
      />
    )
  }
  if (column.key === 'origin') {
    return (
      <AddressCell
        addressId={shipment.origin_id || ''}
        prop="origin_id"
        onChange={(val) => onUpdate(shipment.id, 'origin_id', val)}
      />
    )
  }
  if (column.key === 'destination') {
    return (
      <AddressCell
        addressId={shipment.destination_id || ''}
        prop="destination_id"
        onChange={(val) => onUpdate(shipment.id, 'destination_id', val)}
      />
    )
  }
  if (column.key === 'actions') {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="link" id={`dropdown-task-${shipment.id}`}>
          <MoreVertical size={18} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => onOpen(shipment.id)}>Open</Dropdown.Item>
          <Permission resources={InvoiceWritePermissions}>
            <Dropdown.Item onClick={() => onInvoice(shipment.id)}>Invoice</Dropdown.Item>
          </Permission>
          <Permission resources={TripWritePermissions}>
            <Dropdown.Item onClick={() => onCreateTrip(shipment.id)}>Create Trip</Dropdown.Item>
          </Permission>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
  return <DataCell data={shipment} column={column}  key={column.key}/>
}

export default ShipmentCell;