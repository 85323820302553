/* eslint-disable */
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import ButtonSpinner from '../../../../../components/shared/ButtonSpinner';
import PlaceholderValue from '../../../../../components/data-grid/base/PlaceholderValue';
import { useSelector } from 'react-redux';
import { selectUomObject } from '../../../../../redux/slices/settings';
import { ShipmentListView } from '../../../../../redux/models/shipment.models';

interface Props {
  shipment: ShipmentListView;
  onChange: (value: string) => void;
}

const BaseRateCell: React.FC<Props> = ({ shipment, onChange }) => {
  const value = shipment.base_rate?.quantity || 0;
  const uomId = shipment.base_rate?.uom_id || '';
  const billingTypeId = shipment.billing_type_id || '';
  const baseRate = shipment.base_rate;
  const uoms = useSelector(selectUomObject);
  const [editing, setEditing] = useState(false);
  const [tempValue, setTempValue] = useState(`${value}`);
  const [isSaving, setSaving] = useState(false);
  const uom = uoms[uomId]?.data.name || '';
  const handleSetEditing = () => {
    if (billingTypeId === '' || !baseRate) {
      return;
    }
    setEditing(true);
  }
  const handleBlur = async () => {
    setSaving(true);
    setEditing(false)
    await onChange(tempValue)
    setSaving(false);
  }
  if (editing) {
    return (
      <Form.Control
        type="number"
        value={tempValue}
        onChange={(e) => setTempValue(e.target.value)}
        onBlur={handleBlur}
        autoFocus
      />
    )
  }
  return (
    <div onClick={handleSetEditing} style={{ cursor: 'text' }}>
      {`${value} ${uom}` || <PlaceholderValue />}
      {isSaving && <ButtonSpinner />}
    </div>
  )
}

export default BaseRateCell;