import React from 'react';

// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import LandingLayout from './layouts/Landing';

// Guards
import AuthGuard from './components/guards/AuthGuard';

// Landing
import Landing from './pages/landing/Landing';

import Boards from './pages/boards';
import Planner from './pages/planner';
import Trip from './pages/trips/Trip';
import ShipmentListContainer from './pages/shipments/ShipmentListContainer';
import Shipment from './pages/shipments/ShipmentDetails/Shipment';

// Auth
import Page404 from './pages/auth/Page404';

// Settings
import Settings from './pages/settings/app/Settings';

// Version updates

import LatestUpdates from './pages/version-updates/LatestUpdates';

import InvoiceList from './pages/invoices/InvoiceList';
import Invoice from './pages/invoices/InvoiceDetails/Invoice';
import OrganisationList from './pages/projects';
import BoardSettings from './pages/settings/BoardSettings';
import Permission from './components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from './redux/models/feature.flags.models';
import DispatchNotesTemplateEditor from './pages/settings/app/DispatchNotesTemplateEditor';
import TripTemplateEditor from './pages/templates/TripTemplateEditor';
import ShipmentTypeEditor from './pages/settings/app/shipments/ShipmentTypeEditor';
import {
  BillingItemReadPermissions,
  BillingItemWritePermissions,
  BillingTypeWritePermissions,
  ContactWritePermissions,
  DriverWritePermissions,
  ShipmentTypeWritePermissions,
} from './components/shared/permissions/permissions.utils';
import Dashboard from './pages/dashboard/Dashboard';
import ContactList from './components/entities/contacts/ContactList';
import ContactDetails from './pages/contacts/details/ContactDetails';
import BillingItemsList from './components/entities/billing-items/BillingItemList';
import BillingItemDetails from './pages/billing-items/BillingItemDetails';
import BillingTypeDetails from './pages/billing-types/BillingTypeDetails';
import DriverList from './components/entities/driver/DriverList';
import DriverDetails from './pages/drivers/details/DriverDetails';
import BobtailReport from './pages/dashboard/details/BobtailReport';
import EmptyMoveReport from './pages/dashboard/details/EmptyMoveReport';
import ShareTripDetails from './pages/share-trip-details/ShareTripDetails';
import IntegrationsList from './pages/integrations/IntegrationList';
import TimelinessReport from './pages/dashboard/details/TimelinessReport';
import TripActivityLog from './pages/activity-log/TripActivityLog';
import ShipmentActivityLog from './pages/activity-log/ShipmentActivityLog';
import ApplyDriverShifts from './pages/drivers/apply-shifts/ApplyDriverShifts';
import DriverTripHistory from './pages/planner/DriverTripHistory/DriverTripHistory';
import TripTemplateTripEditor from './pages/templates/TripTemplateTripEditor';
import TripTemplateShipmentEditor from './pages/templates/TripTemplateShipmentEditor';
import ShipmentBoardContainer from './pages/shipments/ShipmentBoardContainer';
import ImportShipments from './pages/shipments/import/ImportShipments';

const BoardWritePermissions = {
  [FeatureResource.Board]: ResourcePermission.Read,
};

const InvoicePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Read,
}

const TripReadPermissions = {
  [FeatureResource.Trip]: ResourcePermission.Read,
};

const TripPlanWritePermissions = {
  [FeatureResource.TripPlan]: ResourcePermission.Write,
};

const ShipmentPermissions = {
  [FeatureResource.Shipment]: ResourcePermission.Read,
}

const routes = [
  {
    path: '/',
    element: <LandingLayout />,
    children: [
      {
        path: '',
        element: <Landing />,
      },
    ],
  },
  {
    path: '/projects',
    element: (
      <AuthGuard>
        <OrganisationList />
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/dashboard/bobtails',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <BobtailReport />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/dashboard/empty-moves',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <EmptyMoveReport />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/dashboard/timeliness',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <TimelinessReport />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/boards',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Dashboard />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/boards/:id',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={TripReadPermissions}>
            <Boards />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/boards/:id/planner',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={TripPlanWritePermissions}>
            <Planner />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/boards/:boardId/planner/driver/:driverId',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={TripReadPermissions}>
            <DriverTripHistory />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/boards/:boardId/settings',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={BoardWritePermissions}>
            <BoardSettings />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/trips/:id',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={TripReadPermissions}>
            <Trip />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/trips/:id/activity-log',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={TripReadPermissions}>
            <TripActivityLog />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/shipments',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={ShipmentPermissions}>
            <ShipmentListContainer />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/shipments-import',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={ShipmentPermissions}>
            <ImportShipments />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/b/shipments/:boardId',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={ShipmentPermissions}>
            <ShipmentBoardContainer />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/b/shipments/:boardId/import',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={ShipmentPermissions}>
            <ImportShipments />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/shipments/:id',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={ShipmentPermissions}>
            <Shipment />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/shipments/:id/activity-log',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={ShipmentPermissions}>
            <ShipmentActivityLog />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/invoices',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={InvoicePermissions}>
            <InvoiceList />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/invoices/:id',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={InvoicePermissions}>
            <Invoice />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Settings />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/contacts',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={ContactWritePermissions}>
            <ContactList />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/drivers',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={DriverWritePermissions}>
            <DriverList />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/drivers/:entityId',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={DriverWritePermissions}>
            <DriverDetails />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/drivers/:entityId/apply-availability',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={DriverWritePermissions}>
            <ApplyDriverShifts />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/rates',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={BillingItemReadPermissions}>
            <BillingItemsList />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/rates/:entityId',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={BillingItemWritePermissions}>
            <BillingItemDetails />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/load-types/:customerId/:entityId',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={BillingTypeWritePermissions}>
            <BillingTypeDetails />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/contacts/:entityId',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={ContactWritePermissions}>
            <ContactDetails />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/dispatch-notes/:entityId',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <DispatchNotesTemplateEditor />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/trip-templates/:entityId',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <TripTemplateEditor />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/trip-templates/:templateId/trips/:key',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <TripTemplateTripEditor />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/trip-templates/:templateId/shipments/:key',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <TripTemplateShipmentEditor />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/settings/shipment-type/:entityId',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Permission resources={ShipmentTypeWritePermissions}>
            <ShipmentTypeEditor />
          </Permission>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/updates',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <LatestUpdates />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: ':orgCode/share/trips',
    element: (
      <div>
        <p>Trips page</p>
      </div>
    ),
  },
  {
    path: ':orgCode/share/trips',
    element: (
      <div>
        <p>Trips page</p>
      </div>
    ),
  },
  {
    path: ':orgCode/share/trips/:id',
    element: <ShareTripDetails />,
  },
  {
    path: ':orgCode/share/shipments',
    element: (
      <div>
        <p>Trips page</p>
      </div>
    ),
  },
  {
    path: ':orgCode/share/shipments/:id',
    element: (
      <div>
        <p>Trips page</p>
      </div>
    ),
  },
  {
    path: ':orgCode/integrations',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <IntegrationsList />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
