import React, { useState } from 'react';
import { Form, Button, Row } from 'react-bootstrap';
import { FormFieldConfig } from '../../../../redux/models/shipment.models';

interface HeaderMappingProps {
  headers: string[];
  shipmentFields: FormFieldConfig[];
  onMappingComplete: (mapping: Record<string, string>) => void;
}

export default function HeaderMapping({ headers, shipmentFields, onMappingComplete }: HeaderMappingProps) {
  const [mapping, setMapping] = useState<Record<string, string>>({});

  const handleMappingChange = (shipmentField: string, fileHeader: string) => {
    setMapping(prev => ({ ...prev, [shipmentField]: fileHeader }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onMappingComplete(mapping);
  };

  return (
    <Row>
      <h4>Map File Headers to Shipment Fields</h4>
      <Form onSubmit={handleSubmit}>
        {shipmentFields.map(field => (
          <Form.Group key={field.key} className="mb-3">
            <Form.Label>{field.name}</Form.Label>
            <Form.Select
              value={mapping[field.key] || ''}
              onChange={(e) => handleMappingChange(field.key, e.target.value)}
            >
              <option value="">Select a header</option>
              {headers.map(header => (
                <option key={header} value={header}>{header}</option>
              ))}
            </Form.Select>
          </Form.Group>
        ))}
        <Button type="submit" variant="primary">Confirm Mapping</Button>
      </Form>
    </Row>
  );
}