import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';

import { TRIP_TABLE_HEADERS } from './constants/tripTable.constants';

import Loader from '../../../components/Loader';
import TripsTableRow from './TripsTableRow';
import { selectAreTripsLoading, selectTripsFilterParams } from '../../../redux/slices/trips/trips';
import { TripBoardModel } from '../../../redux/models/trip.models';
import { TripTableColumns } from '../../../redux/models/board.models';
import { DateService } from '../../../utils/dateService';

const NO_TRIPS_MESSAGE = 'No trips for this group';

interface Props {
  trips: TripBoardModel[];
  title: string;
  headers: TripTableColumns[];
  onOpen: (id: string) => void;
}

export default function TripsTable({
  trips,
  title,
  headers = TRIP_TABLE_HEADERS,
  onOpen,
}: Props) {
  const areTripsLoading = useSelector(selectAreTripsLoading);
  const { dateFrom, dateTo } = useSelector(selectTripsFilterParams);
  const isSameDay = DateService.isSame(dateFrom, dateTo);
  // const openTripPage = (id: string) => navigate(`/${orgCode}/trips/${id}`);
  const openTripPage = (id: string) => {
    if (onOpen) onOpen(id);
  }
  const renderNoTripsMessage = () => (areTripsLoading ? <Loader /> : <div>{NO_TRIPS_MESSAGE}</div>);
  return (
    trips.length ? (
      <Table
        striped
        bordered
        hover
        className="trips-table"
        data-cy={`${title}_trips-table`}
      >
        <thead>
          <tr>
            {headers.map((header) => <th key={header.key}>{header.name}</th>)}
          </tr>
        </thead>
        <tbody>
          {trips.map((trip, index) => (
            <tr
              key={trip.id}
              id={`boards-tripsTable__tableRow-${index}-${trip.id}`}
              className="trips-table-row"
              data-cy={`${title}_trips-table-row`}
              onClick={() => openTripPage(trip.id)}
            >
              {headers.map((header) => (
                <td
                  key={`${trip.id}-${header.key}`}
                  data-cy={`boards-tripsTable__trip-${index}-${header.key}`}
                  id={`boards-tripsTable__trip-${index}-${header.key}`}
                >
                  <TripsTableRow trip={trip} prop={header.key} boardDate={dateFrom} isDateRange={!isSameDay} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    ) : renderNoTripsMessage()
  );
}
