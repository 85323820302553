import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';

import reportWebVitals from './utils/reportWebVitals';
import App from './App';
import { VERSION_NO } from './pages/version-updates/UpdateList';

const baseUrl = process.env.REACT_APP_BASE_API_URL || '';

if (process.env.NODE_ENV === 'production') {
  /**
   * BrowserTracing causes cors error.
   * See https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/
   * for more info on sentry-trace and baggage headers
   */
  const stage = process.env.REACT_APP_APP_STAGE || 'production';
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.BrowserProfilingIntegration(),
    ],
    environment: stage,
    release: `aquadash@${VERSION_NO}`,
    tracePropagationTargets: [`${baseUrl}`],
    tracesSampleRate: 0.3,
    profilesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
