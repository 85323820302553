/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import ButtonSpinner from '../../../components/shared/ButtonSpinner';
import EditableTitleLabel from './EditableTitleLabel';

interface Props {
  name: string;
  isEditing: boolean;
  as?: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onRename: () => void;
}

function EditableTitle({ name, isEditing, as = 'h3', setIsEditing, setName, onRename }: Props) {
  const [isSaving, setIsSaving] = useState(false);
  const handleRename = async () => {
    setIsSaving(true);
    await onRename();
    setIsSaving(false);
  }
  const handleClick = () => {
    setIsEditing(true);
  };
  return (
    <Stack direction="horizontal" gap={1}>
      {isEditing ? (
        <input
          value={name}
          style={{ width: 300 }}
          autoFocus
          onBlur={handleRename}
          onChange={(e) => setName(e.target.value)}
        />
      ) : <EditableTitleLabel title={name} as={as} handleClick={handleClick} />}
      {isSaving && <ButtonSpinner />}
    </Stack>
  )
}

EditableTitle.defaultProps = {
  as: 'h3'
}

export default EditableTitle;