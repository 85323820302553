/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row, Col } from 'react-bootstrap';

import { InputSwitch, SelectInput } from '../../../../../components/shared/Input';

import { Shipment } from '../../../../../redux/models/shipment.models';
import { SHIPMENT_OFFLOAD_TYPES } from '../../../constants/shipments.constants';
// import ContactInput from '../../../../../components/entities/contacts/ContactInput';

const FORM_TITLE = 'Special Requirements';
const offloadOptions: any = Object.values(SHIPMENT_OFFLOAD_TYPES);

interface Props {
  shipmentDetails: Shipment;
  handleChange: any;
  disabled: boolean;
  page: string;
}

export default function SpecialReqsForm({
  shipmentDetails,
  handleChange,
  disabled,
  page
}: Props) {
  return (
    <Row >
      <h4>{FORM_TITLE}</h4>
      <Col xs={4}>
        <SelectInput
          value={shipmentDetails.data.offload_type}
          labelText="Offload"
          name="offload_type"
          placeholder="n/a"
          page={page}
          options={offloadOptions}
          handleChange={handleChange}
          dataCy="offload_type"
          disabled={disabled}
        />
      </Col>
      <Col xs={4}>
        <h6>Air Cargo</h6>
        <InputSwitch
          labelText=""
          name="air_cargo"
          page={page}
          handleChange={handleChange}
          checked={shipmentDetails.data.air_cargo || false}
          dataCy="air_cargo"
          className=""
          disabled={disabled}
        />
      </Col>
      <Col xs={4}>
        <h6>CT Pat</h6>
        <InputSwitch
          labelText=""
          name="ct_pat"
          page={page}
          handleChange={handleChange}
          checked={shipmentDetails.data.ct_pat || false}
          dataCy="ct_pat"
          className=""
          disabled={disabled}
        />
      </Col>
    </Row>
  );
}