import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChevronUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Collapse, ListGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AquadashServiceApiProvider } from '../../../../services/AquadashServiceApiProvider';
import { Uploader } from './Uploader';
import { removeExtension } from '../../utils/shipments.utils';
import Permission from '../../../../components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../../../redux/models/feature.flags.models';

const Permissions = {
  [FeatureResource.Shipment]: ResourcePermission.Write,
};

const ADDITIONAL_DOCS = 'Additional Documents';

export default function DocumentItem({
  attachment, group, handleFileUpload, handleDelete, dataCy,
}) {
  const [open, setOpen] = useState(false);
  const handleFilePond = () => setOpen(!open);
  const displayIcon = !open ? faPlus : faChevronUp;

  const renameFile = (file) => `${attachment.name}${file.extension}`;

  const handleDownload = async () => {
    const file = [{ name: attachment.path, filetype: attachment.filetype }];
    try {
      const { data } = await AquadashServiceApiProvider.downloadFile(file);
      window.open(data.data[0].path, '_blank');
    } catch (e) {
      const errorMessage = "Couldn't download attachments. Please contact support if the problem persists.";
      toast(errorMessage, { type: 'error' });
    }
  };

  const showDownloadLink = attachment?.path ? (
    <span
      className="download_link"
      onClick={handleDownload}
      data-cy={`${attachment?.name}_download_link`}
    >
      {removeExtension(attachment?.name)}
    </span>
  )
    : (<span data-cy={`${attachment?.name}_list_item`}>{attachment?.name}</span>);

  return (
    <ListGroup.Item
      as="li"
      className="d-flex justify-content-between align-items-start"
      data-cy={dataCy}
    >
      <div className="document-list">
        <div>
          {attachment ? showDownloadLink : (<span data-cy="additional_docs_list_item">{ADDITIONAL_DOCS}</span>)}
        </div>
        <Permission resources={Permissions}>
          <Collapse in={open}>
            {attachment ? (
              <div id={`${group}_${attachment?.name}_uploader`}>
                <Uploader renameFile={renameFile} handleFileUpload={handleFileUpload} />
              </div>
            ) : (
              <div id={`${group}_${attachment?.name}_uploader`}>
                <Uploader handleFileUpload={handleFileUpload} />
              </div>
            ) }
          </Collapse>
        </Permission>
      </div>
      <Permission resources={Permissions}>
        {attachment?.path ? (
          <div className="attachment-icon-wrapper" data-cy="shipmentDetails_file-delete-icon">
            <FontAwesomeIcon
              icon={faTrash}
              onClick={handleDelete}
              className="attachment-delete-icon"
            />
          </div>
        ) : (
          <div className="attachment-icon-wrapper" data-cy="shipmentDetails_file-add-icon">
            <FontAwesomeIcon
              icon={displayIcon}
              onClick={handleFilePond}
              aria-controls={`${group}_${attachment?.name}_uploader`}
              aria-expanded={open}
              className="attachment-add-icon"
            />
          </div>
        )}
      </Permission>
    </ListGroup.Item>
  );
}
