/* eslint-disable no-plusplus */
import { Move, MoveData, TripDetails, TripListViewData, Trip, TripListView } from "../../models/trip.models";
import { EntityContainer } from "../../models/core.models";
import { DateService } from "../../../utils/dateService";
import { errorToast } from "../../../components/notifications/app-toast";

const getErrorPlaceholder = (verb: string) =>
  `Couldn't ${verb} trips. Please contact support if the problem persists`;

export const getError = (e: unknown, details = "Couldn't get trips", verb = 'get') => {
  let em;
  if (e instanceof Error) {
    em = e.message;
  }
  const errorMessage = em ?
    `${details}. ${em}. Please contact support if the problem persists.`
    : getErrorPlaceholder(verb);
  return errorToast(errorMessage);
};

export const convertDurationToInt = (secondsStr: string): number => {
  // Use a regular expression to remove non-digit characters
  const numericPart = secondsStr.replace(/\D/g, '');

  // Convert the numeric part of the string to an integer
  const secondsInt = parseInt(numericPart, 10);

  // Check if the conversion resulted in a valid number
  if (Number.isNaN(secondsInt)) {
    throw new Error('Invalid input string');
  }

  return secondsInt;
};

export const createMoveFromData = (data: MoveData): Move => {
  return {
    author: '',
    data: {
      ...data,
    },
    entity_id: data.entity_id || '',
    entity_type: '',
    version: '',
  };
};

export const createTripFromListViewData = (
  trip: TripListViewData,
  estimates: EntityContainer<TimeDistanceEstimate>,
): TripDetails => {
  const distance = estimates[trip.trip_id]?.distance || 0;
  const duration = estimates[trip.trip_id]?.duration || 0;
  return {
    author: '',
    data: {
      board_id: '',
      date: '',
      driver_id: trip.driver_id || '',
      estimated_distance_metres: distance,
      estimated_duration_seconds: duration,
      group_id: '',
      moves: trip.moves.map((move) => createMoveFromData(move)),
      samsara_route_id: '',
      shipments: [],
      status: '',
      trip_no: trip.trip_no,
      vehicle_id: trip.vehicle_id,
    },
    entity_id: trip.entity_id || trip.trip_id,
    entity_type: '',
    version: '',
  };
}

export const getMoveArrival = (move: Move) => move?.data?.scheduled_arrival_time;

/**
 * Validates if the array of date strings is in chronological order
 * @param {string[]} dates - An array of date strings
 * @returns {boolean} - Returns true if the dates are in order, false otherwise
 */
export const areDatesInOrder = (dates: string[]): boolean => {
  for (let i = 0; i < dates.length - 1; i++) {
    if (dates[i] === '') return false;
    const currentDateStr = dates[i];
    const nextDateStr = dates[i + 1];
    const isCurrentValid = DateService.isDateValid(currentDateStr);
    if (isCurrentValid === false) return false;
    const isNextValid = DateService.isDateValid(nextDateStr);
    if (isNextValid === false) return false;
    const currentDate = new Date(dates[i]);
    const nextDate = new Date(dates[i + 1]);
    // Check if the current date is greater than the next date
    if (currentDate > nextDate) {
      return false;
    }
  }
  return true;
};

export const getMoveDates = (moves: Move[]) => {
  return [...moves]
    .sort((a, b) => {
      const aPos = a?.data?.position || 0;
      const bPos = b?.data?.position || 0;
      return aPos - bPos;
    })
    .flatMap((move) => getMoveArrival(move) || '');
};

export const isMoveOrderValid = (moves: Move[]) => {
  const dates = getMoveDates(moves);
  return areDatesInOrder(dates);
};

/// format `scheduled_start` and `scheduled_end` props for the passed in trip based of it's moves
export const formatTripDates = (trip: Trip): Trip => {
  const moves = trip.data.moves || [];
  const moveLen = moves.length || 0;
  if (moveLen < 1) return trip;
  const start = getMoveArrival(moves[0]);
  const end = getMoveArrival(moves[moveLen - 1]);
  return {
    ...trip,
    data: {
      ...trip.data,
      scheduled_start: start || undefined,
      scheduled_end: end || undefined,
    }
  }
};

export interface TimeDistanceEstimate {
  distance: number;
  duration: number;
}

export const shouldUpdateTripStore = (updatedTrip: TripListView, dateFrom: string, dateTo: string) => {
  let startMatchesBoardDate;
  let endMatchesBoardDate;
  const tripStart = updatedTrip.data.start_date;
  const tripEnd = updatedTrip.data.end_date;
  const boardFrom = DateService.getUnixTime(dateFrom);
  const boardTo = DateService.getUnixTime(dateTo);

  // check start of trip is same day as board
  if (tripStart) {
    const start = DateService.getUnixTime(tripStart);
    startMatchesBoardDate = start >= boardFrom && start <= boardTo;
  }

  // check end of trip is same day as board
  if (tripEnd) {
    const end = DateService.getUnixTime(tripEnd);
    endMatchesBoardDate = end >= boardFrom && end <= boardTo;
  }

  // if trip matches board date, update
  if (startMatchesBoardDate || endMatchesBoardDate) {
    return true;
  }
  return false;
};
