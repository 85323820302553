import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Card, Button } from 'react-bootstrap';
import { createDispatchTemplate, getSettingByName, selectNoteTemplates } from '../../../../redux/slices/settings';
import DispatchTemplateRow from './DispatchTemplateRow';
import AddTemplateModal from './AddTemplateModal';
import { NoteTemplate } from '../../../../redux/models/settings.models';
import Analytics from '../../../../utils/analytics';
import { SETTINGS_NAMES } from '../../../../constants/core.constants';
import useAppDispatch from '../../../../hooks/useAppDispatch';

export default function DispatchTemplates() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const templates = useSelector(selectNoteTemplates);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleOpen = (template: NoteTemplate) => {
    navigate(`/${orgCode}/settings/dispatch-notes/${template.entity_id}`);
  };
  const handleReload = async () => {
    await dispatch(getSettingByName(SETTINGS_NAMES.NOTE_TEMPLATES));
  };
  const handleSave = async (templateName: string) => {
    try {
      setIsSaving(true);
      const response = await createDispatchTemplate(templateName);
      if (response.status === 200) {
        await handleReload();
        setShowAddModal(false);
        handleOpen(response.data.data);
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <Card>
      <Card.Header>
        <div className="d-flex align-items-center justify-content-between">
          <h4>Dispatch Notes Templates</h4>
          <Button variant="primary" onClick={() => setShowAddModal(true)}>Add</Button>
        </div>
      </Card.Header>
      <Card.Body>
        <Table bordered hover>
          <tbody>
            {templates.map((template: NoteTemplate) => (
              <DispatchTemplateRow
                key={template.entity_id}
                template={template}
                handleReload={handleReload}
                onOpen={handleOpen}
              />
            ))}
          </tbody>
        </Table>
      </Card.Body>
      <AddTemplateModal
        shouldShowModal={showAddModal}
        isSaving={isSaving}
        saveItem={handleSave}
        cancelSave={() => setShowAddModal(false)}
      />
    </Card>
  );
}
