import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import useSidebar from '../../hooks/useSidebar';

import SidebarNav from './SidebarNav';
import Loader from '../Loader';
import { TEXTS } from '../../pages/landing/landing.constants';
import { SidebarItem } from './sidebar.models';
import { EntityContainer } from '../../redux/models/core.models';

interface ThemeBg {
  sidebar: string;
  content: string;
}

const Styles: EntityContainer<ThemeBg> = {
  default: {
    sidebar: 'sidebar',
    content: 'sidebar-content',
  },
  colour: {
    sidebar: 'sidebar-theme',
    content: 'sidebar-content-theme',
  },
};

interface Props {
  items: SidebarItem[];
  areItemsLoading: boolean;
}

function Sidebar({ items, areItemsLoading = false }: Props) {
  const { orgCode } = useParams();
  const { isOpen } = useSidebar();
  const key = process.env.REACT_APP_THEME || 'default';
  const theme = Styles[key] || Styles.default;
  return (
    <nav className={`${theme.sidebar} ${!isOpen ? 'collapsed' : ''}`}>
      <div className={`${theme.sidebar}`}>
        <PerfectScrollbar>
          <Link className="sidebar-brand" to={`/${orgCode}/boards`}>
            <img src={TEXTS.SIDEBAR_IMG} className="img-fluid" alt={TEXTS.TITLE} />
          </Link>
          {areItemsLoading ? <Loader /> : <SidebarNav items={items} />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
}

export default Sidebar;
