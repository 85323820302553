interface EntityContainer {
  [index: number]: string;
};

export const DAYS: EntityContainer = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
}
