/* eslint-disable */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Alert, Table, Button, Card } from 'react-bootstrap';
import FileUpload from './components/FileUpload';
import HeaderMapping from './components/HeaderMapping';
import { FormFieldConfig, ShipmentData } from '../../../redux/models/shipment.models';
import { defaultShipmentTypeFields } from '../../../redux/slices/shipments.utils';
import { createShipmentBatch } from '../../../redux/slices/shipments';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/models/state.models';
import { selectBoard } from '../../../redux/slices/settings';
import { SHIPMENT_STATUS_TYPES } from '../constants/shipments.constants';


const shipmentFields: FormFieldConfig[] = Object.values(defaultShipmentTypeFields);

export default function ImportShipments() {
  const { boardId } = useParams();
  const [step, setStep] = useState(1);
  const [headers, setHeaders] = useState<string[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [isSaving, setSaving] = useState(false);
  const [mapping, setMapping] = useState<Record<string, string>>({});
  const [importedShipments, setImportedShipments] = useState<ShipmentData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const board = useSelector((state: AppState) => selectBoard(state, boardId));
  const shipmentTypeId = board?.data?.shipment_type?.entity_id;

  const handleFileProcessed = (fileHeaders: string[], fileData: any[]) => {
    setHeaders(fileHeaders);
    setData(fileData);
    setStep(2);
  };

  const handleMappingComplete = (headerMapping: Record<string, string>) => {
    setMapping(headerMapping);
    processImport(headerMapping);
  };

  const processImport = (headerMapping: Record<string, string>) => {
    try {
      setSaving(true);
      const shipments: ShipmentData[] = data.map(row => {
        const shipment: Partial<ShipmentData> = {};
        (Object.keys(headerMapping) as (keyof ShipmentData)[]).forEach(field => {
          const headerIndex = headers.indexOf(headerMapping[field]);
          if (headerIndex !== -1) {
            let value = row[headerIndex];
            if (field === 'weight') {
              value = parseFloat(value);
            } else if (field === 'cut_off_date' || field === 'requested_drop_off_date' || field === 'requested_pick_up_date') {
              value = new Date(value);
            }
            shipment[field] = value;
          }
        });
        return shipment as ShipmentData;
      });

      setImportedShipments(shipments);
      setStep(3);
    } catch (err) {
      setError('Error processing import. Please check your data and mapping.');
    } finally {
      setSaving(false);
    }
  };

  const handleSubmitImport = async () => {
    try {
      // Here you would typically send the importedShipments to your backend API
      // For this example, we'll just log them to the console
      // POST /shipment/batch
      setError(null);
      const data = importedShipments.map((ship) => {
        return {
          ...ship,
          status: SHIPMENT_STATUS_TYPES.BOOKED.value,
          type_id: shipmentTypeId || '',
          type: '',
        }
      })
      setSaving(true);
      const items = await createShipmentBatch(data);
      setStep(4);
    } catch (err) {
      setError('Error submitting import. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const renderShipmentsTable = () => {
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {shipmentFields.map(field => (
              <th key={field.key}>{field.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {importedShipments.map((shipment, index) => (
            <tr key={index}>
              {shipmentFields.map((field: FormFieldConfig) => {
                const prop: any = field.key;
                const key: keyof ShipmentData = prop;
                return (
                  <td key={field.key}>
                    {/* {field === 'cut_off_date' || field === 'requested_drop_off_date'
                      ? shipment[field]?.toLocaleDateString()
                      : shipment[field]?.toString()} */}
                    {shipment[key]?.toString()}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <Container>
      <h1 className="my-4">Import Shipments</h1>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              {step === 1 && <FileUpload onFileProcessed={handleFileProcessed} />}
              {step === 2 && (
                <HeaderMapping
                  headers={headers}
                  shipmentFields={shipmentFields}
                  onMappingComplete={handleMappingComplete}
                />
              )}
              {step === 3 && (
                <div>
                  <Card.Title>Review Import</Card.Title>
                  <p>Number of shipments to import: {importedShipments.length}</p>
                  {renderShipmentsTable()}
                  <Button variant="primary" disabled={isSaving} onClick={handleSubmitImport}>
                    {isSaving ? 'Saving' : 'Create Shipments'}
                  </Button>
                </div>
              )}
              {step === 4 && (
                <div>
                  <Alert variant="success">
                    Import successful! {importedShipments.length} shipments have been imported.
                  </Alert>
                </div>
              )}
              {error && <Alert variant="danger">{error}</Alert>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}