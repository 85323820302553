/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-lonely-if */
import { useState } from 'react';
import { Button, Card, Col, Row, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { DateService } from '../../../utils/dateService';
import { DateInput } from '../../../components/shared/Input';
import { DateRangeType } from '../../../components/modals/SearchFilter/searchFilters.constants';
import { SearchDateRange } from '../../../components/modals/SearchFilter/searchFilter.models';
import DateButton from '../../../components/shared/DateButton';

const TRIP_DATES = 'Trip Dates';
const APPLY = 'Apply';
const CLOSE = 'Close';
const PAGE = 'dispatch_board';

interface Props {
  defaultFromDate: string;
  defaultToDate: string;
  shouldShowFilters: boolean;
  saveFilters: (dateRange: SearchDateRange) => void;
  setShouldShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BoardDateMenu({
  defaultFromDate,
  defaultToDate,
  shouldShowFilters,
  setShouldShowFilters,
  saveFilters,
}: Props) {
  const [isDateRangeSelectorOpen, setIsDateRangeSelectorOpen] = useState(false);
  const [filters, setFilters] = useState<SearchDateRange>({
    dateFrom: defaultFromDate,
    dateTo: defaultToDate,
    dateType: DateRangeType.Custom,
  });

  const handleDateFilterChange = (name: string, value: any) => {
    const { dateTo, dateFrom } = filters;
    const isStart = name === 'dateFrom';
    const val = isStart ? DateService.getStartOfTheDayISO(value) : DateService.getEndOfTheDayISO(value);
    const valUnix = DateService.getUnixTime(val);
    const dateFromUnix = DateService.getUnixTime(dateFrom);
    const dateToUnix = DateService.getUnixTime(dateTo);
    const updates = {
      ...filters,
      dateType: DateRangeType.Custom,
      [name]: val,
    };
    if (isStart) {
      if (valUnix > dateToUnix) {
        updates.dateTo = DateService.getEndOfTheDayISO(value);
      }
    } else {
      if (dateFromUnix > valUnix) {
        updates.dateFrom = DateService.getStartOfTheDayISO(value);
      }
    }
    setFilters(updates);
  };

  const changeDateFilter = (selectedDate: SearchDateRange, type: DateRangeType) => {
    setFilters({
      ...filters,
      dateFrom: selectedDate.dateFrom,
      dateTo: selectedDate.dateTo,
      dateType: type,
    });
  };
  const onApplyClick = () => {
    const { dateFrom, dateTo, dateType } = filters;
    if (dateFrom > dateTo) {
      toast('Date from must be earlier than date to.', { type: 'error' });
      return;
    }
    setShouldShowFilters(false);
    const updates = {
      dateFrom: dateFrom ? DateService.getISOString(dateFrom) : '',
      dateTo: dateTo ? DateService.getISOString(dateTo) : '',
      dateType,
    };
    saveFilters(updates);
  };

  const onClearClick = () => {
    setShouldShowFilters(false);
  };

  return (
    <Card className={`search-filter-wrapper ${shouldShowFilters ? '' : 'hidden'}`}>
      <Card.Body>
        <h4>{TRIP_DATES}</h4>
        <Row>
          <Col md={12}>
            <DateButton
              type={filters.dateType || DateRangeType.Custom}
              range={filters}
              page={PAGE}
              isOpen={isDateRangeSelectorOpen}
              setIsOpen={setIsDateRangeSelectorOpen}
              handleToggle={(open) => setIsDateRangeSelectorOpen(open)}
              setDateFilterParams={changeDateFilter}
            />
          </Col>
          <Col md={6}>
            <DateInput
              labelText="From"
              name="dateFrom"
              page={PAGE}
              max={undefined}
              min={undefined}
              value={DateService.getYYYYMMDDFormat(filters.dateFrom)}
              handleChange={handleDateFilterChange}
              dataCy="shipment_modal_date_from"
            />
          </Col>
          <Col md={6}>
            <DateInput
              labelText="To"
              name="dateTo"
              page={PAGE}
              max={undefined}
              min={undefined}
              value={DateService.getYYYYMMDDFormat(filters.dateTo)}
              handleChange={handleDateFilterChange}
              dataCy="shipment_modal_date_to"
            />
          </Col>
        </Row>
        <div className="mt-2 d-flex justify-content-end">
          <Stack direction="horizontal" gap={2}>
            <Button variant="secondary" data-cy="shipment_modal_close_btn" onClick={onClearClick}>
              {CLOSE}
            </Button>
            <Button
              id="search_shipments_table_apply_filter_btn"
              data-cy="search_shipments_table_apply_filter_btn"
              variant="primary"
              onClick={onApplyClick}
            >
              {APPLY}
            </Button>
          </Stack>
        </div>
      </Card.Body>
    </Card>
  );
};
