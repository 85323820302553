/* eslint-disable  */
import React from 'react';
import { NavLink } from 'react-router-dom';

import { Badge, Collapse } from 'react-bootstrap';
import Permission from '../shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../redux/models/feature.flags.models';

function CustomRouterLink(props: any) {
  const { extlink, to } = props;
  const newProps = {
    ...props,
    extlink: null,
  };
  return extlink ? (<a {...newProps} href={to} />) : <NavLink {...newProps} />;
}

const Permissions = {
  [FeatureResource.Invoice]: ResourcePermission.Read,
}

function SidebarNavListItem(props: any) {
  const {
    title, href, icon: Icon, children, badge, extlink, target, open: openProp = false,
  } = props;

  const [open, setOpen] = React.useState(openProp);

  const handleToggle = () => {
    setOpen((state: any) => !state);
  };

  const financeSideNav = () => (
    <Permission resources={Permissions}>
      <li
        className="sidebar-item"
        data-cy={`${title}_sideBar_link`}
      >
        <CustomRouterLink
          to={href}
          activeclassname="active"
          target={target && target}
          className="sidebar-link"
          extlink={extlink && extlink}
        >
          {Icon && <Icon className="feather align-middle" />}{" "}
          <span className="align-middle">
            {title}
          </span>
          {badge && (
          <Badge className="badge-sidebar-primary" bg="">
            {badge}
          </Badge>
          )}
        </CustomRouterLink>
      </li>
    </Permission>
  );

  if (children) {
    return (
      <li className={`sidebar-item ${open ? 'active' : ''}`}>
        <a
          className={`sidebar-link ${open ? '' : 'collapsed'}`}
          data-bs-toggle="collapse"
          aria-expanded={open ? 'true' : 'false'}
          onClick={handleToggle}
        >
          <span className="align-middle">
            {title}
          </span>
          {badge && (
            <Badge className="badge-sidebar-primary" bg="">
              {badge}
            </Badge>
          )}
          {open ? <div /> : <div />}
        </a>
        <Collapse in={open}>
          <ul className="sidebar-dropdown list-unstyled">{children}</ul>
        </Collapse>
      </li>
    );
  }

  return ((title === 'Invoices') ? financeSideNav()
    : (
      <li
        className="sidebar-item"
      >
        <CustomRouterLink
          to={href}
          activeclassname="active"
          target={target && target}
          className="sidebar-link"
          data-cy={`${title}_sideBar_link`}
          extlink={extlink && extlink}
        >
          <span className="align-middle">
            {title}
          </span>
          {badge && (
          <Badge className="badge-sidebar-primary" bg="">
            {badge}
          </Badge>
          )}
        </CustomRouterLink>
      </li>
    )
  );
}

export default SidebarNavListItem;
