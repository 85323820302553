import React, { useEffect, useState } from 'react';
import { Package } from "react-feather";
import StatCard from './base/StatCard';
import { Api } from '../../../services/services';
import { formatCurrency } from '../../invoices/utils/invoice.utils';
import { Shipment } from '../../../redux/models/shipment.models';
import { BillingItemData } from '../../../redux/models/billing.models';
import { StatDateProps } from '../models/Stat';
import Permission from '../../../components/shared/permissions/Permission';
import {
  InvoiceWritePermissions
} from '../../../components/shared/permissions/permissions.utils';

const calculateAmount = (items: BillingItemData[]) => {
  if (!items) return 0;
  if (items.length === 0) return 0;
  return items?.reduce((total, billing) => {
    const qty = billing?.quantity || 0;
    const rate = billing?.price || 0;
    return total + (qty * rate);
  }, 0);
}

const calculateShipment = (total: number, shipment: Shipment) => {
  const baseRate = calculateAmount([shipment.data.base_rate]);
  const accessorials = calculateAmount(shipment.data.accessorials);
  return total + (baseRate + accessorials);
}

export default function ShipmentRevenueStat({ dateFrom, dateTo }: StatDateProps) {
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const total = shipments.reduce((tot, shipment) => calculateShipment(tot, shipment), 0);
  const details = isLoading ? '' : `${formatCurrency('cad', total)}`;
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await Api.Dashboard.shipmentRevenue({
        start_date: dateFrom,
        end_date: dateTo,
      });
      if (response.status === 200) {
        const shipmentData: Shipment[] = response.data.data || [];
        const baseRates = shipmentData.filter((ship) => ship.data.base_rate !== null);
        setShipments(baseRates);
      }
      setIsLoading(false);
    };
    load();
  }, [dateFrom, dateTo]);
  return (
    <Permission resources={InvoiceWritePermissions}>
      <StatCard
        title={details}
        description="Shipments Moved (no FSC)"
        isLoading={isLoading}
        timerange={`From ${shipments.length} shipments`}
        icon={<Package className="align-middle text-success" />}
      />
    </Permission>
  );
}
