import React, { useMemo } from 'react';

import useSettingsState from '../hooks/useSettingsState';

import { LAYOUT } from '../constants';

const initialState = {
  layout: LAYOUT.FLUID,
};

const LayoutContext = React.createContext(initialState);

function LayoutProvider({ children }) {
  const [layout, setLayout] = useSettingsState('layout', LAYOUT.FLUID);

  const providerValue = useMemo(() => ({
    layout,
    setLayout,
  }), [layout, setLayout]);

  return (
    <LayoutContext.Provider value={providerValue}>
      {children}
    </LayoutContext.Provider>
  );
}

export { LayoutProvider, LayoutContext };
