/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Card, Row } from "react-bootstrap";

import useAuth from "../../../../hooks/useAuth";
import TotalTripStat from "./TotalTripStat";
import ShipmentRevenueStat from "../ShipmentRevenueStat";
import RevenueStat from "../RevenueStat";

export interface Props {
  dateFrom: string;
  dateTo: string;
}

function Statistics({ dateFrom, dateTo }: Props) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const data: any = user;
  const name = data?.displayName || '';
  return (
    <Row>
      <Col md="6" xl className="d-flex">
        <Card className="illustration flex-fill">
          <Card.Body className="p-0 d-flex flex-fill">
            <Row className="g-0 w-100">
              <Col xs="6">
                <div className="illustration-text p-3 m-1">
                  <h4 className="illustration-text">
                    {`${t("Welcome back")}, ${name}!`}
                  </h4>
                </div>
              </Col>
              <Col xs={6} className="align-self-end text-end">
                <div
                  // src={fleet}
                  // alt="fleet image"
                  className="img-fluid illustration-img"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <TotalTripStat dateFrom={dateFrom} dateTo={dateTo} />
      </Col>
      <Col md="6" xl className="d-flex">
        <ShipmentRevenueStat dateFrom={dateFrom} dateTo={dateTo} />
      </Col>
      <Col md="6" xl className="d-flex">
        <RevenueStat dateFrom={dateFrom} dateTo={dateTo} />
      </Col>
    </Row>
  );
};

export default Statistics;
