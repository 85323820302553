import { AxiosInstance } from 'axios';
import { EntityEndpointProvider } from './EntityEndpointProvider';
import { DataModel } from '../../redux/models/core.models';
import { ApiQuery, DEFAULT_API_QUERY } from '../../redux/models/network.models';

export class EntityServiceProvider<T> {
  private endpointProvider: EntityEndpointProvider;

  private httpProvider: AxiosInstance;

  constructor(entity: string, axiosInstance: AxiosInstance) {
    this.endpointProvider = new EntityEndpointProvider(entity);
    this.httpProvider = axiosInstance;
  }
  
  get api() {
    return this.httpProvider;
  }

  get endpoint() {
    return this.endpointProvider;
  }

  getAll() {
    return this.api.get(this.endpoint.entityPath());
  }

  create(entityData: T) {
    return this.api.post(this.endpoint.entityPath(), entityData);
  }

  update(entity: DataModel<T>) {
    return this.api.put(this.endpoint.entityById(entity.entity_id), entity);
  }

  getById(entityId: string) {
    return this.api.get(this.endpoint.entityById(entityId));
  }

  delete(entity: DataModel<T>) {
    return this.api.delete(this.endpoint.entityById(entity.entity_id));
  }

  find(filters: ApiQuery = DEFAULT_API_QUERY) {
    return this.api.post(this.endpoint.find(), filters);
  }

  deleteMany(entities: DataModel<T>[]) {
    const ids = entities.map((entity) => entity.entity_id);
    return this.api.post(this.endpoint.find(), ids);
  }

  getActivityLog(entityId: string) {
    return this.api.get(`${this.endpoint.entityPath()}/activity-log/${entityId}`);
  }
}
