import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { SidebarItem } from './sidebar.models';
import ButtonSpinner from '../shared/ButtonSpinner';
import Permission from '../shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../redux/models/feature.flags.models';

const BoardWritePermissions = {
  [FeatureResource.Board]: ResourcePermission.Read,
};


interface Props {
  item: SidebarItem;
  allowAdd?: boolean;
  onAdd?: () => void;
}

function SidebarNavSectionHeader({ item, allowAdd, onAdd }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const handleAdd = async () => {
    if (onAdd) {
      setIsLoading(true);
      await onAdd();
      setIsLoading(false);
    }
  };
  return (
    <li
      className="sidebar-header d-flex align-items-center justify-content-between"
      onMouseEnter={() => setShowAdd(true)}
      onMouseLeave={() => setShowAdd(false)}
    >
      <span className="pt-3 pb-1">{item.title}</span>
      {allowAdd && showAdd && (
        <Permission resources={BoardWritePermissions}>
          <Button variant="secondary" onClick={handleAdd}>
            {isLoading ? (
              <ButtonSpinner variant="light" />
            ) : (
              <FontAwesomeIcon icon={faPlusCircle} />
            )}
          </Button>
        </Permission>
      )}
    </li>
  )
}

SidebarNavSectionHeader.defaultProps = {
  allowAdd: false,
  onAdd: () => {},
};

export default SidebarNavSectionHeader;
