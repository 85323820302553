/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable dot-notation */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Dropdown } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { MoreHorizontal } from "react-feather";
import { StatDateProps } from "../../models/Stat";
import { selectBoardsObject } from "../../../../redux/slices/settings";
import { Move } from "../../../../redux/models/trip.models";
import { Board } from "../../../../redux/models/board.models";
import MoveTimelinessHeaderTooltip from "../MoveTimelinessHeaderTooltip";
import { Api } from "../../../../services/services";
import TimelinessChart from "./TimelinessChart";

const getBoardId = (selectedBoard: Board | null, boards: Board[]) => {
  if (selectedBoard) {
    return selectedBoard.entity_id;
  }
  const isBoards = boards.length > 0;
  return isBoards ? boards[0].entity_id : '';
};

function BarChart({ dateFrom, dateTo }: StatDateProps) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const boards = useSelector(selectBoardsObject);
  const [moves, setMoves] = useState<Move[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBoard, setSelectedBoard] = useState<Board | null>(null);
  const boardData = Object.values(boards) || [];
  const sortedBoards = boardData.sort((a, b) => {
    const aName = a.data.name || '';
    const bName = b.data.name || '';
    return aName.localeCompare(bName);
  });
  const boardId = getBoardId(selectedBoard, sortedBoards);
  const title = boards[boardId]?.data.name || 'Move';

  const handleNav = () => {
    navigate(`/${orgCode}/dashboard/timeliness`);
  };

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await Api.Dashboard.moveTimeliness({
        start_date: dateFrom,
        end_date: dateTo,
      });
      if (response.status === 200) {
        const moveData = response.data.data || [];
        setMoves(moveData);
      }
      setIsLoading(false);
    };
    load();
  }, [dateFrom, dateTo]);

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {sortedBoards.map((board) => {
                return (
                  <Dropdown.Item
                    key={board.entity_id}
                    onClick={() => setSelectedBoard(board)}
                  >
                    {board.data.name || ''}
                  </Dropdown.Item>
                )
              })}
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={handleNav}
              >
                View More
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">{`${title} Timeliness`} <MoveTimelinessHeaderTooltip moves={moves} /></Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <TimelinessChart boardId={boardId} moves={moves} isLoading={isLoading} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default BarChart;
