import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';

const CANCEL = 'Cancel';

interface Props {
  title: string;
  description: string;
  shouldShowDeleteModal: boolean;
  isDeleting: boolean;
  deleteBtnText: string;
  deleteItem: () => void;
  cancelDeletion: () => void;
}

export default function ActionModal({
  title = 'Add item',
  description = 'Are you sure you want to add this item?',
  shouldShowDeleteModal,
  deleteBtnText = 'Delete',
  isDeleting,
  deleteItem,
  cancelDeletion,
}: Props) {
  return (
    <Modal
      show={shouldShowDeleteModal}
      onHide={cancelDeletion}
      centered
      data-cy="delete_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center m-3">
        <p className="mb-0">{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button id="delete_modal__cancel_btn" variant="secondary" onClick={cancelDeletion}>
          {CANCEL}
        </Button>
        <Button
          id="delete_modal__delete_btn"
          data-cy="delete_modal__delete_btn"
          variant="danger"
          onClick={deleteItem}
        >
          {deleteBtnText}
          {isDeleting && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              style={{ marginLeft: '4px' }}
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
