/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { Transforms, Descendant } from 'slate';
import { MentionElement } from './custom-types';

export const createMentionElement = (character: string): MentionElement => {
  return {
    type: 'mention',
    character,
    children: [{ text: '' }],
  };
}

/// insert mention into text editor
export const insertMention = (editor: any, character: any) => {
  const mention = createMentionElement(character);
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
};

export const addMentionToNotes = (character: string, notes: Descendant[]) => {
  const mention = createMentionElement(character);
  // const lastIdx = notes.length - 1;
  // return notes.map((note, idx) => {
  //   if (idx === lastIdx) {
  //     const childs = note?.children || [];
  //     return {
  //       ...note,
  //       children: [...note.children]
  //     }
  //   }
  //   return note;
  // })
  const paragraph: Descendant = {
    type: 'paragraph',
    children: [mention],
  };
  return [...notes, paragraph];
};

export const templatedValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [
      { text: 'Hi ' },
      {
        type: 'mention',
        character: 'driver',
        children: [{ text: '' }],
      },
      { text: ', ' },
      {
        type: 'mention',
        character: 'action',
        children: [{ text: '' }],
      },
      { text: ' ' },
      {
        type: 'mention',
        character: 'trailer',
        children: [{ text: '' }],
      },
      { text: ' when you get to' },
      {
        type: 'mention',
        character: 'location',
        children: [{ text: '' }],
      },
      { text: '. Thanks, ' },
      {
        type: 'mention',
        character: 'me',
        children: [{ text: '' }],
      },
      { text: '. ' },
    ],
  },
];

export const defaultDispatchTemplate: Descendant[] = [
  {
    type: 'paragraph',
    children: [
      { text: 'Hi! Enter dispatch notes here...' },
    ],
  },
];

export const getNotesTemplate = (notes: string) => [
  {
    type: 'paragraph',
    children: [
      { text: notes },
    ],
  },
];

export const withMentions = (editor: any) => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = (element: any) => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = (element: any) => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  editor.markableVoid = (element: any) => {
    return element.type === 'mention' || markableVoid(element)
  }

  return editor
};