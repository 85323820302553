import { TripTableColumns } from "../../../redux/models/board.models";
import { TripBoardModel } from "../../../redux/models/trip.models";

export const DEFAULT_PLANNER_HEADERS: TripTableColumns[] = [
  {
    name: 'Group',
    key: 'group',
    visible: true,
    width: 200,
    resizable: true,
  },
  {
    name: 'Trailer',
    key: 'trailer',
    visible: true,
    width: 200,
    resizable: true,
  },
  // {
  //   name: 'Container #',
  //   key: 'containerNo',
  //   visible: true,
  //   width: 200,
  //   resizable: true,
  // },
  // {
  //   name: 'Customer',
  //   key: 'customer',
  //   visible: true,
  //   width: 100,
  //   resizable: true,
  // },
  {
    name: 'Quote Template',
    key: 'billingType',
    visible: true,
    width: 100,
    resizable: true,
  },
  {
    name: 'Apt',
    key: 'apt',
    visible: true,
    width: 200,
    resizable: true,
  },
  {
    name: 'Route',
    key: 'route',
    visible: true,
    width: 200,
    resizable: true,
  },
  // {
  //   name: 'P.O. #',
  //   key: 'purchase_order_no',
  //   visible: true,
  //   width: 200,
  //   resizable: true,
  // },
  // {
  //   name: 'Temp (f)',
  //   key: 'temp',
  //   visible: true,
  //   width: 200,
  //   resizable: true,
  // },
  // {
  //   name: 'BOL',
  //   key: 'bol',
  //   visible: true,
  //   width: 200,
  //   resizable: true,
  // },
  // {
  //   name: 'Notes',
  //   key: 'notes',
  //   visible: true,
  //   width: 200,
  //   resizable: true,
  // },
  // {
  //   name: 'DG',
  //   key: 'dangerous_goods',
  //   visible: true,
  //   width: 200,
  //   resizable: true,
  // },
  {
    name: 'Driver',
    key: 'driver',
    visible: true,
    width: 200,
    resizable: true,
  },
  {
    name: 'Status',
    key: 'status',
    visible: true,
    width: 200,
    resizable: true,
  },
  // {
  //   name: 'Timeliness',
  //   key: 'timeliness',
  //   visible: true,
  //   width: 200,
  //   resizable: true,
  // },
];

export const PLANNER_DRIVER_HEADERS: TripTableColumns[] = DEFAULT_PLANNER_HEADERS.filter((col) => col.key !== 'driver');

export const getTripIdsForDispatch = (groupTrips: TripBoardModel[]) => {
  const trips = groupTrips
        .filter((tr) => {
          return tr.driverId !== '' &&
            tr.driverId !== undefined &&
            tr.driverId !== null &&
            tr.status !== 'complete' &&
            tr.status !== 'dispatched' &&
            tr.status !== 'driver-confirmed'
        });
  return trips.map((tr) => tr.id);
};
