import React from 'react'
import ShipmentList from './ShipmentList'
import useFeatureFlags from '../../hooks/useFeatureFlags'
import ShipmentListView from './list-view/ShipmentListView';
import { BILLING_SHIPMENT_TABLE_HEADERS } from './ShipmentsTable/constants/shipmentTable.constants';

export default function ShipmentListContainer() {
  const { paginated_shipments: pagination } = useFeatureFlags();
  const isEnabled = pagination?.is_enabled ?? false;
  const headers = BILLING_SHIPMENT_TABLE_HEADERS;
  return isEnabled ? <ShipmentListView /> : <ShipmentList headers={headers} />
};
