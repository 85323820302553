import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChangeLog } from '../../redux/models/core.models';
import ActivityLogList from './components/ActivityLogList';
import { getShipmentHistory } from '../../redux/slices/shipments';

export default function ShipmentActivityLog() {
  const { id } = useParams();
  const [history, setHistory] = useState<ChangeLog[]>([]);
  useEffect(() => {
    const load = async (shipmentId: string) => {
      const response = await getShipmentHistory(shipmentId);
      if (response.status === 200) {
        setHistory(response.data.data);
      }
    };
    if (id) load(id);
  }, [id]);
  return (
    <div>
      <h4>Trip Activity Log</h4>
      {history.length > 0 && <ActivityLogList entity='trip' history={history} />}
    </div>
  )
}
