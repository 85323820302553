import { toast } from "react-toastify";
import { EntityContainer } from "../../redux/models/core.models";
import { ApiQuery, ElementType, FilterOp, QueryOperator } from "../../redux/models/network.models";
import { AppNotification } from "../../redux/slices/notifications/notifications.models";
import { PollingProvider } from "../polling/PollingProvider";
import { NotificationPollRequest } from "./Notification.models";
import NotificationBody from "../../components/notifications/NotificationBody";

export class NotificationPollProvider {
  private poll = new PollingProvider('/app-notification/find', 2000);

  private requestIds: EntityContainer<string> = {};

  start(requests: NotificationPollRequest[], callback?: (data: AppNotification[]) => void) {
    console.log('add request');
    this.addRequests(requests);
    const inFlightIds = Object.values(this.requestIds);
    console.log('inFlightIds');
    console.log(inFlightIds);
    const params = NotificationPollProvider.postBody(inFlightIds);
    this.poll.params = params;
    this.poll.start((data: AppNotification[]) => {
      console.log('received data from poll');
      console.log(data);
      data.forEach((notification) => {
        const reqId = notification.data.request_id;
        if (reqId) {
          this.remove(reqId);
          NotificationPollProvider.dismissToast(notification);
        }
      });
      if (callback) callback(data)
    });
  }

  addRequests(requests: NotificationPollRequest[]) {
    requests.forEach((request) => {
      const { requestId, message } = request;
      this.requestIds[requestId] = requestId;
      toast(`${message}`, {
        type: toast.TYPE.INFO
      });
    });
  }

  remove(requestId: string) {
    console.log('remove request from poll: ', requestId);
    delete this.requestIds[requestId];
    const remainingIds = Object.values(this.requestIds);
    console.log('remainingIds');
    console.log(remainingIds);
    if (remainingIds.length === 0) {
      this.poll.stop();
    }
  }

  private static dismissToast(notification: AppNotification) {
    toast(<NotificationBody notification={notification} />, {
      type: toast.TYPE.SUCCESS
    });
  }

  private static postBody(requestIdss: string[]) {
    const query: ApiQuery = {
      filters: [
        {
          "field": {
            "name": "data.request_id",
            "type": ElementType.String
          },
          "op": FilterOp.InArray,
          "value": requestIdss,
        },
      ],
      operator: QueryOperator.And,
    };
    return query;
  }
}