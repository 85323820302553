import React, { useEffect } from 'react';
import {
  UomReadPermissions, UomWritePermissions
} from '../../shared/permissions/permissions.utils';
import { getSelectedOptions } from '../../../utils/core.utils';
import { DropdownOptionsModel } from '../../shared/SelectInput.models';
import EntityInput from '../base/inputs/EntityInput';
import { Api } from '../../../services/services';
import { UomData } from '../../../redux/models/settings.models';
import { DataModel } from '../../../redux/models/core.models';

interface Props {
  value: string;
  page: string;
  disabled: boolean;
  prop: string;
  label?: string;
  options: DropdownOptionsModel[];
  onChange: (prop: string, value: string) => void;
  onNewEntity: (prop: string, entity: DataModel<UomData>) => void;
}

function UomInput({ value, page, prop, label, disabled, options, onChange, onNewEntity }: Props) {
  const handleCreateData = (_: string, val: string): UomData => {
    return {
      name: val,
    };
  };
  useEffect(() => {
    console.log('render UOM input');
  }, []);
  return (
    <EntityInput
      disabled={disabled}
      labelText={label || 'Unit Of Measure'}
      name={prop}
      page={page}
      value={value}
      options={getSelectedOptions(value, options)}
      entity="uom"
      dataField="name"
      readResources={UomReadPermissions}
      writeResources={UomWritePermissions}
      api={Api.Uom}
      defaultAdd
      allowAdd
      settingName="uom"
      syncSettings={false}
      onChange={onChange}
      createData={handleCreateData}
      onNewEntity={onNewEntity}
    />
  );
}

UomInput.defaultProps = {
  label: 'Unit Of Measure',
}

export default UomInput;
