/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityContainer } from '../../../redux/models/core.models';
import { Address, Fleet } from '../../../redux/models/settings.models';
import { Shipment } from '../../../redux/models/shipment.models';
import { Move } from '../../../redux/models/trip.models';
import { TRAILER_CHANGE_TABLE_HEADERS } from './trailerChange.constants';
import TrailerChangeTableRowData from './TrailerChangeTableRowData';
import { formatTCMoves } from './trailerChange.utils';
import { DropdownOptionsModel } from '../../shared/SelectInput.models';

const headers = TRAILER_CHANGE_TABLE_HEADERS;

interface Props {
  move: Move;
  index: number;
  name: string;
  shipments: Shipment[];
  fleet: EntityContainer<Fleet>;
  fleetOptions: DropdownOptionsModel[];
  onTrailerChangeInput: (position: number) => (prop: string, value: string) => void;
  addresses: EntityContainer<Address>;
}

export default function TrailerChangeTableRow({
  move, index, name, shipments, fleet, fleetOptions, addresses, onTrailerChangeInput 
}: Props) {
  const formattedMove: any = formatTCMoves([move], addresses)[0];
  return (
    <tr
      key={`${move.entity_id}_${index}`}
      id={`${name}_tableRow-${index}`}
    >
      {headers.map((header) => (
        <td key={`${header.key}_${move.entity_id}`} data-cy={`trailerChanges_${header.key}_${index}`}>
          <TrailerChangeTableRowData
            move={formattedMove}
            shipments={shipments}
            prop={header.key}
            fleet={fleet}
            fleetOptions={fleetOptions}
            onTrailerChangeInput={onTrailerChangeInput}
          />
        </td>
      ))}
    </tr>
  );
}
