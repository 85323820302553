/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useSelector } from 'react-redux';

import GroupCard from './GroupCard';
import Loader from '../../../components/Loader';
import { GroupView, NoteTemplate, Tag } from '../../../redux/models/settings.models';

import {
  selectAreSettingsLoading,
  selectGroupsByBoardId,
} from '../../../redux/slices/settings';
import { selectAreTripsLoading, selectTripsByCardId } from '../../../redux/slices/trips/trips';
import {
  BOARD_PAGE_TABS,
  getUnassignedId,
  LOADING_TEXT,
  getUnassignedCard,
} from '../constants/boards.constants';
import { AppState } from '../../../redux/models/state.models';
import { Board, TripTableColumns } from '../../../redux/models/board.models';
import { selectCurrentPlan, selectIsPlansLoading } from '../../../redux/slices/planner/planner';
import { TripPlan } from '../../../redux/slices/planner/planner.models';
import { EntityContainer } from '../../../redux/models/core.models';
import PlannerEmptyState from '../components/PlannerEmptyState';

const mapDriverPlan = (plan: TripPlan) => {
  if (!plan) return {};
  const container: EntityContainer<string> = {};
  for (const [key, value] of Object.entries(plan.data.plans)) {
    value.forEach((tripId) => { container[tripId] = key });
  }
  return container;
}

interface Props {
  board: Board;
  headers: TripTableColumns[];
  shouldExpandAllItems: boolean;
  noteTemplates: NoteTemplate[];
  tags: Tag[];
  dateFrom: string;
  dateTo: string;
}

export default function Groups({
  board,
  headers,
  shouldExpandAllItems,
  noteTemplates,
  tags,
  dateFrom,
  dateTo,
}: Props) {
  const tabKey = BOARD_PAGE_TABS.GROUPS.eventKey;
  const groupsByBoardId = useSelector((state: AppState) => selectGroupsByBoardId(state, board.entity_id));
  const areSettingsLoading = useSelector(selectAreSettingsLoading);
  const areTripsLoading = useSelector((state: AppState) => selectAreTripsLoading(state));
  const unassignedId = getUnassignedId(tabKey);
  const unassignedTrips = useSelector((state: AppState) => selectTripsByCardId(state, unassignedId));
  const plan = useSelector(selectCurrentPlan);
  const arePlansLoading = useSelector(selectIsPlansLoading);
  const unassignedGroup: GroupView = getUnassignedCard(tabKey);
  const driverPlan = mapDriverPlan(plan);
  const isLoading = areSettingsLoading || areTripsLoading || arePlansLoading;
  return (
    <>
      {isLoading ? <Loader text={LOADING_TEXT} /> : null}
      {!isLoading && !plan && (
        <PlannerEmptyState
          boardId={board.entity_id}
          dateFrom={dateFrom}
          dateTo={dateTo}
          tags={tags}
        />
      )}
      {plan && unassignedTrips.length > 0 && !areTripsLoading ? (
        <GroupCard
          board={board}
          group={unassignedGroup}
          headers={headers}
          noteTemplates={noteTemplates}
          shouldExpandAllItems={shouldExpandAllItems}
          driverPlan={driverPlan}
        />
      ) : null}
      {plan && !areTripsLoading && groupsByBoardId.map((group) => (
        <GroupCard
          key={group.entity_id}
          board={board}
          group={group}
          headers={headers}
          noteTemplates={noteTemplates}
          shouldExpandAllItems={shouldExpandAllItems}
          driverPlan={driverPlan}
        />
      ))}
    </>
  );
}
