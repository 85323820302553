import React, { useState } from 'react';

import { TripPlan } from '../../../../redux/slices/planner/planner.models';
import { saveDraft, setCurrentPlan } from '../../../../redux/slices/planner/planner';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import AsyncButton from '../../../../components/shared/buttons/AsyncButton';
import { EntityContainer } from '../../../../redux/models/core.models';
import { TripBoardModel } from '../../../../redux/models/trip.models';
import Analytics from '../../../../utils/analytics';
import ApiResponse from '../../../../services/base/ApiResponse';

interface Props {
  plan: TripPlan;
  trips: EntityContainer<TripBoardModel>;
}

export default function ResetPlanButton({ plan, trips }: Props) {
  const dispatch = useAppDispatch();
  const [isSaving, setSaving] = useState(false);
  const handleSavePlan = async () => {
    try {
      setSaving(true);
      const plans = plan.data.plans || {};
      const resetDrivers: EntityContainer<string[]> = Object.keys(plans).reduce((store, key) => {
        return {
          ...store,
          [key]: [],
        };
      }, {});
      const unassigned = Object.values(trips).map((trip) => trip.id);
      const updates: TripPlan = {
        ...plan,
        data: {
          ...plan.data,
          unassigned_trip_ids: unassigned,
          plans: {
            ...resetDrivers,
          },
        },
      };
      const response = await saveDraft(updates);
      if (response.status === 200) {
        const entity = ApiResponse.parseItem(response);
        dispatch(setCurrentPlan(entity));
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setSaving(false);
    }
  };
  return (
    <AsyncButton
      title="Reset"
      variant="outline-secondary"
      spinner="secondary"
      disabled={isSaving}
      handleClick={handleSavePlan}
    />
  )
}