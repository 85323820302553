/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from 'axios';
import googleMapsAxios from '../../utils/googleMapsAxios';
import DataCache from '../cache/DataCache';

const PLACES_URL = 'https://places.googleapis.com/v1/places:searchText';

export class GoogleMapServiceProvider {

  private cache: DataCache;

  private httpProvider: AxiosInstance;

  constructor() {
    this.httpProvider = googleMapsAxios;
    this.cache = new DataCache();
  }
  
  get api() {
    return this.httpProvider;
  }

  search(text: string) {
    return this.api.post(PLACES_URL, {
      "textQuery" : text,
    });
  }
}
