import React from 'react';
import AddModal from '../../../modals/AddModal';

interface Props {
  title: string;
  btnTitle: string;
  saveDisabled: boolean;
  children: React.ReactNode;
  shouldShowModal: boolean;
  isSaving: boolean;
  handleSave: () => void;
  cancelSave: () => void;
}

export default function AddEntityModal({
  title,
  btnTitle,
  saveDisabled,
  shouldShowModal,
  isSaving,
  children,
  handleSave,
  cancelSave,
}: Props) {
  return (
    <AddModal
      title={title}
      btnTitle={btnTitle}
      saveDisabled={saveDisabled}
      shouldShowModal={shouldShowModal}
      isSaving={isSaving}
      handleSave={handleSave}
      cancelSave={cancelSave}
    >
      {children}
    </AddModal>
  )
}
