import React from 'react';
import { Stack, Form } from 'react-bootstrap';
import { Driver } from '../../../redux/models/settings.models';
import { DriverFilters, TripPlan } from '../../../redux/slices/planner/planner.models';
import { EntityContainer } from '../../../redux/models/core.models';
import { TripBoardModel } from '../../../redux/models/trip.models';
import { DriverAvailability } from '../../../redux/models/driver.availability.models';
import { sortTripByTBDDate } from '../constants/boards.constants';
import { getFinishTime, getAssignedTimes, checkTripWithinDriverShift, getShiftText } from './driver.utils';
import DriverInfo from './DriverInfo';
import { DateService } from '../../../utils/dateService';

interface Props {
  availability: DriverAvailability[];
  driver: Driver;
  filters: DriverFilters;
  plan: TripPlan;
  tripStore: EntityContainer<TripBoardModel>;
  yard: string;
  handleSelectDriver: (driverId: string, checked: boolean) => void;
}

export default function TripPlannerDriverToggle({
  availability, driver, filters, plan, tripStore, yard, handleSelectDriver
}: Props) {
  const planData = plan.data.plans || {};
  const drafts = planData[driver.entity_id] || [];
  const draftTrips = drafts.flatMap((draftTripId) => tripStore[draftTripId]);
  const numDraftTrips = draftTrips.length || 0;
  const maxOvertime = driver.data.max_overtime || 0;
  const allowOvertime = driver.data.allow_overtime || false;
  const data = draftTrips.sort((a, b) => sortTripByTBDDate(a, b));
  const { start, finish } = getAssignedTimes(data);
  let shiftStart;
  let shiftFinish;
  let tripEndsInOverTime = false;
  availability.forEach((av) => {
    shiftStart = DateService.getHHmmFormat(av.data.start_time);
    shiftFinish = DateService.getHHmmFormat(av.data.finish_time);
    const adjustedFinishTime = getFinishTime(av.data.finish_time, allowOvertime, maxOvertime);
    if (finish > shiftFinish && finish < adjustedFinishTime) tripEndsInOverTime = true;
  });
  const { isWithinShift, textColour, statusMessage } = shiftStart && shiftFinish ?
    checkTripWithinDriverShift({ start: shiftStart, end: shiftFinish }, { start, end: finish }, maxOvertime) :
    { isWithinShift: true, textColour: "text-default", statusMessage: '' };
  return (
    <Stack
      key={driver.entity_id}
      direction="horizontal"
      className="justify-content-between"
    >
      <Stack direction="horizontal" className="align-items-center" gap={2}>
        <DriverInfo
          availability={availability}
          driver={driver}
          start={start}
          finish={finish}
          numTrips={numDraftTrips}
          yard={yard}
        />
        {numDraftTrips > 0 && (
          <p className={`m-0 ${textColour} text-sm`}>
            {getShiftText(numDraftTrips, start, finish, isWithinShift, statusMessage, tripEndsInOverTime)}
          </p>
        )}
      </Stack>
      <Form.Check // prettier-ignore
        type="switch"
        id="board_planner_table_select_company_driver_toggle"
        label=""
        className="float-end"
        data-cy="board_planner_table_select_company_driver_toggle"
        checked={filters.selected[driver.entity_id] ?? false}
        onChange={(e) => handleSelectDriver(driver.entity_id, e.target.checked)}
      />
    </Stack>
  )
}
