import React from 'react';
import { useSelector } from 'react-redux';

import GroupCard from './GroupCard';
import Loader from '../../../components/Loader';
import { GroupView, NoteTemplate } from '../../../redux/models/settings.models';

import {
  selectAreSettingsLoading,
  selectGroupsByBoardId,
} from '../../../redux/slices/settings';
import { selectAreTripsLoading, selectTripsByCardId } from '../../../redux/slices/trips/trips';
import {
  BOARD_PAGE_TABS,
  getUnassignedId,
  LOADING_TEXT,
  getUnassignedCard,
} from '../constants/boards.constants';
import { AppState } from '../../../redux/models/state.models';
import { Board, TripTableColumns } from '../../../redux/models/board.models';

interface Props {
  board: Board;
  headers: TripTableColumns[];
  shouldExpandAllItems: boolean;
  noteTemplates: NoteTemplate[];
}

export default function Groups({
  board,
  headers,
  shouldExpandAllItems,
  noteTemplates,
}: Props) {
  const tabKey = BOARD_PAGE_TABS.GROUPS.eventKey;
  const groupsByBoardId = useSelector((state: AppState) => selectGroupsByBoardId(state, board.entity_id));
  const areSettingsLoading = useSelector(selectAreSettingsLoading);
  const areTripsLoading = useSelector((state: AppState) => selectAreTripsLoading(state));
  const unassignedId = getUnassignedId(tabKey);
  const unassignedTrips = useSelector((state: AppState) => selectTripsByCardId(state, unassignedId));
  const unassignedGroup: GroupView = getUnassignedCard(tabKey);
  return (
    <>
      {areSettingsLoading || areTripsLoading ? <Loader text={LOADING_TEXT} /> : null}
      {unassignedTrips.length > 0 && !areTripsLoading ? (
        <GroupCard
          board={board}
          group={unassignedGroup}
          headers={headers}
          noteTemplates={noteTemplates}
          shouldExpandAllItems={shouldExpandAllItems}
        />
      ) : null}
      {!areTripsLoading && groupsByBoardId.map((group) => (
        <GroupCard
          key={group.entity_id}
          board={board}
          group={group}
          headers={headers}
          noteTemplates={noteTemplates}
          shouldExpandAllItems={shouldExpandAllItems}
        />
      ))}
    </>
  );
}
