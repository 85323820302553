/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import AddEntityModal from '../base/modals/AddEntityModal';
import { ControlInput } from '../../shared/Input';
import { Contact, ContactData, ContactType } from '../../../redux/models/contact.models';
import Permission from '../../shared/permissions/Permission';
import { BillingTypeWritePermissions } from '../../shared/permissions/permissions.utils';
import { getSettingByName } from '../../../redux/slices/settings';
import { Api } from '../../../services/services';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../../constants/core.constants';

const PAGE = 'add_contact_modal';
const TITLE = 'Customer';

const initialContactData: ContactData = {
  name: '',
  types: [ContactType.Customer],
};

interface Props {
  contact?: Contact,
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: (contact: Contact, event: string) => void;
}

function ContactModal({ contact, show, setShow, onUpdate }: Props) {
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState(contact?.data?.name || '');
  const isDisabled = name === '';
  const handleChange = (prop: string, val: string) => {
    if (prop === 'name') setName(val);
  };
  const handleCreate = async () => {
    const entityData: ContactData = {
      ...initialContactData,
      name,
    };
    return Api.Contacts.create(entityData);
  };
  const handleUpdate = () => {
    if (!contact) {
      throw Error(`missing ${TITLE}`);
    }
    const entity: Contact = {
      ...contact,
      data: {
        ...contact.data,
        name,
      },
    };
    return Api.Contacts.update(entity);
  };
  const handleResponse = async (response: AxiosResponse<any, any>, event: string) => {
    if (response.status === 200) {
      await dispatch(getSettingByName(SETTINGS_NAMES.CONTACTS, false));
      toast(`${name} updated.`, { type: 'success' });
      if (onUpdate) {
        const entity = response.data.data;
        onUpdate(entity, event);
      }
      setShow(false);
    }
  }
  const handleSave = async () => {
    if (isDisabled) return;
    try {
      setIsSaving(true);
      if (contact) {
        const response = await handleUpdate();
        handleResponse(response, 'add');
      } else {
        const response = await handleCreate();
        handleResponse(response, 'update');
      }
    } catch (error) {
      toast(`Couldn't update ${name}`, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <Permission resources={BillingTypeWritePermissions}>
      <AddEntityModal
        title={`Add ${TITLE}`}
        btnTitle="Save"
        saveDisabled={isDisabled}
        shouldShowModal={show}
        isSaving={isSaving}
        handleSave={handleSave}
        cancelSave={() => setShow(false)}
      >
        <Stack>
          <ControlInput
            dataCy={`${PAGE}_name_input`}
            name="name"
            type="text"
            page={PAGE}
            value={name}
            labelText="Name"
            handleChange={handleChange}
          />
        </Stack>
      </AddEntityModal>
    </Permission>
  )
};

ContactModal.defaultProps = {
  contact: undefined,
}

export default ContactModal;