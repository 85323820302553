/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Col, Container, Row, ListGroup, Spinner
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { setOrgCode, setPermissions } from '../../config';
import Analytics from '../../utils/analytics';
import { getUserOrganisations } from '../../redux/slices/settings';
import { Organisation } from '../../redux/models/organisation.models';

const TITLE = 'Select Organisation';

interface Props {
  fetch: boolean;
};

function ProjectContainer({ fetch }: Props) {  
  const [orgs, setOrgs] = useState<Organisation[]>([]);
  const [loaded, setLoaded] = useState(false);
  const canSelect = orgs.length > 0;
  const navigate = useNavigate();

  const handleSelect = async (org: Organisation) => {
    const selectedOrg = org.data.organization_code;
    await setOrgCode(selectedOrg);
    navigate(`/${selectedOrg}/boards`);
  };

  useEffect(() => {
    const fetchOrgs = async () => {
      try {
        const appId = process.env.REACT_APP_AQD_APP_ID || '';
        const aqdId = process.env.REACT_APP_AQD_ENTITY_ID || '';
        const { data } = await getUserOrganisations();
        
        const permissions: string[] = data.data.data.permissions || [];
        const allOrgs: Organisation[] = data.data.data.organizations || [];
        setPermissions(permissions);
        const orgData = allOrgs.filter((org) => {
          const appIds = org.data.app_ids || [];
          return appIds.includes(appId) && org.entity_id !== aqdId;
        }).sort((a, b) => {
          const aName = a.data.name || '';
          const bName = b.data.name || '';
          return aName.localeCompare(bName);
        });
        
        if (orgData.length === 1) {
          // set auth header
          const org = orgData[0];
          const orgCode = org.data.organization_code;
          setOrgCode(orgCode);
          navigate(`/${orgCode}/boards`);
        } else {
          setOrgs(orgData);
        }
      } catch (error) {
        Analytics.capture(error);
      } finally {
        setLoaded(true);
      }
    };
    if (!loaded && fetch) fetchOrgs();
  }, [orgs, loaded, fetch]);

  return (
    <section>
      <Container>
        <Row className="mt-4">
          <Col lg="5" className="mx-auto">
            {!loaded && <Spinner animation="border" variant="primary" size="sm" />}
            {canSelect && <h2 className="mb-4 mt-1">{TITLE}</h2>}
            <ListGroup>
              {canSelect && orgs.map((org) => (
                <ListGroup.Item
                  key={org.entity_id}
                  action
                  data-cy={`project-${org.data.name}`}
                  onClick={() => handleSelect(org)}
                >
                  {org.data.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ProjectContainer;
