import React from 'react';
import { useSelector } from 'react-redux';
import { Info, Sliders } from 'react-feather';
import { DropTargetMonitor } from 'react-dnd';
import { Alert, Button, Card, Form, Stack } from 'react-bootstrap';
import { DropdownOptionsModel } from '../../../components/shared/SelectInput.models';
import { BoardSettings } from '../../../redux/models/board.models';
import DriverSection from './Drivers/DriverSection';
import { selectTrips } from '../../../redux/slices/trips/trips';

import { DriverAvailability } from '../../../redux/models/driver.availability.models';
import { DriverFilters, TripPlan } from '../../../redux/slices/planner/planner.models';
import { Driver, Tag } from '../../../redux/models/settings.models';
import { selectDriverFilters, setDriverFilters } from '../../../redux/slices/planner/planner';
import useAppDispatch from '../../../hooks/useAppDispatch';
import DropWrapper from './components/DropWrapper';
import { ItemModel } from './components/ItemModel';

interface Props {
  data: Driver[];
  driverOptions: DropdownOptionsModel[];
  boardSettings: BoardSettings;
  availability: DriverAvailability[];
  plan: TripPlan;
  showFilters: boolean;
  tags: Tag[];
  handleDrop: (item: ItemModel, monitor: DropTargetMonitor<unknown, unknown>, driverId: string) => void;
  handleShowFilters: () => void;
}

export default function TripPlannerDriverCard({
  availability, data, driverOptions, plan, boardSettings, tags, showFilters, handleDrop, handleShowFilters
}: Props) {
  const dispatch = useAppDispatch();
  const tripStore = useSelector(selectTrips);
  const filters = useSelector(selectDriverFilters);
  const {
    available,
    numTrips,
    filtersOpen,
    allDriversOpen,
    tagFilters,
    outsideCartage,
  } = filters;
  const collapseText = allDriversOpen ? 'Collapse All' : 'Expand All';
  const noDriversSelected = data.length === 0;
  const isPlannedTrips = Object.values(plan.data.plans).some((drPlan) => drPlan.length > 0);
  const showEmptyState = noDriversSelected;
  const emptyStateMessage = isPlannedTrips ?
    'Unselected drivers have planned trips assigned to them.' :
    'No drivers selected.';
  const handleSelectTag = (tagId: string, checked: boolean) => {
    const filterData: DriverFilters = {
      ...filters,
      tagFilters: {
        ...tagFilters,
        [tagId]: checked,
      },
    };
    dispatch(setDriverFilters(filterData));
  };
  const handleOpenDrivers = () => {
    const filterData: DriverFilters = {
      ...filters,
      allDriversOpen: !allDriversOpen,
    };
    dispatch(setDriverFilters(filterData));
  };
  const handleNumTrips = (checked: boolean) => {
    const filterData: DriverFilters = {
      ...filters,
      numTrips: checked,
    };
    dispatch(setDriverFilters(filterData));
  };
  const handleAvail = (checked: boolean) => {
    const filterData: DriverFilters = {
      ...filters,
      available: checked,
    };
    dispatch(setDriverFilters(filterData));
  };
  const handleOutsideCartage = (checked: boolean) => {
    const filterData: DriverFilters = {
      ...filters,
      outsideCartage: checked,
    };
    dispatch(setDriverFilters(filterData));
  };
  const handleHideDriver = (driverId: string, checked: boolean) => {
    const filterData: DriverFilters = {
      ...filters,
      selected: {
        ...filters.selected,
        [driverId]: false,
      },
      hidden: {
        ...filters.hidden,
        [driverId]: checked,
      },
    };
    dispatch(setDriverFilters(filterData));
  };
  return (
    <Card>
        {filtersOpen && (
          <Card.Body>
            <Stack>
              <Stack className="pb-2" direction="horizontal" gap={4}>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Drivers With Trips"
                  className="ml-2"
                  data-cy="board_planner_table_trips_toggle"
                  checked={numTrips}
                  onChange={(e) => handleNumTrips(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Available"
                  className="ml-2"
                  data-cy="board_planner_table_available_toggle"
                  checked={available}
                  onChange={(e) => handleAvail(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Outside Cartage"
                  className="ml-2"
                  data-cy="board_planner_table_outside_cartage_toggle"
                  checked={outsideCartage}
                  onChange={(e) => handleOutsideCartage(e.target.checked)}
                />
              </Stack>
              {tags.length > 0 && (
                <>
                  <h6>Tags</h6>
                  <Stack direction="horizontal" className="py-2">
                    {tags.map((tag)=> (
                      <Form.Check // prettier-ignore
                        key={tag.entity_id}
                        type="switch"
                        id={tag.entity_id}
                        label={tag.data.name}
                        className="ml-2"
                        data-cy={`board_planner_table_tag_toggle__${tag.data.name}`}
                        checked={tagFilters[tag.entity_id] || false}
                        onChange={(e) => handleSelectTag(tag.entity_id, e.target.checked)}
                      />
                    ))}
                  </Stack>
                </>
              )}
            </Stack>
          </Card.Body>
        )}
      <Card.Body style={{ maxHeight: '800px', overflowY: 'scroll' }}>
        <Stack gap={3} >
          <Stack direction="horizontal" className="d-flex justify-content-between">
            <h4>{`Drivers (${data.length})`}</h4>
            <Stack direction="horizontal" gap={4}>
              <Button onClick={handleOpenDrivers} variant="link"><h6>{collapseText}</h6></Button>
              <Button
                type="button"
                variant={showFilters ? 'secondary' : 'outline-secondary'}
                onClick={handleShowFilters}>
                <Sliders size={18} />
              </Button>
            </Stack>
          </Stack>
          {showEmptyState && (
            <Alert variant="info" className="p-3">
              <Stack>
                <Stack direction="horizontal">
                  <Info style={{ marginRight: '8px' }} />
                  {emptyStateMessage}
                </Stack>
                <hr />
                <Alert.Link onClick={handleShowFilters}>Open driver filters</Alert.Link>
              </Stack>
            </Alert>
          )}
          {data.map((driver) => {
            const driverAv = availability.filter((av) => av.data.driver?.entity_id === driver.entity_id);
            const planData = plan.data.plans || {};
            const drafts = planData[driver.entity_id] || [];
            const draftTrips = drafts.flatMap((draftTripId) => tripStore[draftTripId]);
            const yard = boardSettings.addresses[driver.data.home_yard_id || '']?.data.samsara_name || '';
            return (
              <DropWrapper
                driverId={driver.entity_id}
                key={driver.entity_id}
                onDrop={handleDrop}
              >
                <DriverSection
                  plan={plan}
                  availability={driverAv}
                  driver={driver}
                  drivers={driverOptions}
                  shouldExpandAllItems={allDriversOpen}
                  trips={draftTrips}
                  yard={yard}
                  handleHideDriver={handleHideDriver}
                />
              </DropWrapper>
            )
          })}
        </Stack>
      </Card.Body>
    </Card>
  );
}
