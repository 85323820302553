/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from "../../utils/axios";

export class PollingProvider {
  private endpoint: string;

  private interval: number;

  private timerId: NodeJS.Timeout | null;

  private isPolling: boolean;
  
  params: any

  constructor(endpoint: string, interval = 5000) {
    this.endpoint = endpoint;
    this.interval = interval;
    this.timerId = null;
    this.isPolling = false;
  }

  private async fetchNotifications(callback: (data: any) => void): Promise<void> {
    try {
      const response = await axiosInstance.post(this.endpoint, this.params);
      if (response.status !== 200) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = response.data.data || [];
      callback(data);
    } catch (error) {
      console.error("Polling error:", error);
    }
  }

  public start(callback: (data: any) => void): void {
    console.log('start polling: ', this.endpoint);
    if (this.isPolling) {
      console.warn("Polling is already started");
      return;
    }
    this.isPolling = true;
    this.poll(callback);
  }

  public stop(): void {
    console.log('stop polling');
    if (!this.isPolling) {
      console.warn("Polling is already stopped");
      return;
    }
    this.isPolling = false;
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  private poll(callback: (data: any) => void): void {
    if (!this.isPolling) {
      return;
    }
    this.fetchNotifications(callback);
    this.timerId = setTimeout(() => this.poll(callback), this.interval);
  }
}

// // Example usage:
// const notificationsEndpoint = "https://api.example.com/notifications";
// const polling = new Polling(notificationsEndpoint, 5000);

// polling.start();

// // To stop polling
// // polling.stop();
