/* eslint-disable */
import React, { useState } from 'react';
import { Form, Button, Alert, Row } from 'react-bootstrap';
import Papa from 'papaparse';
import * as XLSX from 'xlsx-js-style';

interface FileUploadProps {
  onFileProcessed: (headers: string[], data: any[]) => void;
}

export default function FileUpload({ onFileProcessed }: FileUploadProps) {
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const processFile = (file: File) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (fileExtension === 'csv') {
      Papa.parse(file, {
        complete: (results) => {
          if (results.data.length > 0) {
            const headers = results.data[0] as string[];
            const rows = results.data.slice(1) as any[];
            onFileProcessed(headers, rows);
          } else {
            setError('The CSV file appears to be empty.');
          }
        },
        error: (error) => {
          setError(`Error processing CSV file: ${error.message}`);
        },
      });
    } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          
          if (jsonData.length > 0) {
            const headers = jsonData[0] as string[];
            const rows = jsonData.slice(1) as any[];
            onFileProcessed(headers, rows);
          } else {
            setError('The Excel file appears to be empty.');
          }
        } catch (err) {
          setError('Error processing Excel file. Please make sure it\'s a valid Excel file.');
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      setError('Unsupported file type. Please upload a CSV or Excel file.');
    }
  };

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      processFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      processFile(e.target.files[0]);
    }
  };

  return (
    <Row>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Upload Excel or CSV file</Form.Label>
        <div
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          className={`p-5 ${dragActive ? 'border-primary' : 'border-secondary'} text-center`}
        >
          <p>Drag and drop your file here, or</p>
          <Button variant="outline-primary" onClick={() => document.getElementById('fileInput')?.click()}>
            Select File
          </Button>
          <Form.Control
            type="file"
            id="fileInput"
            className="d-none"
            onChange={handleChange}
            accept=".xlsx,.xls,.csv"
          />
        </div>
      </Form.Group>
      {error && <Alert variant="danger">{error}</Alert>}
    </Row>
  );
}