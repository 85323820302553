/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { DateService } from '../../utils/dateService';
import useAuth from '../../hooks/useAuth';
import { getOAuth2Url } from '../../redux/slices/organization/organisation';
import Analytics from '../../utils/analytics';
import AsyncButton from '../../components/shared/buttons/AsyncButton';
import { capitalizeFirstLetter } from '../../utils/core.utils';

export enum AuthenticationTypes {
  ApiKey = 'api_key',
  Credentials = 'credentials',
  OAuth = 'oauth',
  OAuth2 = 'oauth2',
}

export enum IntegrationStatus {
  Active = 'active',
  Error = 'error',
  InActive = 'inactive',
  Waiting = 'waiting_oauth2',
  None = '',
}

const getBtnTitle = (connected: boolean) => (connected ? 'Connected' : 'Connect');
const getBtnColour = (connected: boolean) => {
  return connected ? 'success' : 'primary';
};
const getStatus = (status: IntegrationStatus) => {
  if (status === IntegrationStatus.Waiting) {
    return 'Waiting';
  }
  if (status === IntegrationStatus.None || status === IntegrationStatus.InActive) {
    return 'In Active';
  }
  return capitalizeFirstLetter(status);
};
const getBadgeColour = (status: IntegrationStatus) => {
  if (status === IntegrationStatus.Error) {
    return 'danger';
  }
  if (status === IntegrationStatus.Waiting) {
    return 'warning';
  }
  if (status === IntegrationStatus.None || status === IntegrationStatus.InActive) {
    return 'secondary';
  }
  return 'success';
};

const getAuthIntegrations = (app: any, integrations: Array<any>, userId: string) => {
  const types = app.data.authentication_types || [];
  if (types.includes(AuthenticationTypes.OAuth2)) {
    const userIntegrations = integrations.filter((intes) => userId !== '' && intes.author === userId);
    return userIntegrations;
  }
  return integrations;
};

const getCurrentIntegration = (integrations: Array<any>) => {
  if (integrations.length < 1) return null;
  if (integrations.length === 1) return integrations[0];
  const sortedInts = [...integrations].sort((a, b) => {
    const aTime = a.modified_at || a.created_at;
    const bTime = b.modified_at || b.created_at;
    if (!aTime) return 1;
    if (!bTime) return -1;
    const aDate = DateService.getUnixTime(aTime);
    const bDate = DateService.getUnixTime(bTime);
    return bDate - aDate;
  });
  return sortedInts[0];
};

interface Props {
  app: any;
  integrations: Array<any>;
}

export default function IntegrationListItem({ app, integrations }: Props) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const userId = user?.id || '';
  const userIntegrations = getAuthIntegrations(app, integrations, userId);
  const integration = getCurrentIntegration(userIntegrations);
  const isConnected = integration !== null;
  const appId = app?.entity_id || '';
  const imgUrl = app?.data?.logo_url || '';
  const status = integration?.data?.status || IntegrationStatus.None;
  const authTypes = app?.data.authentication_types || [];
  const isOAuth2 = authTypes.includes(AuthenticationTypes.OAuth2);
  const name = app.data.name || '';

  const handleAuth = async () => {
    if (!appId) return;
    try {
      setIsLoading(true);
      const urlResponse = await getOAuth2Url(appId);
      if (urlResponse.status === 200) {
        const redirectUrl = urlResponse.data.data || '';
        if (redirectUrl !== '') window.open(redirectUrl);
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Card>
      <Card.Img variant="top" src={imgUrl} />
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        <Card.Text>
          <Badge bg={getBadgeColour(status)}>{getStatus(status)}</Badge>
        </Card.Text>
        {isOAuth2 && (
          <AsyncButton
            title={getBtnTitle(isConnected)}
            disabled={isLoading}
            id="integration-btn"
            dataCy="integration-btn"
            variant={getBtnColour(isConnected)}
            spinner="light"
            handleClick={handleAuth}
          />
        )}
      </Card.Body>
    </Card>
  );
}
