import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TripPlan } from '../../../redux/slices/planner/planner.models';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { setCurrentPlan } from '../../../redux/slices/planner/planner';
import { ControlTextArea } from '../../../components/shared/Input';

const CANCEL = 'Cancel';

interface Props {
  plan: TripPlan;
  shouldShow: boolean;
  onClose: () => void;
}

export default function DraftNotesModal({
  plan,
  shouldShow,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const [notes, setNotes] = useState(plan.data.notes || '');
  const handleChange = (_: string, value: string) => {
    setNotes(value);
  };
  const handleCancel = () => {
    onClose();
  };
  const handleSave = () => {
    const updates: TripPlan = {
      ...plan,
      data: {
        ...plan.data,
        notes,
      }
    };
    dispatch(setCurrentPlan(updates));
    onClose();
  };
  return (
    <Modal
      show={shouldShow}
      onHide={handleCancel}
      centered
      data-cy="delete_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ControlTextArea
          labelText=""
          name="notes"
          page="{page}"
          value={notes}
          rows="4"
          handleChange={handleChange}
          dataCy="shipment_notes"
          disabled={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button id="delete_modal__cancel_btn" variant="secondary" onClick={handleCancel}>
          {CANCEL}
        </Button>
        <Button
          id="draft_notes_modal_btn"
          data-cy="draft_notes_modal_btn"
          variant="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
