/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import DeleteModal from '../../../../components/modals/DeleteModal';
import { getSettingByName } from '../../../../redux/slices/settings';
import { SETTINGS_NAMES } from '../../../../constants/core.constants';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { deleteShipmentType } from '../../../../redux/slices/shipment.types';
import { ShipmentType } from '../../../../redux/models/shipment.models';

interface Props {
  type: ShipmentType
}

export default function ShipmentDetailsSettingsListItem({ type }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const [showDelete, setShowDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    await deleteShipmentType(type);
    await dispatch(getSettingByName(SETTINGS_NAMES.SHIPMENT_TYPES));
    await setShowDelete(false);
    setDeleting(false);
  };

  const handleEdit = () => navigate(`/${orgCode}/settings/shipment-type/${type.entity_id}`);

  return (
    <tr>
      <td>{type.data.name || ''}</td>
      <td>
        <div className="float-end justify-content-end">
          <DropdownButton id="dropdown-basic-button" title="Options">
            <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => setShowDelete(true)}>Delete</Dropdown.Item>
          </DropdownButton>
        </div>
      </td>
      <DeleteModal
        title="Delete Shipment Type"
        description={`Would you like to delete ${type.data.name || 'shipment type'}?`}
        deleteBtnText="Delete"
        isDeleting={isDeleting}
        shouldShowDeleteModal={showDelete}
        deleteItem={handleDelete}
        cancelDeletion={() => setShowDelete(false)}
      />
    </tr>
  );
}
