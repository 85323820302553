import { useState } from 'react';
import { useSelector } from 'react-redux';

import TrailerChangeAlertList from './components/trailer-change-alert/TrailerChangeAlertList';
import {
  TrailerChangeSaveModal,
  TrailerChangeModal,
} from '../../../components/modals/TrailerChangeModal/TrailerChangeModal';

import { selectFleetObject } from '../../../redux/slices/settings';
import {
  resetAssociatedTrips,
  selectMoveState,
  selectUpdatedAssociatedTrips,
  updateTrailerChanges
} from '../../../redux/slices/trailer-changes/trailerChanges';
import Analytics from '../../../utils/analytics';
import useAppDispatch from '../../../hooks/useAppDispatch';

const TRAILER_CHANGE_ID = 'trailerChanges';

interface Props {
  showTrailerSaveModal: boolean;
  showTrailerChangeModal: boolean;
  isSaving: boolean;
  shouldDisableSave: boolean;
  tripId: string;
  showTrailers: boolean;
  handleSave: () => void;
  handleModalClose: () => void;
  handleChangeModals: () => void;
}

export default function MoveFooter({
  showTrailerSaveModal,
  handleSave,
  showTrailerChangeModal,
  isSaving,
  shouldDisableSave,
  handleModalClose,
  handleChangeModals,
  tripId,
  showTrailers,
}: Props) {
  const dispatch = useAppDispatch();
  const fleet = useSelector(selectFleetObject);
  const moveState = useSelector(selectMoveState);
  const updatedTrips = useSelector(selectUpdatedAssociatedTrips);
  const [associatedTripsSaving, setAssociatedTripsSaving] = useState(false);
  const handleTrailerChangeSave = async () => {
    console.log(updatedTrips);
    try {
      setAssociatedTripsSaving(true);
      const response = await updateTrailerChanges(updatedTrips);
      console.log(response.data.data);
      dispatch(resetAssociatedTrips());
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setAssociatedTripsSaving(false);
    }
  };
  return (
    <>
      {showTrailers && (
        <TrailerChangeAlertList
          name={TRAILER_CHANGE_ID}
          handleChangeModals={handleChangeModals}
          fleet={fleet}
        />
      )}
      {showTrailerChangeModal && (
        <TrailerChangeModal
          name={TRAILER_CHANGE_ID}
          tripId={tripId}
          moveState={moveState}
          showTrailerChangeModal={showTrailerChangeModal}
          handleModalClose={handleModalClose}
          fleet={fleet}
          handleTrailerChangeSave={handleTrailerChangeSave}
          isSaving={associatedTripsSaving}
        />
      )}
      <TrailerChangeSaveModal
        showTrailerSaveModal={showTrailerSaveModal}
        handleChangeModals={handleChangeModals}
        handleSave={handleSave}
        fleet={fleet}
        handleModalClose={handleModalClose}
        shouldDisableSave={shouldDisableSave}
        isSaving={isSaving}
      />
    </>
  );
}
