import { DateService } from '../../../utils/dateService';

export const getDefaultDate = (date) => {
  switch (date) {
    case 'today':
      return ({
        dateFrom: DateService.getStartOfTheDayISO(),
        dateTo: DateService.getEndOfTheDayISO(),
      });
    case 'tomorrow':
      return ({
        dateFrom: DateService.getStartOfTheDayISO(DateService.addDaysToDateISO(null, 1)),
        dateTo: DateService.getEndOfTheDayISO(DateService.addDaysToDateISO(null, 1)),
      });
    case 'yesterday':
      return ({
        dateFrom: DateService.getStartOfTheDayISO(DateService.subtractDaysFromDateISO(null, 1)),
        dateTo: DateService.getEndOfTheDayISO(DateService.subtractDaysFromDateISO(null, 1)),
      });
    case 'last-seven-days':
      return ({
        dateFrom: DateService.getStartOfTheDayISO(DateService.subtractDaysFromDateISO(null, 7)),
        dateTo: DateService.getEndOfTheDayISO(),
      });
    case 'last-thirty-days':
      return ({
        dateFrom: DateService.getStartOfTheDayISO(DateService.subtractDaysFromDateISO(null, 30)),
        dateTo: DateService.getEndOfTheDayISO(),
      });
    case 'this-month':
      return ({
        dateFrom: DateService.getStartOfTheMonthISO(),
        dateTo: DateService.getEndOfTheMonthISO(),
      });
    case 'last-month':
      return ({
        dateFrom: DateService.getStartOfTheMonthISO(DateService.subtractMonthsFromDateISO(null, 1)),
        dateTo: DateService.getEndOfTheMonthISO(DateService.subtractMonthsFromDateISO(null, 1)),
      });
    default:
      return ({
        dateFrom: '',
        dateTo: '',
      });
  }
};

export const createFilterOptions = (items, name) => {
  const options = [...new Set(items.map((item) => item[name]))];
  const updatedOptions = options.filter((option) => option !== '')
    .map((option) => ({
      value: option,
      label: option,
    }));
  // updatedOptions.unshift(ALL_OPTIONS);
  return updatedOptions;
};

export const createFiltersWithNoneOption = (items, name) => {
  const options = [...new Set(items.map((item) => item[name]))];
  const updatedOptions = options.filter((option) => option !== '')
    .map((option) => ({
      value: option,
      label: option,
    }));
  // updatedOptions.unshift(NO_OPTIONS);
  // updatedOptions.unshift(ALL_OPTIONS);
  return updatedOptions;
};

export const getFilterOptions = (options) => {
  const newOptions = [...Object.values(options)];
  // newOptions.unshift(ALL_OPTIONS);
  return newOptions;
};

export const getAllOptions = (options) => {
  const newOptions = [...Object.values(options)];
  // newOptions.unshift(NO_OPTIONS);
  // newOptions.unshift(ALL_OPTIONS);
  return newOptions;
};
