import React, { useState } from 'react';
import AddModal from '../../../components/modals/AddModal';
import RadioInput from '../../../components/shared/inputs/RadioInput';

interface Props {
  shouldShow: boolean;
  isSaving: boolean;
  onDuplicate: (duplicateShipments: boolean) => void;
  onCancel: () => void;
}

export default function DuplicateTripModal({
  shouldShow,
  isSaving,
  onDuplicate,
  onCancel,
}: Props) {
  const [option, setOption] = useState('');
  const isDisabled = option === '';
  const onSelect = (_: string, value: string) => {
    setOption(value)
  };
  const handleDuplicate = () => {
    const isTrips = option === '2';
    onDuplicate(isTrips);
  };
  return (
    <AddModal
      title="Duplicate Trip"
      btnTitle="Duplicate"
      saveDisabled={isDisabled}
      shouldShowModal={shouldShow}
      isSaving={isSaving}
      handleSave={handleDuplicate}
      cancelSave={onCancel}
    >
      <RadioInput id="1" label="Duplicate trip" onChange={onSelect} />
      <RadioInput id="2" label="Duplicate trip and shipments" onChange={onSelect} />
    </AddModal>
  );
}
