/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { AquadashServiceApiProvider } from '../../services/AquadashServiceApiProvider';
import { getInitialState, getError } from '../models/mention.models';
import { AppState, DispatchProps } from '../models/state.models';
import { MENTIONS } from './slices';

const SLICE_NAME = MENTIONS;
const initialState = getInitialState();

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setMentions(state, action) {
      state.mentions = action.payload;
    },
    setIsMentionLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsMentionLoaded(state, action) {
      state.isLoaded = action.payload;
    },
    resetMentions: () => getInitialState(),
  },
});

export const {
  setMentions,
  setIsMentionLoading,
  setIsMentionLoaded,
  resetMentions,
} = slice.actions;

export const getMentions = () => async (dispatch: DispatchProps) => {
  dispatch(setIsMentionLoading(true));
  try {
    const { data } = await AquadashServiceApiProvider.getMentions();
    dispatch(setIsMentionLoaded(true));
    dispatch(setMentions(data.data));
  } catch (e) {
    getError(e);
  } finally {
    dispatch(setIsMentionLoading(false));
  }
};

export const selectMentions = (state: AppState) => state[SLICE_NAME].mentions;
export const selectIsMentionsLoaded = (state: AppState) => state[SLICE_NAME].isLoaded;
export const selectIsMentionsLoading = (state: AppState) => state[SLICE_NAME].isLoading;

export const mentionsReducer = slice.reducer;
