import React from 'react';
import { ControlInput } from '../shared/Input';
import { getTimeliness, getColour } from '../../utils/timeliness';

interface Props {
  scheduled?: string | null;
  actual?: string | null;
}

function TimelinessBadge({ scheduled, actual }: Props) {
  const timeliness = getTimeliness(scheduled, actual);
  const textColour = timeliness ? `text-${getColour(timeliness)}` : 'text-dark';
  const noAction = () => {};
  return (
    <ControlInput
      name={`move_timeliness ${textColour}`}
      page="move_timeliness"
      type="text"
      value={timeliness || ''}
      shouldHaveMargin={false}
      handleChange={noAction}
      dataCy="move_timeliness"
      disabled
      labelText=""
    />
  )
}

TimelinessBadge.defaultProps = {
  scheduled: null,
  actual: null,
};

export default TimelinessBadge;
