import { createSlice } from '@reduxjs/toolkit';
import{
  getInitialState,
  getError,
  getInitialDriverFilterState,
  initialTripFilterState,
  TripPlan,
  TripPlanData,
  DriverFilters,
  saveSelectedDrivers,
} from './planner.models';
import { AppState, DispatchProps } from '../../models/state.models';
import { PLANNER } from '../slices';
import { Address, Driver } from '../../models/settings.models';
import { TripBoardModel } from '../../models/trip.models';
import { EntityContainer } from '../../models/core.models';
import { getUnassignedTrips, mergePlan, optimiseTripsHandler, updateDraft } from './planner.utils';
import { DriverAvailability } from '../../models/driver.availability.models';
import TripPlanApi from '../../../services/planner/TripPlanApi';
import { TripPlanDispatchProps } from '../../../services/planner/TripPlanApi.models';

const SLICE_NAME = PLANNER;
const initialState = getInitialState();

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setMergeNewTrips(state, action) {
      const store: EntityContainer<TripBoardModel> = action.payload.trips || {};
      const drivers: EntityContainer<Driver> = action.payload.drivers || {};
      const planId = state.selectedId;
      if (planId === '' || state.isLoading || !state.isLoaded) return;
      const plan = state.plans[planId];
      if (!plan) return;
      const unmerged = getUnassignedTrips(plan, store);
      const isMergeRequired = (unmerged.length || 0) > 0;
      if (isMergeRequired) {
        const merged = mergePlan(plan, unmerged, drivers);
        state.plans = {
          ...state.plans,
          [planId]: merged,
        };
      }
    },
    // setMergeNewTrips(state, action) {
    //   const trips: TripBoardModel[] = action.payload || [];
    //   const planId = state.selectedId;
    //   const plan = state.plans[planId];
    //   const merged = mergePlan(plan, trips);
    //   state.plans = {
    //     ...state.plans,
    //     [planId]: merged,
    //   };
    // },
    setPlans(state, action) {
      state.plans = action.payload;
    },
    setIsPlanLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsPlanLoaded(state, action) {
      state.isLoaded = action.payload;
    },
    setDriverFilters(state, action) {
      const filters: DriverFilters = action.payload;
      const selected = filters.selected || {};
      state.driverFilters = action.payload;
      saveSelectedDrivers(selected);
    },
    setTripFilters(state, action) {
      state.tripFilters = action.payload;
    },
    setSelectedPlannerTab(state, action) {
      state.selectedTab = action.payload;
    },
    setDraftDriver(state, action) {
      const { driverId, trip } = action.payload;
      const tripId = trip.id;
      const planId = state.selectedId;
      const draft = state.plans[planId];
      const newPlan = updateDraft(draft, driverId, tripId);
      state.plans = {
        ...state.plans,
        [planId]: newPlan,
      };
    },
    setCurrentPlan(state, action) {
      const plan: TripPlan = action.payload;
      state.plans = {
        ...state.plans,
        [plan.entity_id]: plan,
      }
      state.selectedId = plan.entity_id;
    },
    removeCurrentPlan(state, action) {
      const planId = action.payload;
      const updates = {
        ...state.plans,
      };
      delete updates[planId];
      state.selectedId = '';
    },
    resetFilters(state) {
      state.driverFilters = getInitialDriverFilterState();
      state.tripFilters = initialTripFilterState;
    },
    resetPlans: () => getInitialState(),
  },
});

export const {
  removeCurrentPlan,
  setMergeNewTrips,
  setCurrentPlan,
  setDriverFilters,
  setTripFilters,
  setDraftDriver,
  setPlans,
  setIsPlanLoading,
  setIsPlanLoaded,
  setSelectedPlannerTab,
  resetPlans,
  resetFilters,
} = slice.actions;

export const fetchDraftPlans = (boardId: string, from: string, to: string) => async (dispatch: DispatchProps) => {
  dispatch(setIsPlanLoading(true));
  try {
    const response = await TripPlanApi.fetchDrafts(boardId, from, to);
    if (response.status === 200) {
      const plans = response.data.data || [];
      dispatch(setIsPlanLoaded(true));
      dispatch(setPlans(plans));
      if (plans.length > 0) {
        dispatch(setCurrentPlan(plans[0]));
      }
    }
  } catch (e) {
    getError(e);
  } finally {
    dispatch(setIsPlanLoading(false));
  }
};

export const optimisePlan = (plan: TripPlan, driverIds: string[], greedy: boolean) => {
  return TripPlanApi.optimise(plan, driverIds, greedy);
};

export const createDraft = (plan: TripPlanData) => {
  return TripPlanApi.create(plan);
};

export const saveDraft = (plan: TripPlan) => {
  return TripPlanApi.update(plan);
};

export const fetchDraft = (plan: TripPlan) => {
  return TripPlanApi.getById(plan.entity_id);
};

export const applyDraft = (plan: TripPlan) => {
  return TripPlanApi.applyDraft(plan);
};

export const dispatchDraft = (plan: TripPlan, dispatch: TripPlanDispatchProps) => {
  return TripPlanApi.dispatchDraft(plan, dispatch);
};

export const updateDraftDriver = (driverId: string, trip: TripBoardModel) => async (dispatch: DispatchProps) => {
  dispatch(setDraftDriver({ driverId, trip }))
};

export const optimiseTrips = (
  date: string,
  availability: DriverAvailability[],
  drivers: Driver[],
  trips: EntityContainer<TripBoardModel>,
  addresses: EntityContainer<Address>,
  greedy: boolean,
) => {
  return optimiseTripsHandler(
    date,
    availability,
    drivers,
    trips,
    addresses,
    greedy
  );
};

export const selectPlans = (state: AppState) => state[SLICE_NAME].plans;
export const selectDriverFilters = (state: AppState) => state[SLICE_NAME].driverFilters;
export const selectTripFilters = (state: AppState) => state[SLICE_NAME].tripFilters;
export const selectPlannerTab = (state: AppState) => state[SLICE_NAME].selectedTab;
export const selectPlansArr = (state: AppState) => {
  const plans = selectPlans(state);
  return [...Object.values(plans)].sort((a, b) => {
    const aName = a.data.name || '';
    const bName = b.data.name || '';
    return aName.localeCompare(bName);
  });
};
export const selectIsPlansLoaded = (state: AppState) => state[SLICE_NAME].isLoaded;
export const selectIsPlansLoading = (state: AppState) => state[SLICE_NAME].isLoading;
export const selectCurrentPlan = (state: AppState) => {
  const id = state[SLICE_NAME].selectedId;
  return state[SLICE_NAME].plans[id];
};
export const selectPlan = (state: AppState, name: string) => selectPlans(state)[name];
export const selectDraftByTripId = (state: AppState, tripId: string) => {
  const plan = selectCurrentPlan(state);
  const planData = plan.data.plans || {};
  return Object.values(planData).flatMap((plans) => plans).find((draft) => draft === tripId);
};

export const plannerReducer = slice.reducer;
