/* eslint-disable max-len */
import { VERSION_NO } from '../version-updates/UpdateList';

const aquadashLogo = 'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/640b6ff7561700f7f0bddbfb_AquaDash-transparent.png';
const LOGO_URL = process.env.REACT_APP_LOGO_URL || aquadashLogo;
const defaultLogoUrl =
  'https://uploads-ssl.webflow.com/64bb0882e7ccd4a6c9875041/64bb0f84778125db8721558a_Light%20Logo.svg';
const logoUrl = process.env.REACT_APP_COVER_IMG_URL || defaultLogoUrl;
const SIDEBAR_URL = logoUrl;
const APP_NAME = process.env.REACT_APP_NAME || 'Dispatch Driven';

export const TEXTS = {
  LOGO_URL,
  VERSION: VERSION_NO,
  TITLE: APP_NAME,
  SIDEBAR_IMG: SIDEBAR_URL,
  DESCRIPTION: 'Dispatching and tracking portal',
  LOGIN: 'Login',
};
