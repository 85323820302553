import React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedRequest } from '../../redux/slices/notifications/notifications';
import AsyncDispatchMessageModal from '../modals/AsyncDispatchMessageModal/AsyncDispatchMessageModal';

export default function DispatchNotificationContainer() {
  const dispatches = useSelector(selectSelectedRequest);
  return (
    <div>
      {dispatches && (
        <AsyncDispatchMessageModal dispatch={dispatches} />
      )}
    </div>
  );
}
