import { DateService } from '../../../../utils/dateService';

const formatStartDate = (shipmentData) => {
  if (shipmentData && shipmentData.dates) {
    const startDate = shipmentData.dates.start_date || '';
    return DateService.getDoMMMFormat(startDate);
  }
  return '';
};

const formatUnix = (shipmentData) => {
  if (shipmentData && shipmentData.dates) {
    const startDate = shipmentData.dates.start_date || '';
    return DateService.getUnixTime(startDate);
  }
  return 0;
};

export const formatShipments = (
  shipments,
  contactsObject,
  billingTypeObject,
  addressesObject,
) => [...shipments].map((shipment) => ({
  id: shipment.entity_id,
  customer: contactsObject[shipment.data?.customer_id]?.data?.name || '',
  shipment_no: shipment.data?.shipment_no,
  billing_type: billingTypeObject[shipment.data?.billing_type_id]?.data?.name || '',
  type: shipment.data.type || '',
  origin: addressesObject[shipment.data?.origin_id]?.data?.samsara_name || 'Origin',
  destination: addressesObject[shipment.data?.destination_id]?.data?.samsara_name || 'Destination',
  status: shipment.data?.status || '',
  startDate: formatStartDate(shipment.data || {}),
  purchase_order_no: shipment.data?.purchase_order_no || '',
  unix: formatUnix(shipment.data || {}),
})).sort((a, b) => b.unix - a.unix);

export const formatModalShipments = (
  shipments,
  contactsObject,
  billingTypeObject,
  addressesObject,
) => shipments.map((shipment) => ({
  id: shipment.entity_id,
  customer: contactsObject[shipment.data?.customer_id]?.data?.name || '',
  shipment_no: shipment.data?.shipment_no,
  container_no: shipment.data?.container_no,
  purchase_order_no: shipment.data?.purchase_order_no || '',
  type: billingTypeObject[shipment.data?.billing_type_id]?.data?.name,
  status: shipment.data?.status || '',
  start_date: formatStartDate(shipment.data || {}),
  route: `${addressesObject[shipment.data?.origin_id]?.data?.samsara_name || 'Origin'} 
    >> ${addressesObject[shipment.data?.destination_id]?.data?.samsara_name || 'Destination'}`,
})).sort((a, b) => b.unix - a.unix);

export const formatBoardShipments = (shipments, contactsObject, billingTypeObject) => shipments.map((shipment) => ({
  id: shipment.entity_id,
  shipment_no: shipment.data?.shipment_no,
  customer: contactsObject[shipment?.customer_id]?.data?.name,
  type: billingTypeObject[shipment?.billing_type_id]?.data?.name,
  purchase_order_no: shipment.data?.purchase_order_no,
})).sort((a, b) => b.unix - a.unix);
