/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row, Col } from 'react-bootstrap';

import { SelectInputWithSearch } from '../../../../../components/shared/Input';

import { getSelectedOptions } from '../../../../../utils/core.utils';
import { Shipment, ShipmentTypeFieldData } from '../../../../../redux/models/shipment.models';
import { isFieldError } from './shipment.form.utils';
import { EntityContainer } from '../../../../../redux/models/core.models';
import { ShipmentErrorLabel } from './ShipmentField';
// import ContactInput from '../../../../../components/entities/contacts/ContactInput';

const FORM_TITLE = 'Customer';

interface Props {
  shipmentDetails: Shipment;
  customerSelectOptions: any;
  handleChange: any;
  page: string;
  disabled: boolean;
  fields: ShipmentTypeFieldData;
  validation: EntityContainer<boolean>;
}

export default function CustomerForm({
  shipmentDetails,
  customerSelectOptions,
  handleChange,
  page,
  disabled,
  validation,
  fields,
}: Props) {
  const shipmentField = fields?.customer_id;
  const isVisible = shipmentField?.visible || false;
  const key = shipmentField?.key || '';
  // const consigneeField = fields.consignee_id;
  return (
    <Row className="mt-4">
      <h4>{FORM_TITLE}</h4>
      {isVisible && (
        <Col md={6}>
          <SelectInputWithSearch
            labelText="Customer"
            name={key}
            page={page}
            value={shipmentDetails.data.customer_id}
            options={getSelectedOptions(shipmentDetails.data.customer_id, customerSelectOptions)}
            settingName="contact"
            apiQueryParam="name"
            handleChange={handleChange}
            dataCy={key}
            disabled={disabled}
          />
          {shipmentField && isFieldError(shipmentField, validation) && (
            <ShipmentErrorLabel field={shipmentField} />
          )}
        </Col>
      )}
      {/* {consigneeField.visible && (
        <Col md={6}>
          <ContactInput
            label={getName(consigneeField)}
            page={page}
            value={shipmentDetails.data.consignee_id || ''}
            options={getSelectedOptions(shipmentDetails.data.consignee_id, customerSelectOptions)}
            onChange={handleChange}
            allowAdd={false}
            disabled={disabled}
            onNewEntity={() => {}}
            prop="consignee_id"
          />
          {isFieldError(shipmentField, validation) && (
            <ShipmentErrorLabel field={consigneeField} />
          )}
        </Col>
      )} */}
    </Row>
  );
}