import { EntityContainer } from "../../../redux/models/core.models";
import { Move } from "../../../redux/models/trip.models";
import { ApiQuery, FilterOp, QueryOperator } from '../../../redux/models/network.models';
import { Timeliness, getTimeliness } from '../../../utils/timeliness';

export const getAddressMoves = (moves: Move[]) => {
  if (!moves || moves.length === 0) return {};
  const container: EntityContainer<Move[]> = {};
  return moves.reduce((store, move) => {
    const addressId = move.data.destination_id || '';
    const existing = store[addressId] || [];
    return {
      ...store,
      [addressId]: [...existing, move],
    };
  }, container);
};

export const getBoardMoves = (moves: Move[]) => {
  if (!moves || moves.length === 0) return {};
  const container: EntityContainer<Move[]> = {};
  return moves.reduce((store, move) => {
    const boardId = move.data.board_id || '';
    const existing = store[boardId] || [];
    return {
      ...store,
      [boardId]: [...existing, move],
    };
  }, container);
};

export const moveQuery = (dateFrom: string, dateTo: string, action: string): ApiQuery => {
  return {
    filters: [
      {
        field: {
          name: 'data.actual_arrival_time',
          type: 'datetime',
        },
        op: FilterOp.GreaterThanOrEqual,
        value: dateFrom,
      },
      {
        field: {
          name: 'data.actual_arrival_time',
          type: 'datetime',
        },
        op: FilterOp.LessThan,
        value: dateTo,
      },
      {
        field: {
          name: 'data.action',
          type: 'string',
        },
        op: FilterOp.Equals,
        value: action,
      },
    ],
    operator: QueryOperator.And,
    pagination: {
      page: 0,
      page_size: 500,
    }
  }
}

export const calculateTime = (moves: Move[], target: Timeliness | null) => {
  const amount = moves.filter((move) => {
    const scheduled = move.data.scheduled_arrival_time;
    const actual = move.data.actual_arrival_time;
    const timeliness = getTimeliness(scheduled, actual);
    return timeliness === target;
  });
  // const percent = (amount.length / moves.length) * 100;
  // return Math.trunc(percent);
  return amount.length;
};
