import { useEffect, useState, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { selectShipmentTypes } from '../../../../redux/slices/settings';
import { ShipmentDropdownOptions, ShipmentType } from '../../../../redux/slices/shipment-list/shipment-list.models';
import { EntityContainer } from '../../../../redux/models/core.models';
import { Contact } from '../../../../redux/models/contact.models';
import { BillingType } from '../../../../redux/models/billing.models';
import { DateService } from '../../../../utils/dateService';

const getUnknownName = (groupBy: string) => {
  const option = Object.values(ShipmentDropdownOptions).find((opt) => {
    return opt.id === groupBy;
  });
  return option?.value || '';
};

const getType = (
  groupBy: string,
  groupByKey: string,
  types: ShipmentType[],
  contacts: EntityContainer<Contact>,
  billingTypes: EntityContainer<BillingType>
) => {
  if (groupBy === 'customer') {
    return contacts[groupByKey]?.data.name || '';
  }
  if (groupBy === 'billing_type') {
    return billingTypes[groupByKey]?.data.name || '';
  }
  if (groupBy === 'start_date') {
    return DateService.getDoMMMFormat(groupByKey);
  }
  const type = types.find((entity) => entity.entity_id === groupByKey);
  if (type) {
    return type.data.name || '';
  }
  return null;
};

interface Props {
  groupBy: string;
  groupByKey: string;
  shouldExpandAllItems: boolean;
  contacts: EntityContainer<Contact>;
  billingTypes: EntityContainer<BillingType>;
  children: ReactNode,
}

export default function ShipmentListGroupCard({
  groupByKey,
  groupBy,
  shouldExpandAllItems,
  billingTypes,
  contacts,
  children,
}: Props) {
  const [isItemExpanded, setIsItemExpanded] = useState(shouldExpandAllItems);
  const types = useSelector(selectShipmentTypes);
  useEffect(() => setIsItemExpanded(shouldExpandAllItems), [shouldExpandAllItems]);
  const name = getType(groupBy, groupByKey, types, contacts, billingTypes) || groupByKey;
  return (
    <Accordion activeKey={isItemExpanded ? '0' : ''}>
      <Card>
        <Accordion.Item eventKey="0">
          <Card.Header className="group-card-header">
            <Card.Title className="group-card-title">
              {name || `No ${getUnknownName(groupBy)}`}
            </Card.Title>
            <div
              className="custom-collapse-icon-wrapper"
              onClick={() => setIsItemExpanded(!isItemExpanded)}
            >
              <FontAwesomeIcon
                width={25}
                height={25}
                icon={faChevronDown}
                className="custom-collapse-icon"
              />
            </div>
          </Card.Header>
          <Accordion.Body style={{ background: 'white', padding: 0 }}>
            <Card.Body>
              {children}
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  );
}