/* eslint-disable @typescript-eslint/no-explicit-any */
import { Shipment } from '../redux/models/shipment.models';
import axiosInstance from '../utils/axios';
import { DateService } from '../utils/dateService';

import { AquadashServiceConfigProvider } from './AquadashServiceConfigProvider';

export class ShipmentServiceApiProvider {
  static createShipment(shipmentTypeId = '') {
    const now = new Date();
    const utc = DateService.getUtcISOString(now);
    const postBody = {
      type: '',
      customer_id: '',
      billing_type_id: '',
      status: 'booked',
      trip_ids: [],
      type_id: shipmentTypeId,
      requested_drop_off_date: utc,
      requested_pick_up_date: utc
    };
    return axiosInstance.post(AquadashServiceConfigProvider.createShipment(), postBody);
  }

  static createShipmentOnTrip(data: any) {
    const now = new Date();
    const utc = DateService.getUtcISOString(now);
    const postBody = {
      ...data,
      requested_drop_off_date: utc,
      requested_pick_up_date: utc
    };
    return axiosInstance.post(AquadashServiceConfigProvider.createShipment(), postBody);
  }

  static createBoardShipment(body: any) {
    return axiosInstance.post(AquadashServiceConfigProvider.createBoardShipment(), body);
  }

  static getShipments(filters: any) {
    return axiosInstance.get(AquadashServiceConfigProvider.getShipments(filters));
  }

  static getShipmentsByTypeId(filters: any, shipmentTypeId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getShipmentsByTypeId(filters, shipmentTypeId));
  }

  static getShipmentsAllDetails(filters: any) {
    return axiosInstance.get(AquadashServiceConfigProvider.getShipmentsAllDetails(filters));
  }

  static getShipmentDetails(shipmentId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getShipmentDetails(shipmentId));
  }

  static updateShipment(shipment: Shipment) {
    return axiosInstance.put(AquadashServiceConfigProvider.updateShipment(shipment.entity_id), shipment);
  }

  static deleteShipment(shipmentId: string) {
    return axiosInstance.delete(AquadashServiceConfigProvider.deleteShipment(shipmentId));
  }
}