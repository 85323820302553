import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import AsyncButton from '../shared/buttons/AsyncButton';

const CANCEL = 'Cancel';

interface Props {
  title: string;
  details: string;
  btnTitle: string;
  saveDisabled: boolean;
  shouldShowModal: boolean;
  isSaving: boolean;
  handleSave: () => void;
  cancelSave: () => void;
}

export default function ConfirmModal({
  title,
  details,
  btnTitle,
  saveDisabled,
  shouldShowModal,
  isSaving,
  handleSave,
  cancelSave,
}: Props) {
  return (
    <Modal
      show={shouldShowModal}
      onHide={cancelSave}
      centered
      data-cy="confirm_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{details}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button id="delete_modal__cancel_btn" variant="secondary" onClick={cancelSave}>
          {CANCEL}
        </Button>
        <AsyncButton
          title={btnTitle}
          id="add_template_modal_btn"
          dataCy="add_template_modal_btn"
          variant="primary"
          spinner="light"
          disabled={saveDisabled || isSaving}
          handleClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
}
