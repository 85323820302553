import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Spinner, Stack, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { ControlInput } from '../../../../components/shared/Input';
import { defaultShipmentTypeFields, getShipmentFieldData } from '../../../../redux/slices/shipments.utils';
import ShipmentTypeEditorTableRow from './ShipmentTypeEditorTableRow';
import { getShipmentType, updateShipmentType } from '../../../../redux/slices/shipment.types';
import { ShipmentType, FormFieldConfig } from '../../../../redux/models/shipment.models';
import { EntityContainer } from '../../../../redux/models/core.models';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../../../constants/core.constants';
import { getSettingByName } from '../../../../redux/slices/settings';
import AsyncButton from '../../../../components/shared/buttons/AsyncButton';

const PAGE = 'shipment_type_editor';

export default function ShipmentTypeEditor() {
  const dispatch = useAppDispatch();
  const { orgCode, entityId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [shipmentType, setShipmentType] = useState<ShipmentType | null>(null);
  const [name, setName] = useState('');
  const [fields, setFields] = useState<EntityContainer<FormFieldConfig>>({ ...defaultShipmentTypeFields });
  const fieldRows = Object.values(fields).sort((a, b) => {
    const aName = a.name || '';
    const bName = b.name || '';
    return aName.localeCompare(bName);
  });
  const handleNameUpdate = (_: string, value: string) => {
    setName(value);
  };
  const onFieldChange = (field: FormFieldConfig) => {
    setFields({
      ...fields,
      [field.key]: {
        ...field,
      },
    });
  };
  const handleClose = () => navigate(`/${orgCode}/settings`);
  const handleSave = async () => {
    if (!shipmentType) return;
    const updates: ShipmentType = {
      ...shipmentType,
      data: {
        ...shipmentType.data,
        name,
        fields: {
          ...getShipmentFieldData(fields),
        },
      },
    };
    try {
      const response = await updateShipmentType(updates);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.SHIPMENT_TYPES));
        handleClose();
      }
    } catch (error) {
      toast(`Couldn't save ${name || 'shipment template'}`, { type: 'error' });
    }
  };

  useEffect(() => {
    const handleLoad = async (id: string) => {
      setIsLoading(true);
      const response = await getShipmentType(id);
      if (response.status === 200) {
        const data: ShipmentType | null | undefined = response.data.data || null;
        if (data) {
          setShipmentType(data);
          setName(data.data.name || '');
          const fieldData = data.data.fields || defaultShipmentTypeFields;
          const container = Object.values(fieldData).reduce((store, field) => {
            return {
              ...store,
              [field.key]: field,
            }
          }, {});
          const items = getShipmentFieldData(container);
          setFields(({ ...items }));
        }
      }
      setIsLoading(false);
    }
    if (entityId) handleLoad(entityId);
  }, [entityId]);

  if (isLoading) return <Spinner animation="border" variant="light" />;

  return (
    <Card>
      <Card.Header as="h4">Shipment Type Settings</Card.Header>
      <Card.Body>
        {isLoading && <Spinner animation="border" variant="primary" />}
        {!isLoading && (
          <>
            <Row>
              <ControlInput
                dataCy={`${PAGE}_name_input`}
                name="name"
                type="text"
                page={PAGE}
                value={name}
                labelText="Name"
                handleChange={handleNameUpdate}
              />
            </Row>
            <h5 className="mt-2">Shipment Fields</h5>
            <Table bordered hover className="mt-2">
              <tbody>
                {fieldRows.map((field) => (
                  <ShipmentTypeEditorTableRow key={field.key} field={field} onFieldChange={onFieldChange} />
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Card.Body>
      {!isLoading && (
        <Card.Footer>
          <Stack direction="horizontal" gap={3} className="float-end justify-content-end">
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            <AsyncButton
              variant="primary"
              spinner="light"
              title="Save"
              handleClick={handleSave}
            />
          </Stack>
        </Card.Footer>
      )}
    </Card>
  );
}
