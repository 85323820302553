import React, { useState } from 'react';
import { Badge, Button, Stack } from 'react-bootstrap';
import { DriverAvailability } from '../../../../redux/models/driver.availability.models';
import DriverAvailabilityModal from '../../../../components/entities/driver-availability/DriverAvailabilityModal';
import { DAYS } from '../../../../components/entities/driver-availability/data';
import { Driver } from '../../../../redux/models/settings.models';
import { DateService } from '../../../../utils/dateService';

const getTime = (isoStr: string) => {
  return DateService.getHHmmFormat(isoStr);
}

interface Props {
  availability: DriverAvailability | undefined;
  day: number;
  driver: Driver;
  onUpdate: (av: DriverAvailability | null, day: number) => void;
}

export default function DriverDetailsAvailabilityItem({ availability, day, driver, onUpdate }: Props) {
  const [edit, setEdit] = useState(false);
  const isSelected = availability !== undefined;
  const badgeTitle = isSelected ? 'On' : 'Off';
  const badgeVariant = isSelected ? 'primary' : 'secondary';
  const start = availability?.data.start_time || '';
  const finish = availability?.data.finish_time || '';
  const handleEdit = () => {
    setEdit(true);
  };
  return (
    <tr data-cy={`driver_details_editor_availability_table_row_${day}`}>
      <td>
        <Stack direction="horizontal" gap={3}>
          {DAYS[day] || ''}
          <Badge bg={badgeVariant}>{badgeTitle}</Badge>
        </Stack>
      </td>
      <td>{isSelected ? getTime(start) : ''}</td>
      <td>{isSelected ? getTime(finish) : ''}</td>
      <td>
        <Button onClick={handleEdit}>Edit</Button>
      </td>
      {edit && (
        <DriverAvailabilityModal
          availability={availability}
          day={day}
          driver={driver}
          show={edit}
          setShow={setEdit}
          onUpdate={onUpdate}
        />
      )}
    </tr>
  );
}
