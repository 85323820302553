import React from 'react';
import { AlertCircle } from 'react-feather';
import { Alert } from 'react-bootstrap';

const name = 'move-date-alert';

export default function MoveOrderAlert() {
  return (
    <Alert
      variant="warning"
      className={`alert-outline ${name}-alert`}
      data-cy={`${name}-alert`}
    >
      <div className={`alert-icon ${name}-btn`}>
        <AlertCircle />
      </div>
      <div className="alert-message">
        Move dates should ordered from be earliest to latest.
      </div>
    </Alert>
  );
}
