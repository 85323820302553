import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, Table } from 'react-bootstrap';
import BillingTypeRow from './BillingTypeRow';
import Permission from '../../../components/shared/permissions/Permission';
import BillingTypeModal from '../../../components/entities/billing-types/BillingTypeModal';
import {
  BillingTypeReadPermissions, BillingTypeWritePermissions
} from '../../../components/shared/permissions/permissions.utils';
import { BillingType } from '../../../redux/models/billing.models';

const SUBHEADER = 'Quote Templates';

interface Props {
  contactId: string;
  templates: BillingType[];
  onDelete: (template: BillingType) => void;
  onUpdate: (template: BillingType) => void;
}

export default function ContactRates({ contactId, templates, onUpdate, onDelete }: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const [showAddBillingType, setShowAddBillingType] = useState(false);

  const handleAddBillingType = () => {
    navigate(`/${orgCode}/settings/load-types/${contactId}/new`);
  };

  return (
      <>
        <Stack direction="horizontal" gap={3}>
          <h5 className="mt-2">{SUBHEADER}</h5>
          <Permission resources={BillingTypeWritePermissions}>
            <Button variant="outline-primary" onClick={handleAddBillingType}>Add</Button>
          </Permission>
        </Stack>
        <Permission resources={BillingTypeReadPermissions}>
          <Table bordered hover className="mt-2">
            <thead>
              <th>Name</th>
              <th>FSC</th>
              <th>Base Rate</th>
              <th>Accessorials</th>
              <th>Options</th>
            </thead>
            <tbody>
              {templates.map((type) => (
                <BillingTypeRow
                  key={type.entity_id}
                  type={type}
                  onDelete={onDelete}
                />
              ))}
            </tbody>
          </Table>
        </Permission>
        {showAddBillingType && (
          <BillingTypeModal
            show={showAddBillingType}
            customerId={contactId}
            defaultBillToId={contactId}
            setShow={setShowAddBillingType}
            onUpdate={onUpdate}
          />
        )}
      </>
  );
}
