/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import moment from 'moment-timezone';
import { DateService } from '../../utils/dateService';

import {
  ControlInput,
  SelectInput,
  SelectInputWithSearchLocalData,
} from '../../components/shared/Input';
// import { updateTripDetails, updateTripDetailsMoves } from '../../redux/slices/trips/trips';
import { getSelectedOptions } from '../../utils/core.utils';
import { Move, TripDetails } from '../../redux/models/trip.models';
import { Board } from '../../redux/models/board.models';
import { Driver, Group, Vehicle } from '../../redux/models/settings.models';

interface SelectOption {
  value: string;
  label: string;
}

interface Props {
  page: string;
  isEditable: boolean;
  tripDetails: TripDetails;
  boardSelectOptions: Board[];
  groupSelectOptions: Group[];
  driverSelectOptions: Driver[];
  vehicleSelectOptions: Vehicle[];
  statusSelectOptions: SelectOption[];
  onUpdateTrip: (trip: TripDetails) => void;
  onUpdateMoves: (moves: Move[]) => void;
}
export default function TripForm({
  page,
  isEditable,
  tripDetails,
  boardSelectOptions,
  groupSelectOptions,
  driverSelectOptions,
  vehicleSelectOptions,
  statusSelectOptions,
  onUpdateTrip,
  onUpdateMoves,
}: Props) {
  const updateMoveDates = (value: string) => {
    const initalDate = moment(tripDetails.data.date);
    const dateChange = moment(value);
    const dateDiff = dateChange.diff(initalDate);

    const newTripMoves = tripDetails.data.moves.map((move) => {
      const moveDate = move.data.scheduled_arrival_time;
      const newArrivalDate = moment(moveDate).add(dateDiff).format();
      const newDepartureDate = DateService.addMinsToDateISO(newArrivalDate, 15, true);
      if (value !== moveDate) {
        return {
          ...move,
          data: {
            ...move.data,
            scheduled_arrival_time: DateService.getUtcISOString(newArrivalDate),
            scheduled_departure_time: DateService.getUtcISOString(newDepartureDate),
          },
        };
      }
      return move;
    });
    // dispatch(updateTripDetailsMoves(newTripMoves));
    onUpdateMoves(newTripMoves);
  };

  const handleForm = (prop: string, value: string) => {
    if (prop === 'board_id') {
      onUpdateTrip({
        ...tripDetails,
        data: {
          ...tripDetails.data,
          board_id: value,
          group_id: '',
        },
      });
    } else if (prop === 'status') {
      onUpdateTrip({
        ...tripDetails,
        data: {
          ...tripDetails.data,
          status: value,
        },
      });
    } else if (prop === 'date') {
      const date = DateService.getISOString(value);
      onUpdateTrip({
        ...tripDetails,
        data: {
          ...tripDetails.data,
          [prop]: DateService.getUtcISOString(date),
        },
      });
      updateMoveDates(value);
    } else {
      onUpdateTrip({
        ...tripDetails,
        data: {
          ...tripDetails.data,
          [prop]: value,
        },
      });
    }
  };

  return (
    <Card>
      <Card.Body>
        <Row className="mt-2">
          <Col md={4}>
            <SelectInput
              labelText="Board"
              name="board_id"
              page={page}
              value={tripDetails.data.board_id}
              options={boardSelectOptions as any}
              handleChange={handleForm}
              dataCy="trip_board_id"
              disabled={!isEditable}
            />
          </Col>
          <Col md={4}>
            <SelectInput
              labelText="Group"
              name="group_id"
              page={page}
              value={tripDetails.data.group_id}
              options={groupSelectOptions as any}
              handleChange={handleForm}
              dataCy="trip_group_id"
              disabled={!isEditable}
            />
          </Col>
          <Col md={4}>
            <SelectInputWithSearchLocalData
              disabled={!isEditable}
              labelText="Driver"
              name="driver_id"
              page={page}
              value={tripDetails.data.driver_id}
              options={getSelectedOptions(tripDetails.data.driver_id, driverSelectOptions)}
              handleChange={handleForm}
              validDriverOptions={driverSelectOptions}
              dataCy="trip_driver_id"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4}>
            <SelectInputWithSearchLocalData
              disabled={!isEditable}
              labelText="Vehicle"
              name="vehicle_id"
              page={page}
              value={tripDetails.data.vehicle_id}
              options={getSelectedOptions(tripDetails.data.vehicle_id, vehicleSelectOptions)}
              validDriverOptions={vehicleSelectOptions}
              handleChange={handleForm}
              dataCy="trip_vehicle_id"
            />
          </Col>
          <Col md={4}>
            <ControlInput
              labelText="Date"
              name="date"
              page={page}
              type="date"
              disabled={!isEditable}
              value={DateService.getYYYYMMDDFormat(tripDetails.data.date)}
              handleChange={handleForm}
              dataCy="trip_date"
            />
          </Col>
          <Col md={4}>
            <SelectInput
              labelText="Status"
              name="status"
              value={tripDetails.data.status}
              page={page}
              options={statusSelectOptions as any}
              handleChange={handleForm}
              dataCy="trip_status"
              disabled={!isEditable}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}