import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';

import useAuth from '../../hooks/useAuth';
import useAppDispatch from '../../hooks/useAppDispatch';
import { pusher } from '../../services/pusher';

import TripDetails from './TripDetails';
import Loader from '../../components/Loader';
import CustomToast from '../../components/shared/CustomToast';

import {
  getTripDetails,
  // getFleetLocations,
  selectAreTripDetailsLoading,
  selectTripDetails,
  resetTrips,
  getTripRoute,
} from '../../redux/slices/trips/trips';
import { resetTrailerChanges } from '../../redux/slices/trailer-changes/trailerChanges';

export default function Trip() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { user } = useAuth();
  const tripDetails = useSelector(selectTripDetails);
  const areTripDetailsLoading = useSelector(selectAreTripDetailsLoading);
  const [shouldDisableSave, setShouldDisableSave] = useState(false);
  const [fleetLocations] = useState({});

  const userId = user.id;
  const routeId = tripDetails?.data?.samsara_route_id;
  // const getGetFleetLocations = async () => {
  //   const locations = await getFleetLocations();
  //   setFleetLocations(locations);
  // };

  useEffect(() => {
    dispatch(getTripDetails(id));
    dispatch(resetTrailerChanges());
  }, [dispatch, id]);

  // useEffect(async () => {
  //   getGetFleetLocations();
  // }, []);

  useEffect(() => {
    if (userId) {
      const tripChannel = pusher.subscribe(id);
      tripChannel.bind('update', ({ author }) => {
        const isAnotherUserUpdatingTrip = author !== user.id;
        if (isAnotherUserUpdatingTrip) {
          toast(<CustomToast message="This trip was updated, refresh the page" />, {
            type: 'warning',
            autoClose: 2000,
          });
          setShouldDisableSave(true);
        }
      });
    }
  }, [userId]);

  useEffect(() => {
    if (routeId) {
      dispatch(getTripRoute(routeId));
    }
  }, [dispatch, routeId]);

  useEffect(() => () => dispatch(resetTrips()), [dispatch]);

  useEffect(() => {
    return () => {
      if (userId) pusher.unsubscribe(id || '');
    };
  }, [userId, id]);

  return (
    <>
      <Helmet title="Trip" />
      <Container fluid className="p-0 trip-container">
        {areTripDetailsLoading ? <Loader /> : null}
        {tripDetails ? (
          <TripDetails
            tripDetails={tripDetails}
            shouldDisableSave={shouldDisableSave}
            fleetLocations={fleetLocations}
          />
        ) : null}
      </Container>
    </>
  );
}
