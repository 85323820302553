export const TRIP_STATUS_TYPES = [
  {
    value: '',
    label: 'Select...',
  },
  {
    value: 'ready-to-dispatch',
    label: 'Ready To Dispatch',
  },
  {
    value: 'dispatched',
    label: 'Dispatched',
  },
  {
    value: 'driver-confirmed',
    label: 'Driver Confirmed',
  },
  {
    value: 'complete',
    label: 'Complete',
  },
];
