import React from 'react';
import AsyncButton from './AsyncButton';
import { shouldLoadNextPage } from '../../../redux/slices/shipment-list/shipment-list.utils';
import ButtonSpinner from '../ButtonSpinner';

interface Props {
  dataCy: string;
  variant: string;
  spinner: string;
  isLoading: boolean;
  total: number;
  page: number;
  pageSize: number;
  handleClick: () => void;
}

export default function PaginatedButton({
  dataCy, variant, spinner, isLoading, total, page, pageSize, handleClick
}: Props) {
  const isNextPage = shouldLoadNextPage(page, pageSize, total);
  if (isNextPage === false) return <div />;
  const nextPage = page + 1;
  const nextEntity = (pageSize * nextPage) + 1;
  const lastLoadedEntity = pageSize * (nextPage + 1);
  const lastEntity = lastLoadedEntity > total ? total : lastLoadedEntity;
  const title = `Load ${nextEntity} - ${lastEntity}`;
  if (isLoading) {
    return <ButtonSpinner variant={spinner} />
  }
  return (
    <AsyncButton
      title={title}
      disabled={isLoading}
      id={dataCy}
      dataCy={dataCy}
      variant={variant}
      spinner={spinner}
      handleClick={handleClick}
    />
  )
};
