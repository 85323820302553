import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Stack, Table } from 'react-bootstrap';
import BetaBadge from '../../../badge/BetaBadge';
import EntityListItem from './EntityListItem';
import { DataModel } from '../../../../redux/models/core.models';
import { EntityServiceProvider } from '../../../../services/base/EntityServiceProvider';
import { ApiQuery, DEFAULT_API_QUERY, SortOrder } from '../../../../redux/models/network.models';
import Loader from '../../../Loader';
import Analytics from '../../../../utils/analytics';
import { getPostSearchBody } from '../../../../redux/slices/settings.utils';
import { debounce } from '../../../../utils/core.utils';
import AsyncButton from '../../../shared/buttons/AsyncButton';

const SORT_ORDER = SortOrder.Ascending;

interface Props<T> {
  title: string;
  settingsKey: string;
  nameKey?: string;
  api: EntityServiceProvider<T>;
  allowAdd?: boolean;
  children?: React.ReactNode;
  onGetName: (entity: DataModel<T>) => string;
  onCreateRoute: (entity: DataModel<T>) => string;
  onAdd?: () => void;
}

function EntityList<T>({
  title, settingsKey, api, allowAdd, nameKey, children, onGetName, onCreateRoute, onAdd
}: Props<T>) {
  const [entities, setEntities] = useState<DataModel<T>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [totalEntities, setTotalEntities] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const showLoadMore = entities.length < totalEntities;
  const pageSize = 25;
  const nextEntity = (pageSize * page) + 1;
  const lastLoadedEntity = pageSize * (page + 1);
  const lastEntity = lastLoadedEntity > totalEntities ? totalEntities : lastLoadedEntity;
  const loadMoreTitle = `Load ${nextEntity} - ${lastEntity}`;
  const isChildren = !isLoading && children;

  const handleDelete = async (entity: DataModel<T>) => {
    try {
      const response = await api.delete(entity);
      if (response.status === 200) {
        const data = entities.filter((item) => item.entity_id !== entity.entity_id);
        setEntities(data);
      }
    } catch (error) {
      Analytics.capture(error);
    }
  };
  const handleLoad = async (query: ApiQuery, loadMore = false) => {
    try {
      if (!loadMore) setIsLoading(true);
      const response = await api.find({
        ...query,
        pagination: {
          page,
          page_size: pageSize,
        },
        sorting: [
          {
            field: nameKey ? `data.${nameKey}` : 'created_at',
            order: SORT_ORDER,
          }
        ]
      });
      if (response.status === 200) {
        const data = response.data.data.items || [];
        setPage(page + 1);
        setTotalEntities(response.data.data.total_count ?? 0);
        const items = loadMore ? [...entities, ...data] : data;
        setEntities(items);
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (!loadMore) setIsLoading(false);
    }
  };

  const remoteSearch = (value: string) => {
    if (value.length > 2) {
      const query = getPostSearchBody(nameKey || 'name', value);
      handleLoad(query);
    };
    if (value === '') {
      handleLoad(DEFAULT_API_QUERY);
    }
  }
  const loadNextPage = async () => {
    setIsLoadingNextPage(true);
    const query = search.length > 2 ? getPostSearchBody(nameKey || 'name', search) : DEFAULT_API_QUERY;
    await handleLoad(query, true);
    setIsLoadingNextPage(false);
  };
  const optimisedSearch = useCallback(debounce(remoteSearch), []);
  const handleSearch = async (value: string) => {
    setSearch(value);
    optimisedSearch(value);
  };
  const handleAddEntity = () => {
    if (onAdd) onAdd();
  };
  useEffect(() => {
    const load = async (query: ApiQuery) => {
      try {
        setIsLoading(true);
        const response = await api.find({
          ...query,
          pagination: {
            page: 0,
            page_size: 25,
          },
          sorting: [
            {
              field: nameKey ? `data.${nameKey}` : 'created_at',
              order: SORT_ORDER,
            }
          ]
        });
        if (response.status === 200) {
          const data = response.data.data.items || [];
          setPage(page + 1);
          setTotalEntities(response.data.data.total_count ?? 0);
          setEntities(data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    load(DEFAULT_API_QUERY);
  }, [api]);
  return (
    <Card>
      <Card.Header>
        <div className="d-flex align-items-center justify-content-between">
          <Stack direction="horizontal" gap={2}>
            <h4 className="pt-2 mr-2">{title}</h4>
            <BetaBadge />
          </Stack>
          <input
            value={search}
            style={{ width: '50%' }}
            placeholder={`Search ${totalEntities} ${title}...`}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {allowAdd && <Button variant="primary" onClick={handleAddEntity}>Add</Button>}
        </div>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && entities.length > 0 && (
          <Table bordered hover className="mt-2">
            <tbody>
              {entities.map((entity: DataModel<T>) => {
                return (
                  <EntityListItem
                    key={entity.entity_id}
                    entity={entity}
                    settingsKey={settingsKey}
                    onDelete={handleDelete}
                    getName={() => {
                      return onGetName(entity);
                    }}
                    createRoute={() => {
                      return onCreateRoute(entity);
                    }}
                  />
                )
              })}
            </tbody>
          </Table>
        )}
        {!isLoading && showLoadMore && (
          <AsyncButton
            title={loadMoreTitle}
            disabled={isLoadingNextPage}
            id="load_more_unassigned_shipments_btn"
            dataCy="load_more_unassigned_shipments_btn"
            variant="outline-secondary"
            spinner="secondary"
            handleClick={loadNextPage}
          />
        )}
        {!isLoading && entities.length === 0 && (
          <div>
            <p>{`No ${title} have been added`}</p>
          </div>
        )}
        {isChildren && children}
      </Card.Body>
    </Card>
  );
};

EntityList.defaultProps = {
  allowAdd: false,
  onAdd: undefined,
  children: undefined,
  nameKey: 'name',
};

export default EntityList;
