import {
  Button, Col, Row, Spinner,
} from 'react-bootstrap';

const SAVE_BUTTON_TEXT = 'Save';
const CLOSE_BUTTON_TEXT = 'Close';
const DELETE_BUTTON_TEXT = 'Delete';

interface Props {
  disabled: boolean;
  isSaving?: boolean;
  deleteButton?: boolean;
  page: string;
  isEditable: boolean;
  handleClose: () => void;
  handleDelete?: () => void;
  handleSave: () => void;
}

function PageFooterButtons({
  page, handleDelete, handleClose, handleSave, disabled, isSaving, deleteButton, isEditable,
}: Props) {
  return (
    <Row className="flex align-items-end">
      <Col md={8}>
        {deleteButton && isEditable && (
          <Button
            id={`${page}-delete__btn`}
            data-cy={`${page}-delete__btn`}
            variant="outline-danger"
            size="lg"
            onClick={handleDelete}
            disabled={disabled}
          >
            {DELETE_BUTTON_TEXT}
          </Button>
        )}
      </Col>
      <Col className="d-flex justify-content-end align-items-end">
        <Button
          id={`${page}-close__btn`}
          data-cy={`${page}-close__btn`}
          variant="light"
          size="lg"
          className="mx-4"
          onClick={handleClose}
        >
          {CLOSE_BUTTON_TEXT}
        </Button>
        {isEditable && (
          <Button
            id={`${page}-save__btn`}
            data-cy={`${page}-save__btn`}
            variant="primary"
            size="lg"
            onClick={handleSave}
            disabled={disabled}
          >
            {isSaving && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
            />
            )}
            {!isSaving && SAVE_BUTTON_TEXT}
          </Button>
        )}
      </Col>
    </Row>
  );
}

PageFooterButtons.defaultProps = {
  isSaving: false,
  deleteButton: true,
  handleDelete: () => {},
}

export default PageFooterButtons;