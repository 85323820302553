/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityContainer } from '../../../redux/models/core.models';
import { BillingItem, BillingType } from '../../../redux/models/billing.models';
import { handleBilling } from '../utils/shipments.utils';
import { ShippingLine, UOM } from '../../../redux/models/settings.models';
import { Shipment } from '../../../redux/models/shipment.models';

export const getDataGridPostBody = (
  shipment: Shipment,
  prop: string,
  value: any,
  billingTypes: EntityContainer<BillingType>,
  billingItems: EntityContainer<BillingItem>,
  shippingLines: EntityContainer<ShippingLine>,
  uoms: EntityContainer<UOM>
) => {
  const updates: any = {
    ...shipment,
    data: {
      ...shipment.data,
    }
  };
  if (shipment.data.billing_type_id !== '') {
    const btId = shipment.data.billing_type_id || '';
    const type = billingTypes[btId];
    if (type !== undefined && type !== null) updates.data.billing_type = type;
  }
  if (shipment.data.base_rate && shipment.data.base_rate?.uom_id !== '') {
    const basRat = shipment.data.base_rate;
    const uomId = basRat?.uom_id || '';
    const uom = uoms[uomId];
    if (uom !== undefined && uom !== null) {
      updates.data.base_rate = {
        ...basRat,
        uom,
      }
    }
  }
  if (prop !== 'base_rate' && updates && updates.data) {
    updates.data[prop] = value;
  }
  if (prop === 'billing_type_id' && shipment.data.billing_type_id && shipment.data.billing_type_id !== '') {
    const typeRel = billingTypes[shipment.data.billing_type_id];
    if (typeRel !== undefined && typeRel !== null) {
      updates.data.billing_type = typeRel;
    }
  } else if (prop === 'shipping_line_id' && shipment.data.shipping_line_id && shipment.data.shipping_line_id !== '') {
    const shippingLineRel = shippingLines[shipment.data.shipping_line_id];
    if (shippingLineRel !== undefined && shippingLineRel !== null) {
      updates.data.shipping_line = shippingLineRel;
    }
  } else if (prop === 'customer_id') {
    updates.data.customer_id = value;
    updates.data.billing_type_id = '';
    updates.data.billing_type = null;
    updates.data.base_rate = null;
    updates.data.accessorials = [];
  } else if (prop === 'billing_type_id') {
    const billingDetails = handleBilling(value, Object.values(billingTypes), Object.values(billingItems));
    const isEmpty = value === '';
    if (isEmpty) {
      updates.data.billing_type = null;
    } else {
      const type = billingTypes[value];
      if (type !== undefined && type !== null) updates.data.billing_type = type;
    }
    const baseRate: any = billingDetails.base_rate;
    const uomId = baseRate.uom_id || '';
    const uom = uoms[uomId];
    updates.data.base_rate = {
      ...baseRate,
      uom
    };
    updates.data.accessorials = billingDetails.accessorials;
  } else if (prop === 'base_rate') {
    if (updates.data.base_rate !== undefined && updates.data.base_rate !== null) {
      updates.data.base_rate = {
        ...updates.data.base_rate,
        quantity: parseFloat(value)
      }
    }
  }

  return updates;
}
