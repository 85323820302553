import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export function CollapseButton({ shouldExpandAllItems, setShouldExpandAllItems }) {
  return (
    <div
      id="boards-collapse__btn"
      className="expand-groups-toggle"
      onClick={() => setShouldExpandAllItems(!shouldExpandAllItems)}
    >
      {shouldExpandAllItems ? 'Collapse All' : 'Expand All'}
      <FontAwesomeIcon
        width={25}
        he={25}
        icon={faChevronDown}
        className="custom-collapse-icon"
      />
    </div>
  );
}
