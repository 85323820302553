import React, { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FileText } from 'react-feather';
import AddModal from '../../../../components/modals/AddModal';
import { Document } from '../../../../redux/models/file.models';
import { SelectInput } from '../../../../components/shared/Input';
import Analytics from '../../../../utils/analytics';
import { ShipmentServiceApiProvider } from '../../../../services/ShipmentServiceProvider';
import { Shipment } from '../../../../redux/models/shipment.models';
import {
  ShipmentDocKey,
  createShipmentDocOptions,
  assignDocs,
  DropdownOption
} from './AssignDocModal.utils';

interface Props {
  shipmentOptions: DropdownOption[];
  document: Document;
  documentIdx: number;
  shouldShowModal: boolean;
  handleSave: () => void;
  cancelSave: () => void;
}

export default function AssignDocModal({
  shipmentOptions,
  document,
  documentIdx,
  shouldShowModal,
  handleSave,
  cancelSave,
}: Props) {
  const [shipmentId, setShipmentId] = useState<string>('');
  const [shipmentDocId, setShipmentDocId] = useState<string>('');
  const [shipmentLoaded, setShipmentLoaded] = useState(false);
  const [shipmentLoading, setShipmentLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [shipment, setShipment] = useState<Shipment | null>(null);
  const deliveryDocs = shipment?.data.delivery_attachments || [];
  const pickUpDocs = shipment?.data.pick_up_attachments || [];
  const miscDocs = shipment?.data.misc_attachments || [];
  const isValid = shipmentId !== '' && shipmentDocId !== '';

  const shipmentDocOptions = [
    ...createShipmentDocOptions(deliveryDocs, ShipmentDocKey.Delivery),
    ...createShipmentDocOptions(pickUpDocs, ShipmentDocKey.PickUp),
    ...createShipmentDocOptions(miscDocs, ShipmentDocKey.Misc),
  ];

  const handleSelectShipment = async (prop: string, value: string) => {
    console.log(prop);
    setShipmentId(value);
    try {
      setShipmentLoaded(false);
      setShipmentLoading(true);
      const response = await ShipmentServiceApiProvider.getShipmentDetails(value);
      if (response.status === 200) {
        const shipData: Shipment = response.data.data;
        setShipment(shipData);
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setShipmentLoaded(true);
      setShipmentLoading(false);
    }
  };
  const handleSelectShipmentDoc = (prop: string, value: string) => {
    console.log(prop);
    setShipmentDocId(value);
  };
  const onSave = async () => {
    if (!isValid || !shipment) return;
    const moveDoc = document;
    const shipmentUpdates: Shipment = {
      ...shipment,
      data: {
        ...shipment.data,
        delivery_attachments: assignDocs(deliveryDocs, shipmentDocId, ShipmentDocKey.Delivery, moveDoc),
        misc_attachments: assignDocs(miscDocs, shipmentDocId, ShipmentDocKey.Misc, moveDoc),
        pick_up_attachments: assignDocs(pickUpDocs, shipmentDocId, ShipmentDocKey.PickUp, moveDoc),
      },
    };
    setIsSaving(true);
    const response = await ShipmentServiceApiProvider.updateShipment(shipmentUpdates);
    setIsSaving(false);
    if (response.status === 200) {
      console.log(response.data.data);
      handleSave();
    } else {
      toast("Couldn't Save Shipment", { type: 'error' });
    }
  };
  return (
    <AddModal
      title="Document Assignment"
      btnTitle="Save"
      saveDisabled={!isValid}
      shouldShowModal={shouldShowModal}
      isSaving={isSaving}
      handleSave={onSave}
      cancelSave={cancelSave}
    >
      <Form>
        <h4>Move Document</h4>
        <div>
          <FileText className="feather align-middle" />{" "}
          {`${documentIdx + 1}. ${document.name}`}
        </div>
        <h4 className="mt-4">Shipment</h4>
        <SelectInput
          name="shipment_ids"
          page="page"
          value={shipmentId}
          options={shipmentOptions}
          shouldHaveMargin={false}
          handleChange={handleSelectShipment}
          dataCy="move_shipment_id"
          disabled={false}
          labelText=""
        />
        <div className="mt-4">
          {shipmentLoading && (
            <Spinner animation="border" variant="primary" />
          )}
          {shipmentLoaded && !shipmentLoading && (
            <div>
              <h4>Shipment Documents</h4>
              <SelectInput
                name="shipment_docs"
                page="page"
                value={shipmentDocId}
                options={shipmentDocOptions}
                shouldHaveMargin={false}
                handleChange={handleSelectShipmentDoc}
                dataCy="move_shipment_id"
                disabled={false}
                labelText=""
              />
            </div>
          )}
        </div>
      </Form>
    </AddModal>
  );
}
