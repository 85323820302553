export interface Coordinate {
  latitude: number;
  longitude: number;
}

function toRad(value: number): number {
  return (value * Math.PI) / 180;
}

export class DistanceService {
  static getDistance(coord1: Coordinate, coord2: Coordinate): number {
    const R = 6371; // Radius of the earth in km
    const dLat = toRad(coord2.latitude - coord1.latitude);
    const dLon = toRad(coord2.longitude - coord1.longitude);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(coord1.latitude)) * Math.cos(toRad(coord2.latitude)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }
}

// // Usage
// const coord1: Coordinate = { latitude: 40.7128, longitude: -74.0060 }; // New York
// const coord2: Coordinate = { latitude: 51.5074, longitude: -0.1278 }; // London

// console.log(getDistance(coord1, coord2)); // Output: 5570.271 km (approximately)
