import React from 'react';
import { TEXTS } from '../landing/landing.constants';

const WELCOME_TEXT = `Welcome to ${TEXTS.TITLE}!`;
const WELCOME_DESCRIPTION = 'Select your dispatch board in the sidebar.';

export default function WelcomePage() {
  return (
    <div className="col-md-5 p-lg-1 mx-auto my-5">
      <h1 className="display-6 fw-normal">{WELCOME_TEXT}</h1>
      <p className="lead fw-normal">
        {WELCOME_DESCRIPTION}
      </p>
    </div>
  );
}
