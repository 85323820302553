import { EntityContainer } from '../../../redux/models/core.models';
import { LineItem } from '../../../redux/models/invoice.models';
import { UOM } from '../../../redux/models/settings.models';
import { Shipment } from '../../../redux/models/shipment.models';
import { formatCurrency } from './invoice.utils';

export const formatLineItems = (
  lineItems: LineItem[],
  shipments: Shipment[],
  uomObject: EntityContainer<UOM>,
  currency: string,
) => [...lineItems].map((lineItem) => {
  const shipment = shipments.find((ship) => ship.entity_id === lineItem.data.shipment_id);
  return {
    id: lineItem.entity_id,
    name: lineItem.data.description,
    shipment_no: shipment?.data?.shipment_no,
    qty: lineItem.data.quantity,
    unit: uomObject[lineItem.data.uom_id]?.data?.name || '',
    rate: formatCurrency(currency, lineItem.data.price, true),
    sub_total: formatCurrency(currency, lineItem.data.sub_total),
    tax_name: lineItem.data.tax_name || '',
    total: formatCurrency(currency, lineItem.data.total),
  };
});
