export default class TrailerChangeEndpoints {
  static getTrailerChangesMoves() {
    return '/trailer-changes/moves';
  }

  static getTrailerChangesTrips() {
    return '/trailer-changes/trips';
  }

  static updateTrailerChanges() {
    return '/trailer-changes/trips';
  }
}