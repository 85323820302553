/* eslint-disable */
import React, { useState } from 'react'
import ButtonSpinner from '../../../../../components/shared/ButtonSpinner';
import ContactInput from '../../../../../components/entities/contacts/ContactInput';
import { useSelector } from 'react-redux';
import { selectContactsObject } from '../../../../../redux/slices/settings';
import PlaceholderValue from '../../../../../components/data-grid/base/PlaceholderValue';

const CustomerCell: React.FC<{ customerId: string; onChange: (value: string) => void }> = ({ customerId, onChange }) => {
  const [editing, setEditing] = useState(false)
  const [tempValue, setTempValue] = useState(customerId)
  const [isSaving, setSaving] = useState(false);
  const customers = useSelector(selectContactsObject);
  const name = customers[tempValue]?.data?.name || '';
  const handleEdit = () => {
    setEditing(true);
  };

  const handleChange = async (_: string, newCustomerId: string) => {
    setTempValue(newCustomerId);
    setSaving(true);
    setEditing(false);
    await onChange(newCustomerId);
    setSaving(false);
  };
  const handleCreate = () => {};

  if (editing) {
    return (
      <ContactInput
        value={customerId}
        page=""
        prop="customer_id"
        label=""
        disabled={false}
        allowAdd={false}
        options={[]}
        onChange={handleChange}
        onNewEntity={handleCreate}
      />
    )
  }

  return (
    <div onClick={handleEdit} style={{ cursor: 'text' }}>
      {name || <PlaceholderValue />}
      {isSaving && <ButtonSpinner />}
    </div>
  )
}

export default CustomerCell;