/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Stack,
  Table,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { getSettingByName, selectSettings, selectTemplates } from '../../redux/slices/settings';

import TemplateListRow from './TemplateListRow';
import useAppDispatch from '../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../constants/core.constants';
import { Group, TripTemplate } from '../../redux/models/settings.models';
import AddTripTemplateModal from './AddTemplate/AddTripTemplateModal';
import Analytics from '../../utils/analytics';
import { createTemplate } from '../../redux/slices/templates';
import { Board } from '../../redux/models/board.models';

const TITLE = 'Trip Templates';
const LOADING_TEXT = 'Loading...';

interface TemplateTableProps {
  templates: TripTemplate[];
  settings: {
    boards: {
      list: Board[];
    };
    groups: {
      list: Group[];
    };
  };
  onOpen: (template: TripTemplate) => void;
}

function TemplateTable({ templates, settings, onOpen }: TemplateTableProps) {
  const templateData = [...templates] || [];
  templateData.sort((a: any, b: any) => {
    const aName = a?.data?.name || '';
    const bName = b?.data?.name || '';
    return aName.localeCompare(bName);
  });
  return (
    <Table bordered hover className="settings-table mt-2">
      <tbody>
        {templateData.map((template: any, idx: number) => (
          <TemplateListRow
            key={template.entity_id || idx}
            template={template}
            settings={settings}
            onOpen={onOpen}
          />
        ))}
      </tbody>
    </Table>
  );
}

export default function Templates() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const settings: any = useSelector(selectSettings);
  const templates = useSelector(selectTemplates);
  const [loading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleOpen = (template: TripTemplate) => {
    navigate(`/${orgCode}/settings/trip-templates/${template.entity_id}`);
  };
  const handleReload = async () => {
    await dispatch(getSettingByName(SETTINGS_NAMES.TRIP_TEMPLATES));
  };

  const handleCreateTemplate = async (name: string, shipmentId: string, boardId: string, groupId: string) => {
    try {
      setIsSaving(true);
      const params = {
        name,
        shipment_id: shipmentId,
        board_ids: [boardId],
        group_ids: [groupId],
      };
      const response = await createTemplate(params);
      if (response.status === 200) {
        await handleReload();
        setShowAddModal(false);
        handleOpen(response.data.data);
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <Card>
      <Card.Header>
        <div className="d-flex align-items-center justify-content-between">
          <Stack direction="horizontal" gap={2}>
            <h4 className="pt-2 mr-2">{TITLE}</h4>
          </Stack>
          <Button variant="primary" onClick={() => setShowAddModal(true)}>Add</Button>
        </div>
      </Card.Header>
      <Card.Body>
        {loading ? <p>{LOADING_TEXT}</p> : (
          <TemplateTable templates={templates} settings={settings} onOpen={handleOpen} />
        )}
        {templates.length === 0 && (
          <div>
            <p>No templates. Click the add button to create a new template</p>
          </div>
        )}
      </Card.Body>
      <AddTripTemplateModal
        shouldShowModal={showAddModal}
        isSaving={isSaving}
        saveItem={handleCreateTemplate}
        cancelSave={() => setShowAddModal(false)}
      />
    </Card>
  );
}
