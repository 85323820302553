import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const CLOSE = 'Close';

interface Props {
  title: string;
  description: string;
  showMessageModal: boolean;
  closeMessage: () => void;
  page: string;
}

export default function MessageModal({
  title,
  description,
  showMessageModal,
  closeMessage,
  page,
}: Props) {
  return (
    <Modal
      show={showMessageModal}
      onHide={closeMessage}
      centered
      data-cy={`${page}_message_modal`}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center m-3">
        <p className="mb-0">{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button id="message_modal_close_button" data-cy="message-close-btn" variant="secondary" onClick={closeMessage}>
          {CLOSE}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
