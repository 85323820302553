/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import AddEntityModal from '../base/modals/AddEntityModal';
import { ControlInput } from '../../shared/Input';
import Permission from '../../shared/permissions/Permission';
import { AddressWritePermissions } from '../../shared/permissions/permissions.utils';
import { addSettingsItem } from '../../../redux/slices/settings';
import { Api } from '../../../services/services';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import { Address, AddressData } from '../../../redux/models/settings.models';
import { defaultAddressData, Place } from './data';
import PlaceDetails from './PlaceDetails';

const PAGE = 'add_address_modal';
const TITLE = 'Address';

interface Props {
  show: boolean;
  searchTerm: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: (address: Address, event: string) => void;
}

function AddressModal({ searchTerm, show, setShow, onUpdate }: Props) {
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [results, setResults] = useState<Place[]>([]);
  const [place, setPlace] = useState<Place | null>(null);
  const [name, setName] = useState(searchTerm || '');
  const isDisabled = place === null;
  const handleSearch = async (_: string, val: string) => {
    if (place) setPlace(null);
    setName(val);
    if (val !== '') {
      const response = await Api.Maps.search(val);
      if (response.status === 200) {
        const items = response.data.places || [];
        setResults(items);
      }
    }
  };
  const handleCreate = async () => {
    if (!place) throw Error('No place selected');
    const entityData: AddressData = {
      ...defaultAddressData,
      samsara_name: place.displayName.text,
      samsara_formatted_address: place.formattedAddress,
      samsara_latitude: place.location.latitude,
      samsara_longitude: place.location.longitude,
      external_ids: {
        google_id: place.id,        
      },
    };
    return Api.Addresses.create(entityData);
  };

  const handleResponse = async (response: AxiosResponse<any, any>, event: string) => {
    if (response.status === 200) {
      const nm = place?.displayName.text || name;
      toast(`${nm} updated.`, { type: 'success' });
      if (onUpdate) {
        const entity = response.data.data;
        dispatch(addSettingsItem({ name: SETTINGS_NAMES.ADDRESSES, data: entity }));
        onUpdate(entity, event);
      }
      setShow(false);
    }
  }
  const handleSelectPlace = (result: Place) => {
    setPlace(result);
  }
  const handleSave = async () => {
    if (isDisabled) return;
    try {
      setIsSaving(true);
      const response = await handleCreate();
      handleResponse(response, 'create');
    } catch (error) {
      toast(`Couldn't update ${name}`, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  useEffect(() => {
    const load = async (search: string) => {
      const response = await Api.Maps.search(search);
      if (response.status === 200) {
        const items = response.data.places || [];
        setResults(items);
      }
    };
    if (searchTerm !== '') load(searchTerm);
  }, [searchTerm]);
  return (
    <Permission resources={AddressWritePermissions}>
      <AddEntityModal
        title={`Add ${TITLE}`}
        btnTitle="Save"
        saveDisabled={isDisabled}
        shouldShowModal={show}
        isSaving={isSaving}
        handleSave={handleSave}
        cancelSave={() => setShow(false)}
      >
        <Stack>
          <ControlInput
            dataCy={`${PAGE}_name_input`}
            name="name"
            type="text"
            page={PAGE}
            value={name}
            labelText="Search"
            handleChange={handleSearch}
          />
          {!place && results.length > 0 && (
            <div>
              {results.map((result) => (
                <Button
                  key={result.id}
                  variant="link"
                  onClick={() => handleSelectPlace(result)}
                >
                  {`${result.displayName.text}, ${result.formattedAddress}`}
                </Button>
              ))}
            </div>
          )}
          {place && <PlaceDetails place={place} />}
        </Stack>
      </AddEntityModal>
    </Permission>
  )
};

export default AddressModal;
