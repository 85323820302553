/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Form, FormLabel } from 'react-bootstrap';
import { ControlInput, SelectInput } from '../../../components/shared/Input';
import { DateService } from '../../../utils/dateService';
import { INVOICE_STATUS_TYPES } from '../constants/invoices.constants';
import { Invoice } from '../../../redux/models/invoice.models';

const invoiceSelectOptions = Object.values(INVOICE_STATUS_TYPES);
const DATE_TITLE = 'Date';

interface Props {
  invoiceDetails: Invoice;
  page: string;
  isEditable: boolean;
  handleForm: (prop: string, value: any) => void;
}

export default function InvoiceForm({ invoiceDetails, page, isEditable, handleForm }: Props) {
  return (
    <Col sm={4} className={`${page}-invoice-form`}>
      <ControlInput
        labelText="Invoice No"
        name="invoice_no"
        page={page}
        value={invoiceDetails.data.invoice_no}
        handleChange={handleForm}
        dataCy="invoice_no"
        disabled={!isEditable}
      />
      <SelectInput
        labelText="Status"
        name="status"
        page={page}
        value={invoiceDetails.data.status}
        options={invoiceSelectOptions}
        handleChange={handleForm}
        dataCy="invoice_status"
        disabled={!isEditable}
      />
      <FormLabel><h5>{DATE_TITLE}</h5></FormLabel>
      <Form.Control
        value={DateService.getYYYYMMDDFormat(invoiceDetails.data.date) || ''}
        type="date"
        name="date"
        id="invoice-date"
        onChange={(e) => handleForm('date', DateService.getISOString(e.target.value))}
        data-cy="invoice_date"
        disabled={!isEditable}
      />
    </Col>
  );
}
