import { DateService } from '../../../utils/dateService';
import { TripBoardModel } from '../../../redux/models/trip.models';

export const getTripTimes = (trip: TripBoardModel): { start: string; finish: string; } => {
  if (!trip) {
    return {
      start: '',
      finish: ''
    }
  }
  const tripMoves = trip?.moves || [];
  const firstMoves = [...tripMoves].sort((a, b) => {
    const aPos = a?.position || 0;
    const bPos = b?.position || 0;
    return aPos - bPos;
  });
  const numFirstMoves = firstMoves.length || 0;
  if (numFirstMoves < 1) {
    return {
      start: trip.apt,
      finish: trip.apt
    }
  }
  const [firstMove] = firstMoves;
  const lastMove = firstMoves[numFirstMoves - 1];
  return {
    start: DateService.getHHmmFormat(firstMove.scheduled_arrival_time),
    finish: DateService.getHHmmFormat(lastMove.scheduled_arrival_time)
  }
}

export const getAssignedTimes = (trips: TripBoardModel[]): { start: string; finish: string; } => {
  const numTrips = trips.length || 0;
  if (numTrips < 1) return {
    start: '',
    finish: ''
  };
  const [firstTrip] = trips;
  const lastTrip = trips[numTrips - 1];
  if (numTrips === 1) {
    return getTripTimes(firstTrip);
  }
  return {
    start: getTripTimes(firstTrip).start,
    finish: getTripTimes(lastTrip).finish
  }
}

export const getFinishTime = (finish: string, allowOvertime: boolean, maxOvertime: number) => {
  if (allowOvertime && maxOvertime > 0) {
    const dt = DateService.addTimeToDateISO(finish, maxOvertime, 'seconds');
    return DateService.getHHmmFormat(dt);
  }
  return DateService.getHHmmFormat(finish);
}

export const getShiftText = (
  numTrips: number,
  start: string,
  finish: string,
  isWithinShift: boolean,
  status: string, 
  tripEndsInOverTime: boolean,
) => {
  const message = `${numTrips} ${numTrips > 1 ? 'trips' : 'trip'} planned from ${start} to ${finish}`;
  if (!isWithinShift) {
    return `${message}. ${status}.`;
  }
  if (isWithinShift && tripEndsInOverTime) {
    return `${message}. ${status}.`;
  }
  return message;
}

type TimeRange = {
  start: string;  // in "HH:mm" format, e.g., "23:00"
  end: string;    // in "HH:mm" format, e.g., "07:00"
};

type ShiftCheckResult = {
  isWithinShift: boolean;
  textColour: "text-default" | "text-warning" | "text-danger";
  statusMessage: string;
};

export function checkTripWithinDriverShift(
  driverShift: TimeRange, trip: TimeRange, overtimeSeconds = 0
): ShiftCheckResult {
  const format = "HH:mm";
  const driverShiftStart = DateService.format(driverShift.start, format);
  const driverShiftEnd = DateService.format(driverShift.end, format);
  const tripStart = DateService.format(trip.start, format);
  const tripEnd = DateService.format(trip.end, format);

  const isNightShift = driverShiftEnd.isBefore(driverShiftStart);

  if (isNightShift) {
    driverShiftEnd.add(1, 'day');
    if (tripEnd.isBefore(tripStart)) tripEnd.add(1, 'day');
  }

  const driverShiftEndWithOvertime = driverShiftEnd.clone().add(overtimeSeconds, 'seconds');

  if (tripStart.isSameOrAfter(driverShiftStart) && tripEnd.isSameOrBefore(driverShiftEnd)) {
    return { 
      isWithinShift: true, 
      textColour: "text-default", 
      statusMessage: "Trip starts and finishes within the shift" 
    };
  }
  if (tripStart.isSameOrAfter(driverShiftStart) && tripEnd.isSameOrBefore(driverShiftEndWithOvertime)) {
    return { 
      isWithinShift: true, 
      textColour: "text-warning", 
      statusMessage: "Trip ends in overtime" 
    };
  }
  if (tripStart.isBefore(driverShiftStart) && tripEnd.isAfter(driverShiftEndWithOvertime)) {
    return { 
      isWithinShift: false, 
      textColour: "text-danger", 
      statusMessage: "Trip starts before and ends after shift" 
    };
  }
  if (tripStart.isBefore(driverShiftStart)) {
    return { 
      isWithinShift: false, 
      textColour: "text-danger", 
      statusMessage: "Trip starts before shift" 
    };
  }
  return { 
    isWithinShift: false, 
    textColour: "text-danger", 
    statusMessage: "Trip ends after shift" 
  };
}