/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-plusplus */
/* eslint-disable  no-restricted-syntax */
import React, { useMemo } from 'react';

const initialState = {
  timezone: "America/Vancouver",
  appTz: "US/Pacific",
  organizationId: '',
};

export const AppSettingsContext = React.createContext(initialState);

export function AppSettingsProvider({ children }) {
  const [settings] = React.useState(initialState);

  const providerValue = useMemo(
    () => ({
      ...settings,
    }),
    [settings],
  );
  return (
    <AppSettingsContext.Provider value={providerValue}>
      {children}
    </AppSettingsContext.Provider>
  );
};