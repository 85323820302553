import { DriverAvailability } from "../../models/driver.availability.models";

export interface DriverAvailabilityState {
  isLoaded: boolean;
  isLoading: boolean;
  availability: DriverAvailability[];
};

const initialState: DriverAvailabilityState = {
  isLoaded: false,
  isLoading: false,
  availability: [],
};

export const getInitialState = (): DriverAvailabilityState => ({
  ...initialState
});
