import React from 'react';
import { useSelector } from 'react-redux';

import SuggestionAlert from './SuggestionAlert';
import { DriverTrip } from '../../../../redux/models/driver.availability.models';
import { getMessage } from './suggestion.utils';
import { TripDetails } from '../../../../redux/models/trip.models';
import { selectAddressesObject } from '../../../../redux/slices/settings';

interface Props {
  conflicts: DriverTrip[];
  tripDetails: TripDetails;
  setClosed: () => void;
}

export default function DriverConflict({ conflicts, tripDetails, setClosed }: Props) {
  const addresses = useSelector(selectAddressesObject);
  const conflict = conflicts.length ? conflicts[0] : null;
  const driver = conflict?.data.driver;
  const name = driver?.data?.samsara_name || '';
  const conflictMessages = conflicts.map((conf) => {
    return getMessage(tripDetails, conf, addresses);
  })
  const intro = `${conflicts.length} conflicting trips:`
  const messages = [intro, ...conflictMessages]
  return <SuggestionAlert variant="warning" title={name} messages={messages} action={setClosed} />;
}
