import { AddressData } from "../../../redux/models/settings.models";

export const defaultAddressData: AddressData = {
  port: false,
  samsara_id: '',
  samsara_name: '',
  samsara_latitude: 0,
  samsara_longitude: 0,
};

export interface Place {
  formattedAddress: string;
  id: string;
  currentOpeningHours?: {
    openNow: boolean;
    weekdayDescriptions: string[];
  };
  displayName: {
    text: string;
  };
  googleMapsUri: string;
  location: {
    latitude: number;
    longitude: number;
  };
}
