import React from 'react';
import { Alert, Stack } from 'react-bootstrap';
import { AlertCircle } from 'react-feather';

interface Props {
  variant: string;
  title: string;
  messages: string[];
  action: () => void;
}

export default function SuggestionAlert({ variant, title, messages, action }: Props) {
  return (
    <Alert
      variant={variant}
      className="alert-outline name-alert"
      data-cy="name-alert"
    >
      <div className="alert-icon name-btn">
        <AlertCircle />
      </div>
      <div className="alert-message">
        <Stack >
          <strong>{title}</strong>
          {
            messages.map((message) => (
              <div key={message}>
                {message}
              </div>
            ))
          }
          <Alert.Link data-cy="name-alert__link" onClick={action}>close</Alert.Link>
        </Stack>
      </div>
    </Alert>
  );
}
