import React from 'react';
import { SelectInput } from '../../shared/Input';
import { DropdownOptionsModel } from '../../shared/SelectInput.models';

interface Props {
  value: string[];
  page: string;
  prop: string;
  disabled: boolean;
  options: DropdownOptionsModel[];
  onChange: (prop: string, value: string) => void;
}

export default function AccessorialInput({ value, page, prop, options, disabled, onChange }: Props) {
  return (
    <SelectInput
      multiple
      name={prop}
      page={page}
      value={value}
      options={options}
      shouldHaveMargin={false}
      handleChange={onChange}
      dataCy="accessorials_input"
      disabled={disabled}
      labelText="Accessorials"
    />
  )
}
