import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default function TimelinessTooltip() {
  return (
    <OverlayTrigger overlay={(
      <Tooltip>
        <div>
          <p className="text-start text-muted ml-2 mt-1">
            Planned arrival and actual arrival must be filled out to show values
          </p>
          <ul className="text-start">
            <li className="text-primary">Excessively Early: 45 mins</li>
            <li className="text-info">Early: 10 - 45 mins</li>
            <li className="text-success">On Time: 30 mins early - 10 mins late</li>
            <li className="text-warning">Late: 10 - 45 mins</li>
            <li className="text-danger">Excessively Late: more than 45 mins</li>
          </ul>
        </div>
      </Tooltip>
    )}>
      <span><FontAwesomeIcon icon={faInfoCircle}/></span>
    </OverlayTrigger>
  );
}
