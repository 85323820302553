/* eslint-disable */
import { toast } from 'react-toastify';
import { createSlice } from '@reduxjs/toolkit';

import { Move, Trip } from '../../models/trip.models';
import TrailerChangeApi from '../../../services/TrailerChanges/TrailerChangeApi';
import { EntityContainer } from '../../models/core.models';
import { AppState, DispatchProps } from '../../models/state.models';
import { TRAILER_CHANGES } from '../slices';
import { arraysAreEqual } from '../../../utils/core.utils';
import { TrailerChangeState, MoveState } from './trailerChanges.models';
import { mapInitialTrailerState, TrailerStateHashTable } from './trailerChanges.utils';

/**
 * The purpose of this reducer is to see if trailers are changed on moves.
 * Trailers are often handed off between drivers on other trips without unloading shipments.
 * If a trailer containing these shipments is changed on one move it could be possible it needs changing on another trip / move
 * Shipments don't need to be tracked as they can be unloaded without impacting other trips.
 */
const initialState: TrailerChangeState = {
  moves: {},
  trips: {}, // keyed by trailer state
  associatedMoves: {},
  areTripsWithTrailerLoading: false,
};

const SLICE_NAME = TRAILER_CHANGES;

const slice = createSlice({
  name: TRAILER_CHANGES,
  initialState,
  reducers: {
    setCurrentTrailers(state, action) {
      const move = action.payload;
      const moveId = move.entity_id;
      const original = state.moves[moveId]?.original;
      const trailerIds = move.data.trailer_ids || [];
      const shipmentIds = move.data.shipment_ids || [];
      const oldmate: MoveState = {
        original,
        current: {
          shipment_ids: shipmentIds,
          trailer_ids: trailerIds
        }
      }
      state.moves = {
        ...state.moves,
        [moveId]: oldmate,
      };
    },
    setAssociatedMoveState(state, action) {
      const { moveId, moveState } = action.payload;
      state.associatedMoves = {
        ...state.associatedMoves,
        [moveId]: moveState,
      };
    },
    setTrailerState(state, action) {
      state.moves = action.payload;
    },
    setTrips(state, action) {
      const { move, trips } = action.payload;
      const moveId = move.entity_id || '';
      state.trips[moveId] = trips || [];
    },
    setTripChanges(state, action) {
      const { key, trips, moveId } = action.payload;
      const tripState = state.trips[key] || { moveIds: {} };
      const moveIds = tripState.moveIds || {};
      const tripChangeState = trips.reduce((store: EntityContainer<MoveState>, trip: Trip) => {
        const moves = trip.data.moves || [];
        const tripChangeMoveState = mapInitialTrailerState(moves);
        return {
          ...store,
          ...tripChangeMoveState,
        };
      }, {});
      state.trips = {
        ...state.trips,
        [key]: {
          tripState,
          trips,
          moveIds: {
            ...moveIds,
            [moveId]: moveId,
          }
        }
      }
      state.associatedMoves = {
        ...state.associatedMoves,
        ...tripChangeState,
      }
    },
    setAreTripsWithTrailerLoading(state, action) {
      state.areTripsWithTrailerLoading = action.payload;
    },
    resetTrailerChanges: () => initialState,
    resetAssociatedTrips: (state) => {
      state.trips = {};
      state.associatedMoves = {};
    },
  },
});

export const {
  setAreTripsWithTrailerLoading,
  setCurrentTrailers,
  setAssociatedMoveState,
  setTrailerState,
  setTrips,
  setTripChanges,
  resetAssociatedTrips,
  resetTrailerChanges,
} = slice.actions;

export const fetchTrailerChanges = (moveState: EntityContainer<MoveState>) => {
  // pass in the original to find others like it
  const moves = Object.values(moveState).flatMap((move) => move.original);
  // get unique combos so the order doesn't matter
  const hash = new TrailerStateHashTable(moves).getFirst();
  const trailerIds = hash?.trailer_ids || [];
  const shipmentIds = hash?.shipment_ids || [];
  return TrailerChangeApi.getTrailerChangesTrips(
    trailerIds,
    shipmentIds,
  );
};

export const getTripsWithTrailerChange = (state: any, move: Move) => async (dispatch: DispatchProps) => {
  dispatch(setAreTripsWithTrailerLoading(true));
  try {
    const container = { ...state };
    console.log(container);
    const { data: trailerChange } = await TrailerChangeApi.getTrailerChangesTrips(
      move.data.trailer_ids || [],
      move.data.shipment_ids || [],
    );
    console.log(trailerChange);
    
  } catch (e) {
    const errorMessage = "Couldn't complete trailer search. Please contact support if the problem persists";
    toast(errorMessage, { type: 'error' });
  } finally {
    dispatch(setAreTripsWithTrailerLoading(false));
  }
};

export const checkTrailerChanges = (move: Move, moveState: MoveState) => async (dispatch: DispatchProps) => {
  // dispatch(setCurrentTrailers(move));
  const areShipmentsEqual = arraysAreEqual(
    moveState.original.shipment_ids || [],
    moveState.current.shipment_ids || []
  );
  
  // check shipment_ids equality
  if (areShipmentsEqual === false) {
    return
  }
  // update local state
  // check endpoint for changes
  // filter by trip id
  const response = await TrailerChangeApi.getTrailerChangesTrips(
    moveState.original.trailer_ids || [],
    move.data.shipment_ids || [],
  );
  const trips: Trip[] = response.data.data || [];
  const changes = trips.filter((trip) => trip.entity_id !== move.data.trip_id);
  const key = TrailerStateHashTable.generateKey(moveState.original);
  // create new move state object to store trips
  dispatch(setTripChanges({ key, trips: changes, moveId: move.entity_id }));
  // store trips by move id
};

export const updateTrailerChanges = async (data: any) => TrailerChangeApi.updateTrailerChanges(data);

export const getInitialTrailers = (moves: Move[]) => (dispatch: DispatchProps) => {
  const trailerState = mapInitialTrailerState(moves);
  dispatch(setTrailerState(trailerState));
};

export const updateTrailerState = (moves: Move[]) => (dispatch: DispatchProps) => {
  const trailerState = mapInitialTrailerState(moves);
  dispatch(setTrailerState(trailerState));
};

export const selectAllState = (state: AppState) => state[SLICE_NAME];
export const loadingTrailers = (state: any) => state[SLICE_NAME].areTripsWithTrailerLoading;
export const selectTrailerChangeTripState = (state: AppState) => state[SLICE_NAME].trips;
export const selectMoveState = (state: AppState) => state[SLICE_NAME].moves;
export const selectAssociatedMoveState = (state: AppState) => state[SLICE_NAME].associatedMoves;
export const selectMoveStateById = (state: AppState, moveId: string) => {
  const moveState = selectMoveState(state);
  const changes = moveState[moveId];
  return changes;
};
export const selectAssociatedMoveStateById = (state: AppState, moveId: string) => {
  const moveState = selectAssociatedMoveState(state);
  const changes = moveState[moveId];
  return changes;
};
export const selectIsTrailersChanged = (state: AppState) => {
  const changedTrips = selectTrailerChangeTripState(state);
  return Object.values(changedTrips).some((tripsState) => tripsState.trips.length > 0);
};
export const selectUpdatedAssociatedTrips = (state: AppState) => {
  const changedTrips = selectTrailerChangeTripState(state);
  const associatedMoves = selectAssociatedMoveState(state);
  return Object.values(changedTrips).flatMap((tripsState) => {
    const trips = tripsState.trips || [];
    return trips.map((trip) => {
      const moves = trip.data.moves || [];
      return {
        ...trip,
        data: {
          ...trip.data,
          moves: moves.map((move) => {
            const moveId = move.entity_id;
            const updatedTrailerIds = associatedMoves[moveId].current.trailer_ids || [];
            return {
              ...move,
              data: {
                ...move.data,
                trailer_ids: updatedTrailerIds,
              }
            }
          })
        }
      }
    })
  });
};
export const selectIsTrailersDirty = (state: AppState) => {
  const moves = selectMoveState(state);
  return Object.values(moves).some((move) => arraysAreEqual(move.original.trailer_ids, move.current.trailer_ids));
};
export const selectChangedTrailers = (state: AppState) => {
  const moves = state[SLICE_NAME].moves || {};
  return Object.values(moves).filter((move) => {
    const original = move.original.trailer_ids;
    const current = move.current.trailer_ids;
    return !arraysAreEqual(original, current);
  });
};
export const selectChangedTrailerIds = (state: AppState) => {
  const moves = state[SLICE_NAME].moves || {};
  return Object.values(moves).reduce((store: EntityContainer<string>, move: MoveState) => {
    const trailerIds = move.original.trailer_ids.reduce((stateStore: EntityContainer<string>, id: string) => {
      return {
        ...stateStore,
        [id]: id,
      }
    }, {});
    return {
      ...store,
      ...trailerIds,
    }
  }, {});
};

export const trailerChangesReducer = slice.reducer;
