import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Form, Row } from 'react-bootstrap';
import { TripListViewData } from '../../../../redux/models/trip.models';
import DocumentsGrid from '../../../../components/documents/DocumentsGrid';
import { selectAddressesObject } from '../../../../redux/slices/settings';
import { Document } from '../../../../redux/models/file.models';
import AssignDocModal from './AssignDocModal';
import { Shipment } from '../../../../redux/models/shipment.models';

interface Props {
  shipment: Shipment;
  trip: TripListViewData;
  onClose: () => void;
  onUpdateStore: (shipment: Shipment) => void;
}

export default function DocumentsPanel({ shipment, trip, onClose, onUpdateStore }: Props) {
  const shipmentId = shipment.entity_id;
  const addresses = useSelector(selectAddressesObject);
  const [shipmentOnly, setShipmentOnly] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<Document | null>(null);
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const moveData = !shipmentOnly ? trip.moves : trip.moves.filter((mv) => mv.shipment_ids.includes(shipmentId));
  const moves = moveData.filter((move) => {
    const docs = move.documents || [];
    return docs.length;
  });
  const handleOpenAssignModal = (doc: Document, idx: number) => {
    setSelectedDoc(doc);
    setSelectedIdx(idx);
  };
  const handleCloseModal = () => {
    setSelectedDoc(null);
    setSelectedIdx(null);
  };
  return (
    <Card className="sticky-top">
      <Card.Body>
        <h4>{trip.trip_no || 'Trip'} Documents</h4>
        <Form.Group data-cy="shipment_docs_toggle">
          <Form.Check
            type="switch"
            id="shipment_docs__switch"
            label="Only Show Moves Containing Shipment"
            onChange={(e) => setShipmentOnly(e.target.checked)}
            checked={shipmentOnly}
          />
        </Form.Group>
        {moves.map((mv) => {
          const docs = mv.documents || [];
          const addr = addresses[mv.destination_id || ''];
          return (
            <Row key={mv.position} className="mt-4">
              <h5>{addr?.data.samsara_name || ''}</h5>
              <DocumentsGrid documents={docs} showAssign handleAssignDocument={handleOpenAssignModal} />
            </Row>
          )
        })}
        <Button variant="light" onClick={onClose} className="mt-4">Close</Button>
      </Card.Body>
      {selectedDoc && selectedIdx !== null && (
        <AssignDocModal
          shipment={shipment}
          document={selectedDoc}
          documentIdx={selectedIdx}
          shouldShowModal
          onUpdateStore={onUpdateStore}
          handleSave={handleCloseModal}
          cancelSave={handleCloseModal}
        />
      )}
    </Card>
  );
}
