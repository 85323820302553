/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Col, Row, Spinner, Stack } from 'react-bootstrap';
import {
  getSettingByName,
  getTripTemplate,
  selectAddressesObject,
  selectBoards,
  selectDriversByTags,
  selectFleetObject,
  selectGroups,
  selectVehicles,
  updateTripTemplate,
} from '../../redux/slices/settings';
import { Group, TripTemplate } from '../../redux/models/settings.models';

import useAppDispatch from '../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../constants/core.constants';
import { getDefaultDataModel } from '../../redux/models/core.models';
import { createDropdownOptions, createShipmentIdsDropdownOptions } from '../../utils/core.utils';
import BreadCrumbs, { BreadCrumbRoute } from '../../components/shared/breadcrumbs/BreadCrumbs';
import { Move, TripDetails, TripDetailsData } from '../../redux/models/trip.models';
import TripForm from '../trips/TripForm';
import { Board } from '../../redux/models/board.models';
import { AppState } from '../../redux/models/state.models';
import { TRIP_STATUS_TYPES } from '../trips/constants/trips.constants';
import MoveHeader from '../trips/Moves/MoveHeader';
import MovesTable from '../trips/Moves/MovesTable';
import {
  handleMoveItemDateChange,
  handleMoveDestinationChange,
  handleDeleteMove,
  handleMoveItemChange,
  getBoardVehicles,
  getBoardFleet
} from '../trips/utils/trips.utils';
import MovePanel from '../trips/Moves/MovePanel';

const getRoutes = (
  orgCode: string | undefined,
  template: TripTemplate | null,
  key: string | undefined): BreadCrumbRoute[] => {
  return [
    {
      name: 'Settings',
      route: `/${orgCode || ''}/settings`,
    },
    {
      name: `${template?.data.name || ''}`,
      route: `/${orgCode}/settings/trip-templates/${template?.entity_id || ''}`,
    },
    {
      name: `Trip`,
      route: `/${orgCode}/settings/trip-templates/${template?.entity_id || ''}/trips/${key || ''}`,
    },
  ];
};

const TRIP_FORM_ID = 'tripDetails';

const getTripDetails = (trips: TripDetailsData[], key: string | undefined) => {
  if (key === undefined) return null;
  const keyId = parseInt(key, 2);
  if (Number.isNaN(keyId) || keyId > trips.length) return null;
  const trip = trips[keyId];
  const defaultTrip = getDefaultDataModel(trip);
  const update: TripDetails = {
    ...defaultTrip,
    data: {
      ...defaultTrip.data,
      moves: defaultTrip.data.moves.map((mv: any, idx) => {
        return {
          ...getDefaultDataModel(mv),
          entity_id: `${idx}`,
        }
      }),
    },
  };
  return update;
};

export default function TripTemplateTripEditor() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode, templateId, key } = useParams();
  const [template, setTemplate] = useState<TripTemplate | null>(null);
  const [tripDetails, setTripDetails] = useState<TripDetails | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const boards = useSelector(selectBoards);
  const groups = useSelector(selectGroups);
  const vehicles = useSelector(selectVehicles);
  const addresses = useSelector(selectAddressesObject);
  const fleet = useSelector(selectFleetObject);
  const [selectedMovePosition, setSelectedMovePosition] = useState<number | null>(null);

  const showMovesMenu = selectedMovePosition !== null;
  const currentBoard = boards.find((board: Board) => board.entity_id === tripDetails?.data.board_id);
  const currentBoardDriverTags = currentBoard?.data?.driver_tags || [];
  const currentVehcileTags = currentBoard?.data?.vehicle_tags || [];
  const fleetTags = currentBoard?.data?.fleet_tags;
  const dispatchTemplates = currentBoard?.data.note_templates || [];
  const boardGroups = groups.filter((group: Group) => group.data.board_id === tripDetails?.data.board_id);
  const driversByTagId = useSelector((state: AppState) => selectDriversByTags(state, currentBoardDriverTags));
  const boardVehicles = getBoardVehicles(vehicles, currentVehcileTags)
  const statusSelectOptions = TRIP_STATUS_TYPES;
  const boardSelectOptions = createDropdownOptions(boards);
  const groupSelectOptions = createDropdownOptions(boardGroups);
  const vehicleSelectOptions = createDropdownOptions(boardVehicles, 'samsara_name');
  const driverSelectOptions = createDropdownOptions(driversByTagId, 'samsara_name');
  const moves = tripDetails?.data.moves || [];
  const shipments = tripDetails?.data.shipments || [];
  const selectedMove = moves.find((mv: Move) => {
    const mvPos = mv.data?.position || 0;
    return mvPos === selectedMovePosition
  });
  const sortedMoves = [...moves].sort((a, b) => (a.data?.position || 0) - (b.data?.position || 0));
  const boardFleet = getBoardFleet(Object.values(fleet), fleetTags);
  const fleetDropdownOptions = createDropdownOptions(boardFleet);

  const addressesObject = useSelector(selectAddressesObject);
  const addressesDropdownOptions = createDropdownOptions(
    Object.values(addresses),
    'samsara_name',
    'samsara_formatted_address',
  );
  const shipmentIdsDropdownOptions = createShipmentIdsDropdownOptions(shipments, addressesObject, 'shipment_no');
  const handleTripUpdate = (trip: TripDetails) => {
    if (!tripDetails) return;
    setTripDetails(trip);
  };
  const handleMoveUpdate = (moveData: Move[]) => {
    if (!tripDetails) return;
    handleTripUpdate({
      ...tripDetails,
      data: {
        ...tripDetails.data,
        moves: moveData,
      },
    });
  };
  const onMoveItemDateChange = (movePosition: number) => (prop: string, value: string) => {
    const updatedMovesData = handleMoveItemDateChange(movePosition, sortedMoves)(prop, value);
    handleMoveUpdate(updatedMovesData);
  };
  const onMoveDestinationChange = (movePosition: number) => (prop: string, value: string) => {
    const updatedMovesData = handleMoveDestinationChange(movePosition, sortedMoves)(prop, value);
    handleMoveUpdate(updatedMovesData);
  };
  const onDeleteMove = (position: number) => {
    const updatedMovesData = handleDeleteMove(position, sortedMoves);
    setSelectedMovePosition(null);
    handleMoveUpdate(updatedMovesData);
  };
  const onMoveItemChange = (movePosition: number) => (prop: string, value: string) => {
    const updatedMovesData: any = handleMoveItemChange(movePosition, sortedMoves)(prop, value);
    handleMoveUpdate(updatedMovesData);
  };
  const onAddMove = (mv: Move) => {
    handleMoveUpdate([...sortedMoves, mv]);
  };
  
  const handleClose = () => navigate(-1);
  const handleSave = async () => {
    if (!template || tripDetails === null) return;
    try {
      setIsSaving(true);
      const templateData: TripTemplate = {
        ...template,
        data: {
          ...template.data,
          trips: template.data.trips.map((trip, idx) => {
            if (`${idx}` === key) {
              const item: any = {
                ...tripDetails.data,
                moves: tripDetails.data.moves.map((mv) => mv.data)
              };
              return item;
            };
            return trip;
          })
        }
      }
      const response = await updateTripTemplate(templateData);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.TRIP_TEMPLATES));
        handleClose();
      }
    } catch (error) {
      toast(`Couldn't save ${template?.data.name || 'template'} shipment`, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const handleLoad = async (id: string, keyId: string) => {
      try {
        const response = await getTripTemplate(id);
        if (response.status === 200) {
          const templateData = response.data.data;
          setTemplate(templateData);
          const trips = templateData?.data?.trips || [];
          const ship = getTripDetails(trips, keyId);
          setTripDetails(ship);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    if (templateId && key) handleLoad(templateId, key);
  }, [templateId, key]);
  
  if (isLoading) return <Spinner animation="border" variant="light" />;
  return (
    <div>
      <BreadCrumbs routes={getRoutes(orgCode, template, key)} />
      <h4>{`${template?.data.name || ''} Template Shipment`}</h4>
      {tripDetails && tripDetails.data && (
        <Row>
          <Col md={showMovesMenu ? 8 : 12}>
            <TripForm
              isEditable
              page={TRIP_FORM_ID}
              tripDetails={tripDetails}
              boardSelectOptions={boardSelectOptions}
              groupSelectOptions={groupSelectOptions}
              driverSelectOptions={driverSelectOptions}
              vehicleSelectOptions={vehicleSelectOptions}
              statusSelectOptions={statusSelectOptions}
              onUpdateTrip={handleTripUpdate}
              onUpdateMoves={handleMoveUpdate}
            />
            <MoveHeader
              tripDetails={tripDetails}
              shouldDisableSave={false}
              dispatchTemplates={dispatchTemplates}
              onAddMove={onAddMove}
            />
            <MovesTable
              isEditable
              tripDetails={tripDetails}
              fleetOptions={fleetDropdownOptions}
              shipmentIdsDropdownOptions={shipmentIdsDropdownOptions}
              moves={sortedMoves}
              page={TRIP_FORM_ID}
              addressOptions={addressesDropdownOptions}
              showShipments={false}
              setSelectedMove={setSelectedMovePosition}
              onMoveItemChange={onMoveItemChange}
              onMoveItemDateChange={onMoveItemDateChange}
              // onMoveDestinationChange={onMoveDestinationChange}
              // handleNewAddress={handleNewAddress}
            />
          </Col>
          {showMovesMenu && selectedMove ? (
            <Col md={4}>
              <MovePanel
                showShipments={false}
                move={selectedMove}
                addressOptions={addressesDropdownOptions}
                fleetOptions={fleetDropdownOptions}
                shipmentIdsDropdownOptions={shipmentIdsDropdownOptions}
                routeId={tripDetails.data.samsara_route_id || ''}
                noteTemplates={dispatchTemplates}
                onMoveItemChange={onMoveItemChange}
                onMoveItemDateChange={onMoveItemDateChange}
                onMoveDestinationChange={onMoveDestinationChange}
                setSelectedMove={setSelectedMovePosition}
                deleteMove={onDeleteMove}
              />
            </Col>
          ) : null}
        </Row>
      )}
      <Row>
        <Stack direction="horizontal" gap={3} className="float-end justify-content-end">
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSave}>Save
            {isSaving && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                style={{ marginLeft: '4px' }}
              />
            )}
          </Button>
        </Stack>
      </Row>
    </div>
  );
}
