/* eslint-disable radix */
import React from 'react';
import { TripDetails } from '../../../../redux/models/trip.models';
import { DateService } from '../../../../utils/dateService';

interface Props {
  trip: TripDetails;
}

export default function MoveDistanceLabel({ trip }: Props) {
  let estimate = '';
  const metres = trip.data.estimated_distance_metres;
  const seconds = trip.data.estimated_duration_seconds;
  if (metres) {
    const km = metres / 1000;
    estimate = `${parseInt(`${km}`)} km`;
  }
  if (seconds) {
    const milliseconds = seconds * 1000;
    const hrs = DateService.msToTime(milliseconds);
    const time = `${hrs} hrs estimated travel time`;
    estimate += estimate === '' ? time : ` • ${time}`;
  }
  return (
    <p>{estimate}</p>
  )
}
