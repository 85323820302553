import React, { useEffect, useState } from 'react';
import { Truck } from "react-feather";
import Permission from '../../../../components/shared/permissions/Permission';
import { TripReadPermissions } from '../../../../components/shared/permissions/permissions.utils';

import StatCard from './StatCard';
import { Api } from '../../../../services/services';

interface Props {
  dateFrom: string;
  dateTo: string;
}

export default function TotalTripStat({ dateFrom, dateTo }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [trips, setTrips] = useState(0);
  const [moves, setMoves] = useState(0);
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await Api.Dashboard.tripTotals({
        start_date: dateFrom,
        end_date: dateTo,
      });
      if (response.status === 200) {
        const trs = response.data.data.trips || 0;
        const mvs = response.data.data.moves || 0;
        setTrips(trs);
        setMoves(mvs);
      }
      setIsLoading(false);
    };
    load();
  }, [dateFrom, dateTo]);
  return (
    <Permission resources={TripReadPermissions}>
      <StatCard
        title={`${trips}`}
        description="Total Trips"
        timerange={`With ${moves} moves`}
        isLoading={isLoading}
        icon={<Truck className="align-middle text-success" />}
      />
    </Permission>
  );
}
