import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Spinner, Stack, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { ControlInput } from '../../../components/shared/Input';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import {
  getSettingByName
} from '../../../redux/slices/settings';

import { Api } from '../../../services/services';
import { Contact } from '../../../redux/models/contact.models';
import ContactRates from './ContactRates';
import Permission from '../../../components/shared/permissions/Permission';
import { BillingTypeReadPermissions } from '../../../components/shared/permissions/permissions.utils';
import AsyncButton from '../../../components/shared/buttons/AsyncButton';
import { BillingType } from '../../../redux/models/billing.models';
import { ApiQuery, DEFAULT_API_QUERY, getDataSorting } from '../../../redux/models/network.models';
import { getRelQuery } from '../../../redux/slices/network.utils';
import BreadCrumbs, { BreadCrumbRoute } from '../../../components/shared/breadcrumbs/BreadCrumbs';

const PAGE = 'shipment_type_editor';
const getRoutes = (orgCode: string | undefined, customer: Contact | null): BreadCrumbRoute[] => {
  const routes = [
    {
      name: 'Settings',
      route: `/${orgCode || ''}/settings`,
    },
    {
      name: 'Contacts',
      route: `/${orgCode}/settings/contacts`,
    },
  ];
  if (customer) {
    routes.push({
      name: customer.data.name ?? 'No name',
      route: `/${orgCode}/settings/contacts/${customer.entity_id}`,
    });
  }
  return routes;
};

export default function ContactDetails() {
  const dispatch = useAppDispatch();
  const { orgCode, entityId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [contact, setContact] = useState<Contact | null>(null);
  const [name, setName] = useState(contact?.data.name || '');
  const [templates, setTemplates] = useState<BillingType[]>([]);

  const handleNameUpdate = (_: string, value: string) => {
    setName(value);
  };

  const handleClose = () => navigate(`/${orgCode}/settings/contacts`);
  const handleSave = async () => {
    if (!contact) return;
    const updates: Contact = {
      ...contact,
      data: {
        ...contact.data,
        name,
      },
    };
    try {
      const response = await Api.Contacts.update(updates);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.CONTACTS, false));
        handleClose();
      }
    } catch (error) {
      toast(`Couldn't save ${name || 'shipment template'}`, { type: 'error' });
    }
  };

  const handleUpdateTemplate = (template: BillingType) => {
    const items = templates.filter((item) => item.entity_id !== template.entity_id);
    items.push(template);
    setTemplates(items);
  };

  const handleDelete = (template: BillingType) => {
    const items = templates.filter((item) => item.entity_id !== template.entity_id);
    setTemplates(items);
  }

  useEffect(() => {
    const handleLoad = async (id: string) => {
      setIsLoading(true);
      const query: ApiQuery = {
        ...DEFAULT_API_QUERY,
        filters: [
          getRelQuery('customer_id', id),
        ],
        sorting: getDataSorting('name'),
      }
      const contactResponse = await Api.Contacts.getById(id);
      const response = await Api.BillingTypes.find(query);
      if (contactResponse.status === 200) {
        const data: Contact | null | undefined = contactResponse.data.data || {};
        if (data) {
          setContact(data);
          setName(data.data.name);
        }
      }
      if (response.status === 200) {
        const data: BillingType[] | null | undefined = response.data.data.items || [];
        if (data) {
          setTemplates(data);
        }
      }
      setIsLoading(false);
    }
    if (entityId) handleLoad(entityId);
  }, [entityId]);

  if (isLoading) return <Spinner animation="border" variant="light" />;

  return (
    <>
      <BreadCrumbs routes={getRoutes(orgCode, contact)} />
      <Card>
        <Card.Header as="h4">Customer Details</Card.Header>
        <Card.Body>
          {isLoading && <Spinner animation="border" variant="primary" />}
          {!isLoading && (
            <>
              <Row>
                <ControlInput
                  dataCy={`${PAGE}_name_input`}
                  name="name"
                  type="text"
                  page={PAGE}
                  value={name}
                  labelText="Name"
                  handleChange={handleNameUpdate}
                />
              </Row>
              <Row>
                {!isLoading && entityId && (
                  <Permission resources={BillingTypeReadPermissions}>
                    <ContactRates
                      templates={templates}
                      contactId={entityId}
                      onUpdate={handleUpdateTemplate}
                      onDelete={handleDelete}
                    />
                  </Permission>
                )}
              </Row>
            </>
          )}
        </Card.Body>
        {!isLoading && (
          <Card.Footer>
            <Button variant="outline-danger" onClick={handleClose}>Delete</Button>
            <Stack direction="horizontal" gap={3} className="float-end justify-content-end">
              <Button variant="secondary" onClick={handleClose}>Cancel</Button>
              <AsyncButton
                title="Save"
                variant="primary"
                spinner="light"
                dataCy={`${PAGE}_save_button`}
                handleClick={handleSave}
              />
            </Stack>
          </Card.Footer>
        )}
      </Card>
    </>
  );
}
