import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  selectBoard,
  selectDriversByTags,
} from '../../../redux/slices/settings';
import { selectTrips } from '../../../redux/slices/trips/trips';
import { Tag } from '../../../redux/models/settings.models';
import { AppState } from '../../../redux/models/state.models';
import { TripBoardModel } from '../../../redux/models/trip.models';
import { EntityContainer } from '../../../redux/models/core.models';
import {
  createDraft,
  selectCurrentPlan,
  setCurrentPlan,
} from '../../../redux/slices/planner/planner';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { createDraftTripPlan } from '../../../redux/slices/planner/planner.utils';
import Analytics from '../../../utils/analytics';

interface Props {
  boardId: string;
  dateFrom: string;
  dateTo: string;
  tags: Tag[];
}

export default function PlannerEmptyState({ boardId, tags, dateFrom, dateTo }: Props) {
  const dispatch = useAppDispatch();
  const { orgCode } = useParams();
  const plan = useSelector(selectCurrentPlan);
  const drivers = useSelector((state: AppState) => selectDriversByTags(state, tags));
  const board = useSelector((state: AppState) => selectBoard(state, boardId));
  const [isCreatingDraft, setIsCreatingDraft] = useState(false);
  const tripContainer: EntityContainer<TripBoardModel> = useSelector(selectTrips);
  const handleCreateDraft = async () => {
    if (!board) return;
    try {
      setIsCreatingDraft(true);
      const pl = createDraftTripPlan(plan, board, tripContainer, drivers, dateFrom, dateTo);
      const response = await createDraft(pl.data);
      if (response.status === 200) {
        const itemData = response.data.data;
        if (itemData) {
          dispatch(setCurrentPlan(itemData));
        }
      }
    } catch (error) {
      toast("Couldn't Create Draft", { });
    } finally {
      setIsCreatingDraft(false);
      Analytics.createDraft(orgCode);
    }
  }
  return (
    <Button type="button" onClick={handleCreateDraft}>
      {isCreatingDraft ? <Spinner animation="border" variant="primary" size="sm" /> : 'Create Draft'}
    </Button>
  )
}