/* eslint-disable */
import { DriverAvailabilityData } from '../../redux/models/driver.availability.models';
import { EntityServiceProvider } from '../base/EntityServiceProvider';
// static Availability = new EntityServiceProvider<DriverAvailabilityData>('driver-availability', axiosInstance);

class DriverAvailabilityServiceProvider extends EntityServiceProvider<DriverAvailabilityData> {
  applyAvailability(driverId: string, toDriverIds: string[], tagIds: string[]) {
    const url = `${this.endpoint.entityPath()}/apply/${driverId}/async`;
    return this.api.post(url, { driver_ids: toDriverIds, tag_ids: tagIds });
  }
}

export default DriverAvailabilityServiceProvider;
