/* eslint-disable */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import AddModal from '../../../components/modals/AddModal';
import { TripDetails } from '../../../redux/models/trip.models';
import Analytics from '../../../utils/analytics';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { dispatchTrip, selectIsTripDirty, setTripDetails } from '../../../redux/slices/trips/trips';
import { selectAddressesObject, selectDriversObject } from '../../../redux/slices/settings';
import { formatDispatchErrorMessage, isDispatchErrors, validateSamsaraDispatch } from '../utils/dispatch.utils';

interface Props {
  shouldShow: boolean;
  trip: TripDetails;
  onClose: () => void;
  saveTrip: (trip: TripDetails) => void;
}

export default function DispatchSamsaraModal({
  shouldShow,
  trip,
  saveTrip,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const drivers = useSelector(selectDriversObject);
  const addresses = useSelector(selectAddressesObject);
  const isDirty = useSelector(selectIsTripDirty);
  const { orgCode } = useParams();
  const [isSending, setIsSending] = useState(false);
  
  const handleDispatchTrip = async () => {
    const errors = validateSamsaraDispatch(trip, drivers, addresses);
    const isInvalid = isDispatchErrors(errors);
    if (isInvalid) {
      const message = formatDispatchErrorMessage(errors);
      toast(message, { type: 'error' });
      return;
    }
    try {
      setIsSending(true);
      if (isDirty) await saveTrip(trip);
      const { data, status } = await dispatchTrip(trip.entity_id);
      if (status === 200) {
        dispatch(setTripDetails(data));
        toast('Driver dispatched', { type: 'success' });
        onClose();
      } else {
        const message = data?.description || '';
        toast(message, { type: 'error' });
      }
    } catch (e) {
      if (e instanceof Error) {
        const errorMessage = `Couldn't dispatch driver. ${e.message}`;
        toast(errorMessage, { type: 'error' });
      }
      Analytics.capture(e);
    } finally {
      Analytics.dispatchRoute(orgCode, 'Samsara');
      setIsSending(false);
    }
  };
  return (
    <AddModal
      title="Samsara Dispatch"
      btnTitle="Send"
      variant="primary"
      spinner="light"
      saveDisabled={isSending}
      shouldShowModal={shouldShow}
      isSaving={isSending}
      handleSave={handleDispatchTrip}
      cancelSave={onClose}
    >
      <p>Would you like to send this dispatch to driver?</p>
    </AddModal>
  );
}
