/* eslint-disable no-var */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { decodeToken } from 'react-jwt';
import { Driver, Tag } from '../models/settings.models';
import { LOCAL_STORAGE_TOKEN_KEY, getPermissions } from '../../config';
import { FeaturData, ResourcePermission } from '../models/feature.flags.models';
import { EntityContainer } from '../models/core.models';
import { ApiQuery, ElementType, QueryOperator, FilterOp, QueryFilter } from '../models/network.models';

const { Read, Write } = ResourcePermission;

interface UserPermissions {
  orgs: string[];
  permissions: string[];
}

const getSearchField = (field: string, value: string): QueryFilter => {
  return {
    field: {
      name: `data.${field}`,
      type: ElementType.String,
    },
    op: FilterOp.Regex,
    value,
  };
};

export const getPostSearchBody = (field: string, value: string, secondField?: string) => {
  const primarySearch = getSearchField(field, value);
  const secondSearch = secondField ? getSearchField(secondField, value) : null;
  const filters: QueryFilter[] = secondSearch ? [
    primarySearch,
    secondSearch,
  ] : [primarySearch];
  const query: ApiQuery = {
    filters,
    operator: secondSearch ? QueryOperator.Or : QueryOperator.And,
    pagination: {
      "page": 0,
      "page_size": 25,
    },
  };
  return query;
};

export const getToken = () => {
  const accessToken = window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  if (accessToken) {
    const token: UserPermissions | null = decodeToken(accessToken);
    return token;
  }
  return null;
}

export const filterTags = (tagIds: string[], tags: Tag[]) => {
  const ids = tagIds || [];
  const tagData = tags || [];
  var contains = false;
  tagData.forEach((tag) => {
    if (ids.includes(tag.entity_id)) contains = true;
  })
  return contains;
};

export const filterDriversByTags = (drivers: Driver[], tags: Tag[]): Driver[] => {
  var data = [...drivers];
  if (tags && tags.length > 0) {
    data = data.filter((driver: Driver) => filterTags(driver.data?.hp_tag_ids || [], tags));
  }
  return data
    .sort((a: Driver, b: Driver) => a?.data?.samsara_name?.localeCompare(b?.data?.samsara_name));
};

const arrayOverlap = (array1: Array<any>, array2: Array<any>) => {
  const set2 = new Set(array2);
  for (let i = 0; i < array1.length; i++) {
      if (set2.has(array1[i])) {
          return true;
      }
  }
  return false;
};

export const isPermissionEnabled = (orgCodes: string[], resourceIds: string[], userIds: string[]) => {
  const accessToken = window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '';
  const decoded: any = decodeToken(accessToken);

  const userResources = getPermissions();
  const userOrgs = decoded?.orgs || [];
  const userId = decoded?.sub || '';

  const isOrgsEnabled = arrayOverlap(orgCodes, userOrgs);
  const isResourcesEnabled = arrayOverlap(resourceIds, userResources);
  const isUsersEnabled = userIds.includes(userId);
  return isOrgsEnabled || isResourcesEnabled || isUsersEnabled;
};

export const isFeatureEnabled = (feature: FeaturData) => {
  if (feature === undefined || feature === null) return false;
  if (Object.keys(feature).length === 0) return false;

  const featureOrgData = feature?.organizations || [];
  const orgCodes = featureOrgData.map((org) => org.data?.organization_code);
  const resourceIds = feature?.resource_ids || [];
  const featureUserData = feature?.users || [];
  const userSubs = featureUserData.map((user) => user.data.auth0_id);
  return isPermissionEnabled(orgCodes, resourceIds, userSubs);
};

const createResourcePermissionItem = (orgCode: string, resource: string, permission: ResourcePermission) => {
  return `${orgCode}:${resource}:${permission}`
};

/**
 * Create required permissions the user must contain to access the feature.
 * @param resource resouce_id of the hopper collection
 * @param permission required permission of the feature
 * @returns string format of resource_id:permission Read | Write
 */
const createResourcePermissions = (orgCode: string, resource: string, permission: ResourcePermission) => {
  if (permission === Write) {
    return [createResourcePermissionItem(orgCode, resource, Write)]
  }
  // A user who can read and write will only contain resource_id:write scope so we include
  return [
    createResourcePermissionItem(orgCode, resource, Read),
    createResourcePermissionItem(orgCode, resource, Write),
  ];
};

export const createRequiredPermissions = (
  orgCode: string | undefined,
  resources: EntityContainer<ResourcePermission>,
) => {
  if (!orgCode) return [];
  return Object.keys(resources).flatMap((resource) =>
    createResourcePermissions(orgCode, resource, resources[resource] || false)
  );
};

export const isPermissions = (
  orgCode: string | undefined,
  permissions: string[],
  resources: EntityContainer<ResourcePermission>,
) => {
  const resourceIds = createRequiredPermissions(orgCode, resources);
  return resourceIds.some((resourceId) => {
    return permissions.some((permission) => {
      return permission.includes(resourceId);
    });
  });
}
