/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosHopperInstance from "../../utils/hopperAxios";
import { EntityServiceProvider } from "../base/EntityServiceProvider";


class IntegrationApiProvider extends EntityServiceProvider<any> {
  constructor() {
    super('integration', axiosHopperInstance);
  }


  authorizeOAuth2(appId: string) {
    const domain = window.location.host || '';
    const url = `/oauth2/authorize?app_id=${appId}&redirect_uri=https://${domain}`;
    return this.api.get(url);
  }

  orgIntegrations(orgId: string) {
    const url = `/integration?organisation_id=${orgId}`
    return this.api.get(url);
  }
}

export default IntegrationApiProvider;