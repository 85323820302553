/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Button, Spinner } from 'react-bootstrap'
import { ChevronDown, ChevronRight } from 'react-feather'
import { useParams } from 'react-router-dom'
import TripRow  from './rows/TripRow'
import { Group, GroupView, NoteTemplate } from '../../../../redux/models/settings.models'
import { TripBoardModel } from '../../../../redux/models/trip.models'
import { AppState } from '../../../../redux/models/state.models'
import { selectTripsByCardId, sendTripRouteAsync } from '../../../../redux/slices/trips/trips'
import { TripTableColumns } from '../../../../redux/models/board.models'
import TemplatesMenu from '../../Groups/TemplatesMenu'
import ConfirmModal from '../../../../components/modals/ConfirmModal'
import { getTripIdsForDispatch } from '../../../planner/TripPlanner/table.utils'
import { DateService } from '../../../../utils/dateService'
import useAppSettings from '../../../../hooks/useAppSettings'
import { formatOrgName } from '../../../../utils/core.utils'
import { AsyncDispatchNotificationResponse } from '../../../../redux/slices/dispatch/dispatch.models'
import { infoToast } from '../../../../components/notifications/app-toast'
import useAppDispatch from '../../../../hooks/useAppDispatch'
import { setMultiTripDispatch } from '../../../../redux/slices/notifications/notifications'

const getDispatchMessage = (numTrips: number) => {
  const header = `Would you like to dispatch ${numTrips} trips in this group to these drivers?`;
  const statuses = `completed, dispatched and driver confirmed`
  return `${header} Trips without drivers as well as ${statuses} trips won't be dispatched.`;
};

interface Props {
  group: Group | GroupView;
  columns: TripTableColumns[];
  boardDate: string;
  isDateRange: boolean;
  startResizing: (index: number) => (e: React.MouseEvent) => void;
  onUpdateTask: (sectionId: string, taskId: string, key: string, value: any, subtaskId?: string) => void;
  onGroupExpand: (id: string) => void;
  onTripExpand: (id: string) => void;
  onMoveExpand: (id: string) => void;
  expandedGroupRows: string[];
  expandedMoveRows: string[];
  expandedShipmentRows: string[];
  noteTemplates: NoteTemplate[];
}

function SectionComponent({
  group,
  columns,
  boardDate,
  isDateRange,
  expandedGroupRows, expandedMoveRows, expandedShipmentRows,
  noteTemplates,
  startResizing, onUpdateTask, onGroupExpand, onMoveExpand, onTripExpand
}: Props) {
  const tableRef = useRef<HTMLTableElement>(null)
  const dispatch = useAppDispatch();
  const groupTrips: TripBoardModel[] = useSelector((state: AppState) => selectTripsByCardId(state, group.entity_id));
  const { timezone } = useAppSettings();
  const { orgCode } = useParams();
  const [confirmDispatchOpen, setConfirmDispatchOpen] = useState(false);
  const [isCreatingTrip, setIsCreatingTrip] = useState(false);
  const [isAssigningDrivers, setIsAssigningDrivers] = useState(false);
  const orgName = formatOrgName(orgCode);
  const groupId = group.entity_id;
  const groupName = group.data.name || '';
  const templateIds = group.data.trip_template_ids || [];

  const handleDispatch = async (tripIds: string[]) => {
    try {
      setIsAssigningDrivers(true);
      const offset = DateService.getTimezoneOffset(timezone);
      const params = {
        trip_ids: tripIds,
        user_timezone: offset,
        company_name: orgName,
      };
      const response = await sendTripRouteAsync(params);
      if (response.status === 200) {
        const queuedDispatch: AsyncDispatchNotificationResponse = {
          ...response.data.data,
          message: `${groupName} trips completed processing`
        };
        setConfirmDispatchOpen(false);
        infoToast(`${groupName} trips queued for dispatching.`);
        dispatch(setMultiTripDispatch(queuedDispatch));
      }
    } catch (error) {
      setIsAssigningDrivers(false);
    }
  };
  const onDispatchGroup = () => {
    const tripIds = getTripIdsForDispatch(groupTrips);
    handleDispatch(tripIds);
  };
  const onDispatchTrip = (id: string) => {
    handleDispatch([id]);
  };

  useEffect(() => {
    if (tableRef.current) {
      const tableWidth = columns.reduce((sum, col) => sum + col.width, 0)
      tableRef.current.style.width = `${tableWidth}px`
    }
  }, [columns])

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h4>
          <Button
            variant="link"
            onClick={() => onGroupExpand(group.entity_id)}
            aria-label={expandedGroupRows.includes(group.entity_id) ? "Collapse section" : "Expand section"}
          >
            {expandedGroupRows.includes(group.entity_id) ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
          </Button>
          {group.data.name || ''}
        </h4>
        {!isCreatingTrip && (
          <TemplatesMenu
            groupId={groupId}
            templateIds={templateIds}
            noteTemplates={noteTemplates}
            setConfirmDispatchOpen={setConfirmDispatchOpen}
            setIsCreatingTrip={setIsCreatingTrip}
          />
        )}
        {isCreatingTrip && <Spinner animation="border" variant="primary" size="sm" />}
      </div>
      {expandedGroupRows.includes(group.entity_id) && (
        <div className="table-responsive">
          <Table ref={tableRef} bordered hover style={{ tableLayout: 'fixed' }}>
            <colgroup>
              {columns.map((column) => (
                <col key={column.key} style={{ width: column.width }} />
              ))}
            </colgroup>
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th
                    key={column.key}
                    style={{ 
                      position: 'relative',
                      padding: '0.75rem'
                    }}
                  >
                    {!['expand', 'actions'].includes(column.key)
                      && column.name.charAt(0).toUpperCase()
                      + column.name.slice(1)}
                    {column.resizable && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          bottom: 0,
                          width: '5px',
                          cursor: 'col-resize',
                        }}
                        onMouseDown={startResizing(index)}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {groupTrips.map((trip) => (
                <TripRow
                  key={trip.id}
                  trip={trip}
                  boardDate={boardDate}
                  isDateRange={isDateRange}
                  onUpdate={
                    (taskId, key, value, subtaskId) => onUpdateTask(group.entity_id, taskId, key, value, subtaskId)
                  }
                  onToggleExpand={onTripExpand}
                  onMoveExpand={onMoveExpand}
                  isMovesExpanded={expandedMoveRows.includes(trip.id)}
                  isShipmentsExpanded={expandedShipmentRows.includes(trip.id)}
                  columns={columns}
                  onDispatch={onDispatchTrip}
                />
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {confirmDispatchOpen && (
        <ConfirmModal
          title={`Confirm Dispatch ${getTripIdsForDispatch(groupTrips).length} Trips`}
          details={getDispatchMessage(getTripIdsForDispatch(groupTrips).length)}
          btnTitle="Dispatch"
          saveDisabled={getTripIdsForDispatch(groupTrips).length === 0 || isAssigningDrivers}
          shouldShowModal={confirmDispatchOpen}
          isSaving={isAssigningDrivers}
          handleSave={onDispatchGroup}
          cancelSave={() => setConfirmDispatchOpen(false)}
        />
      )}
    </div>
  )
}

export default SectionComponent;