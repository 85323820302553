/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-plusplus */
/* eslint-disable  no-restricted-syntax */
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
// import Analytics from '../utils/analytics';
import { getPermissions, setPermissions } from '../config';
import { getUserOrganisations } from '../redux/slices/settings';
import useAuth from '../hooks/useAuth';
import useAppDispatch from '../hooks/useAppDispatch';
import { fetchUsers } from '../redux/slices/organization/organisation';

export const UserPermissionsContext = React.createContext([]);

export function UserPermissionsProvider({ children }) {
  const { orgCode } = useParams();
  const { isAuthenticated, isInitialized } = useAuth();
  const dispatch = useAppDispatch();
  const [permissions, setUserPermissions] = React.useState([]);

  useEffect(() => {
    const handleLoad = async () => {
      try {
        const localPermissions = getPermissions();
        if (localPermissions.length) setUserPermissions(localPermissions);
        const response = await getUserOrganisations();
        if (response.status === 200) {
          const fetchedPermissions = response.data.data.data.permissions || [];
          const isPermissionsEqual = isEqual(localPermissions, fetchedPermissions);
          if (!isPermissionsEqual) {
            await setPermissions(fetchedPermissions);
            setUserPermissions(fetchedPermissions);
          }
          const userPermissions = fetchedPermissions.filter((permission) => permission.includes(`${orgCode}:user`));
          if (userPermissions.length > 0) {
            // dispatch fetch users
            const orgs = response.data.data.data.organizations || [];
            const currentOrg = orgs.find((org) => org.data.organization_code === orgCode);
            if (currentOrg) {
              dispatch(fetchUsers(currentOrg.entity_id));
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (isAuthenticated && isInitialized) handleLoad();
  }, [isAuthenticated, isInitialized, orgCode, dispatch]);

  const providerValue = useMemo(
    () => [...permissions],
    [permissions],
  );
  return (
    <UserPermissionsContext.Provider value={providerValue}>
      {children}
    </UserPermissionsContext.Provider>
  );
};