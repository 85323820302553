/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment-timezone';
import { AxiosResponse } from 'axios';
import { EntityContainer } from '../../redux/models/core.models';

interface DataCache {
  timestamp: number;
  expiry: number;
  response: AxiosResponse<any, any>;
}

export default class ResponseCache {
  cache: EntityContainer<DataCache> = {};

  add(key: string, response: AxiosResponse<any, any>) {
    const now = moment().unix();
    this.cache[key] = {
      timestamp: now,
      expiry: now + 28800,
      response,
    };
    console.log('add item to cache: ', key);
    console.log(response);
  }

  get(key: string) {
    return this.cache[key].response;
  }

  exists(key: string) {
    const now = moment().unix();
    const file = this.cache[key];
    if (file) {
      return now < file.expiry;
    }
    return file !== undefined && file !== null;
  }
}