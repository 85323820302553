import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Descendant } from 'slate';
import { toast } from 'react-toastify';
import { Button, Card, Spinner, Stack } from 'react-bootstrap';
import { getDispatchTemplate, getSettingByName, updateDispatchTemplate } from '../../../redux/slices/settings';
import { NoteTemplate } from '../../../redux/models/settings.models';
import MoveNotes from '../../../components/text/MoveNotes';
import {
  getMentions, selectIsMentionsLoaded, selectIsMentionsLoading, selectMentions
} from '../../../redux/slices/mentions';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { defaultDispatchTemplate } from '../../../components/text/MoveNotes.utils';
import { SETTINGS_NAMES } from '../../../constants/core.constants';

export default function DispatchNotesTemplateEditor() {
  const dispatch = useAppDispatch();
  const { entityId } = useParams();
  const navigate = useNavigate();
  const mentions = useSelector(selectMentions);
  const isMentionLoaded = useSelector(selectIsMentionsLoaded);
  const isMentionsLoading = useSelector(selectIsMentionsLoading);
  const [template, setTemplate] = useState<NoteTemplate | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const notes = template?.data.notes || defaultDispatchTemplate;
  const characters = mentions.map((men) => men.data.character);
  const handleChange = (val: Descendant[]) => {
    if (!template) return;
    setTemplate({
      ...template,
      data: {
        ...template.data,
        notes: val,
      },
    });
  }
  const handleClose = () => navigate(-1);
  const handleSave = async () => {
    if (!template) return;
    try {
      setIsSaving(true);
      const response = await updateDispatchTemplate(template);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.NOTE_TEMPLATES));
        handleClose();
      }
    } catch (error) {
      toast(`Couldn't save ${template?.data.name || 'dispatch template'}`, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  useEffect(() => {
    const handleLoad = async (id: string) => {
      const response = await getDispatchTemplate(id);
      if (response.status === 200) setTemplate(response.data.data);
      setIsLoading(false);
    }
    if (entityId) handleLoad(entityId);
  }, [entityId]);
  useEffect(() => {
    if (!isMentionLoaded && !isMentionsLoading) {
      dispatch(getMentions());
    }
  }, [isMentionLoaded, isMentionsLoading, dispatch]);
  if (isLoading) return <Spinner animation="border" variant="light" />;
  return (
    <Card>
      <Card.Header as="h4">{template?.data.name || ''}</Card.Header>
      <Card.Body>
        <MoveNotes characters={characters} notes={notes} handleChange={handleChange} />
      </Card.Body>
      <Card.Footer>
        <Stack direction="horizontal" gap={3} className="float-end justify-content-end">
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSave}>Save
            {isSaving && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                style={{ marginLeft: '4px' }}
              />
            )}
          </Button>
        </Stack>
      </Card.Footer>
    </Card>
  );
}
