import React from 'react';
import { Badge } from 'react-bootstrap';

export function ExperimentalBadge() {
  return <Badge bg="secondary">Experimental</Badge>;
}

export default function BetaBadge() {
  return <Badge bg="secondary">Beta</Badge>;
}
