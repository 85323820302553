import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import AsyncButton from '../shared/buttons/AsyncButton';

const CANCEL = 'Cancel';

interface Props {
  title: string;
  btnTitle: string;
  saveDisabled: boolean;
  children: React.ReactNode;
  shouldShowModal: boolean;
  isSaving: boolean;
  variant?: string;
  spinner?: string;
  dataCy?: string;
  handleSave: () => void;
  cancelSave: () => void;
}

function AddModal({
  title,
  btnTitle,
  saveDisabled,
  shouldShowModal,
  isSaving,
  variant,
  spinner,
  children,
  dataCy,
  handleSave,
  cancelSave,
}: Props) {
  return (
    <Modal
      show={shouldShowModal}
      onHide={cancelSave}
      centered
      data-cy="add_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button id="add_modal__cancel_btn" variant="secondary" onClick={cancelSave}>
          {CANCEL}
        </Button>
        <AsyncButton
          title={btnTitle}
          id="add_template_modal_btn"
          dataCy={dataCy}
          variant={variant}
          spinner={spinner}
          disabled={isSaving || saveDisabled}
          handleClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
}

AddModal.defaultProps = {
  dataCy: 'add_template_modal_btn',
  variant: 'primary',
  spinner: 'light',
};

export default AddModal;
