import { faChevronDown, faChevronUp, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useState } from 'react';
import { Button, Dropdown, Row, Stack } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SearchFilters from '../../components/modals/SearchFilter/SearchFilter';
import { getFilterOptions } from '../../components/modals/SearchFilter/searchFilter.utils';

import { FILTER_TEXT } from '../../components/modals/SearchFilter/searchFilters.constants';

import {
  filterInvoiceDates,
  selectInvoiceFilterParams,
  setFilterParams,
} from '../../redux/slices/invoices';

import { INVOICE_STATUS_TYPES } from './constants/invoices.constants';
import { DateService } from '../../utils/dateService';
import useAppDispatch from '../../hooks/useAppDispatch';
import { InvoiceListView, defaultFilters } from '../../redux/models/invoice.models';
import { DropdownOptionsModel } from '../../components/shared/SelectInput.models';
import ButtonSpinner from '../../components/shared/ButtonSpinner';
import { InvoiceDownloadType } from '../../redux/slices/invoices.utils';

interface Props {
  title: string;
  invoices: InvoiceListView[];
  contacts: DropdownOptionsModel[];
  isDownloading: boolean;
  onDownload: (type: InvoiceDownloadType) => void;
}

export default function Header({ title, invoices, contacts, isDownloading, onDownload }: Props) {
  const dispatch = useAppDispatch();
  const filterParams = useSelector(selectInvoiceFilterParams);
  const statusOptions = getFilterOptions(INVOICE_STATUS_TYPES);
  const [shouldShowFilters, setShouldShowFilters] = useState(false);
  const handleDateRangeChange = (startDate: Date, endDate: Date, dateFrom: Date, dateTo: Date) => {
    dispatch(filterInvoiceDates({
      ...filterParams,
      dateFrom: startDate && DateService.getISOString(dateFrom),
      dateTo: endDate && DateService.getISOString(dateTo),
      dateRange: `${DateService.getYYYYMMDDFormat(dateFrom)} to ${DateService.getYYYYMMDDFormat(dateTo)}`,
      page: 0,
      total: 0,
    }));
  }

  return (
    <Row className="mb-2">
      <Stack direction="horizontal" gap={3}>
        <h3 style={{ width: '50%' }}>{title}</h3>
        <div className="search-filter">
          <Button
            variant="outline-secondary"
            className="filter-datepicker"
            data-cy="Invoices_filter_button"
            onClick={() => setShouldShowFilters(!shouldShowFilters)}
          >
            <Stack gap={1} direction="horizontal">
              <FontAwesomeIcon
                height={10}
                width={10}
                icon={faFilter}
              />
              {' '}
              {FILTER_TEXT}
              <FontAwesomeIcon
                height={10}
                width={10}
                icon={shouldShowFilters ? faChevronUp : faChevronDown}
              />
            </Stack>
          </Button>
          <SearchFilters
            statusOptions={statusOptions}
            shouldShowFilters={shouldShowFilters}
            setFilterParams={setFilterParams}
            setShouldShowFilters={setShouldShowFilters}
            filterParams={filterParams}
            formattedItem={invoices}
            page="Invoices"
            defaultFilters={defaultFilters}
            customerOptions={contacts}
            setDateRangeChange={handleDateRangeChange}
          />
        </div>
        <Dropdown
          onSelect={() => onDownload(InvoiceDownloadType.Sage)}
          data-cy="invoice_download_btn"
          className="md-2"
        >
          <Dropdown.Toggle
            data-cy="invoice_download_btn_toggle"
            className="filter-datepicker"
            variant="outline-secondary"
          >
            {isDownloading ? <ButtonSpinner /> : 'Download'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item data-cy="invoice_download_btn_qb" onClick={() => onDownload(InvoiceDownloadType.Quickbooks)}>
              Quickbooks Template
            </Dropdown.Item>
            <Dropdown.Item data-cy="invoice_download_btn_sage" onClick={() => onDownload(InvoiceDownloadType.Sage)}>
              Sage Template
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Stack>
    </Row>
  );
}
