import React from 'react';
import { Col } from 'react-bootstrap';

import { FormFieldConfig } from '../../../../../redux/models/shipment.models';
import { DropdownOptionsModel } from '../../../../../components/shared/SelectInput.models';
import { 
  ControlInput, SelectInput
  // InputSwitch,  
} from '../../../../../components/shared/Input';
import { ElementType } from '../../../../../redux/models/network.models';
import { getSelectedOptions } from '../../../../../utils/core.utils';
import { getName, isFieldError } from './shipment.form.utils';
import { EntityContainer } from '../../../../../redux/models/core.models';
import HopperSearchInput from '../../../../../components/shared/inputs/HopperSearchInput';
import { getShipmentFieldType } from '../../../../../redux/slices/shipments.utils';
import { DateService } from '../../../../../utils/dateService';

const PAGE = 'SHIPMENT_DETAILS';

export function ShipmentErrorLabel({ field }: { field: FormFieldConfig }) {
  return (
    <p className="text-danger">{`${field.name} must be filled out`}</p>
  )
}

interface SelectProps {
  field: FormFieldConfig;
  value: string;
  options: DropdownOptionsModel[],
  disabled: boolean,
  handleChange: (prop: string, value: string) => void
}

function SelectField({ field, value, options, disabled, handleChange }: SelectProps) {
  const name = getName(field);
  return (
    <SelectInput
      page={PAGE}
      dataCy={`shipment_${field.key}`}
      labelText={name}
      name={field.key}
      value={value || ''}
      options={options}
      handleChange={handleChange}
      disabled={disabled}
    />
  )
}

interface RelProps {
  field: FormFieldConfig;
  value: string;
  options: DropdownOptionsModel[],
  disabled: boolean,
  entity: string;
  dataField: string;
  handleChange: (prop: string, value: string) => void
}

function RelField({ field, value, options, entity, dataField, disabled, handleChange }: RelProps) {
  const name = getName(field);
  return (
    <HopperSearchInput
      page={PAGE}
      labelText={name}
      name={field.key}
      value={value}
      emptyStateOptions={options}
      options={getSelectedOptions(value, options)}
      settingName={entity}
      dataField={dataField}
      handleChange={handleChange}
      dataCy={`shipment_${field.key}`}
      disabled={disabled}
    />
  )
}

interface ControlProps {
  field: FormFieldConfig;
  value: string;
  disabled: boolean;
  handleChange: (prop: string, value: string) => void;
}

const formatDateTimeLocal = (datetime: string) => {
  return DateService.getMomentDate(datetime).format('YYYY-MM-DDTHH:mm');
}

function ControlField({ field, value, disabled, handleChange }: ControlProps) {
  const isDateField = field.type === ElementType.DateTime;
  const type = isDateField ? 'datetime-local' : field.type;
  const name = getName(field);
  const formattedValue = isDateField ? formatDateTimeLocal(value) : value;
  const onChange = (prop: string, val: string) => {
    if (isDateField) {
      const date = DateService.getISOString(val, true);
      const utc = DateService.getUtcISOString(date);
      handleChange(prop, utc);
    } else {
      handleChange(prop, val);
    }
  }
  return (
    <ControlInput
      page={PAGE}
      type={type}
      dataCy={`shipment_${field.key}`}
      labelText={name}
      name={field.key}
      value={formattedValue || ''}
      handleChange={onChange}
      disabled={disabled}
    />
  )
}

interface InputProps {
  field: FormFieldConfig;
  value: string;
  options?: DropdownOptionsModel[],
  entity?: string;
  disabled: boolean,
  size?: number;
  validation: EntityContainer<boolean>;
  dataField?: string;
  handleChange: (prop: string, value: string) => void
}

function ShipmentField({
  field, value, size, entity, options, dataField, disabled, validation, handleChange
}: InputProps) {
  if (!field) return null;
  if (!field.visible) return null;
  const type = getShipmentFieldType(field.key || '');
  const isError = isFieldError(field, validation);
  return (
    <Col md={size}>
      {type === ElementType.Select && (
        <SelectField
          field={field}
          value={value}
          options={options || []}
          disabled={disabled}
          handleChange={handleChange}
        />
      )}
      {type === ElementType.Rel && (
        <RelField
          field={field}
          value={value}
          entity={entity || ''}
          options={options || []}
          disabled={disabled}
          dataField={dataField || 'samsara_name'}
          handleChange={handleChange}
        />
      )}
      {type === ElementType.String && (
        <ControlField field={field} value={value} disabled={disabled} handleChange={handleChange} />
      )}
      {type === ElementType.DateTime && (
        <ControlField field={field} value={value} disabled={disabled} handleChange={handleChange} />
      )}
      {type === ElementType.Number && (
        <ControlField field={field} value={value} disabled={disabled} handleChange={handleChange} />
      )}
      {isError && (
        <ShipmentErrorLabel field={field} />
      )}
    </Col>
  )
}

ShipmentField.defaultProps = {
  options: [],
  entity: '',
  size: 3,
  dataField: 'samsara_name',
};

export default ShipmentField;