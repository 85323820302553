import { Table } from 'react-bootstrap';
import { INVOICE_TABLE_HEADERS } from '../constants/invoicesTable.constants';
import InvoiceTableRow from './InvoiceTableRow';

export default function InvoiceTableComponent({
  headers = INVOICE_TABLE_HEADERS,
  formattedInvoices,
  openInvoicePage,
}) {
  return (
    formattedInvoices.length && (
      <Table
        striped
        bordered
        hover
        className="invoices-table"
        data-cy="invoices-table"
      >
        <thead>
          <tr>
            {headers.slice(0).map((header) => (
              <th key={header.key}>
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {formattedInvoices.map((invoice, index) => (
            <tr
              key={invoice.id}
              id={`invoice-invoiceTable__tableRow-${index}`}
              className="invoices-table-row"
              data-cy="invoices-table-row"
              onClick={() => openInvoicePage(invoice.id)}
            >
              {headers.slice(0).map((header) => (
                <td className="capitalized" key={`${invoice.id}-${header.key}`}>
                  <InvoiceTableRow invoice={invoice} prop={header.key} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  );
}
