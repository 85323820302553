/* eslint-disable import/no-cycle */
import Analytics from "../../utils/analytics";
import { DataModel } from "./core.models";

export interface MentionData {
  array_position: {
    end: number | null;
    start: number | null;
  };
  character: string;
  child: {
    prop: string;
    prop_type: string;
  };
  prop: string;
  prop_type: string;
  rel_entity_type: string;
  target_entity: string;
}

export type Mention = DataModel<MentionData>;

export interface MentionState {
  isLoaded: boolean;
  isLoading: boolean;
  mentions: Mention[];
};

const initialState: MentionState = {
  isLoaded: false,
  isLoading: false,
  mentions: [],
};

export const getInitialState = (): MentionState => ({
  ...initialState
});

const getErrorPlaceholder = (verb: string) =>
  `Couldn't ${verb} invoices. Please contact support if the problem persists`;

export const getError = (
  e: unknown,
  details = "Couldn't get invoices",
  verb = 'get'
): React.ReactText => {
  let em;
  if (e instanceof Error) {
    em = e.message;
    Analytics.capture(em);
  }
  const errorMessage = em ?
    `${details}. ${em}. Please contact support if the problem persists.`
    : getErrorPlaceholder(verb);
  return errorMessage;
};