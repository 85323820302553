import { DateService } from "./dateService"

export enum Timeliness {
  ExcessivelyEarly = 'Excessively Early',
  Early = 'Early',
  OnTime = 'On Time',
  Late = 'Late',
  ExcessivelyLate = 'Excessively Late',
}

const { ExcessivelyEarly, Early, OnTime, Late, ExcessivelyLate } = Timeliness;

export const getColour = (state: Timeliness) => {
  if (state === ExcessivelyEarly) return "primary";
  if (state === Early) return "info";
  if (state === OnTime) return "success";
  if (state === Late) return "warning";
  if (state === ExcessivelyLate) return "danger";
  return "";
};

export const getTimeliness = (scheduled?: string | null, actual?: string | null) => {
  if (!scheduled || !actual) return null;
  const minutes = DateService.getDifferenceInMinutes(actual, scheduled);
  if (minutes > 45) return ExcessivelyLate;
  if (minutes > 10) return Late;
  if (minutes >= -30 && minutes <= 10) return OnTime;
  if (minutes >= -45) return Early;
  return ExcessivelyEarly;
};
