/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { getSettingByName } from '../../../../redux/slices/settings';
import DeleteModal from '../../../modals/DeleteModal';
import { DataModel } from '../../../../redux/models/core.models';

interface Props<T> {
  entity: DataModel<T>;
  settingsKey: string;
  getName: (entity: DataModel<T>) => string;
  createRoute: (entity: DataModel<T>) => string;
  onDelete: (entity: DataModel<T>) => void;
}

export default function EntityListItem<T>({ entity, settingsKey, getName, createRoute, onDelete }: Props<T>) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const name = getName(entity) || '';
  const route = createRoute(entity);
  const handleDelete = async () => {
    setDeleting(true);
    await onDelete(entity);
    await dispatch(getSettingByName(settingsKey, false)); // SETTINGS_NAMES.CONTACTS
    await setShowDelete(false);
    setDeleting(false);
  };

  const handleEdit = () => {
    console.log('handleEdit: ', route);
    navigate(route); // `/${orgCode}/settings/${route}/${entity.entity_id}`
  };

  return (
    <tr>
      <td>{name}</td>
      <td>
        <div className="float-end justify-content-end">
          <DropdownButton id="dropdown-basic-button" title="Options">
            <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => setShowDelete(true)}>Delete</Dropdown.Item>
          </DropdownButton>
        </div>
      </td>
      <DeleteModal
        title="Confirm Delete"
        description={`Would you like to delete ${name}?`}
        deleteBtnText="Delete"
        isDeleting={isDeleting}
        shouldShowDeleteModal={showDelete}
        deleteItem={handleDelete}
        cancelDeletion={() => setShowDelete(false)}
      />
    </tr>
  );
}
