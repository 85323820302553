import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectUomObject } from '../../../redux/slices/settings';
import { getInvoiceLineItems } from '../constants/invoicesTable.constants';
import { formatLineItems } from '../utils/invoicesTable.utils';

export default function LineItemsTable({
  lineItems,
  shipments,
  page,
  currency,
}) {
  const uomObject = useSelector(selectUomObject);
  const formattedLineItems = formatLineItems(lineItems, shipments, uomObject, currency);
  const headers = getInvoiceLineItems(currency || '');
  return (
    <Table
      striped
      className={`${page}-lineItems-table`}
      data-cy={`${page}_lineItems_table`}
    >
      <thead>
        <tr>
          {headers.map((header) => <th key={header.key}>{header.name}</th>)}
        </tr>
      </thead>
      <tbody>
        {formattedLineItems.map((lineItem, index) => (
          <tr
            key={lineItem.id}
            id={`${page}-lineItem__tableRow-${index}`}
            data-cy={`${page}_lineItem-row`}
          >
            {headers.map((header) => (
              <td className="capitalized" key={`${lineItem.id}-${header.key}`}>
                {lineItem[header.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
