import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Row, Spinner } from 'react-bootstrap';
import { ControlInput, SelectInput } from '../../../../components/shared/Input';

import { selectBillingItems, selectUoms } from '../../../../redux/slices/settings';
import { createDropdownOptions } from '../../../../utils/core.utils';
import { BillingItemData } from '../../../../redux/models/billing.models';

const CANCEL = 'Cancel';

const PARAM_PROPS = {
  description: 'description',
  price: 'price',
  rate_id: 'rate_id',
  uom_id: 'uom_id',
};

const PAGE = 'create_accessorial_input';

interface Props {
  shouldShowModal: boolean;
  isSaving: boolean;
  accessorials: BillingItemData[];
  saveItem: (rate: BillingItemData) => void;
  cancelSave: () => void;
}

export default function AddAccessorialModal({
  accessorials,
  shouldShowModal,
  isSaving,
  saveItem,
  cancelSave,
}: Props) {
  const rates = useSelector(selectBillingItems);
  const uoms = useSelector(selectUoms);
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [rateId, setRate] = useState('');
  const [uomId, setUom] = useState('');
  const selecedIds = accessorials.map((acc) => acc.billing_item_id);
  const availableRates = rates.filter((rate) => !selecedIds.includes((rate.entity_id)) && rate.data.accessorial);
  const rateOptions = createDropdownOptions(availableRates, 'description');
  const uomOptions = createDropdownOptions(uoms);
  const isDisabled = rateId === '';
  const handleChange = (prop: string, value: string) => {
    if (prop === PARAM_PROPS.description) setDescription(value);
    if (prop === PARAM_PROPS.price) setPrice(parseFloat(value));
    if (prop === PARAM_PROPS.uom_id) setUom(value);
    if (prop === PARAM_PROPS.rate_id) {
      setRate(value);
      const billingItem = rates.find((rt) => rt.entity_id === value);
      if (billingItem) {
        setDescription(billingItem.data.description || '');
        setPrice(billingItem.data.price || 0);
        setUom(billingItem.data.uom_id || '');
      }
    }
  };
  const handleResetForm = () => {
    setRate('');
    setPrice(0);
  };
  const handleSave = async () => {
    const billingItem = rates.find((rt) => rt.entity_id === rateId);
    if (!billingItem) return;
    const data = {
      ...billingItem.data,
      billing_item_id: billingItem.entity_id,
      price,
      description,
    };
    await saveItem(data);
    handleResetForm();
  };
  const handleCancel = () => {
    handleResetForm();
    cancelSave();
  }
  return (
    <Modal
      show={shouldShowModal}
      onHide={cancelSave}
      centered
      data-cy="delete_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Accessorial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <SelectInput
            dataCy={`${PAGE}_${PARAM_PROPS.rate_id}`}
            name={PARAM_PROPS.rate_id}
            page={PAGE}
            options={rateOptions}
            value={rateId}
            labelText="Rate"
            handleChange={handleChange}
          />
        </Row>
        {!isDisabled && (
          <Row>
            <ControlInput
              dataCy={`${PAGE}_${PARAM_PROPS.price}`}
              name={PARAM_PROPS.price}
              type="number"
              page={PAGE}
              value={`${price}`}
              labelText="Price"
              handleChange={handleChange}
            />
          </Row>
        )}
        {!isDisabled && (
          <Row>
            <ControlInput
              dataCy={`${PAGE}_${PARAM_PROPS.description}`}
              name={PARAM_PROPS.description}
              type="text"
              page={PAGE}
              value={description}
              labelText="Name"
              handleChange={handleChange}
            />
          </Row>
        )}
        {!isDisabled && (
          <Row>
            <SelectInput
              dataCy={`${PAGE}_${PARAM_PROPS.uom_id}`}
              name={PARAM_PROPS.uom_id}
              page={PAGE}
              options={uomOptions}
              value={uomId}
              labelText="Unit"
              handleChange={handleChange}
            />
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button id="delete_modal__cancel_btn" variant="secondary" onClick={handleCancel}>
          {CANCEL}
        </Button>
        <Button
          id="add_template_modal_btn"
          data-cy="add_template_modal_btn"
          variant="primary"
          disabled={isDisabled}
          onClick={handleSave}
        >
          Add
          {isSaving && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              style={{ marginLeft: '4px' }}
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
