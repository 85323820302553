import { AppNotificationData } from '../../redux/slices/notifications/notifications.models';
import axiosInstance from '../../utils/axios';
import { EntityServiceProvider } from '../base/EntityServiceProvider';
import { ApiQuery, ElementType, QueryOperator, FilterOp, SortOrder } from '../../redux/models/network.models';


class NotificationProvider<T> extends EntityServiceProvider<T> {
  fetchUnreadNotifications(userId: string) {
    const query: ApiQuery = {
      filters: [
        {
          "field": {
            "name": "data.user_id",
            "type": ElementType.String
          },
          "op": FilterOp.Equals,
          "value": userId,
        },
        {
          "field": {
            "name": "data.read",
            "type": ElementType.Bool
          },
          "op": FilterOp.Equals,
          "value": false,
        },
      ],
      operator: QueryOperator.And,
      pagination: {
        page: 0,
        page_size: 1,
      },
    }
    return this.find(query);
  }

  fetchUserNotifications(userId: string) {
    const query: ApiQuery = {
      filters: [
        {
          "field": {
            "name": "data.user_id",
            "type": ElementType.String
          },
          "op": FilterOp.Equals,
          "value": userId,
        },
      ],
      operator: QueryOperator.And,
      pagination: {
        page: 0,
        page_size: 10,
      },
      sorting: [
        {
          field: 'created_at',
          order: SortOrder.Descending,
        }
      ]
    }
    return this.find(query);
  }
}

const NotificationApi = new NotificationProvider<AppNotificationData>('app-notification', axiosInstance);

export default NotificationApi;