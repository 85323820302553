import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ShipmentList from './ShipmentList'
import { selectBoard } from '../../redux/slices/settings';
import { AppState } from '../../redux/models/state.models';
import { BILLING_SHIPMENT_TABLE_HEADERS } from './ShipmentsTable/constants/shipmentTable.constants';

export default function ShipmentBoardContainer() {
  const { boardId } = useParams();
  const board = useSelector((state: AppState) => selectBoard(state, boardId));
  const shipmentTypeId = board?.data?.shipment_type?.entity_id;
  const name = board?.data?.name;
  const cols = board?.data?.table_config?.shipment_table || BILLING_SHIPMENT_TABLE_HEADERS;
  const headers = cols.filter((col) => col.visible);
  return <ShipmentList board={board} name={name} shipmentTypeId={shipmentTypeId} headers={headers} />
};
