/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import DeleteModal from '../../components/modals/DeleteModal';
import { deleteTemplate } from '../../redux/slices/templates';
import useAppDispatch from '../../hooks/useAppDispatch';
import { getSettingByName } from '../../redux/slices/settings';
import { SETTINGS_NAMES } from '../../constants/core.constants';
import { Group, TripTemplate } from '../../redux/models/settings.models';
import { Board } from '../../redux/models/board.models';
import { EntityContainer } from '../../redux/models/core.models';

interface Props {
  template: TripTemplate;
  settings: {
    boards: {
      list: Board[];
    };
    groups: {
      list: Group[];
    };
  };
  onOpen: (template: TripTemplate) => void;
}

export default function TemplateListRow({ template, settings, onOpen }: Props) {
  const dispatch = useAppDispatch();
  const { name } = template.data;
  const { boards, groups } = settings;
  const [items, setItems] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    await deleteTemplate(template.entity_id);
    await dispatch(getSettingByName(SETTINGS_NAMES.TRIP_TEMPLATES));
    await setShowDelete(false);
    setDeleting(false);
  };

  const handleEdit = () => {
    onOpen(template);
  };

  useEffect(() => {
    const boardContainer: EntityContainer<string> = {};
    boards.list.forEach((board: Board) => {
      boardContainer[board.entity_id] = board.data.name || '';
    });
    const container: EntityContainer<any> = {};
    groups.list.forEach((group: Group) => {
      const temps = group.data.trip_template_ids || [];
      temps.forEach((temp) => {
        if (temp === template.entity_id) {
          const groupBoard = group.data.board_id || '';
          container[group.entity_id] = {
            name: group.data.name,
            board: boardContainer[groupBoard] || '',
          };
        }
      });
    });
    const templateGroups = Object.values(container).reduce((prev, next) => {
      const templateGroup = `${next.board} - ${next.name}`;
      if (prev === '') {
        return templateGroup;
      }
      return `${prev}, ${templateGroup}`;
    }, '');
    setItems(templateGroups);
  }, [boards, groups, template]);

  return (
    <tr>
      <td>{name}</td>
      <td>
        {items}
      </td>
      <td>
        <div className="float-end justify-content-end">
          <DropdownButton id="dropdown-basic-button" title="Options">
            <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => setShowDelete(true)}>Delete</Dropdown.Item>
          </DropdownButton>
        </div>
      </td>
      <DeleteModal
        title="Delete Trip Template"
        description={`Would you like to delete ${template?.data?.name || 'trip template'}?`}
        deleteBtnText="Delete"
        isDeleting={isDeleting}
        shouldShowDeleteModal={showDelete}
        deleteItem={handleDelete}
        cancelDeletion={() => setShowDelete(false)}
      />
    </tr>
  );
}
