import { EntityContainer } from "../../../../../redux/models/core.models";
import { FormFieldConfig } from "../../../../../redux/models/shipment.models";

export const getName = (field: FormFieldConfig) => {
  return field.required ? `${field.name}*` : field.name;
}

export const isFieldError = (field: FormFieldConfig, validation: EntityContainer<boolean>) => {
  if (field.required) return validation[field.key] === false;
  return false;
};
