/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Stack } from 'react-bootstrap';

import { EntityContainer } from '../../../../redux/models/core.models';
import { DriverAvailabilityData, DriverTrip } from '../../../../redux/models/driver.availability.models';
import { Driver } from '../../../../redux/models/settings.models';
import SuggestionListItem from './SuggestionListItem';
import { TripDetails } from '../../../../redux/models/trip.models';
import { getDriverTrips } from '../../../../redux/slices/availability/driver.availability';
import BetaBadge from '../../../../components/badge/BetaBadge';
import { getIsConflict } from './suggestion.utils';
import { selectAddressesObject } from '../../../../redux/slices/settings';

interface Props {
  startDate: string;
  endDate: string;
  suggestions: EntityContainer<DriverAvailabilityData>;
  drivers: Driver[];
  openSuggestions: EntityContainer<boolean>; 
  tripDetails: TripDetails;
  setOpenSuggestions: React.Dispatch<React.SetStateAction<EntityContainer<boolean>>>;
}

export default function SuggestionList({
  suggestions,
  drivers,
  startDate,
  endDate,
  openSuggestions,
  setOpenSuggestions,
  tripDetails,
}: Props) {
  const addresses = useSelector(selectAddressesObject);
  const [rosters, setRosters] = useState<EntityContainer<DriverTrip[]>>({});
  const [isLoaded, setLoaded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const initStore: EntityContainer<Driver> = {};
  const driverStore = drivers.reduce((store, driver) => {
    return {
      ...store,
      [driver.entity_id]: driver,
    }
  }, initStore);
  const keyIds = Object.keys(suggestions) || [];
  const taggedKeys = keyIds.filter((driverId) => driverStore[driverId]);
  const shouldShowMore = taggedKeys.length > 3 && isExpanded;
  const expandedKeys = shouldShowMore ? taggedKeys : taggedKeys.slice(0, 3);

  const handleClose = (driverId: string) => {
    setOpenSuggestions({
      ...openSuggestions,
      [driverId]: false,
    });
  };
  useEffect(() => {
    const loadRoster = async (start: string, end: string, driverIds: string[]) => {
      const { data } = await getDriverTrips(start, end, driverIds);
      const items = data.data || [];
      const drivable = items.filter((ros: DriverTrip) => getIsConflict(tripDetails, ros, addresses));
      console.log('all booked trips: ', items.length);
      console.log('drivable booked trips: ', drivable.length);
      const rosterStore = items.reduce((store: EntityContainer<DriverTrip[]>, trip: DriverTrip) => {
        const driverId = trip.data.driver.entity_id || '';
        return {
          ...store,
          [driverId]: store[driverId] ? [store[driverId].push(trip)] : [trip],
        }
      }, {});
      setLoaded(true);
      setRosters(rosterStore);
    }
    if (!isLoaded) loadRoster(startDate, endDate, taggedKeys);
  }, [startDate, endDate, taggedKeys, isLoaded, tripDetails, addresses]);
  return (
    <div>
      <Stack direction='horizontal' gap={2}>
        <h5 className="pt-2">Suggestions</h5>
        <BetaBadge />
      </Stack>
      {
        expandedKeys.map((driverId) => {
          const driverRosters = rosters[driverId] || [];
          return openSuggestions[driverId] && (
            <SuggestionListItem key={driverId} tripDetails={tripDetails} driver={driverStore[driverId]} roster={driverRosters} close={handleClose} />
          )
        })
      }
      {keyIds.length > 3 && (
        <Button type="button" variant="link" onClick={() => setIsExpanded(!isExpanded)}>Show {isExpanded ? 'Less' : 'More'}</Button>
      )}
    </div>
  );
}
