/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiQuery } from '../redux/models/network.models';
import axiosInstance from '../utils/axios';

import { AquadashServiceConfigProvider } from './AquadashServiceConfigProvider';
import { DispatchRouteAsyncProps } from './base/TripServiceProvider.models';

export class TripServiceApiProvider {
  static getBoards() {
    return axiosInstance.get(AquadashServiceConfigProvider.getBoards());
  }

  static getTrips(boardId: string, filters: any) {
    return axiosInstance.get(AquadashServiceConfigProvider.getTrips(boardId, filters));
  }

  static getTripDetails(tripId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getTripDetails(tripId));
  }

  static getTripRoute(routeId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getTripRoute(routeId));
  }

  static getTripDocument(documentId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getTripDocument(documentId));
  }

  static getFleetLocations(destinationId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getFleetLocations(destinationId));
  }

  static createTrip(body: any) {
    return axiosInstance.post(AquadashServiceConfigProvider.createTrip(), body);
  }

  static createTripFromTemplate(templateId: string, date: string) {
    return axiosInstance.post(AquadashServiceConfigProvider.createTripFromTemplate(templateId), {
      driver_id: '',
      date,
    });
  }

  static createMove(body: string) {
    return axiosInstance.post(AquadashServiceConfigProvider.createMove(), body);
  }

  static updateTrip(trip: any) {
    return axiosInstance.put(AquadashServiceConfigProvider.updateTrip(trip.entity_id), trip);
  }

  static updateMoves(moves: any) {
    return axiosInstance.put(AquadashServiceConfigProvider.updateBatchMoves(), moves);
  }

  static sendTripRoute(tripId: string) {
    return axiosInstance.post(AquadashServiceConfigProvider.sendTripRoute(tripId));
  }

  static sendTripRouteAsync(body: DispatchRouteAsyncProps) {
    return axiosInstance.post(AquadashServiceConfigProvider.sendTripRouteAsync(), body);
  }

  static deleteTrip(tripId: string) {
    return axiosInstance.delete(AquadashServiceConfigProvider.deleteTrip(tripId));
  }

  static addShipmentsToTrip(tripId: string, tripDetails: any) {
    return axiosInstance.put(AquadashServiceConfigProvider.addShipmentsToTrip(tripId), tripDetails);
  }

  static findTrips(query: ApiQuery) {
    return axiosInstance.post('/trip/find', query);
  }

  static findMoves(query: ApiQuery) {
    return axiosInstance.post('/move/find', query);
  }

  static dispatchTrip(tripId: string) {
    return axiosInstance({
      method: 'POST',
      url: AquadashServiceConfigProvider.dispatchTrip(tripId),
      validateStatus: () => true,
    });
  }

  static updateDriver(driverId: string, tripId: string, version: string) {
    return axiosInstance({
      method: 'PUT',
      url: AquadashServiceConfigProvider.updateTripDriver(tripId),
      validateStatus: () => true,
      data: {
        driver_id: driverId,
        version,
      },
    });
  }
}
