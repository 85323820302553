import { BillingItemData } from "../../../redux/models/billing.models";

export const CURRENCY_OPTIONS = [
  {
    value: 'cad',
    label: 'CAD',
  },
  {
    value: 'usd',
    label: 'USD',
  },
];

export const initialData: BillingItemData = {
  accessorial: false,
  billing_item_id: '',
  currency: '',
  department_code: '',
  department_code_id: '',
  department_name: '',
  description: '',
  gl_code: '',
  gl_code_id: '',
  gl_name: '',
  invoice_id: '',
  item_code: '',
  price: 0,
  quantity: 0,
  tax: null,
  uom: null,
};
