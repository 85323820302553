/* eslint-disable @typescript-eslint/no-explicit-any */
import { Board } from '../redux/models/board.models';
import { NoteTemplate, NoteTemplateData } from '../redux/models/settings.models';
import axiosInstance from '../utils/axios';
import axiosHopperInstance from '../utils/hopperAxios';

import { AquadashServiceConfigProvider } from './AquadashServiceConfigProvider';
import HopperServiceConfigProvider from './HopperServiceConfigProvider';

export class SettingsApi {
  static saveBoard(board: Board) {
    return axiosInstance.put(AquadashServiceConfigProvider.saveBoard(board.entity_id), board);
  }

  static getUserOrgs() {
    return axiosHopperInstance({
      method: 'GET',
      url: HopperServiceConfigProvider.getUserData(),
      validateStatus: () => true,
    });
  }

  static getUsers(orgId: string) {
    return axiosHopperInstance({
      method: 'GET',
      url: HopperServiceConfigProvider.getUsers(orgId),
      validateStatus: () => true,
    });
  }
  
  static getRoles(organizationCode: string) {
    return axiosHopperInstance({
      method: 'GET',
      url: HopperServiceConfigProvider.getRoles(organizationCode),
      validateStatus: () => true,
    });
  }

  static createDispatchTemplate(data: NoteTemplateData) {
    return axiosInstance.post(AquadashServiceConfigProvider.createDispatchTemplate(), data);
  }

  static updateDispatchTemplate(template: NoteTemplate) {
    return axiosInstance.put(AquadashServiceConfigProvider.dispatchTemplateEntity(template.entity_id), template);
  }

  static getDispatchTemplate(entityId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.dispatchTemplateEntity(entityId));
  }

  static deleteDispatchTemplate(template: NoteTemplate) {
    return axiosInstance.delete(AquadashServiceConfigProvider.dispatchTemplateEntity(template.entity_id));
  }

  static getFeatureFlags() {
    const appId = process.env.REACT_APP_AQD_APP_ID || '';
    return axiosHopperInstance({
      method: 'GET',
      url: `/feature-flag/app/${appId}`,
      validateStatus: () => true,
    });
  }
}