import axios from 'axios';

const key = process.env.REACT_APP_GOOGLE_MAPS_KEY || '';

const googleMapsAxios = axios.create({
  baseURL: 'https://places.googleapis.com/v1/',
});

googleMapsAxios.interceptors.request.use(
  async (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': `${key}`,
      'X-Goog-FieldMask': '*'
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

googleMapsAxios.interceptors.response.use((response) => response, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 0 && !originalRequest.retry) {
    originalRequest.retry = true;
    return googleMapsAxios(originalRequest);
  }
  return Promise.reject((error.response && error.response.data) || 'Something went wrong');
});

export default googleMapsAxios;
