import React from 'react';
import { useSelector } from 'react-redux';
import {
  Bell,
  // Home,
  // UserPlus,
} from "react-feather";
import NavbarDropdown from './NavbarDropdown';
import {
  selectDispatchNotifications
} from '../../redux/slices/notifications/notifications';
import NavbarDispatchNotificationItem from './NavbarDispatchNotificationItem';

export default function NavbarDispatchNotifications() {
  const notifications = useSelector(selectDispatchNotifications);
  const hanleOpen = (isOpen: boolean) => {
    console.log('open: ', isOpen);
  };
  return (
    <NavbarDropdown
      header="Dispatches"
      footer=""
      icon={Bell}
      count={notifications.length}
      showBadge={false}
      handleOpen={hanleOpen}
    >
      {notifications.map((item, key) => {
        return (
          <NavbarDispatchNotificationItem
            key={key}
            notification={item}
          />
        );
      })}
    </NavbarDropdown>
  );
}
