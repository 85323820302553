/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Network Errors:
 * - 400: Bad request
 * - 403: Permissions error
 * - 500: Server error
 */
export interface NetworkError {
  title: string;
  message: string;
  code: number;
}

export const NotFound: NetworkError = {
  code: 404,
  title: 'Page not found',
  message: 'Sorry, we couldn’t find the page you’re looking for.',
};

export const PermissionsError: NetworkError = {
  code: 403,
  title: 'Permissions Error',
  message: "You don't have permission to access this item. Please contact your admin to change your permission levels.",
};

export const ServerError: NetworkError = {
  code: 500,
  title: 'Well, this is unexpected...',
  message: "An error has occured and we're working to fix the problem! We'll be up and running shortly.",
};

export interface QueryFilterField {
  id?: string;
  name: string;
  type: string;
}

export enum ElementType {
  Bool = 'bool',
  DateTime = 'datetime',
  Number = 'number',
  Rel = 'rel',
  Select = 'select',
  String = 'string',
}

export enum FilterOp {
  Equals = 'eq',
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'gte',
  In = 'in',
  InArray = 'inarray',
  LessThan = 'lt',
  LessThanOrEqual = 'lte',
  NotEquals = 'ne',
  Regex = 'regex',
}

export enum QueryOperator {
  And = 'AND',
  Or = 'OR',
}

export interface QueryFilter {
  field: QueryFilterField;
  op: string;
  value: any;
}

export interface ApiPagination {
  page: number;
  page_size: number;
}

export enum SortOrder {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export interface ApiSorting {
  field: string;
  order: SortOrder;
}

export interface ApiQuery {
  filters: QueryFilter[];
  operator: string;
  pagination?: ApiPagination;
  sorting?: ApiSorting[];
}

export const DEFUALT_CREATED_AT_SORTING: ApiSorting[] = [
  {
    field: 'created_at',
    order: SortOrder.Descending,
  }
];

export const DEFAULT_API_QUERY: ApiQuery = {
  filters: [],
  operator: QueryOperator.And,
  pagination: {
    page: 0,
    page_size: 25,
  },
  sorting: DEFUALT_CREATED_AT_SORTING,
};

export const getDataSorting = (prop: string, ascending = true) => {
  return [
    {
      field: `data.${prop}`,
      order: ascending ? SortOrder.Ascending : SortOrder.Descending,
    }
  ];
};
