/* eslint-disable @typescript-eslint/no-explicit-any */
import { getNotesTemplate } from '../../../components/text/MoveNotes.utils';
import { Fleet, Tag, Vehicle } from '../../../redux/models/settings.models';
import { Move, Trip } from '../../../redux/models/trip.models';
import { filterTags } from '../../../redux/slices/settings.utils';
import { DateService } from '../../../utils/dateService';

interface NoteChild {
  text: string;
}

// { children: [{ text: "" }], type: "paragraph" }
export interface DispatchNote {
  children: NoteChild[];
  type: string;
}

export const isChildNoteEmpty = (note: DispatchNote) => {
  const childNotes = note.children || [];
  const childLen = childNotes.length;
  if (childLen === 0) return true;
  const emptyNotes = childNotes.filter((child) => child.text === '');
  return emptyNotes.length === childLen;
};

export const isDispatchNotesInvalid = (notes: DispatchNote[]) => {
  const noteLength = notes.length;
  if (noteLength === 0) return true;
  if (noteLength === 1) {
    const firstNote = notes[0];
    return isChildNoteEmpty(firstNote);
  }
  /**
   * User has tapped enter a bunch of times for the following case. We consider this 'not empty' and
   * won't be overwritten with default notes. 
   * 
   * Note: This has potential for user error if they're trying to clear the text field 
   * in order to use default notes but have accidentally left some trailing line break that they can't see
   */
  // const emptyNotes = notes.filter((note: any) => isNoteEmpty(note));
  // return emptyNotes.length === noteLength;
  return false;
}

export const isMoveMissingNotes = (move: Move) => {
  const moveNotes = move.data.dispatch_notes;
  if (moveNotes === undefined || moveNotes === null) {
    return true;
  }
  const notes: any = moveNotes || [];
  return isDispatchNotesInvalid(notes);
}

export const isTripMoveMissingNotes = (trip: Trip) => {
  const moves = trip.data.moves || [];
  const missingNote = moves.some((move) => isMoveMissingNotes(move));
  return missingNote;
}

export const addDefaultDispatch = (notesTemplates: Array<any>, trip: Trip) => {
  if (notesTemplates.length === 0) {
    return trip;
  }
  const defaultNotes = notesTemplates[0].data.notes || [];
  if (defaultNotes.length === 0) {
    return trip;
  }
  return {
    ...trip,
    data: {
      ...trip.data,
      moves: trip.data.moves.map((move) => {
        const isNoteEmpty = isMoveMissingNotes(move);
        if (isNoteEmpty) {
          return {
            ...move,
            data: {
              ...move.data,
              dispatch_notes: defaultNotes,
            },
          };
        }
        return move;
      }),
    },
  };
};

export const handleMoveItemDateChange =
(movePosition: number, sortedMoves: Move[]) => (prop: string, value: string) => {
  const updatedMovesData = sortedMoves.map((move) => {
    if (move.data.position === movePosition) {
      const arrivalUtc = DateService.getUtcISOString(value);
      const departure = DateService.addMinsToDateISO(arrivalUtc, 15, true);
      const departureUtc = DateService.getUtcISOString(departure);
      if (prop === 'scheduled_arrival_time') {
        return {
          ...move,
          data: {
            ...move.data,
            arrivalUtc,
            scheduled_arrival_time: arrivalUtc,
            scheduled_departure_time: departureUtc,
          },
        };
      }
      return {
        ...move,
        data: {
          ...move.data,
          [prop]: arrivalUtc,
        },
      };
    }
    return move;
  });
  // dispatch(updateTripDetailsMoves(updatedMovesData));
  return updatedMovesData;
};

export const handleMoveDestinationChange =
(movePosition: number, sortedMoves: Move[]) => (prop: string, value: string) => {
  return sortedMoves.map((move) => {
    if (move.data.position === movePosition) {
      if (prop === 'destination_id') {
        return {
          ...move,
          data: {
            ...move.data,
            destination_id: value,
            origin_id: value,
          },
        };
      }
      return {
        ...move,
        data: {
          ...move.data,
          [prop]: value,
        },
      };;
    }
    return move;
  });
};

export const handleDeleteMove = (position: number, sortedMoves: Move[]) => {
  return sortedMoves
    .filter((move) => move.data?.position !== position)
    .map((move, idx) => ({
      ...move,
      data: {
        ...move.data,
        position: idx,
      },
    }));
};

export const handleMoveItemChange = (movePosition: number, sortedMoves: Move[]) => (prop: string, value: string) => {
  return sortedMoves.map((move) => {
    if (move.data.position === movePosition) {
      if (prop === 'notes') {
        return {
          ...move,
          data: {
            ...move.data,
            dispatch_notes: getNotesTemplate(value),
          },
        };
      }
      if (prop === 'trailer_id') {
        return {
          ...move,
          data: {
            ...move.data,
            trailer_ids: [value],
            trailer_id: value,
          },
        };
      }
      return {
        ...move,
        data: {
          ...move.data,
          [prop]: value,
        },
      };;
    }
    return move;
  });
};

export const getBoardVehicles = (vehicles: Vehicle[], tags: Tag[]) => {
  const tagData = tags || [];
  if (tagData.length === 0) return vehicles;
  return vehicles.filter((vh) => filterTags(vh.data.tag_ids, tagData));
};

export const getBoardFleet = (fleet: Fleet[], tags: any) => {
  const tagData = tags || [];
  if (tagData.length === 0) return fleet;
  return fleet.filter((fl) => filterTags(fl.data.tag_ids, tagData));
};
