/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Col, Row,
} from 'react-bootstrap';

import {
  ControlInput, InputSwitch, ControlTextArea,
} from '../../../../../components/shared/Input';

import QuantityForm from './QuantityForm';
import WeightForm from './WeightForm';

import { createDropdownOptions } from '../../../../../utils/core.utils';
import ShipmentField, { ShipmentErrorLabel } from './ShipmentField';
import { Shipment, ShipmentTypeFieldData } from '../../../../../redux/models/shipment.models';
import { DropdownOptionsModel } from '../../../../../components/shared/SelectInput.models';
import { EntityContainer } from '../../../../../redux/models/core.models';
import { getName, isFieldError } from './shipment.form.utils';
import { ReeferSetting, reeferSettingOptions } from '../../../constants/shipments.constants';
import { getReeferSettingValue } from '../../../utils/shipments.utils';

const FORM_TITLE = 'Product';

interface Props {
  shipmentDetails: Shipment;
  fields: ShipmentTypeFieldData;
  uomSelectOptions: DropdownOptionsModel[];
  weightUomSelectOptions: DropdownOptionsModel[];
  products: any;
  page: string;
  disabled: boolean;
  validation: EntityContainer<boolean>;
  handleChange: (prop: string, value: string) => void;
}

export default function ProductForm({
  shipmentDetails,
  fields,
  uomSelectOptions,
  weightUomSelectOptions,
  handleChange,
  products,
  page,
  disabled,
  validation,
}: Props) {
  const tempField = fields?.temp;
  const notesField = fields?.notes;
  const reeferField = fields?.reefer_settings;
  const productOptions = createDropdownOptions(products);
  return (
    <Row>
      <h4>{FORM_TITLE}</h4>
      <Col md={3} className="form-container-col">
        {fields.product_name && (
          <ShipmentField
            field={fields.product_name}
            options={productOptions}
            value={shipmentDetails.data.product_name || ''}
            disabled={disabled}
            size={12}
            validation={validation}
            handleChange={handleChange}
          />
        )}
        <InputSwitch
          labelText="Dangerous Goods"
          name="dangerous_goods"
          page={page}
          handleChange={handleChange}
          checked={shipmentDetails.data.dangerous_goods}
          className="form-toggle"
          dataCy="dangerous_goods"
          disabled={disabled}
        />
      </Col>
      {fields.purchase_order_no && (
        <ShipmentField
          field={fields.purchase_order_no}
          value={shipmentDetails.data.purchase_order_no || ''}
          disabled={disabled}
          validation={validation}
          handleChange={handleChange}
        />
      )}
      {fields.booking_no && (
        <ShipmentField
          field={fields.booking_no}
          value={shipmentDetails.data.booking_no || ''}
          disabled={disabled}
          validation={validation}
          handleChange={handleChange}
        />
      )}
      {fields.seal_no && (
        <ShipmentField
          field={fields.seal_no}
          value={shipmentDetails.data.seal_no || ''}
          disabled={disabled}
          validation={validation}
          handleChange={handleChange}
        />
      )}
      {fields.delivery_order_no && (
        <ShipmentField
          field={fields.delivery_order_no}
          value={shipmentDetails.data.delivery_order_no || ''}
          disabled={disabled}
          validation={validation}
          handleChange={handleChange}
        />
      )}
      {fields.paps && (
        <ShipmentField
          field={fields.paps}
          value={shipmentDetails.data.paps || ''}
          disabled={disabled}
          validation={validation}
          handleChange={handleChange}
        />
      )}
      {fields.pars && (
        <ShipmentField
          field={fields.pars}
          value={shipmentDetails.data.pars || ''}
          disabled={disabled}
          validation={validation}
          handleChange={handleChange}
        />
      )}
      {tempField.visible && (
        <Col md={2} className="form-container-col">
          <ControlInput
            labelText="Temp (F)"
            name="temp"
            page={page}
            value={`${shipmentDetails.data.temp}`}
            type="number"
            handleChange={handleChange}
            dataCy="shipment_temp"
            disabled={shipmentDetails.data.reefer_settings === ReeferSetting.Off
              || shipmentDetails.data.reefer_settings === ReeferSetting.Dry
              || disabled}
          />
        </Col>
      )}
      {reeferField?.visible && (
        <ShipmentField
          field={reeferField}
          value={getReeferSettingValue(shipmentDetails)}
          disabled={disabled}
          validation={validation}
          options={reeferSettingOptions}
          handleChange={handleChange}
        />
      )}
      {fields.quantity.visible && (
        <QuantityForm
          shipmentDetails={shipmentDetails}
          uomSelectOptions={uomSelectOptions}
          handleChange={handleChange}
          page={page}
          disabled={disabled}
          validation={validation}
          fields={fields}
        />
      )}
      {fields.weight.visible && (
        <WeightForm
          shipmentDetails={shipmentDetails}
          uomSelectOptions={weightUomSelectOptions}
          handleChange={handleChange}
          page={page}
          disabled={disabled}
          fields={fields}
          validation={validation}
        />
      )}
      {notesField.visible && (
        <Col md={12}>
          <ControlTextArea
            labelText={getName(notesField)}
            name={notesField.key}
            page={page}
            // type="textarea"
            value={shipmentDetails.data.notes}
            rows="4"
            handleChange={handleChange}
            dataCy="shipment_notes"
            disabled={disabled}
          />
          {isFieldError(notesField, validation) && (
            <ShipmentErrorLabel field={notesField} />
          )}
        </Col>
      )}
    </Row>
  );
}
