import { TripBoardModel } from "../../../../redux/models/trip.models";

export interface ItemModel {
  type: string;
  trip: TripBoardModel;
  index: number;
  driverId: string;
}

export const ROW_TYPE = 'DRAFT_PLAN';
