/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import useAppDispatch from '../../hooks/useAppDispatch';

import Header from './Header';
import ShipmentsTable from './ShipmentsTable/ShipmentsTable';
import NoShipmentMsg from './ShipmentsTable/NoShipmentMsg';
import Loader from '../../components/Loader';
import { createTripFromShipments } from '../../redux/slices/trips/trips';
import {
  loadShipments,
  selectAreShipmentsLoading,
  selectShipments,
} from '../../redux/slices/shipments';
import { selectIsSettingsLoaded, selectShipmentBoardSettings } from '../../redux/slices/settings';
import { createInvoice } from '../../redux/slices/invoices';
import { LOADING_TEXT } from '../boards/constants/boards.constants';
import UnassignedShipmentsTable from './ShipmentsTable/UnassignedShipmentsTable';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import ShipmentDataGrid from './ShipmentDataGrid/ShipmentDataGrid';
import { Board, TripTableColumns } from '../../redux/models/board.models';
import Analytics from '../../utils/analytics';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { isPermissions } from '../../redux/slices/settings.utils';
import { getPermissions } from '../../config';
import { ShipmentWritePermissions } from '../../components/shared/permissions/permissions.utils';

const NO_SHIPMENTS_MESSAGE = 'No shipments found';

interface Props {
  name?: string;
  board?: Board;
  shipmentTypeId?: string;
  headers: TripTableColumns[];
}

function ShipmentList({ name, board, shipmentTypeId, headers }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const features = useFeatureFlags();
  const [selectedShipments, setSelectedShipments] = useState<string[]>([]);
  const [showConfirmInvoice, setShowConfirmInvoice] = useState(false);
  const [showConfirmTrip, setShowConfirmTrip] = useState(false);
  const [creatingOptions, setCreatingOptions] = useState(false);
  const isDataGridEnabled = features.data_grid?.is_enabled || false;
  const permissions = getPermissions();
  const isEditable = isPermissions(orgCode, permissions, ShipmentWritePermissions);
  const useDataGrid = isEditable && isDataGridEnabled && shipmentTypeId !== undefined;
  const shipments = useSelector(selectShipments);
  const areShipmentsLoading = useSelector(selectAreShipmentsLoading);
  const boardSettings = useSelector(selectShipmentBoardSettings);
  const isBoardSettings = useSelector(selectIsSettingsLoaded);
  const title = name ? `${name} Shipments` : 'Shipments';
  
  const handleConfirmInvoice = () => setShowConfirmInvoice(true);
  const handleConfirmCreateTrip = () => setShowConfirmTrip(true);
  const handleOpen = (id: string) => navigate(`/${orgCode}/shipments/${id}`);

  const handleAddInvoice = async (shipmentIds: string[]) => {
    try {
      setCreatingOptions(true);
      const invoice = await createInvoice(shipmentIds, orgCode);
      if (invoice.entity_id) {
        navigate(`/${orgCode}/invoices/${invoice.entity_id}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCreatingOptions(false);
    }
  };
  const handleCreateInvoice = async () => {
    handleAddInvoice(selectedShipments);
  };
  const handleInvoiceShipment = async (id: string) => {
    await setSelectedShipments([id]);
    handleConfirmInvoice();
  };
  const handleAddTrip = async (shipmentIds: string[]) => {
    try {
      setCreatingOptions(true);
      const trip = await createTripFromShipments(shipmentIds);
      if (trip.entity_id) {
        Analytics.createTripFromShipments(orgCode);
        navigate(`/${orgCode}/trips/${trip.entity_id}`);
      }
    } catch (error) {
      const errorMessage = `Couldn't create trip from shipments. Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    } finally {
      setCreatingOptions(false);
    }
  };
  const handleCreateTripFromShipments = () => {
    handleAddTrip(selectedShipments);
  };
  const handleCreateTrip = async (id: string) => {
    await setSelectedShipments([id]);
    handleConfirmCreateTrip();
  };
  useEffect(() => {
    if (isBoardSettings) {
      dispatch(loadShipments(boardSettings, shipmentTypeId));
    }
  }, [dispatch, isBoardSettings, shipmentTypeId]);

  return (
    <>
      <Helmet title={title} />
      <Container fluid className="p-0">
        <Header
          title={title}
          board={board}
          selectedShipments={selectedShipments}
          shipmentTypeId={shipmentTypeId}
          onCreateInvoice={handleConfirmInvoice}
          onCreateTrip={handleConfirmCreateTrip}
        />
        {areShipmentsLoading && <Loader text={LOADING_TEXT} />}
        {!areShipmentsLoading && !shipmentTypeId && (
          <UnassignedShipmentsTable />
        )}
        {!useDataGrid && shipments.length > 0 && !areShipmentsLoading && (
          <div className="mt-4">
            <ShipmentsTable
              headers={headers}
              noShipmentsMessage={NO_SHIPMENTS_MESSAGE}
              formattedShipments={shipments}
              selectedShipments={selectedShipments}
              setSelectedShipments={setSelectedShipments}
            />
          </div>
        )}
        {useDataGrid && shipments.length > 0 && !areShipmentsLoading && (
          <div className="mt-4">
            <ShipmentDataGrid
              boardSettings={boardSettings}
              headers={headers}
              shipments={shipments}
              selectedShipments={selectedShipments}
              shipmentTypeId={shipmentTypeId}
              setSelectedShipments={setSelectedShipments}
              onOpen={handleOpen}
              onInvoice={handleInvoiceShipment}
              onCreateTrip={handleCreateTrip}
            />
          </div>
        )}
        {shipments.length === 0 && !areShipmentsLoading && (
          <div className="mt-6">
            <NoShipmentMsg areShipmentsLoading={areShipmentsLoading} />
          </div>
        )}
      </Container>
      {showConfirmInvoice && (
        <ConfirmModal
          title="Create Invoice"
          details="Would you like to create an invoice from the selected shipments? This will create one invoice with shipments as the line items"
          btnTitle="Create Invoice"
          saveDisabled={creatingOptions || selectedShipments.length === 0}
          shouldShowModal
          isSaving={creatingOptions}
          handleSave={handleCreateInvoice}
          cancelSave={() => setShowConfirmInvoice(false)}
        />
      )}
      {showConfirmTrip && (
        <ConfirmModal
          title="Create Trip"
          details="Would you like to create a trip from the selected shipments? Moves will be added for the shipment origin and destination if they are filled out."
          btnTitle="Create Trip"
          saveDisabled={creatingOptions || selectedShipments.length === 0}
          shouldShowModal
          isSaving={creatingOptions}
          handleSave={handleCreateTripFromShipments}
          cancelSave={() => setShowConfirmTrip(false)}
        />
      )}
    </>
  );
}

ShipmentList.defaultProps = {
  board: undefined,
  name: undefined,
  shipmentTypeId: undefined,
};

export default ShipmentList;