/* eslint-disable */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddModal from '../../../components/modals/AddModal';
import { DispatchNotificationType, TripDetails } from '../../../redux/models/trip.models';
import { ControlInput } from '../../../components/shared/Input';
import { useSelector } from 'react-redux';
import { selectDriversObject } from '../../../redux/slices/settings';
import { DateService } from '../../../utils/dateService';
import { emailTrip, saveTripDetails, selectIsTripDirty } from '../../../redux/slices/trips/trips';
import { formatOrgName, getValueFromLocalStorage, saveValueToLocalStorage } from '../../../utils/core.utils';
import Analytics from '../../../utils/analytics';
import { formatDispatchErrorMessage, isDispatchErrors, validateEmailDispatch, validateSmsDispatch } from '../utils/dispatch.utils';
import useAppSettings from '../../../hooks/useAppSettings';

const PAGE = 'trip_details';

const updateStatus = (trip: TripDetails): TripDetails => {
  return {
    ...trip,
    data: {
      ...trip.data,
      status: 'dispatched',
    },
  };
};

const getReplyToKey = (orgCode: string | undefined) => {
  const org = orgCode || '';
  return `${org}-reply-to-address`;
};

const getReplyTo = (org: string | undefined) => {
  const key = getReplyToKey(org);
  return getValueFromLocalStorage(key) || '';
};

const saveReplyTo = (email: string, org: string | undefined) => {
  const key = getReplyToKey(org);
  saveValueToLocalStorage(key, email);
};

interface Props {
  shouldShow: boolean;
  trip: TripDetails;
  type: DispatchNotificationType;
  saveTrip: (trip: TripDetails) => void;
  onClose: () => void;
}

export default function DispatchNotificationModal({
  shouldShow,
  trip,
  type,
  saveTrip,
  onClose,
}: Props) {
  const { orgCode } = useParams();
  const { timezone } = useAppSettings();
  const drivers = useSelector(selectDriversObject);
  const isDirty = useSelector(selectIsTripDirty);
  const driverId = trip.data.driver_id || '';
  const driver = drivers[driverId];
  const defaultName = driver?.data.samsara_name || '';
  const defaultEmail = driver?.data.email || '';
  const defaultPhone = driver?.data.samsara_phone || '';
  const defaultReplyTo = getReplyTo(orgCode) || '';
  const [isSending, setIsSending] = useState(false);
  const [name, setName] = useState(defaultName);
  const [email, setEmail] = useState(defaultEmail);
  const [phone, setPhone] = useState(defaultPhone);
  const [replyto, setReplyTo] = useState(defaultReplyTo);
  const timezone_offset = DateService.getTimezoneOffset(timezone);
  const isEmail = type === DispatchNotificationType.Email;
  const isEmailDisabled = name === '' || email === '' || replyto === '';
  const isSmsDisabled = name === '' || phone.length < 10;
  const isDisabled = isEmail ? isEmailDisabled : isSmsDisabled;
  const title = isEmail ? 'Email Dispatch' : 'Sms Dispatch';

  const onSelect = (prop: string, value: string) => {
    if (prop === 'name') setName(value);
    if (prop === 'email') setEmail(value);
    if (prop === 'reply_to') setReplyTo(value);
    if (prop === 'phone') setPhone(value);
  };
  const handleSend = async () => {
    const errors = isEmail ? validateEmailDispatch(trip, email, replyto) : validateSmsDispatch(trip, phone);
    const isInvalid = isDispatchErrors(errors);
    if (isInvalid) {
      const message = formatDispatchErrorMessage(errors);
      toast(message, { type: 'error' });
      return;
    }
    try {
      setIsSending(true);
      saveReplyTo(replyto, orgCode);
      let updatedTrip = trip;
      if (isDirty) {
        const dirtyTripResponse = await saveTripDetails(trip);
        if (dirtyTripResponse.status === 200) {
          updatedTrip = dirtyTripResponse.data.data;
        }
      }
      const response = await emailTrip(updatedTrip.entity_id, {
        type: type,
        send_to: isEmail ? email : `+1${phone}`,
        company_name: formatOrgName(orgCode),
        reply_to: isEmail ? replyto : null,
        driver_name: name,
        user_timezone: timezone_offset,
      });
      // save trip with dispatched status
      const updates = updateStatus(updatedTrip);
      await saveTrip(updates);
      if (response.status === 200) {
        toast('Dispatch Sent', { type: 'success' });
        onClose();
      }
    } catch (error: any) {
      const message = error?.description || "Couldn't dispatch trip.";
      toast(message, { type: 'error' });
    } finally {
      const event = isEmail ? 'Email' : 'SMS';
      Analytics.dispatchRoute(orgCode, event);
      setIsSending(false);
    }
  };
  return (
    <AddModal
      title={title}
      btnTitle="Send"
      variant="primary"
      spinner="light"
      saveDisabled={isDisabled}
      shouldShowModal={shouldShow}
      isSaving={isSending}
      handleSave={handleSend}
      cancelSave={onClose}
    >
      <ControlInput
        dataCy={`${PAGE}_name_input`}
        name="name"
        type="text"
        page={PAGE}
        value={name}
        labelText="Name"
        handleChange={onSelect}
      />
      {isEmail && (
        <ControlInput
          dataCy={`${PAGE}_email_input`}
          name="email"
          type="text"
          page={PAGE}
          value={email}
          labelText="Email"
          handleChange={onSelect}
        />
      )}
      {isEmail && (
        <ControlInput
          dataCy={`${PAGE}_reply_to_input`}
          name="reply_to"
          type="text"
          page={PAGE}
          value={replyto}
          labelText="Reply To Address"
          handleChange={onSelect}
        />
      )}
      {!isEmail && (
        <div className="container mt-3">
          <label htmlFor="phone-number" className="form-label">
            <h5>Phone Number</h5>
          </label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              +1
            </span>
            <input
              type="tel"
              className="form-control"
              placeholder="123-456-7890"
              aria-label="PhoneNumber"
              aria-describedby="basic-addon1"
              value={phone}
              onChange={(e) => onSelect('phone', e.target.value)}
            />
          </div>
        </div>
      )}
    </AddModal>
  );
}
