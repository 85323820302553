import axiosInstance from '../utils/axios';
import { AquadashServiceConfigProvider } from './AquadashServiceConfigProvider';
import { Document } from '../redux/models/file.models';
import FileCache from './FileCache';

export interface DownloadPostBody {
  name: string;
  filetype: string;
}

export class FileServiceApi {
  static downloadFile(files: DownloadPostBody[]) {
    return axiosInstance.post(AquadashServiceConfigProvider.downloadFile(), files);
  }

  static async getFiles(files: DownloadPostBody[]) {
    const exists = files.filter((fl) => FileCache.exists(fl.name));
    const notExists = files.filter((fl) => !FileCache.exists(fl.name));
    const cached = exists.map((fl) => FileCache.get(fl.name));
    if (notExists.length === 0) return cached;
    const response = await axiosInstance.post(AquadashServiceConfigProvider.downloadFile(), notExists);
    if (response.status === 200) {
      const data: Document[] = response.data.data || [];
      data.forEach((doc) => FileCache.add(doc));
      return [...cached, ...data];
    }
    return cached;
  }

  static async open(attachment: Document) {
    const files = [{ name: attachment.path, filetype: attachment.filetype }];
    const response = await FileServiceApi.downloadFile(files);
    if (response.status === 200) {
      window.open(response.data.data[0].path, '_blank');
    } else {
      const errorMessage = "Couldn't download attachments. Please contact support if the problem persists.";
      throw new Error(errorMessage);
    }
  }
}