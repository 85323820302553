import React, { useState } from 'react';
import { BillingItemReadPermissions, BillingItemWritePermissions } from '../../shared/permissions/permissions.utils';
import { getSelectedOptions } from '../../../utils/core.utils';
import { DropdownOptionsModel } from '../../shared/SelectInput.models';
import EntityInput from '../base/inputs/EntityInput';
import { Api } from '../../../services/services';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import { DataModel } from '../../../redux/models/core.models';
import { BillingItemData } from '../../../redux/models/billing.models';
import BillingItemModal from './BillingItemModal';

interface Props {
  value: string;
  page: string;
  prop: string;
  label?: string;
  disabled: boolean;
  allowAdd?: boolean;
  options: DropdownOptionsModel[];
  onChange: (prop: string, value: string) => void;
  onNewEntity: (prop: string, entity: DataModel<BillingItemData>) => void;
}

function BillingItemInput({ value, page, prop, label, disabled, allowAdd, options, onChange, onNewEntity }: Props) {
  const [showModal, setShowModal] = useState(false);
  const handleCreateData = (_: string, val: string): BillingItemData => {
    return {
      accessorial: false,
      billing_item_id: '',
      currency: '',
      department: null,
      department_code: '',
      department_code_id: '',
      department_name: '',
      description: val,
      gl: null,
      gl_code: '',
      gl_code_id: '',
      gl_name: '',
      invoice_id: '',
      item_code: '',
      price: 0,
      quantity: 0,
      tax: null,
      uom: null,
    };
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleNewRate = (entity: DataModel<BillingItemData>) => {
    onNewEntity(prop, entity);
  };
  return (
    <>
      <EntityInput
        disabled={disabled}
        labelText={label || 'Rate'}
        name={prop}
        page={page}
        value={value}
        options={getSelectedOptions(value, options)}
        entity="billing-item"
        dataField="description"
        readResources={BillingItemReadPermissions}
        writeResources={BillingItemWritePermissions}
        api={Api.BillingItems}
        allowAdd={allowAdd || false}
        modalAdd={allowAdd || false}
        settingName={SETTINGS_NAMES.BILLING_ITEMS}
        syncSettings
        onChange={onChange}
        createData={handleCreateData}
        onNewEntity={onNewEntity}
        onShowModal={handleShowModal}
      />
      {showModal && (
        <BillingItemModal
          show={showModal}
          setShow={setShowModal}
          onUpdate={handleNewRate}
        />
      )}
    </>
  )
}

BillingItemInput.defaultProps = {
  label: 'Rate',
  allowAdd: false,
}

export default BillingItemInput;
