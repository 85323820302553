export const SHIPMENTS_MODAL_TABLE_HEADERS = [
  {
    name: 'Shipment No',
    key: 'shipment_no',
  },
  {
    name: 'PO #',
    key: 'purchase_order_no',
  },
  {
    name: 'Container #',
    key: 'contaier_no',
  },
  {
    name: 'Customer',
    key: 'customer',
  },
  {
    name: 'Type',
    key: 'type',
  },
  {
    name: 'Date',
    key: 'start_date',
  },
];
