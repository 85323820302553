import { Badge } from 'react-bootstrap';

const renderShipments = (shipmentNos) => shipmentNos.map(
  (shipmentNo) => <Badge bg="secondary" className="badge-space" key={shipmentNo}>{shipmentNo}</Badge>,
);

const renderStatus = (status) => {
  switch (status) {
    case 'draft':
      return (
        <Badge bg="info" className="badge-space capitalized">
          {status}
        </Badge>
      );
    case 'sent':
      return (
        <Badge bg="primary" className="badge-space capitalized">
          {status}
        </Badge>
      );
    case 'paid':
      return (
        <Badge bg="success" className="badge-space capitalized">
          {status}
        </Badge>
      );
    case 'void':
      return (
        <Badge bg="warning" className="badge-space capitalized">
          {status}
        </Badge>
      );
    default:
      return '';
  }
};

export default function InvoiceTableRow({ invoice, prop }) {
  if (prop === 'shipment_no') {
    return renderShipments(invoice.shipment_no);
  }
  if (prop === 'status') {
    return renderStatus(invoice.status);
  }
  return `${invoice[prop]}`;
}
