/* eslint-disable import/no-cycle */
import { DataModel, EntityContainer } from '../../models/core.models';
import { DateService } from '../../../utils/dateService';
import { getFilterValueFromSessionStorage, saveFilterValueToSessionStorage } from '../../../utils/core.utils';
import { DropdownOption } from '../../../components/shared/dropdown.models';
import { TripListViewData } from '../../models/trip.models';
import { Invoice } from '../../models/invoice.models';
import { BillingItemData } from '../../models/billing.models';
import { Document } from '../../models/file.models';

export interface FormFieldConfig {
  name: string;
  key: string;
  required: boolean;
  type: string;
  visible: boolean;
}

export interface ShipmentTypeFieldData {
  billing_type_id: FormFieldConfig;
  bol: FormFieldConfig;
  booking_no: FormFieldConfig;
  // clearance_no: FormFieldConfig; // PAPS & PARS
  container_no: FormFieldConfig;
  container_size: FormFieldConfig;
  consignee_id: FormFieldConfig;
  customer_id: FormFieldConfig;
  // custom_fields: FormFieldConfig[];
  cut_off_date: FormFieldConfig;
  dangerous_goods: FormFieldConfig;
  // delivery_attachments: FormFieldConfig;
  delivery_order_no: FormFieldConfig;
  destination_id: FormFieldConfig;
  empty_release_depot: FormFieldConfig;
  erd: FormFieldConfig;
  // invoice: FormFieldConfig;
  last_free_date: FormFieldConfig;
  // misc_attachments: FormFieldConfig;
  notes: FormFieldConfig;
  origin_id: FormFieldConfig;
  paps: FormFieldConfig;
  pars: FormFieldConfig;
  // pick_up_attachments: FormFieldConfig;
  port_id: FormFieldConfig;
  product_name: FormFieldConfig;
  purchase_order_no: FormFieldConfig;
  quantity: FormFieldConfig;
  // quantity_uom: FormFieldConfig;
  quantity_uom_id: FormFieldConfig;
  reefer_settings: FormFieldConfig;
  requested_pick_up_date: FormFieldConfig;
  requested_drop_off_date: FormFieldConfig;
  seal_no: FormFieldConfig;
  // shipment_no: FormFieldConfig;
  shipping_line_id: FormFieldConfig;
  // status: FormFieldConfig;
  temp: FormFieldConfig;
  // trip_keys: FormFieldConfig;
  // trips: FormFieldConfig;
  // type: FormFieldConfig;
  vessel: FormFieldConfig;
  weight: FormFieldConfig;
  weight_uom_id: FormFieldConfig;
}

export interface ShipmentTypeData {
  name: string;
  fields: ShipmentTypeFieldData;
  delivery_attachments: Document[];
  misc_attachments: Document[];
  pick_up_attachments: Document[];
};

export type ShipmentType = DataModel<ShipmentTypeData>;

export interface ShipmentListViewData {
  shipment_id?: string;
  billing_type_id: string;
  bol: string;
  container_no: string;
  customer_id: string;
  dangerous_goods: boolean;
  dry: boolean;
  notes: string;
  purchase_order_no?: string;
  shipment_no?: string;
  status: string;
  temp: string;
}

export interface TripBoardShipment {
  id: string;
  billing_type_id: string;
  billingType: string;
  bol: string;
  containerNo: string;
  customer: string;
  customer_id: string;
  dangerous_goods: boolean;
  dry: boolean;
  notes: string;
  purchase_order_no?: string;
  shipment_no: string;
  shipment_status: string;
  temp: string;
}

export interface ShipmentData {
  accessorials: BillingItemData[];
  base_rate: BillingItemData;
  billing_type_id: string;
  booking_no?: string;
  bol: string;
  // clearance_no?: string;
  container_no: string;
  container_size?: string;
  consignee_id?: string;
  customer_id: string;
  cut_off_date?: string;
  dangerous_goods: boolean;
  dates?: {
    end_date: string;
    start_date: string;
  };
  delivery_attachments: Document[];
  delivery_order_no: string;
  destination_id: string;
  dry: boolean;
  empty_release_depot?: string;
  erd?: string;
  invoice?: Invoice;
  last_free_date?: string;
  misc_attachments: Document[];
  notes: string;
  origin_id: string;
  paps?: string;
  pars?: string;
  port_id?: string;
  pick_up_attachments: Document[];
  product_name: string;
  purchase_order_no?: string;
  quantity: number;
  quantity_uom: string;
  quantity_uom_id: string;
  requested_drop_off_date?: string;
  requested_pick_up_date?: string;
  role_ids?: string[];
  seal_no: string;
  shipment_no: string;
  shipping_line_id?: string;
  status?: string;
  temp: number;
  trip_ids?: string[];
  trips?: TripListViewData[];
  type: string;
  type_id: string;
  vessel?: string;
  weight?: number;
  weight_uom_id?: string;
}

export type Shipment = DataModel<ShipmentData>;

export interface ShipmentListView {
  id: string;
  container_no: string;
  customer: string;
  shipment_no: string;
  billing_type: string;
  type: string;
  type_id: string;
  origin: string;
  destination: string;
  status: string;
  start_date: string;
  purchase_order_no: string;
  unix: number;
}

const DEFAULT_DATE_RANGE = 'Today';

export const ShipmentDropdownOptions: EntityContainer<DropdownOption> = {
  customer: {
    id: 'customer',
    value: 'Customer'
  },
  startDate: {
    id: 'start_date',
    value: 'Date'
  },
  type: {
    id: 'type',
    value: 'Shipment Type'
  },
  type_id: {
    id: 'type_id',
    value: 'Shipment Type'
  },
  billing_type: {
    id: 'billing_type',
    value: 'Quote Template'
  },
  status: {
    id: 'status',
    value: 'Status'
  },
};

export const PageSizeDropdownOptions: EntityContainer<DropdownOption> = {
  // ten: {
  //   id: '10',
  //   value: '10'
  // },
  twentyFive: {
    id: '25',
    value: '25'
  },
  fifty: {
    id: '50',
    value: '50'
  },
  hundred: {
    id: '100',
    value: '100'
  },
}

export interface ShipmentListFilters {
  containerNo: string;
  customer: string;
  dateFrom: string;
  dateTo: string;
  dateRange: string;
  destination: string;
  grouping: string;
  loadType: string;
  origin: string;
  purchaseOrder: string;
  status: string;
  shipmentType: string;
  shipmentTypeId: string;
  pageSize: number;
  page: number;
}

export interface ShipmentGroup {
  id: string;
  shipments: string[];
}

export const DEFAULT_PAGE_SIZE = 25;

export const defaultShipmentListFilters: ShipmentListFilters = {
  containerNo: '',
  dateFrom: DateService.getStartOfTheDayISO(new Date()),
  dateTo: DateService.getEndOfTheDayISO(new Date()),
  dateRange: DEFAULT_DATE_RANGE,
  grouping: '',
  origin: '',
  destination: '',
  customer: '',
  shipmentType: '',
  shipmentTypeId: '',
  loadType: '',
  purchaseOrder: '',
  status: '',
  pageSize: DEFAULT_PAGE_SIZE,
  page: 0,
};

export const FILTER_KEY = 'shipment-list-filters';

export const getSessionFilters = (): ShipmentListFilters => {
  const sessionFilterString = getFilterValueFromSessionStorage(FILTER_KEY);
  return sessionFilterString ? JSON.parse(sessionFilterString) : defaultShipmentListFilters;
};

export const saveSessionFilters = (filters: ShipmentListFilters) => {
  saveFilterValueToSessionStorage(FILTER_KEY, filters);
};

export interface ShipmentListState {
  areShipmentsLoading: boolean;
  areShipmentsLoadingMore: boolean;
  isLoaded: boolean;
  groupedShipments: ShipmentGroup[],
  container: EntityContainer<Shipment>;
  expandedGroups: EntityContainer<boolean>;
  list: string[]; 
  areShipmentDetailsLoading: boolean;
  shipmentDetails: Shipment | null;
  filterParams: ShipmentListFilters;
  totalShipments: number;
}

export const initialState: ShipmentListState = {
  areShipmentsLoading: true,
  areShipmentsLoadingMore: false,
  container: {},
  expandedGroups: {},
  isLoaded: false,
  groupedShipments: [],
  areShipmentDetailsLoading: true,
  shipmentDetails: null,
  filterParams: getSessionFilters(),
  list: [],
  totalShipments: 0,
};

export const resetInitialState = () => ({
  ...initialState,
  isLoaded: false,
  areShipmentsLoading: false,
  filterParams: getSessionFilters(),
});