import React from 'react';
import { useSelector } from 'react-redux';
import SuggestionAlert from './SuggestionAlert';
import { Driver } from '../../../../redux/models/settings.models';
import { DriverTrip } from '../../../../redux/models/driver.availability.models';
import { TripDetails } from '../../../../redux/models/trip.models';
import { selectAddressesObject } from '../../../../redux/slices/settings';
import { getIsConflict, getMessage } from './suggestion.utils';
import { DateService } from '../../../../utils/dateService';

interface Props {
  tripDetails: TripDetails;
  driver: Driver;
  roster: DriverTrip[];
  close: (driverId: string) => void;
}

export default function SuggestionListItem({ tripDetails, driver, roster, close }: Props) {
  const addresses = useSelector(selectAddressesObject);
  const sortedRosters = [...roster].sort((a, b) => {
    const aTime = a.data.scheduled_departure_time || '';
    const bTime = b.data.scheduled_arrival_time || '';
    return aTime.localeCompare(bTime);
  });

  const tripDate = tripDetails.data.date || '';
  const messages = sortedRosters.length ?
    sortedRosters.map((tripRoster) => getMessage(tripDetails, tripRoster, addresses)) :
    [`No other trips on ${DateService.getDoMMMFormat(tripDate)}.`];
  const isDrivable = sortedRosters.length ?
    sortedRosters
      .map((tripRoster) => getIsConflict(tripDetails, tripRoster, addresses))
      .filter((drivable) => drivable).length > 0 : true;
  const variant = isDrivable ? 'primary' : 'warning';
  const handleSelect = () => close(driver.entity_id);
  return (
    <div>
      <SuggestionAlert
        variant={variant}
        title={driver?.data.samsara_name || ''}
        messages={messages}
        action={handleSelect}
      />
    </div>
  );
}
