import React from 'react';
import {
  Dropdown,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

import { DateRangeType, getDefaultDate } from '../modals/SearchFilter/searchFilters.constants';
import { SearchDateRange } from '../modals/SearchFilter/searchFilter.models';
import { DateService } from '../../utils/dateService';

interface Props {
  page: string;
  range: SearchDateRange;
  type: DateRangeType;
  isOpen: boolean;
  handleToggle: (open: boolean) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDateFilterParams: (selectedDate: SearchDateRange, type: DateRangeType) => void;
}

function getBtnText(type: DateRangeType, range: SearchDateRange) {
  if (type !== DateRangeType.Custom) {
    return type
  }
  const start = DateService.getDoMMMFormat(range.dateFrom);
  const end = DateService.getDoMMMFormat(range.dateTo);
  return `${start} - ${end}`;
}

export default function DateButton({
  page,
  range,
  type,
  isOpen,
  setIsOpen,
  handleToggle,
  setDateFilterParams,
}: Props) {
  
  const dateOptions = [...Object.values(DateRangeType)];

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDateSelect = (selectedValue: string | null) => {
    if (!selectedValue) return;
    const dateType = selectedValue as DateRangeType;
    if (dateType !== DateRangeType.Custom) {
      const selectedDate = getDefaultDate(dateType);
      setDateFilterParams(selectedDate, dateType);
      handleClose();
    }
  };
  return (
    <Dropdown
      show={isOpen}
      onToggle={handleToggle}
      onSelect={handleDateSelect}
      data-cy={`${page}_date_select`}
      className="md-2"
      autoClose={false}
    >
      <Dropdown.Toggle
        data-cy={`${page}_date_filter`}
        className="filter-datepicker"
        variant="outline-secondary"
      >
        <FontAwesomeIcon
          height={10}
          width={10}
          icon={faCalendar}
        />
        { ' '}
        {getBtnText(type, range)}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {dateOptions.filter((option) => option !== DateRangeType.Custom).map((option) => (
          <Dropdown.Item key={option} data-cy={`${option}_btn`} eventKey={option}>
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
