import { TripTableColumns } from "../../../../redux/models/board.models";

export const TRIP_TABLE_HEADERS: TripTableColumns[] = [
  {
    name: 'Driver',
    key: 'driver',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'trip',
  },
  {
    name: 'Trailer',
    key: 'trailer',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'move',
  },
  {
    name: 'Container #',
    key: 'containerNo',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Customer',
    key: 'customer',
    visible: true,
    width: 100,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Quote Template',
    key: 'billingType',
    visible: true,
    width: 100,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Route',
    key: 'route',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'move',
  },
  {
    name: 'Apt',
    key: 'apt',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'move',
  },
  {
    name: 'P.O. #',
    key: 'purchase_order_no',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Temp (f)',
    key: 'temp',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'move',
  },
  {
    name: 'BOL',
    key: 'bol',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Notes',
    key: 'notes',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'DG',
    key: 'dangerous_goods',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Status',
    key: 'status',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'trip',
  },
  {
    name: 'Timeliness',
    key: 'timeliness',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'trip',
  },
];


export const SHIPMENT_TABLE_HEADERS: TripTableColumns[] = [
  {
    name: 'Customer',
    key: 'customer',
    visible: true,
    width: 100,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Quote Template',
    key: 'billingType',
    visible: true,
    width: 100,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Container #',
    key: 'containerNo',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'P.O. #',
    key: 'purchase_order_no',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Notes',
    key: 'notes',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Status',
    key: 'status',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'trip',
  },
];
