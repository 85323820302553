/* eslint-disable */
import React, { useState } from 'react'
import ButtonSpinner from '../../../components/shared/ButtonSpinner';
import { useSelector } from 'react-redux';
import { selectAddressesObject, selectContactsObject } from '../../../redux/slices/settings';
import AddressInput from '../../entities/address/AddressInput';
import PlaceholderValue from './PlaceholderValue';

interface Props {
  addressId: string;
  prop: string;
  onChange: (value: string) => void;
}

const AddressCell: React.FC<Props> = ({ addressId, prop, onChange }) => {
  const [editing, setEditing] = useState(false)
  const [tempValue, setTempValue] = useState(addressId)
  const [isSaving, setSaving] = useState(false);
  const addresses = useSelector(selectAddressesObject);
  const name = addresses[tempValue]?.data?.samsara_name || '';
  const handleEdit = () => {
    setEditing(true);
  };

  const handleChange = async (_: string, newCustomerId: string) => {
    setTempValue(newCustomerId);
    setSaving(true);
    setEditing(false);
    await onChange(newCustomerId);
    setSaving(false);
  };
  const handleCreate = () => {};

  if (editing) {
    return (
      <AddressInput
        value={addressId}
        page=""
        prop={prop}
        label=""
        disabled={false}
        allowAdd={false}
        onChange={handleChange}
        onNewEntity={handleCreate}
      />
    )
  }

  return (
    <div onClick={handleEdit} style={{ cursor: 'text' }}>
      {name || <PlaceholderValue />}
      {isSaving && <ButtonSpinner />}
    </div>
  )
}

export default AddressCell;