import React from 'react';
import { toast } from 'react-toastify';

import RelInput from '../../../shared/inputs/RelInput';
import { getSelectedOptions } from '../../../../utils/core.utils';
import { DropdownOptionsModel } from '../../../shared/SelectInput.models';
import { EntityServiceProvider } from '../../../../services/base/EntityServiceProvider';
import ApiResponse from '../../../../services/base/ApiResponse';
import { addSettingsItem } from '../../../../redux/slices/settings';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { DataModel, EntityContainer } from '../../../../redux/models/core.models';
import { ResourcePermission } from '../../../../redux/models/feature.flags.models';

interface Props<T> {
  allowAdd?: boolean;
  value: string;
  page: string;
  disabled: boolean;
  options: DropdownOptionsModel[];
  entity: string;
  settingName: string;
  syncSettings: boolean;
  api: EntityServiceProvider<T>;
  defaultAdd?: boolean;
  modalAdd?: boolean;
  customAdd?: boolean;
  labelText: string;
  name: string;
  dataField: string;
  secondSearch?: string;
  readResources: EntityContainer<ResourcePermission>;
  writeResources: EntityContainer<ResourcePermission>;
  createData: (prop: string, value: string) => T;
  onChange: (prop: string, value: string) => void;
  onNewEntity: (props: string, entity: DataModel<T>) => void;
  onCreateCustom?: (props: string, value: string) => void;
  onShowModal?: (props: string, value: string) => void;
}

function EntityInput<T>({
  allowAdd,
  api,
  customAdd,
  dataField,
  secondSearch,
  defaultAdd,
  disabled,
  entity,
  labelText,
  modalAdd,
  name,
  options,
  page,
  settingName,
  syncSettings,
  value,
  readResources,
  writeResources,
  onChange,
  createData,
  onNewEntity,
  onCreateCustom,
  onShowModal,
}: Props<T>) {
  const dispatch = useAppDispatch();
  
  async function handleAddEntity(prop: string, data: T) {
    try {      
      const response = await api.create(data);
      if (response.status === 200) {
        const newEntity: DataModel<T> = ApiResponse.parseItem(response);
        if (syncSettings) dispatch(addSettingsItem({ name: settingName, data: newEntity }));
        if (onNewEntity) onNewEntity(prop, newEntity);
      }
    } catch (error) {
      toast(`Couldn't add ${settingName.toLocaleLowerCase()}`, { type: 'error' });
    }
  }

  const handleOnCreate = async (prop: string, val: string) => {
    if (defaultAdd) {
      const data = createData(prop, val);
      await handleAddEntity(prop, data);
    }
    if (modalAdd && onShowModal) onShowModal(prop, val);
    if (customAdd && onCreateCustom) onCreateCustom(prop, val);
  };

  return (
    <RelInput
      allowAdd={allowAdd}
      disabled={disabled}
      labelText={labelText}
      name={name}
      page={page}
      value={value}
      options={getSelectedOptions(value, options)}
      handleChange={onChange}
      dataCy={`${page}_${name}_input`}
      entity={entity}
      dataField={dataField}
      secondSearch={secondSearch}
      emptyStateOptions={[]}
      readResources={readResources}
      writeResources={writeResources}
      handleCreate={handleOnCreate}
    />
  )
}

EntityInput.defaultProps = {
  allowAdd: false,
  defaultAdd: false,
  modalAdd: false,
  customAdd: false,
  secondSearch: undefined,
  onCreateCustom: () => {},
  onShowModal: () => {},
};

export default EntityInput;
