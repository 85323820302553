/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-plusplus */
/* eslint-disable  no-restricted-syntax */
import React, { useEffect, useMemo } from 'react';
import Analytics from '../utils/analytics';
import { fetchFeatureFlags, getLocalFeatureFlags } from '../redux/slices/settings';
import useAuth from '../hooks/useAuth';
import { deepEqual } from '../utils/core.utils';

const getInitialData = (name, key) => ({
  key,
  name,
  organization_ids: [],
  organizations: [],
  resource_ids: [],
  user_ids: [],
  is_enabled: false,
});

const initialState = {
  driver_availability: getInitialData('Driver Availability', 'driver_availability'),
  dashboard: getInitialData('Dashboard', 'dashboard'),
  invoices: getInitialData('Invoices', 'invoices'),
  optimise_planner: getInitialData('Trip Planner - Assign Drivers', 'optimise_planner'),
  paginated_shipments: getInitialData('Paginated Shipments', 'paginated_shipments'),
  samsara_routing: getInitialData('Samsara Routing', 'samsara_routing'),
  email_dispatch: getInitialData('Email Dispatch', 'email_dispatch'),
  sms_dispatch: getInitialData('Sms Dispatch', 'sms_dispatch'),
  share: getInitialData('Share', 'share'),
  trailer_changes: getInitialData('Trailer Changes', 'trailer_changes'),
  trip_costs: getInitialData('Costs', 'trip_costs'),
  trip_planner: getInitialData('Trip Planner', 'trip_planner'),
  planner_dispatch: getInitialData('Planner Dispatch', 'planner_dispatch'),
  async_dispatch: getInitialData('Async Dispatch', 'async_dispatch'),
  async_templates: getInitialData('Async Templates', 'async_templates'),
  data_grid: getInitialData('Data Grid', 'data_grid'),
};

export const FeatureFlagsContext = React.createContext(initialState);

export function FeatureFlagsProvider({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  const [features, setFeatures] = React.useState(initialState);

  useEffect(() => {
    const handleLoad = async () => {
      try {
        const localData = getLocalFeatureFlags();
        const localList = localData || {};
        if (Object.keys(localList).length) setFeatures(localList);
        const featurData = await fetchFeatureFlags();
        const isEqual = deepEqual(localList, featurData);
        if (!isEqual) setFeatures(featurData);
      } catch (error) {
        Analytics.capture(error);
      }
    };
    if (isAuthenticated && isInitialized) handleLoad();
  }, [isAuthenticated, isInitialized]);

  const providerValue = useMemo(
    () => ({
      ...features,
    }),
    [features],
  );
  return (
    <FeatureFlagsContext.Provider value={providerValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};