import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EntityList from '../base/list/EntityList';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import { Api } from '../../../services/services';
import BreadCrumbs, { BreadCrumbRoute } from '../../shared/breadcrumbs/BreadCrumbs';
import { Driver } from '../../../redux/models/settings.models';
import DriverModal from './DriverModal';

const getRoutes = (orgCode: string | undefined): BreadCrumbRoute[] => {
  return [
    {
      name: 'Settings',
      route: `/${orgCode || ''}/settings`,
    },
    {
      name: 'Drivers',
      route: `/${orgCode}/settings/drivers`,
    },
  ];
}

export default function DriverList() {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const [showAdd, setShowAdd] = useState(false);
  const handleGetName = (driver: Driver) => driver.data.samsara_name || '';
  const createRoute = (driver: Driver) => `/${orgCode}/settings/drivers/${driver.entity_id}`;
  const handleAdd = () => {
    setShowAdd(true);
  };
  const handleUpdate = (driver: Driver) => {
    const route = createRoute(driver);
    navigate(route);
  };
  return (
    <>
      <BreadCrumbs routes={getRoutes(orgCode)} />
      <EntityList
        title="Drivers"
        settingsKey={SETTINGS_NAMES.DRIVERS}
        nameKey="samsara_name"
        api={Api.Drivers}
        onGetName={handleGetName}
        onCreateRoute={createRoute}
        onAdd={handleAdd}
      />
      {showAdd && (
        <DriverModal
          show={showAdd}
          setShow={setShowAdd}
          onUpdate={handleUpdate}
        />
      )}
    </>
  );
}
