/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

import { DateService } from '../../utils/dateService';

import { getDefaultTrip } from '../../pages/boards/constants/boards.constants';
import {
  saveFilterValueToSessionStorage,
} from '../../utils/core.utils';
import {
  initialState,
  resetInitialState,
  FILTER_KEY,
  Shipment,
  ShipmentListFilters,
  ShipmentData,
  ShipmentState,
  getSessionFilters,
} from '../models/shipment.models';
import {
  formatShipments, clientFilterShipments, groupBy, getError, getFindShipmentFilters, getGroupByKey
} from './shipments.utils';
import { EntityContainer } from '../models/core.models';
import Analytics from '../../utils/analytics';
import { ShipmentServiceApiProvider } from '../../services/ShipmentServiceProvider';
import { TripServiceApiProvider } from '../../services/TripServiceProvider';
import { Contact } from '../models/contact.models';
import { BillingType } from '../models/billing.models';
import { Address } from '../models/settings.models';
import { formatModalShipments } from '../../pages/shipments/ShipmentsTable/utils/shipmentTable.utils';
import { TripData } from '../models/trip.models';
import { Api } from '../../services/services';
import { ApiQuery, DEFUALT_CREATED_AT_SORTING } from '../models/network.models';
import { Document } from '../models/file.models';

const api = Api.Shipments;

const SLICE_NAME = 'shipments';

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setShipments(state, action) {
      const { container, shipments, filters, modalShipments } = action.payload;
      const groups = groupBy(filters.grouping, shipments);
      const storedFilters = JSON.stringify(filters);
      saveFilterValueToSessionStorage(FILTER_KEY, storedFilters);
      state.container = {
        ...container,
      }
      state.groupedShipments = groups;
      state.shipments = shipments;
      state.filteredShipments = modalShipments || [];
    },
    setShipmentListItem(state, action) {
      const { entity, listItem, filters } = action.payload;
      if (filters.grouping !== '') {
        const groupId = getGroupByKey(filters.grouping, listItem);
        state.groupedShipments = state.groupedShipments.map((group) => {
          if (group.id === groupId) return {
            ...group,
            shipments: group.shipments.map((ship) => {
              if (ship.id === listItem.id) return listItem;
              return ship;
            })
          }
          return group;
        })
      }
      state.shipments = state.shipments.map((item) => {
        if (item.id === listItem.id) return listItem;
        return item
      });
      state.container = {
        ...state.container,
        [entity.entity_id]: entity,
      }
    },
    setNewShipmentListItem(state, action) {
      const { entity, listItem, filters } = action.payload;
      if (filters.grouping !== '') {
        const groupId = getGroupByKey(filters.grouping, listItem);
        state.groupedShipments = state.groupedShipments.map((group) => {
          if (group.id === groupId) return {
            ...group,
            shipments: [...group.shipments, listItem]
          }
          return group;
        })
      }
      state.shipments = [...state.shipments, listItem];
      state.container = {
        ...state.container,
        [entity.entity_id]: entity,
      }
    },
    setFilteredShipments(state, action) {
      state.filteredShipments = action.payload || [];
    },
    setAreShipmentsLoading(state, action) {
      state.areShipmentsLoading = action.payload;
    },
    setShipmentDetails(state, action) {
      state.shipmentDetails = action.payload.data;
    },
    updateShipmentDetails(state, action) {
      state.shipmentDetails = action.payload;
    },
    setFilteredShipmentsAllDetails(state, action) {
      state.filteredShipmentsAllDetails = action.payload.data;
    },
    setAreShipmentDetailsLoading(state, action) {
      state.areShipmentDetailsLoading = action.payload;
    },
    setFilterParams(state, action) {
      const storedFilters = JSON.stringify(action.payload);
      saveFilterValueToSessionStorage(FILTER_KEY, storedFilters);
      const {
        customer, status, origin, destination, type, purchaseOrder,
      } = action.payload;
      const filteredShipments = clientFilterShipments(
        [...state.shipments],
        customer,
        status,
        origin,
        destination,
        type,
        purchaseOrder,
      );
      state.filterParams = action.payload;
      state.filteredShipments = filteredShipments;
    },
    setGroupBy(state, action) {
      const filters = {
        ...state.filterParams,
        grouping: action.payload,
      };
      const storedFilters = JSON.stringify(filters);
      saveFilterValueToSessionStorage(FILTER_KEY, storedFilters);
      const groups = groupBy(action.payload, [...state.shipments]);
      state.groupedShipments = groups;
      state.filterParams = filters;
    },
    resetShipments: () => resetInitialState(),
  },
});

export const {
  setShipments,
  setShipmentListItem,
  setNewShipmentListItem,
  setFilteredShipments,
  setAreShipmentsLoading,
  setShipmentDetails,
  setFilteredShipmentsAllDetails,
  updateShipmentDetails,
  setAreShipmentDetailsLoading,
  setFilterParams,
  setGroupBy,
  resetShipments,
} = slice.actions;

const formatShipmentPostBody = (shipment: Shipment, tripIds: string[] = []) => {
  const trips = shipment.data.trips || [];
  const trIds = trips.filter((tr) => tr.entity_id !== undefined).map((trip) => trip.entity_id || '').concat(tripIds);
  const shipmentData: Shipment = {
    ...shipment,
    data: {
      ...shipment.data,
      trip_ids: trIds || [],
    },
  };
  delete shipmentData.data.trips;
  return shipmentData;
};

export const loadUnassignedShipments = async (listFilters: ShipmentListFilters, page: number) => {
  const queryfilters = getFindShipmentFilters(listFilters);
  const params: ApiQuery = {
    filters: [
      {
        field: {
          name: 'data.trip_ids',
          type: 'rel',
        },
        op: 'eq',
        value: [],
      },
      ...queryfilters,
    ],
    operator: "AND",
    pagination: {
      page,
      page_size: 10,
    },
    sorting: DEFUALT_CREATED_AT_SORTING,
  };
  return Api.Shipments.find(params);
}

export const duplicateShipment = (shipmentId: string, trips: boolean) => {
  return Api.Shipments.duplicate(shipmentId, trips);
}

export const loadShipments = (shipmentSettings: any, shipmentTypeId: string | undefined) => async (dispatch: any) => {
  dispatch(setAreShipmentsLoading(true));
  try {
    const { addresses, contacts, billingTypes } = shipmentSettings;
    const filters = getSessionFilters();
    
    const { data } = shipmentTypeId
      ? await ShipmentServiceApiProvider.getShipmentsByTypeId(filters, shipmentTypeId)
      : await ShipmentServiceApiProvider.getShipments(filters);
    const items: Shipment[] = data.data;
    const container: EntityContainer<Shipment> = items.reduce((store, ship) => {
      return {
        ...store,
        [ship.entity_id]: ship,
      }
    }, {});
    const sortKey = shipmentTypeId ? 'requested_pick_up_date' : 'dates';
    const shipments = formatShipments(data.data, contacts, billingTypes, addresses, sortKey);
    const modalShipments = formatModalShipments(data.data, contacts, billingTypes, addresses);
    const foundModalShipments = formatModalShipments(data.data, contacts, billingTypes, addresses);
    return dispatch(
      setShipments({
        container,
        shipments: [...shipments],
        filters,
        modalShipments: [...modalShipments, ...foundModalShipments]
      })
    );
  } catch (e) {
    return getError(e);
  } finally {
    dispatch(setAreShipmentsLoading(false));
  }
};

export const otherUpdateShipmentListViewItem = (
  shipment: Shipment
) => {
  return Api.Shipments.updateListView(shipment);
};

export const updateShipmentListViewItem = (
  shipment: Shipment, filters: ShipmentListFilters, settings: any,
) => async (dispatch: any) => {
  const { addresses, contacts, billingTypes } = settings;
  const listItem = formatShipments([shipment], contacts, billingTypes, addresses)[0];
  dispatch(
    setShipmentListItem({
      listItem,
      entity: shipment,
      filters,
    })
  );
};

export const addShipmentListViewItem = (
  shipment: Shipment, filters: ShipmentListFilters, settings: any,
) => async (dispatch: any) => {
  const { addresses, contacts, billingTypes } = settings;
  const listItem = formatShipments([shipment], contacts, billingTypes, addresses)[0];
  dispatch(
    setNewShipmentListItem({
      listItem,
      entity: shipment,
      filters,
    })
  );
};

export const getShipments = (filters: ShipmentListFilters, shipmentSettings: any) => async (dispatch: any) => {
  dispatch(setAreShipmentsLoading(true));
  try {
    const { addresses, contacts, billingTypes } = shipmentSettings;
    const { data } = await ShipmentServiceApiProvider.getShipments(filters);
    const items: Shipment[] = data.data;
    const container: EntityContainer<Shipment> = items.reduce((store, ship) => {
      return {
        ...store,
        [ship.entity_id]: ship,
      }
    }, {});
    const shipments = formatShipments(data.data, contacts, billingTypes, addresses);
    const modalShipments = formatModalShipments(data.data, contacts, billingTypes, addresses);
    return dispatch(setShipments({ container, shipments, filters, modalShipments }));
  } catch (e) {
    return getError(e);
  } finally {
    dispatch(setAreShipmentsLoading(false));
  }
};

export const updateShipment = (shipment: Shipment) => {
  return Api.Shipments.update(shipment);
};

export const addShareDocument = (shipmentId: string, document: Document) => {
  return Api.Shipments.addDocument(shipmentId, document);
};

export const filterShipmentDates = (
  filters: ShipmentListFilters, shipmentSettings: any, shipmentTypeId: string | undefined
) => async (dispatch: any) => {
  dispatch(setAreShipmentsLoading(true));
  try {
    const { addresses, contacts, billingTypes } = shipmentSettings;
    const { data } = shipmentTypeId
      ? await ShipmentServiceApiProvider.getShipmentsByTypeId(filters, shipmentTypeId)
      : await ShipmentServiceApiProvider.getShipments(filters);
    const items: Shipment[] = data.data;
    const container: EntityContainer<Shipment> = items.reduce((store, ship) => {
      return {
        ...store,
        [ship.entity_id]: ship,
      }
    }, {});
    const shipments = formatShipments(data.data, contacts, billingTypes, addresses);
    const modalShipments = formatModalShipments(data.data, contacts, billingTypes, addresses);
    dispatch(setFilterParams(filters));
    return dispatch(setShipments({ container, shipments, filters, modalShipments }));
  } catch (e) {
    return getError(e);
  } finally {
    dispatch(setAreShipmentsLoading(false));
  }
};

export const filterShipments = (
  filters: ShipmentListFilters,
  contacts: EntityContainer<Contact>,
  billingTypes: EntityContainer<BillingType>,
  addresses: EntityContainer<Address>,
  ) => async (dispatch: any) => {
  dispatch(setAreShipmentsLoading(true));
  try {
    const { data } = await ShipmentServiceApiProvider.getShipments(filters);
    const shipments = formatModalShipments(data.data, contacts, billingTypes, addresses);
    return dispatch(setFilteredShipments(shipments));
  } catch (e) {
    return getError(e);
  } finally {
    dispatch(setAreShipmentsLoading(false));
  }
};

export const filterShipmentsAllDetails = (filters: ShipmentListFilters) => async (dispatch: any) => {
  dispatch(setAreShipmentsLoading(true));
  try {
    const { data } = await ShipmentServiceApiProvider.getShipmentsAllDetails(filters);
    return dispatch(setFilteredShipmentsAllDetails(data));
  } catch (e) {
    return getError(e);
  } finally {
    dispatch(setAreShipmentsLoading(false));
  }
};

export const getShipmentDetails = (shipmentId: string) => async (dispatch: any) => {
  dispatch(setAreShipmentDetailsLoading(true));
  try {
    const { data: shipment } = await ShipmentServiceApiProvider.getShipmentDetails(shipmentId);
    return dispatch(setShipmentDetails(shipment));
  } catch (e) {
    return getError(e);
  } finally {
    dispatch(setAreShipmentDetailsLoading(false));
  }
};

export const loadShipmentDetails = (shipmentId: string) => {
  return ShipmentServiceApiProvider.getShipmentDetails(shipmentId);
};

export const getShipmentHistory = (shipmentId: string) => {
  return Api.Shipments.getActivityLog(shipmentId);
};

/**
 *  - Create default trip
 *  - Update shipment
 *  - Get shipment details
 *  - Dispatch shipment
 */
export const addShipmentTrip = (shipment: Shipment, orgCode: string | undefined) => async (dispatch: any) => {
  try {
    const date = DateService.getISOString();
    const tripData = getDefaultTrip(date, '', '', '', [shipment.entity_id]);
    const { data: trip } = await TripServiceApiProvider.createTrip(tripData);
    const tripId: string = trip.data.entity_id;
    const shipmentPostBody = formatShipmentPostBody(shipment, [tripId]);
    await ShipmentServiceApiProvider.updateShipment(shipmentPostBody);
    const { data: updatedDetails } = await ShipmentServiceApiProvider.getShipmentDetails(shipment.entity_id);
    return dispatch(setShipmentDetails(updatedDetails));
  } catch (e) {
    return getError(e, "Couldn't add shipment trip.");
  } finally {
    Analytics.createNewTrip(orgCode);
  }
};

export const createBoardShipment = async (body: ShipmentData, orgCode: string | undefined) => {
  try {
    const { data: shipment } = await ShipmentServiceApiProvider.createBoardShipment(body);
    return shipment.data;
  } catch (e) {
    return getError(e, "Couldn't create shipment", "create");
  } finally {
    Analytics.createShipment(orgCode);
  }
};

export const createBoardTrip = async (data: TripData, orgCode: string | undefined) => {
  try {
    const { data: trip } = await Api.Trips.create(data);
    return trip.data;
  } catch (e) {
    return getError(e, "Couldn't create trip", "create");
  } finally {
    Analytics.createNewTrip(orgCode);
  }
};

export const saveShipmentDetails = (data: Shipment, reload = true) => async (dispatch: any) => {
  dispatch(setAreShipmentDetailsLoading(true));
  try {
    const shipmentData = {
      ...data.data,
    };
    const trips = shipmentData.trips || [];
    shipmentData.trip_ids = trips.map((trip) => trip.entity_id || '');
    const postBody = {
      ...data,
      data: {
        ...shipmentData,
      },
    };
    const { data: updatedShipment } = await api.updateCQRS(postBody);
    if (reload) {
      const { data: reloadedShipment } = await ShipmentServiceApiProvider.getShipmentDetails(data.entity_id);
      return dispatch(setShipmentDetails(reloadedShipment));
    }
    return dispatch(setShipmentDetails(updatedShipment));
  } catch (e) {
    return getError(e, "Couldn't save shipment", "save");
  } finally {
    dispatch(setAreShipmentDetailsLoading(false));
  }
};

export const createShipment = async (shipmentTypeId = '') => {
  try {
    const { data: shipment } = await api.createCQRS(shipmentTypeId);
    return shipment.data;
  } catch (e) {
    return getError(e, "Couldn't create shipment", "create");
  }
};

export const createShipmentBatch = async (shipments: ShipmentData[]) => {
  return api.createBatch(shipments);
};

export const deleteShipment = async (shipment: Shipment, trips: boolean) => api.deleteShipment(shipment, trips);

export const selectShipments = (state: EntityContainer<ShipmentState>) => state[SLICE_NAME].shipments;
export const selectIsShipmentsLoaded = (state: EntityContainer<ShipmentState>) => state[SLICE_NAME].isLoaded;

export const selectFilteredShipments = (state: EntityContainer<ShipmentState>) => state[SLICE_NAME].filteredShipments;
export const selectGroupedShipments = (state: EntityContainer<ShipmentState>) => state[SLICE_NAME].groupedShipments;
export const selectShipmentContainer = (state: EntityContainer<ShipmentState>) => state[SLICE_NAME].container || {};
export const selectShipmentEntity = (state: EntityContainer<ShipmentState>, id: string) => {
  const container = selectShipmentContainer(state);
  if (container[id]) return container[id];
  return null;
};
export const selectFilteredShipmentsAllDetails = (state: EntityContainer<ShipmentState>) => (
  state[SLICE_NAME].filteredShipmentsAllDetails
);
export const selectAreShipmentsLoading = (state: EntityContainer<ShipmentState>) => (
  state[SLICE_NAME].areShipmentsLoading
);

export const selectShipmentDetails = (state: EntityContainer<ShipmentState>) => state[SLICE_NAME].shipmentDetails;
export const selectAreShipmentDetailsLoading = (state: EntityContainer<ShipmentState>) => (
  state[SLICE_NAME].areShipmentDetailsLoading
);
export const selectShipmentFilterParams = (state: EntityContainer<ShipmentState>) => state[SLICE_NAME].filterParams;

export const shipmentsReducer = slice.reducer;