/* eslint-disable radix */
import React from 'react';
import { Stack } from 'react-bootstrap';
import { Shipment } from '../../../../../redux/models/shipment.models';
import { BillingItemData } from '../../../../../redux/models/billing.models';


const getPrice = (rate: BillingItemData | undefined) => {
  if (!rate) return 0;
  return rate.price * rate.quantity;
};

interface Props {
  shipment: Shipment;
  totalCost: number;
}

export default function ShipmentRevenue({ shipment, totalCost }: Props) {
  const baseRate = getPrice(shipment.data.base_rate) || 0;
  const accessorials = shipment.data.accessorials || [];
  const revenue = accessorials.reduce((total, rate) => total + getPrice(rate), baseRate);
  const profit = revenue - totalCost;
  const margin = (profit / revenue) * 100;
  return (
    <Stack gap={2}>
      <p>{`Shipment Price: $${revenue.toFixed(2)}`}</p>
      <p>{`Shipment Cost: $${totalCost.toFixed(2)}`}</p>
      <p>{`Profit: $${profit.toFixed(2)}`}</p>
      <p>{`Margin: ${parseInt(`${margin}`)}%`}</p>
    </Stack>
  );
}
