import React from 'react';
import { Container, Row, Spinner } from 'react-bootstrap';

interface Props {
  text?: string;
}

function Loader({ text }: Props) {
  return (
    <Container fluid className="vh-50 d-flex">
      <Row className="justify-content-center align-self-center text-center mx-auto">
        <Spinner animation="border" variant="primary" />
        {text ? <p className="mt-1">{text}</p> : null}
      </Row>
    </Container>
  );
}

Loader.defaultProps = {
  text: undefined,
};

export default Loader;
