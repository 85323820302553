import { useNavigate, useParams } from 'react-router-dom';

import InvoiceTableComponent from './InvoiceTableComponent';
import { InvoiceListView } from '../../../redux/models/invoice.models';
import { INVOICE_TABLE_HEADERS } from '../constants/invoicesTable.constants';

interface Props {
  invoices: InvoiceListView[];
}

export default function InvoiceTable({
  invoices,
}: Props) {
  const { orgCode } = useParams();
  const navigate = useNavigate();
  const openInvoicePage = (id: string) => navigate(`/${orgCode}/invoices/${id}`);

  return (
    <div className="bg-white">
      <InvoiceTableComponent
        headers={INVOICE_TABLE_HEADERS}
        formattedInvoices={invoices}
        openInvoicePage={openInvoicePage}
      />
    </div>
  );
}
