/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import { Driver, Location, Trip, TripPlan, DraftTrip, Appointment } from './planner.models';
import { DriverAvailability } from '../../models/driver.availability.models';
import { DateService } from '../../../utils/dateService';
import { EntityContainer, getDefaultDataModel } from '../../models/core.models';
import { TripBoardModel, MoveData } from '../../models/trip.models';
import { Driver as HopperDriver, Address } from '../../models/settings.models';
import { Board } from '../../models/board.models';

export function haversineDistance(coords1: Location, coords2: Location): number {
  const toRadian = (angle: number) => (Math.PI / 180) * angle;
  const earthRadius = 6371; // radius in kilometers

  const latDifference = toRadian(coords2.lat - coords1.lat);
  const longDifference = toRadian(coords2.lng - coords1.lng);

  const a =
    Math.sin(latDifference / 2) * Math.sin(latDifference / 2) +
    Math.cos(toRadian(coords1.lat)) * Math.cos(toRadian(coords2.lat)) *
    Math.sin(longDifference / 2) * Math.sin(longDifference / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadius * c;
}

function canTakeTrip(driver: Driver, trip: Trip): boolean {
  // Check if driver certifications match trip requirements
  // if (trip.containsDangerousGoods && !driver.dangerousGoodsCertified) return false;
  if (trip.containsAirCargo && !driver.airCargoCertified) return false;
  // Check if the trip fits into the driver's shift
  if (trip.start.time < driver.shiftStartTime || trip.end.time > driver.shiftEndTime) {
    return false;
  }

  // Check if the trip has a valid date
  // if (Object.keys(trip.start.time).length === 0 || Object.keys(trip.end.time).length === 0 ) return false;

  // Check if the trip overlaps with any already assigned trips
  for (const assignedTrip of driver.assignedTrips) {
    if (trip.start.time < assignedTrip.end.time && trip.end.time > assignedTrip.start.time) return false;
  }

  return true;
}

// Function to estimate travel time between two locations
export function calculateTravelTime(startLocation: Location, endLocation: Location): number {
  // Assume a fixed average speed to convert distance to time for simplicity.
  const averageSpeedKmPerHour = 30;
  const distanceKm = haversineDistance(startLocation, endLocation);
  const travelTimeHours = distanceKm / averageSpeedKmPerHour;
  return travelTimeHours * 60 * 60 * 1000; // Convert hours to milliseconds
}

// Calculate the "cost" of assigning a trip to a driver based on distance to start location and waiting time
function calculateAssignmentCost(driver: Driver, trip: Trip, lastTrip: Trip | null): number {
  const travelTimeToNextTrip = lastTrip ? calculateTravelTime(lastTrip.end.location, trip.start.location) : 0;
  const waitTimeAtNextTrip = lastTrip ?
    Math.max(0, trip.start.time.getTime() - lastTrip.end.time.getTime() - travelTimeToNextTrip) :
    0;

  // This is a simple additive cost function. Depending on the specific needs, the cost function
  // might need to account for these factors differently, e.g., by weighting them.
  return travelTimeToNextTrip + waitTimeAtNextTrip;
}

/**
 * Given a list of trips - find the next available driver.
 * This function is a trips first approach meaning trips will be spread evenly across drivers
 * @param trips 
 * @param drivers 
 * @returns 
 */
export function assignDriversToTrips(trips: Trip[], drivers: Driver[]) {
  // Sort trips by start time
  trips.sort((a, b) => a.start.time.getTime() - b.start.time.getTime());
  // Iterate over each trip to find a suitable driver
  for (const trip of trips) {
    let bestDriver: Driver | null = null;
    let minCost = Number.MAX_VALUE;

    // Find the best available driver that can take the trip
    for (const driver of drivers) {
      const isPossible = canTakeTrip(driver, trip);
      if (isPossible) {
        const lastTrip = driver.assignedTrips[driver.assignedTrips.length - 1] || null;
        const cost = calculateAssignmentCost(driver, trip, lastTrip);
        if (cost < minCost) {
          minCost = cost;
          bestDriver = driver;
        }
      }
    }

    // If we found a suitable driver, assign the trip
    if (bestDriver) {
      bestDriver.assignedTrips.push(trip);
    } else {
      // Handle case where no driver is found for a trip
    }
  }

  return drivers;
}

/**
 * Given a list of drivers - fill out their available day with trips
 * This function is a drivers first approach meaning a drivers day will be filled up before looking at the next driver.
 * This is a more economical way in terms of driver wages for dispatching.
 * @param trips 
 * @param drivers already sorted as per outside cartage and start time
 * @returns 
 */
export function assignTripsToDrivers(trips: Trip[], drivers: Driver[]) {
  const assignedTrips: EntityContainer<string> = {};
  // Sort trips by start time
  trips.sort((a, b) => a.start.time.getTime() - b.start.time.getTime());
  let unassignedTrips = trips;
  // Iterate over each trip to find a suitable driver
  for (const driver of drivers) {
    // let bestDriver: Driver | null = null;
    const minCost = Number.MAX_VALUE;
    const driverTrips: EntityContainer<Trip> = driver.assignedTrips.reduce((store, tr) => {
      return {
        ...store,
        [tr.id]: tr,
      }
    }, {});
    // Find the best available driver that can take the trip
    for (const trip of trips) {
      const prevAssigned = assignedTrips[trip.id];
      if (!prevAssigned) {
        const isPossible = canTakeTrip({
          ...driver,
          assignedTrips: Object.values(driverTrips),
        }, trip);
        if (isPossible) {
          const lastTrip = driver.assignedTrips[driver.assignedTrips.length - 1] || null;
          const cost = calculateAssignmentCost(driver, trip, lastTrip);
          if (cost < minCost) {
            assignedTrips[trip.id] = driver.id;
            unassignedTrips = unassignedTrips.filter((tr) => tr.id !== trip.id);
            driverTrips[trip.id] = trip;
          }
        }
      }
    }
    driver.assignedTrips = Object.values(driverTrips)
      .filter((tr) => assignedTrips[tr.id] === driver.id)
      .sort((a, b) => a.start.time.getTime() - b.start.time.getTime());
  }
  return drivers;
}

export const findDriverAvailability = (driverId: string, date: string, availability: DriverAvailability[]) => {
  const weekday = DateService.getWeekday(date);
  return availability.find((data) => {
    const isDriver = data.data.driver?.entity_id === driverId;
    return isDriver && data.data.weekday === weekday;
  });
};

export const createDraft = (item: number, board: Board, from: string, to: string): TripPlan => {
  return {
    ...getDefaultDataModel({
      name: `Draft ${item}`,
      notes: '',
      plans: {},
      board,
      start_date: from,
      end_date: to,
      unassigned_trip_ids: [],
    })
  }
};

export const getUpdateShiftDate = (timeString: string, toDate: string) => {
  const dt = DateService.updateTimeStrToDate(timeString, toDate);
  return new Date(dt);
}

export const getTimeForDate = (time: string) => {
  const dt = DateService.getMomentDate(new Date());
  const st = dt.format('YYYY-MM-DD');
  return `${st}T${time}:00`;
}

/**
 * Sort by:
 * - not outside cartage
 * - start time
 * - name
 * @param date 
 * @param drivers 
 * @param availability 
 * @returns 
 */
export const sortPlannedDrivers = (date: string, drivers: HopperDriver[], availability: DriverAvailability[]) => {
  const defaultStore: EntityContainer<DriverAvailability[]>= {};
  const avStore = availability
    .filter((av) => av.data && av.data.driver)
    .reduce((store, av) => {
      const driverId = av.data.driver.entity_id;
      const existing = store[driverId] || [];
      existing.push(av);
      const sortedEx = [...existing].sort((a, b) => {
        const aTime = getUpdateShiftDate(a.data.start_time, date).getTime();
        const bTime = getUpdateShiftDate(b.data.start_time, date).getTime();
        return aTime - bTime;
      });
      return {
        ...store,
        [driverId]: sortedEx,
      }
    }, defaultStore);
  return [...drivers].sort((a, b) => {
    // First, compare by outside cartage (non outside cartage drivers come first)
    if (!a.data.outside_cartage && b.data.outside_cartage) return -1;
    if (a.data.outside_cartage && !b.data.outside_cartage) return 1;

    const aTimes = avStore[a.entity_id] || [];
    const aTime = aTimes.length ? aTimes[0] : null;

    const bTimes = avStore[b.entity_id] || [];
    const bTime = bTimes.length ? bTimes[0] : null;
    
    if (aTime !== null && bTime === null) return -1;
    if (aTime == null && bTime !== null) return 1;
    
    // If outsideCartage is the same, then sort by shift start time
    if (aTime && bTime) {
      const aNightShift = DateService.spansMidnight(aTime.data.start_time, aTime.data.finish_time);
      const bNightShift = DateService.spansMidnight(bTime.data.start_time, bTime.data.finish_time);
      if (aNightShift && !bNightShift) return 1;
      if (!aNightShift && bNightShift) return -1;
      const aStart = getUpdateShiftDate(aTime.data.start_time, date).getTime();
      const bStart = getUpdateShiftDate(bTime.data.start_time, date).getTime();
      if (aStart < bStart) return -1;
      if (aStart > bStart) return 1;
    }
    const aName = a.data.samsara_name || '';
    const bName = b.data.samsara_name || '';
    return aName.localeCompare(bName);
  })
};

const getLocation = (addressId: string, addresses: EntityContainer<Address>): Location => {
  const addr = addresses[addressId];
  return {
    name: addr.data.samsara_name,
    lat: addr.data.samsara_latitude,
    lng: addr.data.samsara_longitude,
  };
};

const getAppt = (move: MoveData, addresses: EntityContainer<Address>): Appointment => {
  return {
    time: new Date(move.scheduled_departure_time || ''),
    location: getLocation(move.destination_id, addresses),
  };
};

export const optimiseTripsHandler = (
  date: string,
  availability: DriverAvailability[],
  drivers: HopperDriver[],
  trips: EntityContainer<TripBoardModel>,
  addresses: EntityContainer<Address>,
  greedy: boolean,
) => {
  const homeYard = drivers.filter((dr) => dr.data.home_yard_id);
  const sortedDrs = sortPlannedDrivers(date, homeYard, availability);  
  const defaultStartTime = getTimeForDate('07:00');
  const defaultFinishTime = getTimeForDate('17:00');
  const drs: Driver[] = sortedDrs.map((driver) => {
    const av = findDriverAvailability(driver.entity_id, date, availability);
    return {
      id: driver.entity_id,
      name: driver.data.samsara_name,
      homeLocation: getLocation(driver.data.home_yard_id || '', addresses),
      shiftStartTime: getUpdateShiftDate(av?.data.start_time || defaultStartTime, date),
      shiftEndTime: getUpdateShiftDate(av?.data.finish_time || defaultFinishTime, date),
      dangerousGoodsCertified: true,
      airCargoCertified: true,
      outsideCartage: driver.data.outside_cartage || false,
      assignedTrips: [],
    }
  })
  // sort trips by start time
  const trs: Trip[] = Object.values(trips).filter((tr) => tr.moves.length > 1).map((tr) => {
    const moves = tr.moves || [];
    const sorted = [...moves].sort((a, b) => a.position - b.position);
    const start = sorted[0];
    const end = sorted[sorted.length - 1];
    return {
      id: tr.id,
      start: getAppt(start, addresses),
      end: getAppt(end, addresses),
      containsDangerousGoods: true,
      containsAirCargo: true,
    }
  });
  if (greedy) return assignTripsToDrivers(trs, drs);
  return assignDriversToTrips(trs, drs);
};

const createUnique = (ids: string[]) => {
  const unassignedSet = new Set([...ids]);
  return Array.from(unassignedSet);
}

export const getDriverDraftTrips = (trips: TripBoardModel[], driverId: string): string[] => {
  const assigned = trips
    .filter((trip) => trip.driverId === driverId)
    .map((tr) => tr.id);
  return createUnique(assigned);
};

const createDriverAssignments = (item: [string, string[]], tripId: string, driverId: string) => {
  const [key, prevDrafts] = item;
  const sameDriver = driverId === key;
  let exists = false;
  // remove the previously assigned trip from any drivers
  const existingDrafts: string[] = prevDrafts.filter((prev) => prev !== tripId);
  if (sameDriver) {
    // check the trip doesn't already exist
    exists = existingDrafts.some((tr) => tr === tripId);
  }
  return sameDriver && !exists ? [...existingDrafts, tripId] : [...existingDrafts];
}

const createAssignments = (plan: TripPlan, driverId: string, tripId: string): EntityContainer<string[]> => {
  const assignments = Object.entries(plan.data.plans).reduce((store, item) => {
    const [key] = item;
    const drafts = createDriverAssignments(item, tripId, driverId);
    return {
      ...store,
      [key]: drafts,
    };
  }, {});
  const planContainsDriver = plan.data.plans[driverId];
  if (!planContainsDriver) {
    return {
      ...assignments,
      [driverId]: [tripId],
    };
  }
  return assignments;
}

/// If `isUnassigned` add to `unassignedIds`
/// - if is assigned then remove from `unassignedIds` 
const updateUnassigned = (isUnassigned: boolean, tripId: string, unassignedIds: string[]) => {
  if (isUnassigned) {
    return unassignedIds.filter((id) => id !== tripId);
  }
  return [...unassignedIds, tripId];
}

export const updateDraft = (plan: TripPlan, driverId: string, tripId: string): TripPlan => {
  const assignments = createAssignments(plan, driverId, tripId);
  const isUnassigned = driverId !== '';
  const originalUnassigned = plan.data.unassigned_trip_ids || [];
  const unassigned = updateUnassigned(isUnassigned, tripId, originalUnassigned);
  return {
    ...plan,
    data: {
      ...plan.data,
      unassigned_trip_ids: createUnique(unassigned),
      plans: { ...assignments }
    },
  };
}

export const mergePlan = (
  plan: TripPlan, trips: TripBoardModel[], drivers: EntityContainer<HopperDriver>
): TripPlan => {
  const updatedDriverPlans = Object.entries(plan.data.plans)
    .reduce((store, item) => {
      const [key, drafts] = item;
      const newDrafts = getDriverDraftTrips(trips, key);
      return {
        ...store,
        [key]: createUnique([...drafts, ...newDrafts]),
      };
    }, {});
  const unassignedTripIds = trips
    .filter((trip) => {
      const driverId = trip.driverId || '';
      const noDriver = driverId === '';
      const exists = noDriver || drivers[driverId];
      return noDriver || !exists;
    })
    .map((trip) => trip.id);
  const prevUnassigned = plan.data.unassigned_trip_ids || [];
  const unassigned = createUnique([...unassignedTripIds, ...prevUnassigned]);
  return {
    ...plan,
    data: {
      ...plan.data,
      unassigned_trip_ids: unassigned,
      plans: updatedDriverPlans,
    },
  };
}

export const createDraftTripPlan = (
  plan: TripPlan,
  board: Board,
  tripContainer: EntityContainer<TripBoardModel>,
  drivers: HopperDriver[],
  from: string,
  to: string,
): TripPlan => {
  const planData = plan || createDraft(1, board, from, to);
  const trips = Object.values(tripContainer);
  const unassigned = getDriverDraftTrips(trips, '');
  const assigned: EntityContainer<string[]> = drivers.reduce((store, driver) => {
    const driverTrips: string[] = getDriverDraftTrips(trips, driver.entity_id);
    return {
      ...store,
      [driver.entity_id]: driverTrips,
    }
  }, {});
  return {
    ...planData,
    data: {
      ...planData.data,
      board,
      unassigned_trip_ids: createUnique(unassigned),
      plans: {
        ...assigned,
      },
    },
  };
};

export const reOrderDrafts = (drafts: DraftTrip[], oldIndex: number, newIndex: number) => {
  // Check if the oldIndex is within the array bounds
  if (oldIndex < 0 || oldIndex >= drafts.length) {
      return drafts;
  }

  // Check if the newIndex is within the draftsay bounds
  if (newIndex < 0 || newIndex > drafts.length) {
      return drafts;
  }

  const selected = drafts[oldIndex];
  // Remove the string from the old position
  drafts.splice(oldIndex, 1);

  // Insert the string at the new position
  drafts.splice(newIndex, 0, selected);

  return [...drafts];
}

export const getUnassignedTrips = (plan: TripPlan, store: EntityContainer<TripBoardModel>) => {
  const drafts: EntityContainer<string> = Object.values(plan.data.plans)
    .flatMap((draft) => draft)
    .reduce((container, draft) => {
      return {
        ...container,
        [draft]: draft,
      };
    }, {});
  const unassigned: EntityContainer<string> = Object.values(plan.data.unassigned_trip_ids)
    .reduce((container, draft) => {
      return {
        ...container,
        [draft]: draft,
      };
    }, {});
  return Object.values(store).filter((trip) => !drafts[trip.id] && !unassigned[trip.id]);
};
