import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';

const CANCEL = 'Cancel';

interface Props {
  shouldShowModal: boolean;
  isSaving: boolean;
  saveItem: (name: string) => void;
  cancelSave: () => void;
}

export default function AddTemplateModal({
  shouldShowModal,
  isSaving,
  saveItem,
  cancelSave,
}: Props) {
  const [templateName, setTemplateName] = useState('');
  return (
    <Modal
      show={shouldShowModal}
      onHide={cancelSave}
      centered
      data-cy="delete_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-0">Template Name</p>
        <input type="text" value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
      </Modal.Body>
      <Modal.Footer>
        <Button id="delete_modal__cancel_btn" variant="secondary" onClick={cancelSave}>
          {CANCEL}
        </Button>
        <Button
          id="add_template_modal_btn"
          data-cy="add_template_modal_btn"
          variant="primary"
          onClick={() => saveItem(templateName)}
        >
          Create
          {isSaving && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              style={{ marginLeft: '4px' }}
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
