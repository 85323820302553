/* eslint-disable */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddModal from '../../../components/modals/AddModal';
import RadioInput from '../../../components/shared/inputs/RadioInput';
import { TripDetails } from '../../../redux/models/trip.models';
import { Api } from '../../../services/services';
import { useDispatch } from 'react-redux';
import { resetShipments } from '../../../redux/slices/shipment-list/shipment-list';

interface Props {
  shouldShow: boolean;
  trip: TripDetails;
  onCancel: () => void;
}

export default function DeleteTripModal({
  shouldShow,
  trip,
  onCancel,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const [option, setOption] = useState('');
  const isDisabled = option === '';
  const onSelect = (_: string, value: string) => {
    setOption(value)
  };
  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      const deleteShipments = option === '2';
      const boardId = trip.data.board_id;
      const shipmentIds = trip.data.shipments.map((shipment) => shipment.entity_id);
      await Api.Trips.deleteTrip(trip, deleteShipments, shipmentIds);
      dispatch(resetShipments());
      navigate(`/${orgCode}/boards/${boardId}`);
    } catch (error: any) {
      const message = error?.description || "Couldn't Delete Trip.";
      toast(message, { type: 'error' });
    } finally {
      setIsDeleting(false);
    }
  };
  return (
    <AddModal
      title="Delete Trip"
      btnTitle="Delete"
      variant="danger"
      spinner="light"
      saveDisabled={isDisabled}
      shouldShowModal={shouldShow}
      isSaving={isDeleting}
      handleSave={handleDelete}
      cancelSave={onCancel}
    >
      <RadioInput id="1" label="Delete trip" onChange={onSelect} />
      <RadioInput id="2" label="Delete trip and shipments" onChange={onSelect} />
    </AddModal>
  );
}
