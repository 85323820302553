import React, { useState } from 'react';
import { Button, Modal, Row, Spinner } from 'react-bootstrap';
import { ControlInput } from '../../../../components/shared/Input';

const CANCEL = 'Cancel';

const PARAM_PROPS = {
  board_id: 'board_id',
  group_id: 'group_id',
  name: 'name',
  shipment_id: 'shipment_id',
};

const PAGE = 'create_trip_template_input';

interface Props {
  shouldShowModal: boolean;
  isSaving: boolean;
  saveItem: (name: string) => void;
  cancelSave: () => void;
}

export default function AddShipmentTypeModal({
  shouldShowModal,
  isSaving,
  saveItem,
  cancelSave,
}: Props) {
  const [name, setName] = useState('');
  const isDisabled = name === '';
  const handleChange = (_: string, value: string) => {
    setName(value);
  };
  const handleResetForm = () => {
    setName('');
  };
  const handleSave = async () => {
    await saveItem(name);
    handleResetForm();
  };
  const handleCancel = () => {
    handleResetForm();
    cancelSave();
  }
  return (
    <Modal
      show={shouldShowModal}
      onHide={cancelSave}
      centered
      data-cy="delete_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <ControlInput
            dataCy={`${PAGE}_${PARAM_PROPS.name}`}
            name={PARAM_PROPS.name}
            type="text"
            page={PAGE}
            value={name}
            labelText="Name"
            handleChange={handleChange}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button id="delete_modal__cancel_btn" variant="secondary" onClick={handleCancel}>
          {CANCEL}
        </Button>
        <Button
          id="add_template_modal_btn"
          data-cy="add_template_modal_btn"
          variant="primary"
          disabled={isDisabled}
          onClick={handleSave}
        >
          Create
          {isSaving && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              style={{ marginLeft: '4px' }}
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
