import React, { useMemo } from 'react';

import useSettingsState from '../hooks/useSettingsState';

import { THEME } from '../constants';

const initialState = {
  theme: THEME.DEFAULT,
};

const ThemeContext = React.createContext(initialState);

function ThemeProvider({ children }) {
  const [theme, setTheme] = useSettingsState('theme', THEME.DEFAULT);

  const providerValue = useMemo(() => ({
    theme,
    setTheme,
  }), [theme, setTheme]);

  return (
    <ThemeContext.Provider value={providerValue}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
