import React, { useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";

import { Calendar } from "react-feather";
import { DateService } from "../../../../utils/dateService";

enum DateRange {
  Today = 'today',
  Tomorrow = 'tomorrow',
  Yesterday = 'yesterday',
  ThisWeek = 'this-week',
  LastWeek = 'last-week',
}

const getName = (date: string) => {
  const words = date.split('-');
  const capitalised = words.map(word => 
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return capitalised.join(' ');
}

interface Props {
  title: string;
  handleDate: (from: string, to: string) => void;
}

export default function Header({ title, handleDate }: Props) {
  const [range, setRange] = useState(DateRange.Today);  
  const today = new Date();
  const tomorrow = DateService.addDaysToDateISO(today, 1);
  const yesterday = DateService.subtractDaysFromDateISO(today, 1);
  const lastWeek = DateService.subtractWeeksFromDateISO(today, 1);

  const handleToday = () => {
    setRange(DateRange.Today);
    const from = DateService.getStartOfTheDayISO();
    const to = DateService.getEndOfTheDayISO();
    handleDate(from, to);
  };
  const handleTomorrow = () => {
    setRange(DateRange.Tomorrow);
    const from = DateService.getStartOfTheDayISO(tomorrow);
    const to = DateService.getEndOfTheDayISO(tomorrow);
    handleDate(from, to);
  };
  const handleYesterday = () => {
    setRange(DateRange.Yesterday);
    const from = DateService.getStartOfTheDayISO(yesterday);
    const to = DateService.getEndOfTheDayISO(yesterday);
    handleDate(from, to);
  };
  const handleThisWeek = () => {
    setRange(DateRange.ThisWeek);
    const from = DateService.getStartOfTheWeekISO();
    const to = DateService.getEndOfTheWeekISO();
    handleDate(from, to);
  };
  const handleLastWeek = () => {
    setRange(DateRange.LastWeek);
    const from = DateService.getStartOfTheWeekISO(lastWeek);
    const to = DateService.getEndOfTheWeekISO(lastWeek);
    handleDate(from, to);
  };
  return (
    <Row className="mb-2 mb-xl-3">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>{title}</h3>
      </Col>

      <Col xs="auto" className="ms-auto text-end mt-n1">
        <Dropdown className="d-inline me-2">
          <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
            <Calendar className="feather align-middle mt-n1" /> {getName(range)}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleToday}>Today</Dropdown.Item>
            <Dropdown.Item onClick={handleTomorrow}>Tomorrow</Dropdown.Item>
            <Dropdown.Item onClick={handleYesterday}>Yesterday</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleThisWeek}>This Week</Dropdown.Item>
            <Dropdown.Item onClick={handleLastWeek}>Last Week</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};
