/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col } from 'react-bootstrap';

import { ControlInput, SelectInput } from '../../../../../components/shared/Input';
import { Shipment, ShipmentTypeFieldData } from '../../../../../redux/models/shipment.models';
import { EntityContainer } from '../../../../../redux/models/core.models';
import { getName, isFieldError } from './shipment.form.utils';
import { ShipmentErrorLabel } from './ShipmentField';

interface Props {
  shipmentDetails: Shipment;
  uomSelectOptions: any;
  handleChange: any;
  page: string;
  disabled: boolean;
  fields: ShipmentTypeFieldData;
  validation: EntityContainer<boolean>;
}

export default function QuantityForm({
  shipmentDetails,
  uomSelectOptions,
  handleChange,
  page,
  disabled,
  validation,
  fields,
}: Props) {
  const qtyField = fields.quantity;
  const qtyUomField = fields.quantity_uom_id;
  return (
    <Col md={4}>
      <div className="d-flex">
        <div className="col-4 pe-2">
          <ControlInput
            labelText={getName(qtyField)}
            name={qtyField.key}
            page={page}
            type="number"
            value={`${shipmentDetails.data.quantity}`}
            handleChange={handleChange}
            dataCy="shipment_quantity"
            disabled={disabled}
          />
          {isFieldError(qtyField, validation) && (
            <ShipmentErrorLabel field={qtyField} />
          )}
        </div>
        <div className="col-8 ps-2">
          <SelectInput
            labelText={getName(qtyUomField)}
            name={qtyUomField.key}
            page={page}
            value={shipmentDetails.data.quantity_uom_id}
            options={uomSelectOptions}
            handleChange={handleChange}
            dataCy="shipment_quantity_unit"
            disabled={disabled}
          />
          {isFieldError(qtyUomField, validation) && (
            <ShipmentErrorLabel field={qtyUomField} />
          )}
        </div>
      </div>
    </Col>
  );
}
