/* eslint-disable radix */
import { getDistanceAndTime } from "../../pages/trips/Moves/utils/moves.utils";
import { EntityContainer } from "../../redux/models/core.models";
import { Address, Cost, Driver, UOM, Vehicle } from "../../redux/models/settings.models";
import { Move, TripDetails } from "../../redux/models/trip.models";
import { getValueFromLocalStorage, saveValueToLocalStorage } from "../../utils/core.utils";

export interface CostUnit {
  name: string;
  qty: number;
  unit: string;
  rate: number;
  total: number;
}

export interface TripCost {
  driver: CostUnit | null;
  vehicle: CostUnit | null;
  burn: CostUnit | null;
  total: number;
}

export const getFuelBurnLitres = (vehicle: Vehicle | undefined, km: number) => {
  // effeciency in L/ 100km
  const efficiency = vehicle?.data.efficiency || 0;
  const litres = (efficiency / 100) * km;
  return litres;
};

export const getDriverCost = (
  trip: TripDetails,
  moves: Move[],
  drivers: EntityContainer<Driver>,
  // vehicles: EntityContainer<Vehicle>,
  addresses: EntityContainer<Address>,
  costs: EntityContainer<Cost>,
  uoms: EntityContainer<UOM>,
): CostUnit => {
  const trailersArr = moves.flatMap((move) => {
    const trs = move.data.trailer_ids || [];
    return Array.from(new Set(trs));
  }).filter((trailerId) => trailerId !== '');

  const trailersSet = new Set([...trailersArr]);
  const trailerIds = Array.from(trailersSet);
  const noTrailers = trailerIds.length || 0;
  const driverId = trip.data.driver_id || '';
  const driver = drivers[driverId];
  const driverName = driver?.data.samsara_name || '';
  const driverCostId = driver?.data?.cost_id || '';
  const driverCost = costs[driverCostId];
  const driverRate = driverCost?.data.rate || 0;
  const driverCostUomId = driverCost?.data.unit_id || '';
  const driverUom = uoms[driverCostUomId];
  const driverUomName = driverUom?.data.name || '';
  const isTrailerRate = driverUom?.data.unit === 'trailer';
  // const truckId = trip.data.vehicle_id || '';
  // const truck = vehicles[truckId];
  // const truckName = truck?.data.samsara_name || '';
  const trailerTotal = noTrailers * driverRate;
  const total = getDistanceAndTime(moves, addresses);
  // const km = parseInt(total.distance.toFixed(1));
  const hrs = total.time;
  const driverHrlyTotal = (driverRate * (total.time));
  const driverQty = isTrailerRate ? noTrailers : hrs;
  const driverTotal = isTrailerRate ? trailerTotal : driverHrlyTotal;
  return {
    name: driverName,
    qty: driverQty,
    unit: driverUomName,
    rate: driverRate,
    total: driverTotal,
  };
}

const getVehicleBurn = (
  moves: Move[],
  truck: Vehicle | undefined,
  addresses: EntityContainer<Address>,
  fuelRate: number,
): CostUnit | null => {
  if (!truck || moves.length === 0) return null;
  if (!truck.data.efficiency || truck.data.efficiency === 0) return null;
  const total = getDistanceAndTime(moves, addresses);
  const km = parseInt(total.distance.toFixed(1));
  const truckName = truck?.data.samsara_name || '';
  const fuelBurnLitres = getFuelBurnLitres(truck, km);
  const fuelBurnTotal = fuelRate * fuelBurnLitres;
  return {
    name: truckName,
    qty: fuelBurnLitres,
    unit: 'Litres',
    rate: fuelRate,
    total: fuelBurnTotal,
  };
};

const getCost = (
  name: string,
  cost: Cost | undefined | null,
  trip: TripDetails,
  moves: Move[],
  addresses: EntityContainer<Address>,
  uoms: EntityContainer<UOM>,
): CostUnit | null => {
  if (!cost) return null;
  const rate = cost?.data.rate || 0;
  const costUomId = cost?.data.unit_id || cost?.data.unit?.entity_id || '';
  const uom = uoms[costUomId];
  const uomName = uom?.data.name || '';

  if (uom?.data.unit === 'trailer') {
    const trailersArr = moves.flatMap((move) => {
      const trs = move.data.trailer_ids || [];
      return Array.from(new Set(trs));
    }).filter((trailerId) => trailerId !== '');
  
    const trailersSet = new Set([...trailersArr]);
    const trailerIds = Array.from(trailersSet);
    const noTrailers = trailerIds.length || 0;
    const trailerTotal = noTrailers * rate;
    return {
      name,
      qty: noTrailers,
      unit: uomName,
      rate,
      total: trailerTotal,
    };
  }

  if (uom?.data.symbol === 'km') {
    const metres = trip.data.estimated_distance_metres || 0;
    const distance = metres / 1000;
    const km = parseInt(distance.toFixed(1));
    const driverTotal = km * rate;
    return {
      name,
      qty: km,
      unit: uomName,
      rate,
      total: driverTotal,
    };
  }

  if (uom?.data.symbol === 'hr') {
    const total = getDistanceAndTime(moves, addresses);
    const hrs = total.time;
    const driverHrlyTotal = (rate * (total.time));
    const driverTotal = driverHrlyTotal;
    return {
      name,
      qty: hrs,
      unit: uomName,
      rate,
      total: driverTotal,
    };
  }
  return null;
}

export const getTripCost = (
  trip: TripDetails,
  moves: Move[],
  drivers: EntityContainer<Driver>,
  vehicles: EntityContainer<Vehicle>,
  addresses: EntityContainer<Address>,
  costs: EntityContainer<Cost>,
  uoms: EntityContainer<UOM>,
  fuelRate: number,
): TripCost => {
  
  const truckId = trip.data.vehicle_id || '';
  const truck = vehicles[truckId];
  const truckName = truck?.data.samsara_name || '';
  const truckCostId = truck?.data?.cost_id || '';
  const truckCost = costs[truckCostId];

  const driverId = trip.data.driver_id || '';
  const driver = drivers[driverId];
  const driverName = driver?.data.samsara_name || '';
  const driverCostId = driver?.data?.cost_id || '';
  const driverCost = costs[driverCostId];

  const driverUnit = getCost(
    driverName,
    driverCost,
    trip,
    moves,
    addresses,
    uoms
  );

  const vehicleUnit = getCost(
    truckName,
    truckCost,
    trip,
    moves,
    addresses,
    uoms
  );

  const vehicleBurnUnit = getVehicleBurn(
    moves,
    truck,
    addresses,
    fuelRate,
  );
  const driverTotal = driverUnit?.total || 0;
  const vehicleTotal = vehicleUnit?.total || 0;
  const burnTotal = vehicleBurnUnit?.total || 0;
  return {
    driver: driverUnit,
    vehicle: vehicleUnit,
    burn: vehicleBurnUnit,
    total: driverTotal + vehicleTotal + burnTotal,
  };
}

export const getShipmentMoves = (moves: Move[], shipmentId: string) => {
  return moves.filter((move) => {
    const moveShipmentIds = move.data.shipment_ids || [];
    return moveShipmentIds.includes(shipmentId);
  });
}

const FUEL_RATE_LOCAL_STORAGE_KEY = 'fuel-rate';

export const saveDefaultFuelRate = (rate: number) => {
  saveValueToLocalStorage(FUEL_RATE_LOCAL_STORAGE_KEY, rate);
};

export const loadDefaultFuelRate = () => {
  const rate = getValueFromLocalStorage(FUEL_RATE_LOCAL_STORAGE_KEY);
  return Number(rate);
};
