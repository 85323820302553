import React from 'react';
import { Spinner } from 'react-bootstrap';

interface Props {
  variant?: string;
}

function ButtonSpinner({ variant }: Props) {
  return <Spinner animation="border" variant={variant} size="sm" />;
}

ButtonSpinner.defaultProps = {
  variant: 'primary',
};

export default ButtonSpinner;