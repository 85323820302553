/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

interface Props {
  title: string;
  as: string;
  handleClick: () => void;
}

export default function EditableTitleLabel({ title, as, handleClick }: Props) {
  if (as === 'h1') {
    return <h1 onClick={handleClick}>{title}</h1>
  }
  if (as === 'h2') {
    return <h2 onClick={handleClick}>{title}</h2>
  }
  if (as === 'h3') {
    return <h3 onClick={handleClick}>{title}</h3>
  }
  if (as === 'h4') {
    return <h4 onClick={handleClick}>{title}</h4>
  }
  if (as === 'h5') {
    return <h5 onClick={handleClick}>{title}</h5>
  }
  if (as === 'h6') {
    return <h6 onClick={handleClick}>{title}</h6>
  }
  return <p onClick={handleClick}>{title}</p>
};
