export const INVOICES = 'invoices';
export const MENTIONS = 'mentions';
export const SETTINGS = 'settings';
export const DRIVER_AVAILABILITY = 'driver_availability';
export const NOTIFICATIONS = 'notifications';
export const ORGANIZATION = 'organization';
export const PLANNER = 'planner';
export const ROLES = 'roles';
export const SHIPMENT_LIST = 'shipment_list';
export const TRAILER_CHANGES = 'trailerChanges';
export const TRIPS = 'trips';