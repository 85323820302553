import React from 'react';
import { ContactReadPermissions, ContactWritePermissions } from '../../shared/permissions/permissions.utils';
import { getSelectedOptions } from '../../../utils/core.utils';
import { DropdownOptionsModel } from '../../shared/SelectInput.models';
import EntityInput from '../base/inputs/EntityInput';
import { Api } from '../../../services/services';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import { DataModel } from '../../../redux/models/core.models';
import { ContactData, ContactType } from '../../../redux/models/contact.models';

interface Props {
  value: string;
  page: string;
  prop: string;
  label?: string;
  disabled: boolean;
  allowAdd?: boolean;
  options: DropdownOptionsModel[];
  onChange: (prop: string, value: string) => void;
  onNewEntity: (prop: string, vehicle: DataModel<ContactData>) => void;
}

function ContactInput({ value, page, prop, label, disabled, allowAdd, options, onChange, onNewEntity }: Props) {
  const handleCreateData = (_: string, val: string): ContactData => {
    return {
      name: val,
      types: [ContactType.Customer],
    };
  };
  return (
    <EntityInput
      disabled={disabled}
      labelText={label || ''}
      name={prop}
      page={page}
      value={value}
      options={getSelectedOptions(value, options)}
      entity="contact"
      dataField="name"
      readResources={ContactReadPermissions}
      writeResources={ContactWritePermissions}
      api={Api.Contacts}
      defaultAdd={allowAdd || false}
      settingName={SETTINGS_NAMES.CONTACTS}
      syncSettings
      onChange={onChange}
      createData={handleCreateData}
      onNewEntity={onNewEntity}
    />
  )
}

ContactInput.defaultProps = {
  label: 'Customer',
  allowAdd: false,
}

export default ContactInput;
