import { Form } from 'react-bootstrap';
import { ShipmentListView } from '../../../redux/slices/shipment-list/shipment-list.models';
import { EntityContainer } from '../../../redux/models/core.models';
import { ShipmentType } from '../../../redux/models/shipment.models';

const NO_TYPE = 'No type';
const NO_CUSTOMER = 'No customer';
const getBillingType = (shipment: ShipmentListView, shipmentTypes: EntityContainer<ShipmentType>) => {
  const id = shipment.type_id;
  if (!id) return NO_TYPE;
  return shipmentTypes[id]?.data.name || NO_TYPE;
}

interface Props {
  shipment: ShipmentListView;
  selectedShipments: string[];
  shipmentTypes: EntityContainer<ShipmentType>;
  selectShipment: (e: React.ChangeEvent<HTMLInputElement>, shipmentId: string) => void;
}

export default function ShipmentsModalTableItem({ shipment, selectedShipments, shipmentTypes, selectShipment }: Props) {
  return (
    <tr className="shipmentsModal-table-row">
      <td>{shipment.shipment_no}</td>
      <td>{shipment.purchase_order_no}</td>
      <td>{shipment.container_no}</td>
      <td>{shipment.customer || NO_CUSTOMER }</td>
      <td>{getBillingType(shipment, shipmentTypes)}</td>
      <td>{shipment.start_date}</td>
      <td>
        <Form.Group data-cy="add_shipment_to_trip">
          <Form.Check
            checked={selectedShipments.includes(shipment.id)}
            className="shipments-table-checkbox"
            type="checkbox"
            onChange={(e) => selectShipment(e, shipment.id)}
          />
        </Form.Group>
      </td>
    </tr>
  )
};