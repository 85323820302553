import React, { useEffect } from 'react';
import {
  Badge, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';

import useTheme from '../../hooks/useTheme';
import useSidebar from '../../hooks/useSidebar';
import useLayout from '../../hooks/useLayout';
import useAuth from '../../hooks/useAuth';

import {
  SIDEBAR_POSITION, SIDEBAR_BEHAVIOR, LAYOUT, THEME,
} from '../../constants';
import { TEXTS } from './landing.constants';

import screenshotMixed from '../../assets/img/screenshots/mixed.jpg';
import Analytics from '../../utils/analytics';

function Landing() {
  const { signIn, isLoggingIn, isAuthenticated, isInitialized } = useAuth();
  const { setTheme } = useTheme();
  const { setPosition, setBehavior } = useSidebar();
  const { setLayout } = useLayout();
  const navigate = useNavigate();
  useEffect(() => {
    setTheme(THEME.DEFAULT);
    setPosition(SIDEBAR_POSITION.LEFT);
    setBehavior(SIDEBAR_BEHAVIOR.STICKY);
    setLayout(LAYOUT.FLUID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignIn = async () => {
    try {
      if (isAuthenticated && isInitialized) {
        navigate('/projects');
      } else {
        signIn();
      }
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong';
      Analytics.capture(error);
      toast(errorMessage, { type: 'error' });
    }
  };

  if (isAuthenticated && isInitialized) {
    return <Navigate to="/projects" />
  }
  return (
    <section className="landing-section">
      <Container>
        <Row className="align-items-center">
          <Col lg="5" className="mx-auto">
            <Badge bg="" className="badge-soft-primary p-1 mt-4">
              {TEXTS.VERSION}
            </Badge>
            <h1 className="mb-4 mt-1">{TEXTS.TITLE}</h1>
            <p className="text-lg">{TEXTS.DESCRIPTION}</p>
            <div className="my-4">
              <button
                type="button"
                className="btn btn-primary btn-lg me-2"
                disabled={!isInitialized}
                onClick={handleSignIn}
              >
                {isLoggingIn || !isInitialized ? <Spinner size="sm" animation="border" variant="light" /> : TEXTS.LOGIN}
              </button>
            </div>
          </Col>
          <Col lg="7" className="d-none d-lg-flex mx-auto text-center">
            <div className="landing-intro-screenshot pb-3">
              <img src={screenshotMixed} alt="Aquadash Dispatching Portal" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Landing;
