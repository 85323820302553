import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';

import { FeatureResource, ResourcePermission } from '../../../../redux/models/feature.flags.models';
import { TableHeader } from '../models/models';
import Permission from '../../../../components/shared/permissions/Permission';
import ShipmentsTableRow from '../ShipmentsTableRow';
import { selectShipmentListViewItem } from '../../../../redux/slices/shipment-list/shipment-list';
import { EntityContainer } from '../../../../redux/models/core.models';
import { ShipmentListState } from '../../../../redux/slices/shipment-list/shipment-list.models';
import { formatShipment } from '../../../../redux/slices/shipment-list/shipment-list.utils';
import { Address } from '../../../../redux/models/settings.models';
import { Contact } from '../../../../redux/models/contact.models';
import { BillingType } from '../../../../redux/models/billing.models';

const WritePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Write,
};

interface Props {
  id: string;
  index: number;
  headers: TableHeader[];
  selectedShipments: string[];
  addresses: EntityContainer<Address>;
  contacts: EntityContainer<Contact>;
  billingTypes: EntityContainer<BillingType>;
  openShipmentPage: (id: string) => void;
  selectShipment: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void; 
}

export default function ShipmentListViewTableRow({
  id, index, headers, selectedShipments, addresses, contacts, billingTypes, openShipmentPage, selectShipment
}: Props) {
  const shipment = useSelector((state: EntityContainer<ShipmentListState>) => selectShipmentListViewItem(state, id));
  const formatted = formatShipment(shipment, contacts, billingTypes, addresses);
  return (
    <tr
      key={id}
      id={`shipments-shipmentTable__tableRow-${index}`}
      className="shipments-table-row"
      data-cy="shipments-table-row"
      onClick={() => openShipmentPage(id)}
    >
        <td
          role="gridcell"
          className="invoice-checkbox-col"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Permission resources={WritePermissions}>
            <Form.Group data-cy={`multi_invoices_checkbox_${index}`}>
              <Form.Check
                checked={selectedShipments?.includes(id)}
                className="invoices-checkbox"
                type="checkbox"
                onChange={(e) => selectShipment(e, id)}
              />
            </Form.Group>
          </Permission>
        </td>
      {headers.slice(0).map((header) => (
        <td key={`${id}-${header.key}`}>
          {shipment && <ShipmentsTableRow shipment={formatted} prop={header.key} />}
        </td>
      ))}
    </tr>
  )
}