import { Card } from "react-bootstrap"
import ButtonSpinner from "../../../../components/shared/ButtonSpinner";

interface Props {
  title: string;
  description: string;
  timerange: string;
  icon: JSX.Element;
  isLoading: boolean;
}

export default function StatCard({ title, description, timerange, icon, isLoading }: Props) {
  // const variant = success ? 'badge-soft-success' : 'badge-soft-danger';
  return (
    <Card className="flex-fill">
      <Card.Body className=" py-4">
        <div className="d-flex align-items-start">
          <div className="flex-grow-1">
            {isLoading && <ButtonSpinner />}
            {!isLoading && <h3 className="mb-2">{title}</h3>}
            <p className="mb-2">{description}</p>
            <div className="mb-0">
              {/* <Badge bg="" className={`${variant} me-2`}>
                {percent}
              </Badge> */}
              <span className="text-muted">{`${timerange}`}</span>
            </div>
          </div>
          <div className="d-inline-block ms-3">
            <div className="stat">
              {icon}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}