import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserContainer } from '../../../redux/slices/organization/organisation';

interface Props {
  userId: string;
}

export default function AssigneeTableRow({ userId }: Props) {
  const users = useSelector(selectUserContainer);
  const name = users[userId]?.data.name || '';
  return (
    <td>{name}</td>
  );
}
