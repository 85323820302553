/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { AquadashServiceApiProvider } from '../../services/AquadashServiceApiProvider';
import { TripTemplate } from '../models/settings.models';

export const getTemplates = async () => {
  try {
    const { data } = await AquadashServiceApiProvider.getTemplates();
    const templates = data.data;
    templates.sort((a: TripTemplate, b: TripTemplate) => a.data.name.localeCompare(b.data.name));
    return templates;
  } catch (e) {
    const errorMessage = 'Couldn\'t get templates. Please contact support if the problem persists.';
    return toast(errorMessage, { type: 'error' });
  }
};

export const deleteTemplate = async (entityId: string) => {
  try {
    const { data } = await AquadashServiceApiProvider.deleteTemplate(entityId);
    return data.data;
  } catch (e) {
    const errorMessage = 'Couldn\'t delete templates. Please contact support if the problem persists.';
    return toast(errorMessage, { type: 'error' });
  }
};

export const createTemplate = async (params: object) => {
  return AquadashServiceApiProvider.createTemplate(params);
};
