import { DateService } from '../../../utils/dateService';

export enum DateRangeType {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Yesterday = 'Yesterday',
  Last7Days = 'Last 7 Days',
  Last30Days = 'Last 30 Days',
  Next7Days = 'Next 7 Days',
  Next30Days = 'Next 30 Days',
  ThisMonth = 'This Month',
  LastMonth = 'Last Month',
  NextMonth = 'Next Month',
  Custom = 'Custom',
}

export const getDefaultDate = (type: DateRangeType) => {
  switch (type) {
    case DateRangeType.Today:
      return ({
        dateFrom: DateService.getStartOfTheDayISO(),
        dateTo: DateService.getEndOfTheDayISO(),
      });
    case DateRangeType.Tomorrow:
      return ({
        dateFrom: DateService.getStartOfTheDayISO(DateService.addDaysToDateISO(null, 1)),
        dateTo: DateService.getEndOfTheDayISO(DateService.addDaysToDateISO(null, 1)),
      });
    case DateRangeType.Yesterday:
      return ({
        dateFrom: DateService.getStartOfTheDayISO(DateService.subtractDaysFromDateISO(null, 1)),
        dateTo: DateService.getEndOfTheDayISO(DateService.subtractDaysFromDateISO(null, 1)),
      });
    case DateRangeType.Last7Days:
      return ({
        dateFrom: DateService.getStartOfTheDayISO(DateService.subtractDaysFromDateISO(null, 7)),
        dateTo: DateService.getEndOfTheDayISO(),
      });
    case DateRangeType.Last30Days:
      return ({
        dateFrom: DateService.getStartOfTheDayISO(DateService.subtractDaysFromDateISO(null, 30)),
        dateTo: DateService.getEndOfTheDayISO(),
      });
    case DateRangeType.Next7Days:
      return ({
        dateFrom: DateService.getStartOfTheDayISO(),
        dateTo: DateService.getEndOfTheDayISO(DateService.addDaysToDateISO(null, 7)),
      });
    case DateRangeType.Next30Days:
      return ({
        dateFrom: DateService.getStartOfTheDayISO(),
        dateTo: DateService.getEndOfTheDayISO(DateService.addDaysToDateISO(null, 30)),
      });
    case DateRangeType.ThisMonth:
      return ({
        dateFrom: DateService.getStartOfTheMonthISO(),
        dateTo: DateService.getEndOfTheMonthISO(),
      });
    case DateRangeType.LastMonth:
      return ({
        dateFrom: DateService.getStartOfTheMonthISO(DateService.subtractMonthsFromDateISO(null, 1)),
        dateTo: DateService.getEndOfTheMonthISO(DateService.subtractMonthsFromDateISO(null, 1)),
      });
    case DateRangeType.NextMonth:
      return ({
        dateFrom: DateService.getStartOfTheMonthISO(DateService.addMonthsToDateISO(null, 1)),
        dateTo: DateService.getEndOfTheMonthISO(DateService.addMonthsToDateISO(null, 1)),
      });
    default:
      return ({
        dateFrom: '',
        dateTo: '',
      });
  }
};


export const DATE_PARAMS = [
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'tomorrow',
    label: 'Tomorrow',
  },
  {
    value: 'yesterday',
    label: 'Yesterday',
  },
  {
    value: 'last-seven-days',
    label: 'Last 7 Days',
  },
  {
    value: 'last-thirty-days',
    label: 'Last 30 Days',
  },
  {
    value: 'this-month',
    label: 'This Month',
  },
  {
    value: 'last-month',
    label: 'Last Month',
  },
  // {
  //   value: 'custom',
  //   label: 'Custom',
  // },
];

export const DEFAULT_DATE_RANGE = [
  new Date(DateService.getStartOfTheDayISO(DateService.subtractDaysFromDateISO(null, 2))),
  new Date(DateService.getEndOfTheDayISO(DateService.addDaysToDateISO(null, 1))),
];

export const DATE_RANGE_LABEL = 'Date Range';
export const FILTER_TEXT = 'Filters ';
