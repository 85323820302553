/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Descendant } from 'slate';

import MoveNotes from '../../../../components/text/MoveNotes';
// import { Move } from '../../../../redux/models/trip.models';
import {
  getMentions,
  selectIsMentionsLoaded,
  selectIsMentionsLoading,
  selectMentions,
} from '../../../../redux/slices/mentions';
import useAppDispatch from '../../../../hooks/useAppDispatch';
// import { defaultDispatchTemplate } from '../../../../components/text/MoveNotes.utils';
// import { NoteTemplate } from '../../../../redux/models/settings.models';

interface Props {
  initialNotes: Descendant[];
  handleUpdateDispatch: (dispatchNotes: Descendant[]) => void;
}

function DispatchNotes({ initialNotes, handleUpdateDispatch }: Props) {
  const dispatch = useAppDispatch();
  const mentions = useSelector(selectMentions);
  const isMentionLoaded = useSelector(selectIsMentionsLoaded);
  const isMentionsLoading = useSelector(selectIsMentionsLoading);
  
  const characters = mentions.map((men) => men.data.character);
  
  useEffect(() => {
    if (!isMentionLoaded && !isMentionsLoading) {
      dispatch(getMentions());
    }
  }, [isMentionLoaded, isMentionsLoading, dispatch]);
  return (
    <div>
      <div className="mt-2">
        <MoveNotes characters={characters} notes={initialNotes} handleChange={handleUpdateDispatch} />
      </div>
    </div>
  )
}

export default DispatchNotes;
