import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { Card, Table } from "react-bootstrap";

// import { MoreHorizontal } from "react-feather";
import usePalette from "../../../../hooks/usePalette";
import { Move } from "../../../../redux/models/trip.models";
import { ApiQuery } from "../../../../redux/models/network.models";
import { findMoves } from "../../../../redux/slices/trips/trips";
import { EntityContainer } from "../../../../redux/models/core.models";
import { selectAddressesObject } from "../../../../redux/slices/settings";
import { getAddressMoves } from "../../utils/move.utils";
import Loader from "../../../../components/Loader";

interface Props {
  title: string;
  query: ApiQuery;
  boardColours: EntityContainer<string>;
}

export default function BobtailAddress({ title, query, boardColours }: Props) {
  const palette = usePalette();
  const addresses = useSelector(selectAddressesObject);
  const [moves, setMoves] = useState<Move[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const addressIds = Array.from(new Set(moves.map((move) => move.data.destination_id)));
  const addressMoves = getAddressMoves(moves);
  const sortedAddressIds = [...addressIds].sort((a, b) => {
    const aCount = addressMoves[a]?.length || 0;
    const bCount = addressMoves[b]?.length || 0;
    return bCount - aCount;
  })
  const labels = sortedAddressIds
    .map((addressId) => {
      if (addressId === '') return 'No address set';
      return addresses[addressId]?.data.samsara_name || 'No name set';
    }).sort((a, b) => {
      const aCount = addressMoves[a]?.length || 0;
      const bCount = addressMoves[b]?.length || 0;
      return bCount - aCount;
    });
  const moveCount = sortedAddressIds.map((addressId) => {
    const mvs = moves.filter((mv) => mv.data.destination_id === addressId) || [];
    return mvs.length;
  });  

  const data = {
    labels,
    datasets: [
      {
        data: moveCount,
        backgroundColor: Object.values(boardColours),
        borderWidth: 5,
        borderColor: palette.white,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await findMoves(query);
      if (response.status === 200) {
        const moveData = response.data.data.items || [];
        // const count = response.data.data.total_count || 0;
        // setTotal(count);
        setMoves(moveData);
      }
      setIsLoading(false);
    };
    load();
  }, [query]);
  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        {/* <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
        <Card.Title className="mb-0">{title}</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        {isLoading && (
          <div className="align-self-center w-100">
            <Loader />
          </div>
        )}
        {!isLoading && (
          <div className="align-self-center w-100">
            <div className="py-3">
              <div className="chart chart-xs">
                <Pie data={data} options={options} />
              </div>
            </div>
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>Address</th>
                  <th className="text-end">{title}</th>
                  {/* <th className="text-end">Moves</th> */}
                </tr>
              </thead>
              <tbody>
                {sortedAddressIds.map((addressId) => {
                  const name = addresses[addressId]?.data.samsara_name || 'No name set';
                  const mvs = moves.filter((mv) => mv.data.destination_id === addressId) || [];
                  return (
                    <tr key={addressId}>
                      <td>
                        {name}
                      </td>
                      <td className="text-end">{mvs.length}</td>
                      {/* <td className="text-end text-success">+43%</td> */}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
