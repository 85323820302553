import React, { useEffect, useState } from 'react';
import { DollarSign } from "react-feather";

import StatCard from './base/StatCard';
import { Invoice } from '../../../redux/models/invoice.models';
import { Api } from '../../../services/services';
import { formatCurrency } from '../../invoices/utils/invoice.utils';
import {
 InvoiceWritePermissions
} from '../../../components/shared/permissions/permissions.utils';
import Permission from '../../../components/shared/permissions/Permission';

interface Props {
  dateFrom: string;
  dateTo: string;
}

export default function RevenueStat({ dateFrom, dateTo }: Props) {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const total = invoices.reduce((tot, invoice) => {
    const amount = invoice.data.total || 0;
    return tot + amount;
  }, 0);
  const details = isLoading ? '' : `${formatCurrency('cad', total)}`;
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await Api.Dashboard.shipmentsInvoiced({
        start_date: dateFrom,
        end_date: dateTo,
      });
      if (response.status === 200) {
        const invData = response.data.data || [];
        setInvoices(invData);
      }
      setIsLoading(false);
    };
    load();
  }, [dateFrom, dateTo]);
  return (
    <Permission resources={InvoiceWritePermissions}>
      <StatCard
        title={details}
        description="Invoiced Revenue"
        timerange={`From ${invoices.length} invoices`}
        isLoading={false}
        icon={<DollarSign className="align-middle text-success" />}
      />
    </Permission>
  )
}