import React, { useState } from 'react';
import { Send } from 'react-feather';
import { toast } from 'react-toastify';
import { Col, Dropdown, Row, Stack } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { DispatchNotificationType, TripDetails } from '../../redux/models/trip.models';
import {
  // dispatchTrip,
  duplicateTrip,
  // setTripDetails,
  updateTripDetails,
} from '../../redux/slices/trips/trips';
import useAppDispatch from '../../hooks/useAppDispatch';
import Analytics from '../../utils/analytics';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import Permission from '../../components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../redux/models/feature.flags.models';
import ShareButton from '../../components/permissions/ShareButton';
import TripMenu from './components/TripMenu';
// import AsyncButton from '../../components/shared/buttons/AsyncButton';
import DuplicateTripModal from './components/DuplicateTripModal';
import DispatchEmailModal from './components/DispatchNotificationModal';
import DispatchSamsaraModal from './components/DispatchSamsaraModal';
import { resetShipments } from '../../redux/slices/shipment-list/shipment-list';

const getTripPageTitle = (tripNumber: string) => `Trip Details • ${tripNumber}`;
// const DISPATCH_BTN_TITLE = 'Dispatch';

const WriteRoutePermissions = {
  [FeatureResource.Route]: ResourcePermission.Write,
};

interface Props {
  tripDetails: TripDetails;
  isEditable: boolean;
  onDelete: () => void;
  saveTrip: (trip: TripDetails) => void;
}

export default function TripDetailsHeader({ tripDetails, isEditable, onDelete, saveTrip }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const features = useFeatureFlags();
  const { orgCode } = useParams();

  const [showMenu, setShowMenu] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [showDispatchSamsara, setShowDispatchSamsara] = useState(false);
  const [showDispatchEmail, setShowDispatchEmail] = useState(false);
  const [showDispatchSms, setShowDispatchSms] = useState(false);

  const isSamsaraEnabled = features.samsara_routing?.is_enabled || false;
  const isEmailEnabled = features.email_dispatch?.is_enabled || false;
  const isSmsEnabled = features.sms_dispatch?.is_enabled || false;
  const isRoutingEnabled = isSamsaraEnabled || isEmailEnabled || isSmsEnabled;
  const tripRoles = tripDetails.data.role_ids || tripDetails.roles || [];
  const handleUpdateRoles = (roleIds: string[]) => {
    const updates: TripDetails = {
      ...tripDetails,
      data: {
        ...tripDetails.data,
        role_ids: roleIds,
      },
    };
    dispatch(updateTripDetails(updates));
  };
  const handleConfirmDuplicate = () => {
    setShowDuplicate(true);
  };
  const handleDuplicate = async (duplicateShipments: boolean) => {
    try {
      setIsDuplicating(true);
      await saveTrip(tripDetails);
      const response = await duplicateTrip(tripDetails.entity_id, duplicateShipments);
      if (response.status === 200) {
        const duplicated: TripDetails = response.data.data;
        setIsDuplicating(false);
        setShowDuplicate(false);
        Analytics.duplicateTrip(orgCode, duplicateShipments);
        dispatch(resetShipments());
        navigate(`/${orgCode}/trips/${duplicated.entity_id}`);
      }
    } catch (error) {
      if (error instanceof Error) {
        const errorMessage = `Couldn't duplicate trip. ${error.message}.
        Please contact support if the problem persists.`;
        toast(errorMessage, { type: 'error' });
      }
    } finally {
      setIsDuplicating(false);
    }
  };
  return (
    <Row>
      <Col>
        <Stack
          direction="horizontal"
          gap={3}
          onMouseEnter={() => setShowMenu(true)}
          onMouseLeave={() => setShowMenu(false)}
        >
          <h1 className="h3 mb-3" data-cy="trip_title">{getTripPageTitle(tripDetails.data.trip_no)}</h1>
          {showMenu && (
            <TripMenu onDuplicate={handleConfirmDuplicate} onDelete={onDelete} />
          )}
          {showMenu && (
            <ShareButton roleIds={tripRoles} onUpdateRoles={handleUpdateRoles} />
          )}
        </Stack>
      </Col>
      <Col md={6}>
        {isRoutingEnabled && isEditable && (
          <Permission resources={WriteRoutePermissions}>
            <Dropdown className="d-inline me-2 float-end">
              <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
                <Send className="feather align-middle mt-n1" /> Dispatch
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {isEmailEnabled && (
                  <Dropdown.Item onClick={() => setShowDispatchEmail(true)}>Email</Dropdown.Item>
                )}
                {isSamsaraEnabled && (
                  <Dropdown.Item onClick={() => setShowDispatchSamsara(true)}>Samsara</Dropdown.Item>
                )}
                {isSmsEnabled && (
                  <Dropdown.Item onClick={() => setShowDispatchSms(true)}>SMS</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Permission>
        )}
      </Col>
      {showDuplicate && (
        <DuplicateTripModal
          shouldShow={showDuplicate}
          isSaving={isDuplicating}
          onDuplicate={handleDuplicate}
          onCancel={() => setShowDuplicate(false)}
        />
      )}
      {showDispatchEmail && (
        <DispatchEmailModal
          shouldShow={showDispatchEmail}
          trip={tripDetails}
          type={DispatchNotificationType.Email}
          saveTrip={saveTrip}
          onClose={() => setShowDispatchEmail(false)}
        />
      )}
      {showDispatchSamsara && (
        <DispatchSamsaraModal
          shouldShow={showDispatchSamsara}
          trip={tripDetails}
          saveTrip={saveTrip}
          onClose={() => setShowDispatchSamsara(false)}
        />
      )}
      {showDispatchSms && (
        <DispatchEmailModal
          shouldShow={showDispatchSms}
          trip={tripDetails}
          type={DispatchNotificationType.Sms}
          saveTrip={saveTrip}
          onClose={() => setShowDispatchSms(false)}
        />
      )}
    </Row>
  )
}