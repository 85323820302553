import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ButtonSpinner from '../ButtonSpinner';

interface Props {
  title: string;
  variant?: string;
  spinner?: string;
  id?: string;
  dataCy?: string;
  disabled?: boolean;
  className?: string;
  handleClick: () => void;
}

function AsyncButton({ title, disabled, id, dataCy, variant, spinner, className, handleClick }: Props) {
  const [isSaving, setIsSaving] = useState(false);
  const handleSavePlan = async () => {
    setIsSaving(true);
    await handleClick();
    setIsSaving(false);
  };
  return (
    <Button
      disabled={disabled || isSaving}
      id={id}
      data-cy={dataCy}
      variant={variant}
      className={className || ''}
      onClick={handleSavePlan}
    >
      {isSaving ? <ButtonSpinner variant={spinner} /> : title}
    </Button>
  )
}

AsyncButton.defaultProps = {
  variant: 'primary',
  spinner: 'primary',
  id: '',
  dataCy: '',
  className: '',
  disabled: false,
}

export default AsyncButton;