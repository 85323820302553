/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityContainer } from '../../../redux/models/core.models';
import { TripBoardModel } from '../../../redux/models/trip.models';
import { CreateTripAsyncProps } from '../../../services/base/TripServiceProvider.models';
import { DateService } from '../../../utils/dateService';
import { DispatchNote, isDispatchNotesInvalid } from '../../trips/utils/trips.utils';

export const FILTER_KEYS = {
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
  DATE_TYPE: 'dateType',
};

export const BOARD_PAGE_TABS = {
  GROUPS: {
    eventKey: 'GROUPS',
    title: 'Groups',
    tripKey: 'groupId',
  },
  DRIVERS: {
    eventKey: 'DRIVERS',
    title: 'Drivers',
    tripKey: 'driverId',
  },
  PLANNER: {
    eventKey: 'PLANNER',
    title: 'Planner',
    tripKey: 'groupId',
  },
  PREVIEW: {
    eventKey: 'PREVIEW',
    title: 'Preview',
    tripKey: 'groupId',
  },
  SHIPMENTS: {
    eventKey: 'SHIPMENTS',
    title: 'Shipments',
    tripKey: 'customerId',
  },
};

export const LOADING_TEXT = 'Loading...';

export const getUnassignedId = (tabKey: string) => `UNASSIGNED-${tabKey}`;

export const getUnassignedCard = (tabKey: string) => ({
  entity_id: getUnassignedId(tabKey),
  data: {
    name: 'Unassigned',
    samsara_name: 'Unassigned',
    trip_template_ids: [],
  },
});

export const getTime = (date: string) => DateService.getUnixTime(date);

export const sortTripByTBDDate = (a: TripBoardModel, b: TripBoardModel) => {
  const aMidnight = DateService.isMidnight(a.apt || '');
  const bMidNight = DateService.isMidnight(b.apt || '');
  if (aMidnight && !bMidNight) return 1;
  if (!aMidnight && bMidNight) return -1;
  const aMoves = a.moves || [];
  const bMoves = b.moves || [];
  const aTbd = aMoves.some((mv) => mv.schedule_tbd) || false;
  const bTbd = bMoves.some((mv) => mv.schedule_tbd) || false;
  if (aTbd && !bTbd) return 1;
  if (!aTbd && bTbd) return -1;
  return getTime(a.apt) - getTime(b.apt);
}

export const sortTripByDate = (a: TripBoardModel, b: TripBoardModel) => sortTripByTBDDate(a, b);

export const boardViewFormatter = (trips: TripBoardModel[], key: string, unassignedKey: string) => {
  const defaultContainer: EntityContainer<TripBoardModel[]> = {};
  const groups = trips.reduce((container, trip: any) => {
    const keyId = trip[key] || getUnassignedId(unassignedKey);
    const groupTrips = container[keyId] || [];
    groupTrips.push(trip);
    return {
      ...container,
      [keyId]: groupTrips,
    };
  }, defaultContainer);
  return Object.entries(groups).reduce((container, group) => {
    const [groupId, value] = group;
    return {
      ...container,
      [groupId]: [...value].sort((a, b) => sortTripByDate(a, b)),
    };
  }, {});
};

export const getDefaultShipment = (tripIds: string[] = []) => ({
  type: 'reefer-van',
  customer_id: '',
  billing_type_id: '',
  trip_ids: tripIds,
  pick_up_attachments: [{
    name: 'Waybill',
    filetype: null,
    path: null,
  },
  {
    name: 'Bill of Lading (BOL) or Packing List',
    filetype: null,
    path: null,
  },
  ],
  delivery_attachments: [
    {
      name: 'Waybill',
      filetype: null,
      path: null,
    },
    {
      name: 'Bill of Lading (BOL) or Packing List',
      filetype: null,
      path: null,
    },
    {
      name: 'Delivery Sticker',
      filetype: null,
      path: null,
    },
    {
      name: 'Manifest',
      filetype: null,
      path: null,
    },
  ],
  misc_attachments: [
    {
      name: 'Load Confirmation',
      filetype: null,
      path: null,
    },
    {
      name: 'CSCD',
      filetype: null,
      path: null,
    },
    {
      name: 'Sailing Confirmation',
      filetype: null,
      path: null,
    },
    {
      name: 'Customs',
      filetype: null,
      path: null,
    },
  ],
});

export const getDefaultTrip = (
  date: string, boardId: string, groupId: string, driverId: string, shipmentIds: string[] = []
) => ({
  board_id: boardId || '',
  group_id: groupId || '',
  driver_id: driverId || '',
  move_ids: [],
  shipment_ids: shipmentIds,
  vehicle_id: '',
  date,
});

export const boardShipment = (
  date: string,
  boardId: string,
  groupId: string,
  driverId: string,
  shipmentIds: string[],
  noteTemplateId: string | undefined,
  defaultNotes: Array<any>
) => {
  const data: any = {
    trip: getDefaultTrip(date, boardId, groupId, driverId, shipmentIds || []),
    shipment: getDefaultShipment(),
  };
  if (noteTemplateId) {
    data.note_template_id = noteTemplateId;
  }
  const isTemplateValid = isDispatchNotesInvalid(defaultNotes || []);
  if (!isTemplateValid) {
    data.dispatch_notes = defaultNotes;
  }
  return data;
};

export const boardTrip = (
  date: string,
  boardId: string,
  groupId: string,
  driverId: string,
  shipmentIds: string[],
  defaultNotes: Array<any>) => {
  const data: any = getDefaultTrip(date, boardId, groupId, driverId, shipmentIds || []);
  const isTemplateValid = isDispatchNotesInvalid(defaultNotes || []);
  if (!isTemplateValid) {
    data.dispatch_notes = defaultNotes;
  }
  return data;
};

export const createTripFromTemplateParams = (
  date: string, notesTemplateId: string, defaultNotes: DispatchNote[], timezone: string
) => {
  const data: EntityContainer<any> = {
    driver_id: '',
    date,
    time_zone: timezone,
  };
  if (notesTemplateId !== '' && notesTemplateId !== undefined && notesTemplateId !== null) {
    data.note_template_id = notesTemplateId;
  }
  const isTemplateValid = isDispatchNotesInvalid(defaultNotes || []);
  if (!isTemplateValid) {
    data.dispatch_notes = defaultNotes;
  }
  return data;
}

export const createTripFromTemplateAsyncParams = (
  date: string, defaultNotes: DispatchNote[], timezone: string, boardId: string
) => {
  const data: CreateTripAsyncProps = {
    driver_id: '',
    date,
    time_zone: timezone,
    board_id: boardId,
    dispatch_notes: defaultNotes,
  };
  return data;
};