/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Col,
} from 'react-bootstrap';

import { ControlInput, SelectInput } from '../../../../../components/shared/Input';
import { Shipment, ShipmentTypeFieldData } from '../../../../../redux/models/shipment.models';
import { EntityContainer } from '../../../../../redux/models/core.models';
import { getName, isFieldError } from './shipment.form.utils';
import { ShipmentErrorLabel } from './ShipmentField';

interface Props {
  shipmentDetails: Shipment;
  fields: ShipmentTypeFieldData;
  uomSelectOptions: any;
  handleChange: any;
  page: string;
  disabled: boolean;
  validation: EntityContainer<boolean>;
}

export default function WeightForm({
  shipmentDetails,
  fields,
  uomSelectOptions,
  handleChange,
  page,
  disabled,
  validation,
}: Props) {
  const weightField = fields.weight;
  const weightUomField = fields.weight_uom_id;
  return (
    <Col md={4}>
      <div className="d-flex">
        <div className="col-4 pe-2">
          <ControlInput
            labelText={getName(weightField)}
            name={weightField.key}
            page={page}
            type="number"
            value={`${shipmentDetails.data.weight}`}
            handleChange={handleChange}
            dataCy="shipment_weight"
            disabled={disabled}
          />
          {isFieldError(weightField, validation) && (
            <ShipmentErrorLabel field={weightField} />
          )}
        </div>
        <div className="col-8 pe-2">
          <SelectInput
            labelText={getName(weightUomField)}
            name={weightUomField.key}
            page={page}
            value={shipmentDetails.data.weight_uom_id}
            options={uomSelectOptions}
            handleChange={handleChange}
            dataCy="shipment_weight_unit"
            disabled={disabled}
          />
          {isFieldError(weightUomField, validation) && (
            <ShipmentErrorLabel field={weightUomField} />
          )}
        </div>
      </div>
    </Col>
  );
}
