import { Document } from '../../../../redux/models/file.models';

export interface DropdownOption {
  label: string;
  value: string;
  disabled: boolean;
}

export const isEmpty = (doc: Document) => {
  const isNoPath = doc.path === '' || doc.path === undefined || doc.path === null;
  const isNoFileType = doc.filetype === '' || doc.filetype === undefined || doc.filetype === null;
  return isNoPath || isNoFileType;
};

export const getId = (doc: Document) => {
  return `${doc.name}-${doc.path}-${doc.filetype}`;
};

export const getShipmentDocId = (doc: Document, key: string) => {
  return `${doc.name}-${doc.path}-${doc.filetype}-${key}`;
};

export const createMoveDocOptions = (docs: Document[]): DropdownOption[] => {
  return docs.map((doc, idx) => ({
    label: `${idx + 1}. ${doc.name}`,
    value: getId(doc),
    disabled: isEmpty(doc),
  }));
};

export const ADDITIONAL_DOC_OPTION_KEY = 'ADDITIONAL_DOC_OPTION';

export const additionalDocId = (key: string) => {
  return `${ADDITIONAL_DOC_OPTION_KEY}-${key}`;
}

export const createShipmentDocOptions = (docs: Document[], key: string): DropdownOption[] => {
  const items = docs.filter((doc) => isEmpty(doc)).map((doc) => ({
    label: `${doc.name} - ${key}`,
    value: getShipmentDocId(doc, key),
    disabled: !isEmpty(doc),
  }));
  const additional = {
    label: `Additional Document - ${key}`,
    value: additionalDocId(key),
    disabled: false,
  }
  return [...items, additional]
};

export enum ShipmentDocKey {
  Delivery = 'Delivery',
  Misc = 'Misc',
  PickUp = 'Pick Up',
}

export const isAdditionalDoc = (shipmentDocId: string, key: ShipmentDocKey) => {
  const additionalKey = additionalDocId(key);
  return shipmentDocId === additionalKey;
};

export const assignDocs = (
  shipmentDocs: Document[],
  shipmentDocId: string,
  key: ShipmentDocKey,
  moveDoc: Document | undefined
) => {
  if (!moveDoc || shipmentDocId === '') return shipmentDocs;
  const isAdditional = isAdditionalDoc(shipmentDocId, key);
  if (isAdditional) {
    const add = {
      filetype: moveDoc.filetype,
      name: moveDoc.path,
      path: moveDoc.path,
    };
    return [...shipmentDocs, add];
  };
  return shipmentDocs.map((doc) => {
    if (getShipmentDocId(doc, key) === shipmentDocId && moveDoc) {
      return {
        ...doc,
        path: moveDoc.path,
        filetype: moveDoc.filetype,
      }
    }
    return doc;
  });
};
