import React, { useEffect, useState } from 'react';
import DriverConflict from './components/DriverConflict';
import { DriverAvailabilityData, DriverTrip } from '../../../redux/models/driver.availability.models';
import { Move, TripDetails } from '../../../redux/models/trip.models';
import { EntityContainer } from '../../../redux/models/core.models';
import SuggestionList from './components/SuggestionsList';
import { Driver } from '../../../redux/models/settings.models';
import Analytics from '../../../utils/analytics';
import { getSuggestedDrivers, getConflictingDriverTrips } from '../../../redux/slices/availability/driver.availability';

interface Props {
  tripDetails: TripDetails;
  drivers: Driver[];
}

const getMove = (moves: Move[], index: number) => {
  if (moves.length < index) return null;
  return moves[index];
}

export default function DriverSuggestions({ tripDetails, drivers }: Props) {
  const [conflictOpen, setConflictOpen] = useState(true);
  const [openSuggestions, setOpenSuggestions] = useState<EntityContainer<boolean>>({});
  const [conflicts, setConflicts] = useState<DriverTrip[]>([]);
  const [suggestions, setSuggestions] = useState<EntityContainer<DriverAvailabilityData>>({});
  const isConflict = conflicts.length > 0;
  const tripId = tripDetails.entity_id;
  const moves = [...tripDetails.data.moves].sort((a, b) => {
    const aPos = a.data.position || 0;
    const bPost = b.data.position || 0;
    return aPos - bPost;
  });
  const arrivalIdx = moves.length - 1;
  const departure = getMove(moves, 0);
  const arrival = getMove(moves, arrivalIdx);
  const driverId = tripDetails.data.driver_id || '';
  const departureTime = departure?.data.scheduled_arrival_time || '';
  const arrivalTime = arrival?.data.scheduled_arrival_time || '';
  const isDg = tripDetails.data.shipments.filter((shipment) => shipment.data.dangerous_goods).length > 0;
  const handleConflictClose = () => setConflictOpen(false);
  useEffect(() => {
    const loadTrips = async (departTime: string, arrTime: string, drivId: string) => {
      const { data } = await getConflictingDriverTrips(departTime, arrTime, drivId);
      const conflictData = data.data || [];
      const ds = conflictData.filter((conflict: DriverTrip) => conflict.data.trip.entity_id !== tripId);
      setConflicts(ds);
    };
    loadTrips(departureTime, arrivalTime, driverId);
  }, [tripId, departureTime, arrivalTime, driverId]);
  useEffect(() => {
    const loadTrips = async (departTime: string, arrTime: string, drivId: string) => {
      try {
        const { data } = await getSuggestedDrivers(departTime, arrTime, drivId, isDg);
        const items: EntityContainer<DriverAvailabilityData> = data.data;
        const openSuggs: EntityContainer<boolean> = Object.keys(items).reduce((store, id) => {
          return {
            ...store,
            [id]: true,
          }
        }, {});
        setOpenSuggestions(openSuggs);
        setSuggestions(items);
      } catch (error) {
        Analytics.capture(error);
      }
    };
    loadTrips(departureTime, arrivalTime, driverId);
  }, [tripId, departureTime, arrivalTime, driverId, isDg]);
  return (
    <div>
      {isConflict && conflictOpen && (
        <DriverConflict tripDetails={tripDetails} conflicts={conflicts} setClosed={handleConflictClose} />
      )}
      {Object.keys(suggestions).length > 0 && (
        <SuggestionList
          tripDetails={tripDetails}
          startDate={departureTime}
          endDate={arrivalTime}
          suggestions={suggestions}
          openSuggestions={openSuggestions}
          setOpenSuggestions={setOpenSuggestions}
          drivers={drivers}
        />
      )}
    </div>
  );
}
