/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/function-component-definition */
/* eslint-disable  @typescript-eslint/no-use-before-define */
/* eslint-disable default-case */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react'
import { Editor, Transforms, Range, createEditor, Descendant } from 'slate'
import { withHistory } from 'slate-history'
import {
  Slate,
  Editable,
  ReactEditor,
  withReact,
} from 'slate-react'
import { Button, Spinner, Stack } from 'react-bootstrap';
import { Portal } from './components';
import { insertMention, withMentions } from './MoveNotes.utils';
import { Leaf, Element } from './Mention';

interface MoveNoteProps {
  notes: Descendant[];
  characters: string[];
  handleChange: (value: Descendant[]) => void;
}

const MoveNotes = ({ characters, notes, handleChange }: MoveNoteProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isMentionsLoading] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [target, setTarget] = useState<Range | undefined | null>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const showMoreTitle = `${showTags ? 'Hide' : 'Show'} More`;
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    []
  );

  // const [editor] = useState(() => withMentions(withReact(withHistory(createEditor()))));
  
  // const initialValue = useMemo(
  //   () =>
  //     notes,
  //   []
  // )
  const [initialValue] = useState(notes);
  const chars = characters.filter(c =>
    c.toLowerCase().startsWith(search.toLowerCase())
  ).slice(0, 10);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (target && chars.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            insertMention(editor, chars[index])
            setTarget(null)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(null)
            break
        }
      }
    },
    [chars, editor, index, target]
  )

  const handleAddMention = (text: string) => {
    insertMention(editor, text);
  };
  useEffect(() => {
    if (target && chars.length > 0) {
      const el: any = ref.current
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      el.style.top = `${rect.top + window.pageYOffset + 24}px`
      el.style.left = `${rect.left + window.pageXOffset}px`
    }
  }, [chars.length, editor, index, search, target])

  return (
    <div>
      <div style={{ border: '1px solid #ced4da', borderRadius: '4px', padding: '8px' }}>
        <Slate
          editor={editor}
          initialValue={initialValue}
          onChange={(value) => {
            const { selection } = editor

            if (selection && Range.isCollapsed(selection)) {
              const [start] = Range.edges(selection)
              const wordBefore = Editor.before(editor, start, { unit: 'word' })
              const before = wordBefore && Editor.before(editor, wordBefore)
              const beforeRange = before && Editor.range(editor, before, start)
              const beforeText = beforeRange && Editor.string(editor, beforeRange)
              const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
              const after = Editor.after(editor, start)
              const afterRange = Editor.range(editor, start, after)
              const afterText = Editor.string(editor, afterRange)
              const afterMatch = afterText.match(/^(\s|$)/)

              if (beforeMatch && afterMatch) {
                setTarget(beforeRange)
                setSearch(beforeMatch[1])
                setIndex(0)
                return
              }
            }

            setTarget(null);

            const isAstChange = editor.operations.some(
              (op: any) => 'set_selection' !== op.type
            )
            if (isAstChange) {
              // Save the value to Local Storage.
              handleChange(value);
            }
          }}
        >
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={onKeyDown}
            placeholder="Enter dispatch notes..."
          />
          {target && chars.length > 0 && (
            <Portal>
              <div
                ref={ref}
                style={{
                  top: '-9999px',
                  left: '-9999px',
                  position: 'absolute',
                  zIndex: 1,
                  padding: '3px',
                  background: 'white',
                  borderRadius: '4px',
                  border: '1px solid #ced4da',
                  boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                }}
                data-cy="mentions-portal"
              >
                {chars.map((char, i) => (
                  <div
                    key={char}
                    onClick={() => {
                      Transforms.select(editor, target)
                      insertMention(editor, char)
                      setTarget(null)
                    }}
                    style={{
                      padding: '1px 3px',
                      borderRadius: '3px',
                      background: i === index ? '#B4D5FF' : 'transparent',
                    }}
                  >
                    {char}
                  </div>
                ))}
              </div>
            </Portal>
          )}
        </Slate>
      </div>
      <div>
        <Button variant="link" className="my-2" onClick={() => setShowTags(!showTags)}>{showMoreTitle}</Button>
        {isMentionsLoading && <Spinner animation="border" variant="primary" />}
        {showTags && characters && characters.length && (
            <Stack direction="horizontal" gap={1} className="flex-wrap">
              {characters.map((mention) => {
                return (
                  <Button
                    key={mention}
                    variant="outline-secondary"
                    className="mr-2"
                    onClick={() => handleAddMention(mention)}
                  >
                    {mention}
                  </Button>
                )
              })}
            </Stack>
          )}
      </div>
    </div>
  )
}

export default MoveNotes;
