import React from 'react';
import {
  // Navigate,
  Outlet
} from 'react-router-dom';

import Main from '../components/Main';
// import useAuth from '../hooks/useAuth';

// const BOARDS_PAGE_TITLE = '/projects';

function Landing({ children }) {
  // const { isAuthenticated, isInitialized } = useAuth();
  // console.log('isAuthenticated: ', isAuthenticated);
  // console.log('isInitialized: ', isInitialized);
  // if (isInitialized && isAuthenticated) {
  //   return <Navigate to={BOARDS_PAGE_TITLE} />;
  // }

  return (
    <Main>
      {children}
      <Outlet />
    </Main>
  );
}

export default Landing;
