/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { AquadashServiceApiProvider } from '../../services/AquadashServiceApiProvider';
import {
  saveFilterValueToSessionStorage,
} from '../../utils/core.utils';
import {
  formatInvoices,
  getError,
  getFindInvoiceFilters,
} from './invoices.utils';
import {
  getInitialState, FILTER_KEY, InvoiceState, Invoice, InvoiceFilters, defaultFilters,
} from '../models/invoice.models';
import { EntityContainer } from '../models/core.models';
import Analytics from '../../utils/analytics';
import { INVOICES } from './slices';
import { Api } from '../../services/services';
import { ApiQuery, DEFAULT_API_QUERY } from '../models/network.models';

const SLICE_NAME = INVOICES;

const api = Api.Invoices;
type DispatchProps = any;
const initialState = getInitialState();

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setInvoices(state, action) {
      const { invoices } = action.payload;
      const listItems = formatInvoices(invoices);
      state.container = invoices.reduce((store: EntityContainer<Invoice>, invoice: Invoice) => {
        return {
          ...store,
          [invoice.entity_id]: invoice,
        };
      }, {});
      state.list = listItems;
    },
    setInvoiceList(state, action) {
      state.container = action.payload.data;
    },
    setAreInvoicesLoading(state, action) {
      state.areInvoicesLoading = action.payload;
    },
    setIsListInvoicesLoaded(state, action) {
      state.isListLoaded = action.payload;
    },
    setIsLoadingNextPage(state, action) {
      state.isLoadingNextPage = action.payload;
    },
    setInvoiceDetails(state, action) {
      state.invoiceDetails = action.payload.data;
    },
    updateInvoiceDetails(state, action) {
      state.invoiceDetails = action.payload;
    },
    setAreInvoiceDetailsLoading(state, action) {
      state.areInvoiceDetailsLoading = action.payload;
    },
    setFilterParams(state, action) {
      const storedFilters = JSON.stringify(action.payload);
      saveFilterValueToSessionStorage(FILTER_KEY, storedFilters);
      state.filterParams = action.payload;
    },
    resetInvoices: () => getInitialState(),
  },
});

export const {
  setInvoices,
  setInvoiceList,
  setAreInvoicesLoading,
  setInvoiceDetails,
  updateInvoiceDetails,
  setAreInvoiceDetailsLoading,
  setFilterParams,
  setIsListInvoicesLoaded,
  setIsLoadingNextPage,
  resetInvoices,
} = slice.actions;

export const findInvoices = (filters: InvoiceFilters) => {
  const q: ApiQuery = {
    ...DEFAULT_API_QUERY,
    filters: getFindInvoiceFilters(filters),
    pagination: {
      page: filters.page,
      page_size: filters.page_size,
    },
  };
  return api.find(q);
};

export const getLineItems = (invoiceIds: string[]) => {
  return api.lineItems(invoiceIds);
};

export const filterInvoiceDates = (filters: InvoiceFilters = defaultFilters) => async (dispatch: DispatchProps) => {
  dispatch(setAreInvoicesLoading(true));
  try {
    const { data } = await findInvoices(filters);
    const invoices = data?.data.items || [];
    const total = data?.data.total_count ?? 0;
    const updatedFilters = { 
      ...filters,
      // page: filters.page + 1,
      total,
    };
    dispatch(setFilterParams(updatedFilters));
    dispatch(setInvoices({ invoices }));
  } catch (e) {
    getError(e);
  } finally {
    dispatch(setAreInvoicesLoading(false));
    dispatch(setIsListInvoicesLoaded(true));
  }
};

export const loadNextPage = (filters: InvoiceFilters = defaultFilters, invoices: Invoice[]) => async (dispatch: DispatchProps) => {
  dispatch(setIsLoadingNextPage(true));
  try {
    const { data } = await findInvoices(filters);
    const invData = data?.data.items || [];
    const total = data?.data.total_count ?? 0;
    const nextPageData = formatInvoices(invData);
    const updatedFilters = { 
      ...filters,
      // page: filters.page + 1,
      total,
    };
    const allInvoices = [...invoices, ...nextPageData];
    dispatch(setFilterParams(updatedFilters));
    dispatch(setInvoices({ invoices: allInvoices }));
  } catch (e) {
    getError(e);
  } finally {
    dispatch(setIsLoadingNextPage(false));
  }
};

export const getInvoiceDetails = (invoiceId: string) => async (dispatch: DispatchProps) => {
  dispatch(setAreInvoiceDetailsLoading(true));
  try {
    const { data: invoice } = await AquadashServiceApiProvider.getInvoice(invoiceId);
    return dispatch(setInvoiceDetails(invoice));
  } catch (e) {
    return getError(e);
  } finally {
    dispatch(setAreInvoiceDetailsLoading(false));
  }
};

export const createInvoice = async (shipmentIds: string[], orgCode: string | undefined) => {
  try {
    const response = await AquadashServiceApiProvider.createInvoice(shipmentIds);
    if (response.status === 200) {
      return response.data.data;
    }
    const errorMessage = `Couldn't create invoice. ${response.data.description || ''}`;
    return toast(errorMessage, { type: 'error' });
  } catch (e) {
    return getError(e, "Couldn't create invoice", 'create');
  } finally {
    Analytics.createInvoice(orgCode);
  }
};

// no post body for updating accessorials
export const updateAccessorials = async (shipmentId: string) => {
  try {
    const { data: invoice } = await AquadashServiceApiProvider.updateAccessorials(shipmentId);
    return invoice.data;
  } catch (e) {
    return getError(e, "Couldn't update invoice", 'update');
  }
};

export const deleteInvoiceShipment = async (shipmentId: string) =>
  AquadashServiceApiProvider.deleteShipmentInvoice(shipmentId);

type AppState = EntityContainer<InvoiceState>;

export const updateInvoice = async (invoice: Invoice) => AquadashServiceApiProvider.updateInvoice(invoice);
export const sendInvoice = async (invoice: Invoice) => AquadashServiceApiProvider.sendInvoice(invoice);
export const selectInvoiceContainer = (state: AppState) => state[SLICE_NAME].container || {};
export const selectInvoices = (state: AppState) => {
  return Object.values(selectInvoiceContainer(state));
};
export const selectInvoiceDetails = (state: AppState) => state[SLICE_NAME].invoiceDetails;
export const selectInvoiceList = (state: AppState) => state[SLICE_NAME].list;
export const selectAreInvoicesLoading = (state: AppState) => state[SLICE_NAME].areInvoicesLoading;
export const selectIsListLoaded = (state: AppState) => state[SLICE_NAME].isListLoaded;
export const selectLoadingNextInvoicePage = (state: AppState) => state[SLICE_NAME].isLoadingNextPage;
export const selectAreInvoiceDetailsLoading = (state: AppState) => state[SLICE_NAME].areInvoiceDetailsLoading;
export const selectInvoiceFilterParams = (state: AppState) => state[SLICE_NAME].filterParams;

export const invoicesReducer = slice.reducer;
