import { EntityContainer } from "../../../redux/models/core.models";
import { Address, Driver } from "../../../redux/models/settings.models";
import { TripDetails } from "../../../redux/models/trip.models";
import { isMoveOrderValid } from "../../../redux/slices/trips/trips.utils";

export interface DispatchError {
  numMoves?: string;
  invalidAddress?: string;
  invalidMove?: string;
  invalidMoveDate?: string;
  invalidSamsaraAddress?: string;
  noDriver?: string;
  invalidSamsaraDriver?: string;
  invalidPhoneNum?: string;
  invalidEmail?: string;
  invalidReplyTo?: string;
}

export const isValidEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const isValidPhoneNumber = (phone: string) => {
  // This regex matches simple international phone numbers with optional + prefix,
  // spaces, dashes, and parentheses. Adjust the regex according to your needs.
  const regex = /^\+?(\d[\d\s\-()]{7,}\d)$/; 
  return regex.test(phone);
}

export const baseDispatchValidation = (trip: TripDetails) => {
  const errors: DispatchError = {};
  const moves = trip.data.moves || [];
  const numMoves = moves.length || 0;
  const driverId = trip.data.driver_id || '';
  const invalidMove = moves.some((move) => move.data.destination_id === '');
  if (numMoves < 2) {
    errors.numMoves = 'Must have at least 2 moves to dispatch trip';
  }
  if (invalidMove) {
    errors.invalidMove = 'Moves must have an address selected';
  }
  if (driverId === '') {
    errors.noDriver = 'Must select a driver';
  }
  const isOrderValid = isMoveOrderValid(moves);
  if (!isOrderValid) {
    errors.invalidMoveDate = 'Move dates must be ordered from earliest to latest';
  }
  return errors;
};

export const validateSamsaraDispatch = (
  trip: TripDetails,
  drivers: EntityContainer<Driver>,
  addresses: EntityContainer<Address>
) => {
  const errors = baseDispatchValidation(trip);
  const moves = trip.data.moves || [];
  const driverId = trip.data.driver_id || '';
  const driver = drivers[driverId];
  const samsaraDriverId = driver?.data.samsara_id || '';
  const invalidSamsaraMove = moves.some((move) => {
    const destinationId = move.data.destination_id || '';
    const addr = addresses[destinationId];
    const samsaraId = addr?.data.samsara_id || '';
    return samsaraId === '';
  });
  if (invalidSamsaraMove) {
    errors.invalidSamsaraAddress = 'Moves must have a valid Samsara address';
  }
  if (samsaraDriverId === '' || samsaraDriverId === undefined || samsaraDriverId === null) {
    errors.invalidSamsaraDriver = 'Must be a valid Samsara driver';
  }
  return errors;
};

export const validateSmsDispatch = (
  trip: TripDetails,
  phoneNo: string,
) => {
  const errors = baseDispatchValidation(trip);
  const validPhoneNo = isValidPhoneNumber(phoneNo);
  if (!validPhoneNo) {
    errors.invalidPhoneNum = 'Must include a valid phone number';
  }
  return errors;
};

export const validateEmailDispatch = (
  trip: TripDetails,
  email: string,
  replyTo: string,
) => {
  const errors = baseDispatchValidation(trip);
  const validEmail = isValidEmail(email);
  const validReplyTo = isValidEmail(replyTo);
  if (!validEmail) {
    errors.invalidEmail = 'Must include a valid email';
  }
  if (!validReplyTo) {
    errors.invalidReplyTo = 'Must include a valid reply to address';
  }
  return errors;
};

export const isDispatchErrors = (errors: DispatchError) => {
  if (!errors) return false;
  return Object.keys(errors).length > 0;
};

export const formatDispatchErrorMessage = (errors: DispatchError) => {
  if (!errors) return '';
  return Object.values(errors).join('\n');
};
