export const INTEGRATIONS_FERRY_TABLE_HEADERS = [
  {
    name: 'Trailer #',
    key: 'trailer',
  },
  {
    name: 'Departing',
    key: 'origin',
  },
  {
    name: 'ETA',
    key: 'eta',
  },
  {
    name: 'Destination',
    key: 'destination',
  },
  {
    name: 'Reefer Temp',
    key: 'temp',
  },
  {
    name: 'Sailing Time',
    key: 'sailingTime',
  },
];

export const INTEGRATIONS_ETA_TABLE_HEADERS = [
  {
    name: 'No.',
    key: 'number',
  },
  {
    name: 'Trailer',
    key: 'trailer',
  },
];
