import React, { useState } from 'react';
import {
  // Button,
  Col,
  Container,
  Row,
  // Stack,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { DateService } from '../../../utils/dateService';

import Header from '../components/base/Header';
import { selectBoardsObject } from '../../../redux/slices/settings';
import { THEME_PALETTE_LIGHT } from '../../../constants';
import { EntityContainer } from '../../../redux/models/core.models';
import { ApiQuery, FilterOp, QueryOperator } from '../../../redux/models/network.models';
import BobtailAddress from '../components/base/BobtailAddress';

const getColour = (colours: string[], selected: string[]) => {
  if (colours.length === 0) return THEME_PALETTE_LIGHT.primary;
  const remaining = colours.filter((colour) => !selected.includes(colour));
  const randomIndex = Math.floor(Math.random() * remaining.length);
  return remaining[randomIndex];
};

export default function EmptyMoveReport() {
  const boards = useSelector(selectBoardsObject);
  
  const [startDate, setStartDate] = useState(DateService.getStartOfTheDayISO());
  const [endDate, setEndDate] = useState(DateService.getEndOfTheDayISO());

  const colourContainer: EntityContainer<string> = {};
  const allColours = Object.values(THEME_PALETTE_LIGHT).filter((colour) => {
    return colour !== '#fff' && colour !== '#000' && !colour.includes('gray');
  });
  const boardData = Object.values(boards);
  const boardColours = boardData.reduce((store, board) => {
    const selected = Object.values(store);
    const colour = getColour(allColours, selected);
    return {
      ...store,
      [board.entity_id]: colour,
    };
  }, colourContainer);

  const query: ApiQuery = {
    filters: [
      {
        field: {
          name: 'data.scheduled_arrival_time',
          type: 'datetime',
        },
        op: FilterOp.GreaterThanOrEqual,
        value: startDate,
      },
      {
        field: {
          name: 'data.scheduled_arrival_time',
          type: 'datetime',
        },
        op: FilterOp.LessThan,
        value: endDate,
      },
      {
        field: {
          name: 'data.shipment_ids',
          type: 'string',
        },
        op: FilterOp.Equals,
        value: [],
      },
    ],
    operator: QueryOperator.And,
    pagination: {
      page: 0,
      page_size: 500,
    }
  };
  
  const handleDate = (from: string, to: string) => {
    setStartDate(from);
    setEndDate(to);
  };
  return (
    <Container fluid className="p-0">
      <Header title="Empty Moves" handleDate={handleDate} />
      <Row>
        <Col lg="12" xl="12" className="d-flex">
          <BobtailAddress
            title=""
            query={query}
            boardColours={boardColours}
          />
        </Col>
      </Row>
    </Container>
  );
}
