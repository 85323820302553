import { Trip } from "../../redux/models/trip.models";
import axiosInstance from "../../utils/axios";
import TrailerChangeEndpoints from "./TrailerChangeEndpoints";

interface GetTrailerChangeParams {
  shipment_ids: string[];
  trailer_ids: string[];
}
export default class TrailerChangeApi {
  static getTrailerChangesMoves(trailerIds: string[], shipmentIds: string[]) {
    const data: GetTrailerChangeParams = {
      shipment_ids: shipmentIds,
      trailer_ids: trailerIds,
    };
    return axiosInstance.post(TrailerChangeEndpoints.getTrailerChangesMoves(), data);
  }

  static getTrailerChangesTrips(trailerIds: string[], shipmentIds: string[]) {
    const data: GetTrailerChangeParams = {
      shipment_ids: shipmentIds,
      trailer_ids: trailerIds,
    };
    return axiosInstance.post(TrailerChangeEndpoints.getTrailerChangesTrips(), data);
  }

  static updateTrailerChanges(trips: Trip[]) {
    return axiosInstance.put(TrailerChangeEndpoints.updateTrailerChanges(), trips);
  }
}