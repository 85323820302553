import { Stack, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import { AlertTriangle, Info, CheckCircle } from 'react-feather';
import { Driver } from '../../../redux/models/settings.models';
import { DriverAvailability } from '../../../redux/models/driver.availability.models';
import { DateService } from '../../../utils/dateService';
import { getFinishTime, getShiftText, checkTripWithinDriverShift } from './driver.utils';
import { formatDriverName } from '../TripTable/utils/tripTable.utils';

function DriverCert({ title }: { title: string; }) {
  return (
    <Stack direction="horizontal" gap={2}>
      <p className="text-muted">
        {`${title}: `}
      </p>
      <CheckCircle className="text-success mb-3" size={12} />
    </Stack>
  )
}

interface Props {
  availability: DriverAvailability[];
  driver: Driver;
  numTrips: number;
  yard: string;
  start: string;
  finish: string;
}

export default function DriverInfo({ availability, driver, start, finish, numTrips, yard }: Props) {
  const driverName = formatDriverName(driver);
  const airCargo = driver.data.air_cargo;
  const bTrain = driver.data.b_train;
  const ctPat = driver.data.ct_pat;
  const dg = driver.data.dangerous_goods;
  const forklift = driver.data.forklift_certified;
  const lightDuties = driver.data.light_duties;
  const tipper = driver.data.tipper_truck;
  const allowOvertime = driver.data.allow_overtime || false;
  const maxOvertime = driver.data.max_overtime || 0;
  let shiftStart;
  let shiftFinish;
  let tripEndsInOverTime = false;
  availability.forEach((av) => {
    shiftStart = DateService.getHHmmFormat(av.data.start_time);
    shiftFinish = DateService.getHHmmFormat(av.data.finish_time);
    const adjustedFinishTime = getFinishTime(av.data.finish_time, allowOvertime, maxOvertime);
    if (finish > shiftFinish && finish < adjustedFinishTime) tripEndsInOverTime = true;
  });
  const { isWithinShift, textColour, statusMessage } = shiftStart && shiftFinish && numTrips > 0 ?
    checkTripWithinDriverShift({ start: shiftStart, end: shiftFinish }, { start, end: finish }, maxOvertime) :
    { isWithinShift: true, textColour: "text-default", statusMessage: '' };
  return (
    <OverlayTrigger overlay={(
      <Tooltip>
        <div>
          {numTrips > 0 && (
            <p className={`pt-2 text-start ${textColour} text-sm`}>
              {getShiftText(numTrips, start, finish, isWithinShift, statusMessage, tripEndsInOverTime)}
            </p>
          )}
          {availability.map((av) => {
            const startTime = DateService.getHHmmFormat(av.data.start_time);
            const finishTime = DateService.getHHmmFormat(av.data.finish_time);
            const spansMidnight = DateService.spansMidnight(startTime, finishTime);
            return (
              <Stack key={av.entity_id} direction="horizontal" gap={2}>
                <p className="text-start text-muted">
                  {`${DateService.getDay(av.data.weekday)}: ${startTime} - ${finishTime}`}
                </p>
                {spansMidnight && <Badge bg="secondary" className="mb-2">Night Shift</Badge>}
              </Stack>              
            )
          })}
          {allowOvertime && (
            <p className="text-start text-muted ml-2 mt-1">
              {`Allow Overtime: ${(maxOvertime / 3600).toFixed(2)} hrs`}
            </p>
          )}
          <p className="text-start text-muted ml-2 mt-1">
            {`Home Yard: ${yard || ''}`}
          </p>
          {airCargo && (
            <DriverCert title="Air Cargo Certified" />
          )}
          {bTrain && (
            <DriverCert title="B-Train Certified" />
          )}
          {ctPat && (
            <DriverCert title="CT-Pat Certified" />
          )}
          {dg && (
            <DriverCert title="Dangerous Goods Certified" />
          )}
          {forklift && (
            <DriverCert title="Forklift Licence" />
          )}
          {lightDuties && (
            <Stack direction="horizontal" gap={2}>
              <p className="text-muted">
                {`Light Duties: `}
              </p>
              <AlertTriangle className="text-warning mb-3" size={12} />
            </Stack>
          )}
          {tipper && (
            <DriverCert title="Tipper Certified" />
          )}
        </div>
      </Tooltip>
    )}>
      <Stack direction="horizontal" gap={2}>
        <h5 className="m-0">{driverName}</h5>
        {lightDuties || textColour !== 'text-default' ? (
          <AlertTriangle
            className={textColour}
            size="18"
          />
        ) : <Info size="18"/>}
      </Stack>
    </OverlayTrigger>
  )
}
