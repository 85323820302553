import { Col, Container, Row } from 'react-bootstrap';
import { Version } from './UpdateList';

export default function Update({ update }: { update: Version }) {
  return (
    <Container className="update-container">
      <Row><h3>{update.version}</h3></Row>
      <Row>
        <Col xs={3}><h6>Released on:</h6></Col>
        <Col>
          <Row>
            {update.date}
          </Row>
        </Col>
      </Row>
      <Row className="last-row">
        <Col xs={3}><h6>Updates:</h6></Col>
        <Col>
          <ul className="unordered-list">
            {update.updates.map((updateComments: string) => <li key={updateComments}>{updateComments}</li>)}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
