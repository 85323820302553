import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import { DateService } from "../../../../../utils/dateService";
import ButtonSpinner from "../../../../../components/shared/ButtonSpinner";
import PlaceholderValue from "../../../../../components/data-grid/base/PlaceholderValue";

const formatDateTimeLocal = (datetime: string) => {
  return DateService.getMomentDate(datetime).format('YYYY-MM-DDTHH:mm');
}

const getDateLabel = (value: string | undefined) => {
  if (value === undefined) return '';
  const today = new Date();
  const isToday = DateService.isSame(value, today, 'day');
  if (isToday) {
    return DateService.getHHmmFormat(value);
  }
  return DateService.getDoMMMHHmmFormat(value);
}

interface Props {
  value: string | undefined;
  onChange: (value: string) => void;
}

function ShipmentDateCell({ value, onChange }: Props) {
  const [editing, setEditing] = useState(false)
  const [tempValue, setTempValue] = useState(value || '')
  const [isSaving, setSaving] = useState(false);
  const handleBlur = async () => {
    setSaving(true);
    setEditing(false)
    await onChange(tempValue)
    setSaving(false);
  }
  const handleChange = (newVal: string) => {
    const date = DateService.getISOString(newVal, true);
    const utc = DateService.getUtcISOString(date);
    setTempValue(utc);
  }
  if (editing) {
    return (
      <Form.Control
        type="datetime-local"
        value={formatDateTimeLocal(tempValue)}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
        autoFocus
      />
    )
  }

  return (
    <div onClick={() => setEditing(true)} style={{ cursor: 'text' }}>
      {getDateLabel(value) || <PlaceholderValue />}
      {isSaving && <ButtonSpinner />}
    </div>
  )
}

export default ShipmentDateCell;
