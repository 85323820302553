/* eslint-disable */
import React from 'react';
import { ChangeLog, EntityContainer } from '../../../redux/models/core.models';
import { DateService } from '../../../utils/dateService';

type ArrayComparisonResult = {
  isSame: boolean;
  isCompletelyDifferent: boolean;
  additions: any[];
  removals: any[];
};

function compareArrays(oldArray: any[], newArray: any[]): ArrayComparisonResult {
  const additions = oldArray && newArray ? newArray.filter(item => !oldArray.includes(item)) : newArray || [];
  const removals = newArray && oldArray ? oldArray.filter(item => newArray && !newArray.includes(item)) : oldArray || [];
  
  const isSame = additions?.length === 0 && removals?.length === 0;
  const isCompletelyDifferent = oldArray?.length > 0 && newArray?.length > 0 && isSame === false && additions?.length === newArray?.length && removals?.length === oldArray?.length;
  
  return {
    isSame,
    isCompletelyDifferent,
    additions,
    removals,
  };
}

interface Props {
  change: ChangeLog;
  entity: string;
  users: EntityContainer<string>;
}

export default function ActivityLogListItem({ change, entity, users }: Props) {
  const { author, event_type: event, modified_at: date } = change;
  const username = users[author] || author;
  const updates = Object.keys(change.changes)
    .filter((key) => {
      const log = change.changes[key];
      const update = log.new;
      const old = log.old;
      if (Array.isArray(update) || Array.isArray(old)) {
        const comparision = compareArrays(old, update);
        return !comparision.isSame;
      }
      return update !== null && old !== null
    })
    .map((key) => {
      const log = change.changes[key];
      const update = log.new;
      const old = log.old;
      if (typeof update === null && typeof old !== null) {
        // removed
        return `removed ${key}`;
      }
      if (typeof update !== null && typeof old === null) {
        // added
        return `added ${key} as ${update}`;
      }
      const isArray = Array.isArray(update);
      if (isArray) {
        const comparision = compareArrays(old, update);
        let message = '';
        if (comparision.additions.length > 0) message = `${comparision.additions.length} ${key} added`;
        if (comparision.removals.length > 0) {
          const prefix = message.length > 0 ? ' and ' : '';
          message = `${message}${prefix}${comparision.removals.length}${key} removed`;
        }
        return message;
      }
      // both are not null
      return `Updated ${key} from ${old} to ${update}`;
    });
  return (
    <div>
      <p>{`${username} ${event}d ${entity} at ${DateService.getHHmmaFormat(date)}, ${DateService.getDDMMYYYFormat(date)}`}</p>
      <ul>
        {updates.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </ul>
    </div>
  )
};
