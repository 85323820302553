import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertCircle } from "react-feather";
import { Row, Col, ListGroup } from 'react-bootstrap';
import { AsyncDispatchNotification } from '../../redux/slices/dispatch/dispatch.models';
import { DateService } from '../../utils/dateService';
import { selectTripById } from '../../redux/slices/trips/trips';
import { AppState } from '../../redux/models/state.models';
import { AsyncState } from '../../services/notifications/Notification.models';
import { TripBoardModel } from '../../redux/models/trip.models';

const getDefaultMessage = (state: string) => {
  if (state === AsyncState.SUCCESS) return 'Successfully dispatched';
  if (state === AsyncState.ERROR) return 'Error dispatching';
  return 'Waiting to dispatch trip';
};
const getMessage = (dispatch: AsyncDispatchNotification, trip: TripBoardModel) => {
  const message = getDefaultMessage(dispatch.state);
  if (!trip) return `${message} trip`;
  const driver = trip.driver || '';
  const route = trip.route || '';
  const appt = trip.apt || '';
  return `${message} ${DateService.getHHmmaFormat(appt)} trip for ${driver} from ${route}`;
};

interface Props {
  notification: AsyncDispatchNotification;
}

function NavbarDispatchNotificationItem({ notification }: Props) {
  const tripId = notification.trip_id || '';
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const trip = useSelector((state: AppState) => selectTripById(state, tripId));
  const icon = <AlertCircle size={18} className="text-primary" />;
  const created = DateService.getISOString(notification.created_at);
  const timestamp = DateService.toRelativeTime(created);
  const spacing = false;
  const title = notification.message || '';
  const description = getMessage(notification, trip);
  const handleClick = () => {
    const { trip_id: id } = notification;
    navigate(`/${orgCode}/trips/${id}`);
  }
  return (
    <ListGroup.Item onClick={handleClick}>
      <Row className="align-items-center g-0">
        <Col xs={2}>{icon}</Col>
        <Col xs={10} className={spacing ? 'pl-2' : ''}>
          <div className="text-dark">{title}</div>
          <div className="text-muted small mt-1">{description}</div>
          <div className="text-muted small mt-1">{timestamp}</div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

export default NavbarDispatchNotificationItem;
