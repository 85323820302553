
export class DriverAvailabilityEndpoints {
  static entity = 'driver-trips';

  static addDiverTrip(driverId: string) {
    return `/${DriverAvailabilityEndpoints.entity}/trip/${driverId}`;
  } 

  static removeDriverTrips(tripId: string) {
    return `/${DriverAvailabilityEndpoints.entity}/trip/remove/${tripId}`;
  } 
}
