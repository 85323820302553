/* eslint-disable import/no-cycle */
import { Contact } from "./contact.models";
import { DataModel, EntityContainer } from "./core.models";
import { Shipment } from "./shipment.models";
import { getFilterValueFromSessionStorage } from '../../utils/core.utils';
import { DateService } from "../../utils/dateService";

const DEFAULT_DATE_RANGE = 'Today';

export const FILTER_KEY = 'invoice-list-filters';
export const INVOICE_PAGE_SIZE = 25;

export interface InvoiceFilters {
  dateFrom: string;
  dateTo: string;
  dateRange: string;
  invoice: string;
  customer: string;
  status: string;
  page: number;
  page_size: number;
  total: number;
};

export const defaultFilters: InvoiceFilters = {
  dateFrom: DateService.getStartOfTheDayISO(new Date()),
  dateTo: DateService.getEndOfTheDayISO(new Date()),
  dateRange: DEFAULT_DATE_RANGE,
  invoice: '',
  customer: '',
  status: '',
  page: 0,
  page_size: INVOICE_PAGE_SIZE,
  total: 0,
};

export const getSessionFilters = () => {
  const sessionFilterString = getFilterValueFromSessionStorage(FILTER_KEY);
  return sessionFilterString ? JSON.parse(sessionFilterString) : defaultFilters;
};

export interface LineItemData {
  description: string;
  invoice_id: string;
  shipment_id: string;
  gl_code_id?: string;
  price: number;
  quantity: number;
  type: string;
  sub_total: number;
  tax_amount?: number | null;
  tax_id?: string | null;
  tax_name?: string | null;
  tax_rate?: number | null;
  total: number;
  uom_id: string;
}

export type LineItem = DataModel<LineItemData>;

export interface InvoiceData {
  bill_to: Contact;
  currency: string;
  customer: Contact;
  status: string;
  invoice_no: string;
  date: string;
  shipments: Shipment[];
  shipment_ids?: string[];
  line_items: LineItem[];
  total: number;
  sub_total: number;
  tax_amount: number;
}

export type Invoice = DataModel<InvoiceData>;

export interface InvoiceListView {
  id: string;
  customer: string;
  invoice_no: string;
  status: string;
  shipment_no: string[];
  date: string;
  unix: number;
}

export interface InvoiceState {
  areInvoicesLoading: boolean;
  isLoadingNextPage: boolean;
  isListLoaded: boolean;
  container: EntityContainer<Invoice>;
  list: InvoiceListView[];
  areInvoiceDetailsLoading: boolean;
  invoiceDetails: Invoice | null,
  filterParams: InvoiceFilters,
};

const initialState: InvoiceState = {
  areInvoicesLoading: true,
  isListLoaded: false,
  isLoadingNextPage: false,
  container: {},
  list: [],
  areInvoiceDetailsLoading: true,
  invoiceDetails: null,
  filterParams: getSessionFilters(),
};

export const getInitialState = (): InvoiceState => ({
  ...initialState,
  filterParams: getSessionFilters(),
});
