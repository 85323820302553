/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';

import TripForm from './TripForm';
import MovesTable from './Moves/MovesTable';
import TripShipments from './TripShipments/TripShipments';
import MoveHeader from './Moves/MoveHeader';
import MoveFooter from './Moves/MoveFooter';

import {
  selectGroups, selectVehicles, selectBoards, selectDriversByTags, selectAddressesObject, selectFleetObject,
} from '../../redux/slices/settings';

import { createDropdownOptions, createShipmentIdsDropdownOptions } from '../../utils/core.utils';
import {
  saveTripDetails, updateTripDetailsMoves, setTripDetails, updateTripDistance,
  updateTripDetails,
  addMove
} from '../../redux/slices/trips/trips';
import { TRIP_STATUS_TYPES } from './constants/trips.constants';
import {
  resetTrailerChanges,
  selectIsTrailersChanged,
  updateTrailerState,
} from '../../redux/slices/trailer-changes/trailerChanges';
import PageFooterButtons from '../../components/shared/PageFooterBtns';
import { Board } from '../../redux/models/board.models';
import { Group } from '../../redux/models/settings.models';
import { AppState } from '../../redux/models/state.models';
import { isPermissions } from '../../redux/slices/settings.utils';
import TripDetailsHeader from './TripDetailsHeader';
import { Move, Trip, TripDetails as TripDetailsModel } from '../../redux/models/trip.models';
import MovePanel from './Moves/MovePanel';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import DriverSuggestions from './DriverSuggestions/DriverSuggestions';
// import { updateDriverTrip } from '../../redux/slices/availability/driver.availability';
import { FeatureResource, ResourcePermission } from '../../redux/models/feature.flags.models';
import { getPermissions } from '../../config';
import DeleteTripModal from './components/DeleteTripModal';
import Permission from '../../components/shared/permissions/Permission';
import CostCard from './components/cost-card/CostCard';
import { CostReadPermissions } from '../../components/shared/permissions/permissions.utils';
import { loadDefaultFuelRate } from '../../components/costs/cost.utils';
import Analytics from '../../utils/analytics';
import {
  addDefaultDispatch,
  isTripMoveMissingNotes,
  handleMoveItemDateChange,
  handleMoveDestinationChange,
  handleDeleteMove,
  handleMoveItemChange,
  getBoardVehicles,
  getBoardFleet
} from './utils/trips.utils';

const getDefaultFuelRate = (trip: TripDetailsModel) => {
  return trip.data.fuel_rate || loadDefaultFuelRate() || 0;
}

interface Props {
  shouldDisableSave: boolean;
  tripDetails: TripDetailsModel;
}

const WriteTripPermissions = {
  [FeatureResource.Trip]: ResourcePermission.Write,
};

export default function TripDetails({ tripDetails, shouldDisableSave }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const features = useFeatureFlags();
  const { orgCode } = useParams();
  const permissions = getPermissions();
  const isEditable = isPermissions(orgCode, permissions, WriteTripPermissions);

  const boards = useSelector(selectBoards);
  const groups = useSelector(selectGroups);
  const vehicles = useSelector(selectVehicles);
  const addresses = useSelector(selectAddressesObject);
  const fleet = useSelector(selectFleetObject);
  const isTrailersChanged = useSelector(selectIsTrailersChanged);
  
  const [selectedMovePosition, setSelectedMovePosition] = useState<number | null>(null);

  const isSuggestionsEnabled = features.driver_availability?.is_enabled || false;
  const isCostsEnabled = features.trip_costs?.is_enabled || false;
  const showMovesMenu = selectedMovePosition !== null;
  const currentBoard = boards.find((board: Board) => board.entity_id === tripDetails.data.board_id);
  const currentBoardDriverTags = currentBoard?.data?.driver_tags || [];
  const currentVehcileTags = currentBoard?.data?.vehicle_tags || [];
  const fleetTags = currentBoard?.data?.fleet_tags;
  const dispatchTemplates = currentBoard?.data.note_templates || [];
  const boardGroups = groups.filter((group: Group) => group.data.board_id === tripDetails.data.board_id);
  const driversByTagId = useSelector((state: AppState) => selectDriversByTags(state, currentBoardDriverTags));
  const boardVehicles = getBoardVehicles(vehicles, currentVehcileTags)
  const statusSelectOptions = TRIP_STATUS_TYPES;
  const TRIP_FORM_ID = 'tripDetails';

  const boardSelectOptions = createDropdownOptions(boards);
  const groupSelectOptions = createDropdownOptions(boardGroups);
  const vehicleSelectOptions = createDropdownOptions(boardVehicles, 'samsara_name');
  const driverSelectOptions = createDropdownOptions(driversByTagId, 'samsara_name');
  
  useEffect(() => {
    dispatch(updateTrailerState(tripDetails.data.moves));
  }, []);

  const defaultFuelRate = getDefaultFuelRate(tripDetails);
  const [isSaving, setIsSaving] = useState(false);
  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);
  const [showTrailerSaveModal, setShowTrailerSaveModal] = useState(false);
  const [showTrailerChangeModal, setShowTrailerChangeModal] = useState(false);
  const [fuelRate, setFuelRate] = useState<number>(defaultFuelRate);

  const moves = tripDetails.data.moves || [];
  const shipments = tripDetails.data.shipments || [];
  const selectedMove = moves.find((mv: Move) => {
    const mvPos = mv.data?.position || 0;
    return mvPos === selectedMovePosition
  });
  const sortedMoves = [...moves].sort((a, b) => (a.data?.position || 0) - (b.data?.position || 0));
  const boardFleet = getBoardFleet(Object.values(fleet), fleetTags);
  const fleetDropdownOptions = createDropdownOptions(boardFleet);

  const addressesObject = useSelector(selectAddressesObject);
  const addressesDropdownOptions = createDropdownOptions(
    Object.values(addresses),
    'samsara_name',
    'samsara_formatted_address',
  );
  const shipmentIdsDropdownOptions = createShipmentIdsDropdownOptions(shipments, addressesObject, 'shipment_no');

  const handleUpdateTrip = (trip: TripDetailsModel) => {
    dispatch(updateTripDetails(trip));
  };
  const handleUpdateMoves = (moveData: Move[]) => {
    dispatch(updateTripDetailsMoves(moveData));
  };
  const handleCancel = () => {
    if (tripDetails.data.board_id === '') {
      toast('Trip must be assigned to a board.', { type: 'error' });
      return;
    }
    navigate(-1);
  };

  const handleSave = async () => {
    if (tripDetails.data.board_id === '') {
      toast('Trip must be assigned to a board.', { type: 'error' });
      return;
    }
    setIsSaving(true);
    try {
      const isMissingNotes = isTripMoveMissingNotes(tripDetails);
      const dispatchUpdates = isMissingNotes ? addDefaultDispatch(dispatchTemplates, tripDetails) : tripDetails;
      await saveTripDetails(dispatchUpdates);
      if (isSuggestionsEnabled && moves.length >= 2) {
        // const movesValid = moves.filter((mv) => {
        //   const { destination_id: addrId, scheduled_arrival_time: date } = mv.data;
        //   return addrId && addrId !== '' && date && date !== '';
        // }).length > 1;
        // const newDriverId = tripDetails.data.driver_id || '';
        // const tripId = tripDetails.entity_id;
        // if (movesValid) await updateDriverTrip(newDriverId, originalDriverId, tripId);
      }
      dispatch(resetTrailerChanges());
      Analytics.saveTrip(orgCode);
      navigate(-1);
    } catch (error: any) {
      const errorMessage = `Couldn't save trip. ${error.message}. Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveWithTrlChange = () => {
    if (isTrailersChanged) {
      return setShowTrailerSaveModal(true);
    }
    return handleSave();
  };

  const handleModalClose = () => {
    setShowTrailerChangeModal(false);
    setShowTrailerSaveModal(false);
  };

  // on Modal open, update the moves from the API to new trailer & move info from tripDetails
  const handleChangeModals = () => {
    if (!showTrailerSaveModal) setShowTrailerChangeModal(true);
    if (showTrailerSaveModal) {
      setShowTrailerSaveModal(false);
      setShowTrailerChangeModal(true);
    }
  };

  const onMoveItemChange = (movePosition: number) => (prop: string, value: string) => {
    const updatedMovesData = handleMoveItemChange(movePosition, sortedMoves)(prop, value);
    dispatch(updateTripDetailsMoves(updatedMovesData));
  };

  const onMoveDestinationChange = (movePosition: number) => (prop: string, value: string) => {
    const updatedMovesData = handleMoveDestinationChange(movePosition, sortedMoves)(prop, value);
    dispatch(updateTripDetailsMoves(updatedMovesData));
    const nullAddress = updatedMovesData.some((move) => move.data.destination_id === '');
    if (!nullAddress) dispatch(updateTripDistance(updatedMovesData, addresses));
  };

  const onDeleteMove = (position: number) => {
    const updatedMovesData = handleDeleteMove(position, sortedMoves);
    setSelectedMovePosition(null);
    dispatch(updateTripDetailsMoves(updatedMovesData));
  };

  const onMoveItemDateChange = (movePosition: number) => (prop: string, value: string) => {
    const updatedMovesData = handleMoveItemDateChange(movePosition, sortedMoves)(prop, value);
    dispatch(updateTripDetailsMoves(updatedMovesData));
  };

  // const handleNewAddress = (prop: string, addr: Address, position: number) => {
  //   const updatedMovesData = sortedMoves.map((move) => {
  //     if (move.data.position === position) {
  //       const mv: Move = {
  //         ...move,
  //         data: {
  //           ...move.data,
  //           destination_id: addr.entity_id,
  //         },
  //       };
  //       return mv;
  //     }
  //     return move;
  //   });
  //   dispatch(updateTripDetailsMoves(updatedMovesData));
  // };
  const handleSaveAndUpdate = async (trDetails: Trip) => {
    try {
      const dispatchUpdates = addDefaultDispatch(dispatchTemplates, trDetails);
      const { data, status } = await saveTripDetails(dispatchUpdates);
      if (status === 200) {
        dispatch(setTripDetails(data));
      } else {
        const message = data.description || '';
        throw new Error(message);
      }
    } catch (error: any) {
      throw new Error(error?.message || '');
    }
  };

  return (
    <>
      <div>
        <Row>
          <Col md={showMovesMenu ? 8 : 12}>
            <TripDetailsHeader
              tripDetails={tripDetails}
              isEditable={isEditable}
              onDelete={() => setShouldShowDeleteModal(true)}
              saveTrip={handleSaveAndUpdate}
            />
            <TripForm
              isEditable={isEditable}
              page={TRIP_FORM_ID}
              tripDetails={tripDetails}
              boardSelectOptions={boardSelectOptions}
              groupSelectOptions={groupSelectOptions}
              driverSelectOptions={driverSelectOptions}
              vehicleSelectOptions={vehicleSelectOptions}
              statusSelectOptions={statusSelectOptions}
              onUpdateTrip={handleUpdateTrip}
              onUpdateMoves={handleUpdateMoves}
            />
            {isSuggestionsEnabled && tripDetails && (
              <DriverSuggestions tripDetails={tripDetails} drivers={driversByTagId} />
            )}
            {isEditable && (
              <MoveFooter
                tripId={tripDetails.entity_id}
                handleSave={handleSave}
                showTrailerSaveModal={showTrailerSaveModal}
                handleChangeModals={handleChangeModals}
                showTrailerChangeModal={showTrailerChangeModal}
                shouldDisableSave={shouldDisableSave}
                isSaving={isSaving}
                handleModalClose={handleModalClose}
                showTrailers={isTrailersChanged}
              />
            )}
            {isEditable && (
              <MoveHeader
                tripDetails={tripDetails}
                shouldDisableSave={shouldDisableSave}
                dispatchTemplates={dispatchTemplates}
                onAddMove={(mv) => dispatch(addMove(mv))}
              />
            )}
            <MovesTable
              isEditable={isEditable}
              tripDetails={tripDetails}
              fleetOptions={fleetDropdownOptions}
              shipmentIdsDropdownOptions={shipmentIdsDropdownOptions}
              moves={sortedMoves}
              page={TRIP_FORM_ID}
              addressOptions={addressesDropdownOptions}
              showShipments
              setSelectedMove={setSelectedMovePosition}
              onMoveItemChange={onMoveItemChange}
              onMoveItemDateChange={onMoveItemDateChange}
              // onMoveDestinationChange={onMoveDestinationChange}
              // handleNewAddress={handleNewAddress}
            />
            <TripShipments
              page={TRIP_FORM_ID}
              tripDetails={tripDetails}
              shipments={tripDetails.data.shipments}
              isEditable={isEditable}
            />
            {isCostsEnabled && tripDetails && (
              <Permission resources={CostReadPermissions}>
                <CostCard trip={tripDetails} fuelRate={fuelRate} setFuelRate={setFuelRate} />
              </Permission>
            )}
          </Col>
          {showMovesMenu && selectedMove ? (
            <Col md={4}>
              <MovePanel
                showShipments
                move={selectedMove}
                addressOptions={addressesDropdownOptions}
                fleetOptions={fleetDropdownOptions}
                shipmentIdsDropdownOptions={shipmentIdsDropdownOptions}
                routeId={tripDetails.data.samsara_route_id || ''}
                noteTemplates={dispatchTemplates}
                onMoveItemChange={onMoveItemChange}
                onMoveItemDateChange={onMoveItemDateChange}
                onMoveDestinationChange={onMoveDestinationChange}
                setSelectedMove={setSelectedMovePosition}
                deleteMove={onDeleteMove}
              />
            </Col>
          ) : null}
        </Row>
      </div>
      <PageFooterButtons
        disabled={isSaving}
        page={TRIP_FORM_ID}
        deleteButton={false}
        handleSave={handleSaveWithTrlChange}
        handleClose={handleCancel}
        handleDelete={() => setShouldShowDeleteModal(true)}
        isSaving={isSaving}
        isEditable={isEditable}
      />
      {shouldShowDeleteModal && (
        <DeleteTripModal
          shouldShow={shouldShowDeleteModal}
          trip={tripDetails}
          onCancel={() => setShouldShowDeleteModal(false)}
        />
      )}
    </>
  );
}
