/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import AddEntityModal from '../base/modals/AddEntityModal';
import { ControlInput, InputSwitch, SelectInput } from '../../shared/Input';
import Permission from '../../shared/permissions/Permission';
import { BillingTypeWritePermissions } from '../../shared/permissions/permissions.utils';
import { getSettingByName, selectUoms } from '../../../redux/slices/settings';
import { Api } from '../../../services/services';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import { BillingItem, BillingItemData } from '../../../redux/models/billing.models';
import UomInput from '../uom/UomInput';
import { DataModel } from '../../../redux/models/core.models';
import {
  DepartmentCode, DepartmentCodeData, GlCode, GlCodeData, UOM, UomData
} from '../../../redux/models/settings.models';
import { createDropdownOptions } from '../../../utils/core.utils';
import GLCodeInput from '../gl-code/GLCodeInput';
import DepartmentCodeInput from '../department-code/DepartmentCodeInput';
import { DEFAULT_API_QUERY } from '../../../redux/models/network.models';
import { CURRENCY_OPTIONS, initialData } from './data';

const PAGE = 'add_billing_item_modal';
const TITLE = 'Rate';
const getDefaultUom = (rate: BillingItem | undefined, uoms: UOM[]): UOM | null => {
  const id = rate?.data.uom_id || rate?.data.uom?.entity_id;
  if (id) {
    return uoms.find((om) => om.entity_id === id) || null;
  }
  return null;
};
const getDefaultGl = (rate: BillingItem | undefined, gls: GlCode[]): GlCode | null => {
  const selectedGl = rate?.data.gl;
  if (selectedGl) return selectedGl;
  const id = rate?.data.gl_code_id;
  if (id) {
    return gls.find((gl) => gl.entity_id === id) || null;
  }
  return null;
};
const getDefaultDep = (rate: BillingItem | undefined, deps: DepartmentCode[]): DepartmentCode | null => {
  const selectedDep = rate?.data.department;
  if (selectedDep) return selectedDep;
  const id = rate?.data.department_code_id;
  if (id) {
    return deps.find((dep) => dep.entity_id === id) || null;
  }
  return null;
};
interface Props {
  rate?: BillingItem,
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: (contact: BillingItem, event: string) => void;
}

function BillingItemModal({ rate, show, setShow, onUpdate }: Props) {
  const dispatch = useAppDispatch();

  const uoms = useSelector(selectUoms);
  const [deps, setDeps] = useState<DepartmentCode[]>([]);
  const [gls, setGls] = useState<GlCode[]>([]);
  
  const defaultSelectedUom = getDefaultUom(rate, uoms);
  const defaultSelectedGl = getDefaultGl(rate, []);
  const defaultSelectedDep = getDefaultDep(rate, []);
  const [department, setSelectedDepartment] = useState<DepartmentCode | null>(defaultSelectedDep);
  const [gl, setSelectedGl] = useState<GlCode | null>(defaultSelectedGl);
  const [uom, setSelectedUom] = useState<UOM | null>(defaultSelectedUom);
  const [isSaving, setIsSaving] = useState(false);
  const [description, setDescription] = useState(rate?.data?.description || '');
  const [price, setPrice] = useState(rate?.data?.price || 0);
  const [itemCode, setItemCode] = useState(rate?.data?.item_code || '');
  const [currency, setCurrency] = useState(rate?.data?.currency || CURRENCY_OPTIONS[0].value);
  const [accessorial, setAccessorial] = useState(rate?.data?.accessorial || false);
  const isDisabled = description === '' ||
    itemCode === '' ||
    department === null ||
    gl === null ||
    currency === '' ||
    uom === null;
  const uomOptions = createDropdownOptions(uoms);
  const depOptions = createDropdownOptions(deps);
  const glOptions = createDropdownOptions(gls);

  const getEntityData = (): BillingItemData => {
    return {
      ...initialData,
      description,
      gl,
      department,
      uom,
      item_code: itemCode,
      currency,
      price,
    };
  };
  const handleSwitch = (_: string, checked: boolean) => {
    setAccessorial(checked);
  };
  const handleNewUom = (_: string, entity: DataModel<UomData>) => {
    setSelectedUom(entity);
  };
  const handleNewGlCode = (_: string, entity: DataModel<GlCodeData>) => {
    setSelectedGl(entity);
  };
  const handleNewDepartmentCode = (_: string, entity: DataModel<DepartmentCodeData>) => {
    setSelectedDepartment(entity);
  };
  const handleChange = (prop: string, val: string) => {
    if (prop === 'description') setDescription(val);
    if (prop === 'price') setPrice(Number(val));
    if (prop === 'item_code') setItemCode(val);
    if (prop === 'gl_code_id') {
      const glCode = gls.find((item) => item.entity_id === val);
      if (glCode) setSelectedGl(glCode);
    }
    if (prop === 'department_code_id') {
      const dep = deps.find((item) => item.entity_id === val);
      if (dep) setSelectedDepartment(dep);
    }
    if (prop === 'currency') setCurrency(val);
    if (prop === 'uom_id') {
      const uomCode = uoms.find((item) => item.entity_id === val);
      if (uomCode) setSelectedUom(uomCode);
    }
  };
  const handleCreate = async () => {
    const entityData = getEntityData();
    return Api.BillingItems.create(entityData);
  };
  const handleUpdate = () => {
    if (!rate) {
      throw Error(`missing ${TITLE}`);
    }
    const entity: BillingItem = {
      ...rate,
      data: {
        ...rate.data,
        ...getEntityData(),
      },
    };
    return Api.BillingItems.update(entity);
  };
  const handleResponse = async (response: AxiosResponse<any, any>, event: string) => {
    if (response.status === 200) {
      await dispatch(getSettingByName(SETTINGS_NAMES.BILLING_ITEMS, false));
      toast(`${description} updated.`, { type: 'success' });
      if (onUpdate) {
        const entity = response.data.data;
        onUpdate(entity, event);
      }
      setShow(false);
    }
  }
  const handleSave = async () => {
    if (isDisabled) return;
    try {
      setIsSaving(true);
      if (rate) {
        const response = await handleUpdate();
        handleResponse(response, 'add');
      } else {
        const response = await handleCreate();
        handleResponse(response, 'update');
      }
    } catch (error) {
      toast(`Couldn't update ${description}`, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  useEffect(() => {
    const load = async () => {
      try {
        const glResponse = await Api.GlCodes.find(DEFAULT_API_QUERY);
        const depResponse = await Api.DepartmentCodes.find(DEFAULT_API_QUERY);
        if (glResponse.status === 200) {
          const glData: GlCode[] = glResponse.data.data.items || [];
          setGls(glData);
          const defgl = getDefaultGl(rate, glData);
          setSelectedGl(defgl);
        }
        if (depResponse.status === 200) {
          const depData: DepartmentCode[] = depResponse.data.data.items || [];
          setDeps(depData);
          const defdep = getDefaultDep(rate, depData);
          setSelectedDepartment(defdep);
        }
      } catch (error) {
        console.log(error);
      }
    };
    load();
  }, []);
  return (
    <Permission resources={BillingTypeWritePermissions}>
      <AddEntityModal
        title={`Add ${TITLE}`}
        btnTitle="Save"
        saveDisabled={isDisabled}
        shouldShowModal={show}
        isSaving={isSaving}
        handleSave={handleSave}
        cancelSave={() => setShow(false)}
      >
        <Stack>
          <ControlInput
            dataCy={`${PAGE}_description_input`}
            name="description"
            type="text"
            page={PAGE}
            value={description}
            labelText="Description"
            handleChange={handleChange}
          />
          <ControlInput
            dataCy={`${PAGE}_price_input`}
            name="price"
            type="number"
            page={PAGE}
            value={`${price}`}
            labelText="Price"
            handleChange={handleChange}
          />
          <DepartmentCodeInput
            value={department?.entity_id || ''}
            page={PAGE}
            prop="department_code_id"
            label="Department Code"
            disabled={false}
            options={depOptions}
            onChange={handleChange}
            onNewEntity={handleNewDepartmentCode}
          />
          <GLCodeInput
            value={gl?.entity_id || ''}
            page={PAGE}
            prop="gl_code_id"
            label="GL Code"
            disabled={false}
            options={glOptions}
            onChange={handleChange}
            onNewEntity={handleNewGlCode}
          />
          <ControlInput
            dataCy={`${PAGE}_item_code_input`}
            name="item_code"
            type="text"
            page={PAGE}
            value={itemCode}
            labelText="Item Code"
            handleChange={handleChange}
          />
          <SelectInput
            name="currency"
            page={PAGE}
            value={currency}
            options={CURRENCY_OPTIONS}
            shouldHaveMargin={false}
            handleChange={handleChange}
            dataCy={`${PAGE}_currency_input`}
            labelText="Currency"
            multiple={false}
          />
          <UomInput
            value={uom?.entity_id || ''}
            page={PAGE}
            prop="uom_id"
            label="Unit Of Measure"
            disabled={false}
            options={uomOptions}
            onChange={handleChange}
            onNewEntity={handleNewUom}
          />
          <InputSwitch
            labelText="Accessorial"
            name="accessorial"
            page="accessorial"
            handleChange={handleSwitch}
            checked={accessorial}
            className=""
            dataCy={`${PAGE}_accessorial_input`}
            disabled={false}
          />
        </Stack>
      </AddEntityModal>
    </Permission>
  )
};

BillingItemModal.defaultProps = {
  rate: undefined,
}

export default BillingItemModal;