/* eslint-disable import/no-cycle */
import { ShipmentListFilters } from '../redux/models/shipment.models';

const getBoardIdQueryParam = (boardId: string) => (boardId ? `board_id=${boardId}` : '');

const getAssigneeIdQueryParam = (assigneeId: string) => (assigneeId ? `assignee_id=${assigneeId}` : '');

const getCustomerIdQueryParam = (customerId: string) => (customerId ? `customer_id=${customerId}` : '');

const getLoadTypeQueryParam = (billingTypeId: string) => (billingTypeId ? `billing_type_id=${billingTypeId}` : '');

const getTypeQueryParam = (type: string) => (type ? `type=${type}` : '');

const getTypeIdQueryParam = (type: string) => (type ? `type_id=${type}` : '');

const getContainerNoQueryParam = (type: string) => (type ? `container_no=${type}` : '');

const getPurchaseOrderNoQueryParam = (type: string) => (type ? `purchase_order_no=${type}` : '');

const getOriginIdQueryParam = (originId: string) => (originId ? `origin_id=${originId}` : '');

export const getDestinationIdQueryParam = (destinationId: string) => {
  return destinationId ? `destination_id=${destinationId}` : '';
};

const getDateFromQueryParam = (dateFrom: string) => (dateFrom ? `start_date=${dateFrom}` : '');

const getDateToQueryParam = (dateTo: string) => (dateTo ? `end_date=${dateTo}` : '');

const getStatusToQueryParam = (status: string) => (status ? `status=${status}` : '');

const getStatus = (status: string) => (status ? `status=${status}` : '');

export const getTripsQueryString = (boardId: string, { dateFrom, dateTo }: { dateFrom: string; dateTo: string }) => {
  const FILTERS_QUERY_OBJECT = {
    boardId: getBoardIdQueryParam(boardId),
    dateFrom: getDateFromQueryParam(dateFrom),
    dateTo: getDateToQueryParam(dateTo),
  };
  return Object.values(FILTERS_QUERY_OBJECT).filter((queryParam) => queryParam).join('&');
};

export const getGroupsQueryString = (boardId: string) => {
  const FILTERS_QUERY_OBJECT = {
    boardId: getBoardIdQueryParam(boardId),
  };
  return Object.values(FILTERS_QUERY_OBJECT).filter((queryParam) => queryParam).join('&');
};

export const getShipmentQueryString = (filters: ShipmentListFilters) => {
  const FILTERS_QUERY_OBJECT = {
    assigneeId: getAssigneeIdQueryParam(filters.assigneeId),
    customerId: getCustomerIdQueryParam(filters.customer),
    loadType: getLoadTypeQueryParam(filters.loadType),
    containerNo: getContainerNoQueryParam(filters.containerNo),
    purchaseOrderNo: getPurchaseOrderNoQueryParam(filters.purchaseOrder),
    type: getTypeQueryParam(filters.shipmentType),
    typeId: getTypeIdQueryParam(filters.shipmentTypeId),
    originId: getOriginIdQueryParam(filters.origin),
    status: getStatus(filters.status),
    destinationId: getDestinationIdQueryParam(filters.destination),
    dateFrom: getDateFromQueryParam(filters.dateFrom),
    dateTo: getDateToQueryParam(filters.dateTo),
  };
  return Object.values(FILTERS_QUERY_OBJECT).filter((queryParam) => queryParam).join('&');
};

export const getInvoiceQueryString = ({
  customerId,
  status,
  dateFrom,
  dateTo,
} : {
  customerId: string;
  status: string;
  dateFrom: string;
  dateTo: string;
}) => {
  const FILTERS_QUERY_OBJECT = {
    customerId: getCustomerIdQueryParam(customerId),
    status: getStatusToQueryParam(status),
    dateFrom: getDateFromQueryParam(dateFrom),
    dateTo: getDateToQueryParam(dateTo),
  };
  return Object.values(FILTERS_QUERY_OBJECT).filter((queryParam) => queryParam).join('&');
};
