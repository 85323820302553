import { BreadCrumbRoute } from "../../../components/shared/breadcrumbs/BreadCrumbs";
import { Driver } from "../../../redux/models/settings.models";

export const PAGE_SIZE = 6;

export const getRemainingDrivers = (drivers: Driver[]) => {
  const numDrivers = drivers.length;
  if (numDrivers === 0) return 0;
  if (numDrivers > PAGE_SIZE) {
    return drivers.length - PAGE_SIZE;
  }
  if (numDrivers < PAGE_SIZE) {
    return PAGE_SIZE - drivers.length;
  }
  return 0
};

export const getDriverName = (driver: Driver | null) => {
  if (!driver) return '';
  return driver.data.samsara_name || driver.data.samsara_username || 'No name';
};

const getPath = (orgCode: string | undefined, driver: Driver) => {
  return `/${orgCode}/settings/drivers/${driver.entity_id}`;
}

export const getDriverRoutes = (orgCode: string | undefined, driver: Driver | null): BreadCrumbRoute[] => {
  const routes = [
    {
      name: 'Settings',
      route: `/${orgCode || ''}/settings`,
    },
    {
      name: 'Drivers',
      route: `/${orgCode}/settings/drivers`,
    },
  ];
  if (driver) {
    const driverName = getDriverName(driver);
    const driverPath = getPath(orgCode, driver);
    routes.push({
      name: driverName,
      route: driverPath,
    });
  }
  return routes;
};

export const getApplyDriverShiftRoutes = (orgCode: string | undefined, driver: Driver | null): BreadCrumbRoute[] => {
  const routes = getDriverRoutes(orgCode, driver);
  if (driver) {    
    const driverPath = getPath(orgCode, driver);
    routes.push({
      name: 'Apply Availability',
      route: `${driverPath}/apply-availability`,
    });
  }
  return routes;
};
