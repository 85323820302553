/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Row, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import AddEntityModal from '../base/modals/AddEntityModal';
import { ControlInput, InputSwitch } from '../../shared/Input';
import Permission from '../../shared/permissions/Permission';
import { BillingTypeWritePermissions } from '../../shared/permissions/permissions.utils';
import { getSettingByName } from '../../../redux/slices/settings';
import { Api } from '../../../services/services';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import { DriverAvailability, DriverAvailabilityData } from '../../../redux/models/driver.availability.models';
import { Driver } from '../../../redux/models/settings.models';
import { DateService } from '../../../utils/dateService';
import { DAYS } from './data';

const PAGE = 'add_contact_modal';

interface Props {
  availability: DriverAvailability | undefined;
  driver: Driver;
  day: number;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: (av: DriverAvailability | null, day: number) => void;
}

function DriverAvailabilityModal({ availability, day, driver, show, setShow, onUpdate }: Props) {
  const dispatch = useAppDispatch();
  const isData = availability !== undefined;
  const defaultStart = isData ? DateService.getHHmmFormat(availability.data.start_time) : '';
  const defaultFinish = isData ? DateService.getHHmmFormat(availability.data.finish_time) : '';
  const [isSaving, setIsSaving] = useState(false);
  const [onShift, setOnShift] = useState(availability !== undefined);
  const [startTime, setStartTime] = useState(defaultStart);
  const [finishTime, setFinishTime] = useState(defaultFinish);
  const isDisabled = !onShift ? false : (startTime === '' || finishTime === '');
  const name = DAYS[day] || '';
  const getData = (): DriverAvailabilityData => {
    return {
      driver,
      weekday: day,
      finish_time: DateService.convertLocalTimeToUTCISO(finishTime),
      start_time: DateService.convertLocalTimeToUTCISO(startTime),
    };
  };
  const handleOnShift = (_: string, checked: boolean) => {
    setOnShift(checked);
  }
  const handleDate = (prop: string, val: string) => {
    if (prop === 'start_time') setStartTime(val);
    if (prop === 'finish_time') setFinishTime(val);
  };
  const handleCreate = async () => {
    const entityData: DriverAvailabilityData = {
      ...getData(),
    };
    return Api.Availability.create(entityData);
  };
  const handleUpdate = () => {
    if (!availability) {
      throw Error(`missing ${DAYS[day]}`);
    }
    const entity: DriverAvailability = {
      ...availability,
      data: {
        ...availability.data,
        ...getData(),
      },
    };
    return Api.Availability.update(entity);
  };
  const handleResponse = async (response: AxiosResponse<any, any>) => {
    if (response.status === 200) {
      await dispatch(getSettingByName(SETTINGS_NAMES.CONTACTS, false));
      toast(`${name}'s shift updated.`, { type: 'success' });
      if (onUpdate) {
        const entity = response.data.data;
        onUpdate(entity, day);
      }
      setShow(false);
    }
  }
  const handleSave = async () => {
    if (isDisabled) return;
    try {
      setIsSaving(true);
      if (!onShift) {
        if (availability) await Api.Availability.delete(availability);
        onUpdate(null, day);
        setShow(false);
      } else if (onShift && availability) {
        const response = await handleUpdate();
        handleResponse(response);
      } else {
        const response = await handleCreate();
        handleResponse(response);
      }
    } catch (error) {
      toast(`Couldn't update ${name}'s shift`, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <Permission resources={BillingTypeWritePermissions}>
      <AddEntityModal
        title={DAYS[day]}
        btnTitle="Save"
        saveDisabled={isDisabled}
        shouldShowModal={show}
        isSaving={isSaving}
        handleSave={handleSave}
        cancelSave={() => setShow(false)}
      >
        <Stack>
          <Row>
            <h6>On Shift</h6>
            <InputSwitch
              labelText=""
              name="on_shift"
              page={PAGE}
              handleChange={handleOnShift}
              checked={onShift}
              dataCy="on_shift"
              className=""
              disabled={false}
            />
          </Row>
          {onShift && (
            <ControlInput
              dataCy={`${PAGE}_start_input`}
              name="start_time"
              type="time"
              page={PAGE}
              value={startTime}
              labelText="Start Time"
              handleChange={handleDate}
            />
          )}
          {onShift && (
            <ControlInput
              dataCy={`${PAGE}_start_input`}
              name="finish_time"
              type="time"
              page={PAGE}
              value={finishTime}
              labelText="Finish Time"
              handleChange={handleDate}
            />
          )}
        </Stack>
      </AddEntityModal>
    </Permission>
  )
};

export default DriverAvailabilityModal;
