import React, { useState } from 'react';
import { Badge, Button, Form, Stack, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectDriversObject } from '../../../redux/slices/settings';
import { EntityContainer } from '../../../redux/models/core.models';
import { Driver, Tag } from '../../../redux/models/settings.models';
import { getDriverName, getRemainingDrivers, PAGE_SIZE } from '../utils/driver-details.utils';

interface Props {
  driver: Driver;
  selectedDrivers: EntityContainer<boolean>;
  selectedTags: EntityContainer<boolean>
  tags: EntityContainer<Tag>;
  setSelectedDrivers: React.Dispatch<React.SetStateAction<EntityContainer<boolean>>>;
}

export default function ApplyDriverShiftTable({
  driver, selectedDrivers, selectedTags, tags, setSelectedDrivers
}: Props) {
  const driverObj = useSelector(selectDriversObject);
  const [search, setSearch] = useState('');
  const [showDrivers, setShowDrivers] = useState(true);
  const drivers = Object.values(driverObj);
  const driverBtnTitle = showDrivers ? 'Hide All' : 'Show All';
  const isSearchActive = search.length > 3;
  const loadMoreTitle = `Show ${getRemainingDrivers(drivers)} more drivers`;
  const sortedDrivers = [...drivers]
    .filter((dr) => dr.entity_id !== driver.entity_id)
    .sort((a, b) => {
      const aName = getDriverName(a);
      const bName = getDriverName(b);
      return aName.localeCompare(bName);
    });
  const handleShowDrivers = () => setShowDrivers(!showDrivers);
  const getDrivers = () => {
    const data = showDrivers ? sortedDrivers : sortedDrivers.slice(0, PAGE_SIZE);
    if (!isSearchActive) return data;
    return sortedDrivers.filter((dr) => {
      const name = dr.data.samsara_name || dr.data.samsara_username || '';
      return name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
    })
  };
  const handleSelectDriver = (checked: boolean, driverId: string) => {
    setSelectedDrivers((prev) => {
      return {
        ...prev,
        [driverId]: checked,
      }
    });
  };
  return (
    <>
      <Stack direction="horizontal">
        <h4>Drivers</h4>
        <Button variant="link" onClick={handleShowDrivers}>{driverBtnTitle}</Button>
      </Stack>
      <>
        <input placeholder="Search driver" value={search} onChange={(e) => setSearch(e.target.value)} />
        <Table bordered hover className="settings-table" data-cy="driver_details_editor_availability_table">
          <thead>
            <th>{' '}</th>
            <th>Driver</th>
          </thead>
          <tbody>
            {getDrivers().map((dr, idx) => {
              const driverId = dr.entity_id;
              const name = dr.data.samsara_name || dr.data.samsara_username || '';
              const driverTags = dr.data.hp_tag_ids || [];
              const isDriverSelected = selectedDrivers[driverId] || false;
              return (
                <tr data-cy={`driver_details_editor_availability_table_row_${idx}`}>
                  <td>
                    <Form.Check
                      checked={isDriverSelected}
                      className="invoices-checkbox"
                      type="checkbox"
                      onChange={(e) => handleSelectDriver(e.target.checked, driverId)}
                    />
                  </td>
                  <td>{name} {driverTags.map((driverTag) => {
                    const isTagSelected = selectedTags[driverTag] || false;
                    const colour = isTagSelected ? 'primary' : 'secondary';
                    return (
                      <Badge bg={colour}>{tags[driverTag]?.data.name || ''}</Badge>
                    )
                  })}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {!showDrivers && drivers.length > PAGE_SIZE && (
          <Button variant="link" onClick={handleShowDrivers}>{loadMoreTitle}</Button>
        )}
      </>
    </>
  )
}
