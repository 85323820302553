/* eslint-disable */
import React, { useState, useCallback } from 'react'
import { TripTableColumns } from '../../../redux/models/board.models';

const initData = [
  { key: 'expand', name, width: 120, resizable: false },
  { key: 'name', width: 200, resizable: true },
  { key: 'customer', width: 150, resizable: true },
  { key: 'completed', width: 100, resizable: false },
  { key: 'status', width: 150, resizable: true },
  { key: 'actions', width: 100, resizable: false },
];

export const useResizableColumns = (data: TripTableColumns[]) => {
  const [columns, setColumns] = useState<TripTableColumns[]>([
    { name: '', key: 'expand', width: 120, resizable: false, visible: true },
    ...data,
    { name: '', key: 'actions', width: 100, resizable: false, visible: true },
  ]);

  const startResizing = useCallback((index: number) => (mouseDownEvent: React.MouseEvent) => {
    mouseDownEvent.preventDefault()
    if (!columns[index].resizable) return

    const startX = mouseDownEvent.pageX
    const startWidth = columns[index].width

    const handleMouseMove = (mouseMoveEvent: MouseEvent) => {
      const newWidth = Math.max(50, startWidth + mouseMoveEvent.pageX - startX)
      setColumns(prev => 
        prev.map((col, i) => (i === index ? { ...col, width: newWidth } : col))
      )
    }

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }, [columns])

  return { columns, startResizing }
}
