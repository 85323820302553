import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import AsyncButton from '../shared/buttons/AsyncButton';

const CANCEL = 'Cancel';

interface Props {
  title: string;
  description: string;
  shouldShowDeleteModal: boolean;
  isDeleting: boolean;
  deleteBtnText: string;
  deleteItem: () => void;
  cancelDeletion: () => void;
}

export default function DeleteModal({
  title = 'Delete item',
  description = 'Are you sure you want to delete this item?',
  shouldShowDeleteModal,
  deleteBtnText = 'Delete',
  isDeleting,
  deleteItem,
  cancelDeletion,
}: Props) {
  return (
    <Modal
      show={shouldShowDeleteModal}
      onHide={cancelDeletion}
      centered
      data-cy="delete_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center m-3">
        <p className="mb-0">{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button id="delete_modal__cancel_btn" variant="secondary" onClick={cancelDeletion}>
          {CANCEL}
        </Button>
        <AsyncButton
          title={deleteBtnText}
          disabled={isDeleting}
          id="delete_modal__delete_btn"
          dataCy="delete_modal__delete_btn"
          variant="danger"
          spinner="light"
          handleClick={deleteItem}
        />
      </Modal.Footer>
    </Modal>
  );
}
