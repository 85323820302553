/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";

export default class ApiResponse {
  static parseItem<T>(response: AxiosResponse<any, any>) {
    if (response.status === 200) {
      const entity: T = response.data.data;
      if (entity) {
        return entity;
      }
      throw new Error('Error parsing response');
    } else {
      throw new Error('Error')
    }
  }

  static parsePaginatedList<T>(response: AxiosResponse<any, any>) {
    if (response.status === 200) {
      const entities: T[] = response.data.data.items;
      if (entities) {
        return entities;
      }
      throw new Error('Error parsing response');
    } else {
      throw new Error('Error')
    }
  }

  static parseError(status: number) {
    if (status === 401) {
      return "You don't have permission to this record. Please contact your account admin.";
    }
    if (status >= 500) {
      return 'Server Error';
    }
    return 'Error';
  }
}