/* eslint-disable */
import { EntityServiceProvider } from './EntityServiceProvider';
import { InvoiceData } from '../../redux/models/invoice.models';

class InvoiceServiceProvider extends EntityServiceProvider<InvoiceData> {
  lineItems(invoiceIds: string[]) {
    const url = `${this.endpoint.entityPath()}/line-items`;
    return this.api.post(url, { invoice_ids: invoiceIds });
  }
}

export default InvoiceServiceProvider;
