/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import AddEntityModal from '../base/modals/AddEntityModal';
import { ControlInput } from '../../shared/Input';
import Permission from '../../shared/permissions/Permission';
import { BillingTypeWritePermissions } from '../../shared/permissions/permissions.utils';
import { getSettingByName } from '../../../redux/slices/settings';
import { Api } from '../../../services/services';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import { Driver, DriverData } from '../../../redux/models/settings.models';

const PAGE = 'add_driver_modal';
const TITLE = 'Driver';
const api = Api.Drivers;

const initialContactData: DriverData = {
  allow_overtime: false,
  air_cargo: false,
  b_train: false,
  ct_pat: false,
  dangerous_goods: false,
  home_yard_id: null,
  home_yard: null,
  light_duties: false,
  outside_cartage: false,
  hp_tag_ids: [],
  tag_ids: [],
  samsara_name: '',
  samsara_phone: '',
  samsara_username: '',
  email: '',
  tipper_truck: false,
};

interface Props {
  driver?: Driver,
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: (driver: Driver, event: string) => void;
}

function DriverModal({ driver, show, setShow, onUpdate }: Props) {
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState(driver?.data?.samsara_name || '');
  const isDisabled = name === '';
  const handleChange = (prop: string, val: string) => {
    if (prop === 'name') setName(val);
  };
  const handleCreate = async () => {
    const entityData: DriverData = {
      ...initialContactData,
      samsara_name: name,
    };
    return api.create(entityData);
  };
  const handleUpdate = () => {
    if (!driver) {
      throw Error(`missing ${TITLE}`);
    }
    const entity: Driver = {
      ...driver,
      data: {
        ...driver.data,
        samsara_name: name,
      },
    };
    return api.update(entity);
  };
  const handleResponse = async (response: AxiosResponse<any, any>, event: string) => {
    if (response.status === 200) {
      await dispatch(getSettingByName(SETTINGS_NAMES.DRIVERS, false));
      toast(`${name} updated.`, { type: 'success' });
      if (onUpdate) {
        const entity = response.data.data;
        onUpdate(entity, event);
      }
      setShow(false);
    }
  }
  const handleSave = async () => {
    if (isDisabled) return;
    try {
      setIsSaving(true);
      if (driver) {
        const response = await handleUpdate();
        handleResponse(response, 'add');
      } else {
        const response = await handleCreate();
        handleResponse(response, 'update');
      }
    } catch (error) {
      toast(`Couldn't update ${name}`, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <Permission resources={BillingTypeWritePermissions}>
      <AddEntityModal
        title={`Add ${TITLE}`}
        btnTitle="Save"
        saveDisabled={isDisabled}
        shouldShowModal={show}
        isSaving={isSaving}
        handleSave={handleSave}
        cancelSave={() => setShow(false)}
      >
        <Stack>
          <ControlInput
            dataCy={`${PAGE}_name_input`}
            name="name"
            type="text"
            page={PAGE}
            value={name}
            labelText="Name"
            handleChange={handleChange}
          />
        </Stack>
      </AddEntityModal>
    </Permission>
  )
};

DriverModal.defaultProps = {
  driver: undefined,
}

export default DriverModal;
