/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Spinner } from 'react-bootstrap'
import { ChevronDown, ChevronRight } from 'react-feather'
import { TripTableColumns } from '../../../../redux/models/board.models'
import DataGridTable from './DataGridTable'
import { ShipmentGroup } from '../../../../redux/models/shipment.models'
import { selectShipmentTypes } from '../../../../redux/slices/settings'
import { getType, getUnknownName } from '../../utils/shipments.utils';

interface Props {
  groupBy: string;
  group: ShipmentGroup;
  columns: TripTableColumns[];
  startResizing: (index: number) => (e: React.MouseEvent) => void;
  onUpdate: (shipmentId: string, key: string, value: any) => void;
  onGroupExpand: (id: string) => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onOpen: (shipmentId: string) => void;
  onInvoice: (shipmentId: string) => void;
  onCreateShipment: () => void;
  onCreateTrip: (shipmentId: string) => void;
  expandedGroupRows: string[];
  selectedShipments: string[];
}

function SectionComponent({
  groupBy,
  group,
  columns,
  expandedGroupRows,
  selectedShipments,
  startResizing,
  onUpdate,
  onGroupExpand,
  onSelect, onOpen, onInvoice, onCreateShipment, onCreateTrip
}: Props) {
  const [isCreatingTrip] = useState(false);
  const types = useSelector(selectShipmentTypes);
  const name = getType(group.id, types) || group.id;
  const handleCreate = async () => {
    console.log('group id: ', group.id);
    console.log('groupBy: ', groupBy);
    await onCreateShipment();
  }
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h4>
          <Button
            variant="link"
            onClick={() => onGroupExpand(group.id)}
            aria-label={expandedGroupRows.includes(group.id) ? "Collapse section" : "Expand section"}
          >
            {expandedGroupRows.includes(group.id) ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
          </Button>
          {name || `No ${getUnknownName(groupBy)}`}
        </h4>
        {isCreatingTrip && <Spinner animation="border" variant="primary" size="sm" />}
      </div>
      {expandedGroupRows.includes(group.id) && (
        <DataGridTable
          columns={columns}
          selectedShipments={selectedShipments}
          shipments={group.shipments}
          onUpdate={onUpdate}
          onSelect={onSelect}
          startResizing={startResizing}
          onOpen={onOpen}
          onInvoice={onInvoice}
          onCreateShipment={handleCreate}
          onCreateTrip={onCreateTrip}
        />
      )}
    </div>
  )
}

export default SectionComponent;