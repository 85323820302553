/* eslint-disable @typescript-eslint/no-explicit-any */
import { ACTION_DROPDOWN_OPTIONS } from '../../../pages/trips/Moves/constants/moves.constants';
import { getValue, getTrailerNames } from '../../../pages/trips/Moves/utils/moves.utils';
import { EntityContainer } from '../../../redux/models/core.models';
import { Fleet } from '../../../redux/models/settings.models';
import { Shipment } from '../../../redux/models/shipment.models';
import { SelectInput } from '../../shared/Input';
import { DropdownOptionsModel } from '../../shared/SelectInput.models';
import TrailerChangeToInput from './TrailerChangeToInput';

const renderShipments = (shipmentsIds: string[], shipments: any) => shipmentsIds.map((id) => (
  <span
    key={id}
    className="trailerChanges__move-shipment"
  >
    {getValue(id, shipments, 'shipment_no')}
  </span>
));

interface Props {
  move: any;
  prop: string;
  shipments: Shipment[];
  fleetOptions: DropdownOptionsModel[];
  fleet: EntityContainer<Fleet>;
  onTrailerChangeInput:  (position: number) => (prop: string, value: string) => void;
}

export default function TrailerChangeTableRowData({
  move, prop, shipments, fleet, fleetOptions, onTrailerChangeInput
}: Props) {
  
  if (prop === 'shipments') {
    return renderShipments(move.shipments, shipments);
  }

  if (prop === 'from') {
    return getTrailerNames(move.from, fleet);
  }

  if (prop === 'to') {
    return (
      <TrailerChangeToInput
        moveId={move.id}
        options={fleetOptions}
      />
    );
  }

  if (prop === 'action') {
    return (
      <SelectInput
        name="action"
        page="trailerChanges"
        value={move.action}
        options={ACTION_DROPDOWN_OPTIONS}
        shouldHaveMargin={false}
        dataCy="trailerChanges_action"
        handleChange={onTrailerChangeInput(move.position)}
      />
    );
  }
  return move[prop];
}
