import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PieChart from './PieChart';
import { Board } from '../../../../redux/models/board.models';
import { EntityContainer } from '../../../../redux/models/core.models';
import { Api } from '../../../../services/services';
import { Move } from '../../../../redux/models/trip.models';

interface Props {
  boards: Board[];
  boardColours: EntityContainer<string>;
  dateFrom: string;
  dateTo: string;
}

export default function BobtailChart({ boards, boardColours, dateFrom, dateTo }: Props) {
  const { orgCode } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [moves, setMoves] = useState<Move[]>([]);
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await Api.Dashboard.moveBobtails({
        start_date: dateFrom,
        end_date: dateTo,
      });
      if (response.status === 200) {
        const mvs = response.data.data || [];
        setMoves(mvs);
      }
      setIsLoading(false);
    };
    load();
  }, [dateFrom, dateTo]);
  return (
    <PieChart
      title="Bobtails"
      route={`/${orgCode}/dashboard/bobtails`}
      moves={moves}
      isLoading={isLoading}
      boards={boards}
      boardColours={boardColours}
    />
  )
}
