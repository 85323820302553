import React from 'react';
import {
  DepartmentCodeReadPermissions,
  DepartmentCodeWritePermissions,
} from '../../shared/permissions/permissions.utils';
import { getSelectedOptions } from '../../../utils/core.utils';
import { DropdownOptionsModel } from '../../shared/SelectInput.models';
import EntityInput from '../base/inputs/EntityInput';
import { Api } from '../../../services/services';
import { DepartmentCodeData } from '../../../redux/models/settings.models';
import { DataModel } from '../../../redux/models/core.models';

interface Props {
  value: string;
  page: string;
  disabled: boolean;
  prop: string;
  label?: string;
  options: DropdownOptionsModel[];
  onChange: (prop: string, value: string) => void;
  onNewEntity: (prop: string, entity: DataModel<DepartmentCodeData>) => void;
}

function DepartmentCodeInput({ value, page, prop, label, disabled, options, onChange, onNewEntity }: Props) {
  const handleCreateData = (_: string, val: string): DepartmentCodeData => {
    return {
      name: val,
      department_code: '',
    };
  };
  return (
    <EntityInput
      disabled={disabled}
      labelText={label || 'Department Code'}
      name={prop}
      page={page}
      value={value}
      options={getSelectedOptions(value, options)}
      entity="department-code"
      dataField="name"
      readResources={DepartmentCodeReadPermissions}
      writeResources={DepartmentCodeWritePermissions}
      api={Api.DepartmentCodes}
      defaultAdd
      allowAdd
      settingName="department-code"
      syncSettings={false}
      onChange={onChange}
      createData={handleCreateData}
      onNewEntity={onNewEntity}
    />
  );
}

DepartmentCodeInput.defaultProps = {
  label: 'Department Code',
}

export default DepartmentCodeInput;
