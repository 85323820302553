/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { TripTemplate } from '../models/settings.models';
import { ShipmentTypeApi } from '../../services/services';
import { ShipmentType, ShipmentTypeData } from '../models/shipment.models';

export const getShipmentType = async (entityId: string) => {
  return ShipmentTypeApi.getById(entityId);
};

export const getShipmentTypes = async () => {
  try {
    const { data } = await ShipmentTypeApi.sync();
    const templates = data.data;
    templates.sort((a: TripTemplate, b: TripTemplate) => a.data.name.localeCompare(b.data.name));
    return templates;
  } catch (e) {
    const errorMessage = 'Couldn\'t get templates. Please contact support if the problem persists.';
    return toast(errorMessage, { type: 'error' });
  }
};

export const deleteShipmentType = async (type: ShipmentType) => {
  try {
    const { data } = await ShipmentTypeApi.delete(type);
    return data.data;
  } catch (e) {
    const errorMessage = 'Couldn\'t delete templates. Please contact support if the problem persists.';
    return toast(errorMessage, { type: 'error' });
  }
};

export const createShipmentType = async (data: ShipmentTypeData) => {
  return ShipmentTypeApi.create(data);
};

export const updateShipmentType = async (data: ShipmentType) => {
  return ShipmentTypeApi.update(data);
};
