import { DataModel } from "./core.models";
import { Organisation } from "./organisation.models";
import { User as HopperUser } from "./user.models";

export enum FeatureResource {
  Address = 'address',
  BillingItem = 'billing_item',
  BillingType = 'billing_type',
  Board = 'board',
  Contact = 'contact',
  Cost = 'cost',
  DepartmentCode = 'department_code',
  Driver = 'driver',
  DriverAvailability = 'driver_availability',
  FuelSurchargeType = 'fuel_surcharge_type',
  GlCode = 'gl_code',
  Group = 'group',
  NotesTemplate = 'note_template',
  Invoice = 'invoice',
  Integration = 'integration',
  Shipment = 'shipment',
  ShipmentType = 'shipment_type',
  Route = 'route',
  Trip = 'trip',
  TripPlan = 'trip_plan',
  TripTemplate = 'trip_template',
  Uom = 'uom',
  User = 'user',
  Vehicle = 'vehicle',
}

export enum ResourcePermission {
  Read = 'read',
  Write = 'write',
}

export enum ResourceScope {
  Org = 'org',
  Group = 'group',
  User = 'user',
}

export interface FeaturData {
  key: string;
  name: string;
  organizations: Organisation[];
  resource_ids: string[];
  users: HopperUser[];
}

export type FeatureFlag = DataModel<FeaturData>;