import { FilePond, registerPlugin } from 'react-filepond';
import React, { useState } from 'react';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';

import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileRename);

export function Uploader({
  renameFile, handleFileUpload,
}) {
  const [files, setFiles] = useState([]);
  const server = {
    process: (fieldName, file, metadata, load, error, progress, abort) => {
      handleFileUpload(file, load);
      return {
        abort: () => {
          abort();
        },
      };
    },
  };

  return (
    <FilePond
      files={files}
      onupdatefiles={(fileItems) => {
        setFiles({
          files: fileItems.map((fileItem) => fileItem.file),
        });
      }}
      maxFiles="1"
      server={server}
      fileRenameFunction={renameFile}
      credits
      name="files"
      labelIdle='Drag & Drop document or <span class="filepond--label-action">Browse</span>'
    />
  );
}
