import React from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import Permission from '../../../components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../../redux/models/feature.flags.models';

const WriteTripPermissions = {
  [FeatureResource.Trip]: ResourcePermission.Write,
};

interface Props {
  onDuplicate: () => void;
  onDelete: () => void;
}

export default function TripMenu({ onDuplicate, onDelete }: Props) {
  return (
    <DropdownButton
      as={ButtonGroup}
      id="dropdown-button-drop"
      size="sm"
      variant="light"
      title="•••"
    >
      <Permission resources={WriteTripPermissions}>
        <Dropdown.Item onClick={onDuplicate}>Duplicate</Dropdown.Item>
      </Permission>
      <Dropdown.Divider />
      <Permission resources={WriteTripPermissions}>
        <Dropdown.Item onClick={onDelete}>Delete</Dropdown.Item>
      </Permission>
    </DropdownButton>
  )
};
