import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import SidebarNavList from './SidebarNavList';
import { SidebarItem } from './sidebar.models';
import SidebarNavSectionHeader from './SidebarNavSectionHeader';
import { createBoard, getSettingByName } from '../../redux/slices/settings';
import { Board } from '../../redux/models/board.models';
import useAppDispatch from '../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../constants/core.constants';

interface Props {
  item: SidebarItem;
}

function SidebarNavSection({ item }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const handleAdd = async () => {
    try {
      const response = await createBoard();
      if (response.status < 300) {
        const board: Board = response.data.data;
        await dispatch(getSettingByName(SETTINGS_NAMES.BOARDS));
        navigate(`/${orgCode}/boards/${board.entity_id}`);
      }
    } catch (error) {
      toast('Error adding board', { type: 'error' });
    }
  };
  return (
    <>
      {item.title && <SidebarNavSectionHeader item={item} allowAdd={item.allowAdd} onAdd={handleAdd} />}
      <SidebarNavList pages={item.pages} />
    </>
  );
}

export default SidebarNavSection;
