/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiQuery } from '../redux/models/network.models';
import axiosInstance from '../utils/axios';
import { DriverAvailabilityEndpoints as Endpoints } from './DriverAvailabilityEndpoints';

export class DriverAvailabilityApi {
  static findDriverTrips(filters: ApiQuery) {
    return axiosInstance.post('/driver-trips/find', filters);
  }

  static findAvailabileDrivers(filters: ApiQuery) {
    return axiosInstance.post('/driver-roster/availability', filters);
  }

  static removeDriverTrip(driverId: string, tripId: string) {
    return axiosInstance.post(Endpoints.removeDriverTrips(tripId), { driver_id: driverId });
  }

  static addDriverTrip(driverId: string, tripId: string) {
    return axiosInstance.post(Endpoints.addDiverTrip(tripId), { driver_id: driverId });
  }
}
