/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';

import InvoiceRow from './InvoiceRow';
import { Shipment } from '../../../../redux/models/shipment.models';
import AddAccessorialModal from './AddAccessorial';
import { BillingItemData } from '../../../../redux/models/billing.models';
import { InvoiceWritePermissions } from '../../../../components/shared/permissions/permissions.utils';
import Permission from '../../../../components/shared/permissions/Permission';

interface Props {
  disabled: boolean;
  shipmentDetails: Shipment;
  onUpdateStore: (shipment: Shipment) => void;
}

export default function Accessorials({
  disabled,
  shipmentDetails,
  onUpdateStore
}: Props) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [isSaving] = useState(false);
  const accessorials = shipmentDetails.data.accessorials || [];
  const invoice = shipmentDetails?.data?.invoice;
  const isDisabled = disabled || (invoice?.data?.status === 'sent' || invoice?.data?.status === 'paid');
  const baseRate = shipmentDetails.data.base_rate || {};
  const isBaseRate = !!Object.keys(baseRate).length;

  const handleBaseRateChange = () => (_: string, value: string) => {
    onUpdateStore({
      ...shipmentDetails,
      data: {
        ...shipmentDetails.data,
        base_rate: {
          ...baseRate,
          quantity: parseFloat(value),
        },
      },
    });
  };
  const handleChange = (itemId: string) => (prop: string, value: any) => {
    const updatedAccessorials = accessorials.map((item) => {
      if (item.billing_item_id === itemId) {
        return {
          ...item,
          quantity: parseFloat(value),
        };
      }
      return item;
    });
    onUpdateStore({
      ...shipmentDetails,
      data: {
        ...shipmentDetails.data,
        accessorials: updatedAccessorials,
      },
    });
  };

  const handleDelete = (index: number) => {
    const updatedAccessorials = accessorials.filter((item, accIdx) => index !== accIdx);
    onUpdateStore({
      ...shipmentDetails,
      data: {
        ...shipmentDetails.data,
        accessorials: updatedAccessorials,
      },
    });
  };

  const handleAdd = () => {
    setShowAddModal(true);
  };

  const handleCreateAccessorial = (item: BillingItemData) => {
    const updatedAccessorials = [...accessorials, item];
    onUpdateStore({
      ...shipmentDetails,
      data: {
        ...shipmentDetails.data,
        accessorials: updatedAccessorials,
      },
    });
    setShowAddModal(false);
  };

  return (
    <Card.Body>
      {isBaseRate && (
        <InvoiceRow invoiceItem={baseRate} handleChange={handleBaseRateChange} disabled={disabled} />
      )}
      {accessorials && accessorials.map((item, idx) => (
        <InvoiceRow
          key={idx}
          index={idx}
          invoiceItem={item}
          handleChange={handleChange}
          disabled={isDisabled}
          onDelete={handleDelete}
        />
      ),
      )}
      <Permission resources={InvoiceWritePermissions}>
        <Button variant="light" onClick={handleAdd}>Add</Button>
      </Permission>
      <AddAccessorialModal
        shouldShowModal={showAddModal}
        isSaving={isSaving}
        accessorials={accessorials}
        saveItem={handleCreateAccessorial}
        cancelSave={() => setShowAddModal(false)}
      />
    </Card.Body>
  );
}
