import React from 'react';
import { useSelector } from 'react-redux';
import { CostReadPermissions, CostWritePermissions } from '../../shared/permissions/permissions.utils';
import { createDropdownOptions, getSelectedOptions } from '../../../utils/core.utils';
import EntityInput from '../base/inputs/EntityInput';
import { Api } from '../../../services/services';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import { DataModel } from '../../../redux/models/core.models';
import { CostData } from '../../../redux/models/settings.models';
import { selectCosts } from '../../../redux/slices/settings';

interface Props {
  value: string;
  page: string;
  prop: string;
  label?: string;
  disabled: boolean;
  allowAdd?: boolean;
  onChange: (prop: string, value: string) => void;
  onNewEntity: (prop: string, vehicle: DataModel<CostData>) => void;
}

function CostInput({ value, page, prop, label, disabled, allowAdd, onChange, onNewEntity }: Props) {
  const costs = useSelector(selectCosts);
  const options = createDropdownOptions(costs);
  const handleCreateData = (_: string, val: string): CostData => {
    return {
      name: val,
      rate: 0,
      unit: null,
    };
  };
  return (
    <EntityInput
      disabled={disabled}
      labelText={label || 'Cost'}
      name={prop}
      page={page}
      value={value}
      options={getSelectedOptions(value, options)}
      entity="cost"
      dataField="name"
      readResources={CostReadPermissions}
      writeResources={CostWritePermissions}
      api={Api.Costs}
      defaultAdd={allowAdd || false}
      settingName={SETTINGS_NAMES.COSTS}
      syncSettings
      onChange={onChange}
      createData={handleCreateData}
      onNewEntity={onNewEntity}
    />
  )
}

CostInput.defaultProps = {
  label: 'Rate',
  allowAdd: false,
}

export default CostInput;
