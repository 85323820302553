import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Shipment, ShipmentTypeFieldData } from '../../../../../redux/models/shipment.models';
import { DropdownOptionsModel } from '../../../../../components/shared/SelectInput.models';
import { CONTAINER_SIZE } from '../../../constants/shipments.constants';
import ShipmentField from './ShipmentField';
import { EntityContainer } from '../../../../../redux/models/core.models';
import { SelectInput } from '../../../../../components/shared/Input';

const FORM_TITLE = 'Shipping';

interface Props {
  shipmentDetails: Shipment;
  addressSelectOptions: DropdownOptionsModel[];
  portSelectOptions: DropdownOptionsModel[];
  disabled: boolean;
  validation: EntityContainer<boolean>;
  fields: ShipmentTypeFieldData;
  shippingLineOptions: DropdownOptionsModel[];
  handleChange: (prop: string, value: string) => void;
}

export default function ShippingTypeForm({
  shipmentDetails,
  addressSelectOptions,
  portSelectOptions,
  disabled,
  validation,
  fields,
  shippingLineOptions,
  handleChange
}: Props) {
  const erdField = fields.empty_release_depot;
  const originField = fields.origin_id;
  const destinationField = fields.destination_id;
  return (
    <Row>
      <h4>{FORM_TITLE}</h4>
      <ShipmentField
        field={fields.port_id}
        value={shipmentDetails.data.port_id || ''}
        options={portSelectOptions}
        disabled={disabled}
        validation={validation}
        handleChange={handleChange}
      />
      <ShipmentField
        field={fields.vessel}
        value={shipmentDetails.data.vessel || ''}
        options={portSelectOptions}
        disabled={disabled}
        validation={validation}
        handleChange={handleChange}
      />
      <ShipmentField
        field={fields.container_size}
        value={shipmentDetails.data.container_size || ''}
        options={Object.values(CONTAINER_SIZE)}
        disabled={disabled}
        validation={validation}
        handleChange={handleChange}
      />
      <ShipmentField
        field={fields.container_no}
        value={shipmentDetails.data.container_no || ''}
        disabled={disabled}
        validation={validation}
        handleChange={handleChange}
      />
      <ShipmentField
        field={fields.shipping_line_id}
        options={shippingLineOptions}
        value={shipmentDetails.data.shipping_line_id || ''}
        disabled={disabled}
        entity="shipping_lines"
        dataField="name"
        validation={validation}
        handleChange={handleChange}
      />
      {/* <ShipmentField
        field={fields.empty_release_depot}
        options={addressSelectOptions}
        value={shipmentDetails.data.empty_release_depot || ''}
        disabled={disabled}
        validation={validation}
        entity="address"
        handleChange={handleChange}
      /> */}
      {erdField && erdField?.visible && (
        <Col md={3}>
          <SelectInput
            name={erdField?.key ?? 'empty_release_depot'}
            page="shipment"
            value={shipmentDetails.data.empty_release_depot || ''}
            options={addressSelectOptions}
            shouldHaveMargin={false}
            handleChange={handleChange}
            dataCy={`shipment_${erdField?.key ?? 'empty_release_depot'}`}
            labelText={erdField.name}
            disabled={disabled}
            multiple={false}
            isClearable
            isSearchable
          />
        </Col>
      )}
      <ShipmentField
        field={fields.cut_off_date}
        value={shipmentDetails.data.cut_off_date || ''}
        disabled={disabled}
        validation={validation}
        handleChange={handleChange}
      />
      <ShipmentField
        field={fields.erd}
        value={shipmentDetails.data.erd || ''}
        disabled={disabled}
        validation={validation}
        handleChange={handleChange}
      />
      <ShipmentField
        field={fields.last_free_date}
        value={shipmentDetails.data.last_free_date || ''}
        disabled={disabled}
        validation={validation}
        handleChange={handleChange}
      />
      {/* <ShipmentField
        field={fields.origin_id}
        value={shipmentDetails.data.origin_id || ''}
        entity="address"
        options={addressSelectOptions}
        disabled={disabled}
        validation={validation}
        handleChange={handleChange}
      />
      <ShipmentField
        field={fields.destination_id}
        value={shipmentDetails.data.destination_id || ''}
        entity="address"
        disabled={disabled}
        options={addressSelectOptions}
        validation={validation}
        handleChange={handleChange}
      /> */}
      {originField && originField?.visible && (
        <Col md={3}>
          <SelectInput
            name={originField?.key || 'origin_id'}
            page="shipment"
            value={shipmentDetails.data.origin_id || ''}
            options={addressSelectOptions}
            shouldHaveMargin={false}
            handleChange={handleChange}
            dataCy={`shipment_${originField?.key || 'origin_id'}`}
            labelText={originField?.name || 'origin_id'}
            disabled={disabled}
            multiple={false}
            isClearable
            isSearchable
          />
        </Col>
      )}
      {destinationField && destinationField?.visible && (
        <Col md={3}>
          <SelectInput
            name={destinationField?.key || 'destination_id'}
            page="shipment"
            value={shipmentDetails.data.destination_id || ''}
            options={addressSelectOptions}
            shouldHaveMargin={false}
            handleChange={handleChange}
            dataCy={`shipment_${destinationField?.key || 'destination_id'}`}
            labelText={destinationField?.name || 'destination_id'}
            disabled={disabled}
            multiple={false}
            isClearable
            isSearchable
          />
        </Col>
      )}
      <ShipmentField
        field={fields.bol}
        value={shipmentDetails.data.bol || ''}
        disabled={disabled}
        validation={validation}
        handleChange={handleChange}
      />
      <ShipmentField
        field={fields.requested_pick_up_date}
        value={shipmentDetails.data.requested_pick_up_date || ''}
        disabled={disabled}
        validation={validation}
        handleChange={handleChange}
      />
      <ShipmentField
        field={fields.requested_drop_off_date}
        value={shipmentDetails.data.requested_drop_off_date || ''}
        disabled={disabled}
        validation={validation}
        handleChange={handleChange}
      />
    </Row>
  )
};
