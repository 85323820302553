import React from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../../components/Loader';
import {
  selectAreSettingsLoading,
} from '../../../redux/slices/settings';
import { selectAreTripsLoading, selectBoardShipments, selectTrips } from '../../../redux/slices/trips/trips';
import {
  LOADING_TEXT,
} from '../constants/boards.constants';
import { AppState } from '../../../redux/models/state.models';
import { TripTableColumns } from '../../../redux/models/board.models';
import ShipmentCard from './ShipmentCard';

interface Props {
  headers: TripTableColumns[];
  shouldExpandAllItems: boolean;
}

export default function Shipments({
  headers,
  shouldExpandAllItems,
}: Props) {
  const shipments = useSelector(selectBoardShipments);
  const tripContainer = useSelector(selectTrips);
  const areSettingsLoading = useSelector(selectAreSettingsLoading);
  const areTripsLoading = useSelector((state: AppState) => selectAreTripsLoading(state));
  return (
    <>
      {areSettingsLoading || areTripsLoading ? <Loader text={LOADING_TEXT} /> : null}
      {!areTripsLoading && (
        <ShipmentCard
          shipments={shipments}
          headers={headers}
          shouldExpandAllItems={shouldExpandAllItems}
          trips={tripContainer}
        />
      )}
    </>
  );
}
