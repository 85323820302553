/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import { Wrapper, Status } from './Wrapper';
import MapView from './MapView';

const key = process.env.REACT_APP_GOOGLE_MAPS_KEY || '';

const render = (status: Status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return <></>;
};

interface Props {
  lat: number;
  lng: number;
  title: string;
}

export default function Map({ lat, lng, title }: Props) {
  const center = { lat, lng };
  const zoom = 13;
  return (
    <Wrapper apiKey={key} render={render}>
      <MapView center={center} zoom={zoom} title={title} />
    </Wrapper>
  );
}
