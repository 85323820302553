import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { AquadashServiceApiProvider } from '../../../services/AquadashServiceApiProvider';
import useAppDispatch from '../../../hooks/useAppDispatch';
import ShipmentsModalTable from './ShipmentsModalTable';
import { setTripDetails } from '../../../redux/slices/trips/trips';
import { turnIdsToObjects } from '../../../pages/shipments/utils/shipments.utils';
import { resetShipments } from '../../../redux/slices/shipment-list/shipment-list';

const CANCEL = 'Cancel';
const SAVE = 'Save';

export default function ShipmentsModal({
  title = 'Search Shipments',
  shouldShowShipmentsModal,
  handleShowShipmentModal,
  tripDetails,
}) {
  const dispatch = useAppDispatch();
  const existingShipmentsIds = tripDetails.data.shipments.map((shipment) => shipment.entity_id);
  const [selectedShipments, setSelectedShipments] = useState(existingShipmentsIds);
  const [isLoading, setIsLoading] = useState(false);
  const shipments = [];
  const removeCompletedShipments = shipments.filter(
    (shipment) => shipment.data?.status !== 'invoiced' && shipment.data?.status !== 'completed',
  ) || [];

  const onSave = async () => {
    setIsLoading(true);
    try {
      const { data: updatedTrip } = await AquadashServiceApiProvider.addShipmentsToTrip(tripDetails.entity_id, {
        ...tripDetails,
        data: {
          ...tripDetails.data,
          shipments: turnIdsToObjects(selectedShipments),
        },
      });
      dispatch(resetShipments());
      return dispatch(setTripDetails(updatedTrip));
    } catch (e) {
      const errorMessage = "Couldn't update trip shipments. Please contact support if the problem persists.";
      return toast(errorMessage, { type: 'error' });
    } finally {
      setIsLoading(false);
      handleShowShipmentModal();
    }
  };

  return (
    <Modal
      scrollable
      dialogClassName="large-modal"
      backdrop="static"
      keyboard={false}
      data-cy="add_shipments_modal"
      show={shouldShowShipmentsModal}
      onHide={handleShowShipmentModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center m-3 shipments-modal-body">
        <ShipmentsModalTable
          shipments={removeCompletedShipments}
          selectedShipments={selectedShipments}
          setSelectedShipments={setSelectedShipments}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShowShipmentModal}>
          {CANCEL}
        </Button>
        <Button data-cy="add_shipments_save_btn" variant="primary" disabled={isLoading} onClick={onSave}>
          {SAVE}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
