/* eslint-disable import/no-cycle */
import React from 'react';
import { Button } from "react-bootstrap";
import useAppDispatch from '../../hooks/useAppDispatch';
import { setSelectedRequestId } from '../../redux/slices/notifications/notifications';

const DispatchRequestButtonTitle = 'See Details';
interface DispatchRequestButtonProps {
  requestId: string;
}

export default function DispatchRequestButton({ requestId }: DispatchRequestButtonProps) {
  const appDispatch = useAppDispatch();
  const handleClick = () => {
    console.log(requestId);
    appDispatch(setSelectedRequestId(requestId));
  };
  return (
    <Button onClick={handleClick} type="button" variant="link">
      <span>{DispatchRequestButtonTitle}</span>
    </Button>
  );
}
