import Analytics from "../../../utils/analytics";
import { EntityContainer } from "../../models/core.models";

export interface Role {
  id: string;
  name: string;
  description: string;
}

export interface RoleState {
  isLoaded: boolean;
  isLoading: boolean;
  roles: Role[];
};

const initialState: RoleState = {
  isLoaded: false,
  isLoading: false,
  roles: [],
};

export const getInitialState = (): RoleState => ({
  ...initialState
});

const getErrorPlaceholder = (verb: string) =>
  `Couldn't ${verb} invoices. Please contact support if the problem persists`;

export const getError = (
  e: unknown,
  details = "Couldn't get roles",
  verb = 'get'
): React.ReactText => {
  let em;
  if (e instanceof Error) {
    em = e.message;
    Analytics.capture(em);
  }
  const errorMessage = em ?
    `${details}. ${em}. Please contact support if the problem persists.`
    : getErrorPlaceholder(verb);
  return errorMessage;
};

export class RoleStore {
  private cache: EntityContainer<Role[]> = {};

  add(orgCode: string, groups: Role[]) {
    this.cache[orgCode] = groups;
  }

  get(orgCode: string) {
    return this.cache[orgCode];
  }

  exists(orgCode: string) {
    return this.cache[orgCode] !== undefined && this.cache[orgCode] !== null;
  }
}
