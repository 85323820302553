import { Container, Row } from 'react-bootstrap';
import Update from './Update';
import { updateArr } from './UpdateList';

export default function VersionUpdates() {
  return (
    <Container>
      <Row style={{ marginBottom: '20px' }}>
        <h1>Updates:</h1>
      </Row>
      <Row>
        {updateArr.map((version) => (
          <Update key={version.version} update={version} />
        ))}
      </Row>
    </Container>
  );
}
