import React from "react";
import { Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { Card, Dropdown, Table } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import usePalette from "../../../../hooks/usePalette";
import { Move } from "../../../../redux/models/trip.models";
import { getBoardMoves } from "../../utils/move.utils";
import { EntityContainer } from "../../../../redux/models/core.models";
import { Board } from "../../../../redux/models/board.models";
import ButtonSpinner from "../../../../components/shared/ButtonSpinner";

interface Props {
  title: string;
  boards: Board[];
  boardColours: EntityContainer<string>;
  route: string;
  moves: Move[];
  isLoading: boolean;
}

export default function PieChart({ title, boards, boardColours, moves, route, isLoading }: Props) {
  const palette = usePalette();
  const navigate = useNavigate();
  // const [moves, setMoves] = useState<Move[]>([]);
  // const [isLoading, setIsLoading] = useState(false);
  const boardData = Object.values(boards);
  const boardMoves = getBoardMoves(moves);
  const sortedBoards = [...boardData].sort((a, b) => {
    const aCount = boardMoves[a.entity_id]?.length || 0;
    const bCount = boardMoves[b.entity_id]?.length || 0;
    return bCount - aCount;
  });
  const labels = sortedBoards.map((board) => board.data.name || 'No name');
  const moveCount = sortedBoards.map((board) => {
    const id = board.entity_id || '';
    const mvs = boardMoves[id] || [];
    return mvs.length;
  });  
  const dataColours = sortedBoards.map((board) => boardColours[board.entity_id]);
  const data = {
    labels,
    datasets: [
      {
        data: moveCount,
        backgroundColor: dataColours,
        borderWidth: 5,
        borderColor: palette.white,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const handleNavigate = () => {
    if (!route || route === '') {
      return;
    }
    navigate(route);
  };
  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleNavigate}>
                View
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">{title}</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        {isLoading && (
          <ButtonSpinner />
        )}
        {!isLoading && (
          <div className="align-self-center w-100">
            <div className="py-3">
              <div className="chart chart-xs">
                <Pie data={data} options={options} />
              </div>
            </div>
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>Board</th>
                  <th className="text-end">Bobtails</th>
                  {/* <th className="text-end">Moves</th> */}
                </tr>
              </thead>
              <tbody>
                {sortedBoards.map((board) => {
                  const boardId = board.entity_id;
                  const name = board.data.name || 'No name set';
                  const mvs = boardMoves[boardId] || [];
                  const colour = boardColours[boardId];
                  return (
                    <tr key={boardId}>
                      <td>
                        <FontAwesomeIcon icon={faSquare} style={{ color: colour }} />{" "}
                        {name}
                      </td>
                      <td className="text-end">{mvs.length}</td>
                      {/* <td className="text-end text-success">+43%</td> */}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
