/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import AddModal from '../../../../components/modals/AddModal';
import RadioInput from '../../../../components/shared/inputs/RadioInput';
import { Shipment } from '../../../../redux/models/shipment.models';
import { deleteShipment } from '../../../../redux/slices/shipments';
import { resetShipments } from '../../../../redux/slices/shipment-list/shipment-list';
import useAppDispatch from '../../../../hooks/useAppDispatch';


interface Props {
  shipment: Shipment;
  shouldShow: boolean;
  onCancel: () => void;
}

export default function DeleteShipmentModal({
  shipment,
  shouldShow,
  onCancel,
}: Props) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const [option, setOption] = useState('');
  const [deleting, setDeleting] = useState(false);
  const isDisabled = option === '';
  const onSelect = (_: string, value: string) => {
    setOption(value)
  };
  const handleDelete = async () => {
    const deleteTrips = option === '2';
    try {
      setDeleting(true)
      await deleteShipment(shipment, deleteTrips);
      dispatch(resetShipments());
      navigate(`/${orgCode}/shipments`);
    } catch (error: any) {
      const message = error?.description || "Couldn't Delete Trip.";
      const errorMessage = `Couldn't delete shipment. ${message}.
       Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    } finally {
      setDeleting(false);
    }

  };
  return (
    <AddModal
      title="Delete Shipment"
      btnTitle="Delete"
      variant="danger"
      saveDisabled={isDisabled}
      shouldShowModal={shouldShow}
      isSaving={deleting}
      dataCy="delete_shipment_modal_btn"
      handleSave={handleDelete}
      cancelSave={onCancel}
    >
      <RadioInput id="1" label="Delete shipment" onChange={onSelect} />
      <RadioInput
        id="2"
        label="Delete shipment and trips"
        dataCy='shipment_details_delete_shipment_and_trips_radio'
        onChange={onSelect}
      />
    </AddModal>
  );
}
