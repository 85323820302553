export interface FilterButton {
  name: string;
  selected: boolean;
};

export const FILTER_KEYS = {
  All: 'All',
  Available: 'Available',
  Unavailable: 'Unavailable',
}

export const FILTER_OPTIONS: FilterButton[] = Object.values(FILTER_KEYS).map((name) => ({
  name,
  selected: name === FILTER_KEYS.Available,
}));