import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { TripDetails } from '../../../redux/models/trip.models';
import { DateService } from '../../../utils/dateService';
import { renderStatus } from '../../boards/TripTable/TripsTableRow';

export default function ShareTripHeader({ trip }: { trip: TripDetails }) {
  const moves = trip?.data.moves || [];
  const status = trip?.data.status || '';
  const start = moves.find((move) => move.data.scheduled_arrival_time)?.data.scheduled_arrival_time || '';
  const appt = start !== '' ? DateService.getYYYYMMDDhhmmFormat(start) : 'No start time';
  const tripNo = trip.data.trip_no || '';
  return (
    <div style={{ background: '#fff' }} className="p-4 pb-2">
      <h5>{tripNo}</h5>
      <Row>
        <Col>
          <h6>Scheduled Start</h6>
          <p>{appt}</p>
        </Col>
        <Col>
          <h6>Status</h6>
          {renderStatus(status)}
        </Col>
      </Row>
    </div>
  );
}
