/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';
import { Table } from 'react-bootstrap'
import ShipmentRow  from './rows/ShipmentRow'
import { TripTableColumns } from '../../../../redux/models/board.models';
import { ShipmentListView } from '../../../../redux/models/shipment.models';
import AddShipmentRow from './rows/AddShipmentRow';

interface Props {
  columns: TripTableColumns[];
  startResizing: (index: number) => (e: React.MouseEvent) => void;
  onUpdate: (shipmentId: string, key: string, value: any) => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onOpen: (shipmentId: string) => void;
  onInvoice: (shipmentId: string) => void;
  onCreateShipment: () => void;
  onCreateTrip: (shipmentId: string) => void;
  selectedShipments: string[];
  shipments: ShipmentListView[];
}

export default function DataGridTable({
  columns, selectedShipments, shipments,
  onUpdate, onSelect, startResizing, onOpen, onInvoice, onCreateShipment, onCreateTrip
}: Props) {
  const tableRef = useRef<HTMLTableElement>(null);
  useEffect(() => {
    if (tableRef.current) {
      const tableWidth = columns.reduce((sum, col) => sum + col.width, 0)
      tableRef.current.style.width = `${tableWidth}px`
    }
  }, [columns])
  return (
    <div className="table-responsive">
      <Table ref={tableRef} bordered hover style={{ tableLayout: 'fixed' }}>
        <colgroup>
          {columns.map((column) => (
            <col key={column.key} style={{ width: column.width }} />
          ))}
        </colgroup>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={column.key}
                style={{ 
                  position: 'relative',
                  padding: '0.75rem'
                }}
              >
                {!['expand', 'actions'].includes(column.key)
                  && column.name.charAt(0).toUpperCase()
                  + column.name.slice(1)}
                {column.resizable && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      width: '5px',
                      cursor: 'col-resize',
                    }}
                    onMouseDown={startResizing(index)}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {shipments.map((shipment) => (
            <ShipmentRow
              key={shipment.id}
              shipment={shipment}
              onUpdate={onUpdate}
              onSelect={onSelect}
              selectedShipments={selectedShipments}
              columns={columns}
              onOpen={onOpen}
              onInvoice={onInvoice}
              onCreateTrip={onCreateTrip}
            />
          ))}
          <AddShipmentRow numCols={columns.length} onAdd={onCreateShipment} />
        </tbody>
      </Table>
    </div>
  );
}
