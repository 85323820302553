/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

import AddEntityModal from '../base/modals/AddEntityModal';
import { ControlInput } from '../../shared/Input';
import { BillingItemData, BillingType, BillingTypeData } from '../../../redux/models/billing.models';
import ContactInput from '../contacts/ContactInput';
import { DataModel } from '../../../redux/models/core.models';
import { ContactData } from '../../../redux/models/contact.models';
import Permission from '../../shared/permissions/Permission';
import { BillingTypeWritePermissions } from '../../shared/permissions/permissions.utils';
import { getSettingByName, selectBillingItems, selectContacts } from '../../../redux/slices/settings';
import { createDropdownOptions } from '../../../utils/core.utils';
import BillingItemInput from '../billing-items/BillingItemInput';
import FuelSurchargeTypeInput from '../fuel-surcharge-type/FuelSurchargeTypeInput';
import { FuelSurchargeType, FuelSurchargeTypeData } from '../../../redux/models/settings.models';
import { Api } from '../../../services/services';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import AccessorialInput from '../billing-items/AccessorialInput';

const PAGE = 'add_billing_type_modal';
const initialBillingTypeData: BillingTypeData = {
  accessorials: null,
  accessorial_ids: [],
  base_rate_id: '',
  bill_to_id: '',
  currency: '',
  customer_id: '',
  description: '',
  fuel_surcharge_type: null,
  fuel_surcharge_type_id: '',
  name: '',
  price: 0,
  role_ids: [],
};

interface Props {
  type?: BillingType,
  customerId?: string,
  defaultBillToId?: string,
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: (type: BillingType) => void;
}

function BillingTypeModal({ type, show, customerId, defaultBillToId, setShow, onUpdate }: Props) {
  const dispatch = useAppDispatch();
  const contacts = useSelector(selectContacts);
  const rates = useSelector(selectBillingItems);
  const [fscs, setFscs] = useState<FuelSurchargeType[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const initialAccessorials = type?.data?.accessorials || [];
  const initialAccessorialIds = initialAccessorials.map((acc) => acc.entity_id);
  const [name, setName] = useState(type?.data?.name || '');
  const [billToId, setBillToId] = useState(type?.data?.bill_to?.entity_id || defaultBillToId || '');
  const [baseRateId, setBaseRateId] = useState(type?.data?.base_rate?.entity_id || '');
  const [fscId, setFscId] = useState(type?.data?.fuel_surcharge_type?.entity_id || '');
  const [accessorialIds, setAccessorialIds] = useState(initialAccessorialIds);
  const isDisabled = name === '' || billToId === '' || baseRateId === '' || fscId === '';
  const accessorials = rates.filter((acc) => acc.data.accessorial);
  const contactOptions = createDropdownOptions(contacts);
  const rateOptions = createDropdownOptions(rates, 'description');
  const accessorialOptions = createDropdownOptions(accessorials, 'description');
  const fscOptions = createDropdownOptions(fscs);

  const getEntityData = (data: BillingTypeData): BillingTypeData => {
    const parentId = type?.data.customer?.entity_id || customerId;
    const accs: any = accessorialIds
      .map((id) => rates.find((rate) => rate.entity_id === id))
      .filter((item) => item);
    return {
      ...data,
      name,
      customer: contacts.find((contact) => contact.entity_id === parentId) || null,
      bill_to: contacts.find((contact) => contact.entity_id === billToId) || null,
      base_rate: rates.find((rate) => rate.entity_id === baseRateId) || null,
      fuel_surcharge_type: fscs.find((fsc) => fsc.entity_id === fscId),
      accessorials: accs,
    };
  };
  const handleChange = (prop: string, val: string) => {
    if (prop === 'name') setName(val);
    if (prop === 'customer_id') setBillToId(val);
    if (prop === 'base_rate_id') setBaseRateId(val);
    if (prop === 'fuel_surcharge_type_id') setFscId(val);
  };
  const handleAccChange = (_: string, val: any) => {
    setAccessorialIds(val);
  };
  const handleNewContact = (_: string, entity: DataModel<ContactData>) => {
    setBillToId(entity.entity_id);
  };
  const handleNewBaseRate = (_: string, entity: DataModel<BillingItemData>) => {
    setBaseRateId(entity.entity_id);
  };
  const handleNewFSC = (_: string, entity: DataModel<FuelSurchargeTypeData>) => {
    setFscId(entity.entity_id);
  };
  const handleCreate = async () => {
    const entityData = getEntityData(initialBillingTypeData);
    return Api.BillingTypes.create(entityData);
  };
  const handleUpdate = () => {
    if (!type) {
      throw Error('missing billing type');
    }
    const entity: BillingType = {
      ...type,
      data: {
        ...getEntityData(type.data),
      },
    };
    return Api.BillingTypes.update(entity);
  };
  
  const handleResponse = async (response: AxiosResponse<any, any>) => {
    if (response.status === 200) {
      await dispatch(getSettingByName(SETTINGS_NAMES.BILLING_TYPES, false));
      toast("Quote Template Updated", { type: 'success' });
      const item = response.data.data;
      if (item) onUpdate(item);
      setShow(false);
    }
  }
  const handleSave = async () => {
    if (isDisabled) return;
    try {
      setIsSaving(true);
      if (type) {
        const response = await handleUpdate();
        handleResponse(response);
      } else {
        const response = await handleCreate();
        handleResponse(response);
      }
    } catch (error) {
      toast("Couldn't Update Quote Template", { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  useEffect(() => {
    const load = async () => {
      try {
        const response = await Api.FuelSurchargeTypes.find();
        if (response.status === 200) {
          const items = response.data.data.items || [];
          setFscs(items);
        }
      } catch (error) {
        console.log(error);
      }
    };
    load();
  }, []);
  return (
    <Permission resources={BillingTypeWritePermissions}>
      <AddEntityModal
        title="Add Quote Template"
        btnTitle="Save"
        saveDisabled={isDisabled}
        shouldShowModal={show}
        isSaving={isSaving}
        handleSave={handleSave}
        cancelSave={() => setShow(false)}
      >
        <Stack>
          <ControlInput
            dataCy={`${PAGE}_name_input`}
            name="name"
            type="text"
            page={PAGE}
            value={name}
            labelText="Name"
            handleChange={handleChange}
          />
          <ContactInput
            value={billToId}
            page={PAGE}
            prop="customer_id"
            label="Bill To"
            disabled={false}
            options={contactOptions}
            onChange={handleChange}
            onNewEntity={handleNewContact}
          />
          <BillingItemInput
            value={baseRateId}
            page={PAGE}
            prop="base_rate_id"
            label="Base Rate"
            disabled={false}
            options={rateOptions}
            allowAdd
            onChange={handleChange}
            onNewEntity={handleNewBaseRate}
          />
          <AccessorialInput
            value={accessorialIds}
            page={PAGE}
            prop="accessorial_ids"
            options={accessorialOptions}
            disabled={false}
            onChange={handleAccChange}
          />
          <FuelSurchargeTypeInput
            value={fscId}
            page={PAGE}
            prop="fuel_surcharge_type_id"
            label="Fuel Surcharge"
            disabled={false}
            options={fscOptions}
            onChange={handleChange}
            onNewEntity={handleNewFSC}
          />
        </Stack>
      </AddEntityModal>
    </Permission>
  )
};

BillingTypeModal.defaultProps = {
  type: undefined,
  defaultBillToId: '',
  customerId: undefined,
}

export default BillingTypeModal;