/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';

import DriverCard from './DriverCard';
import Loader from '../../../components/Loader';

import { selectAreTripsLoading, selectTripViews, selectTripsByCardId } from '../../../redux/slices/trips/trips';
import { selectDriversByTags, selectAreSettingsLoading } from '../../../redux/slices/settings';

import {
  BOARD_PAGE_TABS,
  LOADING_TEXT,
  getUnassignedCard,
  getUnassignedId,
} from '../constants/boards.constants';
import { AppState } from '../../../redux/models/state.models';
import { Driver, Tag } from '../../../redux/models/settings.models';
import { TripTableColumns } from '../../../redux/models/board.models';

interface Props {
  tags: Tag[];
  headers: TripTableColumns[];
  shouldExpandAllItems: boolean;
}

const getAssignedDrivers = (drivers: Driver[], tripsByDriver: any) => {
  return drivers.filter((driver) => tripsByDriver[driver.entity_id]);
};

const getEmptyDrivers = (drivers: Driver[], tripsByDriver: any) => {
  return drivers.filter((driver) => tripsByDriver[driver.entity_id] === undefined);
};

export default function Drivers({ tags, headers, shouldExpandAllItems }: Props) {
  const tabKey = BOARD_PAGE_TABS.DRIVERS.eventKey;
  const unassignedId = getUnassignedId(tabKey);
  const driversByTagId = useSelector((state: AppState) => selectDriversByTags(state, tags));
  const areSettingsLoading = useSelector(selectAreSettingsLoading);
  const areTripsLoading = useSelector(selectAreTripsLoading);
  const unassignedTrips = useSelector((state: AppState) => selectTripsByCardId(state, unassignedId));
  const tripsView = useSelector((state: AppState) => selectTripViews(state));

  const tripsByDriver: any = Object.values(tripsView).flatMap((arr: any) => arr).reduce((store: any, trip: any) => {
    const { driverId } = trip;
    if (!driverId) {
      return store;
    }
    return {
      ...store,
      [driverId]: trip,
    };
  }, {});

  const assignedDrivers = getAssignedDrivers(driversByTagId, tripsByDriver);
  const emptyDrivers = getEmptyDrivers(driversByTagId, tripsByDriver);

  return (
    <>
      {areSettingsLoading || areTripsLoading ? <Loader text={LOADING_TEXT} /> : null}
      {unassignedTrips.length > 0 && !areTripsLoading ? (
        <DriverCard
          driver={getUnassignedCard(tabKey)}
          headers={headers}
          shouldExpandAllItems={shouldExpandAllItems}
        />
      ) : null}
      {!areTripsLoading && assignedDrivers.map((driver) => (
        <DriverCard
          key={driver.entity_id}
          driver={driver}
          headers={headers}
          shouldExpandAllItems={shouldExpandAllItems}
        />
      ))}
      <h4>Drivers With No Trips</h4>
      {!areTripsLoading && emptyDrivers.map((driver) => (
        <p key={driver.entity_id}>{driver.data.samsara_name}</p>
      ))}
    </>
  );
}
