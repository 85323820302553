export const TRIP_SUMMARY_TABLE_HEADERS = [
  {
    name: 'Trip No.',
    key: 'trip_no',
  },
  {
    name: 'Date',
    key: 'date',
  },
  {
    name: 'Board',
    key: 'board',
  },
  {
    name: 'Group',
    key: 'group',
  },
  {
    name: 'Driver',
    key: 'driver',
  },
  {
    name: 'Route',
    key: 'route',
  },
];
