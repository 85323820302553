/* eslint-disable no-lonely-if */
/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import{ AppNotification } from './notifications.models';
import{
  getDispatch,
  getInitialState,
  updateDispatchNotificationState,
  isMultiDispatchComplete,
  showDispatchRequestNotification,
} from './notifications.state.utils';
import { AppState, DispatchProps } from '../../models/state.models';
import { NOTIFICATIONS } from '../slices';
import NotificationApi from '../../../services/notifications/NotificationApi';
import { EntityContainer } from '../../models/core.models';
import { notificationToast } from '../../../components/notifications/app-toast';
import { DateService } from '../../../utils/dateService';

const SLICE_NAME = NOTIFICATIONS;
const initialState = getInitialState();
export class RequestIdState {
  static shown: EntityContainer<string> = {};
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAppNotifications(state, action) {
      state.appNotifications = action.payload;
    },
    setPusherNotification(state, action) {
      state.notifications = [
        ...state.notifications,
        action.payload,
      ];
    },
    setIsNotificationsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsNotificationsLoaded(state, action) {
      state.isLoaded = action.payload;
    },
    setMultiTripDispatch(state, action) {
      console.log('setMultiTripDispatch: ');
      console.log(action.payload);
      const tripDispatch = action.payload;
      const requestId = tripDispatch.request_id || '';
      state.multiDispatches[requestId] = tripDispatch;
    },
    setSelectedRequestId(state, action) {
      state.selectedRequestId = action.payload || null;
    },
    setTripDispatchNotification(state, action) {
      console.log('setTripDispatchNotification');
      const notification = action.payload.notification || {};
      const orgCode = action.payload.orgCode || '';
      const requestId = notification?.request_id || '';
      const multiDispatch = state.multiDispatches[requestId];
      const tripDispatch = getDispatch(state.multiDispatches, notification);
      console.log(notification);
      console.log(multiDispatch);
      if (multiDispatch) {
        const newState = updateDispatchNotificationState(state.multiDispatches, notification);
        const newRequest = newState[requestId];
        const isRequestComplete = isMultiDispatchComplete(newRequest);
        console.log('There is a trip dispatch');
        console.log(tripDispatch);
        console.log('newState:')
        console.log(newState);
        console.log('isRequestComplete: ', isRequestComplete);
        state.multiDispatches = newState;
        if (isRequestComplete && RequestIdState.shown[requestId] !== requestId) {
          RequestIdState.shown[requestId] = requestId;
          showDispatchRequestNotification(newRequest);
        }
      } else {
        console.log('no multi request object in state');
        console.log('RequestIdState.shown[requestId] !== requestId: ', RequestIdState.shown[requestId] !== requestId);
        console.log(notification);
        if (RequestIdState.shown[requestId] !== requestId) {
          RequestIdState.shown[requestId] = requestId;
          notificationToast(notification, orgCode);
        }
      }
    },
    setUnreadCount(state, action) {
      state.unreadCount = action.payload || 0;
    },
    resetNotifications: () => getInitialState(),
  },
});

export const {
  setAppNotifications,
  setPusherNotification,
  setIsNotificationsLoading,
  setIsNotificationsLoaded,
  setMultiTripDispatch,
  setSelectedRequestId,
  setTripDispatchNotification,
  resetNotifications,
  setUnreadCount,
} = slice.actions;

export const getNotifications = (userId: string) => {
  return NotificationApi.fetchUserNotifications(userId);
};

export const setRead = (notification: AppNotification) => {
  const data: AppNotification = {
    ...notification,
    data: {
      ...notification.data,
      read: true,
    },
  }
  return NotificationApi.update(data);
};

export const getUnreadCount = (userId: string) => async (dispatch: DispatchProps) => {
  const response = await NotificationApi.fetchUnreadNotifications(userId);
  if (response.status === 200) {
    const count = response.data.data.total_count || 0;
    dispatch(setUnreadCount(count));
  }
};

export const selectAppNotifications = (state: AppState) => state[SLICE_NAME].appNotifications || [];
export const selectPusherNotifications = (state: AppState) => state[SLICE_NAME].notifications || [];
export const selectUnreadCount = (state: AppState) => state[SLICE_NAME].unreadCount || 0;
export const selectIsNotificationsLoaded = (state: AppState) => state[SLICE_NAME].isLoaded || false;
export const selectIsNotificationsLoading = (state: AppState) => state[SLICE_NAME].isLoading || false;
export const selectMultiDispatches = (state: AppState) => state[SLICE_NAME].multiDispatches || {};
export const selectSelectedRequest = (state: AppState) => {
  const requestId = state[SLICE_NAME].selectedRequestId;
  if (!requestId) return null;
  return selectMultiDispatches(state)[requestId];
};
export const selectDispatchNotifications = (state: AppState) => {
  const dispatchRequests = selectMultiDispatches(state);
  const ques = Object.values(dispatchRequests).flatMap((namm) => {
    const items = namm.queued;
    return Object.values(items)
  }).sort((a, b) => DateService.getUnixTime(b.created_at) - DateService.getUnixTime(a.created_at));
  return ques;
}
export const notificationsReducer = slice.reducer;
