/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { loadOrgs, loadIntegrations } from '../../redux/slices/organization/organisation';
import ButtonSpinner from '../../components/shared/ButtonSpinner';
import { EntityContainer } from '../../redux/models/core.models';
import IntegrationListItem from './IntegrationListItem';

const createStore = (integrations: Array<any>) => {
  const defaultStore: EntityContainer<any> = {};
  return integrations.reduce((store, integration) => {
    const appId = integration.data?.app?.entity_id || '';
    if (appId === '') return store;
    const ints = store[appId] || [];
    return {
      ...store,
      [appId]: ints.length > 0 ? [...ints, integration] : [integration],
    };
  }, defaultStore);
};

export default function IntegrationsList() {
  const { orgCode } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [apps, setApps] = useState<Array<any>>([]);
  const [integrations, setIntegrations] = useState<EntityContainer<Array<any>>>({});
  console.log(integrations);
  /**
   * - Load org
   *  - filter apps
   * - Load integrations
   */
  useEffect(() => {
    const load = async (organisationCode: string) => {
      try {
        setIsLoading(true);
        const orgResponse = await loadOrgs(organisationCode);
        if (orgResponse) {
          const items = orgResponse.data.data.items || [];
          const org = items.find((item: any) => item.data.organization_code === organisationCode);
          if (org) {
            const entityId = org.entity_id || '';
            const appData = org.data.apps || [];
            const integrationsResponse = await loadIntegrations(entityId);
            const userApps = appData.filter((app: any) => {
              const types = app.data.authentication_types || [];
              return app.data.authentication_type || types.length;
            });
            setApps(userApps);
            if (integrationsResponse.status === 200) {
              const integrationData = integrationsResponse.data.data || [];
              const store = createStore(integrationData);
              setIntegrations(store);
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (orgCode) load(orgCode);
  }, [orgCode]);
  return (
    <Container>
      <p>Integrations</p>
      {isLoading && <ButtonSpinner />}
      {!isLoading && (
        <Row xs={1} md={3} lg={4} className="g-4">
          {apps.map((app) => {
            const appIntegrations = integrations[app.entity_id] || [];
            return (
              <Col key={app.entity_id}>
                <IntegrationListItem app={app} integrations={appIntegrations} />
              </Col>
            )
          })}
        </Row>
      )}
    </Container>
  )
}
