/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button, Card, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ControlInput, InputSwitch, SelectInput } from '../../components/shared/Input';
import Permission from '../../components/shared/permissions/Permission';
import {
  BillingItemReadPermissions, BillingItemWritePermissions
} from '../../components/shared/permissions/permissions.utils';
import { getSettingByName, selectUoms } from '../../redux/slices/settings';
import { Api } from '../../services/services';
import useAppDispatch from '../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../constants/core.constants';
import { BillingItem, BillingItemData } from '../../redux/models/billing.models';
import UomInput from '../../components/entities/uom/UomInput';
import { DataModel } from '../../redux/models/core.models';
import {
  DepartmentCode, DepartmentCodeData, GlCode, GlCodeData, UOM, UomData
} from '../../redux/models/settings.models';
import { createDropdownOptions } from '../../utils/core.utils';
import GLCodeInput from '../../components/entities/gl-code/GLCodeInput';
import DepartmentCodeInput from '../../components/entities/department-code/DepartmentCodeInput';
import { DEFAULT_API_QUERY } from '../../redux/models/network.models';
import { CURRENCY_OPTIONS, initialData } from '../../components/entities/billing-items/data';
import AsyncButton from '../../components/shared/buttons/AsyncButton';
import Loader from '../../components/Loader';
import DeleteModal from '../../components/modals/DeleteModal';
import BreadCrumbs, { BreadCrumbRoute } from '../../components/shared/breadcrumbs/BreadCrumbs';

const PAGE = 'add_billing_item_modal';
const TITLE = 'Rate';
const getRoutes = (orgCode: string | undefined, rate: BillingItem | null): BreadCrumbRoute[] => {
  const routes = [
    {
      name: 'Settings',
      route: `/${orgCode || ''}/settings`,
    },
    {
      name: 'Rates',
      route: `/${orgCode}/settings/rates`,
    },
  ];
  if (rate) {
    routes.push({
      name: rate.data.description ?? 'No name',
      route: `/${orgCode}/settings/rates/${rate.entity_id}`,
    });
  }
  return routes;
};

function BillingItemDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode, entityId } = useParams();
  const uoms = useSelector(selectUoms);
  const [deps, setDeps] = useState<DepartmentCode[]>([]);
  const [gls, setGls] = useState<GlCode[]>([]);

  const [rate, setRate] = useState<BillingItem | null>(null);
  const [department, setSelectedDepartment] = useState<DepartmentCode | null>(null);
  const [description, setDescription] = useState(rate?.data?.description || '');
  const [price, setPrice] = useState(rate?.data?.price || 0);
  const [itemCode, setItemCode] = useState(rate?.data?.item_code || '');
  const [currency, setCurrency] = useState(rate?.data?.currency || CURRENCY_OPTIONS[0].value);
  const [accessorial, setAccessorial] = useState(rate?.data?.accessorial || false);
  const [gl, setSelectedGl] = useState<GlCode | null>(null);
  const [uom, setSelectedUom] = useState<UOM | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  
  const isDisabled = description === '' ||
    itemCode === '' ||
    department === null ||
    gl === null ||
    currency === '' ||
    uom === null;
  const uomOptions = createDropdownOptions(uoms);
  const depOptions = createDropdownOptions(deps);
  const glOptions = createDropdownOptions(gls);

  const getEntityData = (): BillingItemData => {
    return {
      ...initialData,
      description,
      gl,
      department,
      uom,
      item_code: itemCode,
      currency,
      price,
      accessorial,
    };
  };
  const handleSwitch = (_: string, checked: boolean) => {
    setAccessorial(checked);
  };
  const handleNewUom = (_: string, entity: DataModel<UomData>) => {
    setSelectedUom(entity);
  };
  const handleNewGlCode = (_: string, entity: DataModel<GlCodeData>) => {
    setSelectedGl(entity);
  };
  const handleNewDepartmentCode = (_: string, entity: DataModel<DepartmentCodeData>) => {
    setSelectedDepartment(entity);
  };
  const handleChange = (prop: string, val: string) => {
    if (prop === 'description') setDescription(val);
    if (prop === 'price') setPrice(Number(val));
    if (prop === 'item_code') setItemCode(val);
    if (prop === 'gl_code_id') {
      const glCode = gls.find((item) => item.entity_id === val);
      if (glCode) setSelectedGl(glCode);
    }
    if (prop === 'department_code_id') {
      const dep = deps.find((item) => item.entity_id === val);
      if (dep) setSelectedDepartment(dep);
    }
    if (prop === 'currency') setCurrency(val);
    if (prop === 'uom_id') {
      const uomCode = uoms.find((item) => item.entity_id === val);
      if (uomCode) setSelectedUom(uomCode);
    }
  };

  const handleUpdate = () => {
    if (!rate) {
      throw Error(`missing ${TITLE}`);
    }
    const entity: BillingItem = {
      ...rate,
      data: {
        ...rate.data,
        ...getEntityData(),
      },
    };
    return Api.BillingItems.update(entity);
  };
  const handleConfirmDelete = () => {
    setConfirmDelete(true);
  };
  const handleClose = () => navigate(`/${orgCode}/settings/rates`);
  const handleDelete = async () => {
    if (!rate) return;
    try {
      setIsDeleting(true);
      const response = await Api.BillingItems.delete(rate);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.BILLING_ITEMS, false));
        toast(`${description} successfully deleted.`, { type: 'success' });
        handleClose();
      }
    } catch (error) {
      toast(`Couldn't delete ${description}`, { type: 'error' });
    } finally {
      setIsDeleting(false);
    }
  };
  const handleSave = async () => {
    if (isDisabled) return;
    try {
      setIsSaving(true);
      await handleUpdate();
      await dispatch(getSettingByName(SETTINGS_NAMES.BILLING_ITEMS, false));
      toast(`${description} updated.`, { type: 'success' });
      navigate(-1);
    } catch (error) {
      toast(`Couldn't update ${description}`, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  useEffect(() => {
    const load = async (id: string) => {
      try {
        setIsLoading(true);
        const glResponse = await Api.GlCodes.find(DEFAULT_API_QUERY);
        const depResponse = await Api.DepartmentCodes.find(DEFAULT_API_QUERY);
        if (id !== 'new') {
          const rateResponse = await Api.BillingItems.getById(id);
          if (rateResponse.status === 200) {
            const rateData: BillingItem = rateResponse.data.data;
            setRate(rateData);
            setSelectedDepartment(rateData?.data.department || null);
            setDescription(rateData?.data.description || '');
            setPrice(rateData?.data.price || 0);
            setItemCode(rateData?.data.item_code || '');
            setCurrency(rateData?.data.currency || '');
            setAccessorial(rateData?.data.accessorial || false);
            setSelectedGl(rateData?.data.gl || null);
            setSelectedUom(rateData?.data.uom || null);
          }
        }
        if (glResponse.status === 200) {
          const glData = glResponse.data.data.items || [];
          setGls(glData);
        }
        if (depResponse.status === 200) {
          const depData = depResponse.data.data.items || [];
          setDeps(depData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (entityId) load(entityId);
  }, [entityId]);
  return (
    <Permission resources={BillingItemReadPermissions}>
      <>
        <BreadCrumbs routes={getRoutes(orgCode, rate)} />
        <Card>
          <Card.Header as="h4">Rate</Card.Header>
          <Card.Body>
            {isLoading && <Loader />}
            {!isLoading && (
              <Stack>
                <ControlInput
                  dataCy={`${PAGE}_description_input`}
                  name="description"
                  type="text"
                  page={PAGE}
                  value={description}
                  labelText="Description"
                  handleChange={handleChange}
                />
                <ControlInput
                  dataCy={`${PAGE}_price_input`}
                  name="price"
                  type="number"
                  page={PAGE}
                  value={`${price}`}
                  labelText="Price"
                  handleChange={handleChange}
                />
                <DepartmentCodeInput
                  value={department?.entity_id || ''}
                  page={PAGE}
                  prop="department_code_id"
                  label="Department Code"
                  disabled={false}
                  options={depOptions}
                  onChange={handleChange}
                  onNewEntity={handleNewDepartmentCode}
                />
                <GLCodeInput
                  value={gl?.entity_id || ''}
                  page={PAGE}
                  prop="gl_code_id"
                  label="GL Code"
                  disabled={false}
                  options={glOptions}
                  onChange={handleChange}
                  onNewEntity={handleNewGlCode}
                />
                <ControlInput
                  dataCy={`${PAGE}_item_code_input`}
                  name="item_code"
                  type="text"
                  page={PAGE}
                  value={itemCode}
                  labelText="Item Code"
                  handleChange={handleChange}
                />
                <SelectInput
                  name="currency"
                  page={PAGE}
                  value={currency}
                  options={CURRENCY_OPTIONS}
                  shouldHaveMargin={false}
                  handleChange={handleChange}
                  dataCy={`${PAGE}_currency_input`}
                  labelText="Currency"
                  multiple={false}
                />
                <UomInput
                  value={uom?.entity_id || ''}
                  page={PAGE}
                  prop="uom_id"
                  label="Unit Of Measure"
                  disabled={false}
                  options={uomOptions}
                  onChange={handleChange}
                  onNewEntity={handleNewUom}
                />
                <InputSwitch
                  labelText="Accessorial"
                  name="accessorial"
                  page="accessorial"
                  handleChange={handleSwitch}
                  checked={accessorial}
                  className=""
                  dataCy={`${PAGE}_accessorial_input`}
                  disabled={false}
                />
              </Stack>
            )}
            {confirmDelete && (
              <DeleteModal
                title="Delete Rate"
                description={`Would you like to delete ${description}?`}
                deleteBtnText="Delete"
                isDeleting={isDeleting}
                shouldShowDeleteModal={confirmDelete}
                deleteItem={handleDelete}
                cancelDeletion={() => setConfirmDelete(false)}
              />
            )}
          </Card.Body>
          {!isLoading && (
            <Card.Footer>
              <Button variant="outline-danger" onClick={handleConfirmDelete}>Delete</Button>
              <Stack direction="horizontal" gap={3} className="float-end justify-content-end">
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Permission resources={BillingItemWritePermissions}>
                  <AsyncButton
                    title="Save"
                    variant="primary"
                    spinner="light"
                    dataCy={`${PAGE}_save_button`}
                    disabled={isSaving}
                    handleClick={handleSave}
                  />
                </Permission>
              </Stack>
            </Card.Footer>
          )}
        </Card>
      </>
    </Permission>
  )
};

export default BillingItemDetails;
