/* eslint-disable no-new */
import React, { useEffect, useRef } from "react";

export default function MapView({
  center,
  zoom,
  title,
}) {
  const ref = useRef();
  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });
    if (title && title !== '') {
      new window.google.maps.Marker({
        position: center,
        map,
        title,
      });
    }
  });

  return <div ref={ref} id="map" style={{ height: '300px', width: '100%' }} />;
}
