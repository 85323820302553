/* eslint-disable */
import { SettingServiceProvider } from './SettingServiceProvider';
import { Address, AddressData } from '../../redux/models/settings.models';

class AddressServiceProvider extends SettingServiceProvider<AddressData> {
  estimateRoute(addresses: Address[]) {
    const url = `${this.endpoint.entityPath()}/route/estimate`;
    return this.api.post(url, addresses);
  }
}

export default AddressServiceProvider;
