/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
import {
  getFilterValueFromSessionStorage,
} from '../../utils/core.utils';
import {
  boardViewFormatter,
  BOARD_PAGE_TABS,
  FILTER_KEYS,
} from '../../pages/boards/constants/boards.constants';
import { DateService } from '../../utils/dateService';
import { formatTrips } from '../../pages/boards/TripTable/utils/tripTable.utils';
import { EntityContainer } from '../models/core.models';
import { TripBoardModel, TripDetails } from '../models/trip.models';
import {
  FilterButton,
  FILTER_OPTIONS,
} from '../../pages/planner/TripPlanner/DriverAvailability/DriverAvailabilityModels';
import { SearchDateRange } from '../../components/modals/SearchFilter/searchFilter.models';
import { TripBoardShipment } from '../models/shipment.models';

export interface PlannerViewState {
  driversCollapsed: boolean;
  filters: FilterButton[];
  groups: EntityContainer<string>;
  tags: EntityContainer<boolean>;
}

const initialPlannerState = {
  driversCollapsed: false,
  filters: FILTER_OPTIONS,
  groups: {},
  tags: {},
};

export interface TripState {
  areTripDetailsLoading: boolean;
  areTripsLoading: boolean;
  originalDriverId: string;
  filterParams: SearchDateRange;
  isTripRouteLoading: boolean;
  isAddingMove: boolean;
  isFormDirty: boolean;
  plannerState: PlannerViewState;
  selectedTab: string;
  tripDetails: TripDetails | null;
  tripRoutes: any[];
  trips: EntityContainer<TripBoardModel>;
  view: EntityContainer<TripBoardModel[]>; // key: group entity_id | driver_id; val: trip array sorted by appt time
  shipments: TripBoardShipment[];
}

export const initialState: TripState = {
  areTripsLoading: true,
  view: {},
  trips: {},
  shipments: [],
  areTripDetailsLoading: true,
  originalDriverId: '',
  tripDetails: null,
  isTripRouteLoading: false,
  isFormDirty: false,
  tripRoutes: [],
  isAddingMove: false,
  plannerState: initialPlannerState,
  filterParams: {
    dateFrom: getFilterValueFromSessionStorage(FILTER_KEYS.DATE_FROM) || DateService.getStartOfTheDayISO(),
    dateTo: getFilterValueFromSessionStorage(FILTER_KEYS.DATE_TO) || DateService.getEndOfTheDayISO(),
    // dateType: getFilterValueFromSessionStorage(FILTER_KEYS.DATE_TYPE) || DateRangeType.Today,
  },
  selectedTab: BOARD_PAGE_TABS.GROUPS.eventKey,
};

export const createTripsState = (trips: TripBoardModel[]) => {
  const { GROUPS } = BOARD_PAGE_TABS;
  const { DRIVERS } = BOARD_PAGE_TABS;
  const groupTrips = boardViewFormatter(trips, GROUPS.tripKey, GROUPS.eventKey);
  const driverTrips = boardViewFormatter(trips, DRIVERS.tripKey, DRIVERS.eventKey);
  const tripState: EntityContainer<TripBoardModel> = trips
    .reduce((container: EntityContainer<TripBoardModel>, trip) => ({
      ...container,
      [trip.id]: trip,
    }), {});

  const viewState: EntityContainer<TripBoardModel[]> = {
    ...groupTrips,
    ...driverTrips,
  };
  
  const shipments: EntityContainer<TripBoardShipment> = trips.reduce((container: EntityContainer<TripBoardShipment>, trip) => {
    const ships = trip.shipments || [];
    const data: EntityContainer<TripBoardShipment> = ships.reduce((container: EntityContainer<TripBoardShipment>, ship) => {    
      const id = ship.id || '';  
      return {
        ...container,
        [id]: ship,
      }
    }, {});
    return {
      ...container,
      ...data,
    }
  }, {});
  const shipmentViewState = Object.values(shipments).sort((a, b) => {
    const aCust = a.customer || '';
    const bCust = b.customer || '';
    // sort by customer name first
    const customerComp = aCust.localeCompare(bCust);
    if (customerComp !== 0) {
      return customerComp;
    }
    const aShipNo = a.shipment_no || '';
    const bShipNo = b.shipment_no || '';
    // sort by shipment_no second
    return aShipNo.localeCompare(bShipNo);
  });
  return {
    trips: tripState,
    view: viewState,
    shipments: shipmentViewState,
  };
};

export const updateTripsPusherEvent = (state: any, trip: any, boardSettings: any) => {
  const [formattedTripUpdate] = formatTrips({ trips: [trip], boardSettings });
  const stateTrips = Object.values(state.trips).map((storedTrip: any) => ({ ...storedTrip }));
  const doesTripExist = stateTrips.find((stateTrip) => stateTrip.id === formattedTripUpdate.id);

  return doesTripExist
    ? stateTrips.map((exTrip) => (exTrip.id === formattedTripUpdate.id ? formattedTripUpdate : exTrip))
    : [...stateTrips, formattedTripUpdate];
};

export const removeTripPusherEvent = (state: TripState, removedTrip: any) => {
  const trips = [...Object.values(state.trips)];
  return trips.filter((trip) => trip.id !== removedTrip.entity_id);
};
