import { Helmet } from 'react-helmet-async';
import {
  Card,
  Col,
  Container,
  Row,
  Tab,
  ListGroup,
} from 'react-bootstrap';

import SyncSettingsTable from './components/SyncSettingsTable';
import DispatchTemplates from './components/DispatchTemplate';
import {
  NotesTemplateWritePermissions,
  TripTemplateWritePermissions,
  ShipmentTypeWritePermissions
} from '../../../components/shared/permissions/permissions.utils';
import Permission from '../../../components/shared/permissions/Permission';
import Templates from '../../templates/TemplateList';
import ShipmentDetailsSettingsList from './shipments/ShipmentDetailsSettingsList';

const TITLE = 'Settings';

export default function Settings() {
  return (
    <>
      <Helmet title="Settings" />
      <Container fluid className="p-0">
        <h3>{TITLE}</h3>
        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#app">
          <Row>
            <Col md="3" xl="2">
              <Card>
                <ListGroup>
                  <ListGroup.Item action href="#app">
                    App Settings
                  </ListGroup.Item>
                  <Permission resources={NotesTemplateWritePermissions}>
                    <ListGroup.Item action href="#dispatch-notes">
                      Dispatch Notes
                    </ListGroup.Item>
                  </Permission>
                  <Permission resources={ShipmentTypeWritePermissions}>
                    <ListGroup.Item action href="#shipment-details">
                      Shipment Details
                    </ListGroup.Item>
                  </Permission>
                  <Permission resources={TripTemplateWritePermissions}>
                    <ListGroup.Item action href="#trip-templates">
                      Trip Templates
                    </ListGroup.Item>
                  </Permission>
                </ListGroup>
              </Card>
            </Col>
            <Col md="9" xl="10">
              <Card>
                <Tab.Content>
                  <Tab.Pane eventKey="#app">
                    <SyncSettingsTable />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#dispatch-notes"><DispatchTemplates /></Tab.Pane>
                  <Tab.Pane eventKey="#trip-templates"><Templates /></Tab.Pane>
                  <Tab.Pane eventKey="#shipment-details">
                    <Permission resources={ShipmentTypeWritePermissions}>
                      <ShipmentDetailsSettingsList />
                    </Permission>
                  </Tab.Pane>
                </Tab.Content>
              </Card>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}
