/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Card, Stack, Table } from 'react-bootstrap';
import BetaBadge from '../../../../components/badge/BetaBadge';
import ShipmentDetailsSettingsListItem from './ShipmentDetailsSettingsListItem';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { getSettingByName, selectShipmentTypes } from '../../../../redux/slices/settings';
import { SETTINGS_NAMES } from '../../../../constants/core.constants';
import { ShipmentType, ShipmentTypeData } from '../../../../redux/models/shipment.models';
import AddShipmentTypeModal from './AddShipmentTypeModal';
import { createShipmentType } from '../../../../redux/slices/shipment.types';
import Analytics from '../../../../utils/analytics';
import { defaultShipmentTypeFields } from '../../../../redux/slices/shipments.utils';

const TITLE = 'Shipment Details';
const LOADING_TEXT = 'Loading...';

export default function ShipmentDetailsSettingsList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const types: ShipmentType[] = useSelector(selectShipmentTypes);
  const [loading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleOpen = (type: ShipmentType) => {
    navigate(`/${orgCode}/settings/shipment-type/${type.entity_id}`);
  };
  const handleReload = async () => {
    await dispatch(getSettingByName(SETTINGS_NAMES.TRIP_TEMPLATES));
  };

  const handleCreateShipmentType = async (name: string) => {
    try {
      setIsSaving(true);
      const params: ShipmentTypeData = {
        name,
        fields: defaultShipmentTypeFields,
        delivery_attachments: [],
        misc_attachments: [],
        pick_up_attachments: [],
      };
      const response = await createShipmentType(params);
      if (response.status === 200) {
        await handleReload();
        setShowAddModal(false);
        handleOpen(response.data.data);
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <Card>
      <Card.Header>
        <div className="d-flex align-items-center justify-content-between">
          <Stack direction="horizontal" gap={2}>
            <h4 className="pt-2 mr-2">{TITLE}</h4>
            <BetaBadge />
          </Stack>
          <Button variant="primary" onClick={() => setShowAddModal(true)}>Add</Button>
        </div>
      </Card.Header>
      <Card.Body>
      {loading ? <p>{LOADING_TEXT}</p> : (
          <Table bordered hover className="mt-2">
            <tbody>
              {types.map((type) => (
                <ShipmentDetailsSettingsListItem
                  key={type.entity_id}
                  type={type}
                />
              ))}
            </tbody>
          </Table>
        )}
        {types.length === 0 && (
          <div>
            <p>No shipment types configured. Click the add button to create a new shipment type</p>
          </div>
        )}
      </Card.Body>
      <AddShipmentTypeModal
        shouldShowModal={showAddModal}
        isSaving={isSaving}
        saveItem={handleCreateShipmentType}
        cancelSave={() => setShowAddModal(false)}
      />
    </Card>
  );
}
