/* eslint-disable */
import { EntityServiceProvider } from './EntityServiceProvider';
import { AquadashServiceConfigProvider } from '../AquadashServiceConfigProvider';
import { DispatchNotificationParams, TripData } from '../../redux/models/trip.models';
import { DataModel } from '../../redux/models/core.models';
import anonAxios from '../../utils/anonAxios';
import { CreateTripAsyncProps } from './TripServiceProvider.models';

class TripServiceProvider extends EntityServiceProvider<TripData> {
  duplicate(tripId: string, shipments: boolean) {
    const base = `${this.endpoint.entityPath()}/duplicate/${tripId}`;
    const url = shipments ? `${base}/shipments` : base;
    return this.api.post(url);
  }

  deleteTrip(trip: DataModel<TripData>, deleteShipments: boolean, shipmentIds?: string[]) {
    if (deleteShipments) {
      const url = `${this.endpoint.entityPath()}/delete/${trip.entity_id}/shipments`;
      return this.api.post(url, {
        shipment_ids: shipmentIds || [],
      });
    }
    return this.delete(trip);
  }

  getSharedTrip(tripId: string, token: string, orgCode: string) {
    const url = `${this.endpoint.entityPath()}/share/${tripId}`;
    const headers = {
      'x-token': token,
      'x-organization-code': orgCode,
    };
    return anonAxios({
      method: 'GET',
      url,
      headers,
    });
  }

  confirmSharedTrip(tripId: string, token: string, orgCode: string) {
    const url = `${this.endpoint.entityPath()}/share/${tripId}/confirm`;
    const headers = {
      'x-token': token,
      'x-organization-code': orgCode,
    };
    return anonAxios({
      method: 'PUT',
      url,
      headers,
    });
  }

  completeSharedTrip(tripId: string, token: string, orgCode: string) {
    const url = `${this.endpoint.entityPath()}/share/${tripId}/complete`;
    const headers = {
      'x-token': token,
      'x-organization-code': orgCode,
    };
    return anonAxios({
      method: 'PUT',
      url,
      headers,
    });
  }

  list(boardId: string, filters: any) {
    return this.api.get(AquadashServiceConfigProvider.getTrips(boardId, filters));
  }

  getListViewById(tripId: string) {
    const url = `${this.endpoint.entityPath()}/list-view/${tripId}`;
    return this.api.get(url);
  }

  getDetailsById(tripId: string) {
    return this.api.get(AquadashServiceConfigProvider.getTripDetails(tripId));
  }

  dispatchEmail(tripId: string, data: DispatchNotificationParams) {
    const url = `${this.endpoint.entityPath()}/dispatch/${tripId}`;
    return this.api.post(url, data);
  }

  createTripAsync(templateId: string, data: CreateTripAsyncProps) {
    const url = `${this.endpoint.entityPath()}/create/${templateId}/async`;
    return this.api.post(url, data);
  }
}

export default TripServiceProvider;
