/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import { Button, Stack, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import { useState } from 'react';
import useAppDispatch from '../../../../hooks/useAppDispatch';

import { DateService } from '../../../../utils/dateService';

import { getSettingByName, selectSettings } from '../../../../redux/slices/settings';

import { capitalizeFirstLetter } from '../../../../utils/core.utils';
import { EntityContainer } from '../../../../redux/models/core.models';
import Analytics from '../../../../utils/analytics';
import { getSettingName } from '../../../../constants/core.constants';
import AsyncButton from '../../../../components/shared/buttons/AsyncButton';
import {
  BillingItemWritePermissions,
  ContactWritePermissions,
  DriverWritePermissions,
} from '../../../../components/shared/permissions/permissions.utils';
import Permission from '../../../../components/shared/permissions/Permission';

const UPDATED = 'Updated';
const SYNC = 'Sync';
const EDIT = 'Edit';

const DETAIL_PAGES: EntityContainer<boolean> = {
  'contacts': true,
  'billing_items': true,
  'drivers': true,
};

const ROUTES: EntityContainer<string> = {
  'contacts': 'contacts',
  'billing_items': 'rates',
  'drivers': 'drivers',
};

const RESOURCE_PERMISSIONS: EntityContainer<any> = {
  'contacts': ContactWritePermissions,
  'billing_items': BillingItemWritePermissions,
  'drivers': DriverWritePermissions,
};

export default function SyncSettingsTable() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const settings = useSelector(selectSettings);
  const [loadingSettings, setLoadingSettings] = useState<EntityContainer<boolean>>({});

  const syncSetting = async (key: string) => {
    setLoadingSettings({ ...loadingSettings, [key]: true });
    try {
      await dispatch(getSettingByName(key));
    } catch (e) {
      toast(`${capitalizeFirstLetter(key)} settings were not updated`, { type: 'error' });
      Analytics.capture(e);
    } finally {
      setLoadingSettings({ ...loadingSettings, [key]: false });
    }
  };

  const handleNavigate = (key: string) => {
    const route = ROUTES[key];
    if (route) navigate(`/${orgCode}/settings/${route}`);
  };

  const data = Object.keys(settings).sort((a, b) => {
    const aName = getSettingName(a);
    const bName = getSettingName(b);
    return aName.localeCompare(bName)
  });

  return (
    <Table bordered hover className="settings-table">
      <tbody>
        {data.map((key) => {
          const permissions = RESOURCE_PERMISSIONS[key];
          const isEdit = DETAIL_PAGES[key];
          return (
            <tr key={key}>
              <td>{getSettingName(key)}</td>
              <td>
                {UPDATED}
                {' '}
                {DateService.getFromNow(settings[key].lastUpdated)}
              </td>
              <td>
                <Stack direction="horizontal" gap={2}>
                  <AsyncButton
                    title={SYNC}
                    disabled={loadingSettings[key]}
                    variant="primary"
                    spinner="light"
                    handleClick={() => syncSetting(key)}
                  />
                  {isEdit && permissions && (
                    <Permission resources={permissions}>
                      <Button variant="secondary" onClick={() => handleNavigate(key)}>
                        {EDIT}
                      </Button>
                    </Permission>
                  )}
                </Stack>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  );
}
