/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Container, Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import SectionComponent from './components/Section'
import { TripTableColumns } from '../../../redux/models/board.models'

import { useResizableColumns } from '../../boards/DataGrid/utils'
import { ShipmentListView, ShipmentGroup, Shipment } from '../../../redux/models/shipment.models'
import {
  createShipment,
  selectGroupedShipments, selectShipmentContainer, selectShipmentFilterParams, updateShipmentListViewItem,
  addShipmentListViewItem
} from '../../../redux/slices/shipments'
import DataGridTable from './components/DataGridTable'
import useAppDispatch from '../../../hooks/useAppDispatch'


const getPostBody = (shipment: Shipment, prop: string, value: any) => {
  const updates = {
    ...shipment,
    data: {
      ...shipment.data,
      [prop]: value,
    }
  };
  if (prop === 'customer_id') {
    updates.data.billing_type_id = '';
  };
  return updates;
}

interface Props {
  boardSettings: any;
  headers: TripTableColumns[];
  shipments: ShipmentListView[];
  selectedShipments: string[];
  shipmentTypeId: string;
  setSelectedShipments: React.Dispatch<React.SetStateAction<string[]>>;
  onOpen: (shipmentId: string) => void;
  onInvoice: (shipmentId: string) => void;
  onCreateTrip: (shipmentId: string) => void;
}

function ShipmentDataGrid({
  boardSettings, headers, shipments, selectedShipments, shipmentTypeId,
  setSelectedShipments, onOpen, onInvoice, onCreateTrip
}: Props) {
  const dispatch = useAppDispatch();
  const [expandedGroupRows, setExpandedGroupRows] = useState<string[]>([]);
  const { columns, startResizing } = useResizableColumns(headers);
  const filters = useSelector(selectShipmentFilterParams);
  const groupedShipments: ShipmentGroup[] = useSelector(selectGroupedShipments);
  const container = useSelector(selectShipmentContainer);
  const groupBy = filters.grouping;
  const isGrouped = groupBy !== '';
  const handleUpdate = async (
    shipmentId: string, key: string, value: any
  ) => {
    const shipment = container[shipmentId];
    if (shipment) {
      const entity = getPostBody(shipment, key, value);
      try {
        console.log('updateShipmentListViewItem');
        await dispatch(updateShipmentListViewItem(entity, filters, boardSettings));
      } catch (error) {
        console.log(error);
      }
    }
  }
  const handleCreateShipment = async () => {
    try {
      const typeId = shipmentTypeId || '';
      const shipment = await createShipment(typeId);
      await dispatch(addShipmentListViewItem(shipment, filters, boardSettings));
    } catch (e) {
      if (e instanceof Error) {
        const errorMessage = `Couldn't add shipment. ${e.message}. Please contact support if the problem persists.`;
        toast(errorMessage, { type: 'error' });
      }
    }
  };
  const toggleExpand = (id: string) => {
    setExpandedGroupRows(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    )
  }

  const selectShipment = (e: React.ChangeEvent<HTMLInputElement>, shipmentId: string) => {
    e.stopPropagation();
    if (selectedShipments.includes(shipmentId)) {
      return setSelectedShipments(selectedShipments.filter((id: string) => id !== shipmentId));
    }
    return setSelectedShipments([...selectedShipments, shipmentId]);
  };

  return (
    <Card>
      <Container fluid className="mt-4 mb-2">
        {isGrouped && groupedShipments.map(group => (
          <SectionComponent
            key={group.id}
            groupBy={groupBy}
            group={group}
            columns={columns}
            startResizing={startResizing}
            onUpdate={handleUpdate}
            onGroupExpand={toggleExpand}
            expandedGroupRows={expandedGroupRows}
            selectedShipments={selectedShipments}
            onSelect={selectShipment}
            onOpen={onOpen}
            onInvoice={onInvoice}
            onCreateShipment={handleCreateShipment}
            onCreateTrip={onCreateTrip}
          />
        ))}
        {!isGrouped && (
          <DataGridTable
            columns={columns}
            selectedShipments={selectedShipments}
            shipments={shipments}
            onUpdate={handleUpdate}
            onSelect={selectShipment}
            startResizing={startResizing}
            onOpen={onOpen}
            onInvoice={onInvoice}
            onCreateShipment={handleCreateShipment}
            onCreateTrip={onCreateTrip}
          />
        )}
      </Container>
    </Card>
  )
}

export default ShipmentDataGrid;
