import axios from 'axios';

import { auth0Client } from '../contexts/Auth0Context';

import { LOCAL_STORAGE_TOKEN_KEY, getOrgCode } from '../config';

const axiosHopperInstance = axios.create({
  baseURL: process.env.REACT_APP_HOPPER_BASE_API_URL,
});

axiosHopperInstance.interceptors.request.use(
  async (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)}`,
      'X-Organization-Code': `${getOrgCode()}`
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosHopperInstance.interceptors.response.use((response) => response, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 0 && !originalRequest.retry) {
    originalRequest.retry = true;
    const accessToken = await auth0Client.getTokenSilently();
    window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, accessToken);
    return axiosHopperInstance(originalRequest);
  }
  return Promise.reject((error.response && error.response.data) || 'Something went wrong');
});

export default axiosHopperInstance;
