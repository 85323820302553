import React, { useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { NoteTemplate } from '../../../../redux/models/settings.models';
import DeleteModal from '../../../../components/modals/DeleteModal';
import { deleteDispatchTemplate } from '../../../../redux/slices/settings';

interface Props {
  template: NoteTemplate;
  handleReload: () => void;
  onOpen: (template: NoteTemplate) => void;
}

export default function DispatchTemplateRow({ template, handleReload, onOpen }: Props) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const handleEdit = () => {
    onOpen(template);
  };
  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      const response = await deleteDispatchTemplate(template);
      if (response.status === 200) {
        await handleReload();
        setShowDelete(false);
      }
    } catch (error) {
      toast(`Couldn't Delete ${template.data.name || ''}.`, { type: 'error' });
    } finally {
      setIsDeleting(false);
    }
  };
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <h5>{template.data.name || ''}</h5>
        </div>  
      </td>
      <td>
        <div className="float-end justify-content-end">
          <DropdownButton id="dropdown-basic-button" title="Options">
            <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => setShowDelete(true)}>Delete</Dropdown.Item>
          </DropdownButton>
        </div>
      </td>
      <DeleteModal
        title={`Delete ${template?.data?.name || ''}?`}
        description={`Would you like to delete the ${template?.data?.name || ''} dispatch notes template?`}
        deleteBtnText="Delete"
        isDeleting={isDeleting}
        shouldShowDeleteModal={showDelete}
        deleteItem={handleDelete}
        cancelDeletion={() => setShowDelete(false)}
      />
    </tr>
  );
}
