import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { selectAreTripsLoading } from '../../../../redux/slices/trips/trips';
import { BILLING_SHIPMENT_TABLE_HEADERS } from '../constants/shipmentTable.constants';
import {
  selectGroupedShipments, selectShipmentFilterParams
} from '../../../../redux/slices/shipment-list/shipment-list';
import ShipmentListGroupCard from './ShipmentListGroupCard';
import { ShipmentGroup } from '../../../../redux/slices/shipment-list/shipment-list.models';
import { TableHeader } from '../models/models';
import ShipmentListViewTableComponent from './ShipmentListViewTableComponent';
import { EntityContainer } from '../../../../redux/models/core.models';
import { Address } from '../../../../redux/models/settings.models';
import { Contact } from '../../../../redux/models/contact.models';
import { BillingType } from '../../../../redux/models/billing.models';

const NO_SHIPMENTS_MESSAGE = 'No shipments found';

interface Props {
  shipmentIds: string[];
  headers: TableHeader[];
  noShipmentsMessage: string;
  selectedShipments: string[];
  addresses: EntityContainer<Address>;
  contacts: EntityContainer<Contact>;
  billingTypes: EntityContainer<BillingType>;
  setSelectedShipments: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function ShipmentListViewTable({
  shipmentIds,
  headers = BILLING_SHIPMENT_TABLE_HEADERS,
  noShipmentsMessage = NO_SHIPMENTS_MESSAGE,
  selectedShipments,
  addresses,
  billingTypes,
  contacts,
  setSelectedShipments,
}: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const areTripsLoading = useSelector(selectAreTripsLoading);
  const filters = useSelector(selectShipmentFilterParams);
  const groupedShipments: ShipmentGroup[] = useSelector(selectGroupedShipments);
  const groupBy = filters.grouping;
  const openShipmentPage = (id: string) => navigate(`/${orgCode}/shipments/${id}`);

  const selectShipment = (e: React.ChangeEvent<HTMLInputElement>, shipmentId: string) => {
    e.stopPropagation();
    if (selectedShipments.includes(shipmentId)) {
      return setSelectedShipments(selectedShipments.filter((id: string) => id !== shipmentId));
    }
    return setSelectedShipments([...selectedShipments, shipmentId]);
  };

  const renderShipmentsTable = (tableShipments: string[]) => (
    <ShipmentListViewTableComponent
      areTripsLoading={areTripsLoading}
      headers={headers}
      noShipmentsMessage={noShipmentsMessage}
      shipmentIds={tableShipments}
      addresses={addresses}
      billingTypes={billingTypes}
      contacts={contacts}
      openShipmentPage={openShipmentPage}
      selectShipment={selectShipment}
      selectedShipments={selectedShipments}
    />
  );

  return (
    <div className={`${groupBy ? '' : 'bg-white'}`}>
      {groupBy !== '' ? (
        groupedShipments.map((group) => {
          return (
            <ShipmentListGroupCard
              key={group.id}
              shouldExpandAllItems
              groupBy={groupBy}
              groupByKey={group.id}
              contacts={contacts}
              billingTypes={billingTypes}
            >
              {renderShipmentsTable(group.shipments)}
            </ShipmentListGroupCard>
          )
        })
      ) : (
        renderShipmentsTable(shipmentIds)
      )}
    </div>
  );
}
