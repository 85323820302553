import { EntityContainer } from '../../../redux/models/core.models';
import { Address } from '../../../redux/models/settings.models';
import { Move } from '../../../redux/models/trip.models';
import { DateService } from '../../../utils/dateService';

const formatDate = (date: string | undefined | null) => {
  return `${DateService.getDDMMFormat(date)} ${DateService.getHHmmFormat(date)}`;
};

export const formatTCMoves = (
  moves: Move[],
  addresses: EntityContainer<Address>
) => [...moves].map((move) => {
  const current = move;
  const addressId = current?.data?.destination_id || '';
  return {
    id: move.entity_id,
    position: move?.data?.position,
    move: (move?.data?.position || 0) + 1,
    time: formatDate(current?.data.scheduled_arrival_time),
    from: move?.data?.trailer_ids,
    to: current?.data?.trailer_ids,
    action: current?.data?.action,
    shipments: current?.data?.shipment_ids,
    routes: addresses[addressId]?.data?.samsara_name || '',
  };
});
