import React from 'react';
import { useSelector } from 'react-redux';
import { SelectInput } from '../../shared/Input';
import { DropdownOptionsModel } from '../../shared/SelectInput.models';
import {
  selectAssociatedMoveStateById, setAssociatedMoveState
} from '../../../redux/slices/trailer-changes/trailerChanges';
import { AppState } from '../../../redux/models/state.models';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { MoveState } from '../../../redux/slices/trailer-changes/trailerChanges.models';

interface Props {
  moveId: string;
  options: DropdownOptionsModel[];
}

export default function TrailerChangeToInput({ moveId, options }: Props) {
  const dispatch = useAppDispatch();
  const moveState = useSelector((state: AppState) => selectAssociatedMoveStateById(state, moveId));
  const trailerIds = moveState?.current.trailer_ids || [];
  const handleChange = (_: string, val: string[]) => {
    const updates: MoveState = {
      ...moveState,
      current: {
        ...moveState.current,
        trailer_ids: val
      },
    };
    dispatch(setAssociatedMoveState({ moveId, moveState: updates }));
  };
  return (
    <SelectInput
      multiple
      name="to"
      page="{page}"
      value={trailerIds}
      options={options}
      shouldHaveMargin={false}
      handleChange={handleChange}
      dataCy="move_trailer_to"
      labelText=""
    />
  )
}
