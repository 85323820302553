import React from 'react';
import SharePopup from './SharePopup';
import Permission from '../shared/permissions/Permission';
import { GroupReadPermissions } from '../shared/permissions/permissions.utils';
import useFeatureFlags from '../../hooks/useFeatureFlags';

interface Props {
  roleIds: string[];
  onUpdateRoles: (roleIds: string[]) => void;
}

export default function ShareButton({ roleIds, onUpdateRoles }: Props) {
  const { share } = useFeatureFlags();
  const isEnabled = share?.is_enabled || false;
  return (
    <Permission resources={GroupReadPermissions}>
      {isEnabled ? (
        <SharePopup roleIds={roleIds} onUpdateRoles={onUpdateRoles} />
      ) : (
        <div />
      )}
    </Permission>
  );
};
