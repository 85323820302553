import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Popover, OverlayTrigger, Spinner } from 'react-bootstrap';

import { Role } from '../../redux/slices/roles/role.models';
import { loadRoles, selectIsRolesLoading, selectRoles } from '../../redux/slices/roles/roles';
import useAppDispatch from '../../hooks/useAppDispatch';
import ShareList from './ShareList';

interface Props {
  roleIds: string[];
  onUpdateRoles: (roleIds: string[]) => void;
}

export default function SharePopup({ roleIds, onUpdateRoles }: Props) {
  const dispatch = useAppDispatch();
  const { orgCode } = useParams();
  const roles = useSelector(selectRoles);
  const isLoading = useSelector(selectIsRolesLoading);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isActive, setIsActive] = useState(false);
  const isSearchActive = searchTerm.length > 0;
  const isEmpty = roleIds.length === 0;
  const showSelected = !isLoading && !isActive && !isEmpty;
  const showSuggested = (!isLoading && isActive && !isSearchActive) || isEmpty;
  const showSearch = !isLoading && isActive && isSearchActive;
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleUserSelect = (role: Role) => {
    if (!roleIds.some(roleId => roleId === role.id)) {
      onUpdateRoles([...roleIds, role.id]);
    } else {
      onUpdateRoles([...roleIds].filter((roleId) => roleId !== role.id));
    }
    setIsActive(false);
  };

  const handleToggle = (show: boolean) => {
    if (!show) setIsActive(false);
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h4">
        <div className="d-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search groups"
            value={searchTerm}
            onChange={handleSearchChange}
            onFocus={() => setIsActive(true)}
          />
          <Button onClick={() => setIsActive(false)}>Clear</Button>
        </div>
      </Popover.Header>
      <Popover.Body>
        {isLoading && (
          <Spinner animation="border" variant="primary" size="sm" />
        )}
        {showSelected && (
          <ShareList 
            title="Groups With Access"
            selected={roleIds}
            roles={roles
              .filter(role => roleIds.includes(role.id))}
            showIcon
            handleSelect={handleUserSelect}
          />
        )}
        {showSuggested && (
          <ShareList 
            title="Suggested"
            selected={roleIds}
            roles={roles
              .filter(role => !roleIds.includes(role.id))}
            showIcon
            handleSelect={handleUserSelect}
          />
        )}
        {showSearch && (
          <ShareList 
            title="Search"
            selected={roleIds}
            roles={roles
              .filter(role => role.name.toLowerCase().includes(searchTerm.toLowerCase()))}
            showIcon
            handleSelect={handleUserSelect}
          />
        )}
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    const loadGroups = async (org: string) => {
      try {
        dispatch(loadRoles(org));
      } catch (error) {
        console.log(error);
      }
    }
    if (orgCode && !isLoading) loadGroups(orgCode);
  }, [orgCode, isLoading, dispatch]);

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover} onToggle={handleToggle}>
      <Button variant="primary">Share</Button>
    </OverlayTrigger>
  );
};
