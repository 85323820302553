import {
  Button, Card, Col, Dropdown, DropdownButton, Row,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../trips/TripShipments/ShipmentCard.scss';
import Permission from '../../../../components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../../../redux/models/feature.flags.models';
import { Invoice } from '../../../../redux/models/invoice.models';

const WritePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Write,
};

const TITLE = 'Invoice';
const INVOICE_BTN_TITLE = 'View Invoice';
const REMOVE_TEXT = 'Remove Shipment';

interface Props {
  invoiceDetails: Invoice;
  setRemoveShipmentModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function InvoiceCard({
  invoiceDetails,
  setRemoveShipmentModal,
}: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const handleViewInvoice = (invoiceId: string) => navigate(`/${orgCode}/invoices/${invoiceId}`);

  const { status, invoice_no: invoiceNo } = invoiceDetails.data;
  const invoiceId = invoiceDetails.entity_id || '';
  const invoiceShipments = invoiceDetails.data?.shipment_ids || [];
  const invoiceNumberDisplay = `Invoice Number: ${invoiceNo}`;
  const statusDisplay = `Status: ${status || ''}`;

  return (
    <Card data-cy="invoice-card">
      <Card.Body>
        <Row className="mt-2">
          <Col md={2} className="d-flex flow-row">
            <h4>{TITLE}</h4>
            {(invoiceShipments.length > 1) && (
            <Permission resources={WritePermissions}>
              <DropdownButton
                className="caret invoice-dropdown"
                variant="link"
                id="shipmentDetails-invoice-dropdown"
                title="•••"
                data-cy="shipmentDetails_invoice_dropdown"
              >
                <Dropdown.Item
                  data-cy="removeInvoice_btn"
                  onClick={() => setRemoveShipmentModal(true)}
                >
                  {REMOVE_TEXT}
                </Dropdown.Item>
              </DropdownButton>
            </Permission>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            {invoiceNumberDisplay}
          </Col>
          <Col md={4}>
            <Button
              id="viewInvoice__btn"
              data-cy="viewInvoice_btn"
              variant="outline-primary"
              className="float-end"
              onClick={() => handleViewInvoice(invoiceId)}
            >
              {INVOICE_BTN_TITLE}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {statusDisplay}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
