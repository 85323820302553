import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { Download, PlusSquare } from 'react-feather';

import { Document } from '../../redux/models/file.models';
import { FileServiceApi, DownloadPostBody } from '../../services/FileServiceApi';
import { getId } from '../../pages/trips/Moves/components/AssignDocModal.utils';

interface Props {
  documents: Document[];
  showAssign?: boolean;
  handleAssignDocument: (doc: Document, idx: number) => void;
}

function DocumentsGrid({ documents, showAssign, handleAssignDocument }: Props) {
  const [downloads, setDownloads] = useState<Document[]>([]);
  const handleDownload = async (attachment: Document) => {
    try {
      await FileServiceApi.open(attachment);
    } catch (e) {
      const errorMessage = "Couldn't download attachments. Please contact support if the problem persists.";
      toast(errorMessage, { type: 'error' });
    }
  }
  useEffect(() => {
    const download = async (docs: Document[]) => {
      const fas: DownloadPostBody[] = docs.map((doc) => {
        return {
          name: doc.path,
          filetype: doc.filetype,
        }
      });
      const data = await FileServiceApi.getFiles(fas);
      setDownloads(data);
    }
    download(documents);
  }, [documents]);
  return (
    <div className="d-flex flex-wrap justify-content-between">
      {documents.map((doc, idx) => {
        const docId = getId(doc);
        const download = downloads.find((dnw) => dnw.name === doc.path);
        const isImg = doc.filetype === 'image/jpeg';
        return (
          <div
            key={docId}
            className="col-6"
          >
            <div className="m-1 border px-2 pt-1 rounded">
              {download && isImg && (
                <img
                  src={download.path}
                  alt={download.name}
                  draggable
                  width="100%"
                  className="rounded my-1 object-fit-cover"
                />
              )}
              <div className="mt-1">
                <div className="d-flex">
                  <p className="p-0 m-0 ml-1">{`${idx + 1}. ${doc.name}`}</p>
                </div>
                <div>
                  <Button
                    type="button"
                    variant="none"
                    onClick={() => handleDownload(doc)}
                  >
                    <Download className="feather align-middle" />
                  </Button>
                  {showAssign && (
                    <Button
                      type="button"
                      variant="none"
                      onClick={() => handleAssignDocument(doc, idx)}
                    >
                      <PlusSquare className="feather align-middle" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      })}
      {/* {downloads.map((download) => {
        if (download.filetype === 'application/pdf') {
          return (
            <object data={download.path} type="application/pdf" width="100%" height="100%">
              <a href={download.path}>{download.name}</a>
            </object>
          )
        }
        return (
          <img src={download.path} alt={download.name} width="100%" height="100%" />
        )
      })} */}
    </div>
  )
}

DocumentsGrid.defaultProps = {
  showAssign: false,
}

export default DocumentsGrid;