import { FeatureResource, ResourcePermission } from "../../../redux/models/feature.flags.models";

export const AddressReadPermissions = {
  [FeatureResource.Address]: ResourcePermission.Read,
};

export const AddressWritePermissions = {
  [FeatureResource.Address]: ResourcePermission.Write,
};

export const AvailabilityReadPermissions = {
  [FeatureResource.DriverAvailability]: ResourcePermission.Read,
};

export const BillingItemReadPermissions = {
  [FeatureResource.BillingItem]: ResourcePermission.Read,
};

export const BillingItemWritePermissions = {
  [FeatureResource.BillingItem]: ResourcePermission.Write,
};

export const BillingTypeReadPermissions = {
  [FeatureResource.BillingType]: ResourcePermission.Read,
};

export const BillingTypeWritePermissions = {
  [FeatureResource.BillingType]: ResourcePermission.Write,
};

export const ContactReadPermissions = {
  [FeatureResource.Contact]: ResourcePermission.Read,
};

export const ContactWritePermissions = {
  [FeatureResource.Contact]: ResourcePermission.Write,
};

export const CostReadPermissions = {
  [FeatureResource.Cost]: ResourcePermission.Read,
};

export const CostWritePermissions = {
  [FeatureResource.Cost]: ResourcePermission.Write,
};

export const DepartmentCodeReadPermissions = {
  [FeatureResource.DepartmentCode]: ResourcePermission.Read,
};

export const DepartmentCodeWritePermissions = {
  [FeatureResource.DepartmentCode]: ResourcePermission.Write,
};

export const DriverReadPermissions = {
  [FeatureResource.Driver]: ResourcePermission.Read,
};

export const DriverWritePermissions = {
  [FeatureResource.Driver]: ResourcePermission.Write,
};

export const FuelSurchargeTypeReadPermissions = {
  [FeatureResource.FuelSurchargeType]: ResourcePermission.Read,
};

export const FuelSurchargeTypeWritePermissions = {
  [FeatureResource.FuelSurchargeType]: ResourcePermission.Write,
};

export const GlCodeReadPermissions = {
  [FeatureResource.GlCode]: ResourcePermission.Read,
};

export const GlCodeWritePermissions = {
  [FeatureResource.GlCode]: ResourcePermission.Write,
};

export const GroupReadPermissions = {
  [FeatureResource.Group]: ResourcePermission.Read,
};

export const InvoiceWritePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Write,
};

export const NotesTemplateWritePermissions = {
  [FeatureResource.NotesTemplate]: ResourcePermission.Write,
};

export const ShipmentWritePermissions = {
  [FeatureResource.Shipment]: ResourcePermission.Write,
};

export const ShipmentTypeWritePermissions = {
  [FeatureResource.ShipmentType]: ResourcePermission.Write,
};

export const TripReadPermissions = {
  [FeatureResource.Trip]: ResourcePermission.Read,
};

export const TripWritePermissions = {
  [FeatureResource.Trip]: ResourcePermission.Write,
};

export const TripTemplateWritePermissions = {
  [FeatureResource.TripTemplate]: ResourcePermission.Write,
};

export const UomReadPermissions = {
  [FeatureResource.Uom]: ResourcePermission.Read,
};

export const UomWritePermissions = {
  [FeatureResource.Uom]: ResourcePermission.Write,
};


export const VehicleReadPermissions = {
  [FeatureResource.Vehicle]: ResourcePermission.Read,
};

export const VehicleWritePermissions = {
  [FeatureResource.Vehicle]: ResourcePermission.Write,
};
