import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
// import { Archive, Package } from 'react-feather';
import Wrapper from '../components/Wrapper';
import Sidebar from '../components/sidebar/Sidebar';
import Main from '../components/Main';
import Navbar from '../components/navbar/Navbar';
import Content from '../components/Content';
import Footer from '../components/Footer';

import useAppDispatch from '../hooks/useAppDispatch';

import { getSettings, selectAreSettingsLoading, selectAllBoards } from '../redux/slices/settings';

import { formatBoardsToSidebarLinks } from '../utils/core.utils';
import { isPermissions } from '../redux/slices/settings.utils';
import { FeatureResource, ResourcePermission } from '../redux/models/feature.flags.models';
import { getPermissions } from '../config';
import { UserPermissionsProvider } from '../contexts/UserPermissionsContext';
import { FeatureFlagsProvider } from '../contexts/FeatureFlagContext';

const SIDEBAR_TITLE = 'Dispatch Boards';

const InvoicePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Read,
}

const BoardReadPermissions = {
  [FeatureResource.Board]: ResourcePermission.Read,
};

const ShipmentPermissions = {
  [FeatureResource.Shipment]: ResourcePermission.Read,
}

function Dashboard({ children }) {
  const { orgCode } = useParams();
  const dispatch = useAppDispatch();
  const boards = useSelector(selectAllBoards);
  const areSettingsLoading = useSelector(selectAreSettingsLoading);
  const permissions = getPermissions();

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);
  const sidebarItems = [];
  const isBoardsEnabled = isPermissions(orgCode, permissions, BoardReadPermissions);
  const isShipmentsEnabled = isPermissions(orgCode, permissions, ShipmentPermissions);
  const hasInvoicePermissions = isPermissions(orgCode, permissions, InvoicePermissions);
  const isInvociesEnabled = hasInvoicePermissions;

  if (isShipmentsEnabled) {
    const pages = [];
    if (isShipmentsEnabled) {
      const shipmentBoards = formatBoardsToSidebarLinks(boards, orgCode, 'shipment');
      pages.push(
        {
          title: 'Shipments',
          // icon: Package,
          allowAdd: false,
          href: `/${orgCode}/shipments`,
        },
      );
      shipmentBoards.forEach((board) => pages.push(board));
    }
    sidebarItems.push(
      {
        title: 'Orders',
        pages,
      }
    );
  }

  if (isInvociesEnabled) {
    sidebarItems.push(
      {
        title: 'Finance',
        pages: [
          {
            title: 'Invoices',
            // icon: Archive,
            href: `/${orgCode}/invoices`,
          },
        ],
      }
    );
  }


  if (isBoardsEnabled) {
    sidebarItems.push(
      {
        title: SIDEBAR_TITLE,
        allowAdd: true,
        pages: formatBoardsToSidebarLinks(boards, orgCode),
      }
    );
  }

  return (
    <Wrapper>
      <Sidebar items={sidebarItems} areItemsLoading={areSettingsLoading} />
      <Main>
        <UserPermissionsProvider>
          <FeatureFlagsProvider>
            <Navbar />
            <Content>
              {children}
              <Outlet />

            </Content>
            <Footer />
         </FeatureFlagsProvider>
        </UserPermissionsProvider>
      </Main>
    </Wrapper>
  );
}

export default Dashboard;
