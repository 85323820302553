import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { Move } from '../../../../redux/models/trip.models';
import { selectAddressesObject } from '../../../../redux/slices/settings';
import { getTimeliness } from '../../../../utils/timeliness';
import { DateService } from '../../../../utils/dateService';

interface Props {
  boardId: string;
  moves: Move[];
}

export default function TimelinessTable({ boardId, moves }: Props) {
  const { orgCode } = useParams();
  const addresses = useSelector(selectAddressesObject);
  const data = moves
    .filter((move) => move.data.board_id === boardId)
    .sort((a, b) => {
      const aDate = a.data.scheduled_arrival_time || '';
      const bDate = b.data.scheduled_arrival_time || '';
      return DateService.getUnixTime(aDate) - DateService.getUnixTime(bDate);
    });
  return (
    <Table className="mb-0 mt-4">
      <thead>
        <tr>
          <th>Address</th>
          <th>Action</th>
          <th>Date</th>
          <th>Scheduled Arrival</th>
          <th>Actual Arrival</th>
          <th>Variance</th>
          <th>Timeliness</th>
          {/* <th>Moves</th> */}
        </tr>
      </thead>
      <tbody>
        {data.map((move) => {
          const addressId = move.data.destination_id || '';
          const name = addresses[addressId]?.data.samsara_name || 'No name set';
          const action = move.data.action || '';
          const schedApt = move.data.scheduled_arrival_time || '';
          const actApt = move.data.actual_arrival_time || '';
          const tripId = move.data.trip_id || '';
          const timeliness = getTimeliness(schedApt, actApt);
          const variance = DateService.getDifferenceInMinutes(actApt, schedApt);
          return (
            <tr key={addressId}>
              <td>
                <Link to={`/${orgCode}/trips/${tripId}`}>{name}</Link>
              </td>
              <td className="capitalized">{action}</td>
              <td>{DateService.getDDMMYYYFormat(schedApt)}</td>
              <td>{DateService.getHHmmFormat(schedApt)}</td>
              <td>{DateService.getHHmmFormat(actApt)}</td>
              <td>{`${variance.toFixed(1)} mins`}</td>
              <td>{timeliness}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}