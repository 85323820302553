import React, { useState } from 'react';
import { Button, Form, Stack } from 'react-bootstrap';
import { EntityContainer } from '../../../redux/models/core.models';
import { Tag } from '../../../redux/models/settings.models';

interface Props {
  container: EntityContainer<Tag>;
  selectedTags: EntityContainer<boolean>;
  setSelectedTags: React.Dispatch<React.SetStateAction<EntityContainer<boolean>>>;
}

export default function ApplyDriverShiftTags({ container, selectedTags, setSelectedTags }: Props) {
  const [showTags, setShowTags] = useState(true);
  const tags = Object.values(container);
  const tagBtnTitle = showTags ? 'Hide' : 'Show';
  const sortedTags = [...tags].sort((a, b) => {
    const aName = a.data.name || '';
    const bName = b.data.name || '';
    return aName.localeCompare(bName);
  })
  const handleShowTags = () => setShowTags(!showTags);
  
  const handleSelectTag = (checked: boolean, driverId: string) => {
    setSelectedTags((prev) => {
      return {
        ...prev,
        [driverId]: checked,
      }
    });
  };
  return (
    <>
      <Stack direction="horizontal">
        <h4>Tags</h4>
        <Button variant="link" onClick={handleShowTags}>{tagBtnTitle}</Button>
      </Stack>
      <div className="d-flex flex-wrap">
        {showTags && sortedTags.map((tag) => (
          <Stack
            key={tag.entity_id}
            direction="horizontal"
            className="align-items-center bg-light m-2 p-2 rounded"
          >
            <h5 className="my-1 mx-2">{tag.data.name || ''}</h5>
            <Form.Check // prettier-ignore
              type="switch"
              id="board_planner_table_select_outside_cartage_toggle"
              label=""
              className="ml-2"
              data-cy="board_planner_table_select_outside_cartage_toggle"
              checked={selectedTags[tag.entity_id] ?? false}
              onChange={(e) => handleSelectTag(e.target.checked, tag.entity_id)}
            />
          </Stack>
        ))}
      </div>
    </>
  )
}
