import { BillingTypeData } from "../../../redux/models/billing.models";

export const initialBillingTypeData: BillingTypeData = {
  accessorials: null,
  accessorial_ids: [],
  base_rate_id: '',
  base_rate: null,
  bill_to_id: '',
  currency: '',
  customer_id: '',
  description: '',
  fuel_surcharge_type: null,
  fuel_surcharge_type_id: '',
  name: '',
  price: 0,
  role_ids: [],
};
