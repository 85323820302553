/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { Entity, EntityContainer } from '../../../../redux/models/core.models';
import { Address } from '../../../../redux/models/settings.models';
import { Move } from '../../../../redux/models/trip.models';
import { DateService } from '../../../../utils/dateService';
import { Location } from '../../../../redux/slices/planner/planner.models';
import { haversineDistance } from '../../../../redux/slices/planner/planner.utils';

export const getLocationOptions = (fleetLocations: any, fleet: any) => Object.values(fleetLocations)
  .map((location: any) => {
    const trailerName = fleet[location?.trailer_id]?.data?.name;
    const dt = DateService.getDDMMFormat(location?.date);
    return {
      value: location.trailer_id,
      label: `${trailerName} • ${dt}`,
      destinationId: location.destination_id,
    };
  });

export const getEmptyStateOptions = (move: Move, fleetLocationOptions: any) => {
  const destinationId = move?.data?.destination_id;
  if (destinationId) {
    return fleetLocationOptions.filter((option: any) => option.destinationId === destinationId);
  }
  return fleetLocationOptions;
};

export const getValue = (id: string, items: EntityContainer<Entity>, name = 'name') => {
  if (!id) return 'No trailer selected';
  const obj = Object.values(items).find((item) => item.entity_id === id);
  return obj ? obj.data[name] : '';
};

export const getTrailerNames = (ids: string[], entities: EntityContainer<Entity>, key = 'name') => {
  if (ids.length === 0) return 'No trailers selected';
  const trailers = ids.reduce((names, id) => {
    const name = getValue(id, entities, key);
    if (names.length === 0) return name;
    return `${names}, ${name}`;
  }, '');
  return trailers;
};

export const findChangedMoves = (moveObj: any) => Object.entries(moveObj).map((move: any) => {
  const container: any = {};
  container[move[0]] = move[1][move[0]];
  return container;
});

const getAddress = (move: Move, addresses: EntityContainer<Address>): Address | undefined => {
  const addressId = move.data.destination_id;
  if (!addressId || addressId === '') return undefined;
  return addresses[addressId];
};

export const getDistanceAndTime = (moves: Move[], addresses: EntityContainer<Address>) => {
  const locations: Location[] = moves
    .map((move) => {
      const address = getAddress(move, addresses);
      return {
        name: address?.data.samsara_name || '',
        lat: address?.data.samsara_latitude || 0,
        lng: address?.data.samsara_longitude || 0,
      };
    })
    .filter((location) => location.name !== '' && location.lat !== 0 && location.lng !== 0);
  
  var totalDistance = 0;
  locations.forEach((loc, idx) => {
    if (idx > 0 && idx < locations.length) {
      const distance = haversineDistance(loc, locations[idx - 1]);
      totalDistance += distance;
    }
  });

  let totalTime = 0;
  if (moves.length > 0) {
    const firstMove = moves[0];
    const lastMove = moves[moves.length - 1];
    const firstAppt = firstMove.data.scheduled_arrival_time;
    const lastAppt = lastMove.data.scheduled_arrival_time;
    totalTime = (DateService.getDifferenceBetweenInHours(lastAppt, firstAppt));
  }
  return {
    distance: totalDistance,
    time: totalTime,
  }
};
