/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ButtonSpinner from '../../../../../components/shared/ButtonSpinner';
import { useSelector } from 'react-redux';
import { selectBillingTypeObject } from '../../../../../redux/slices/settings';
import EditableSelect from '../../../../../components/data-grid/base/EditableSelect';
import { DropdownOptionsModel } from '../../../../../components/shared/SelectInput.models';
import PlaceholderValue from '../../../../../components/data-grid/base/PlaceholderValue';
import { EntityContainer } from '../../../../../redux/models/core.models';
import { BillingType } from '../../../../../redux/models/billing.models';

const getName = (billingTypes: EntityContainer<BillingType>, id: string) => {
  return billingTypes[id]?.data?.name || '';
}

const BillingTypeCell: React.FC<{ customerId: string; billingTypeId: string; onChange: (value: string) => void }> = ({ customerId, billingTypeId, onChange }) => {
  const [editing, setEditing] = useState(false)
  const [tempValue, setTempValue] = useState(billingTypeId)
  const [isSaving, setSaving] = useState(false);
  const billingTypeContainer = useSelector(selectBillingTypeObject);
  const billingTypeOptions: DropdownOptionsModel[] = Object.values(billingTypeContainer)
    .filter((type) => {
      if (customerId === '') return false;
      return type.data.customer_id === customerId;
    })
    .map((item) => {
      return {
        value: item.entity_id,
        label: item.data.name || '',
      }
    })
  const name = billingTypeContainer[tempValue]?.data?.name || '';
  // console.log('customerId: ', customerId);
  // console.log('billingTypeId: ', billingTypeId);
  // console.log('billingTypeId is empty string: ', billingTypeId === '');
  // console.log('tempValue: ', tempValue);
  // console.log('name: ', getName(billingTypeContainer, tempValue));
  const handleEdit = () => {
    setEditing(true);
  };

  const handleChange = async (newCustomerId: string) => {
    setTempValue(newCustomerId);
    setSaving(true);
    setEditing(false);
    await onChange(newCustomerId);
    setSaving(false);
  };

  if (editing) {
    return (
      <EditableSelect
        value={tempValue}
        options={billingTypeOptions}
        onChange={handleChange}
      />
    )
  }

  return (
    <div onClick={handleEdit} style={{ cursor: 'text' }}>
      {getName(billingTypeContainer, tempValue) || <PlaceholderValue />}
      {isSaving && <ButtonSpinner />}
    </div>
  )
}

export default BillingTypeCell;