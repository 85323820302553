/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import ShipmentsTableComponent from './ShipmentsTableComponent';
import { BILLING_SHIPMENT_TABLE_HEADERS } from './constants/shipmentTable.constants';
import { ShipmentListView } from '../../../redux/models/shipment.models';
import { loadUnassignedShipments, selectShipmentFilterParams } from '../../../redux/slices/shipment-list/shipment-list';
import { selectIsSettingsLoaded, selectShipmentBoardSettings } from '../../../redux/slices/settings';
import ButtonSpinner from '../../../components/shared/ButtonSpinner';
import Permission from '../../../components/shared/permissions/Permission';
import AsyncButton from '../../../components/shared/buttons/AsyncButton';
import { TripWritePermissions } from '../../../components/shared/permissions/permissions.utils';
import { createTripFromShipments } from '../../../redux/slices/trips/trips';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import { formatShipments } from './utils/shipmentTable.utils';
import Analytics from '../../../utils/analytics';
import PaginatedButton from '../../../components/shared/buttons/PaginatedButton';

const NO_SHIPMENTS_MESSAGE = 'No shipments found';
const PAGE_SIZE = 10;

export default function UnassignedShipmentsTable() {
  const navigate = useNavigate();
  const boardSettings = useSelector(selectShipmentBoardSettings);
  const isBoardSettings = useSelector(selectIsSettingsLoaded);
  const filters = useSelector(selectShipmentFilterParams);
  const { orgCode } = useParams();
  const [shipments, setShipments] = useState<ShipmentListView[]>([]);
  const [page, setPage] = useState(0);
  const [totalShipments, setTotalShipments] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [confirmTrip, setConfirmTrip] = useState(false);
  const [isCreatingTrip, setIsCreatingTrip] = useState(false);
  const [selectedShipments, setSelectedShipments] = useState<string[]>([]);
  const headers = BILLING_SHIPMENT_TABLE_HEADERS;
  const title = isExpanded && !isLoading ? `Unassigned (${totalShipments})` : 'Unassigned';

  const openShipmentPage = (id: string) => navigate(`/${orgCode}/shipments/${id}`);
  const handleAddTrip = async () => {
    try {
      setIsCreatingTrip(true);
      const trip = await createTripFromShipments(selectedShipments);
      if (trip.entity_id) {
        Analytics.createTripFromShipments(orgCode);
        navigate(`/${orgCode}/trips/${trip.entity_id}`);
      }
    } catch (error) {
      const errorMessage = `Couldn't create trip from shipments. Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    } finally {
      setIsCreatingTrip(false);
    }
  };

  const handleConfirmTrip = () => setConfirmTrip(true);

  const selectShipment = (e: React.ChangeEvent<HTMLInputElement>, shipmentId: string) => {
    e.stopPropagation();
    if (selectedShipments.includes(shipmentId)) {
      return setSelectedShipments(selectedShipments.filter((id: string) => id !== shipmentId));
    }
    return setSelectedShipments([...selectedShipments, shipmentId]);
  };

  const handleLoadShipments = async () => {
    const response = await loadUnassignedShipments(filters, page, PAGE_SIZE);
    if (response.status === 200) {
      const { addresses, contacts, billingTypes } = boardSettings;
      const data: any = formatShipments(response.data.data.items, contacts, billingTypes, addresses);
      setShipments((prev) => [...prev, ...data]);
      setPage(page + 1);
      setTotalShipments(response.data.data.total_count ?? 0);
    }
  };

  const handleExpanded = async () => {
    const expanded = isExpanded;
    await setIsExpanded(!expanded);
    if (expanded) {
      setPage(0);
      setShipments([]);
    }
    if (!expanded && isBoardSettings) {
      setIsLoading(true);
      await handleLoadShipments();
      setIsLoading(false);
    }
  };

  const loadNextPage = async () => {
    setIsLoadingNextPage(true);
    await handleLoadShipments();
    setIsLoadingNextPage(false);
  };

  return (
    <Accordion activeKey={isExpanded ? '0' : ''}>
      <Card>
        <Accordion.Item eventKey="0">
          <Card.Header className="group-card-header">
            <Card.Title className="group-card-title">
              {title}
            </Card.Title>
            <div
              className="custom-collapse-icon-wrapper"
              onClick={handleExpanded}
            >
              <FontAwesomeIcon
                width={25}
                height={25}
                icon={faChevronDown}
                className="custom-collapse-icon"
              />
            </div>
          </Card.Header>
          <Accordion.Body style={{ background: 'white', padding: 0 }}>
            <Card.Body>
              {isLoading && <ButtonSpinner />}
              {!isLoading && (
                <Permission resources={TripWritePermissions}>
                  <div className="mb-3">
                    <AsyncButton
                      title="Create Trip"
                      spinner="primary"
                      disabled={!selectedShipments.length}
                      variant="outline-primary"
                      handleClick={handleConfirmTrip}
                    />
                  </div>
                </Permission>
              )}
              {!isLoading && (
                <ShipmentsTableComponent
                  areTripsLoading={isLoading}
                  headers={headers}
                  noShipmentsMessage={NO_SHIPMENTS_MESSAGE}
                  formattedShipments={shipments}
                  openShipmentPage={openShipmentPage}
                  selectShipment={selectShipment}
                  selectedShipments={selectedShipments}
                />
              )}
              {!isLoading && (
                <PaginatedButton
                  isLoading={isLoadingNextPage}
                  total={totalShipments || 0}
                  page={page}
                  pageSize={PAGE_SIZE}
                  dataCy="load_more_unassigned_shipments_btn"
                  variant="outline-secondary"
                  spinner="secondary"
                  handleClick={loadNextPage}
                />
              )}
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
      {confirmTrip && (
        <ConfirmModal
          title="Would you like to create a trip from these shipments?"
          details=""
          btnTitle="Create"
          saveDisabled={isCreatingTrip}
          shouldShowModal={confirmTrip}
          isSaving={isCreatingTrip}
          handleSave={handleAddTrip}
          cancelSave={() => setConfirmTrip(false)}
          />
      )}
    </Accordion>
  );
}
