import React from 'react';

import SidebarNavSection from './SidebarNavSection';
import { SidebarItem } from './sidebar.models';

interface Props {
  items: SidebarItem[];
}

function SidebarNav({ items }: Props) {
  return (
    <ul className="sidebar-nav">
      {items && items.map((item) => (
        <SidebarNavSection
          key={item.title}
          item={item}
        />
      ))}
    </ul>
  );
}

export default SidebarNav;
