import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EntityList from '../base/list/EntityList';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import { Api } from '../../../services/services';
import { BillingItem } from '../../../redux/models/billing.models';
import BillingItemModal from './BillingItemModal';
import BreadCrumbs, { BreadCrumbRoute } from '../../shared/breadcrumbs/BreadCrumbs';

const getRoutes = (orgCode: string | undefined): BreadCrumbRoute[] => {
  const routes = [
    {
      name: 'Settings',
      route: `/${orgCode || ''}/settings`,
    },
    {
      name: 'Rates',
      route: `/${orgCode}/settings/rates`,
    },
  ];
  return routes;
};

export default function BillingItemsList() {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const [showAdd, setShowAdd] = useState(false);
  const handleGetName = (rate: BillingItem) => rate.data.description || '';
  const createRoute = (rate: BillingItem) => `/${orgCode}/settings/rates/${rate.entity_id}`;
  const handleAdd = () => {
    setShowAdd(true);
  };
  const handleUpdate = (rate: BillingItem) => {
    const route = createRoute(rate);
    navigate(route);
  };
  return (
    <>
      <BreadCrumbs routes={getRoutes(orgCode)} />
      <EntityList
        title="Rates"
        settingsKey={SETTINGS_NAMES.BILLING_ITEMS}
        api={Api.BillingItems}
        nameKey="description"
        allowAdd
        onAdd={handleAdd}
        onGetName={handleGetName}
        onCreateRoute={createRoute}
      >
        {showAdd && (
          <BillingItemModal
            show={showAdd}
            setShow={setShowAdd}
            onUpdate={handleUpdate}
          />
        )}
      </EntityList>
    </>
  );
}
