import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteModal from '../../../components/modals/DeleteModal';
import MessageModal from '../../../components/modals/MessageModal';
import { deleteInvoiceShipment, setAreInvoiceDetailsLoading } from '../../../redux/slices/invoices';
import ShipmentCard from '../../trips/TripShipments/ShipmentCard';
import { Shipment } from '../../../redux/models/shipment.models';
import Analytics from '../../../utils/analytics';

interface Props {
  page: string;
  shipments: Shipment[];
  isEditable: boolean;
}

export default function InvoiceShipments({ page, shipments, isEditable }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleRemoveShipment = async (shipmentId: string | null) => {
    if (!shipmentId) return;
    dispatch(setAreInvoiceDetailsLoading(true));
    try {
      setIsDeleting(true);
      await deleteInvoiceShipment(shipmentId);
      const successMessage = 'Invoice was updated successfully.';
      toast(successMessage, { type: 'success' });
      navigate(-1);
    } catch (e) {
      if (e instanceof Error) {
        const errorMessage = `Couldn't remove shipment. ${e.message} Please contact support if the problem persists.`;
        toast(errorMessage, { type: 'error' });
      }
      Analytics.capture(e);
    } finally {
      dispatch(setAreInvoiceDetailsLoading(false));
      setIsDeleting(false);
    }
  };
  const handleDeleteModal = (shipmentId: string) => {
    setShouldShowDeleteModal(true);
    setSelectedShipment(shipmentId);
  };

  const handleMessageModal = () => setShowMessageModal(true);

  const handleModals = (shipmentId: string) => {
    if (shipments.length === 1) {
      return handleMessageModal();
    }
    return (handleDeleteModal(shipmentId));
  };

  return (
    <>
      <Row className="shipment-card-container">
        {shipments.length && shipments.map((shipment, index) => (
          <Col
            md={4}
            key={shipment.entity_id}
          >
            <ShipmentCard
              page={page}
              shipment={shipment}
              isEditable={isEditable}
              onRemoveShipment={handleModals}
              dataCy={`${page}_shipmentCard_${index}`}
            />
          </Col>
        ))}
      </Row>
      <MessageModal
        title="Cannot remove shipment"
        page={page}
        description="Void the invoice to include this shipment in another invoice"
        showMessageModal={showMessageModal}
        closeMessage={() => setShowMessageModal(false)}
      />
      {isEditable && (
        <DeleteModal
          title="Remove shipment from invoice?"
          description="Are you sure you want to remove this shipment?"
          shouldShowDeleteModal={shouldShowDeleteModal}
          deleteBtnText="Remove"
          isDeleting={isDeleting}
          deleteItem={() => handleRemoveShipment(selectedShipment)}
          cancelDeletion={() => setShouldShowDeleteModal(false)}
        />
      )}
    </>
  );
}
