/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Stack, Badge, Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DropdownOptionsModel } from '../../../../components/shared/SelectInput.models';
import { DriverAvailability } from '../../../../redux/models/driver.availability.models';
import { DateService } from '../../../../utils/dateService';
import { TripBoardModel } from '../../../../redux/models/trip.models';
import { Driver } from '../../../../redux/models/settings.models';
import { TripPlan } from '../../../../redux/slices/planner/planner.models';
import CrapTable from '../components/table/CrapTable';
import { formatDriverName } from '../../TripTable/utils/tripTable.utils';
import DriverInfo from '../DriverInfo';
import { getAssignedTimes } from '../driver.utils';
import { sortTripByTBDDate } from '../../constants/boards.constants';

interface Props {
  plan: TripPlan;
  availability: DriverAvailability[];
  driver: Driver;
  drivers: DropdownOptionsModel[];
  isOver?: boolean;
  shouldExpandAllItems: boolean;
  trips: TripBoardModel[];
  yard: string;
  handleHideDriver: (driverId: string, checked: boolean) => void;
}

function DriverSection({
  availability, driver, drivers, shouldExpandAllItems, trips, yard, plan, isOver, handleHideDriver
}: Props) {
  const navigate = useNavigate();
  const { id, orgCode } = useParams();
  const driverName = formatDriverName(driver);
  const outSideCartage = driver.data.outside_cartage || false;
  const isTrips = trips.length > 0;
  const numTrips = trips.length === 1 ? ` ${trips.length} Trip` : ` ${trips.length} Trips`;
  const [isExpanded, setIsItemExpanded] = useState(shouldExpandAllItems);
  const data = trips.sort((a, b) => sortTripByTBDDate(a, b));
  const { start, finish } = getAssignedTimes(data);
  const activeStyles: any = {};
  if (isOver) {
    activeStyles.background = '#F1F5F9';
  }
  const handleOpenHistory = () => {
    navigate(`/${orgCode}/boards/${id}/planner/driver/${driver.entity_id}`);
  };
  useEffect(() => setIsItemExpanded(shouldExpandAllItems), [shouldExpandAllItems]);
  return (
    <Stack style={activeStyles} className="rounded">
      <Button type="button" variant="link" onClick={() => setIsItemExpanded(!isExpanded)}>
        <Stack direction="horizontal" gap={2}>
          <DriverInfo
            availability={availability}
            driver={driver}
            start={start}
            finish={finish}
            numTrips={trips.length}
            yard={yard}
          />
          {outSideCartage && <Badge bg="secondary" className="mb-2">Outside Cartage</Badge>}
          <Button variant="link" className="pb-3" onClick={handleOpenHistory}>History</Button>
          
          {!isExpanded && isTrips && <p className="mt-2 px-2">{numTrips}</p>}
          <FontAwesomeIcon
            width={25}
            icon={isExpanded ? faChevronDown : faChevronRight}
            className="custom-collapse-icon mb-2"
          />
        </Stack>
      </Button>
      <div className="px-2">
        {isExpanded && (
          <CrapTable
            driverId={driver.entity_id}
            plan={plan}
            trips={trips}
            drivers={drivers}
            showHeader={false}
          />
        )}
      </div>
    </Stack>
  );
}

DriverSection.defaultProps = {
  isOver: false,
};

export default DriverSection;