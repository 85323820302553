import Form from 'react-bootstrap/Form';
import { Stack } from 'react-bootstrap';
import { DropdownOption } from './dropdown.models';

interface Props {
  selectText: string;
  selectedId: string;
  options: DropdownOption[];
  showSelect?: boolean;
  handleSelect: (optionId: string) => void;
}

function SelectOptions({ selectedId, selectText, options = [], showSelect, handleSelect }: Props) {
  return (
    <Stack>
      {/* <Form.Label htmlFor="groupBy">Group By</Form.Label> */}
      <Form.Select
        aria-label="Group shipment by:"
        value={selectedId}
        name="groupBy"
        id="groupBy"
        onChange={(e) => handleSelect(e.target.value)}
      >
        {showSelect && <option value="">{selectText}</option>}
        {options.map((option) => (
          <option key={option.id} value={option.id}>{option.value}</option>
        ))}
      </Form.Select>
    </Stack>
  );
}

SelectOptions.defaultProps = {
  showSelect: true,
};

export default SelectOptions;
