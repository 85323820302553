import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AsyncDispatchNotificationResponse } from '../../../redux/slices/dispatch/dispatch.models';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { setSelectedRequestId } from '../../../redux/slices/notifications/notifications';
import { AsyncState } from '../../../services/notifications/Notification.models';
import TripDispatchAlert from './TripDispatchAlert';

const CLOSE = 'Close';
const TITLE = 'Completed Trip Dispatch';

interface Props {
  dispatch: AsyncDispatchNotificationResponse;
}

export default function AsyncDispatchMessageModal({ dispatch }: Props) {
  const appDispatch = useAppDispatch();
  const queued = Object.values(dispatch.queued) || [];
  const successes = queued.filter((tripDispatch) => tripDispatch.state === AsyncState.SUCCESS);
  const errors = queued.filter((tripDispatch) => tripDispatch.state === AsyncState.ERROR);
  const errorMessage = errors.length > 0 ? `${errors.length} errors dispatching trips` : '';
  const message =
    `${successes.length} trips successfully dispatched. ${errorMessage}`;
  const handleClose = () => {
    appDispatch(setSelectedRequestId(null));
  };
  return (
    <Modal
      show
      onHide={handleClose}
      centered
      data-cy="AsyncDispatchMessageModal_message_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{TITLE}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center m-3">
        <p className="mb-0">{message}</p>
        {queued.map((tripDispatch) => {
          return <TripDispatchAlert key={tripDispatch.trip_id} dispatch={tripDispatch} />
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button id="message_modal_close_button" data-cy="message-close-btn" variant="secondary" onClick={handleClose}>
          {CLOSE}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
