/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import { AquadashServiceApiProvider } from '../../../services/AquadashServiceApiProvider';
import { selectTripsFilterParams } from '../../../redux/slices/trips/trips';
import { createBoardShipment, createBoardTrip } from '../../../redux/slices/shipments';
import { selectBoard, selectTemplates } from '../../../redux/slices/settings';
import {
  boardShipment, boardTrip, createTripFromTemplateAsyncParams, createTripFromTemplateParams
} from '../constants/boards.constants';
import Analytics from '../../../utils/analytics';
import { NoteTemplate, TripTemplate } from '../../../redux/models/settings.models';
import { AppState } from '../../../redux/models/state.models';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { resetShipments } from '../../../redux/slices/shipment-list/shipment-list';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import useAppSettings from '../../../hooks/useAppSettings';
import { Api } from '../../../services/services';
import { infoToast } from '../../../components/notifications/app-toast';

const NEW_TRIP = 'Add Trip';
const NEW_TRIP_SHIPMENT = 'Add Trip & Shipment';
const DISPATCH_TRIPS = 'Dispatch Trips';

interface Props {
  groupId: string;
  templateIds: string[];
  noteTemplates: NoteTemplate[];
  setConfirmDispatchOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsCreatingTrip: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TemplatesMenu({
  groupId, templateIds = [], noteTemplates, setConfirmDispatchOpen, setIsCreatingTrip
}: Props) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const { appTz } = useAppSettings();
  const { async_dispatch: asyncDispatch, async_templates: asyncTemplates } = useFeatureFlags();
  const { id, orgCode } = useParams();
  const templates: TripTemplate[] = useSelector(selectTemplates);
  const groupTemplates = templates.filter((tmp) => templateIds.includes(tmp.entity_id));
  const selectedDate = useSelector(selectTripsFilterParams).dateFrom;
  const board = useSelector((state: AppState) => selectBoard(state, id));
  const noteTemplate = noteTemplates && noteTemplates.length > 0 ? noteTemplates[0] : null;
  const noteTemplateId = noteTemplate?.entity_id;
  const defaultNotes = noteTemplate?.data.notes || [];
  const isAsyncDispatchEnabled = asyncDispatch?.is_enabled || false;
  const isAsyncTemplatesEnabled = asyncTemplates?.is_enabled || false;
  const boardId = board?.entity_id || '';

  const openShipmentPage = (shipmentId: string) => {
    dispatch(resetShipments());
    navigate(`/${orgCode}/shipments/${shipmentId}`);
  }
  const openTripPage = (tripId: string) => {
    dispatch(resetShipments());
    navigate(`/${orgCode}/trips/${tripId}`);
  }

  const onDefaultTemplateClick = async () => {
    setIsCreatingTrip(true);
    try {
      const defaultData: any = boardShipment(
        selectedDate,
        boardId,
        groupId,
        '',
        [],
        noteTemplateId || '',
        defaultNotes,
      );
      const shipment = await createBoardShipment(defaultData, orgCode);
      if (shipment.entity_id) {
        openShipmentPage(shipment.entity_id);
      }
    } catch (e) {
      if (e instanceof Error) {
        const errorMessage = `Couldn't add trip. ${e.message}. Please contact support if the problem persists.`;
        toast(errorMessage, { type: 'error' });
      }
    } finally {
      setIsCreatingTrip(false);
      Analytics.createShipment(orgCode);
    }
  };

  const onAddTrip = async () => {
    setIsCreatingTrip(true);
    try {
      const defaultData: any = boardTrip(selectedDate, boardId, groupId, '', [], defaultNotes);
      const trip = await createBoardTrip(defaultData, orgCode);
      if (trip.entity_id) {
        openTripPage(trip.entity_id);
      }
    } catch (e) {
      if (e instanceof Error) {
        const errorMessage = `Couldn't add trip. ${e.message}. Please contact support if the problem persists.`;
        toast(errorMessage, { type: 'error' });
      }
    } finally {
      setIsCreatingTrip(false);
      Analytics.createShipment(orgCode);
    }
  };

  const onAsyncTemplateClick = async (templateId: string, name: string) => {
    setIsCreatingTrip(true);
    try {
      const notes: Array<any> = defaultNotes;
      const params = createTripFromTemplateAsyncParams(selectedDate, notes, appTz, boardId);
      const response = await Api.Trips.createTripAsync(templateId, params);
      if (response.status === 200) {
        const message = `Creating trips from ${name} template. A notification will appear when it's ready. Feel free to continue using the app.`;
        infoToast(message);
      }
    } catch (e: any) {
      Analytics.capture(e);
      toast(`Error creating trip from ${name} template.`, { type: 'error' });
    } finally {
      setIsCreatingTrip(false);
      Analytics.createTemplatedTrip(orgCode);
    }
  };
  const onTemplateClick = async (templateId: string) => {
    setIsCreatingTrip(true);
    try {
      const notes: Array<any> = defaultNotes;
      const params = createTripFromTemplateParams(selectedDate, noteTemplateId || '', notes, appTz);
      const { data } = await AquadashServiceApiProvider
        .createTripFromTemplate(templateId, params);
      const { entity_id: shipmentId } = data.data;
      openShipmentPage(shipmentId);
    } catch (e: any) {
      Analytics.capture(e);
      toast('Error creating trip from template.', { type: 'error' });
    } finally {
      setIsCreatingTrip(false);
      Analytics.createTemplatedTrip(orgCode);
    }
  };
  const handleTemplateClick = async (templateId: string, name: string) => {
    if (isAsyncTemplatesEnabled) {
      onAsyncTemplateClick(templateId, name);
    } else {
      onTemplateClick(templateId)
    }
  };
  const onConfirmDispatch = () => setConfirmDispatchOpen(true);
  return (
    <DropdownButton
      as={ButtonGroup}
      id="dropdown-button-drop"
      size="sm"
      variant="light"
      title="+"
      data-cy="board_group_menu"
    >
      {groupTemplates.map((template) => {
        return (
          <Dropdown.Item
            key={template.entity_id}
            onClick={() => handleTemplateClick(template.entity_id, template.data.name)}
          >
            {template.data.name || ''}
          </Dropdown.Item>
        )
      })}
      {groupTemplates.length > 0 && <Dropdown.Divider />}
      <Dropdown.Item onClick={onAddTrip}>{NEW_TRIP}</Dropdown.Item>
      <Dropdown.Item onClick={onDefaultTemplateClick}>{NEW_TRIP_SHIPMENT}</Dropdown.Item>
      {isAsyncDispatchEnabled && <Dropdown.Divider />}
      {isAsyncDispatchEnabled && <Dropdown.Item onClick={onConfirmDispatch}>{DISPATCH_TRIPS}</Dropdown.Item>}
    </DropdownButton>
  );
}
