/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Badge, Container, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { ReeferSetting } from '../shipments/constants/shipments.constants';
import { capitalizeFirstLetter } from '../../utils/core.utils';
import { isReeferSettingLegacy } from '../shipments/utils/shipments.utils';

export function DangerousGoodsIcon() {
  const renderHoverTooltip = (props: any) => (
    <Tooltip {...props} bsPrefix="dangerous-goods-tooltip">
      <h6 className="header">This shipment contains dangerous goods.</h6>
      <h6 className="details-text">To remove this, go to the shipment details page.</h6>
    </Tooltip>
  );
  return (
    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderHoverTooltip}>
      <Container data-cy="dangerous_goods_icon" className="dangerous-goods-icon">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="8.48523" width="12" height="12" transform="rotate(-45 0 8.48523)" fill="#E5A54B" />
        </svg>
      </Container>
    </OverlayTrigger>
  );
}
// dry_icon
export function DryIcon() {
  const text = capitalizeFirstLetter(ReeferSetting.Dry);
  return (
    <Badge pill bg="primary" className="badge-space" data-cy="reefer_off_icon">{text}</Badge>
  );
}

const getTempColour = (setting: string | undefined) => {
  if (setting === ReeferSetting.On || setting === ReeferSetting.Continuous) return 'success';
  if (setting === ReeferSetting.Dry) return 'primary';
  return 'secondary';
};

const CONTINUOUS_LABEL = capitalizeFirstLetter(ReeferSetting.Continuous);

interface TempIconProps {
  setting: string | undefined;
  temp: string | number | undefined;
  dry: boolean;
}

export function TempIcon({ setting, temp, dry }: TempIconProps) {
  if (isReeferSettingLegacy(setting, dry)) return <DryIcon />;
  if (setting === ReeferSetting.Dry) return <DryIcon />;
  if (setting === ReeferSetting.Off) {
    return (
      <Badge
        bg={getTempColour(setting)}
        className="badge-space"
        data-cy={`temp_icon_${setting}`}
      >
        {capitalizeFirstLetter(ReeferSetting.Off)}
      </Badge>
    ) 
  }
  const isContinous = setting === ReeferSetting.Continuous;
  const isUndef = temp === undefined || temp === 'undefined';
  const label = isUndef ? `${capitalizeFirstLetter(setting)}` : `${temp}`;
  const text = isContinous ? `${temp} ${CONTINUOUS_LABEL}` : `${label}`;
  return (
    <Badge
      bg={getTempColour(setting)}
      className="badge-space"
    >
      {text}
    </Badge>
  );
}
