/* eslint-disable */
import React, { useState } from 'react'
import ButtonSpinner from '../../../../../components/shared/ButtonSpinner';
import EditableSelect from '../../../../../components/data-grid/base/EditableSelect';
import { renderShipmentStatus } from '../../../ShipmentsTable/ShipmentsTableRow';
import { SHIPMENT_STATUS_TYPES } from '../../../constants/shipments.constants'

const OPTIONS = Object.values(SHIPMENT_STATUS_TYPES);

const ShipmentStatusCell: React.FC<{ status: string; onChange: (value: string) => void }> = ({ status, onChange }) => {
  const [editing, setEditing] = useState(false)
  const [tempValue, setTempValue] = useState(status)
  const [isSaving, setSaving] = useState(false);
  const name = tempValue;
  const handleEdit = () => {
    setEditing(true);
  };

  const handleChange = async (newCustomerId: string) => {
    setTempValue(newCustomerId);
    setSaving(true);
    setEditing(false);
    await onChange(newCustomerId);
    setSaving(false);
  };

  if (editing) {
    return (
      <EditableSelect
        value={tempValue}
        options={OPTIONS}
        onChange={handleChange}
      />
    )
  }

  return (
    <div onClick={handleEdit} style={{ cursor: 'text' }}>
      {renderShipmentStatus(status)}
      {isSaving && <ButtonSpinner />}
    </div>
  )
}

export default ShipmentStatusCell;