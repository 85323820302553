import React, { useEffect } from 'react';
import {
  FuelSurchargeTypeReadPermissions, FuelSurchargeTypeWritePermissions
} from '../../shared/permissions/permissions.utils';
import { getSelectedOptions } from '../../../utils/core.utils';
import { DropdownOptionsModel } from '../../shared/SelectInput.models';
import EntityInput from '../base/inputs/EntityInput';
import { Api } from '../../../services/services';
import { FuelSurchargeTypeData } from '../../../redux/models/settings.models';
import { DataModel } from '../../../redux/models/core.models';

interface Props {
  value: string;
  page: string;
  disabled: boolean;
  prop: string;
  label?: string;
  options: DropdownOptionsModel[];
  onChange: (prop: string, value: string) => void;
  onNewEntity: (prop: string, entity: DataModel<FuelSurchargeTypeData>) => void;
}

function FuelSurchargeTypeInput({ value, page, prop, label, disabled, options, onChange, onNewEntity }: Props) {
  const handleCreateData = (_: string, val: string): FuelSurchargeTypeData => {
    return {
      name: val,
    };
  };
  useEffect(() => {
    console.log('render FSC input');
  }, []);
  return (
    <EntityInput
      disabled={disabled}
      labelText={label || 'Fuel Surcharge'}
      name={prop}
      page={page}
      value={value}
      options={getSelectedOptions(value, options)}
      entity="fuel-surcharge-type"
      dataField="name"
      readResources={FuelSurchargeTypeReadPermissions}
      writeResources={FuelSurchargeTypeWritePermissions}
      api={Api.FuelSurchargeTypes}
      defaultAdd
      allowAdd
      settingName="fuel-surcharge-type"
      syncSettings={false}
      onChange={onChange}
      createData={handleCreateData}
      onNewEntity={onNewEntity}
    />
  );
}

FuelSurchargeTypeInput.defaultProps = {
  label: 'Fuel Surcharge',
}

export default FuelSurchargeTypeInput;
