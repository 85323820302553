import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Package } from "react-feather";
import { Shipment } from "../../../../redux/models/shipment.models"
import { DateService } from "../../../../utils/dateService";
import {
  selectAddressesObject, selectBillingTypeObject, selectContactsObject
} from "../../../../redux/slices/settings";

interface Props {
  shipment: Shipment;
}

export default function ShipmentFeedItem({ shipment }: Props) {
  const { orgCode } = useParams();
  const addresses = useSelector(selectAddressesObject);
  const customers = useSelector(selectContactsObject);
  const billingTypes = useSelector(selectBillingTypeObject);
  const customerId = shipment.data.customer_id || '';
  const customer = customers[customerId]?.data.name || 'No customer set';
  const createdAt = shipment.created_at;
  const originId = shipment.data.origin_id || '';
  const destinationId = shipment.data.destination_id || '';
  const billingTypeId = shipment.data.billing_type_id || '';
  const origin = addresses[originId]?.data.samsara_name || 'No origin set';
  const destination = addresses[destinationId]?.data.samsara_name || 'no destination set';
  const billingType = billingTypes[billingTypeId]?.data.name || '';
  const route = originId === '' && destinationId === ''
    ? 'No origin or destination set' : `${origin} to ${destination}`;
  const pickupStr = shipment.data.requested_pick_up_date;
  const deliveryStr = shipment.data.requested_drop_off_date;
  const pickup = pickupStr ? `Requested Pick Up ${DateService.getDoMMMHHmmFormat(pickupStr)}` : '';
  const delivery = deliveryStr ? `Requested Drop Off ${DateService.getDoMMMHHmmFormat(deliveryStr)}` : '';
  let request = 'No requested pick up or delivery time';
  if (pickup !== '') request = pickup;
  if (delivery !== '') request = delivery;
  const timeAgo = DateService.toRelativeTime(createdAt);
  return (
    <div key={shipment.entity_id}>
      <div className="d-flex">
        <div className="stat me-2">
          <Package className="align-middle" />
        </div>
        <div className="flex-grow-1">
          <small className="float-end text-navy">{timeAgo}</small>
          <Link to={`/${orgCode}/shipments/${shipment.entity_id}`}>
            <strong>{customer}</strong>
          </Link>
          <br />
          {`${billingType} ${route}`}
          <br />
          <small className="text-muted">{request}</small>
          <br />
        </div>
      </div>
      <hr />
    </div>
  )
}