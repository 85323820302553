import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Stack, Table } from 'react-bootstrap';
import { TripDetails } from '../../../../redux/models/trip.models';
import {
  selectAddressesObject, selectCostsObject, selectDriversObject, selectUomObject, selectVehiclesObject
} from '../../../../redux/slices/settings';
import { ExperimentalBadge } from '../../../../components/badge/BetaBadge';
import {
  CostUnit, getTripCost, loadDefaultFuelRate, saveDefaultFuelRate
} from '../../../../components/costs/cost.utils';

function CostCardRow({ cost }: { cost: CostUnit }) {
  return (
    <tr>
      <td>{cost.name || ''}</td>
      <td>{cost.qty || ''}</td>
      <td>{cost.unit || ''}</td>
      <td>{`$${cost.rate.toFixed(2)}`}</td>
      <td>{`$${cost.total.toFixed(2)}`}</td>
    </tr>
  )
}

interface Props {
  trip: TripDetails;
  fuelRate: number;
  setFuelRate: React.Dispatch<React.SetStateAction<number>>;
}

export default function CostCard({ trip, fuelRate, setFuelRate }: Props) {
  const drivers = useSelector(selectDriversObject);
  const vehicles = useSelector(selectVehiclesObject)
  const addresses = useSelector(selectAddressesObject);
  const costs = useSelector(selectCostsObject);
  const uoms = useSelector(selectUomObject);
  const moves = trip.data.moves || [];
  const sorted = [...moves].sort((a, b) => {
    const aPos = a.data.position || 0;
    const bPos = b.data.position || 0;
    return aPos - bPos;
  })
  const cost = getTripCost(
    trip,
    sorted,
    drivers,
    vehicles,
    addresses,
    costs,
    uoms,
    fuelRate,
  );
  const handleFuelRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRate = Number(e.target.value);
    setFuelRate(newRate);
  };
  const storeFuelRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRate = Number(e.target.value);
    saveDefaultFuelRate(newRate);
  };
  return (
    <>
      <Stack direction="horizontal" gap={2}>
        <h4>Projected Costs</h4>
        <ExperimentalBadge />
      </Stack>
      <Card>
        <Card.Body>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {cost.driver && <CostCardRow cost={cost.driver} />}
              {cost.vehicle && <CostCardRow cost={cost.vehicle} />}
              {cost.burn && (
                <tr>
                  <td>{`${cost.burn.name} Fuel Burn (Estimated)`}</td>
                  <td>{cost.burn.qty.toFixed(2)}</td>
                  <td>{cost.burn.unit}</td>
                  <td>
                    $<input
                      value={fuelRate}
                      type="number"
                      min={0}
                      step={0.05}
                      placeholder={`${loadDefaultFuelRate()}`}
                      onChange={handleFuelRate}
                      onBlur={storeFuelRate}
                    /> {' / L'}
                  </td>
                  <td>{`$${cost.burn.total.toFixed(2)}`}</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{`$${(cost.total).toFixed(2)}`}</td>
              </tr>
            </tfoot>
          </Table>
        </Card.Body>
      </Card>
    </>
  )
}
