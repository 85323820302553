import moment from 'moment-timezone';
import { EntityContainer } from '../redux/models/core.models';
import { Document } from '../redux/models/file.models';

interface DownloadCache {
  timestamp: number;
  expiry: number;
  document: Document;
}

export default class FileCache {
  static cache: EntityContainer<DownloadCache> = {};

  static add(document: Document) {
    const key = document.name;
    const now = moment().unix();
    FileCache.cache[key] = {
      timestamp: now,
      expiry: now + 1800,
      document,
    };
  }

  static get(key: string) {
    return FileCache.cache[key].document;
  }

  static exists(key: string) {
    const now = moment().unix();
    const file = FileCache.cache[key];
    if (file) {
      return now < file.expiry;
    }
    return file !== undefined && file !== null;
  }
}
