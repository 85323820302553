import React from 'react';
import { Modal, Table, Button } from 'react-bootstrap';
import { INTEGRATIONS_FERRY_TABLE_HEADERS } from './integrations.constant';
import { FerryEmail } from '../../redux/models/integration.models';

const FERRY_EMAIL = 'Ferry Email';
const CLOSE = 'Close';

const getTrailers = (trailers: string[]) => {
  return trailers.reduce((store, trailer) => {
    if (store === '') return trailer;
    return `${store}, ${trailer}`;
  }, '');
};

const getTemps = (temps: number[]) => {
  return temps.reduce((store, temp) => {
    if (store === '') return `${temp}`;
    return `${store}, ${temp}`;
  }, '');
};

interface Props {
  shouldShowIntegrationsFerryModal: boolean;
  setShouldShowIntegrationsFerryModal: (show: boolean) => void;
  ferryEmailResponse: FerryEmail;
}

export default function IntegrationsFerryModal({
  shouldShowIntegrationsFerryModal,
  setShouldShowIntegrationsFerryModal,
  ferryEmailResponse,
}: Props) {
  const ferrySailData = ferryEmailResponse?.sailings || [];

  const onClose = () => {
    setShouldShowIntegrationsFerryModal(false);
  };

  return (
    <div>
      <Modal show={shouldShowIntegrationsFerryModal} onHide={() => setShouldShowIntegrationsFerryModal(false)} centered>
        <Modal.Header closeButton>{FERRY_EMAIL}</Modal.Header>
        <Modal.Body className="text-center m-3">
          <Table id="ferry-modal-table">
            <thead>
              <tr>
                {INTEGRATIONS_FERRY_TABLE_HEADERS.map((header) => (
                  <th key={header.key}>{header.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {ferrySailData.map((item, index) => (
                <tr key={index}>
                  <td>{item.trailers ? getTrailers(item.trailers) : 'N/A'}</td>
                  <td>{item.origin ? item.origin : 'N/A'}</td>
                  <td>{item.eta ? item.eta : 'N/A'}</td>
                  <td>{item.destination ? item.destination : 'N/A'}</td>
                  <td>{item.temp ? getTemps(item.temp) : 'N/A'}</td>
                  <td>{item.sailing_time ? item.sailing_time : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            {CLOSE}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
