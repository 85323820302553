export interface CreateTemplateAsyncResponse {
  author: string;
  board_id: string;
  date: string;
  request_id: string;
  state: string;
  trip_template_id: string;
  tz_offset: string;
}

export interface NotificationPollRequest {
  requestId: string;
  message: string;
}

export enum AsyncState {
  QUEUED = 'queued',
  SUCCESS = 'success',
  ERROR = 'error',
}
