import React from 'react';
import { Stack } from 'react-bootstrap';

import { Place } from './data';
import Map from '../../map/Map';

interface Props {
  place: Place;
}

export default function PlaceDetails({ place }: Props) {
  const openDays = place.currentOpeningHours?.weekdayDescriptions || [];
  return (
    <Stack>
      <Map lat={place.location.latitude} lng={place.location.longitude} title={place.displayName.text} />
      <h5 className="mt-2">{place.displayName.text}</h5>
      {openDays.map((day) => <p key={day}>{day}</p>)}
      <a href={place.googleMapsUri} target="_blank" rel="noreferrer">Open in Maps</a>
    </Stack>
  )
}
