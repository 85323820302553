/* eslint-disable import/no-cycle */
import { TypeOptions } from 'react-toastify';
import { multiDispatchToast } from '../../../components/notifications/app-toast';
import { AsyncState } from '../../../services/notifications/Notification.models';
import { EntityContainer } from '../../models/core.models';
import { AsyncDispatchNotification, AsyncDispatchNotificationResponse } from '../dispatch/dispatch.models';
import { AppNotificationData, NotificationState } from './notifications.models';

const initialState: NotificationState = {
  isLoaded: false,
  isLoading: false,
  unreadCount: 0,
  selectedRequestId: '1',
  notifications: [],
  appNotifications: [],
  multiDispatches: {},
};

export const getInitialState = (): NotificationState => ({
  ...initialState
});

export const mapNotificationToDispatch = (notification: AppNotificationData): AsyncDispatchNotification => {
  return {
    message: notification.title || '',
    request_id: notification.request_id || '',
    state: notification.state || '',
    trip_id: notification.entity_id || '',
    created_at: notification.created_at,
  }
}

export const getDispatch = (
  state: EntityContainer<AsyncDispatchNotificationResponse>, notification: AppNotificationData
) => {
  const requestId = notification.request_id || '';
  const tripId = notification.entity_id || '';
  const dispatches = state[requestId]?.queued || {};
  return dispatches[tripId];
};

export const updateDispatchNotificationState = (
  state: EntityContainer<AsyncDispatchNotificationResponse>,
  notification: AppNotificationData,
): EntityContainer<AsyncDispatchNotificationResponse> => {
  const requestId = notification.request_id || '';
  const tripId = notification.entity_id || '';
  const requestState = state[requestId] || {};
  const dispatches = state[requestId]?.queued || {};
  const tripDispatch = mapNotificationToDispatch(notification);
  return {
    ...state,
    [requestId]: {
      ...requestState,
      request_id: requestId,
      queued: {
        ...dispatches,
        [tripId]: tripDispatch,
      },
    },
  };
};

export const removeMultiTripDispatchFromState = (
  state: EntityContainer<AsyncDispatchNotificationResponse>,
  requestId: string,
): EntityContainer<AsyncDispatchNotificationResponse> => {
  const updated = {
    ...state,
  };
  delete updated[requestId];
  return {
    ...updated,
  };
};

export const isMultiDispatchComplete = (dispatch: AsyncDispatchNotificationResponse) => {
  const queued = Object.values(dispatch.queued) || [];
  const readyQ = queued.filter((tripDispatch) => tripDispatch.state !== AsyncState.QUEUED);
  return readyQ.length > 0;
};

export const showDispatchRequestNotification = (
  dispatch: AsyncDispatchNotificationResponse
) => {
  console.log('showDispatchRequestNotification: ');
  const requestId = dispatch.request_id;
  const queued = Object.values(dispatch.queued) || [];
  const successes = queued.filter((tripDispatch) => tripDispatch.state === AsyncState.SUCCESS);
  const errors = queued.filter((tripDispatch) => tripDispatch.state === AsyncState.ERROR);
  const numErrors = errors.length || 0;
  const isErrors = numErrors > 0;
  const message = dispatch.message || `${queued.length} trip dispatches finished processing`;
  const type: TypeOptions = isErrors ? 'warning' : 'success';
  console.log(dispatch);
  console.log('successes: ');
  console.log(successes);
  console.log('errors: ', isErrors);
  console.log(errors);
  multiDispatchToast(
    message,
    requestId,
    type,
  );
};