import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';

import Loader from '../../../components/Loader';
import { selectAreTripsLoading, selectTripsFilterParams } from '../../../redux/slices/trips/trips';
import { TripTableColumns } from '../../../redux/models/board.models';
import { DateService } from '../../../utils/dateService';
import { SHIPMENT_TABLE_HEADERS } from '../TripTable/constants/tripTable.constants';
import TripsTableRow from '../TripTable/TripsTableRow';
import { TripBoardShipment } from '../../../redux/models/shipment.models';
import { renderShipmentStatus } from '../../shipments/ShipmentsTable/ShipmentsTableRow';
import { TripBoardModel } from '../../../redux/models/trip.models';

const NO_SHIPMENTS_MESSAGE = 'No shipments. Create a shipment to get started.';
const renderDate = (shipmentId: string, dates: Record<string, string[]>) => {
  const shipDates = dates[shipmentId] || [];
  if (shipDates.length > 0) {
    const firstTrip = [...shipDates].sort((a, b) => a.localeCompare(b))[0];
    return DateService.getYYYYMMDDFormat(firstTrip);
  }
  return '';
};
interface Props {
  shipments: TripBoardShipment[];
  title: string;
  headers: TripTableColumns[];
  trips: Record<string, TripBoardModel>;
  showDate: boolean;
  onOpen: (id: string) => void;
}

export default function ShipmentsTable({
  shipments,
  title,
  headers = SHIPMENT_TABLE_HEADERS,
  trips,
  showDate = false,
  onOpen,
}: Props) {
  const areTripsLoading = useSelector(selectAreTripsLoading);
  const { dateFrom, dateTo } = useSelector(selectTripsFilterParams);
  const isSameDay = DateService.isSame(dateFrom, dateTo);
  const shipmentDates = Object.values(trips).reduce((store: Record<string, string[]>, trip) => {
    const tripShips = trip.shipments || [];
    const tripDate = trip.startDate || '';
    const shipStore = tripShips.reduce((st: Record<string, string[]>, sh) => {
      const existing = store[sh.id] || [];
      return {
        ...st,
        [sh.id]: existing.length > 0 ? [...existing, tripDate] : [tripDate]
      }
    }, {});
    return {
      ...store,
      ...shipStore,
    }
  }, {});
  const openTripPage = (id: string) => {
    if (onOpen) onOpen(id);
  }
  const renderNoTripsMessage = () => (areTripsLoading ? <Loader /> : <div>{NO_SHIPMENTS_MESSAGE}</div>);
  return (
    shipments.length ? (
      <Table
        striped
        bordered
        hover
        className="trips-table"
        data-cy={`${title}_trips-table`}
      >
        <thead>
          <tr>
            {showDate && (
              <th>First Trip</th>
            )}
            {headers.map((header) => <th key={header.key}>{header.name}</th>)}
          </tr>
        </thead>
        <tbody>
          {shipments.map((trip, index) => (
            <tr
              key={trip.id}
              id={`boards-shipmentsTable__tableRow-${index}-${trip.id}`}
              className="trips-table-row"
              data-cy={`${title}shipments-table-row`}
              onClick={() => openTripPage(trip.id)}
            >
              {showDate && (
                <td>{renderDate(trip.id, shipmentDates)}</td>
              )}
              {headers.map((header) => {
                return (
                  <td
                    key={`${trip.id}-${header.key}`}
                    data-cy={`boards-shipmentsTable__tableRow-${index}-${header.key}`}
                    id={`boards-shipmentsTable__tableRow-${index}-${header.key}`}
                  >
                    <TripsTableRow trip={trip} prop={header.key} boardDate={dateFrom} isDateRange={!isSameDay} />
                    {header.key === 'status' && renderShipmentStatus(trip.shipment_status)}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    ) : renderNoTripsMessage()
  );
}
