import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import DeleteModal from '../../../components/modals/DeleteModal';
import { getSettingByName } from '../../../redux/slices/settings';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { BillingType } from '../../../redux/models/billing.models';
import { Api } from '../../../services/services';

interface Props {
  type: BillingType;
  onDelete: (template: BillingType) => void;
}

export default function BillingTypeRow({ type, onDelete }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const [showDelete, setShowDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const accessorialData = type.data.accessorials || [];
  const accessorials = accessorialData.reduce((store, rate) => {
    if (rate) {
      const name = rate?.data.description || '';
      if (store === '') return name;
      return `${store}, ${name}`;
    }
    return store;
  }, '');
  const handleDelete = async () => {
    setDeleting(true);
    try {
      const response = await Api.BillingTypes.delete(type);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.BILLING_TYPES, false));
        toast(`Success! Deleted ${type.data.name || 'Quote Template'}`, { type: 'success' });
        await setShowDelete(false);
        onDelete(type);
      } else {
        toast(`Couldn't delete ${type.data.name || 'Quote Template'}`, { type: 'error' });
      }
    } catch (error) {
      toast(`Couldn't delete ${type.data.name || 'Quote Template'}`, { type: 'error' });
    } finally {
      setDeleting(false);
    }
  };

  const handleEdit = () => {
    const customerId = type.data.customer?.entity_id || '';
    if (customerId !== '') navigate(`/${orgCode}/settings/load-types/${customerId}/${type.entity_id}`);
  }

  return (
    <tr>
      <td>{type.data.name || ''}</td>
      <td>{type.data.fuel_surcharge_type?.data.name || ''}</td>
      <td>{type.data.base_rate?.data.description || ''}</td>
      <td>{accessorials || ''}</td>
      <td>
        <div className="float-end justify-content-end">
          <DropdownButton id="dropdown-basic-button" title="Options">
            <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => setShowDelete(true)}>Delete</Dropdown.Item>
          </DropdownButton>
        </div>
      </td>
      <DeleteModal
        title="Delete Quote Template"
        description={`Would you like to delete ${type.data.name || 'Quote Template'}?`}
        deleteBtnText="Delete"
        isDeleting={isDeleting}
        shouldShowDeleteModal={showDelete}
        deleteItem={handleDelete}
        cancelDeletion={() => setShowDelete(false)}
      />
    </tr>
  );
}
