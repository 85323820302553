export const TRAILER_CHANGE_TABLE_HEADERS = [
  {
    name: 'Move',
    key: 'move',
  },
  {
    name: 'Time',
    key: 'time',
  },
  {
    name: 'From',
    key: 'from',
  },
  {
    name: 'To',
    key: 'to',
  },
  {
    name: 'Action',
    key: 'action',
  },
  {
    name: 'Shipments',
    key: 'shipments',
  },
  {
    name: 'Routes',
    key: 'Route',
  },
];

export const MODAL_TITLE = 'Trailer Change';
