import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
import { AlertCircle, Check } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import { AsyncDispatchNotification } from '../../../redux/slices/dispatch/dispatch.models';
import { selectTripById } from '../../../redux/slices/trips/trips';
import { AppState } from '../../../redux/models/state.models';
import { AsyncState } from '../../../services/notifications/Notification.models';
import { TripBoardModel } from '../../../redux/models/trip.models';
import { DateService } from '../../../utils/dateService';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { setSelectedRequestId } from '../../../redux/slices/notifications/notifications';

const name = 'TripDispatchAlert';
const LINK_TITLE = ' Open Trip';
const DEFAULT_STATE = `${AsyncState.SUCCESS}`;

const getVariant = (state: string) => {
  if (state === AsyncState.SUCCESS) return 'success';
  if (state === AsyncState.ERROR) return 'danger';
  return 'info';
};
const getDefaultMessage = (state: string) => {
  if (state === AsyncState.SUCCESS) return 'Successfully dispatched';
  if (state === AsyncState.ERROR) return 'Error dispatching';
  return 'Waiting to dispatch trip';
};
const getMessage = (dispatch: AsyncDispatchNotification, trip: TripBoardModel) => {
  const message = getDefaultMessage(dispatch.state);
  if (!trip) return `${message} trip`;
  const driver = trip.driver || '';
  const route = trip.route || '';
  const appt = trip.apt || '';
  return `${message} ${DateService.getHHmmaFormat(appt)} trip for ${driver} from ${route}`;
}
function DispatchIcon({ state = DEFAULT_STATE }) {
  if (state === AsyncState.SUCCESS) return <Check />;
  return <AlertCircle />
}
interface Props {
  dispatch: AsyncDispatchNotification;
}

export default function TripDispatchAlert({ dispatch }: Props) {
  const tripId = dispatch.trip_id || '';
  const { orgCode } = useParams();
  const navigate = useNavigate();
  const appDispatch = useAppDispatch()
  const trip = useSelector((state: AppState) => selectTripById(state, tripId));
  const handleOpenTrip = () => {
    navigate(`/${orgCode}/trips/${dispatch.trip_id}`);
    appDispatch(setSelectedRequestId(null));
  };
  return (
    <Alert
      variant={getVariant(dispatch.state)}
      className={`alert-outline ${name}-alert`}
      data-cy={`${name}-alert`}
    >
      <div className={`alert-icon ${name}-btn`}>
        <DispatchIcon state={dispatch.state} />
      </div>
      <div className="alert-message">
        {getMessage(dispatch, trip)}
        <Button variant="link" onClick={handleOpenTrip}><span>{LINK_TITLE}</span></Button>
      </div>
    </Alert>
  )
};
