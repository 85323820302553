/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  Button, Card, Form, Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';

import IntegrationsFerryModal from './IntegrationsFerryModal';
import IntegrationsEtaModal from './IntegrationsEtaModal';

import { AquadashServiceApiProvider } from '../../services/AquadashServiceApiProvider';

import { SelectInput } from '../../components/shared/Input';
import Analytics from '../../utils/analytics';
import { Group } from '../../redux/models/settings.models';
import { TripBoardModel } from '../../redux/models/trip.models';
import { EtaEmail, FerryEmail } from '../../redux/models/integration.models';

const INTEGRATIONS = 'Integrations';
const ETA_EMAIL = 'ETA Email';
const FERRY_EMAIL = 'Ferry Email';
const SEND = 'Send';

interface Props {
  integrations: string[];
  groups: Group[];
  trips: TripBoardModel[];
  shouldShowIntegrationsMenu: boolean;
  setShouldShowIntegrationsMenu: (show: boolean) => void;
}

export default function IntegrationsMenu({
  integrations,
  groups,
  trips,
  shouldShowIntegrationsMenu,
  setShouldShowIntegrationsMenu,
}: Props) {
  const { orgCode } = useParams();
  const [selectedETAGroups, setSelectedETAGroups] = useState<string[]>([]);
  const [selectedFerryGroup, setSelectedFerryGroup] = useState('');

  const [isSendingETAEmail, setIsSendingETAEmail] = useState(false);
  const [isSendingFerryEmail, setIsSendingFerryEmail] = useState(false);

  const hasETAIntegrations = integrations.indexOf('eta-email') !== -1;
  const hasFerryIntegrations = integrations.indexOf('ferry-email') !== -1;

  const [ferryEmailResponse, setFerryEmailResponse] = useState<FerryEmail | null>(null);
  const [etaEmailResponse, setEtaEmailResponse] = useState<EtaEmail | null>(null);
  const [shouldShowIntegrationsFerryModal, setShouldShowIntegrationsFerryModal] = useState(false);
  const [shouldShowIntegrationsEtaModal, setShouldShowIntegrationsEtaModal] = useState(false);

  const groupsOptions = groups.map((group) => ({
    value: group.entity_id,
    label: group.data.name,
  }));

  const showWarning = () => {
    const message = 'This group does not have trips. Please, select another one or create a new trip.';
    return toast(message, { type: 'warning' });
  };

  const selectETAGroup = (groupId: string) => {
    if (selectedETAGroups.includes(groupId)) {
      return setSelectedETAGroups(selectedETAGroups.filter((id) => id !== groupId));
    }
    return setSelectedETAGroups([...selectedETAGroups, groupId]);
  };

  const sendETAEmail = async () => {
    setIsSendingETAEmail(true);
    try {
      const foundTrips = trips.filter((trip) => selectedETAGroups.includes(trip.groupId));
      const tripsIds = foundTrips.map((trip) => trip.id);
      if (!tripsIds.length) {
        showWarning();
      }
      const response = await AquadashServiceApiProvider.sendETAEMail(tripsIds);
      if (response.status === 200) setEtaEmailResponse(response.data);
      // return toast('Emails were sent.', { type: 'success' });
    } catch (e) {
      setIsSendingETAEmail(false);
      const errorMessage = 'Couldn\'t send ETA email. Please contact support if the problem persists.';
      toast(errorMessage, { type: 'error' });
    } finally {
      setSelectedETAGroups([]);
      setShouldShowIntegrationsEtaModal(true);
      setIsSendingETAEmail(false);
      Analytics.sendETAEmail(orgCode);
    }
  };

  const sendFerryEmail = async () => {
    setIsSendingFerryEmail(true);
    try {
      const tripsIds = trips.filter((trip) => selectedFerryGroup === trip.groupId).map((trip) => trip.id);
      if (!tripsIds.length) {
        showWarning();
      }
      const response = await AquadashServiceApiProvider.sendFerryEmail(tripsIds);
      if (response.status === 200) {
        setFerryEmailResponse(response.data);
        // toast('Emails were sent.', { type: 'success' });
      }
    } catch (e) {
      setIsSendingETAEmail(false);
      const errorMessage = 'Couldn\'t send ferry email. Please contact support if the problem persists.';
      toast(errorMessage, { type: 'error' });
    } finally {
      setSelectedFerryGroup('');
      setIsSendingFerryEmail(false);
      setShouldShowIntegrationsFerryModal(true);
      Analytics.sendFerryEmail(orgCode);
    }
  };

  return (
    <>
      <div className="integrations-menu-header mb-3">
        <FontAwesomeIcon
          id="boards-integrations_toggle-Close__btn"
          size="lg"
          icon={faPaperPlane}
          className="integrations-menu-toggle me-2"
          onClick={() => setShouldShowIntegrationsMenu(!shouldShowIntegrationsMenu)}
        />
        <h5 style={{ margin: 0 }}>
          {INTEGRATIONS}
        </h5>
      </div>
      <div>
        {hasETAIntegrations ? (
          <Card>
            <Card.Body>
              <Form.Group>
                <h5>{ETA_EMAIL}</h5>
                {groups.map((group) => (
                  <Form.Check
                    checked={selectedETAGroups.includes(group.entity_id)}
                    key={group.entity_id}
                    type="checkbox"
                    name={group.entity_id}
                    id={group.entity_id}
                    label={group.data.name}
                    onChange={(e) => selectETAGroup(e.target.name)}
                  />
                ))}
              </Form.Group>
            </Card.Body>
            <Card.Footer style={{ textAlign: 'right' }}>
              <Button
                variant="primary"
                disabled={!selectedETAGroups.length || isSendingETAEmail}
                onClick={sendETAEmail}
              >
                {isSendingETAEmail ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {SEND}
              </Button>
            </Card.Footer>
          </Card>
        ) : null}
        {hasFerryIntegrations ? (
          <Card>
            <Card.Body>
              <SelectInput
                labelText={FERRY_EMAIL}
                name="group"
                page="integrations"
                value={selectedFerryGroup}
                options={groupsOptions}
                shouldHaveMargin={false}
                handleChange={(name: string, value: string) => setSelectedFerryGroup(value)}
                dataCy="intergrations_ferry_email_select_group_input"
              />
            </Card.Body>
            <Card.Footer style={{ textAlign: 'right' }}>
              <Button
                id="integrations-send__btn"
                variant="primary"
                disabled={!selectedFerryGroup || isSendingFerryEmail}
                onClick={sendFerryEmail}
              >
                {isSendingFerryEmail ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {SEND}
              </Button>
            </Card.Footer>
          </Card>
        ) : null}
      </div>
      {shouldShowIntegrationsEtaModal && etaEmailResponse && (
        <IntegrationsEtaModal
          shouldShowIntegrationsEtaModal={shouldShowIntegrationsEtaModal}
          setShouldShowIntegrationsEtaModal={setShouldShowIntegrationsEtaModal}
          etaEmail={etaEmailResponse}
        />
      )}
      {shouldShowIntegrationsFerryModal && ferryEmailResponse && (
        <IntegrationsFerryModal
          shouldShowIntegrationsFerryModal={shouldShowIntegrationsFerryModal}
          setShouldShowIntegrationsFerryModal={setShouldShowIntegrationsFerryModal}
          ferryEmailResponse={ferryEmailResponse}
        />
      )}

    </>
  );
}
