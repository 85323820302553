import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EntityList from '../base/list/EntityList';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import { Contact } from '../../../redux/models/contact.models';
import { Api } from '../../../services/services';
import ContactModal from './ContactModal';
import BreadCrumbs, { BreadCrumbRoute } from '../../shared/breadcrumbs/BreadCrumbs';

const getRoutes = (orgCode: string | undefined): BreadCrumbRoute[] => {
  return [
    {
      name: 'Settings',
      route: `/${orgCode || ''}/settings`,
    },
    {
      name: 'Contacts',
      route: `/${orgCode}/settings/contacts`,
    },
  ];
}

export default function ContactList() {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const [showAdd, setShowAdd] = useState(false);
  
  const handleGetName = (contact: Contact) => contact.data.name || '';
  const createRoute = (contact: Contact) => `/${orgCode}/settings/contacts/${contact.entity_id}`;
  const handleAdd = () => {
    setShowAdd(true);
  };
  const handleUpdate = (contact: Contact) => {
    const route = createRoute(contact);
    navigate(route);
  };
  
  return (
    <>
      <BreadCrumbs routes={getRoutes(orgCode)} />
      <EntityList
        title="Customer"
        settingsKey={SETTINGS_NAMES.CONTACTS}
        nameKey="name"
        api={Api.Contacts}
        allowAdd
        onAdd={handleAdd}
        onGetName={handleGetName}
        onCreateRoute={createRoute}
      >
      {showAdd && (
        <ContactModal
          show={showAdd}
          setShow={setShowAdd}
          onUpdate={handleUpdate}
        />
      )}
    </EntityList>
    </>
  );
}
