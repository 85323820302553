import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';

import Loader from '../../../components/Loader';

import useAppDispatch from '../../../hooks/useAppDispatch';
import {
  getInvoiceDetails, resetInvoices, selectAreInvoiceDetailsLoading, selectInvoiceDetails,
} from '../../../redux/slices/invoices';
import InvoiceDetails from './InvoiceDetails';
import { resetShipments } from '../../../redux/slices/shipments';

export default function Invoice() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  useEffect(() => {
    if (id) dispatch(getInvoiceDetails(id));
  }, [dispatch, id]);

  const invoiceDetails = useSelector(selectInvoiceDetails);

  useEffect(() => () => {
    dispatch(resetShipments());
    dispatch(resetInvoices());
  }, [dispatch]);

  const areInvoiceDetailsLoading = useSelector(selectAreInvoiceDetailsLoading);

  return (
    <>
      <Helmet title="Invoice" />
      <Container fluid className="p-0">
        {areInvoiceDetailsLoading && <Loader />}
        {(invoiceDetails && !areInvoiceDetailsLoading) && <InvoiceDetails invoiceDetails={invoiceDetails} />}
      </Container>
    </>
  );
}
