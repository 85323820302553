/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, JSXElementConstructor } from 'react';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { ItemModel, ROW_TYPE } from './ItemModel';

interface Props {
  driverId: string;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  onDrop: (item: any, monitor: DropTargetMonitor<unknown, unknown>, driverId: string) => void;
}

export default function DropWrapper({ driverId, children, onDrop }: Props) {
  const [{ isOver }, drop] = useDrop({
    accept: ROW_TYPE,
    canDrop: () => {
      // const tripId = item.trip.id;
      // const selectedDriverId = item.trip.driverId;
      // const driverData = drivers || [];
      // const itemIndex = driverData.findIndex((op) => op.entity_id === tripId);
      // const driverIndex = driverData.findIndex((op) => op.entity_id === selectedDriverId);
      // const can = [itemIndex + 1, itemIndex - 1, itemIndex].includes(driverIndex);
      // console.log('canDrop: ', can);
      return true;
    },
    drop: (item: ItemModel, monitor) => {
      // console.log(`drop item: ${item.trip.apt} ${item.trip.route}`);
      // console.log('old driver: ', item.driverId);
      // console.log('new driver: ', driverId);
      onDrop(item, monitor, driverId);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} className="drop-wrapper">
      {React.cloneElement(children, { isOver })}
    </div>
  );
}
