import React from 'react'
import { ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { Role } from '../../redux/slices/roles/role.models';

const isSelected = (id: string, selected: string[]) => selected.includes(id);

interface Props {
  title: string;
  roles: Role[];
  selected: string[];
  showIcon?: boolean;
  handleSelect: (role: Role) => void;
}

function ShareList({ title, roles, selected, showIcon, handleSelect }: Props) {
  return (
    <div>
      <h6 className="mt-2">{title}</h6>
      <ListGroup variant="flush">
        {roles
          .map(role => (
            <ListGroup.Item key={role.id} onClick={() => handleSelect(role)}>
              <div className="d-flex justify-content-between align-items-center">
                {role.name}
                {showIcon && <FontAwesomeIcon icon={isSelected(role.id, selected) ? faCheckCircle : faCircle} />}
              </div>
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  )
}

ShareList.defaultProps = {
  showIcon: false,
}

export default ShareList;