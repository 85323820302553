/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChangeLog, EntityContainer } from '../../../redux/models/core.models';
import ActivityLogListItem from './ActivityLogListItem';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import { Organisation } from '../../../redux/models/organisation.models';
import { getUsers } from '../../../redux/slices/settings';

interface Props {
  entity: string;
  history: ChangeLog[];
}

export default function ActivityLogList({ entity, history }: Props) {
  const { orgCode } = useParams();
  const { invoices } = useFeatureFlags();
  const [users, setUsers] = useState<EntityContainer<string>>({});
  const orgs: Array<Organisation> = invoices.organizations || [];
  const selected = orgs.find((org) => org.data.organization_code === orgCode);
  const orgId = selected?.entity_id;

  useEffect(() => {
    const load = async (organisationId: string) => {
      const response = await getUsers(organisationId);
      if (response.status === 200) {
        console.log(response.data.data);
        const userData = response.data.data || [];
        const obj = userData.reduce((store: EntityContainer<string>, user: any) => {
          return {
            ...store,
            [user.data.auth0_id]: user.data.name,
          }
        }, {});
        setUsers(obj);
      }
    };
    if (orgId) load(orgId);
  }, [orgId]);
  return (
    <div>
      {history.map((change) => (
        <ActivityLogListItem key={change.modified_at} change={change} entity={entity} users={users} />
      ))}
    </div>
  )
}