/* eslint-disable @typescript-eslint/return-await */
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, ListGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AquadashServiceApiProvider } from '../../../../services/AquadashServiceApiProvider';
import { removeExtension } from '../../utils/shipments.utils';

import DocumentItem from './DocumentItem';
import Analytics from '../../../../utils/analytics';

export default function DocumentsGroup({
  documentGroupName, attachments, group, updateShipmentDetails, handleDelete,
}) {
  const { orgCode } = useParams();
  const addAttachmentFile = (groupAttachments, fileName, file) => {
    const defaultAttachment = groupAttachments.find((attachment) => attachment.name === removeExtension(fileName));
    if (defaultAttachment) {
      return groupAttachments.map((attachment) => {
        if (attachment.name === removeExtension(fileName)) {
          return {
            ...attachment,
            filetype: file.filetype,
            path: file.name,
          };
        }
        return attachment;
      });
    }
    const attachmentsArr = [...groupAttachments];
    const obj = {
      name: file.name,
      filetype: file.filetype,
      path: file.name,
    };
    attachmentsArr.push(obj);
    return attachmentsArr;
  };

  const handleFileUpload = async (file, load) => {
    const upload = [{ name: file.name, filetype: file.type }];
    try {
      // get presigned URL
      const { data } = await AquadashServiceApiProvider.uploadFile(upload);
      // upload to s3
      const uploadObj = data.data[0];
      const request = fetch(uploadObj.path, {
        method: 'PUT',
        body: file,
      });
      // show the upload status
      load(uploadObj.name);
      const updatedAttachmentsData = addAttachmentFile(attachments || [], file.name, uploadObj);
      updateShipmentDetails(group, updatedAttachmentsData);
      return request;
    } catch (e) {
      const errorMessage = "Couldn't upload attachment. Please contact support if the problem persists.";
      return toast(errorMessage, { type: 'error' });
    } finally {
      Analytics.uploadDocument(orgCode);
    }
  };

  return (
    <Col className="shipmentDetails_fileGroup-section">
      <Card.Title data-cy="documentGroup_title">{documentGroupName}</Card.Title>
      <ListGroup variant="flush" id={`${group}_list`}>
        {attachments && attachments.map((attachment, i) => (
          <Fragment key={`${attachment.name}${i}`}>
            <DocumentItem
              attachment={attachment}
              group={group}
              handleFileUpload={handleFileUpload}
              handleDelete={() => handleDelete(attachment, group, attachments)}
              dataCy={`${group}_list_item`}
            />
          </Fragment>
        ))}
        <DocumentItem
          group={group}
          handleFileUpload={handleFileUpload}
          dataCy={`${group}_additional_upload`}
        />
      </ListGroup>
    </Col>
  );
}
