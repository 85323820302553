/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Container, Card } from 'react-bootstrap'
import SectionComponent from './components/Section'
import { useResizableColumns } from './utils'
import { Board, TripTableColumns } from '../../../redux/models/board.models'

import { AppState } from '../../../redux/models/state.models'
import { selectGroupsByBoardId } from '../../../redux/slices/settings'
import { selectAreTripsLoading, selectTripsByCardId, selectTripsFilterParams } from '../../../redux/slices/trips/trips'
import { DateService } from '../../../utils/dateService'
import { GroupView, NoteTemplate } from '../../../redux/models/settings.models'
import { BOARD_PAGE_TABS, getUnassignedCard, getUnassignedId } from '../constants/boards.constants'

const tabKey = BOARD_PAGE_TABS.GROUPS.eventKey;
const unassignedGroup: GroupView = getUnassignedCard(tabKey);

interface Props {
  board: Board;
  headers: TripTableColumns[];
  shouldExpandAllItems: boolean;
  noteTemplates: NoteTemplate[];
}

function DataGrid({ board, headers, shouldExpandAllItems, noteTemplates }: Props) {
  const { dateFrom, dateTo } = useSelector(selectTripsFilterParams);
  const groups = useSelector((state: AppState) => selectGroupsByBoardId(state, board.entity_id));
  const areTripsLoading = useSelector((state: AppState) => selectAreTripsLoading(state));
  const unassignedId = getUnassignedId(tabKey);
  const unassignedTrips = useSelector((state: AppState) => selectTripsByCardId(state, unassignedId));
  
  const [expandedGroupRows, setExpandedGroupRows] = useState<string[]>([]);
  const [expandedMoveRows, setExpandedMoveRows] = useState<string[]>([]);
  const [expandedShipmentRows, setExpandedShipmentRows] = useState<string[]>([]);
  const { columns, startResizing } = useResizableColumns(headers)
  const isSameDay = DateService.isSame(dateFrom, dateTo);

  const handleTaskUpdate = (
    sectionId: string, taskId: string, key: string, value: any, subtaskId: string | null = null
  ) => {
    console.log(sectionId)
    console.log(taskId)
    console.log(key)
    console.log(value)
    console.log(subtaskId)
  }

  const toggleExpand = (id: string) => {
    setExpandedGroupRows(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    )
  }

  const moveExpand = (id: string) => {
    setExpandedShipmentRows(prev => prev.filter(rowId => rowId !== id))
    setExpandedMoveRows(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    )
  }

  const tripExpand = (id: string) => {
    const isMoveExpanded = expandedMoveRows.some((trip) => trip === id);
    const isShipmentExpanded = expandedShipmentRows.some((trip) => trip === id);
    const isExpanded = isMoveExpanded || isShipmentExpanded;
    if (isExpanded) {
      // close
      setExpandedMoveRows(prev => prev.filter(rowId => rowId !== id))
      setExpandedShipmentRows(prev => prev.filter(rowId => rowId !== id))
    } else {
      // open
      if (!isMoveExpanded) {
        setExpandedMoveRows(prev => [...prev, id]);
      }
      if (!isShipmentExpanded) {
        setExpandedShipmentRows(prev => [...prev, id]);
      }
    }
  }
  useEffect(() => {
    const defaultExpanded = shouldExpandAllItems ? [
      ...groups.map(section => section.entity_id),
      unassignedGroup.entity_id,
    ] : [];
    setExpandedGroupRows(defaultExpanded);
  }, [shouldExpandAllItems]);
  return (
    <Card>
      <Container fluid className="mt-4 mb-2">
        {unassignedTrips.length > 0 && !areTripsLoading && (
          <SectionComponent
            group={unassignedGroup}
            columns={columns}
            boardDate={dateFrom}
            isDateRange={!isSameDay}
            startResizing={startResizing}
            onUpdateTask={handleTaskUpdate}
            onGroupExpand={toggleExpand}
            onMoveExpand={moveExpand}
            onTripExpand={tripExpand}
            noteTemplates={noteTemplates}
            expandedGroupRows={expandedGroupRows}
            expandedMoveRows={expandedMoveRows}
            expandedShipmentRows={expandedShipmentRows}
          />
        )}
        {groups.map(group => (
          <SectionComponent
            key={group.entity_id}
            group={group}
            columns={columns}
            boardDate={dateFrom}
            isDateRange={!isSameDay}
            startResizing={startResizing}
            onUpdateTask={handleTaskUpdate}
            onGroupExpand={toggleExpand}
            onMoveExpand={moveExpand}
            onTripExpand={tripExpand}
            noteTemplates={noteTemplates}
            expandedGroupRows={expandedGroupRows}
            expandedMoveRows={expandedMoveRows}
            expandedShipmentRows={expandedShipmentRows}
          />
        ))}
      </Container>
    </Card>
  )
}

export default DataGrid
