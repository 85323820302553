import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TEXTS } from '../pages/landing/landing.constants';

function Footer() {
  return (
    <footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col xs="6" className="text-end">
            <p className="mb-0">
              &copy;
              {' '}
              {new Date().getFullYear()}
              {' '}
              -
              {' '}
              <span href="/" className="text-muted">
                {TEXTS.TITLE}
              </span>
              {' '}
              {`v${TEXTS.VERSION}`}
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
