import moment from 'moment-timezone';

const maintenance = process.env.REACT_APP_APP_MAINTENANCE_MODE_STATUS;
const dateStr = process.env.REACT_APP_APP_MAINTENANCE_MODE_DATE;
const durationStr = process.env.REACT_APP_APP_MAINTENANCE_MODE_DURATION_HRS;

const data = {
  isMaintenance: maintenance === 'upcoming',
  date: moment(dateStr).format('dddd, Do MMMM'),
  time: moment(dateStr).format('hh:mm a'),
  duration: durationStr === '1' ? '1 hour' : `${durationStr} hours`,
}

export default data;
