/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';

import { AuthContext } from '../contexts/Auth0Context';

const useAuth: any = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('AuthContext must be placed within AuthProvider');

  return context;
};

export default useAuth;
