/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../Loader';

import {
  selectContactsObject,
  selectBillingTypeObject,
  selectAddressesObject,
  selectShipmentTypesObject,
} from '../../../redux/slices/settings';

import { SHIPMENTS_MODAL_TABLE_HEADERS } from './shipmentsModalTable.constants';
import ShipmentListModalFilter from './ShipmentListModalFilter';
import {
  Shipment,
  ShipmentListFilters,
  ShipmentListView,
  defaultShipmentListFilters
} from '../../../redux/slices/shipment-list/shipment-list.models';
import { fetchShipmentsPaginated } from '../../../redux/slices/shipment-list/shipment-list';
import { formatShipment, totalShipmentsMessage } from '../../../redux/slices/shipment-list/shipment-list.utils';
import PaginatedButton from '../../shared/buttons/PaginatedButton';
import ShipmentsModalTableItem from './ShipmentsModalTableItem';
import Analytics from '../../../utils/analytics';

const NO_SHIPMENTS_MESSAGE = 'No shipments found';
const INIT_FILTERS = defaultShipmentListFilters;

interface Props {
  headers: any;
  shipments: ShipmentListView[];
  selectedShipments: string[];
  setSelectedShipments: React.Dispatch<any>;
}

export default function ShipmentsModalTable({
  headers = SHIPMENTS_MODAL_TABLE_HEADERS,
  shipments,
  selectedShipments,
  setSelectedShipments,
}: Props) {
  const contacts = useSelector(selectContactsObject);
  const billingTypes = useSelector(selectBillingTypeObject);
  const addresses = useSelector(selectAddressesObject);
  const shipmentTypes = useSelector(selectShipmentTypesObject);
  const [shipmentFilters, setShipmentFilters] = useState(INIT_FILTERS);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<ShipmentListView[]>(shipments);
  const [shouldShowFilters, setShouldShowFilters] = useState(false);
  const [isDateBtnOpen, setIsDateBtnOpen] = useState(false);
  const numShipments = data.length || 0;

  const loadShipments = async (filters: ShipmentListFilters) => {
    try {
      setIsLoading(true);
      const filterData: ShipmentListFilters = {
        ...filters,
        page: 0,
      };
      const response = await fetchShipmentsPaginated(filterData);
      const items = response.data.data.items || [];
      const totalCount = response.data.data.total_count || 0;
      const listview = items.map((item: Shipment) => formatShipment(item, contacts, billingTypes, addresses));
      setTotal(totalCount);
      setShipmentFilters(filterData);
      setData(listview);
    } catch (error) {
      Analytics.capture(error);
      const errorMessage = "Couldn't load shipments. Please contact support if the problem persists.";
      toast(errorMessage, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadNextPage = async () => {
    try {
      setIsLoadingNextPage(true);
      const filterData: ShipmentListFilters = {
        ...shipmentFilters,
        page: shipmentFilters.page + 1,
      };
      const response = await fetchShipmentsPaginated(filterData);
      const items = response.data.data.items || [];
      const listview = items.map((item: Shipment) => formatShipment(item, contacts, billingTypes, addresses));
      setShipmentFilters(filterData);
      setData((prev) => [...prev, ...listview]);
    } catch (error) {
      Analytics.capture(error);
      const errorMessage = "Couldn't load shipments. Please contact support if the problem persists.";
      toast(errorMessage, { type: 'error' });
    } finally {
      setIsLoadingNextPage(false);
    }
  };

  const handleDateBtnToggle = (open: boolean) => {
    setIsDateBtnOpen(open);
  };
  const handleMenuToggle = () => {
    const open = !shouldShowFilters;
    setShouldShowFilters(open);
    if (!open) handleDateBtnToggle(false);
  };
  const handleSearchShipments = (filters: ShipmentListFilters) => {
    setShouldShowFilters(false);
    setIsDateBtnOpen(false);
    loadShipments(filters);
  };

  const selectShipment = (e: any, shipmentId: string) => {
    e.stopPropagation();
    if (selectedShipments.includes(shipmentId)) {
      return setSelectedShipments(selectedShipments.filter((id) => id !== shipmentId));
    }
    return setSelectedShipments([...selectedShipments, shipmentId]);
  };

  const renderInTable = (item: JSX.Element | string) => (
    <tr>
      <td colSpan={SHIPMENTS_MODAL_TABLE_HEADERS.length}>
        {item}
      </td>
    </tr>
  );

  useEffect(() => {
    const load = async () => {
      try {
        setIsLoading(true);
        const response = await fetchShipmentsPaginated(INIT_FILTERS);
        const items = response.data.data.items || [];
        const totalCount = response.data.data.total_count || 0;
        const listview = items.map((item: Shipment) => formatShipment(item, contacts, billingTypes, addresses))
        setTotal(totalCount);
        setData(listview);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, []);

  return (
    <div style={{ height: '75vh' }}>
      <Table className="shipments-table">
        <thead className="shipments-table-head">
          <tr>
            {headers.map((header: any) => (
              <th key={header.key}>
                {header.name}
              </th>
            ))}
            <th>
              <FontAwesomeIcon
                fixedWidth
                height={24}
                width={24}
                icon={faSlidersH}
                id="search_shipments_table_open_filter_btn"
                data-cy="search_shipments_table_open_filter_btn"
                className="shipments-table-filter-icon"
                onClick={handleMenuToggle}
              />
              <ShipmentListModalFilter
                filterData={shipmentFilters}
                shouldShowFilters={shouldShowFilters}
                setShouldShowFilters={setShouldShowFilters}
                saveFilters={handleSearchShipments}
                isDateBtnOpen={isDateBtnOpen}
                handleDateBtnToggle={handleDateBtnToggle}
                setIsDateBtnOpen={setIsDateBtnOpen}
                setFilters={setShipmentFilters}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && renderInTable(<Loader />)}
          {!isLoading && data.length === 0 && renderInTable(NO_SHIPMENTS_MESSAGE)}
          {!isLoading && data.map((shipment, idx) => (
            <ShipmentsModalTableItem
              key={shipment.id || `shipment_key_${idx}`}
              shipment={shipment}
              selectedShipments={selectedShipments}
              shipmentTypes={shipmentTypes}
              selectShipment={selectShipment}
            />
          ))}
        </tbody>
      </Table>
      {!isLoading && !isLoadingNextPage && (
        <p className="text-muted">{totalShipmentsMessage(numShipments, total)}</p>
      )}
      {!isLoading && !isLoadingNextPage && (
        <PaginatedButton
          isLoading={isLoadingNextPage}
          total={total}
          page={shipmentFilters.page}
          pageSize={shipmentFilters.pageSize}
          dataCy="load_more_shipments_btn"
          variant="outline-secondary"
          spinner="secondary"
          handleClick={handleLoadNextPage}
        />
      )}
    </div>
  );
}
