import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';

import useAppDispatch from '../../../hooks/useAppDispatch';

import ListViewHeader from './ListViewHeader';
import NoShipmentMsg from '../ShipmentsTable/NoShipmentMsg';
import Loader from '../../../components/Loader';

import {
  loadShipmentsPaginated,
  loadShipmentsNextPage,
  selectAreShipmentsLoading,
  selectTotalShipments,
  selectIsShipmentsLoaded,
  selectShipmentListView,
  selectShipmentPage,
  selectAreShipmentsLoadingMore,
} from '../../../redux/slices/shipment-list/shipment-list';
import { selectIsSettingsLoaded, selectShipmentBoardSettings } from '../../../redux/slices/settings';

import { LOADING_TEXT } from '../../boards/constants/boards.constants';
import { BILLING_SHIPMENT_TABLE_HEADERS } from '../ShipmentsTable/constants/shipmentTable.constants';
import UnassignedShipmentsTable from '../ShipmentsTable/UnassignedShipmentsTable';
import PaginatedButton from '../../../components/shared/buttons/PaginatedButton';
import { getSessionFilters } from '../../../redux/slices/shipment-list/shipment-list.models';
import ShipmentListViewTable from '../ShipmentsTable/list-view/ShipmentListViewTable';
import { totalShipmentsMessage } from '../../../redux/slices/shipment-list/shipment-list.utils';

const NO_SHIPMENTS_MESSAGE = 'No shipments found';

function ShipmentListView() {
  const dispatch = useAppDispatch();
  const [selectedShipments, setSelectedShipments] = useState<string[]>([]);
  const { page, pageSize } = useSelector(selectShipmentPage);
  const shipmentIds = useSelector(selectShipmentListView);
  const areShipmentsLoading = useSelector(selectAreShipmentsLoading);
  const areShipmentsLoadingMore = useSelector(selectAreShipmentsLoadingMore);
  const boardSettings = useSelector(selectShipmentBoardSettings);
  const isBoardSettings = useSelector(selectIsSettingsLoaded);
  const isLoaded = useSelector(selectIsShipmentsLoaded);
  const total = useSelector(selectTotalShipments);
  const numShipments = shipmentIds.length || 0;

  const handleLoadNextPage = () => {
    const nextPage = page + 1;
    dispatch(loadShipmentsNextPage(nextPage, pageSize));
  };

  useEffect(() => {
    if (isBoardSettings && !isLoaded) {
      const filters = getSessionFilters();
      dispatch(loadShipmentsPaginated(0, filters.pageSize, filters));
    }
  }, [dispatch, isBoardSettings, isLoaded]);

  return (
    <>
      <Helmet title="Shipments" />
      <Container fluid className="p-0">
        <ListViewHeader
          title="Shipments"
          selectedShipments={selectedShipments}
        />
        {areShipmentsLoading && <Loader text={LOADING_TEXT} />}
        {!areShipmentsLoading && (
          <UnassignedShipmentsTable />
        )}
        {shipmentIds.length > 0 && !areShipmentsLoading ? (
          <div className="mt-4">
            <ShipmentListViewTable
              headers={BILLING_SHIPMENT_TABLE_HEADERS}
              noShipmentsMessage={NO_SHIPMENTS_MESSAGE}
              shipmentIds={shipmentIds}
              selectedShipments={selectedShipments}
              addresses={boardSettings.addresses || {}}
              billingTypes={boardSettings.billingTypes || {}}
              contacts={boardSettings.contacts || {}}
              setSelectedShipments={setSelectedShipments}
            />
            <p>{totalShipmentsMessage(numShipments, total)}</p>
            <PaginatedButton
              isLoading={areShipmentsLoadingMore}
              total={total}
              page={page}
              pageSize={pageSize}
              dataCy="load_more_shipments_btn"
              variant="outline-secondary"
              spinner="secondary"
              handleClick={handleLoadNextPage}
            />
          </div>
        ) : (
          <div className="mt-6">
            <NoShipmentMsg areShipmentsLoading={areShipmentsLoading} />
          </div>
        )}
      </Container>
    </>
  );
}

export default ShipmentListView;
