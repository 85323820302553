/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, Col, Stack } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { createInvoice, updateAccessorials } from '../../../../redux/slices/invoices';
import Accessorials from './Accessorials';
import { Shipment, ShipmentTypeFieldData } from '../../../../redux/models/shipment.models';
import { DropdownOptionsModel } from '../../../../components/shared/SelectInput.models';
import { EntityContainer } from '../../../../redux/models/core.models';
import { SelectInput } from '../../../../components/shared/Input';
import { isFieldError } from '../ShipmentForm/ShipmentTypes/shipment.form.utils';
import { ShipmentErrorLabel } from '../ShipmentForm/ShipmentTypes/ShipmentField';

import { BillingType, BillingItem } from '../../../../redux/models/billing.models';
import Permission from '../../../../components/shared/permissions/Permission';
import { InvoiceWritePermissions } from '../../../../components/shared/permissions/permissions.utils';
import AsyncButton from '../../../../components/shared/buttons/AsyncButton';
import { handleBilling, mapBillingItem } from '../../utils/shipments.utils';
import { GlCode, UOM } from '../../../../redux/models/settings.models';

interface Props {
  shipmentDetails: Shipment;
  disabled: boolean;
  billingTypeSelectOptions: DropdownOptionsModel[];
  fields: ShipmentTypeFieldData;
  validation: EntityContainer<boolean>;
  billingTypes: BillingType[];
  billingItems: BillingItem[];
  uoms: Record<string,UOM>;
  glCodes: Record<string,GlCode>;
  onSave: (shipment: Shipment, reload: boolean) => void;
  onUpdateStore: (shipment: Shipment) => void;
}

export default function BillingCard({
  shipmentDetails, disabled, billingTypeSelectOptions,
  fields, validation, billingTypes, billingItems,
  uoms, glCodes,
  onSave, onUpdateStore
}: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const baseRateData = shipmentDetails.data.base_rate || {};
  const isBaseRate = !!Object.keys(baseRateData).length;
  const quoteTemplateField = fields.billing_type_id;
  const invoice = shipmentDetails.data?.invoice;

  const handlePaginatedSave = async () => {
    await onSave(shipmentDetails, false);
  };
  const handleAddInvoice = async () => {
    await handlePaginatedSave();
    const inv = await createInvoice([shipmentDetails.entity_id], orgCode);
    if (inv.entity_id) {
      navigate(`/${orgCode}/invoices/${inv.entity_id}`);
    }
  };

  const handleUpdate = async () => {
    // remove existing invoice data from shipment details & save before updating invoice
    const shipmentDetailsData = {
      ...shipmentDetails,
      data: {
        ...shipmentDetails.data,
      },
    };
    if (shipmentDetailsData.data?.invoice) {
      delete shipmentDetailsData?.data?.invoice;
    }
    await handlePaginatedSave();
    const inv = await updateAccessorials(shipmentDetails.entity_id);
    if (inv) {
      navigate(`/${orgCode}/invoices/${inv.entity_id}`);
    }
  };

  const handleForm = (prop: string, value: string) => {
    const updates: any = {
      ...shipmentDetails,
      data: {
        ...shipmentDetails.data,
        [prop]: value,
      },
    };
    if (prop === 'billing_type_id') {
      const isEmpty = value === '';
      if (isEmpty) {
        updates.data.billing_type = null;
        updates.data.base_rate = null;
        updates.data.accessorials = [];
      } else {
        const btype = billingTypes.find((type) => type.entity_id === value);
        if (btype !== undefined && btype !== null) updates.data.billing_type = btype;
        const billingDetails = handleBilling(value, billingTypes, billingItems);
        if (billingDetails.base_rate) {
          const br = mapBillingItem(billingDetails.base_rate as any, uoms, glCodes)
          updates.data.base_rate = {
            ...br,
          };
        }
        const accessorials = billingDetails.accessorials || [];
        updates.data.accessorials = accessorials.map((acc) => mapBillingItem(acc, uoms, glCodes))
      }
    }
    onUpdateStore(updates);
  };

  return (
    <Card data-cy="billing-card">
      <Card.Header>
        <Stack direction="horizontal">
          <Col md={7}>
            <h4>Billing</h4>
          </Col>
          <Col md={5}>
            <Stack direction="horizontal" gap={2}>
              {quoteTemplateField.visible && (
                <Stack>
                  <SelectInput
                    labelText="  "
                    placeholder="Template..."
                    name={quoteTemplateField.key}
                    page="{page}"
                    value={shipmentDetails.data.billing_type_id}
                    options={billingTypeSelectOptions}
                    handleChange={handleForm}
                    dataCy="billing_type"
                    disabled={disabled}
                  />
                  {isFieldError(quoteTemplateField, validation) && (
                    <ShipmentErrorLabel field={quoteTemplateField} />
                  )}
                </Stack>
              )}
              <Permission resources={InvoiceWritePermissions}>
                {!invoice ? (
                  <AsyncButton
                    title="Invoice"
                    id="shipmentDetails-createInvoice__btn"
                    dataCy="shipmentDetails_createInvoice_btn"
                    variant="outline-primary"
                    spinner="primary"
                    disabled={disabled}
                    handleClick={handleAddInvoice}
                  />
                ) : (
                  <AsyncButton
                    title="Update"
                    id="shipmentDetails-updateInvoice__btn"
                    dataCy="shipmentDetails_updateInvoice_btn"
                    variant="outline-primary"
                    disabled={disabled}
                    handleClick={handleUpdate}
                  />
                )}
              </Permission>
            </Stack>
          </Col>
        </Stack>
      </Card.Header>
      {isBaseRate && (
        <Accessorials
          disabled={disabled}
          shipmentDetails={shipmentDetails}
          onUpdateStore={onUpdateStore}
        />
      )}
    </Card>
  );
}
