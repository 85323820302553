import React from 'react';
import { useSelector } from 'react-redux';
import { SelectInput } from '../../../components/shared/Input';
import { Move } from '../../../redux/models/trip.models';
import { DropdownOptionsModel } from '../../../components/shared/SelectInput.models';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { checkTrailerChanges, selectMoveState } from '../../../redux/slices/trailer-changes/trailerChanges';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
// import { AppState } from '../../../redux/models/state.models';

interface Props {
  page: string;
  move: Move;
  fleetOptions: DropdownOptionsModel[];
  isEditable: boolean;
  onMoveItemChange: (movePosition: number) => (prop: string, value: string) => void;
}

export default function TrailerInput({ page, move, fleetOptions, isEditable, onMoveItemChange }: Props) {
  const dispatch = useAppDispatch();
  const features = useFeatureFlags();
  const moveState = useSelector(selectMoveState);
  const isChangesEnabled = features.trailer_changes?.is_enabled || false;
  // TODO: Update trailer changes
  const handleTrailerChange = (movePosition: number) => (prop: string, value: string) => {
    onMoveItemChange(movePosition)(prop, value);
    if (isChangesEnabled && move.entity_id !== '') dispatch(checkTrailerChanges(move, moveState[move.entity_id]));
  };
  return (
    <SelectInput
      multiple
      name="trailer_ids"
      page={page}
      value={move?.data?.trailer_ids}
      options={fleetOptions}
      shouldHaveMargin={false}
      handleChange={handleTrailerChange(move.data.position)}
      dataCy="move_trailer_id"
      disabled={!isEditable}
      labelText=""
    />
  );
}
